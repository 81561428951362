import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Dress1 from '../images/apr24-sewingbee-dress1.jpg';
import Dress2 from '../images/apr24-sewingbee-dress2.jpg';
import Dress3 from '../images/apr24-sewingbee-dress3.jpg';
import Dress4 from '../images/apr24-sewingbee-dress4.jpg';
import Dress5 from '../images/apr24-sewingbee-dress5.jpg';
import Dress6 from '../images/apr24-sewingbee-dress6.jpg';
import Dress7 from '../images/apr24-sewingbee-dress7.jpg';
import Dress8 from '../images/apr24-sewingbee-dress8.jpg';
import Dress9 from '../images/apr24-sewingbee-dress9.jpg';
import Dress10 from '../images/apr24-sewingbee-dress10.jpg';
import Dress11 from '../images/apr24-sewingbee-dress11.jpg';
import Dress12 from '../images/apr24-sewingbee-dress12.jpg';
import Dress13 from '../images/apr24-sewingbee-dress13.jpg';

const  April24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Sewing Bee"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle yellow">Choose one project</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo yellow-light">Advanced - Make a Dress</div>
                    <div className="badgePageItemEvidence"> 
                        <p>I had a number of disasters whilst putting this dress together - the sewing machine refused to work so it is all hand-stitched, and the back panels got sewn up not one, not two but three times because I made some pretty basic errors!!</p>
                        <br/>
                        <p>But, I love this duck fabric so I persevered.  It doesn't have much shape on the hanger, but it looks nice on!</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Dress1} alt="A dress pattern on top of some colourful duck print fabric" /></div>
                            <div className="galleryItem"><img src={Dress2} alt="A dress pattern laid out on fabric,ready for cutting" /></div>
                            <div className="galleryItem"><img src={Dress3} alt="Dress pattern pieces cut out" /></div>
                            <div className="galleryItem"><img src={Dress4} alt="Dress fabric in the process of being hand stitched" /></div>
                            <div className="galleryItem"><img src={Dress5} alt="A zip ready for sewing in to a dress" /></div>
                            <div className="galleryItem"><img src={Dress6} alt="Pinned seams on a dress" /></div>
                            <div className="galleryItem"><img src={Dress7} alt="Pinned binding on a dress" /></div>
                            <div className="galleryItem"><img src={Dress8} alt="Completed armhole binding on a dress" /></div>
                            <div className="galleryItem"><img src={Dress9} alt="A dress being hemmed" /></div>
                            <div className="galleryItem"><img src={Dress10} alt="Front view of a newly made dress" /></div>
                            <div className="galleryItem"><img src={Dress11} alt="Back view of a newly made dress" /></div>
                            <div className="galleryItem"><img src={Dress12} alt="Finished dress on a hangar" /></div>
                            <div className="galleryItem"><img src={Dress13} alt="Close up of the back lacing of a dress" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default April24