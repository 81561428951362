import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Cornucopia1 from '../images/cupjan24-cornucopia1.jpg';
import Cornucopia2 from '../images/cupjan24-cornucopia2.jpg';
import Cornucopia3 from '../images/cupjan24-cornucopia3.jpg';
import Cornucopia4 from '../images/cupjan24-cornucopia4.jpg';
import Cornucopia5 from '../images/cupjan24-cornucopia5.jpg';
import Cornucopia6 from '../images/cupjan24-cornucopia6.jpg';
import Cornucopia7 from '../images/cupjan24-cornucopia7.jpg';
import Cornucopia8 from '../images/cupjan24-cornucopia8.jpg';
import Cornucopia9 from '../images/cupjan24-cornucopia9.jpg';
import Cornucopia10 from '../images/cupjan24-cornucopia10.jpg';
import Cornucopia11 from '../images/cupjan24-cornucopia11.jpg';
import Cornucopia12 from '../images/cupjan24-cornucopia12.jpg';
import Cornucopia13 from '../images/cupjan24-cornucopia13.jpg';
import Capricorn1 from '../images/cupjan24-toc1.jpg';
import Capricorn2 from '../images/cupjan24-toc2.jpg';
import Capricorn3 from '../images/cupjan24-toc3.jpg';
import Capricorn4 from '../images/cupjan24-toc4.jpg';
import Capricorn5 from '../images/cupjan24-toc5.jpg';
import Capricorn6 from '../images/cupjan24-toc6.jpg';
import Capricorn7 from '../images/cupjan24-toc7.jpg';
import Capricorn8 from '../images/cupjan24-toc8.jpg';
import Capricorn9 from '../images/cupjan24-toc9.jpg';
import Capricorn10 from '../images/cupjan24-toc10.jpg';
import Capricorn11 from '../images/cupjan24-toc11.jpg';
import Capricorn12 from '../images/cupjan24-toc12.jpg';




const CupJan24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Rebel Cup January 2024 Bonus Points"} />
            <div className="badgePageItem">Rebel Cup challenges are only posted in the facebook group for confirmed members. You will have to join the club to find out why I am doing these things!</div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Bonus 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light">Cornucopia</div>
                    <div className="badgePageItemEvidence"> 
                        <p>Here's my WIP and final image.</p>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Cornucopia1} alt="Work in progress shot of a drawing of a cornucopia" /></div>
                            <div className="galleryItem"><img src={Cornucopia2} alt="Work in progress shot of a drawing of a cornucopia" /></div>
                            <div className="galleryItem"><img src={Cornucopia3} alt="Work in progress shot of a drawing of a cornucopia" /></div>
                            <div className="galleryItem"><img src={Cornucopia4} alt="Work in progress shot of a drawing of a cornucopia" /></div>
                            <div className="galleryItem"><img src={Cornucopia5} alt="Work in progress shot of a drawing of a cornucopia" /></div>
                            <div className="galleryItem"><img src={Cornucopia6} alt="Work in progress shot of a drawing of a cornucopia" /></div>
                            <div className="galleryItem"><img src={Cornucopia7} alt="Work in progress shot of a drawing of a cornucopia" /></div>
                            <div className="galleryItem"><img src={Cornucopia8} alt="Work in progress shot of a drawing of a cornucopia" /></div>
                            <div className="galleryItem"><img src={Cornucopia9} alt="Work in progress shot of a drawing of a cornucopia" /></div>
                            <div className="galleryItem"><img src={Cornucopia10} alt="Work in progress shot of a drawing of a cornucopia" /></div>
                            <div className="galleryItem"><img src={Cornucopia11} alt="Work in progress shot of a drawing of a cornucopia" /></div>
                            <div className="galleryItem"><img src={Cornucopia12} alt="Work in progress shot of a drawing of a cornucopia" /></div>
                            <div className="galleryItem"><img src={Cornucopia13} alt="Finished drawing of a cornucopia" /></div>
                        </div>                       
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Bonus 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light">Tropic of Capricorn</div>
                    <div className="badgePageItemEvidence"> 
                        <p>For my celebration I chose Brazil. I did some research around New Year in Brazil, so for my celebration I wore white and included flowers, candles and perfume on my table to represent offerings made during celebrations. </p>
                        <br/>
                        <blockquote>Quote: "In Rio, crowds of people go to beaches to make offerings to Yemenja. She is the African deity known as the 'Queen of the Sea' in the Candomble religion. People set hundreds of little boats carrying presents, such as flowers, perfume, and candles, into the waters of Copacabana Beach."</blockquote>
                        <br/>
                        <p>I wore black underneath my white outfit to represent independence. Not all sources list black as an option.</p>
                        <br/>
                        <blockquote>Quote: "In Brazilian culture, all are expected to wear white attire to welcome the New Year, to attract peace and happiness. But everyone can also add their own twist by wearing accent colors beneath the white clothing to represent New Year's resolutions-- such as orange for professional success, blue for harmony, yellow for prosperity, red or pink for romance, purple for inspiration, and green for health."</blockquote>
                        <br/>
                        <p>Source for the colour black: https://www.saintcoxinha.com/blogs/news/celebrating-new-year-s-eve-in-brazil#:~:text=black%20for%20independence</p>
                        <br/>
                        <p>For my food I made the following:</p>
                        <ul className="list">
                            <li>Brazilian One Pan Chicken and Rice - https://www.iheartbrazil.com/brazilian-chicken-and-rice-galinhada-recipe/</li>
                            <li>Pacoca - https://www.tasteofhome.com/recipes/pacoca/</li>
                            <li>Bala Baianas - https://eatrio.net/2015/02/balas-baianas-sugar-glass-coated-coconut-candy.html</li>
                            <li>Red Wine Cocktail - https://www.iheartbrazil.com/brazilian-red-wine-cocktail</li>
                        </ul>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Capricorn1} alt="Red pepper, onion, garlic and rice on a chopping board" /></div>
                            <div className="galleryItem"><img src={Capricorn2} alt="A plate of chicken and rice" /></div>
                            <div className="galleryItem"><img src={Capricorn3} alt="Ingredients for Pacoca in a food blender" /></div>
                            <div className="galleryItem"><img src={Capricorn4} alt="Pacoca mixture laid out in a pan to set" /></div>
                            <div className="galleryItem"><img src={Capricorn5} alt="Set and cut Pacoca" /></div>
                            <div className="galleryItem"><img src={Capricorn6} alt="Ingredients in a pan for making Bala Baianas" /></div>
                            <div className="galleryItem"><img src={Capricorn7} alt="Rolled balls of baked condensed milk and sugar on a plate" /></div>
                            <div className="galleryItem"><img src={Capricorn8} alt="Sugar caramelising in a pan" /></div>
                            <div className="galleryItem"><img src={Capricorn9} alt="Balas Baianas freshly dipped in caramlised sugar on a plate" /></div>
                            <div className="galleryItem"><img src={Capricorn10} alt="Cocktail ingredients in a blender" /></div>
                            <div className="galleryItem"><img src={Capricorn11} alt="Pouring a blended cocktail into a glass" /></div>
                            <div className="galleryItem"><img src={Capricorn12} alt="Plates of food and drink laid out on a table ready for a celebration" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CupJan24