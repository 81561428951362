import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Vegan from '../images/chef22.jpg';
import IceCream from '../images/cupjuly23-sundae15.jpg';
import Jelly1 from '../images/confectioner-jelly1.jpg';
import Jelly2 from '../images/confectioner-jelly2.jpg';
import Jelly3 from '../images/confectioner-jelly3.jpg';
import Jelly4 from '../images/confectioner-jelly4.jpg';
import Jelly5 from '../images/confectioner-jelly5.jpg';
import Jelly6 from '../images/confectioner-jelly6.jpg';
import Jelly7 from '../images/confectioner-jelly7.jpg';
import Mousse1 from '../images/confectioner-mousse1.jpg';
import Mousse2 from '../images/confectioner-mousse2.jpg';
import Mousse3 from '../images/confectioner-mousse3.jpg';
import Mousse4 from '../images/confectioner-mousse4.jpg';
import Dumpling1 from '../images/confectioner-dumpling1.jpg';
import Dumpling2 from '../images/confectioner-dumpling2.jpg';
import Dumpling3 from '../images/confectioner-dumpling3.jpg';
import Dumpling4 from '../images/confectioner-dumpling4.jpg';
import Dumpling5 from '../images/confectioner-dumpling5.jpg';
import Dumpling6 from '../images/confectioner-dumpling6.jpg';
import Dumpling7 from '../images/confectioner-dumpling7.jpg';
import Dumpling8 from '../images/confectioner-dumpling8.jpg';
import Dumpling9 from '../images/confectioner-dumpling9.jpg';
import Dumpling10 from '../images/confectioner-dumpling10.jpg';
import Dumpling11 from '../images/confectioner-dumpling11.jpg';
import Candy1 from '../images/confectioner-candy1.jpg';
import Candy2 from '../images/confectioner-candy2.jpg';
import Candy3 from '../images/confectioner-candy3.jpg';
import Candy4 from '../images/confectioner-candy4.jpg';

const Confectioner = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"Confectioner"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Dessert 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Something vegan</div>
                    <div className="badgePageItemEvidence">
                        <p>For this, I am cross-using a pudding I made for my chef badge - Chia Coconut Pudding.</p>
                        <p>Recipe: https://www.allrecipes.com/recipe/237469/chia-coconut-pudding-with-coconut-milk/</p>
                        <br/>
                        <img src={Vegan} className="imgQuarter" alt="A Chia Coconut pudding in a small glass dish on a wooden place mat on a table" />
                    </div>
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Dessert 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Homemade Ice Cream</div>
                    <div className="badgePageItemEvidence">
                        <p>I made my own ice cream for the July Cup Sundae challenge.</p>
                        <p>Recipe: https://www.theguardian.com/food/2021/jul/28/how-to-make-the-perfect-vegan-coconut-ice-cream-recipe</p>
                        <img className="imgQuarter" src={IceCream} alt="Close up of a coconut sundae served in a bowl made from the base of a pineapple" />
                    </div>
                </div>
            </div>          
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Dessert 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Something with Jelly</div>
                    <div className="badgePageItemEvidence">
                        <p>For this, I made Lime Fruit Dessert. This is the one that my family seemed to enjoy the most!</p>
                        <p>Recipe: https://everydaycooks.co.uk/lime-fruit-dessert/</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Jelly1} alt="Canned fruit salad being strained"/></div>
                            <div className="galleryItem"><img src={Jelly2} alt="Jelly cubes in a glass jug ready for dissolving"/></div>
                            <div className="galleryItem"><img src={Jelly3} alt="Dissolved jelly cubes in a glass jug"/></div>
                            <div className="galleryItem"><img src={Jelly4} alt="Partially set lime jelly in a mixing bowl"/></div>
                            <div className="galleryItem"><img src={Jelly5} alt="Lime Jelly and Double Cream mixed together in a bowl"/></div>
                            <div className="galleryItem"><img src={Jelly6} alt="Lime Jelly and Double Cream mixed together in a bowl with strained fruit salad mixed in"/></div>
                            <div className="galleryItem"><img src={Jelly7} alt="Lime Fruit Desert, set in a ramekin ready for serving"/></div>
                        </div>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Dessert 4</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">A mousse</div>
                    <div className="badgePageItemEvidence">
                        <p>For this clause I made a Creamy Frozen Raspberry Mousse. I think this one was my favourite of the deserts.</p>
                        <p>Recipe: https://www.womansday.com/food-recipes/food-drinks/a22728255/creamy-frozen-raspberry-mousse-recipe/</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Mousse1} alt="Quark being measured into a jug"/></div>
                            <div className="galleryItem"><img src={Mousse2} alt="Quark, confectioners sugar, and a bottle of lime juice"/></div>
                            <div className="galleryItem"><img src={Mousse3} alt="Quark, confectioners sugar, and a bottle of lime juice with frozen raspberries being weighed on an electronic scale"/></div>
                            <div className="galleryItem"><img src={Mousse4} alt="Creamy Frozen Raspberry Mousse in a glass ready for serving"/></div>
                        </div>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Dessert 5</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Something Containing Caramel</div>
                    <div className="badgePageItemEvidence">
                        <p>For this, I made Apple Dumplings with Browned Butter Salted Caramel Sauce.</p>
                        <p>Recipe: https://wholeandheavenlyoven.com/2020/11/05/apple-dumplings-with-browned-butter-salted-caramel-sauce/</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Dumpling1} alt="Two balls of pastry dough in a mixing bowl"/></div>
                            <div className="galleryItem"><img src={Dumpling2} alt="Two red apples that have had their cores removed"/></div>
                            <div className="galleryItem"><img src={Dumpling3} alt="Sugar and spices melted and mixed together in a small bowl with a silver teaspoon"/></div>
                            <div className="galleryItem"><img src={Dumpling4} alt="A cored apple on rolled out pastry dough with a spice mix poured into the empty core"/></div>
                            <div className="galleryItem"><img src={Dumpling5} alt="Two apples wrapper in pastry dough in a glass dish reday for baking"/></div>
                            <div className="galleryItem"><img src={Dumpling6} alt="Melted browned butter in a small glass dish"/></div>
                            <div className="galleryItem"><img src={Dumpling7} alt="Sugar in a pan with a wooden spoon"/></div>
                            <div className="galleryItem"><img src={Dumpling8} alt="Melted sugar bubbling in a pan on a gas hob"/></div>
                            <div className="galleryItem"><img src={Dumpling9} alt="Melted sugar being poured over two pastry covered apples in a glass dish"/></div>
                            <div className="galleryItem"><img src={Dumpling10} alt="Two pastry covered apples with caramel poured over the top in a glass dish ready for baking"/></div>
                            <div className="galleryItem"><img src={Dumpling11} alt="A baked pastry covered apple with melted spices and caramel on a plate ready for serving"/></div>
                        </div>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Dessert 6</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">A foreign dessert</div>
                    <div className="badgePageItemEvidence">
                        <p>For this clause, I made Pacoca - a Brazilian Peanut Candy. I absolutely love this!</p>
                        <p>Recipe: https://www.tasteofhome.com/recipes/pacoca/</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Candy1} alt="A packet of digestive biscuits, a packet of dry roasted peanuts and a tin of condensed milk on a baking tray"/></div>
                            <div className="galleryItem"><img src={Candy2} alt="Biscuits and peanuts blended together in a food processor"/></div>
                            <div className="galleryItem"><img src={Candy3} alt="Condensed milk added to a blended biscuit and peanut mixture in a food processor"/></div>
                            <div className="galleryItem"><img src={Candy4} alt="Pacoca candy cut and ready for eating"/></div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default Confectioner