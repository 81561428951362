import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const Builder = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"Builder"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Project 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Project 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div>          
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Project 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div> 
             <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Project 4</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div> 
        </div>
    )
}

export default Builder