import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Cake1 from '../images/cup-apr24-cakes1.jpg';
import Cake2 from '../images/cup-apr24-cakes2.jpg';
import Cake3 from '../images/cup-apr24-cakes3.jpg';
import Cake4 from '../images/cup-apr24-cakes4.jpg';
import Cake5 from '../images/cup-apr24-cakes5.jpg';
import Cake6 from '../images/cup-apr24-cakes6.jpg';


const CupApr24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Rebel Cup Bonus Points"} />
            <div className="badgePageItem">Rebel Cup challenges are only posted in the facebook group for confirmed members. You will have to join the club to find out why I am doing these things!</div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Bonus 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light">A flock of sheep</div>
                    <div className="badgePageItemEvidence"> 
                        <p>Here is my flock of rainbow sheep!</p>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Cake1} alt="Raw cupcake mixture in a bun tin"/></div>
                            <div className="galleryItem"><img src={Cake2} alt="Cooked cupcakes in a bun tin"/></div>
                            <div className="galleryItem"><img src={Cake3} alt="Marshmallows, sugar eyes and icing sugar ready for decoratin cupcakes"/></div>
                            <div className="galleryItem"><img src={Cake4} alt="Cupcakes that look like sheep"/></div>
                            <div className="galleryItem"><img src={Cake5} alt="Food colouring being used to colour marshmallows"/></div>
                            <div className="galleryItem"><img src={Cake6} alt="Cupcakes that look like rainbow coloured sheep"/></div>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    )
}

export default CupApr24