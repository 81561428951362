import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Journal1 from '../images/journal1.png';

const Diarist = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Diarist"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Keep a regular diary</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                     <div className="badgePageItemInfo reverse hidden">Over a period of six months,write a journal at least four times per week. You can decide whether you keep a gratitude journal, a bullet journal, a 'one line a day' diary or a more detailed account.</div>
                    <div className="badgePageItemEvidence">
                        I'm not going to lie, I have a penchant for stationery, it's been a problem since I was a kid.
                        <br/>
                        On a day out to Chalice Well in Glastonbury, I found this amazing sparkly notebook and because I must absolutely buy anything that sparkles (usually regardless of whether I will use it or not) I brought it home!
                        <br/><br/>
                        <img src={Journal1} alt="A gold sparkly notebook captioned Mercury Retrograde Made Me Do It" className="galleryItem" />
                        In the absence of any other purpose for a notebook, it is now my Rebel journal where I am tracking various things as well as recording one liners per day about my day, my work day and gratitude.
                        <br/><br/>
                        I really suck at journals, I've never kept one for long, definitely not in adulthood, but I'll keep it going as long as I can. So far, so good!
                        <br/><br/>
                        <p><strong>Update: 30/11/2023</strong></p>
                        <p>Doh! It didn't last! :(  </p><br/>
                        <p>That was back in 2022! I stopped recording, I just don't have enough to say to keep a diary. In fact, I obviously did so badly that apparently I even removed it from my Book 1 page!!</p>
                        <br/><p>However, I decided to make another push, so from May 2023 I started again. This time, as the clause specifies a minimum times per week to make an entry, I decided 4 would be a good number - 1 on a Thursday after work to sum up my working week, then 1 for each day of the weekend. I used my OneNote app instead of a physical journal this time.</p>
                        <br/><p>For some reason I managed to miss June entirely, but the good news is ..... I started again in July and as of the end of November I have done it!!!!</p>
                        <br/><p>There's a good chance I won't keep this up, but you never know.</p>
                        <br/>
                        <p>I am not sharing the contents of my diary here, but I'm quite proud of my 6 months worth of notes :)</p>
                        <br/>
                        <p>(p.s. don't worry, the sparkly notebook will be re-purposed!)</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Diarist