import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Helper1 from '../images/EmergencyHelper1.jpg';
import Helper2 from '../images/EmergencyHelper2.jpg';
import Helper3 from '../images/EmergencyHelper3.jpg';
import Helper4 from '../images/EmergencyHelper4.jpg';
import Helper5 from '../images/EmergencyHelper5.jpg';

const EmergencyHelper = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Emergency Helper"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">01</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">First Aid</div>
                    <div className="badgePageItemInfo reverse hidden">Complete at least a 2 day first aid qualification which includes CPR, se of an AED, choking, anaphylaxis, severe bleeding and strokes.</div>
                    <div className="badgePageItemEvidence">
                        <p>This is the first of three clauses that I am choosing to backdate, as I covered all of this when I received my lifesaving qualification.</p>
                        <br/>
                        <p>As a concession to keeping up to date and in the spirit of badge work, I have topped this up with some theory to ensure I stay on top of things.</p>
                        <br/>
                        <p>I have used the following resources:</p>
                        <ul className='list'>
                            <li>First Aid in the Workplace Certificate - News Skills Academy</li>
                            <li>How to perform CPR and use an AED - First Aid For Life</li>
                            <li>Safety considerations when using a defibrillator AED - - First Aid For Free</li>
                            <li>Online automated External Defibrillator AED Course - First Aid For Free</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">02</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">50m swim</div>
                    <div className="badgePageItemInfo reverse hidden">Swim 50 metres fully clothed and demonstrate how to tow another person to safety in deep water.</div>
                    <div className="badgePageItemEvidence">
                        <p>This is the second of three clauses that I am choosing to backdate, as I was assessed for this as part of a lifesaving qualification.</p>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">03</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Rescue</div>
                    <div className="badgePageItemInfo reverse-hidden">Demonstrate how to rescue someone who is drowning without entering the water.</div>
                    <div className="badgePageItemEvidence">
                        <p>This is the third of three clauses that I am choosing to backdate, as I was assessed for this as part of a lifesaving qualification.</p>
                        <p>The theory is this:</p>
                        <br/>
                        <ol className="list">
                            <li>Reach
                                <ul className="list">
                                    <li>Grab a stick, pole, or any long object from the poolside, dock, boat, or platform.</li>
                                    <li>Extend it toward the person in trouble. If they can reach out to the stick, they might be able to hold on.</li>
                                    <li>Avoid getting too close to the water; maintain a safe distance.</li>
                                </ul>
                            </li>
                            <li>Throw
                                <ul className="list">
                                    <li>If the stick won't reach or the person doesn't notice it, throw something buoyant.</li>
                                    <li>Use anything that floats—such as a lifebuoy, ring, or even a piece of clothing.</li>
                                    <li>Aim for the person to grab onto the floating object.</li>
                                </ul>
                            </li>
                            <li>Wade or Row
                                <ul className="list">
                                    <li>If you're near shallow water, wade in carefully.</li>
                                    <li>Use a boat or any available flotation device to reach the person.</li>
                                    <li>Always ensure someone else calls for help while you attempt the rescue.</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">04</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Submerged vehicles.</div>
                    <div className="badgePageItemInfo reverse hidden">Know how to escape a car which is being submerged.</div>
                    <div className="badgePageItemEvidence">
                        <p>Follow these steps:</p>
                        <ol className="list">
                            <li>Stay Calm:
                                <ul className="list">
                                    <li>Remain calm and avoid panic. Clear thinking is essential in this situation.</li>
                                </ul>
                            </li>
                            <li>Seatbelt and Windows:
                                <ul className="list">
                                    <li>Unbuckle your seatbelt immediately.</li>
                                    <li>If the car is still floating, roll down the windows or use any available means to open them. Electric windows should work for a while even after submersion.</li>
                                </ul>
                            </li>
                            <li>Exit Swiftly:
                                <ul className="list">
                                    <li>Do not wait for the car to fill up; act quickly.</li>
                                    <li>Swim to the surface as soon as possible. Push off the car and swim upward.</li>
                                    <li>Look for light and swim toward it. Alternatively, follow any bubbles you see—they will be going up.</li>
                                </ul>
                            </li>
                            <li>Help Passengers:
                                <ul className="list">
                                    <li>Assist passengers in escaping through windows or openings.</li>
                                    <li>Take your mobile phone if possible—it might still work and allow you to call for help.</li>
                                    <li>Any floating objects you can grab will aid you in the water.</li>
                                </ul>
                            </li>
                            <br/>
                            <p>Remember, speed is crucial. Don't hesitate; escape the submerged car promptly.</p>
                            <br/><br/>
                            <p>I also came across this twitter thread which was helpful:</p>
                            <div className='gallery'>
                                <div className="galleryItem"><img src={Helper1} alt="Screenshot of a Twitter thread" /></div>
                                <div className="galleryItem"><img src={Helper2} alt="Screenshot of a Twitter thread" /></div>
                                <div className="galleryItem"><img src={Helper3} alt="Screenshot of a Twitter thread" /></div>
                                <div className="galleryItem"><img src={Helper4} alt="Screenshot of a Twitter thread" /></div>
                                <div className="galleryItem"><img src={Helper5} alt="Screenshot of a Twitter thread" /></div>
                            </div>
                        </ol>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">05</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Clothing Fire</div>
                    <div className="badgePageItemInfo reverse hidden">Know how to deal with clothing which has caught fire.</div>
                    <div className="badgePageItemEvidence">
                        <p>STOP. DROP. ROLL</p>
                        <br/>
                        <ul className="list">
                            <li>Stop: Do not run or wave your arms. Movement can fan the flames and worsen burns. Suppress the urge to reach water or help; instead, stop right where you are.</li>
                            <li>Drop: Get on the ground quickly and cover your face with your hands. Lie flat with your legs extended to maximize contact with the ground and smother the flames. Covering your face helps prevent facial burns.</li>
                            <li>Roll: Roll over and over to smother the flames. Focus on the burning area of your body. The direction of rolling is determined by the “Stop” step. If possible, roll into a rug or thick, non-flammable material (like tent canvas) to aid in smothering the flames. Avoid thin blankets, sheets, or plastic, as they may catch fire accidentally. Others can assist by patting the fire with their hands or using a fire extinguisher. Once the fire is out, cool the affected area and treat any burns. If burns resulted from flaming clothing, call 911 for immediate medical attention.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">06</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Electrical Fire</div>
                    <div className="badgePageItemInfo reverse hidden">Know how to treat a fire which has been caused by electricity.</div>
                    <div className="badgePageItemEvidence">
                        <p>Follow the steps as below:</p>
                        <br/>
                        <ol className="list">
                            <li>Call Emergency Services:
                                <ul className="list">
                                    <li>If a fire is developing and you cannot turn off the electricity or if it's growing rapidly, call the fire department immediately.</li>
                                    <li>Firefighters are better equipped to handle fires involving live electricity.</li>
                                    <li>Inform them that you are dealing with an electrical fire.</li>
                                </ul>
                            </li>
                            <li>Ensure Safe Evacuation:
                                <ul className="list">
                                    <li>Before attempting to put out the fire yourself, ensure you have two paths to safety.</li>
                                    <li>If you can see two escape routes, it's reasonable to stay and fight the fire.</li>
                                    <li>If you have only one escape route, take it and let the professionals handle the fire.</li>
                                </ul>
                            </li>
                            <li>Evacuate If Necessary:
                                <ul className="list">
                                    <li>If you feel unsafe, an exit gets blocked, you get burned, or your fire-fighting techniques aren't effective, abandon your efforts.</li>
                                    <li>Prioritize your safety over belongings or buildings.</li>
                                    <li>Close doors behind you to contain the fire as much as possible.</li>
                                </ul>
                            </li>
                            <li>Disconnect the Electricity (if possible):
                                <ul className="list">
                                    <li>Unplug appliances that are on fire.</li>
                                    <li>Never use water on an electrical fire.</li>
                                    <li>Smother a small fire with baking soda if you can't disconnect the appliance or cord.</li>
                                </ul>
                            </li>
                            <li>First Aid for Electrical Burns:
                                <ul className="list">
                                    <li>If someone suffers an electrical burn, turn off the source of electricity if possible.</li>
                                    <li>Move the source away from both the injured person and yourself.</li>
                                    <li>Use a dry, nonconducting object made of cardboard, plastic, or wood.</li>
                                    <li>Begin CPR if the person is not breathing, coughing, or moving and doesn't have a pulse. Do not remove clothing or try to clean the burned area.</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">07</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Fat</div>
                    <div className="badgePageItemInfo reverse hidden">Know how to extinguish a burning pan of fat.</div>
                    <div className="badgePageItemEvidence">
                        <p>Turn Off the Heat: If it's safe to do so, turn off the heat source (such as the stove or burner) immediately. This prevents the fire from intensifying.</p>
                        <p>Cover the Pan: Use a lid or a fire blanket to cover the burning pan. This helps smother the flames and prevents oxygen from feeding the fire.</p>
                        <p>Avoid Water: Never use water to extinguish a fat fire. Water can cause the hot oil to splatter and spread the fire further. It might even lead to an explosive reaction.</p>
                        <p>Use Baking Soda or Salt: If you have baking soda or salt nearby, sprinkle it over the flames. These substances can help suppress the fire by reducing the heat.</p>
                        <p>Fire Extinguisher: If available, use a wet chemical fire extinguisher specifically designed for Class F fires (oil and grease fires). Wet chemical extinguishers are the only suitable type for dealing with fat fires. They work effectively by cooling and emulsifying the burning oil.</p>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">08</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Utilities</div>
                    <div className="badgePageItemInfo reverse hidden">Know how to switch off the gas, electricity and water mains in your home.</div>
                    <div className="badgePageItemEvidence">
                        <p>My electricity can be turned off using the kill switch in the circuit box located in the kitchen.</p>
                        <p>My Water can be turned off using the stop-cock under the kitchen sink.</p>
                        <p>My Gas can be turned of using the isolation valve near my gas meter.</p>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">09</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">First responder</div>
                    <div className="badgePageItemInfo reverse hidden">Know how to act if you are the first to the scene of a car accident.</div>
                    <div className="badgePageItemEvidence">
                        <p>Follow these steps:</p>
                        <ol className="list">
                            <li>Identify and Mitigate Dangers:
                                <ul className="list">
                                    <li>Assess the situation and ensure your safety.</li>
                                    <li>Check for hazards that could endanger you, the casualty, or others nearby.</li>
                                    <li>For example, if it's a road accident, try to block traffic. If machinery is involved, switch it off.</li>
                                </ul>
                            </li>
                            <li>Call for Help:
                                <ul className="list">
                                    <li>Do not handle the situation alone.</li>
                                    <li>Call emergency services (dial 999). One person can provide first aid while another manages traffic and makes the call.</li>
                                    <li>Assess casualties' conditions before leaving the scene.</li>
                                </ul>
                            </li>
                            <li>Check for a Response:
                                <ul className="list">
                                    <li>Talk loudly and clearly to the casualty to check for responsiveness.</li>
                                    <li>If no response, gently shake their shoulders or pinch their earlobe (if severe injury is suspected).</li>
                                    <li>Prioritize unresponsive casualties if there are multiple.</li>
                                </ul>
                            </li>
                            <li>Check the Casualty's Airway:
                                <ul className="list">
                                    <li>If responsive, help them clear their airway.</li>
                                    <li>If unresponsive, position their head to open the airway.</li>
                                    <li>Place your hand on their forehead and gently tilt their head back.</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">10</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">First Aid Kits</div>
                    <div className="badgePageItemInfo reverse hidden">Create first aid kits for your home and car, and consider carrying a small basic kit for your handbag or backpack. What other items might be useful in case of emergency?</div>
                    <div className="badgePageItemEvidence">
                        <p>I have the following first aid kits:</p>
                        <ul className='list'>
                            <li>A main kit in my bathroom</li>
                            <li>A mini kit that stays in my beach clean bag and goes into my handbag as required</li>
                            <li>A full first aid kit for beach clean events</li>
                        </ul>
                        <p>I do not have a car!</p>
                    </div>
                </div>
            </div>            
        </div>
    )
}

export default EmergencyHelper