import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Project1a from '../images/moneysaver1.jpg';
import Project1b from '../images/moneysaver2.jpg';
import Project1c from '../images/moneysaver3.jpg';
import Project1d from '../images/moneysaver4.jpg';
import Project1e from '../images/moneysaver5.jpg';
import Project1f from '../images/moneysaver6.jpg';
import Project1g from '../images/moneysaver7.jpg';
import Project2a from '../images/moneysaver-lampholder1.jpg';
import Project2b from '../images/moneysaver-lampholder2.jpg';
import Project2c from '../images/moneysaver-lampholder3.jpg';
import Project2d from '../images/moneysaver-lampholder4.jpg';
import Project2e from '../images/moneysaver-lampholder5.jpg';
import Project3a from '../images/moneysaver-bulb1.jpg';
import Project3b from '../images/moneysaver-bulb2.jpg';
import Project3c from '../images/moneysaver-bulb3.jpg';
import Project3d from '../images/moneysaver-bulb4.jpg';
import Book from '../images/moneysaver-book1.jpg';

const MoneySaver = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Money Saver"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">01</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Aim to buy as little as possible</div>
                    <div className="badgePageItemEvidence">
                        It's hard to evidence this but I buy as little as possible anyway! I'm not one for spending money, so will only buy things I need.
                        <br/><br/>
                        I can't quantify what my saving would be because I don't have anything to cut down on.
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">02</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Review Utilities</div>
                    <div className="badgePageItemEvidence">
                        I have checked these recently and there is nothing to change at this time. 
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">03</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Research savings</div>
                    <div className="badgePageItemEvidence">
                        <p>Easy-access savings accounts give a lower rate of interest but allow you to deposit and withdraw whenevr you want to.</p>
                        <p>Easy-access ISAs allow you to earn up to £1000 interest per year tax-free. Rates are often lower than standard savings accounts.</p>
                        <p>Premium Bonds offer a tax-free savings option and you may win additional money!</p>
                        <p>Bank accounts pay higher rates than standard savings but have specific requirements, such as paying in fixed amounts per month</p>
                        <p>Regular savings accounts have some of the highest rates and you can pay in small amounts each month - most allow you to withdraw money whenever you need to.</p>
                        <p>Fixed-term savings - savings accounts or ISAs - have high rates, but your money is not accessible for the duration of the term.</p>
                        <p>Notice accounts are less flexible than savings accounts but more flexible than fixed-term savings as you can withdraw money with advance notice. Rates are variable so you'd still have to wait the notice period before making a withdrawal.</p>
                        <br/><br/>
                        <p>Having previously been in a lot of debt, I was able to clear everything I owed by moving money around from loans to interest free credit card deals. I ended up paying a lot of money across several cards but saved myself huge interest charges.</p>
                        <br/>
                        <p>I'm not going to put any information on here about my finances, savings or pensions so you'll have ot trust that the rest of this clause is complete!</p>
                        <br/><br/>
                        <p>Source: https://www.moneysavingexpert.com/savings/which-saving-account/</p>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">04</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Save money in every day life</div>
                    <div className="badgePageItemEvidence">
                        This is difficult to evidence as well because I already minimise what I spend.
                        <br/>
                        <ul className="list">
                            <li>I don't drive and don't use public transport so I save money on transport</li>
                            <li>I hand-make when I can</li>
                            <li>My food is generally made from scratch now</li>
                            <li>I have cancelled all the unnecessary outgoings that I had, there is nothing else to trim at this time, although some are on "probation" - i.e. if I don't use them enough in the next 6 months they will be gone, or I'm finishing a series / course then the subscription will be cancelled.</li>
                            <li>If I do need or want to buy something I buy second hand where possible, alternatively I wait for offers and sales to minimise my spend.</li>
                            <li>Switching away from meat saves money on shopping, vegetables are much cheaper!!</li>
                            <li>Switching to plastic-free alternatives and making my own products also saves money in the long term - after the initial cost.</li>
                            <li>Using natural remedies instead of pharmaceuticals is cheaper - honey and lemon are cheaper than lemsip for example</li>
                            <li>I don't like indoor spaces at the moment so I'm saving a lot of money on tickets, travel and hotels!!</li>
                            <li>Making the choice to stay at home and be occupied there and go out to walk or to the family only saves money on activities</li>
                            <li>If I do go out for a day I take my own food and drink, that cuts down cafe and takeaway costs</li>
                            <li>As I don't go far, I have more than enough clothes so I save on shoes and clothes, just buying a replacement pair of jeans or trainers occassionally!</li>
                            <li>I work from home full time so I have no costs for travel or for work clothes / equipment</li>
                            <li>Christmas is capped in our family, the adults do secret santa so each adult only has to buy for one other and we all get one gift, the price is capped at £20. The kids are excluded from this.</li>
                            <li>I save gas and electric by only running the boiler for an hour or two a day to boost the hot water and heating</li>
                            <li>I save water costs by not washing up constantly and by not flushing every time (I only do this if it's just me in the house). I only do washing once a week.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">05</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Fix items you would usually throw away</div>
                    <div className="badgePageItemEvidence">
                        <strong>Project 1</strong>
                        <br/><br/>
                        My purse broke!! The zip wouldn't fasten any more. Usually I would throw it out and buy a new one, but I took the time to take it apart and repair it instead.
                        <br/><br/>
                        I peeled it away, removed the broken zip and ordered a replacement. I glued the zip into place then hand-sewed the purse back together (that was hard work!)
                        <br/><br/>
                        Net cost was a lot less than replacing it like-for-like.
                        <br/><br/>
                        <div className="moneyGallery">
                            <div className="moneyItem"><img src={Project1a} alt="Purse that has been taken apart" width="400px" height="400px" /></div>
                            <div className="moneyItem"><img src={Project1b} alt="Purse that has been taken apart" width="400px" height="400px" /></div>
                            <div className="moneyItem"><img src={Project1c} alt="Purse that has been taken apart" width="400px" height="400px" /></div>
                            <div className="moneyItem"><img src={Project1d} alt="Purse that is being stuck back together" width="400px" height="400px" /></div>
                            <div className="moneyItem"><img src={Project1e} alt="Purse being hand-stitched for repair" width="400px" height="400px" /></div>
                            <div className="moneyItem"><img src={Project1f} alt="Completed Purse Repair with zip open" width="400px" height="400px" /></div>
                            <div className="moneyItem"><img src={Project1g} alt="Completed Purse Repair with zip done up" width="400px" height="400px" /></div>
                        </div>
                    </div>
                    <br/><br/>
                    <div className="badgePageItemEvidence">
                        <strong>Project 2</strong>
                        <br/><br/>
                        I have an outdoor light in a shed outside the house, but a while ago the fitting broke and it wouldn't hold a bulb anymore. Usually, I would throw this away and buy a new one, but instead I bought a new lamp holder and fitted it.
                        <br/><br/>
                        <div className="moneyGallery">
                            <div className="moneyItem"><img src={Project2a} alt="Broken lamp holder" width="400px" height="400px" /></div>
                            <div className="moneyItem"><img src={Project2b} alt="Parts and tools ready to replace a broken lamp holder" width="400px" height="400px" /></div>
                            <div className="moneyItem"><img src={Project2c} alt="Re-wired lamp holder" width="400px" height="400px" /></div>
                            <div className="moneyItem"><img src={Project2d} alt="Repaired lamp holder" width="400px" height="400px" /></div>
                            <div className="moneyItem"><img src={Project2e} alt="Repaired lamp holder" width="400px" height="400px" /></div>
                        </div>
                    </div>
                    <br/><br/>
                    <div className="badgePageItemEvidence">
                        <strong>Project 3</strong>
                        <br/><br/>
                        For this project, I looked at repairing the bulb on Mum's sewing machine. The bulb had blown and replacing the new bulb wasn't working - the bulb wouldn't go in a stay in place. I removed the front cover of the machine and then the top of the bulb holder. Eventually, I managed to get the bulb in position and fixed.
                        <br/><br/>
                        This wasn't something that would necessarily have been thrown out, but it was a useful and necessary repair.
                        <br/><br/>
                        <div className="moneyGallery">
                            <div className="moneyItem"><img src={Project3a} alt="Sewing machine with the front fittings removed" width="400px" height="400px" /></div>
                            <div className="moneyItem"><img src={Project3b} alt="Sewing machine lamp holder" width="400px" height="400px" /></div>
                            <div className="moneyItem"><img src={Project3c} alt="Sewing machine with fixed bulb holderf and new bulb in place" width="400px" height="400px" /></div>
                            <div className="moneyItem"><img src={Project3d} alt="Sewing machine with sections re-attached" width="400px" height="400px" /></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">06</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Educate yourself</div>
                    <div className="badgePageItemEvidence">
                        <p>To meet the criteria for this clause, I have done the following:</p>
                        <br/>
                        <ul className="list">
                            <li>Completed the Introduction to Bitcoin, Blockchain and Cryptocurrencies Diploma course with Centre of Excellence.</li>
                            <li>Read the book "Investing for Dummies" which was kindly gifted to me by my Dad a few years ago and I never got round to reading!</li>
                        </ul>
                        <br/>
                        <img className="imgThird" src={Book} alt="Investing for Dummies book." />
                        <br/><br/>
                        <p>In addition, I have been looking at the GBP exchange rate trends and forecasts.</p>
                        <br/>
                        <p>During 2022, the GBP was among the worst-performing currencies and fell to a record low against the USD in September. This was driven by political instability, the Ukraine/Russia war, the energy crisis and double-digit inflation.</p>
                        <br/>
                        <p>GBP also lost against the Euro in 2022, down 5%</p>
                        <br/>
                        <p>The forecast at the moment is that the pound will continue to lose against the dollar and 2023 looks to remain unstable.</p>
                        <br/>
                        <p>Sources:</p>
                        <ul className="list">
                            <li>https://capex.com/en/overview/british-pound-gbp-price-prediction</li>
                            <li>https://www.exchangerates.org.uk/news/tag/pound-sterling.html</li>
                            <li>https://www.google.com/finance/markets/currencies</li>
                            <li>https://www.dailyfx.com/news/british-pound-week-ahead-gbp-usd-eur-gbp-and-gbp-jpy-outlooks-20230602.html</li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default MoneySaver