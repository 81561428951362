import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Experiment1 from '../images/florist-experiment1.jpg';
import Experiment2 from '../images/florist-experiment2.jpg';
import Practice1 from '../images/florist-practice1.jpg';
import Practice2 from '../images/florist-practice2.jpg';
import Practice3 from '../images/florist-practice3.jpg';
import Practice4 from '../images/florist-practice4.jpg';
import Practice5 from '../images/florist-practice5.jpg';
import Practice6 from '../images/florist-practice6.jpg';
import Practice7 from '../images/florist-practice7.jpg';
import Practice8 from '../images/florist-practice8.jpg';
import Practice9 from '../images/florist-practice9.jpg';
import Practice10 from '../images/florist-practice10.jpg';
import Practice11 from '../images/florist-practice11.jpg';
import Practice12 from '../images/florist-practice12.jpg';
import Practice13 from '../images/florist-practice13.jpg';
import Practice14 from '../images/florist-practice14.jpg';
import Practice15 from '../images/florist-practice15.jpg';
import Practice16 from '../images/florist-practice16.jpg';
import Practice17 from '../images/florist-practice17.jpg';
import Practice18 from '../images/florist-practice18.jpg';
import Practice19 from '../images/florist-practice19.jpg';
import Practice20 from '../images/florist-practice20.jpg';
import Practice22 from '../images/florist-practice22.jpg';
import Practice23 from '../images/florist-practice23.jpg';
import Practice24 from '../images/florist-practice24.jpg';
import Practice25 from '../images/florist-practice25.jpg';
import Practice26 from '../images/florist-practice26.jpg';
import Practice27 from '../images/florist-practice27.jpg';
import Practice28 from '../images/florist-practice28.jpg';
import Practice29 from '../images/florist-practice29.jpg';
import Practice30 from '../images/florist-practice30.jpg';
import Practice31 from '../images/florist-practice31.jpg';
import Practice32 from '../images/florist-practice32.jpg';
import Practice33 from '../images/florist-practice33.jpg';
import Practice34 from '../images/florist-practice34.jpg';
import Practice35 from '../images/florist-practice35.jpg';
import Practice36 from '../images/florist-practice36.jpg';
import Practice37 from '../images/florist-practice37.jpg';
import Practice38 from '../images/florist-practice38.jpg';
import Practice39 from '../images/florist-practice39.jpg';
import Practice40 from '../images/florist-practice40.jpg';
import Practice41 from '../images/florist-practice41.jpg';
import Practice42 from '../images/florist-practice42.jpg';
import Final1 from '../images/florist-final1.jpg';
import Final2 from '../images/florist-final2.jpg';
import Final1U1 from '../images/florist-final1-update1.jpg';
import Final1U2 from '../images/florist-final1-update2.jpg';
import Final2U1 from '../images/florist-final2-update1.jpg';
import Final2U2 from '../images/florist-final2-update2.jpg';
import Final3U1 from '../images/florist-final3-update1.jpg';

const Florist = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Florist"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">01 Tools</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>In my florist's toolkit:</p>
                        <ul className="list">
                            <li>Floral Clippers - for cleanly trimming stems. Keeping cuts clean helps flowers absorb water better to keep the fresher for longer.</li>
                            <li>Floral Tape - for securing stems and adding support and stability to arrangements</li>
                            <li>Water tubes - keep individual stems hydrated - can be useful while transporting</li>
                            <li>Pin Frogs - bases with spikes that hold flowers in place - used in shallow arrangements</li>
                            <li>Chicken wire - Allows better positioning of stems in a vase, provides structure and support</li>
                            <li>Wire Cutters - for working with floral wire</li>
                            <li>Floral Wire - used for reinforcing stems or binding things together. Can also be used to create decorative elements. Comes in different gauges.</li>
                            <li>Pruning Snips - for more delicate work like removing leaves or thorns</li>
                            <li>Oasis - used as a base for arrangements, can be dry or wet. Dry for dried flowers, wet is used to keep fresh flowers hydrated and fresher for longer. Comes in many shapes and size, some bases come with oasis pre-installed.</li>
                            <li>Knife - useful for cutting thick stems and very woody branches. Allows for a clean cut without damaging the stem.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">02 Principles</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        <ul className="list">
                            <li>Balance - Either symmetrical which provides a formal, impressive look or asymmetrical which is more informal and stylish whilst maintaining a sense of balance visually.</li>
                            <li>Proportion - the size relationship between the elements of the design. Proprotion should take into account both the size of the arrangement and the size of its surroundings.</li>
                            <li>Dominance - The focal point. Usually a large flower or bright colour. Breaks the horizontal line of the container and provides stability.</li>
                            <li>Rhythm / Movement - The flow and movement of the arrangement, guides the viewer's gaze along the design. Repeats certain elements or creates a sense of movement.</li>
                            <li>Harmony - ensures all elements work together and complement each other. Relates to colours, shapes and textures.</li>
                            <li>Unity - Brings all the elements together as a cohesive whole.  Created by consistent colour schemes, similar forms and complementary materials.</li>
                            <li>Contrast - Use different elements to create a  visual impact. Colours, shapes and textures are used to provide contrast. The balance between harmony and contrast is key.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">03 Position</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        <p><strong>Floral Foam</strong></p>
                        <br/>
                        <p>Floral foam or Oasis forms the base or foundation for arrangements. It's main functions are:</p>
                        <ul className="list">
                            <li>Stem Support - holds stems securely in place</li>
                            <li>Water Retention - absorbs and retains water to keep flowers hydrated and fresh</li>
                            <li>Design flexibility - can be cut into many different shapes to accommodate different styles of arrangement and containers</li>
                        </ul>
                        <p>Usage:</p>
                        <ul className="list">
                            <li>Cutting and Shaping - can be cut to fit containers</li>
                            <li>Soaking -  can be sokaed prior to use in a mixture of water and flower food. Should be allowed to absorb moisure, not immersed in water.</li>
                            <li>Securing - provides stability and support</li>
                        </ul>
                        <br/><br/>
                        <p><strong>Floral Tape</strong></p>
                        <br/>
                        <p>Purpose:</p>
                        <ul className='list'>
                            <li>Binding stems - used to wrap stems after binding with wire</li>
                            <li>Corsages and Boutonnieres - essential for assembling smaller arrangements, can be used to wrap stems to secure them as well as securing ribbon or other elements</li>
                            <li>Budling - can be used to bundle stems together</li>
                            <li>Camouflage - used to conceal wire and other mechanics in designs</li>
                            <li>Grids - can be used with netting or foam to create a grid for holding flowers in place</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">04 Preserve</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        <ul className="list">
                            <li>Flower Preservatives - commercial products for cut flowers that are sold at florists, garden centres and supermarkets:
                                <ul>
                                    <li>Sugar - provides energy</li>
                                    <li>Acid - stabilises colour and adjusts the pH of the water</li>
                                    <li>Biocide - kills bacteria and fungi</li>
                                </ul>
                            </li>
                            <li>Bleach solution - capful of bleach in a vase acts as an anti-bacterial agent which keeps the water cleans and prevents microbial growth</li>
                            <li>Citrus Soda (Non-diet Lemon or Lime) - a small amount in the water helps to nourish the flowers and keeps the water fresh.</li>
                            <li>Sugar Water - tablespoon of sugar in vase water, provides nutrients and encourages blooms to last longer</li>
                            <li>Vinegar and Sugar Mixture - vinegar fights  bacteria, sugar acts as food. Only use small amounts to prevent over-acidification</li>
                            <li>Silica Gel or Fine Sand - used to dry flowers. Removes moisture and preserves shape and colour</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">05 Vases</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        <ul className="list">
                            <li>Tall Cylinder Glass Vases - supports tall stems like roses, lilies or delphiniums</li>
                            <li>Narrow-Necked Glass vases - hold individual flowers in place, elegant and mimnimalist. Best for single stems or small bouquets</li>
                            <li>Fish Bowl Vases - Support flowers that rest on water surface, like hydrangeas, lotus or faloating candles</li>
                            <li>Square Glass Vases - complement modern and geometric designs, use bold flowers like tulips, gerbera or orchids</li>
                            <li>Rectangular Glass Vases - elongated shape allows for creative designs, use for linear arrangements and designs that are foliage-heavy</li>
                            <li>Square Tank Glass Vases - easy access for maintaining small plants, best for terrarium styles, succulents and air plants</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">06 Shape</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                    <p>For my first couple of arrangements I went with two fairly classic shapes.</p>
                    <br/>
                    <p>Experiment one was a low, wide, triangular display in a rectangular container.</p>
                    <br/>
                    <p>Experiment two was a tall, narrow, triangular display in an oval bucket container.</p>
                    <br/>
                    <p>Here they are when I finished them and in place at home.</p>
                    <img className="imgHalf" src={Experiment1} alt="Two flower arrangements side by side" />
                    <img className="imgHalf" src={Experiment2} alt="Two flower arrangements side by side" />
                    <br/>
                    <p>The next shape I attempted was a heart</p>
                    <img className="imgHalf" src={Practice15} alt="Completed heart shaped flower decoration using white roses and greenery"/>
                    </div>
                    <br/>
                    <p>I went for something completely different for my next experiment, using an asymetrical "L" shape design with dried flowers and salvaged driftwood.</p>
                    <img className="imgHalf" src={Practice22} alt="Completed floristry display of tall flowers displayed on a piece of tree bark"/>
                    <br/>
                    <p>Next up was a bit of a random one and probably didn't follow any specific pattern! It's a bit of a bowl shape with a tall backing - multi-layered. I'm not a fan of this one, but that's the purpose of experimenting!</p>
                    <img className="imgHalf" src={Practice25} alt="Flower arrangement" />
                    <br/>
                    <p>The next decorations were for my parents' 50th wedding anniversary, so Mum directed the styles and blooms. We had three that were symetrical, round designs and one rectangular design for the cake table.</p>
                    <img className="imgHalf" src={Practice26} alt="Flower arrangement" />
                    <img className="imgHalf" src={Practice27} alt="Flower arrangement" />
                    <br/>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">07 Practice</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>My first attempts were on 3/3/2023. My Mum has always enjoyed flower arranging and was taught by someone with professional expertise so I recruited her to teach me!! We purchased some oasis from a local flower shop and two bunches of flowers from Tesco. We then raided her garden for some greenery (and the neighbour's garden, but shhhhhh.....!!)</p>
                        <br/>
                        <p>She kindly provided me two containers after we had failed to find anything unusual that grabbed our attention during a round of the charity shops!</p>
                        <br/>
                        <p>I was concentrating so hard I forgot to take progress shots, but here are our containers and blooms.</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Practice1} alt="Flower arranging preparation"/></div>
                            <div className="galleryItem"><img src={Practice2} alt="Flower arranging preparation"/></div>
                            <div className="galleryItem"><img src={Practice3} alt="Flower arranging preparation"/></div>
                            <div className="galleryItem"><img src={Practice4} alt="Flower arranging preparation"/></div>
                            <div className="galleryItem"><img src={Practice5} alt="Flower arranging preparation"/></div>
                            <div className="galleryItem"><img src={Practice6} alt="Flower arranging preparation"/></div>
                            <div className="galleryItem"><img src={Practice7} alt="Flower arranging preparation"/></div>
                            <div className="galleryItem"><img src={Practice9} alt="Flower arranging preparation"/></div>
                            <div className="galleryItem"><img src={Practice10} alt="Flower arranging preparation"/></div>
                        </div>
                        <br/><br/>
                        <p>I decided to go rogue for mother's day and see if I could do something for Mum without her guidance! I found this sweet heart oasis on Amazon, bought some white roses from Tesco (she loves white flowers), raided my Pittosporum bush again and set to it!</p>
                        <br/>
                        <p>I'm not sure I'm ready to leave her tutelage just yet, but I don't think I did too badly!</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Practice11} alt="Heart shaped oasis ready for flower arranging"/></div>
                            <div className="galleryItem"><img src={Practice12} alt="Greenery being added to a heart shaped oasis"/></div>
                            <div className="galleryItem"><img src={Practice13} alt="Roses and leaves added to a heart shaped oasis"/></div>
                            <div className="galleryItem"><img src={Practice14} alt="Close up of a white rose"/></div>
                            <div className="galleryItem"><img src={Practice15} alt="Completed heart shaped flower decoration using white roses and greenery"/></div>
                        </div>
                        <br/><br/>
                        <p>For my next attempt, I wanted to use a gorgeous piece of bark that I had picked up at the beach. I wanted a tall, minimal design with a loose theme of sea spray. This particular practice uses fake flowers, very pretty though!</p>
                        <br/>
                        <p>Mum took me to a local flower supply shop and it's my new favourite place! We picked up some supplies and got down to it. I really like this one and it was all my design. The little piece of driftwood I also picked up on a beach walk.</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Practice16} alt="Oasis, florist tape and flower stems on a table"/></div>
                            <div className="galleryItem"><img src={Practice17} alt="Piece of driftwood placed on some tree bark"/></div>
                            <div className="galleryItem"><img src={Practice18} alt="Oasis and driftwood placed on some tree bark"/></div>
                            <div className="galleryItem"><img src={Practice19} alt="Oasis and driftwood placed on some tree bark"/></div>
                            <div className="galleryItem"><img src={Practice20} alt="Flowers added to oasis on tree bark"/></div>
                            <div className="galleryItem"><img src={Practice22} alt="Completed floristry display of tall flowers displayed on a piece of tree bark"/></div>
                            <div className="galleryItem"><img src={Practice23} alt="Close up of flowers at the base of a display made on a piece of tree bark"/></div>
                        </div>
                        <br/><br/>
                        <p>I wanted to use up the flowers from the last design, so I made this little one with a multi-layered design.</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Practice24} alt="Fake flowers displayed in a small round pot"/></div>
                            <div className="galleryItem"><img src={Practice25} alt="Fake flowers displayed in a small round pot"/></div>
                        </div>
                        <br/><br/>
                        <p>The next foray into arranging was for my parents' 50th wedding anniversary. We sourced flowers from local supermarkets again and foraged greenery from my garden and public spaces!</p>
                        <br/>
                        <p>We had three circular style designs for table centrepieces and one larger, rectangular design to sit behind the cake on the cake table.</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Practice28} alt="Orange roses and daisies in a vase"/></div>
                            <div className="galleryItem"><img src={Practice29} alt="Flowers in a vase on a garden table"/></div>
                            <div className="galleryItem"><img src={Practice30} alt="Oasis in small bases ready to take a flower arrangement"/></div>
                            <div className="galleryItem"><img src={Practice31} alt="Greenery in oasis"/></div>
                            <div className="galleryItem"><img src={Practice32} alt="Progress of a flower arrangement"/></div>
                            <div className="galleryItem"><img src={Practice33} alt="Progress of a flower arrangement"/></div>
                            <div className="galleryItem"><img src={Practice34} alt="Progress of a flower arrangement"/></div>
                            <div className="galleryItem"><img src={Practice35} alt="Progress of a flower arrangement"/></div>
                            <div className="galleryItem"><img src={Practice36} alt="Progress of a flower arrangement"/></div>
                            <div className="galleryItem"><img src={Practice26} alt="Progress of a flower arrangement"/></div>
                            <div className="galleryItem"><img src={Practice37} alt="Progress of a flower arrangement"/></div>
                            <div className="galleryItem"><img src={Practice26} alt="Progress of a flower arrangement"/></div>
                            <div className="galleryItem"><img src={Practice38} alt="Flower arrangements displayed on a bench"/></div>
                            <div className="galleryItem"><img src={Practice39} alt="Flower arrangements displayed on a bench"/></div>
                            <div className="galleryItem"><img src={Practice40} alt="Flower arrangement displayed on a table"/></div>
                            <div className="galleryItem"><img src={Practice41} alt="Flower arrangement displayed on a table"/></div>
                            <div className="galleryItem"><img src={Practice42} alt="Flower arrangement displayed on a table"/></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">08 Blooms</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>For my first two experiments, I purchased two bunches of flowers from Tesco. One was a selection of Alstroemeria (Mum's favourite!) and the other had pink, white and purple chrysanthemums.</p>
                        <br/>
                        <div className="imgThird"><img src={Practice8} alt="Flower arranging preparation"/></div>
                        <br/>
                        <p>I'm not sure what the greenery is  (Mum wasn't sure either!), one of them I know is Pittosporum, I'll try and identify the others!</p>
                        <br/>
                        <p>For my next experiment I used white roses, once again from Tesco. The greenery is pittosporum and the leaves from the roses.</p>
                        <br/>
                        <div className="galleryItem"><img src={Practice14} alt="Close up view of a white rose"/></div>
                        <br/>
                        <p>A selection of flowers from local supermarkets and greenery sourced from my garden and foraged from public spaces. Additional flowers can be seen in the finished arrangements as I didn't get photos of them all.</p>
                        <img className="imgHalf" src={Practice28} alt="Flowers in a bucket ready for arranging"/>
                        <img className="imgHalf" src={Practice29} alt="A selection of greenery in a vase ready for arranging"/>
                        <br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">09 Photograph</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Photograph progress</div>
                     <div className="badgePageItemInfo reverse hidden">Keep a record of all the different arrangements you make during the course of this badge.</div>
                    <div className="badgePageItemEvidence">
                        <div className="gallery">
                            <div className="galleryItem"><img src={Final1} alt="Flower arrangement" /></div>
                            <div className="galleryItem"><img src={Final1U1} alt="Flower arrangement" /></div>
                            <div className="galleryItem"><img src={Final1U2} alt="Flower arrangement" /></div>
                        </div>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Final2} alt="Flower arrangement" /></div>
                            <div className="galleryItem"><img src={Final2U1} alt="Flower arrangement" /></div>
                            <div className="galleryItem"><img src={Final2U2} alt="Flower arrangement" /></div>
                        </div>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Practice15} alt="Flower arrangement" /></div>
                        </div>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Final3U1} alt="Flower arrangement, tall display done on a piece of tree bark" /></div>
                        </div>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Practice25} alt="Flower arrangement" /></div>
                        </div>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Practice38} alt="Flower arrangement" /></div>
                            <div className="galleryItem"><img src={Practice27} alt="Flower arrangement" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Florist