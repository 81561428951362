import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const Write22 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Write Every Day"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Write Every Day - November 2022</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Write something every day.</div>
                    <div className="badgePageItemEvidence">
                        I saw this badge as my nemesis!! I like writing and have done it a lot over the years, but having to write something every day is not something I've had the discipline to do previously!
                        <br/><br/>
                        Novels and long form writing really weren't a viable option for me, so joining something like NaNoWriMo wasn't the way forward.
                        <br/><br/>
                        Most of what I have written previously is poetry or lyrics, prose, short form things.
                        <br/><br/>
                        However, I bought the badge as part of a three-month bundle because I wanted the Halloween and Christmas badges so I thought I should give it a go.
                        <br/><br/>
                        Looking at various prompt type apps, I came up with an idea!!
                        <br/><br/>
                        I downloaded an app called "Poetry Magnets" - it didn't require any registration and popped up one word a day as a writing prompt.
                        <br/><br/>
                        I then used that word to generate a poem ... but there's a catch (or two)
                        <br/><br/>
                        <ul className="listWrite">
                            <li>The poem should use the prompt word as a theme</li>
                            <li>Each letter starts a line of the poem, so read downwards the poem spells the prompt word</li>
                            <li>The last word of the poem should be the prompt word</li>
                        </ul>
                        <br/><br/>
                        That proved quite challenging but ..... I MADE IT!!!!
                        <br/><br/>
                        So, here we go, 30 days of random writing!
                        <br/><br/>
                        <table className="tblWrite">
                            <thead>
                                <tr>
                                    <th colspan="2">1st November 2022</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>E</td>
                                    <td>Ever since the stars turned cold</td>
                                </tr>
                                <tr>
                                    <td>M</td>
                                    <td>Memories hang frozen in time</td>
                                </tr>
                                <tr>
                                    <td>B</td>
                                    <td>Blood pumps through veins turned old</td>
                                </tr>
                                <tr>
                                    <td>R</td>
                                    <td>Rage is quiet as idle crime </td>
                                </tr>
                                <tr>
                                    <td>A</td>
                                    <td>Anger dies in the face of acceptance</td>
                                </tr>
                                <tr>
                                    <td>C</td>
                                    <td>Compassion leads the way to calm</td>
                                </tr>
                                <tr>
                                    <td>E</td>
                                    <td>Everything melts away from harm in a warm embrace</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <table className="tblWrite">
                            <thead>
                                <tr>
                                    <th colspan="2">2nd November 2022</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>C</td>
                                    <td>Cash for clicks these days</td>
                                </tr>
                                <tr>
                                    <td>U</td>
                                    <td>Usage stats driving activity</td>
                                </tr>
                                <tr>
                                    <td>R</td>
                                    <td>Rush to post words tactically</td>
                                </tr>
                                <tr>
                                    <td>R</td>
                                    <td>Reality manifests in whole new ways</td>
                                </tr>
                                <tr>
                                    <td>E</td>
                                    <td>Everyone defined by a follower count</td>
                                </tr>
                                <tr>
                                    <td>N</td>
                                    <td>No longer quality but amount</td>
                                </tr>
                                <tr>
                                    <td>C</td>
                                    <td>Chasing illusive popularity</td>
                                </tr>
                                <tr>
                                    <td>Y</td>
                                    <td>Your every move digital currency</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <table className="tblWrite">
                            <thead>
                                <tr>
                                    <th colspan="2">3rd November 2022</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>T</td>
                                    <td>Tops of fingers and tips of toes</td>
                                </tr>
                                <tr>
                                    <td>I</td>
                                    <td>Icy chills when cold winds blow</td>
                                </tr>
                                <tr>
                                    <td>N</td>
                                    <td>Noses red and start to run</td>
                                </tr>
                                <tr>
                                    <td>G</td>
                                    <td>Glowing bright in Winter sun</td>
                                </tr>
                                <tr>
                                    <td>L</td>
                                    <td>Light a fire with logs to kindle</td>
                                </tr>
                                <tr>
                                    <td>E</td>
                                    <td>Everything will start to tingle.</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <table className="tblWrite">
                            <thead>
                                <tr>
                                    <th colspan="2">4th November 2022</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>M</td>
                                    <td>Making marks, brushstrokes in plural</td>
                                </tr>
                                <tr>
                                    <td>U</td>
                                    <td>Up and down the movements flow</td>
                                </tr>
                                <tr>
                                    <td>R</td>
                                    <td>Rainbow palettes paint a show</td>
                                </tr>
                                <tr>
                                    <td>A</td>
                                    <td>A world imagined starts to grow</td>
                                </tr>
                                <tr>
                                    <td>L</td>
                                    <td>Loving work becomes a mural</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <table className="tblWrite">
                            <thead>
                                <tr>
                                    <th colspan="2">5th November 2022</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>G</td>
                                    <td>Going on adventures now and then</td>
                                </tr>
                                <tr>
                                    <td>O</td>
                                    <td>Out and about</td>
                                </tr>
                                <tr>
                                    <td>O</td>
                                    <td>Once in a while</td>
                                </tr>
                                <tr>
                                    <td>S</td>
                                    <td>Smiles and giggles spread again</td>
                                </tr>
                                <tr>
                                    <td>E</td>
                                    <td>Excitement starts to grow</td>
                                </tr>
                                <tr>
                                    <td>B</td>
                                    <td>Bubbling to the surface</td>
                                </tr>
                                <tr>
                                    <td>U</td>
                                    <td>Uplifting spirits </td>
                                </tr>
                                <tr>
                                    <td>M</td>
                                    <td>Magic begins to flow</td>
                                </tr>
                                <tr>
                                    <td>P</td>
                                    <td>Preparedness peaks and blood pumps</td>
                                </tr>
                                <tr>
                                    <td>S</td>
                                    <td>Starting to raise the tiny goosebumps</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <table className="tblWrite">
                            <thead>
                                <tr>
                                    <th colspan="2">6th November 2022</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>K</td>
                                    <td>Kindling romance, a new love on course</td>
                                </tr>
                                <tr>
                                    <td>I</td>
                                    <td>Intimate feelings spring into force</td>
                                </tr>
                                <tr>
                                    <td>S</td>
                                    <td>Sensuous moments of comfort and bliss</td>
                                </tr>
                                <tr>
                                    <td>S</td>
                                    <td>Sealed very soon by a hesitant kiss</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <table className="tblWrite">
                            <thead>
                                <tr>
                                    <th colspan="2">7th November 2022</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>I</td>
                                    <td>Information pops at the tip of a finger</td>
                                </tr>
                                <tr>
                                    <td>M</td>
                                    <td>Minds are focussed but do not linger</td>
                                </tr>
                                <tr>
                                    <td>A</td>
                                    <td>All things run by power of automation</td>
                                </tr>
                                <tr>
                                    <td>G</td>
                                    <td>Gratification by the power of information</td>
                                </tr>
                                <tr>
                                    <td>I</td>
                                    <td>Inside the hopes and dreams of generations</td>
                                </tr>
                                <tr>
                                    <td>N</td>
                                    <td>Now become real things that can happen</td>
                                </tr>
                                <tr>
                                    <td>E</td>
                                    <td>Everything possible that you can imagine</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <table className="tblWrite">
                            <thead>
                                <tr>
                                    <th colspan="2">8th November 2022</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>S</td>
                                    <td>Silent spaces give you thrills</td>
                                </tr>
                                <tr>
                                    <td>H</td>
                                    <td>Hairs are raised by sudden chills</td>
                                </tr>
                                <tr>
                                    <td>U</td>
                                    <td>Unlit rooms cause instant night</td>
                                </tr>
                                <tr>
                                    <td>D</td>
                                    <td>Darkened buildings can cause fright</td>
                                </tr>
                                <tr>
                                    <td>D</td>
                                    <td>Daylight stills and lamp lights gutter </td>
                                </tr>
                                <tr>
                                    <td>E</td>
                                    <td>Engines cease and start to judder</td>
                                </tr>
                                <tr>
                                    <td>R</td>
                                    <td>Reasons all to make you shudder</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <table className="tblWrite">
                            <thead>
                                <tr>
                                    <th colspan="2">9th November 2022</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>S</td>
                                    <td>Smells so good as it touches your lip</td>
                                </tr>
                                <tr>
                                    <td>I</td>
                                    <td>Inhale deeply while you lick a drip</td>
                                </tr>
                                <tr>
                                    <td>P</td>
                                    <td>Piquance peaks when you take a sip</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <table className="tblWrite">
                            <thead>
                                <tr>
                                    <th colspan="2">10th November 2022</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>W</td>
                                    <td>Waistlines grow and waistlines shrink</td>
                                </tr>
                                <tr>
                                    <td>E</td>
                                    <td>Every bite is gone in a blink</td>
                                </tr>
                                <tr>
                                    <td>I</td>
                                    <td>Inches come and inches go</td>
                                </tr>
                                <tr>
                                    <td>G</td>
                                    <td>Gain it fast and lose it slow</td>
                                </tr>
                                <tr>
                                    <td>H</td>
                                    <td>Have whatever you want on that plate</td>
                                </tr>
                                <tr>
                                    <td>T</td>
                                    <td>There's more to loving life than weight.</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <table className="tblWrite">
                            <thead>
                                <tr>
                                    <th colspan="2">11th November 2022</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>A</td>
                                    <td>Adrift in the ocean on a gathering storm</td>
                                </tr>
                                <tr>
                                    <td>N</td>
                                    <td>Night's drawing in and stars take their form</td>
                                </tr>
                                <tr>
                                    <td>C</td>
                                    <td>Calm water rising, the moon starts to shine</td>
                                </tr>
                                <tr>
                                    <td>H</td>
                                    <td>Harbour draws closer with safety in line</td>
                                </tr>
                                <tr>
                                    <td>O</td>
                                    <td>Onwards through swells where waves whip up anger</td>
                                </tr>
                                <tr>
                                    <td>R</td>
                                    <td>Resting now sheltered while safely at anchor</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <table className="tblWrite">
                            <thead>
                                <tr>
                                    <th colspan="2">12th November 2022</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>F</td>
                                    <td>Feelings and fears rise fast and then fly</td>
                                </tr>
                                <tr>
                                    <td>L</td>
                                    <td>Life's many moments are fearfully few</td>
                                </tr>
                                <tr>
                                    <td>E</td>
                                    <td>Every second goes past in the blink of an eye</td>
                                </tr>
                                <tr>
                                    <td>E</td>
                                    <td>Every day rises quickly, shiny and new</td>
                                </tr>
                                <tr>
                                    <td>T</td>
                                    <td>Time passes faster the greater your age</td>
                                </tr>
                                <tr>
                                    <td>I</td>
                                    <td>Into the void, your heart keeps on beating</td>
                                </tr>
                                <tr>
                                    <td>N</td>
                                    <td>Never to stop on the turn of a page</td>
                                </tr>
                                <tr>
                                    <td>G</td>
                                    <td>Grabbing the moments increasingly fleeting</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <table className="tblWrite">
                            <thead>
                                <tr>
                                    <th colspan="2">13th November 2022</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>B</td>
                                    <td>Boating and floating on slow waterways</td>
                                </tr>
                                <tr>
                                    <td>A</td>
                                    <td>Any time cruising goes by in a haze</td>
                                </tr>
                                <tr>
                                    <td>R</td>
                                    <td>Rest and recharge through the days you're afloat</td>
                                </tr>
                                <tr>
                                    <td>G</td>
                                    <td>Going through locks, passing craft small and large</td>
                                </tr>
                                <tr>
                                    <td>E</td>
                                    <td>Enjoying the slowness of life on a barge</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <table className="tblWrite">
                            <thead>
                                <tr>
                                    <th colspan="2">14th November 2022</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>A</td>
                                    <td>Age and longevity, a lifetime passes by</td>
                                </tr>
                                <tr>
                                    <td>N</td>
                                    <td>Nations rise and nations fall as civilisations die</td>
                                </tr>
                                <tr>
                                    <td>C</td>
                                    <td>Cities crumble and re-build as they reach towards the sky</td>
                                </tr>
                                <tr>
                                    <td>I</td>
                                    <td>Innovation, Population , Exploitation, Aspiration</td>
                                </tr>
                                <tr>
                                    <td>E</td>
                                    <td>Eons dwell in layers under years of deep cement</td>
                                </tr>
                                <tr>
                                    <td>N</td>
                                    <td>Nature hides old treasures to be found by just the patient</td>
                                </tr>
                                <tr>
                                    <td>T</td>
                                    <td>Things that rise to show us all the treasures of the ancient</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <table className="tblWrite">
                            <thead>
                                <tr>
                                    <th colspan="2">15th November 2022</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>C</td>
                                    <td>Creeping slowly close to the ground</td>
                                </tr>
                                <tr>
                                    <td>R</td>
                                    <td>Running water covering the sound</td>
                                </tr>
                                <tr>
                                    <td>A</td>
                                    <td>Anxiously avoiding being found</td>
                                </tr>
                                <tr>
                                    <td>W</td>
                                    <td>Walking on all fours, trying to be small</td>
                                </tr>
                                <tr>
                                    <td>L</td>
                                    <td>Learning the best way to leave is to crawl.</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <table className="tblWrite">
                            <thead>
                                <tr>
                                    <th colspan="2">16th November 2022</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>F</td>
                                    <td>Fancy china and dainty dolls</td>
                                </tr>
                                <tr>
                                    <td>I</td>
                                    <td>Intricate flowers and porcelain bowls</td>
                                </tr>
                                <tr>
                                    <td>G</td>
                                    <td>Gods and Goddesses inlaid with gold</td>
                                </tr>
                                <tr>
                                    <td>U</td>
                                    <td>Urns for unguents, ages old</td>
                                </tr>
                                <tr>
                                    <td>R</td>
                                    <td>Rose bowls and platters with fine painted scenes</td>
                                </tr>
                                <tr>
                                    <td>I</td>
                                    <td>Indistinct patterns where artwork has been</td>
                                </tr>
                                <tr>
                                    <td>N</td>
                                    <td>Neat little animals, glazed to a sheen</td>
                                </tr>
                                <tr>
                                    <td>E</td>
                                    <td>Elegance found in each figurine</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <table className="tblWrite">
                            <thead>
                                <tr>
                                    <th colspan="2">17th November 2022</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>D</td>
                                    <td>Dinner plates and pudding bowls</td>
                                </tr>
                                <tr>
                                    <td>I</td>
                                    <td>In plastic wrap and multi-rolls</td>
                                </tr>
                                <tr>
                                    <td>S</td>
                                    <td>Spoons and forks, knives and straws</td>
                                </tr>
                                <tr>
                                    <td>P</td>
                                    <td>Pens and packets shoved in draws</td>
                                </tr>
                                <tr>
                                    <td>O</td>
                                    <td>Objects made for single use</td>
                                </tr>
                                <tr>
                                    <td>S</td>
                                    <td>So many things are over-produced </td>
                                </tr>
                                <tr>
                                    <td>A</td>
                                    <td>All the things we use for convenience</td>
                                </tr>
                                <tr>
                                    <td>B</td>
                                    <td>Bring problems that are hard to conscience</td>
                                </tr>
                                <tr>
                                    <td>L</td>
                                    <td>Letting things change is not opposable</td>
                                </tr>
                                <tr>
                                    <td>E</td>
                                    <td>Everything is disposable</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <table className="tblWrite">
                            <thead>
                                <tr>
                                    <th colspan="2">18th November 2022</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>E</td>
                                    <td>Elan, flair, style and grace</td>
                                </tr>
                                <tr>
                                    <td>L</td>
                                    <td>Looking good in leather and lace</td>
                                </tr>
                                <tr>
                                    <td>E</td>
                                    <td>Ease in fashion, strutting a stage</td>
                                </tr>
                                <tr>
                                    <td>G</td>
                                    <td>Gracefulness held in posture for days</td>
                                </tr>
                                <tr>
                                    <td>A</td>
                                    <td>Artistic licence in sentiment</td>
                                </tr>
                                <tr>
                                    <td>N</td>
                                    <td>Nothing is quite as decadent</td>
                                </tr>
                                <tr>
                                    <td>T</td>
                                    <td>Transient and Elegant</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <table className="tblWrite">
                            <thead>
                                <tr>
                                    <th colspan="2">19th November 2022</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>S</td>
                                    <td>Stop for a moment and look all around you</td>
                                </tr>
                                <tr>
                                    <td>T</td>
                                    <td>Take time for a pause and appreciate the view</td>
                                </tr>
                                <tr>
                                    <td>R</td>
                                    <td>Roses and flowers, vast rolling hills</td>
                                </tr>
                                <tr>
                                    <td>I</td>
                                    <td>Indigo skies framed by giant windmills</td>
                                </tr>
                                <tr>
                                    <td>K</td>
                                    <td>Kingfishers diving in fast flowing streams</td>
                                </tr>
                                <tr>
                                    <td>I</td>
                                    <td>Insects and animals, old fashioned beams</td>
                                </tr>
                                <tr>
                                    <td>N</td>
                                    <td>Neap tides and spring tides, thunder and lightening</td>
                                </tr>
                                <tr>
                                    <td>G</td>
                                    <td>Glowing sunrises make everything striking.</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <table className="tblWrite">
                            <thead>
                                <tr>
                                    <th colspan="2">20th November 2022</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>B</td>
                                    <td>Bicycle bells help to clear paths ahead</td>
                                </tr>
                                <tr>
                                    <td>E</td>
                                    <td>Electronic alarms can make us stop dead</td>
                                </tr>
                                <tr>
                                    <td>E</td>
                                    <td>Emergency sirens will raise us from sleep</td>
                                </tr>
                                <tr>
                                    <td>P</td>
                                    <td>Panic devices raise hell with a beep</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <table className="tblWrite">
                            <thead>
                                <tr>
                                    <th colspan="2">21st November 2022</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>F</td>
                                    <td>Fun and friendships come and go</td>
                                </tr>
                                <tr>
                                    <td>O</td>
                                    <td>Over quicker than sand</td>
                                </tr>
                                <tr>
                                    <td>L</td>
                                    <td>Less time spent with the devil you know</td>
                                </tr>
                                <tr>
                                    <td>L</td>
                                    <td>Life goes on as planned </td>
                                </tr>
                                <tr>
                                    <td>O</td>
                                    <td>Old memories can be hard to swallow</td>
                                </tr>
                                <tr>
                                    <td>W</td>
                                    <td>Wherever I go, trouble will follow</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <table className="tblWrite">
                            <thead>
                                <tr>
                                    <th colspan="2">22nd November 2022</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>S</td>
                                    <td>Silently the household slept</td>
                                </tr>
                                <tr>
                                    <td>W</td>
                                    <td>While one more wakeful silently wept</td>
                                </tr>
                                <tr>
                                    <td>E</td>
                                    <td>Each secret being cautiously kept</td>
                                </tr>
                                <tr>
                                    <td>P</td>
                                    <td>Passed room to room, stealthily crept</td>
                                </tr>
                                <tr>
                                    <td>T</td>
                                    <td>Through hallways and staircases carefully swept</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <table className="tblWrite">
                            <thead>
                                <tr>
                                    <th colspan="2">23rd November 2022</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>A</td>
                                    <td>Always question everything you’re told</td>
                                </tr>
                                <tr>
                                    <td>N</td>
                                    <td>No valid question will ever be too bold</td>
                                </tr>
                                <tr>
                                    <td>S</td>
                                    <td>Searching for truth in lessons that are learned</td>
                                </tr>
                                <tr>
                                    <td>W</td>
                                    <td>Wisdom is not easily earned</td>
                                </tr>
                                <tr>
                                    <td>E</td>
                                    <td>Everyone can go through life, winging like a chancer</td>
                                </tr>
                                <tr>
                                    <td>R</td>
                                    <td>Rarer breeds move forward slower, looking for the answer</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <table className="tblWrite">
                            <thead>
                                <tr>
                                    <th colspan="2">24th November 2022</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>E</td>
                                    <td>Extravagance and shiny things</td>
                                </tr>
                                <tr>
                                    <td>N</td>
                                    <td>Neon lights and twinkles</td>
                                </tr>
                                <tr>
                                    <td>C</td>
                                    <td>Candles, jewels and sparkly rings</td>
                                </tr>
                                <tr>
                                    <td>H</td>
                                    <td>Harmonies and dimples</td>
                                </tr>
                                <tr>
                                    <td>A</td>
                                    <td>Apple trees and spiky plants</td>
                                </tr>
                                <tr>
                                    <td>N</td>
                                    <td>Nature glows where humans can’t</td>
                                </tr>
                                <tr>
                                    <td>T</td>
                                    <td>Treasures made just to enchant</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <table className="tblWrite">
                            <thead>
                                <tr>
                                    <th colspan="2">25th November 2022</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>M</td>
                                    <td>Maybe getting lost was more than just a mishap</td>
                                </tr>
                                <tr>
                                    <td>A</td>
                                    <td>A little bit of guesswork and we'll do another lap</td>
                                </tr>
                                <tr>
                                    <td>P</td>
                                    <td>Possibly we should have thought of picking up a map</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <table className="tblWrite">
                            <thead>
                                <tr>
                                    <th colspan="2">26th November 2022</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>G</td>
                                    <td>Grabbing at that final straw</td>
                                </tr>
                                <tr>
                                    <td>R</td>
                                    <td>Reaching for what's gone before</td>
                                </tr>
                                <tr>
                                    <td>A</td>
                                    <td>A loss of what has past</td>
                                </tr>
                                <tr>
                                    <td>S</td>
                                    <td>Searching for a hand to clasp</td>
                                </tr>
                                <tr>
                                    <td>P</td>
                                    <td>Permanence too hard to grasp</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <table className="tblWrite">
                            <thead>
                                <tr>
                                    <th colspan="2">27th November 2022</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>S</td>
                                    <td>Smoke will rise a message to the sky</td>
                                </tr>
                                <tr>
                                    <td>I</td>
                                    <td>Indicator lights flash intent to passers-by</td>
                                </tr>
                                <tr>
                                    <td>G</td>
                                    <td>Glass panels amplify the flashes of a beam</td>
                                </tr>
                                <tr>
                                    <td>N</td>
                                    <td>Nods show agreement with the latest flashy scheme</td>
                                </tr>
                                <tr>
                                    <td>A</td>
                                    <td>Adrift in a world where nothing is simple</td>
                                </tr>
                                <tr>
                                    <td>L</td>
                                    <td>Lost in a life without any signal</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <table className="tblWrite">
                            <thead>
                                <tr>
                                    <th colspan="2">28th November 2022</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>D</td>
                                    <td>Donning costumes to hide a self</td>
                                </tr>
                                <tr>
                                    <td>I</td>
                                    <td>Incognito, passes with stealth</td>
                                </tr>
                                <tr>
                                    <td>S</td>
                                    <td>Sunglasses, makeup, a quick change of hair</td>
                                </tr>
                                <tr>
                                    <td>G</td>
                                    <td>Gum to change faces, a lopsided stare</td>
                                </tr>
                                <tr>
                                    <td>U</td>
                                    <td>Under protection of non-descript clothes</td>
                                </tr>
                                <tr>
                                    <td>I</td>
                                    <td>Into the world with a misshapen nose</td>
                                </tr>
                                <tr>
                                    <td>S</td>
                                    <td>Suspenseful moments leading to surprise</td>
                                </tr>
                                <tr>
                                    <td>E</td>
                                    <td>Enigmatic master of disguise</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <table className="tblWrite">
                            <thead>
                                <tr>
                                    <th colspan="2">29th November 2022</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>I</td>
                                    <td>It really isn't worth it</td>
                                </tr>
                                <tr>
                                    <td>N</td>
                                    <td>Not to throw yourself all in</td>
                                </tr>
                                <tr>
                                    <td>T</td>
                                    <td>Take the time to do the work</td>
                                </tr>
                                <tr>
                                    <td>E</td>
                                    <td>Enjoy the chance to win</td>
                                </tr>
                                <tr>
                                    <td>N</td>
                                    <td>Never miss the chance to learn</td>
                                </tr>
                                <tr>
                                    <td>T</td>
                                    <td>To take on something new</td>
                                </tr>
                                <tr>
                                    <td>I</td>
                                    <td>Incite a riot in your brain</td>
                                </tr>
                                <tr>
                                    <td>O</td>
                                    <td>Or read a book or two</td>
                                </tr>
                                <tr>
                                    <td>N</td>
                                    <td>Never toe the party line</td>
                                </tr>
                                <tr>
                                    <td>A</td>
                                    <td>Always thrive alone</td>
                                </tr>
                                <tr>
                                    <td>L</td>
                                    <td>Look for help when things go rare but keep it in the zone.</td>
                                </tr>
                                <tr>
                                    <td>L</td>
                                    <td>Lose thoughts of doing things conventionally</td>
                                </tr>
                                <tr>
                                    <td>Y</td>
                                    <td>Yet always do things intentionally</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <table className="tblWrite">
                            <thead>
                                <tr>
                                    <th colspan="2">30th November 2022</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>V</td>
                                    <td>Visits made to other lands</td>
                                </tr>
                                <tr>
                                    <td>A</td>
                                    <td>Amazing sights in far away places</td>
                                </tr>
                                <tr>
                                    <td>C</td>
                                    <td>Coastal waters and desert sands</td>
                                </tr>
                                <tr>
                                    <td>A</td>
                                    <td>Animals and different faces</td>
                                </tr>
                                <tr>
                                    <td>T</td>
                                    <td>Travelling far and travelling near</td>
                                </tr>
                                <tr>
                                    <td>I</td>
                                    <td>In a world of inspiration</td>
                                </tr>
                                <tr>
                                    <td>O</td>
                                    <td>Off exploring round the sphere</td>
                                </tr>
                                <tr>
                                    <td>N</td>
                                    <td>Navigating a vacation</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Write22