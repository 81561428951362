import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const Environmentalist = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Environmentalist"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">01</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Track Rubbish</div>
                    <div className="badgePageItemEvidence">
                        I've been tracking my rubbish for a while and I'm constantly frustrated by how much there is!
                        <br/><br/>
                        I recycle as much as possible but there is still too much that can't be recycled - much of this is food packaging.
                        <br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">02</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Reduce your waste</div>
                    <div className="badgePageItemEvidence">
                        It is difficult when online shopping to stop the plastic and very much depends on who picks and packs! I switched to Sainsburys from Tesco this month and there was less plastic because they don't insist on putting everything in individual plastic bags that have no handles. I received one plastic bag that contained meat, instead of the usual several from Tesco, so that helped.
                        <br/><br/>
                        I always buy loose veg where possible and this time they came without bags which was also good.
                        <br/><br/>
                        Switching to vegetarian / vegan diet helps to reduce packaging because meat is a huge culprit for - often excessive - plastic packaging.
                        <br/><br/>
                        I have increased my intake of water and stopped drinking squash which has reduced the plastic bottles. If I buy fizzy drinks for a treat, I buy cans or bottles.
                        <br/><br/>
                        My bathroom is mostly plastic free, any item that isn't zero waste is recyclable.
                        <br/><br/>
                        My washing products are plastic free - I use Smol products which get sent through the post about once a quarter in recyclable packaging.
                        <br/><br/>
                        Cooking from scratch also reduces waste as you don't having the packaging from pre-prepared food.
                        <br/><br/>
                        So, this month has been better, largely thanks to switching shopping provider so I will keep an eye on that.
                        <br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">03 - Activity 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Vegetarian food</div>
                    <div className="badgePageItemEvidence">
                        I have been gradually changing my diet from meat to vegetarian. It's difficult because for a very long time I have been on a super low carb diet which involved a lot of meat!
                        <br/><br/>
                        I have really enjoyed the vege meals though so I cook those more often than not now.
                        <br/><br/>
                        Examples of some of the meals I have cooked can be seen on my Chef Badge page.
                        <br/><br/>
                        I have tried a few substitutes, I have found that I prefer the Quorn products over plant based alternatives.
                        <br/><br/>
                        I have enjoyed yoghurt and ice cream for the first time in years using Alpro, Roar, Perfect World products that are made using dairy alternatives.
                        <br/><br/>
                        I have tried vegan cheeses, but I really can't get on with them. This is the reason why I will be vegetarian and not vegan, I like eggs and cheese too much!
                        <br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">03 - Activity 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Travel</div>
                    <div className="badgePageItemEvidence">
                        This is very easy - I don't go anywhere unless I can walk there. I don't drive so don't have a car and have no need to travel at the moment.
                        <br/><br/>
                        I don't have to travel to work (other than from the bedroom to the office!)
                        <br/><br/>
                        I make occassional car journeys when one of the family comes to whisk me away for a day.
                        <br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">02 - Activity 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Water use</div>
                    <div className="badgePageItemEvidence">
                        I am being mindful over water consumption.<br/>
                        <ul className="list">
                            <li>I don't wash up constantly - I wait until there is enough for a full sink</li>
                            <li>I turn off the tap when brushing my teeth</li>
                            <li>Baths are not run full</li>
                            <li>I only do washing once a week at most</li>
                            <li>I don't flush the toilet every time (if it's only me in the house)</li>
                        </ul> 
                        <br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">03 - Activity 4</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Make sure every lightbulb in your house is an energy-efficient one.</div>
                    <div className="badgePageItemEvidence">
                        All light bulbs have now been changed! 
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">03 - Activity 5</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Litter picks</div>
                    <div className="badgePageItemEvidence">
                        I do minimum 2 solo litter picks a week, plus monthly and quarterly organised events.
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">03 - Activity 6</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Check all chemicals in your home are non-toxic.</div>
                    <div className="badgePageItemEvidence">
                        All cleaners are non-toxic.
                        <br/><br/>
                        I make my own cleaning products using vinegar and citrus peel.
                        <br/><br/>
                        I have made toilet fizzers using baking soda and essential oils
                        <br/><br/>
                        I don't keep other chemicals.
                        <br/><br/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Environmentalist