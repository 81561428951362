import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import MidiPlayer from 'react-midi-player';
import CodeBreaker1 from '../images/codebreaker1.png';
import CodeBreaker2 from '../images/codebreaker2.png';
import CodeBreaker3 from '../documents/rebel.midi';
import CodeBreaker4 from '../images/codebreaker4.png';
import CodeBreaker5 from '../images/codebreaker5.bmp';
import CodeBreaker6 from '../images/codebreaker6.png';
import CodeBreaker7 from '../images/codebreaker7.jpg';
import CodeBreaker8 from '../images/codebreaker8.jpg';
import CodeBreaker9 from '../images/codebreaker9.jpg';

const CodeBreaker = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Codebreaker"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">02 - Phonetics</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light">Learn the phonetic alphabet</div>
                    <div className="badgePageItemEvidence">
                        The phonetic alphabet is something I learned years ago, listing it here doesn't evidence anything but it's all I can do!
                        <br/>
                        <ul className="list">
                            <li>A - Alpha</li>
                            <li>B - Bravo</li>
                            <li>C - Charlie</li>
                            <li>D - Delta</li>
                            <li>E - Echo</li>
                            <li>F - Foxtrot</li>
                            <li>G - Golf</li>
                            <li>H - Hotel</li>
                            <li>I - India</li>
                            <li>J - Juliett</li>
                            <li>K - Kilo</li>
                            <li>L - Lima</li>
                            <li>M - Mike</li>
                            <li>N - November</li>
                            <li>O - Oscar</li>
                            <li>P - Papa</li>
                            <li>Q - Quebec</li>
                            <li>R - Romeo</li>
                            <li>S - Sierra</li>
                            <li>T - Tango</li>
                            <li>U - Uniform</li>
                            <li>V - Victor</li>
                            <li>W - Whiskey</li>
                            <li>X - X-Ray</li>
                            <li>Y - Yankee</li>
                            <li>Z - Zulu</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">04 - Ciphers</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light">Research ciphers</div>
                    <div className="badgePageItemEvidence">
                        For this, I have used the phrase "Go and buy a Rebel Badge Book" and encrypted it into each of the cipher types.
                        <br/><br/>
                        <strong>Classic Substitution</strong><br/>
                        A Substitution Cipher uses the letters of the alphabet in some order to represent each other. Simple ciphers include Caesar (shifted) and Atbash (reversed). More complex substitutions are called "Deranged" or "Mixed".
                        <br/><br/>
                        Often, a complex cipher works using a keyword - the keyword is written first (with repeated letters removed) and then the rest of the alphabet is written out in normal order to generate the key.
                        <br/><br/>
                        If we use "clause" as the keyword, this is what it would look like:
                        <br/><br/>
                        <table>
                            <tr>
                                <td>Plain text</td><td>A</td><td>B</td><td>C</td><td>D</td><td>E</td><td>F</td><td>G</td><td>H</td><td>I</td><td>J</td><td>K</td><td>L</td><td>M</td><td>N</td><td>O</td><td>P</td><td>Q</td><td>R</td><td>S</td><td>T</td><td>U</td><td>V</td><td>W</td><td>X</td><td>Y</td><td>Z</td>
                            </tr>
                            <tr>
                                <td>Cipher text</td><td>C</td><td>L</td><td>A</td><td>U</td><td>S</td><td>E</td><td>B</td><td>D</td><td>F</td><td>G</td><td>H</td><td>I</td><td>J</td><td>K</td><td>M</td><td>N</td><td>O</td><td>P</td><td>Q</td><td>R</td><td>T</td><td>V</td><td>W</td><td>X</td><td>Y</td><td>Z</td>
                            </tr>
                        </table>               
                        Encrypted Phrase: BMCK ULTY CPSL SILC UBSL MMH
                        <br/><br/>
                        You can try it out here: <a className="linkUnderline" href="https://www.dcode.fr/substitution-cipher" target="_blank" rel="noopener noreferrer">Substitution Cipher</a>
                        <br/><br/><br/>
                        <strong>Caesar cipher</strong><br/>
                         A Caesar Cipher  is a substitution cipher where each letter of the alphabet is substituted with another. It is shifted, so plain text and cipher text are in the same sequence. All the letters would be in alphabetic order but the cipher text will start on a different letter to the plain text.
                        <br/><br/>
                        <table>
                            <tr>
                                <td>Plain text</td><td>A</td><td>B</td><td>C</td><td>D</td><td>E</td><td>F</td><td>G</td><td>H</td><td>I</td><td>J</td><td>K</td><td>L</td><td>M</td><td>N</td><td>O</td><td>P</td><td>Q</td><td>R</td><td>S</td><td>T</td><td>U</td><td>V</td><td>W</td><td>X</td><td>Y</td><td>Z</td>
                            </tr>
                            <tr>
                                <td>Cipher text</td><td>H</td><td>I</td><td>J</td><td>K</td><td>L</td><td>M</td><td>N</td><td>O</td><td>P</td><td>Q</td><td>R</td><td>S</td><td>T</td><td>U</td><td>V</td><td>W</td><td>X</td><td>Y</td><td>Z</td><td>A</td><td>B</td><td>C</td><td>D</td><td>E</td><td>F</td><td>G</td>
                            </tr>
                        </table>
                        
                        Encrypted Phrase: NVHU LIBF HYLI LSIH KNLI VVR
                        <br/><br/>
                        You can try it out here: <a className="linkUnderline" href="https://www.dcode.fr/caesar-cipher" target="_blank" rel="noopener noreferrer">Caesar Cipher</a>
                        <br/><br/><br/>
                        <strong>Homophonic Substitution</strong><br/>
                        Homophonic ciphers are based on the frequency of each letter in the English language. The frequency of the letter E, for example, is 12% so it is assigned 12 symbols.
                        <br/><br/>
                        This is a sample of what it may look like (link to source given as example below):
                        <br/><br/>
                        <img className="imgQuarter" src={CodeBreaker1} alt="Exaple of a homophonic cipher" width="400px" height="400px" />
                        <br/><br/>
                        Encrypted Phrase: 06 90 78 59 45 81 08 21 09 35 87 81 44 37 81 78 45 06 44 81 99 00 04
                        <br/><br/>
                        You can try it out here: <a className="linkUnderline" href="https://www.simonsingh.net/The_Black_Chamber/homophonic_cipher.html" target="_blank" rel="noopener noreferrer">The Black Chamber</a>
                        <br/><br/><br/>
                        <strong>Vigenere cipher</strong><br/>
                        This is a polyalphabetic cipher and uses multiple Caesar Ciphers in sequence with different shift values. It can be encoded and decoded using a Vigenere Square, see below:
                        <br/>
                        <img className="imgQuarter" src={CodeBreaker2} alt="Exaple of a Vigenere Square" width="400px" height="400px" />
                        <br/>
                        It uses a keyword, the keyword is repeated for the length of the key. If I use the same keyword as before, "badge", this is our key:  badgebadgebadgebadgebad
                        <br/><br/>
                        The first letter of our phrase is "G", this is paired with "B" which is the first letter of our key. If we look at row "G" and column "B" we get our substituted letter: "H".
                        <br/><br/>                    
                        Encrypted Phrase:  HODTHCUBGVFBHRFBDJKFPON
                        <br/><br/>
                        You can try it out here: <a className="linkUnderline" href="https://vigenerecipher.com/" target="_blank" rel="noopener noreferrer">Vigenere Cipher</a>
                        <br/><br/><br/>
                        <strong>Book Cipher</strong><br/>
                        Book ciphers use a book or piece of text as the key to encode / decode. They work by replacing words in the plain text with the location of words from the book. Both the sender and recipient would need to own the same copy of the text. Each letter is encoded by the rank of a word in a text/book.
                        <br/><br/>
                        The Bible and the Dictionary are commonly used texts. For my example, I'm using the <a href="https://www.rebelbadgestore.com/about" target="_blank" rel="noopener noreferrer">About page on the Rebel Badge Book website</a>. 
                        <br/><br/>
                        The first word of our phrase is "Go", on the About page, the word "Go" is the 84th word on the page. I have used both Word matching and letter matching, because the word "Buy" doesn't appear on the page. Traditionally I think you would use either letter or word substitution, not both.
                        <br/><br/>
                        Encrypted Phrase: 84 16 (8 10 51) 14 7 8 9
                        <br/><br/>
                        You can try it out here: <a href="https://www.dcode.fr/book-cipher" target="_blank" rel="noopener noreferrer">Book Cipher</a>
                        <br/><br/><br/>
                        <strong>Playfair cipher</strong><br/>
                        Also known as Playfair Square or Wheatstone-Playfair Cipher.
                        <br/><br/>
                        This cipher encrypts pairs of letters instead of single letters. It uses a 5x5 grid. It works in the same way as orther substitution ciphers, by using a key and the remaining letters of the alphabet in order - omitting J as only 25 legtters can be used. If J is required it uses I.
                        <br/><br/>
                        The plain text should then be split into pairs of letters, so this would be our phrase: go an db uy ar eb el ba dg eb oo k
                        <br/><br/>
                        If there are an odd number of letters, add a Z to the last letter: go an db uy ar eb el ba dg eb oo kz
                        <br/><br/>
                        Other rules include: 
                        <ul className="list">
                            <li>Pair cannot be made with the same letter - break them up and add a bogus letter</li>
                            <li>If a letter is orphaned, add a bogus letter</li>
                        </ul>
                        <br/>
                        Encrypting:<br/>
                        <ul className="list">
                            <li>If both letters are in the same column, take the letter below each one</li>
                            <li>If both letters are in the same row, take the letter to the right of each one (looping to the beginning of the row if needed)</li>
                            <li>Otherwise, form a rectangle with the two letters and take the letters on the horizontal opposite</li>
                        </ul>
                        <br/>
                        Using our keyword "clause", this is our grid:
                        <br/>
                        <table className="tblCipher">
                            <tr>
                                <td>C</td>
                                <td>L</td>
                                <td>A</td>
                                <td>U</td>
                                <td>S</td>
                            </tr>
                            <tr>
                                <td>E</td>
                                <td>B</td>
                                <td>D</td>
                                <td>F</td>
                                <td>G</td>
                            </tr>
                            <tr>
                                <td>H</td>
                                <td>J</td>
                                <td>K</td>
                                <td>M</td>
                                <td>N</td>
                            </tr>
                            <tr>
                                <td>O</td>
                                <td>P</td>
                                <td>Q</td>
                                <td>R</td>
                                <td>T</td>
                            </tr>
                            <tr>
                                <td>V</td>
                                <td>W</td>
                                <td>X</td>
                                <td>Y</td>
                                <td>Z</td>
                            </tr>

                        </table>
                        <br/>
                        Encrypted Phrase: ET SK FD FU UQ BD BC DL FE BD QV QH
                        <br/><br/>
                        This page breaks it down brilliantly: <a className="linkUnderline" href="https://www.geeksforgeeks.org/playfair-cipher-with-examples/" target="_blank" rel="noopener noreferrer">Playfair Cipher</a>
                        <br/><br/><br/>
                        <strong>ADFGVX Cipher</strong><br/>
                        This is another cipher that uses a 6x6 grid. The rows and columns are labelled with the letters ADFGVX and the table filled with the letters of the alphabet and numbers 0-9.
                        <br/><br/>
                        We are using the grid in its simplest form, with no keyword transposition.
                        <br/>
                         <table className="tblCipher">
                            <tr>
                                <td></td>
                                <td>A</td>
                                <td>B</td>
                                <td>F</td>
                                <td>G</td>
                                <td>V</td>
                                <td>X</td>
                            </tr>
                            <tr>
                                <td>A</td>
                                <td>A</td>
                                <td>B</td>
                                <td>C</td>
                                <td>D</td>
                                <td>E</td>
                                <td>F</td>
                            </tr>
                            <tr>
                                <td>D</td>
                                <td>G</td>
                                <td>H</td>
                                <td>I</td>
                                <td>J</td>
                                <td>K</td>
                                <td>L</td>
                            </tr>
                            <tr>
                                <td>F</td>
                                <td>M</td>
                                <td>N</td>
                                <td>O</td>
                                <td>P</td>
                                <td>Q</td>
                                <td>R`</td>
                            </tr>
                            <tr>
                                <td>G</td>
                                <td>S</td>
                                <td>T</td>
                                <td>U</td>
                                <td>V</td>
                                <td>W</td>
                                <td>X</td>
                            </tr>
                            <tr>
                                <td>V</td>
                                <td>Y</td>
                                <td>Z</td>
                                <td>0</td>
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                            </tr>
                             <tr>
                                <td>X</td>
                                <td>4</td>
                                <td>5</td>
                                <td>6</td>
                                <td>7</td>
                                <td>8</td>
                                <td>9</td>
                            </tr>
                        </table>
                        <br/>
                        The first letter of our phrase is "G". Looking at the grid, the letter "G" is in row "D" and column"A" so it becomes DA.
                        <br/><br/>
                        Encrypted Phrase: DAFFAAFDAGADGFVAAAFXAVADAVDXADAAAGDAAVADFFFFDV
                        <br/><br/>
                        There are much more complex ecryptions using keyword transposition - this is all explained at the link below.
                        <br/><br/>
                        You can try it out here: <a className="linkUnderline" href="https://www.dcode.fr/adfgvx-cipher" target="_blank" rel="noopener noreferrer">ADFGVX Cipher</a>
                        <br/><br/><br/>
                        <strong>Enigma cipher</strong><br/>
                        The Enigma cipher uses a polyalphabetic substitution cipher. It works by using a scrambler that has a set of rotors that change the electrical path from character to character between the input and output device.
                        <br/><br/>
                        There is a <a href="https://en.wikipedia.org/wiki/Cryptanalysis_of_the_Enigma" target="_blank" rel="noopener noreferrer">full breakdown of the system</a> at Wikipedia
                        <br/><br/>
                        Using the Enigma M3 and Reflector UKW our Encrypted Phrase is: hgwsl dtnqs zhrjs crows luo
                        <br/><br/>
                        You can try it out here: <a className="linkUnderline" href="https://cryptii.com/pipes/enigma-machine" target="_blank" rel="noopener noreferrer">Enigma Machine</a>
                        <br/><br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">05 - Messages</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light">Learn three practical ways to hide a message</div>
                    <div className="badgePageItemEvidence">
                        <strong>Steganography</strong><br/>
                        This involves hiding a secret message in or on something that is not secret, for example hiding a message in a photo.
                        <br/>
                        Using Lemon Juice and heat to reveal is also steganography.
                        <br/><br/>
                        Here's a yummy photo of meringues I made for my Baker badge - our phrase is hidden in here:
                        <br/>
                        <img className="imgQuarter" src={CodeBreaker5} alt="Steganography example" />
                        <br/><br/>
                        You will only see the message if you use an app called "QuickStego" - if I open the picture in there you will see the message:
                        <br/>
                        <img className="imgQuarter" src={CodeBreaker6} alt="Steganography example" />
                        <br/><br/><br/>
                        <strong>Musical Cryptography</strong><br/>
                        Next, I like Muscial Cryptography. This allows musicians to share secret messages in muscial compositions.
                        <br/><br/>
                        There is information on musical cryptography at <a className="linkUnderline" href="https://www.atlasobscura.com/articles/musical-cryptography-codes" target="_blank"  rel="noopener noreferrer">Atlas Obscura</a>
                        <br/><br/>
                        You can try it our at the <a className="linkUnderline" href="https://www.wmich.edu/mus-theo/solfa-cipher/" target="_blank" rel="noopener noreferrer">Solfa Cipher</a> page, it's very cool!
                        <br/><br/>
                        Listen to our phrase encrypted into a music track:<br/>
                        <MidiPlayer src={CodeBreaker3} />
                        <br/><br/><br/>
                        <strong>Fontcode</strong><br/>
                        A relatively new way of hiding messages in text is using Fontcode. This takes a secret message, translates it into numbers then uses those numbers to make imperceptible changes to single characters in fonts.
                        <br/><br/>
                        Here is an example of how the manipulation works:
                        <br/>
                        <img className="imgQuarter" src={CodeBreaker4} alt="Example of Fontcode manipulation" />
                        <br/><br/>
                        You can find out more about it at the <a className="linkUnderline" href="https://www.cs.columbia.edu/cg/fontcode/" target="_blank" rel="noopener noreferrer">Computer Science at Columbia University</a> website.
                        <br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">06 - Code Wheel</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light">Make a code wheel</div>
                    <div className="badgePageItemEvidence">
                        With thanks to the BBC Parents Toolkit and the Science Museum Group for the printables!
                        <br/><br/>
                        <img className="imgQuarter" src={CodeBreaker8} alt="A code wheel" />
                        <br/><br/>
                        Let's shift the wheel and encrypt our phrase:
                        <br/>
                        <img className="imgQuarter" src={CodeBreaker9} alt="A code wheel" />
                        <br/><br/>
                        Encrypted Phrase: LTFSI GZNF WJGJ GGFI LJGT TP
                        <br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">10 - Puzzles</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light">Puzzles, Adventures and Games</div>
                    <div className="badgePageItemEvidence">
                        Since the beginning of lockdown I have been doing crosswords - both standard and cryptic - twice a week with my Dad, and sometimes my brother too, over Skype. I always have puzzle books in the house and I am making my way through a cryptic book that Dad gave me!
                        <br/><br/>
                        Before lockdown we used to do them quite regularly when I visited, since the end of lockdowns we have kept up the habit - it's a nice way to keep in touch and keep all our brains working!!
                        <br/><br/>
                        It may not be a formal book, but it's equivalent!
                        <br/><br/>
                        <img className="imgQuarter" src={CodeBreaker7} alt="Completed crossword printouts" /><br/>
                        I am more than happy to claim this part of the badge based on what I have already done, but I will be continuing as I have two new books to complete - a GCHQ Puzzle Book and a Nautical puzzle book. That'll keep me busy!
                        <br/><br/>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default CodeBreaker