import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Reader1 from '../images/reader1.jpg';
import Reader2 from '../images/reader2.jpg';
import Reader3 from '../images/reader3.jpg';
import Reader4 from '../images/reader4.jpg';
import Reader5 from '../images/reader5.jpg';
import Reader6 from '../images/reader6.jpg';
import Reader7 from '../images/reader7.jpg';
import Reader8 from '../images/reader8.jpg';
import Reader9 from '../images/reader9.png';
import Reader10 from '../images/reader10.jpg';
import Reader11 from '../images/reader11.jpg';
import Reader12 from '../images/reader12.jpg';
import Reader13 from '../images/reader13.jpg';

const Reader = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Reader"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">01</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Read</div>
                    <div className="badgePageItemEvidence">
                        I love reading, I love to have a physical copy of a book to read. These are the books that I read for this clause:
                        <br/><br/>
                        <div className="readerGallery">
                            <div className="readerItem"><img src={Reader1} alt="" width="400px" height="400px" /></div>
                            <div className="readerItem"><img src={Reader2} alt="" width="400px" height="400px" /></div>
                            <div className="readerItem"><img src={Reader3} alt="" width="400px" height="400px" /></div>
                            <div className="readerItem"><img src={Reader4} alt="" width="400px" height="400px" /></div>
                            <div className="readerItem"><img src={Reader5} alt="" width="400px" height="400px" /></div>
                            <div className="readerItem"><img src={Reader6} alt="" width="400px" height="400px" /></div>
                            <div className="readerItem"><img src={Reader7} alt="" width="400px" height="400px" /></div>
                            <div className="readerItem"><img src={Reader8} alt="" width="400px" height="400px" /></div>
                            <div className="readerItem"><img src={Reader10} alt="" width="400px" height="400px" /></div>
                            <div className="readerItem"><img src={Reader11} alt="" width="400px" height="400px" /></div>
                            <div className="readerItem"><img src={Reader12} alt="" width="400px" height="400px" /></div>
                            <div className="readerItem"><img src={Reader13} alt="" width="400px" height="400px" /></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">02</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Include specific categories</div>
                    <div className="badgePageItemEvidence">
                        These are the three books that I read to satisfy this clause:
                        <br/><br/>
                        <strong>Fiction:</strong><br/>
                        <div className="readerItem"><img src={Reader6} alt="" width="400px" height="400px" /></div>
                        <br/><br/>
                        <strong>Biography:</strong><br/>
                        <div className="readerItem"><img src={Reader7} alt="" width="400px" height="400px" /></div>
                        <br/><br/>
                        <strong>Non-Fiction:</strong><br/>
                        <div className="readerItem"><img src={Reader4} alt="" width="400px" height="400px" /></div>
                        <br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">03</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Review</div>
                    <div className="badgePageItemEvidence">
                        <strong>Meeting Mungo Thunk</strong>
                        <br/>
                        <p>I loved Mungo Thunk! The book itself, it's look and feel and the story of hopelessness being transformed.  When Adam hits rock-bottom, he invites a strange character called Mungo Thunk as  a flat mate. Mungo Thunk isn't a particularly likeable person, but there is something intriguing about him. As Adam navigates life around his odd flatmate, he finds himself the recipient of helpful advice. With the assistance of Mungo Thunk, Adam starts to transform his life – and Mungo's!</p>
                        <br/><p>Watching how the characters change in response to each other is intriguing and the premise that something / someone is only in your life until they have served their intended purpose appeals to me.</p>
                        <br/><p>I have since read more from Keith A. Pearson and very much enjoy his work.</p>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">04</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Critique</div>
                    <div className="badgePageItemEvidence">
                        <strong>How to Read Water</strong>
                        <br/><br/>
                        <p>I read through the critical reviews of How to Read Water and came across this one.</p>
                        <br/>
                        <img src={Reader9} alt="" />
                        <br/><br/>
                        <p>Apologies to the writer, this is purely a Rebel exercise, but I don't wholly agree with your assessment!!</p>
                        <br/><br/>
                        <p>The first point I disagree with is the title of the review, “How not to read about water”.  Whilst trying to be ironic, it is in itself factually incorrect, because the book is definitely about how to read water.</p>
                        <br/><br/>
                        <p>I agree with part of the first statement “… a book that glories in the wonders and mysteries of that ubiquitous life-giving substance is one to be celebrated” but my agreement mostly ends there.</p>
                        <br/><br/>
                        <p>I haven't taken issue with the style of the writing and haven't found it boring or too slow-moving, but maybe that's because I'm actually learning something from it.</p>
                        <br/><br/>
                        <p><quote>“But nor, equally clearly , is he much of a scientist.”</quote></p>
                        <p> Well, no, he's not, and he doesn't claim to be, he is a writer and natural navigator.</p>
                        <br/><br/>
                        <p><quote>“The attempt to explain the myriad fascinations of water in qualitative terms, rather than the impossible complexities of fluid dynamics and such science is very welcome and often works quite well.”</quote></p>
                        <p>Well I'm glad there is something positive here, this isn't intended to be a complex scientific book, but a layman's guide to interpreting what we see around us.</p>
                        <br/><br/>
                        <p><quote>“However the observations often verge on the banal, as noted by the critic who points out that finding a crayfish in a stream shows the presence of limestone overlooks the fact that one would assume that was blindingly obvious from the landscape.“</quote></p>
                        <p>What one person may find blindingly obvious, another would not. It depends on how much time you have spent learning about our different environments and what should, rightfully, thrive where. </p>
                        <br/><br/>
                        <p><quote>“Or just plain wrong, like the statement that the moon is the same volume as the Pacific Ocean. A brief check finds it to be 300 times larger so that the entire explanation for tides is undermined by this fundamental error.”</quote></p>
                        <p>I do have to agree with this. A quick check shows the following facts:</p>
                        <br/>
                        <ul className="list">
                            <li>pacific ocean volume: 669.88 million cu km (src: https://www.cia.gov/the-world-factbook/oceans/pacific-ocean/)</li>
                            <li>The volume of the Moon is about 21.9 billion cubic kilometers. (src: https://www.universetoday.com/20435/volume-of-the-moon/)</li>
                        </ul>
                        <br/>
                        <p>I wonder whether “Volume” wasn't the intended word, it seems a rather substantial mistake, unless both the writer and I are looking at the wrong thing (entirely possible!)</p>
                        <br/><br/>
                        <p><quote>“As becomes increasingly clear, the author is a sailor, and one who has somehow managed to create some form of reputation as a media-friendly one. He should stick to sailing.”</quote></p>
                        <p>It should have been very clear (or "blindingly obvious") from the beginning if the writer had read about the author, or, indeed, the first page of the book which makes clear what the author does.</p>
                        <br/>
                        <p>Having had the privilege of joining a webinar with the author I can confirm that he is very media friendly and it is important to have people who are both experts in their field and media friendly to help educate others on topics that otherwise may not come across as easily digestible.</p>
                        <br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">05</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Take part in a book club</div>
                    <div className="badgePageItemEvidence">
                        My book club is with my Mum! I know, in terms of formal book clubs it doesn't cut it, but this is as close as I am going to get for the purpose of this badge.
                        <br/><br/>
                        We regularly exchange books and chat about what we've read and we are going to do the advanced reader challenge together - she was quite excited when I showed her what I had chosen!
                        <br/><br/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reader