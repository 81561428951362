import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import RoundUp from '../images/annual-roundup2023.png';

const  Roundup23 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Annual Roundup 2023"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Annual Roundup 2023</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>I did this one for extra cup points!! It was nice to read everyone's roundups though.</p>   
                        <br/>       
                        <img src={RoundUp} alt="" className="imgHalf"/>
                        <br/>          
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Roundup23