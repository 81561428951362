import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Collection1 from '../images/mycollection1.jpg';
import Collection2 from '../images/mycollection2.jpg';
import Collection3 from '../images/mycollection3.jpg';
import Collection4 from '../images/mycollection4.jpg';
import Collection5 from '../images/mycollection5.jpg';
import Collection6 from '../images/mycollection6.jpg';
import Collection7 from '../images/mycollection7.jpg';

const Collection = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"My Collection"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Task 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence">
                    <p>This is one that is almost entirely backdated - a very long way. This is because I already have a collection and I'm not intending to collect anything else now.</p>
                    <br/>
                    <p>I have a collection of spoons from my childhood, which I no longer display but still have stored away. I also have a collection of dolls stored away.</p>
                    <br/>
                    <p>The only thing I do tend to collect now is magnets - I buy one from any place that I go to and they go on the boiler in the kitchen so the memories are always there.</p>
                    <br/>
                    <img className="imgQuarter" src={Collection1} alt="Collecter spoons in a wooden display unit" />
                    <img className="imgQuarter" src={Collection2} alt="Collecter spoons in a wooden display unit" />
                    <img className="imgQuarter" src={Collection3} alt="Collector spoons in a wooden display unit" />
                    <img className="imgQuarter" src={Collection4} alt="Collector spoons" />
                    <img className="imgQuarter" src={Collection5} alt="Collection of magnets displayed on a boiler" />
                    </div>
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Task 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>The wooden display units are how the spoons have always been displayed and these are wall mounted.</p>
                        <br/>
                        <p>My magnets are displayed on the boiler in the kitchen.</p>
                        <br/><br/>
                        <p>Perhaps not the most attractive of displays, but it's the best and most obvious place to put them for them to serve their purpose of retaining memories.</p>
                        <br/>
                    </div>
                </div>
            </div>          
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Task 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>Here is my spoon collection on my instagram account to share with interested people.</p>
                        <br/>
                        <img className="imgThird" src={Collection6} alt="Collector spoons on wooden display units" />&nbsp;&nbsp;
                        <img className="imgThird" src={Collection7} alt="Collector spoons" />
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Task 4</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>When I was born, I was gifted two silver spoons. That started the collection, whenever we went anywhere I would buy another one with my pocket money.</p>
                        <br/>
                        <p>The magnets came from my desire not to buy lots of "souvenirs" when on family holidays - I have too much junk I don't need! - but I wanted to have something to help me remember our times away, so I started buying magnets that I could display in the kitchen where I can always see them.</p>
                        <br/><br/>
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default Collection