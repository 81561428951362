import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Challenge1 from '../images/bronze-challenge1.jpg';
import Challenge2 from '../images/bronze-challenge2.jpg';
import Challenge3 from '../images/bronze-challenge3.jpg';
import Challenge4 from '../images/bronze-challenge4.jpg';
import Challenge5 from '../images/bronze-challenge5.jpg';
import Challenge6 from '../images/bronze-challenge6.jpg';
import Challenge7 from '../images/bronze-challenge7.jpg';
import Challenge8 from '../images/bronze-challenge8.jpg';
import Challenge9 from '../images/bronze-challenge9.jpg';
import Challenge10 from '../images/bronze-challenge10.jpg';
import Challenge11 from '../images/bronze-challenge11.jpg';
import Challenge12 from '../images/bronze-challenge12.jpg';
import Challenge13 from '../images/bronze-challenge13.jpg';
import Challenge14 from '../images/bronze-challenge14.jpg';
import Challenge15 from '../images/bronze-challenge15.jpg';
import Challenge16 from '../images/bronze-challenge16.png';
import Challenge17 from '../images/bronze-challenge17.jpg';
import Challenge18 from '../images/bronze-challenge18.png';
import Challenge19 from '../images/bronze-challenge19.png';
import Challenge20 from '../images/bronze-challenge20.jpg';
import Challenge21 from '../images/bronze-challenge21.jpg';
import Challenge22 from '../images/bronze-challenge22.jpg';
import Challenge23 from '../images/bronze-challenge23.jpg';
import Challenge24 from '../images/bronze-challenge24.jpg';
import Challenge25 from '../images/bronze-challenge25.jpg';

const MyChallenge = () => {
    return (
        <div>
            <ActionBar area={"bronze"} />
            <EvidenceHeader title={"My Challenge"} />
            <div className="mavPageItem">
                <div className="mavPageItemTitle blue">My Challenge</div>
                <div className="mavPageItemContent">
                    <div className="mavPageItemInfo blue-light">Set yourself a measurable challenge, which then takes you at least six months to complete.</div>
                    <div className="mavPageItemEvidence">
                        <p>This is going to be an interesting one! For my challenge I have set myself the task of gamifying my monthly litter picks.</p>
                        <br/>
                        <p>I am going to work on a web app that uses Augmented Reality (AR) to place virtual litter objects in a real world scene for people to find.</p>
                        <br/>
                        <p>There will be virtual items placed at specific GPS co-ordinates that users will be able to find and then read a fact. The ultimate challenge will be to locate the team mascot! Maybe there will be a prize involved, I haven't decided yet.</p>
                        <br/>
                        <p>This is going to involve a few different skills, these are the measurable steps to compeletion:</p>
                        <br/>
                        <ul className="mavList">
                            <li>Research available AR technologies and any software required</li>
                            <li>Decide the type of app to use - mobile app, web app etc.</li>
                            <li>Look at sample projects and understand how they work</li>
                            <li>Acquire some sample 3d images</li>
                            <li>Create a new project</li>
                            <li>Add sample data to the project and test to make sure you can place an object at a co-ordinate</li>
                            <li>Research the best software for 3D drawing</li>
                            <li>Follow tutorials on how to create 3D drawings for use with AR</li>
                            <li>Create a 3D image and load into the project</li>
                            <li>Test project to see the image working</li>
                            <li>Once the basics are in place, look at extending functionality.</li>
                            <li>Create a plan for the app - what litter items to include, what facts about each to include</li>
                            <li>Create the 3D drawings needed</li>
                            <li>Include custom drawings and related information into the project</li>
                            <li>Test!</li>
                            <li>Tweak</li>
                            <li>Re-test!</li>
                            <li>Create a QR code for people to scan to access to app at litter pick events</li>
                            <li>Find someone willing to test</li>
                            <li>Adjust for feedback and re-test</li>
                            <li>Project is live!</li>
                        </ul>
                        <br/><br/>
                        <p>This feels a little ambitious, but we're going to do it!</p>
                        <br/><br/>
                        <hr />
                        <br/>
                        <p className="challengeTitle"><h3 className="bronze">Research available AR technologies and any software required</h3></p>
                        <p>I started looking at the options available for AR applications, which essentially involved a lot of googling!</p>
                        <p>The most common options were:</p>
                            <ul className="challengeList">
                                <li>ARKit for iOS</li>
                                <li>ARCore for Android</li>
                            </ul>  
                        <p>Each of these require other technologies such as Unity and ViroReact, for example, as also special hosting options which would incur cost.</p>
                        <p>I did look at them more closely, find some samples and tutorials, but the cost was prohibitive and I didn't want to target one OS over another as that excludes too many people. It also meant that people would have to download apps to get involved and - although I have experimented with mobile apps a little a long time ago - was too much of a learning curve for this project.</p>
                        <p>I was already aware of an app called MetaVerse (not the new Facebook metaverse!) and that would have been absolutely perfect, but sadly the location functionality didn't work correctly and it's out of support.</p>
                        <p>I also came across AR.JS, which looked much more promising.</p>
                        <br/>
                        <p className="challengeStatus">Complete</p>
                        <br/><br/>
                        <hr />
                        <br/>
                        <p><h3 className="bronze">Decide the type of app to use - mobile app, web app etc.</h3></p>
                        <p>I opted to work with AR.JS as it requires a smaller learning curve, it is cross-platform, browser based and doesn't require anything to be downloaded. All end users require is an internet connection.</p>
                        <p>It doesn't require any new sofware, hosting or framework to make it work.</p>
                        <br/>
                        <p className="challengeStatus">Complete</p>
                        <br/><br/>
                        <hr />
                        <br/>
                        <p><h3 className="bronze">Look at sample projects and understand how they work</h3></p>
                        <p>I looked at a few samples, and tried several, but this is the one that was really helpful and I used it to kick-start my project:<br/><a href="https://medium.com/swlh/build-your-location-based-augmented-reality-web-app-a841956eed2c" target="_blank" rel="noopener noreferrer">Build your Location-Based Augmented Reality Web App</a></p>
                        <p>I already have knowledge of the languages required to implement this, so I was a good pointer as to how to make the AR functionalilty work.</p>
                        <br/>
                        <p className="challengeStatus">Complete</p>
                        <br/><br/>
                        <hr />
                        <br/>
                        <p><h3 className="bronze">Acquire some sample 3d images</h3></p>
                        <p>The tutorial contained a link to a resource containing some 3d images of Pokemon! These were very helpful to get started and I had great fun putting Dragonite in different places!</p>
                        <br/>
                        <p className="challengeStatus">Complete</p>
                        <br/><br/>
                        <hr />
                        <br/><br/>
                        <p><h3 className="bronze">Create a new project</h3></p>
                        <p>I created a new repository (repo) on my laptop to hold the assets and code files.</p>
                        <p>I also set up a new GitHub repo as a remote file backup and synced the two.</p>
                        <br/>
                        <p className="challengeStatus">Complete</p>
                        <br/><br/>
                        <hr />
                        <br/>
                        <p><h3 className="bronze">Add sample data to the project and test to make sure you can place an object at a co-ordinate</h3></p>
                        <p>This was the most exciting part!  I copied the code from the tutorial and edited it to use my current location co-ordinates.</p>
                        <p>It's easy enough to get those by using What3Words or by using websites that retrieve your location from your internet settings.</p>
                        <p>I added my office co-ordinates for the first test - it's hard to test inside as location services aren't as accurate, but it was good enough to see if it was working.</p>
                        <br/>
                        <p>Here we go ... Dragonite, Magnemite and Articuno floating around my house!</p>
                        <br/>
                        <div className="challengeGallery">
                            <img className="challengeImg" src={Challenge1} alt="3d Dragonite placed in a real-world location using augmented reality technology" />
                            <img className="challengeImg" src={Challenge2} alt="3d Magnemite placed in a real-world location using augmented reality technology" />
                            <img className="challengeImg" src={Challenge3} alt="3d Articuno placed in a real-world location using augmented reality technology" />
                        </div>
                        <br/>
                        <p className="challengeStatus">Complete</p>
                        <br/><br/>
                        <hr />
                        <br/>
                        <p><h3 className="bronze">Research the best software for 3D drawing</h3></p>
                        <p>For this project, I needed images in glb/gltf format. Most sources pointed to Unity, Blender or SketchUp.</p>
                        <p>I have worked a little with Blender before but a very long time ago! All require either specialist software or subscriptions, or both.</p>
                        <p>Whilst looking at tutorials on YouTube I came across one for Paint3D. This is installed as a default app on my laptop so would cost nothing, is lightweight, basic and would do what I needed without too much heavy learning.</p>
                        <p>So, Paint3D it is!</p>
                        <br/>
                        <p className="challengeStatus">Complete</p>
                        <br/><br/>
                        <hr />
                        <br/>
                        <p><h3 className="bronze">Follow tutorials on how to create 3D drawings for use with AR</h3></p>
                        <p>I found a tutorial on YouTube that covered <a href="https://www.youtube.com/watch?v=xjx-y7N8XJI" target="_blank" rel="noopener noreferrer">how to draw 3d cookies in Paint 3D</a> - it was as good a place to start as any!</p>
                        <p>This was enough to show me the basics, so off I went!</p>
                        <br/>
                        <p className="challengeStatus">Complete</p>
                        <br/><br/>
                        <hr />
                        <br/>
                        <p><h3 className="bronze">Create a 3D image and load into the project</h3></p>
                        <p>My very first attempt at 3D drawing for this project was a crisp packet! It's not perfect, it needs detail, but it's recognisable (I think!).  You can see my reference picture at the top.</p>
                        <p>I was particularly pleased with my crisp!!</p>
                        <p>I branded my crisps as "Crabbies" because the logo for the group this app will be used for is a crab.</p>
                        <br/>
                        <div className="challengeGallery">
                            <img className="challengeImg" src={Challenge4} alt="3d drawing of a crisp packet" />
                            <img className="challengeImg" src={Challenge5} alt="3d drawing of a crisp" />
                            <img className="challengeImg" src={Challenge6} alt="3d drawing of a crisp packet" />
                        </div>
                        <br/>   
                        <p className="challengeStatus">Complete</p>
                        <br/><br/>
                        <hr />
                        <br/>
                        <p><h3 className="bronze">Test project to see the image working</h3></p>
                        <p>Here's my crisp packet floating around my front room.</p>
                        <p>The second item I attempted to draw was a bottle. You can see me testing it here in the hotel room we stayed in for My Adventure! The other bottle was an earlier iteration with an image file I downloaded.</p>
                        <p>I've also tested using co-ordinates at various beach cleans and litter picks. Magnemite is shown here in the actual location where this app will operate.</p>
                        <p>There are some issues with rotation and scaling, but I can iron those out.</p>
                        <br/>
                        <div className="challengeGallery">
                            <img className="challengeImg" src={Challenge7} alt="3d crisp packet floating in a real world scenario using AR technology" />
                            <img className="challengeImg" src={Challenge8} alt="3d bottle floating in a real world scenario using AR technology" />
                            <img className="challengeImg" src={Challenge9} alt="3d Dragonite floating in a real world scenario using AR technology" />
                            <img className="challengeImg" src={Challenge10} alt="3d Magnemite floating in a real world scenario using AR technology" />
                            <img className="challengeImg" src={Challenge11} alt="3d bottle floating in a real world scenario using AR technology" />
                            <img className="challengeImg" src={Challenge12} alt="3d bottle floating in a real world scenario using AR technology" />
                        </div>
                        <br/>
                        <p className="challengeStatus">Complete</p>
                        <br/><br/>
                        <hr />
                        <br/>
                        <p><h3 className="bronze">Once the basics are in place, look at extending functionality.</h3></p>
                        <p>The tutorial code was working fine but it was time to learn more about it. I wanted to have a button for people to press to find out about the item they had found, but that bit wasn't working well because I didn't want it on screen all the time, only when an item was nearby.</p>
                        <br/>
                        <p>I wasn't able to get that working satisfactorily, so I tried attaching text to the 3d models. As you can see in one of the images above that was also less than ideal.</p>
                        <br/>
                        <p>During the process of reading the documentation for the functions, I realised the base code had been updated since I first started and needed to be changed in my project. I added the updated scripts and made sure everything was still working.</p>
                        <br/>
                        <p>I found that there were new features, including a cursor function that enabled click events on elements. I updated my code to use the new tags and wrote some new javascript to handle user clicks on the objects.  Even better, it triggers when you're looking at the item, not only on click.</p>
                        <br/>
                        <p>Once I had that in place, I thought about something I hadn't previously considered - a way for people to record what they were finding. I didn't want to have to give out clipboards etc. so I thought how this could be done in the same app.</p>
                        <br/>
                        <p>So, I added a recording form for people to use that can be hidden and shown as required to keep the screen estate free for object hunting. I added the ability to download the entered answers as text so they could be saved and shown at the end if the screen was cleared in error.</p>
                        <br/>
                        <p>Here are some pictures of the bottle in the cursor, the popup with some minimal test data and the recording form.</p>
                        <br/>
                        <div className="challengeGallery">
                            <img className="challengeImg" src={Challenge14} alt="Popup message in an AR app" />
                            <img className="challengeImg" src={Challenge13} alt="3d bottle floating in a real world scenario using AR technology" />
                            <img className="challengeImg" src={Challenge15} alt="Additionaol functionality for recording results in an AR app" />
                        </div>
                        <p className="challengeStatus">Complete</p>
                        <br/><br/>
                        <hr />
                        <br/>
                        <p><h3 className="bronze">Create a plan for the app - what litter items to include, what facts about each to include</h3></p>
                        <p>I am going to include 5 items to find.</p>
                        <ul className="challengeList">
                            <li>Crisp packet - take 75-80 years to break down</li>
                            <li>Plastic bottle - takes at least 450 years to break down</li>
                            <li>Fork - plastic cutlery takes 200 years to break down</li>
                            <li>Straw - takes 100 to 200 years to break down</li>
                            <li>Sauce Sachet - takes 20 to 50 years to break down</li>
                        </ul>
                        <br/>
                        <p>The simple stats shown above are what will be included in the app.  With limited screen space short is better.</p>
                        <br/>
                        <p>Sources:</p>
                        <ul className="challengeList">
                            <li>Crisps - <a href="https://www.ibtimes.co.uk/this-how-many-years-it-takes-these-everyday-items-decompose-1650426" target="_blank" rel="noopener noreferrer">https://www.ibtimes.co.uk/this-how-many-years-it-takes-these-everyday-items-decompose-1650426</a></li>
                            <li>Bottles - <a href="https://www.thegoodforco.com/how-long-does-it-take-for-a-plastic-bottle-to-break-down/" target="_blank" rel="noopener noreferrer">https://www.thegoodforco.com/how-long-does-it-take-for-a-plastic-bottle-to-break-down/</a></li>
                            <li>Cutlery - <a href="https://www.watergen.com/blog/the-price-we-pay-for-convenience-the-true-impact-of-plastic-cutlery/" target="_blank" rel="noopener noreferrer">https://www.watergen.com/blog/the-price-we-pay-for-convenience-the-true-impact-of-plastic-cutlery/</a></li>
                            <li>Straw - <a href="https://nationwideplastics.net/product/how-long-does-it-take-for-plastic-straws-to-decompose.html" target="_blank" rel="noopener noreferrer">https://nationwideplastics.net/product/how-long-does-it-take-for-plastic-straws-to-decompose.html</a></li>
                            <li>Sauce Sachet - <a href="https://havenhillcuisine.com/the-plastic-pollution-crisis-how-long-does-it-take-for-ketchup-packets-to-biodegrade/" target="_blank" rel="noopener noreferrer">https://havenhillcuisine.com/the-plastic-pollution-crisis-how-long-does-it-take-for-ketchup-packets-to-biodegrade/</a></li>
                        </ul>
                        <br/>
                        <p>There is a new page on the group website that contains more information including the source links for people to read more. 
                        <br/>You can visit the page here: <a href="https://mudefordquaycleanup.org.uk/play" target="_blank" rel="noreferrer noopener">Mudeford Quay Cleanup Crew - Play</a></p>
                        <br/><br/>
                        <p className="challengeStatus">Complete</p>
                        <br/><br/>
                        <hr />
                        <br/>
                        <p><h3 className="bronze">Create the 3D drawings needed</h3></p>
                        <p>I started with the crisp packet and the water bottle. I tried to do a can, but it proved too tricky for my current knowledge so I moved on and created the fork, straw and sauce sachet!</p>
                        <br/><br/>
                        <img src={Challenge16} alt="Objects drawn using 3d software" className="imgHalf" />
                        <p className="challengeStatus">Complete</p>
                        <br/><br/>
                        <hr />
                        <br/>
                        <p><h3 className="bronze">Include custom drawings and related information into the project</h3></p>
                        <p>Crisp packet and water bottle included and tested.</p>
                        <p>14/1/2023: Fork, Straw and Sauce Sachet added.</p>
                        <br/>
                        <img src={Challenge17} alt="Screenshot of a Javascript file showing assets added to a project." className="imgHalf" />
                        <br/><br/>
                        <p className="challengeStatus">Complete</p>
                        <br/><br/>
                        <hr />
                        <br/>
                        <p><h3 className="bronze">Test! Tweak! Re-test!</h3></p>
                        <p>I have been testing at each stage of the process so far and tweaking as required.</p>
                        <p>I have played with the rotation, scaling and camera options to understand and apply the optimal settings.</p>
                        <br/>
                        <p>On 15th Jan I updated the code with location tags for where I would be at my beach clean so that I could test my updated app in the wild!</p>
                        <p>I'm pleased to say it all worked well!  I had 3 locations, I found three items at varying distances, as it should be. You may not spot the fork in the last image, it's very small because it's distant, but I just managed to spot it!</p>
                        <br/>
                        <img className="imgQuarter" src={Challenge20} alt="Screenshot of the result of finding an AR crisp packet at a real beach location" />
                        <img className="imgQuarter" src={Challenge21} alt="Screenshot of a 3d model crisp packet superimposed on a real beach location" />
                        <img className="imgQuarter" src={Challenge22} alt="Screenshot of a 3d model bottle superimposed on a real beach location" />
                        <img className="imgQuarter" src={Challenge23} alt="Screenshot of a 3d model fork superimposed on a real beach location" />
                        <br/><br/>
                        <p className="challengeStatus challengeStatusIP">In Progress</p>
                        <br/><br/>
                        <hr />
                        <br/>
                        <p><h3 className="bronze">Create a QR code for people to scan to access to app at litter pick events</h3></p>
                        <p>Source files have been moved to their final destination and the QR code has been created!</p>
                        <br/>
                        <img className="imgHalf" src={Challenge18} alt="QR Code Generator screenshot" />
                        <br/>
                        <img className="imgHalf" src={Challenge19} alt="QR Code Generator editing screen" />
                        <br/>
                        <p className="challengeStatus">Complete</p>
                        <br/><br/>
                        <hr />
                        <br/>
                        <p><h3 className="bronze">Find someone willing to test</h3></p>
                        <p>After a beach clean today, I dragged my brother with me to the final location and we both activated the app and walked around hunting for virtual litter!!</p>
                        <br/>
                        <p>All in all, it went very well!  I have a couple of tweaks to make - we found that some of the objects were too small, so I need to increase the size of the 3d objects.</p>
                        <br/>
                        <p>We also found that the locations were too close - I need to adjust the sensitivity so that objects don't appear all at once.</p>
                        <br/>
                        <p>The only other thing I realised was that I hadn't picked the best spots - for example, I placed one near the play park, but I hadn't considered that pointing your camera at the play park is probably not the best approach, so I'll be moving that one!</p>
                        <br/>
                        <p>We also got caught by a very confused RNLI team member at the Lifeboat Station when we were looking for an object around there! Note to self - be more careful at positioning away from points of interest!</p>
                        <br/>
                        I'm happy though, this went well.
                        <br/><br/>
                        <p className="challengeStatus">Complete</p>
                        <br/><br/>
                        <hr />
                        <br/>
                        <p><h3 className="bronze">Adjust for feedback</h3></p>
                        <p>I have made these changes:</p>
                            <ul className="list">
                                <li>Increased the scaling on the smaller items</li>
                                <li>Re-located the item near the play park to point in the opposite direction!</li>
                                <li>Re-located the item near the lifeboat station</li>
                                <li>Added the bonus item - Crabbie!</li>
                                <li>Updated the page title so it displays correctly and identifies the page correctly</li>
                                <li>Updated the play page on the site to include the QR code for convenience</li>
                            </ul>
                            <br/>
                        <p className="challengeStatus">Complete</p>
                        <br/><br/>
                        <hr />
                        <p><h3 className="bronze">Final Notes</h3></p>
                        <p>As a "prize" for completing the virtual litter hunt, I got these plantable pencils! I wanted to make sure that any reward wasn't going to contribute to the litter problem and I like this idea. When you have used up the pencil, just plant the end in the soil and it will sprout whatever seed it is labelled with</p>
                        <br/>
                        <img className="imgThird" src={Challenge24} alt="Plantable pencils in a box" />
                        <img className="imgThird" src={Challenge25} alt="Plantable pencils showing their seed contents" />
                        <br/>
                        <p className="challengeStatus">Complete</p>
                        <br/><br/>
                        <hr />
                        <br/><br/>
                        <p><h3 className="bronze">Project is live!</h3></p>
                        <p>Technically, my little app is live!!</p>
                        <p>You can view the play page here for the live links:</p>
                        <a href="https://mudefordquaycleanup.org.uk/play" target="_blank" rel="noopener noreferrer">Virtual Litter Hunt</a>
                        <br/><br/>
                        <p className="challengeStatus">Complete</p>
                        <br/><br/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyChallenge