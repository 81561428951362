import React from 'react';
import {Link} from 'react-router-dom';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Roulette from '../images/bebrave-1.jpg';

const  Brave24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Be Brave"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>For this clause I completed my <Link to="/fundraiser">Fundraiser</Link> badge.</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>For this clause I completed my <Link to="/palaeontologist" className="linkUnderline">Palaeontologist</Link> badge.</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>For this clause I completed <Link to="/healthcheck24" className="linkUnderline">Rebel health Check 2024</Link></p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 4</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>For this clause, I ordered badge roulette and started <Link to="/musician">Musician</Link> and <Link to="/journalist">Journalist</Link>.</p>
                        <br/>
                        <img src={Roulette} alt="Five random badges displayed around a Badge Roulette badge" className="imgThird" /> 
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 5</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p className="hidden">Every week, do something that scares you</p>
                        <p>This clause is personal and I won't be sharing evidence here.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Brave24