import React from 'react'
import { Link } from 'react-router-dom';

export default function ActionBar({area}) {
    let pageArea = "/bookone";
    if(area === undefined)
    {
        area = "bookone";
    }
    else if (area !== null)
    {
        pageArea = "/" + area;
    }
    else
    {
        area = "bookone";
    }

    if (area === "bookone")
    { 
        area = "Book One";
    }
    else if (area=== "booktwo")
    {
        area = "Book Two";
    }
        else if (area=== "extras")
    {
        area = "Extras";
    }
    
    return (
        <div className="actionBar">
            <div className="actionBarItem"><Link to={pageArea}>Back to {area}</Link></div>
            <div className="actionBarItem"><Link to="/">Back to Dashboard</Link></div>
        </div>
    );
}