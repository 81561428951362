import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const SelfCare = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Self Care"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">02</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Sleep</div>
                    <div className="badgePageItemEvidence">
                        This is something that I have already worked out through trial and error! I have to get 7 hours of sleep a night. I follow a routine quite rigidly - I have to be up at 6am so I'm asleep by 11pm usually. Quite often I'm awake before 6am, certainly in the summer, so I don't always need the full 7 hours.
                        <br/><br/>
                        It does all go quite wrong if I don't maintain a strict routine though!  I'm a migraineur and tiredness is a big problem, so sleep along with other factors is important. Disrupting the cycle of sleep brings untold pain, so I'm good at being disciplined!
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">03</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Bedtime</div>
                    <div className="badgePageItemEvidence">
                        This, again, is something I have done and changed. These days, I go to bed, turn off the light and that's usually game over! This is quite astounding given that for the first 40+ years of my life my sleep was terrible, even as a child. I would read for hours, listen to the radio, listen to music but no more. In the last few years it has been a remarkable change and I usually sleep well now.
                        <br/><br/>
                        Occassionally I still have bad nights where my brain won't settle. Then I tend to do crosswords until I find myself nose-down in the clues!! 
                        <br/><br/>
                        I do suffer from nightmares sometimes, then I have to turn on a light a music until I can settle again. 
                        <br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">04</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Diet</div>
                    <div className="badgePageItemEvidence">
                        This is something that I have done as well. For many years I have eaten a super low carb diet but I wanted to switch to vegetarian - I won't do Vegan because I love eggs and cheese too much!
                        <br/><br/>
                        I did switch to a 100% vegetarian diet for a few months, but I started putting on weight and my body wasn't adjusting so I had to quit it and go back to the low carb.
                        <br/><br/>
                        Now, I'm more of a flexitarian. I eat vegetarian or vegan meals as often as possible. I've never been a fan of cooking - if I can't stick it in the over for 20 minutes and walk away I'm not interested - but that really changed when I switched to the veggie diet. I was googling recipes and had to make meals from scratch every night and I found that I loved it! 
                        <br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">05</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Screen Time</div>
                    <div className="badgePageItemEvidence">
                        This is definitely something I need to re-instate. I was very good for a while with no TV during the week, I go through good weeks and bad weeks. As long as I have something else to focus on I'm not bothered about screens so the badges are a perfect distraction!
                        <br/><br/>
                        Social media is my downfall. I don't really use my Facebook account any more for personal updates, I have to have it for other things. I love my Instagram and Twitter though, so I have to have a dedicated Social media time to catch up with the world before I get my head down again!
                        <br/><br/>
                        <table>
                            <thead>
                                <tr>
                                    <td>Date</td>
                                    <td>TV / Social Media time</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>23/8/2022</td>
                                    <td>1 hour after work for wind-down time</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">06</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Pamper</div>
                    <div className="badgePageItemEvidence">
                        This isn't something I'm great at, I'm not really into beauty things. I do ocassionally do an at-home pamper on a weekend evening - I used to have a Sunday  night pamper and I'm re-instating that for my mindfulness badge.
                        <br/><br/>
                        After a month I'll stop recording, it will be habit by that point and not necessary to keep recording dates!
                        <br/><br/>
                        <table>
                            <thead>
                                <tr>
                                    <td>Date</td>
                                    <td>Complete</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>21/8/2022</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>28/8/2022</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>4/9/2022</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>11/8/2022</td>
                                    <td>Yes</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelfCare