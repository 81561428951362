import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Brain1 from '../images/braingames1.jpg';
import Brain2 from '../images/braingames2.jpg';
import Brain3 from '../images/braingames3.jpg';
import Brain4 from '../images/braingames4.jpg';
import Brain5 from '../images/codebreaker7.jpg';


const BrainGames = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"Brain Games"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemInfo reverse hidden">2hrs a week for 8 wks complete different brain games</div>
                    <div className="badgePageItemEvidence">
                        <p>I love brain games!!  I do them regularly every week, so this was easy to complete and I have done this consistently for 2hrs a week during this time.</p>
                        <br/><br/>
                        <p>Here are my current favourite books:</p>
                        <br/>
                        <img className="imgThird" src={Brain1} alt="Cryptic crossword book" />
                        <img className="imgThird" src={Brain2} alt="Nautical Puzzles book" />
                        <img className="imgThird" src={Brain3} alt="GCHQ Puzzle Book" />
                    </div>
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemInfo reverse hidden">At least 5 different types</div>
                    <div className="badgePageItemEvidence">
                        During this time I have completed the following:
                        <ul className="list">
                            <li>Crosswords</li>
                            <li>Cryptic crosswords</li>
                            <li>Codewords</li>
                            <li>Sudoku</li>
                            <li>Jigsaw</li>
                            <li>Suguru</li>
                            <li>Slitherlink</li>
                            <li>Various GCHQ puzzles from the GCHQ Puzzle Book</li>
                            <li>Various Nautical puzzles from The Nautical Puzle Book</li>
                        </ul>
                    </div>
                </div>
            </div>          
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemInfo reverse hidden">Two types you have never played before</div>
                    <div className="badgePageItemEvidence">
                        <ul className="list">
                            <li>Suguru</li>
                            <li>Slitherlink</li>
                        </ul>
                        <br/>
                        <img className="imgThird" src={Brain4} alt="Completed Suguru and Slitherlink puzzles printed on A4 paper laid on a table" />
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 4</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemInfo reverse hidden">At least one with another person</div>
                    <div className="badgePageItemEvidence">
                    <p>Twice a week I do crosswords with Dad over Skype - Jumbo crossword on Sunday evenings and cryptic crossword on Wednesday evenings.</p>
                    <br/>
                    <p>I also help Mum with her jigsaw whenever I go over there!</p>
                    <br/>
                    Here's a pile of crosswords that we have completed:
                    <br/>
                    <img className="imgThird" src={Brain5} alt="Completed crossword grids in a pile on a floor" />
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default BrainGames