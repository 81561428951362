import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const  June24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Carbon Footprint"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle darkblue">The concept</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light"></div>
                    <div className="badgePageItemEvidence"> 
                       Learn about the origins of the carbon footprint concept and how and why it was brought into public usage
                       <p>The concept of the carbon footprint started in the late 1990s, influenced by the rising concern over climate change and greenhouse gas emissions. It evolved from the "life cycle assessment", which is an environmental management methodology and one of the frist ways to measure the impact of a product or system over its entire lifetime.  There were various reasons for bringing it into public usage, including:</p>
                       <ul className="list">
                        <li>Climate Change Awareness - the primary reason. Aiming to raise awareness of how we contribute to climate change individually and collectively. Quantifying an individual impact allows lifestyle choices to be made.</li>
                        <li>Behavioural Change - intened to encourage people to make changes to their environmental impact</li>
                        <li>Policy and Corporate Responsibility - enabling businesses to calculate their CF, develop sustainability reporting and showing their commitment to reducing their impact</li>
                        <li>Educational tool - used to teach about the sources of greehouse gases and the importance of reducing emissions</li>
                        <li>Marketing and Greenwashing - CF concept adopted by some companies as part of marketing strategies, to appeal to people who are environmentally conscious. Unfortunately in some cases this leads to "greenwashing" where the environmental benefits are overstated or misleading</li>
                       </ul>
                       <br/><br/>
                       <p>It has had a positive impact in that it has led to greater understanding of climate change and improved efforts to reduce emissions, driving innovation in renewable energy, energy efficiency and sustainable practices.  However, it can be argued that the focus on individual footprints takes responsibility away from the larger issues and actions of the largest polluters. There is also a concern that it overfsimplifies the complex factors that contribute to climate chage.</p>
                        <p>The CF concept has played a significant role in conversations around the environment, making the issues more relatable to the general public.</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle darkblue">Individual Impact</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p><strong>Carbon Footprint Calculation</strong></p>
                        <p>I'm not sharing the breakdown here, but my footprint is below the average, largely because I don't travel and am at home the majority of the time. I don't generally buy a lot of goods, clothes, products etc., I don't overheat my house or leave appliances on or on standby, so my footprint is mostly heat / electric / internet services / food.</p>
                        <br/>
                        <p><strong>Reduction Plans</strong></p>
                        <p>I am limited to how much I can reduce my footprint, but I will continue to look at these areas to try and make more improvements:</p>
                        <ul className="list">
                            <li>Food waste - I do unfortunately find that veg often gets wasted!</li>
                            <li>Re-using and upcycling - I already recycle as much as I can, and buy second hand where possible</li>
                            <li>Digital detox - I do store too much electronic information, so I can look to reduce that</li>
                            <li>Household waste - I would like to find ways to reduce this</li>
                        </ul>
                       <br/>
                       <p><strong>Getting started</strong></p>
                       <p>Reducing my impact on the planet is something that I have been trying to do for quite some time now. What I will be continuing to do this month is:</p>
                       <ul className="list">
                        <li>Try to reduce food waste even more. I meal plan and freeze as much as I can every month, veg suffer as I buy monthly and they don't always last.</li>
                        <li>Upcycle and re-use clothing rather than buying new to create a new, exciting wardrobe!</li>
                        <li>Go through my email inboxes and delete all unnecessary items. I've already removed everything from the cloud, so I only have local storage backups.</li>
                        <li>Try to find a way to reduce household waste or at least make sure that recycling is maximised.</li>
                        <li>Ensure when buying online that the companies offset their carbon emissions and are environmentally conscious. Support local and UK businesses.</li>
                        <li>Continue to make my own beauty and cleaning products</li>
                       </ul>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle darkblue">Community Impact</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>I didn't have to complete both individual and community impact sections, but as I'm limited to what I can do personally I have chosen four of the clauses from the community section as well.</p>
                        <p><strong>Important:</strong> The information below is sourced from ChatGPT and Bing Co-Pilot, I don't claim it as being anything original, it's used here as a summary of the research I have done only.</p>
                        <br/><br/>
                        <p><strong>Research the impact of clean air zones in cities</strong></p>
                        <p>Air pollution creates serious health risks in cities, CAZs are dedicated areas within cities that aim to improve air quality by reducing pollution from vehicles.</p>
                        <br/>
                        <p>How they work:</p>
                        <ul classname="list">
                            <li>Vehicle Restrictions: Vehicles that do not meet specific emission standards are either restricted from entering the zone or are subject to a fee.</li>
                            <li>Emission Standards: Standards vary by zone but generally include regulations for nitrogen dioxide (NO2) and particulate matter (PM).</li>
                            <li>Monitoring and Enforcement: Cities use technologies such as Automatic Number Plate Recognition (ANPR) cameras to monitor and enforce CAZ regulations</li>
                            <li>Charges and Penalties: Vehicles that fail to meet the standards may pay daily charges to enter the zone. Penalties may apply for non-compliance.</li>
                            <li>Public Awareness Campaigns: Cities often run campaigns to inform the public about the CAZ, its boundaries, and the benefits of compliance.</li>
                        </ul>
                        <br/>
                        <p>Where are they implemented:</p>
                        <ul className='list'>
                            <li>Europe: The UK has several CAZs, including in cities like London, Birmingham, and Leeds. Germany has Low Emission Zones (LEZ) in over 70 cities, including Berlin and Stuttgart.</li>
                            <li>North America: New York City and Los Angeles are exploring or implementing similar concepts, often under different terminologies like Low Emission Zones.</li>
                            <li>Asia: Cities like Beijing and Delhi have implemented various forms of vehicle restrictions to combat severe air pollution.</li>
                            <li>Australia: Sydney and Melbourne have considered similar measures but are in earlier stages of implementation.</li>
                        </ul>
                        <br/>
                        <p>Pros of Clean Air Zones</p>
                        <ul className='list'>
                            <li>Significant reductions in NO2, PM, and other pollutants.</li>
                            <li>Lower incidences of respiratory diseases, cardiovascular conditions, and other health issues.</li>
                            <li>Decreases greenhouse gas emissions</li>
                            <li>Promotes the use of public transport, cycling, and walking.</li>
                            <li>Stimulates the market for cleaner vehicles, including electric and hybrid options.</li>
                            <li>Potential long-term healthcare savings and productivity gains from a healthier population.</li>
                        </ul>
                        <br/>
                        <p>Cons of Clean Air Zones</p>
                        <ul className='list'>
                            <li>Can be financially burdensome for individuals and businesses relying on older, non-compliant vehicles.</li>
                            <li>High costs associated with setting up the infrastructure and technology for monitoring and enforcement.</li>
                            <li>Risk of displacing pollution to areas outside the CAZ, leading to potential "pollution havens."</li>
                            <li>Could disproportionately affect low-income individuals who may not afford newer, compliant vehicles.</li>
                            <li>Requires extensive planning, public consultation, and often faces political and public resistance.</li>
                            <li>Ensuring compliance and managing violations can be administratively complex.</li>
                        </ul>
                        <br/>
                        <p>Benefits of Clean Air Zones</p>
                        <ul className='list'>
                            <li>Direct correlation between cleaner air and improved public health outcomes.</li>
                            <li>Lower incidences of pollution-related illnesses reduce the burden on healthcare systems.</li>
                            <li>Cleaner environment contributes to overall well-being and attractiveness of urban areas.</li>
                            <li>Supports broader environmental goals, including biodiversity conservation and climate change mitigation.</li>
                            <li>Can stimulate local economies by creating demand for green technologies and infrastructure.</li>
                            <li>Cities that implement CAZs position themselves as leaders in sustainability and innovation.</li>
                        </ul>
                        <br/>
                        <p>Success stories</p>
                        <ul className='list'>
                            <li>Cities that implement CAZs position themselves as leaders in sustainability and innovation.</li>
                            <li>Stockholm's Congestion Tax Zone: Although not a CAZ per se, Stockholm's congestion tax has led to improved air quality and traffic reduction, illustrating the potential benefits of similar measures.</li>
                        </ul>
                        <br/><br/>
                        <p><strong>Research some of the natural alternatives to trees for reducing carbon dioxide levels</strong></p>
                        <p>There are several alternatives to trees for managing carbon dioxide levels:</p>
                        <ul className='list'>
                            <li>Soil Carbon Sequestration - improves soil health, enhances water retention and reduced erosion.</li>
                            <li>Marine Algae and Seaweed Farming - Provides marine habitat, improves water quality, can be harvested for commercial use, promotes economic benefits</li>
                            <li>Wetlands Restoration - Mangroves, peatlands and marshes, provide critical habitat, improve water quality by filtering pollutants, enhances flood control</li>
                            <li>Grasslands Management - supports biodiversity and soil health, reduces erosion, improves water infiltration</li>
                            <li>Kelp Forests - provides habitat for marine organisms, protects shorelines from erosion</li>
                            <li>Blue Carbon Ecosystems - supports biodiversity and coastal protection, improves water wuality, supports fisheries</li>
                            <li>Biochar - improves soil fertility and health, enhances water retention and nutrient availability in soils.</li>
                            <li>Agroforestry - enhances biodiversity and soil health, provides additional income streams for farmers through the production of timber, fruits and nuts.</li>
                            <li>Restoration of Degraded Lands - Restores ecosystems and enhances biodiversity, increases land productivity and resilience to climate change</li>
                        </ul>
                        <br/>
                        <p><strong>Find out the difference between carbon-offsetting and carbon zero</strong></p>
                        <br/>
                        <p>Carbon Offsetting</p>
                        <p>Involves compensating for CO2 emissions by funding projects that remove an equivalent amount of CO2 from the atmosphere.Includes renewable energy projects, reforestation, energy efficiency initiatives.</p>
                        <p>People calculate their carbon footprint, purchase carbon credits equivalent to their emissions, the finds from these purchases support projects that reduce or sequester carbon.</p>
                        <p>e.g. Investing in renewable energy, planting trees, methan capture projects</p>
                        <p>Does not reduce actual emissions at source, used as a transitional measure towards more sustainable practices.</p>
                        <br/><br/>
                        <p>Carbon Zero (Carbon Neutrality)</p>
                        <p>Achieved by balancing emitted CO2 with an equivalent amount of CO2 removal or offsetting.</p>
                        <p>Reduce emissions as much as possible, offset what remains through purchase of carbon credits with a goal of net zero.</p>
                        <p>Measure -&gt; Reduce -&gt; Offset -&gt; Certify</p>
                        <br/><br/>
                        <p>Key differences</p>
                        <ol className="list">
                            <li>Scope and Approach
                                <ul>
                                    <li>Carbon Offsetting: Primarily focuses on compensating for emissions by funding external projects.</li>
                                    <li>Carbon Neutrality: Involves a comprehensive approach of measuring, reducing, and offsetting emissions to achieve net zero.</li>
                                </ul>
                            </li>
                            <li>Goals
                                <ul>
                                    <li>Carbon Offsetting: Often used as a part of a broader strategy, especially when immediate reduction of emissions is not feasible.</li>
                                    <li>Carbon Neutrality: Aims for a balance between emissions produced and emissions removed, striving for a zero net impact on the atmosphere.</li>
                                </ul>
                            </li>
                            <li>Long-term Impact
                                <ul>
                                    <li>Carbon Offsetting: Seen as an interim solution that does not address the root cause of emissions.</li>
                                    <li>Carbon Neutrality: Encourages sustainable practices and systemic changes to reduce overall emissions.</li>
                                </ul>
                            </li>
                        </ol>
                        <br/><br/>
                        <p>Sources: </p>
                        <ol className="list">
                            <li>United Nations Framework Convention on Climate Change (UNFCCC): Provides detailed guidelines and standards for carbon offsetting and carbon neutrality</li>
                            <li>Carbon Trust: Offers definitions and guidance on carbon management practices, including carbon neutrality and offsetting.</li>
                            <li>Environmental Protection Agency (EPA): Outlines methods for measuring and reducing carbon footprints, and explains the role of carbon offsets in achieving carbon neutrality.</li>
                        </ol>
                        <br/><br/>
                        <p><strong>Research new technology to reduce the amount of carbon in the atmosphere</strong></p>
                        <p>Emerging technologies include Carbon Dioxide Removal (CDR) or Negative Emissions Technologies (NETs).</p>
                        <br/>
                        <p>Direct Air Capture (DAC)</p>
                        <p>Directly removes CO2 from ambient air. Air passes through a chemical solution that selectively captures CO2. The CO2 is then released, purified, stored or used.</p>
                        <br/>
                        <p>Enhanced Weathering</p>
                        <p>Accelerates the natural process of weathering minerals that absorb CO2. Crushed silicate rocks are spread over large areas, minerals react with CO2 in the atmosphere, forming stable carbonates.</p>
                        <br/>
                        <p>Bioenergy with Carbon Capture and Storage (BECCS)</p>
                        <p>Combines bioenergy production with CO2 capture and storage. Emissions generated during production of bioenergy and captured and stored underground.</p>
                        <br/>
                        <p>Ocean Fertilisation</p>
                        <p>Adding nutrients to the ocean to stimuate phytoplankton growth which absorbs CO2. Iron or nitrogen is added to surface waters, phytoplankton absorbs CO2, part of the biomass sinks to the ocean floor, storing carbon.</p>
                        <br/>
                        <p>Mineral Carbonation</p>
                        <p>Reacting CO2 with metal oxides or hydroxides to form stable carbonates. CO2 reacts with minerals like serpentine or olivine to form solid carbonates. Carbonates can be stored safely for long periods, locking away CO2.</p>
                        <br/><br/>
                        <p>Sources:</p>
                        <ul className='list'>
                            <li>National Academies of Sciences, Engineering, and Medicine: Reports on various Negative Emissions Technologies.</li>
                            <li>Intergovernmental Panel on Climate Change (IPCC): Assessments on the role of CDR in climate mitigation.</li>
                            <li>Carbon180: Non-profit organization focusing on carbon removal solutions.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default June24