import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Hat1 from '../images/cupmay23-hat1.jpg';
import Hat2 from '../images/cupmay23-hat2.jpg';
import Hat3 from '../images/cupmay23-hat3.jpg';
import Hat4 from '../images/cupmay23-hat4.jpg';
import Hat5 from '../images/cupmay23-hat5.jpg';
import Hat6 from '../images/cupmay23-hat6.jpg';
import Hat7 from '../images/cupmay23-hat7.jpg';
import Hat8 from '../images/cupmay23-hat8.jpg';
import Hat9 from '../images/cupmay23-hat9.jpg';

const CupMay23 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Rebel Cup Bonus Points"} />
            <div className="badgePageItem">Rebel Cup challenges are only posted in the facebook group for confirmed members. You will have to join the club to find out why I am doing these things!</div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Bonus 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light">Decorate a straw hat with a design using at least 10 buttons</div>
                    <div className="badgePageItemEvidence"> 
                        <p>My straw hat has ears, but that's ok, we can work with that.</p>
                        <br/>
                        <p>With the cornation this month, I decided to give my hat a coronation theme. I found a little gold cake-topper crown and used that to start the design.</p>
                        <br/>
                        <p>I didn't want to make permanent changes to my hat, so everything is removable! The crown is held in place by little gold pins. It is stuffed with dry oasis, covered with shiny purple wrapping paper, for a royal look.</p>
                        <br/>
                        <p>I went through my button collection and through the collections of buttons that my Mum fished out for me and pulled out all the purple buttons I could find.</p>
                        <br/>
                        <p>I used some gold craft wire to turn them in to something resembling flowers.</p>
                        <br/>
                        <p>I then arranged them inside the crown.</p>
                        <br/>
                        <p>The colour of the coronation was royal blue, but I didn't have any buttons in that colour, so I used some lighter blue to create some complimentary "flowers" with some light green buttons for "greenery" and laid this bunch in the hat's other ear!</p>
                        <br/><br/>
                        <p>Originally this was the end of the design, but on re-reading the clause I worried that this wasn't technically a "design". So, I sewed some more buttons onto some jute ribbon and placed it over the hat's black band.</p>
                        
                        <br/><br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Hat1} alt="Straw hat with ears before being decorated" /></div>
                            <div className="galleryItem"><img src={Hat2} alt="Straw hat with ears, with small gold crown placed around one ear and some purple button flowers laid on top." /></div>
                            <div className="galleryItem"><img src={Hat3} alt="Straw hat with ears, decorated with a small gold crown stuffed with dry oasis covered with purple shiny wrapping paper and purple button flowers." /></div>
                            <div className="galleryItem"><img src={Hat4} alt="Straw hat with ears, decorated with a small gold crown stuffed with dry oasis covered with purple shiny wrapping paper and purple button flowers." /></div>
                            <div className="galleryItem"><img src={Hat5} alt="Straw hat with ears, decorated with a small gold crown stuffed with dry oasis covered with purple shiny wrapping paper and purple button flowers." /></div>
                            <div className="galleryItem"><img src={Hat6} alt="Straw hat with ears, decorated with a small gold crown stuffed with dry oasis covered with purple shiny wrapping paper and purple button flowers." /></div>
                            <div className="galleryItem"><img src={Hat9} alt="Buttons laid out in flowers patterns on some jute ribbon." /></div>
                            <div className="galleryItem"><img src={Hat7} alt="Straw hat with ears, decorated with a small gold crown stuffed with dry oasis covered with purple shiny wrapping paper and purple button flowers." /></div>
                            <div className="galleryItem"><img src={Hat8} alt="Straw hat with ears, decorated with a small gold crown stuffed with dry oasis covered with purple shiny wrapping paper and purple button flowers." /></div>
                        </div>                    
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CupMay23