import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const Journalist = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"Journalist"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Task 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light"></div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div>   
        </div>
    )
}

export default Journalist