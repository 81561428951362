import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Scavenger1 from '../images/aug24-scavenger1.jpg';
import Scavenger2 from '../images/aug24-scavenger2.jpg';
import Scavenger3 from '../images/aug24-scavenger3.jpg';
import Scavenger4 from '../images/aug24-scavenger4.jpg';
import Scavenger5 from '../images/aug24-scavenger5.jpg';
import Scavenger6 from '../images/aug24-scavenger6.jpg';
import Scavenger7 from '../images/aug24-scavenger7.jpg';
import Scavenger8 from '../images/aug24-scavenger8.jpg';

const  August24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Scavenger Hunt"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle darkblue"></div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>Yes, I'm aware that I'm pushing it with one or two of these, but it's a bit of fun, it's self-assessed and I'm not doing super-scavenger.</p>
                        <p>One of my lighthouses is not technically a toy, it's a garden ornament but it is fully functioning with rotating light, so, included!!</p>
                        <br/>
                        <ul className="list">
                            <li>A full size flag with yellow in it, flying on a flag pole</li>
                            <li>A coin that has never had a monetary value</li>
                            <li>A chinese character which means an animal</li>
                            <li>A working lighthouse (not a toy)</li>
                            <li>The oldest expiry date in your kitchen</li>
                            <li>A postcard from another Rebel, received this month</li>
                            <li>A completely wooden toadstool</li>
                        </ul>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Scavenger1} alt="Lighthouse next to a pond"/></div>
                            <div className="galleryItem"><img src={Scavenger2} alt="Lighthouse from across the sea"/></div>
                            <div className="galleryItem"><img src={Scavenger3} alt="Plastic supermarket coin with a star imprint"/></div>
                            <div className="galleryItem"><img src={Scavenger4} alt="Lifeguard flags on a beach"/></div>
                            <div className="galleryItem"><img src={Scavenger5} alt="Lifeguard flag on a beach"/></div>
                            <div className="galleryItem"><img src={Scavenger6} alt="Postcard"/></div>
                            <div className="galleryItem"><img src={Scavenger7} alt="Jar of herbs showing an expiry date in 2006"/></div>
                            <div className="galleryItem"><img src={Scavenger8} alt="Wooden toadstools in a plant pot"/></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default August24