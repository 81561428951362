import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import AdvancedWriter1 from '../images/AdvancedWriter1.jpg';
import AdvancedWriter2 from '../images/AdvancedWriter2.jpg';
import AdvancedWriter3 from '../images/AdvancedWriter3.png';
import AdvancedWriter4 from '../images/AdvancedWriter4.png';


const AdvancedWriter = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"Advanced Writer"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Task 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>I have continued my poetry anthology for this clause, but I am not sharing it here, so you will have to trust that I have done it.</p>
                    </div>
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Task 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>Book covers</p>
                        <br/>
                        <img src={AdvancedWriter3} className="imgThird" alt="Cover of a book called My Life Became A Lyric" />
                        <img src={AdvancedWriter4} className="imgThird" alt="Cover of a book called Beyond the Lyric" />
                    </div>
                </div>
            </div>          
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Task 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>Here's my published first anthology and me showing it off alongside a treat from the printer!!!</p>
                        <br/>
                        <img className="imgThird" src={AdvancedWriter1} alt="A book called My Life Became A Lyric lying in a beam of sunlight on a hard wood floor" />
                        <img className="imgThird" src={AdvancedWriter2} alt="Me with my published anthology" />
                        <br/><br/>
                        <p>I did create a selfie to share as part of a competition with the printer, sadly I didn't win the iPad!</p>
                    </div>
                </div>
            </div> 
             <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Task 4</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        I have completed the following courses to satisfy this clause:
                        <br/>
                        <ul className="list">
                            <li>Secret Sauce of Great Writing </li>
                            <li>Write Dreamy And Imaginative Poetry With Creative Writing </li>
                            <li>Songwriting: Creative Lyric Writing Approaches </li>
                        </ul>
                        <br/>
                        These are free courses on Udemy and I have picked a selection to cover both general writing and more targetted lessons around the project topic.
                        <br/><br/>
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default AdvancedWriter