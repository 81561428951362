import React from 'react';
import { Link } from 'react-router-dom';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Writer1 from '../images/writer1.jpg';

const Writer = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Activist"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">08</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">A Poetry Anthology</div>
                    <div className="badgePageItemEvidence">
                        <p>This is something that I am happy to backdate. I have been writing poetry for years, although only sporadically in the last few.</p>
                        <br/>
                        <p>It will be nice to have that work count towards this badge.</p>
                        <br/><br/>
                        <p>In the spirit of badge work, and so that this isn't entirely backdated, I am including my <Link className="linkUnderline" to="/write22">Write Every Day</Link> badge as part of my anthology. That was a whole month of random poems so nicely provides new material!</p>
                        <br/><br/>
                        <p>I don't want to publish anything here, but just so there is something on this page, here's the book where all the secrets hide ;)</p>
                        <br/><br/>
                        <img className="imgThird" src={Writer1} alt="Hard back  Anotebook, with marbled black cover and wire binding" />
                        <br/><br/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Writer