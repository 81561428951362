import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const AdvancedCommunity = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"Advanced Community Service"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Extended Volunteering</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light"></div>
                    <div className="badgePageItemEvidence">
                        I have been volunteering consistently since 2018 and will be continuing to do that for the foreseeable future. All the details of my volunteer work can be found on my beach cleaning pages:
                        <br/><br/>
                        <ul className="list">
                            <li><a href="https://www.seashorty.co.uk/stats.aspx" target="_blank" rel="noopener noreferrer" className="linkUnderline">Seashorty</a></li>
                            <li><a href="https://www.mudefordquaycleanup.org.uk/stats" target="_blank" rel="noopener noreferrer" className="linkUnderline">Mudeford Quay Cleanup Crew</a></li>
                            <li><a href="https://www.highcliffebeachcleanteam.org.uk" target="_blank" rel="noopener noreferrer" className="linkUnderline">Highcliffe Beach Clean Team</a></li>
                            <li><a href="https://www.friarscliffbeachcare.org.uk" target="_blank" rel="noopener noreferrer" className="linkUnderline">Friars Cliff Beach Care</a></li>
                        </ul>
                    </div>
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Opportunity 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light"></div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div>          
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Opportunity 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light"></div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Opportunity 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light"></div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div> 
        </div>
    )
}

export default AdvancedCommunity