import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import PlasticBook1 from '../images/plasticbook1.jpg';
import PlasticBook2 from '../images/plasticbook2.jpg';
import PlasticBook3 from '../images/plasticbook3.jpg';
import PlasticBook4 from '../images/plasticbook4.jpg';
import PlasticBook5 from '../images/plasticbook5.jpg';
import PlasticMovie1 from '../images/PlasticMovie1.jpg';
import PlasticMovie2 from '../images/PlasticMovie2.jpg';
import Podcast1 from '../images/activist-podcast1.jpg';
import Podcast2 from '../images/activist-podcast2.jpg';
import Art1 from '../images/activist-art1.jpg';
import Art2 from '../images/activist-art2.jpg';
import Art3 from '../images/activist-art3.jpg';
import Art4 from '../images/activist-art4.jpg';
import Art5 from '../images/activist-art5.jpg';
import Art6 from '../images/activist-art6.png';
import Art7 from '../images/activist-art7.png';
import Art8 from '../images/activist-art8.png';
import Art9 from '../images/activist-art9.png';
import Art10 from '../images/activist-art10.png';
import Art11 from '../images/activist-art11.png';

const Activist = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Activist"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Identify the change you want to make</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Choose a social or political cause which is close to your heart. Have clear vision of the world you want to create.</div>
                    <div className="badgePageItemEvidence">
                        My activism area is environment, litter and plastics. I work hard to keep my local area clean from litter and run plastic free initiatives.
                        <br/><br/>
                        The world I want to create is one that we don't kill.  We need to protect our natural world, not destroy it.
                        <br/><br/>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Educate Yourself</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Read at least three books either about activism or your chosen cause and watch a documentary  or listen to a podcast about your chosen cause.</div>
                    <div className="badgePageItemEvidence">
                        This is something that I have already done, a few times over. Below are the books I have read and films I have watched.
                        <br /><br />
                        You will notice there are a couple of children's books here! I buy them occassionally because I hope to be able to take them along to various events for children to look at, I like to have resources for everyone.
                        <br /><br />
                        <table id="tblActivist">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Details</th>
                                    <th>Picture</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Book</td>
                                    <td>F**k Plastic - 101 ways to free yourself from plastic and save the world.</td>
                                    <td><img src={PlasticBook1} alt="Book titled F**k Plastic - 101 ways to free yourself from plastic and save the world." width="200px" height="200px" /></td>
                                </tr>
                                <tr>
                                    <td>Book</td>
                                    <td>No. More. Plastic. What you can do to make a difference by Martin Dorey, founder of the 2MinuteSolution.</td>
                                    <td><img src={PlasticBook2} alt="Book titled No More Plastic, what you can do to make a difference" width="200px" height="200px" /></td>
                                </tr>
                                <tr>
                                    <td>Book</td>
                                    <td>Kids Fight Plastic by Martin Dorey.</td>
                                    <td><img src={PlasticBook3} alt="Book titled Kids Fight Plastic" width="200px" height="200px" /></td>
                                </tr>
                                <tr>
                                    <td>Book</td>
                                    <td>How to give up plastic - a guide to changing the world one plastic bottle at a time, by Will McCallum, head of oceans, Greenpeace UK</td>
                                    <td><img src={PlasticBook4} alt="Book titled How to give up plastic" width="200px" height="200px" /> </td>
                                </tr>
                                <tr>
                                    <td>Book</td>
                                    <td>Herman Needs a Home by Lucy Noguera</td>
                                    <td><img src={PlasticBook5} alt="Book titled Herman Needs a Home" width="200px" height="200px" /></td>
                                </tr>
                                <tr>
                                    <td>Film</td>
                                    <td>The Plastic Sea - NetFlix</td>
                                    <td><img src={PlasticMovie1} alt="Movie poster showing the words A Plastic Ocean over an ocean background" width="200px" height="200px" /></td>
                                </tr>
                                <tr>
                                    <td>Film</td>
                                    <td>Seaspiracy - NetFlix</td>
                                    <td><img src={PlasticMovie2} alt="Movie poster showing the word Seaspiracy over a fishing net that has captured the planet earth" width="200px" height="200px" /></td>
                                </tr>
                                <tr>
                                    <td>Podcast</td>
                                    <td>Plastisphere</td>
                                    <td><img src={Podcast1} alt="Plastisphere podcast icon" width="200px" height="200px" /></td>
                                </tr>
                                <tr>
                                    <td>Podcast</td>
                                    <td>Age of Plastic</td>
                                    <td><img src={Podcast2} alt="Age of Plastic podcast icon" width="200px" height="200px"/></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Immerse Yourself</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Find like-minded people - either online or at meet-ups. Is there an organisation or charity you can become involved with?</div>
                    <div className="badgePageItemEvidence">
                        This is something I do already, I lead several groups where I meet plenty of like-minded people and I engage with the Marine Conservation Society, Surfers Against Sewage and 2MinuteBeachClean nationally and internationally and with our local campaign Litter Free Dorset.
                        <br/><br/>
                        I run the following groups:
                        <ul className="list">
                            <li>Mudeford Quay Cleanup Crew</li>
                            <li>Highcliffe Beach Clean Team</li>
                            <li>Friars Cliff Beach Care</li>
                            <li>Plastic Free Highcliffe-on-Sea</li>
                            <li>Christchurch Marine Conservation</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Show Up</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Attend a protest, rally, demonstration, town hall, talk or other meeting related to your chosen cause. If you can't attend in person, how else can you support them?</div>
                    <div className="badgePageItemEvidence">
                        I don't attend these in person but I do promote them through my social channels to help raise awareness. I also attend online talks when they are available and as I am a member of the Marine Conservation Society I attend their annual events.
                        <br/><br/>
                        I will continue to join local meetings in the coming year and engage more with the community.
                        <br/><br/>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Be Counted</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Sign at least one related petition and use your network to rally support for it</div>
                    <div className="badgePageItemEvidence">
                        I have signed several petitions related to my causes. The most recent ones have been to prevent sewage pollution in our rivers and seas.
                        <br /><br />
                        The latest one I signed was today and was a call to <a href="https://www.change.org/p/renationalise-the-water-industry" target="_blank" rel="noopener noreferrer" >re-nationalise the water service</a> due to the sewage problems currently.
                        <br /><br />
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Raise funds</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Run a fundraiser to support a charity or non-profit involved with your chosen cause. If you have the means, pledge a regular amount yourself.</div>
                    <div className="badgePageItemEvidence">
                        I am a member of Marine Conservation Society and Surfers Against Sewage and make a monthly donation to both.  
                        <br/><br/>
                        I don't run fundraisers, I don't have the network to make this viable, but I do contribute to and promote other campaigns or other people running campaigns and I have a Ko-Fi account to take donations which are used to fund kit for my cleanup groups.
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Activism and The Arts</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Study one example of how art was used to communicate a message and create culture change. Make a scrapbook or similar about your findings.</div>
                    <div className="badgePageItemEvidence">
                        An example of art in activism that I took part in is "The Creature" which was run by Surfers Against Sewage in 2019.  There was a social media build up with a teaser movie to catch people's interest. 
                        <br/><br/>
                        Here are some screenshots of the teasers in my Instagram feed on my beach cleaning account with the handle "Seashorty".
                        <br/><br/>
                        <div className="activistGallery">
                            <div className="activistItem"><img src={Art1} alt="Instagram grid showing posts about The Creature" /></div>
                            <div className="activistItem"><img src={Art2} alt="Instagram post showing The Creature" /></div>
                            <div className="activistItem"><img src={Art3} alt="Instagram post showing the text accompanying The Creature" /></div>
                            <div className="activistItem"><img src={Art5} alt="Instagram post shoing the text accompanying the video of The Creature" /></div>
                            <div className="activistItem"><img src={Art4} alt="Instagram post showing the video of The Creature" /></div>
                        </div>
                        <br/><br/>
                        The first posts were put out at the end of April 2019 to create a buzz online and the full video was released on 1st May 2019.
                        <br/><br/>
                        This was the caption: "In memory of #TheCreature. One marine mammal or sea bird dies every 30 seconds due to plastic pollution."
                        <br/><br/>
                        It was tagged with "GenerationSea" and linked to a petition to end plastic pollution: <a href="https://sas.org.uk/take-action/campaign-for-the-ocean/" target="_blank" rel="noopener noreferrer">Campaign for the Ocean</a>
                        <br/><br/>
                        This is the text that accompanied the video - as found on the video description on YouTube:
                        <br/><br/>
                        *******************************************************************************************************************************************
                        "What happens when a mysterious, beautiful ocean animal is found alive, but beached? 
                        <br/><br/>
                        The Creature in our film is an animal no one has ever seen before. Did you know that 95% of our oceans are unexplored? Both Mars and the Moon are better mapped than the ocean floor.
                        <br/><br/>
                        Incredible things are living in the deep, from remarkable animals to unique bacteria that could be the source of life-saving new antibiotics.
                        <br/><br/>
                        But with plastic pollution and climate change, we could be destroying sea life life faster than we’re able to discover it.
                        <br/><br/>
                        We made the Creature to wake people up. To shock, move and inspire them to join our movement for ocean protection, #GenerationSea."
                        <br/><br/>
                        *******************************************************************************************************************************************
                        <br/><br/>
                        The full video can be found here:
                        <br/><br/>
                        <a className="activistLink" href="https://www.youtube.com/watch?v=-0Ml8nrBJmY" target="_blank" rel="noopener noreferrer">Killed by plastic pollution: unknown animal washes up on Cornwall beach. #TheCreature</a>
                        <br/><br/>
                        This is the link to the Surfers Against Sewage content about The Creature:
                        <br/><a className="activistLink" href="https://sas.org.uk/updates/powerful-new-film-reveals-destruction-to-undiscovered-marine-life-in-battle-against-plastic-pollution/" target="_blank" rel="noopener noreferrer">Powerful new film reveals destruction to undiscovered marine life in battle against plastic pollution</a>
                        <br/><br/>
                        Here is an article from Surfer Today about the campaign:
                        <br/><a className="activistLink" href="https://www.surfertoday.com/environment/the-creature-lost-its-battle-against-plastic-pollution" target="_blank" rel="noopener noreferrer">"The Creature" lost its battle against plastic pollution</a>
                        <br/><br/>
                        Here is an article from Cornwall Live about the campaign:
                        <br/><a className="activistLink" href="https://www.cornwalllive.com/news/cornwall-news/surfers-against-sewage-moving-mysterious-2818193" target="_blank" rel="noopener noreferrer">Surfers Against Sewage moving mysterious creature film has a serious message</a>
                        <br/><br/>
                        SAS took the campaign to parliament, with a 15ft model of The Creature:
                        <br/><img src={Art6} alt="SAS campaigns outside Parliament" width="200px" className="imgQuarter" />
                        &nbsp;<img src={Art7} alt="SAS campaigns with The Creature" width="200px" className="imgQuarter" />
                        <br/><br/>
                        Here are a couple of the posts from Twitter on the hashtag TheCreature. I've left the accounts visible because the posts are public.
                        <br/><img src={Art8} alt="" width="200px" className="imgQuarter" />
                        &nbsp;<img src={Art9} alt="" width="200px" className="imgQuarter" />
                        &nbsp;<img src={Art10} alt="" width="200px" className="imgQuarter" />
                        &nbsp;<img src={Art11} alt="" width="200px" className="imgQuarter" />
                        <br/><br/>
                        I was proud to be a small part of sharing this campaign, it was very important to highlight the catastrophic damage we are inflicting on this world.
                        <br/><br/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Activist