import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const Walker = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"Walker"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Regular walking</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light"></div>
                    <div className="badgePageItemEvidence">
                        <p><strong>January</strong></p>
                        <p>In January, I have walked every day either inside or outside and have covered 45.45 miles in total so far.</p>
                        <br/><br/>
                        <p><strong>February</strong></p>
                        <p>In February, I walked 20 days out of 29 and covered 35.46 miles. Despite the increase to 1.5 miles minimum per day, I was unable to keep up the daily exercise due to illness.</p>
                        <br/><br/>
                        <p><strong>March</strong></p>
                        <p>In March, I walked 25 days out of 31 and covered 55.05 miles.</p>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Targets</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>The target I set is just under 42 miles per month - this is what I need to do to meet the walking challenge I am doing for my Silver Maverick.</p>
                    </div>
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Increase</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light"></div>
                    <div className="badgePageItemEvidence">
                        <p><strong>February</strong></p>
                        <p>Indoor walks increased to 1.5 miles per day</p>
                        <br/>
                        <p><strong>March</strong></p>
                        <p>Indoor walks increased to 2 miles per day</p>
                        <br/>
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default Walker