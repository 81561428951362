import React from 'react';
import {Link} from 'react-router-dom';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const Happy2023 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Happy 2023"} />
            <p><strong>This page provides my evidence for the first 2023 quarterly badge - Happy 2023.  The information for this badge is available publicly on the <a className="linkUnderline" href="https://www.rebelbadgestore.com/rebelblog/quarterly-challenge-happy-2023" target="_blank" rel="noopener noreferrer">blog</a> so descriptions haven't been removed.</strong></p>
            <br/>
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Complete 2 Self Aware badges</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p><Link to="/mystyle" className="linkUnderline">My Style</Link> completed on 4/2/2023</p>
                        <br/>
                        <p><Link to="/mycollection" className="linkUnderline">My Collection</Link> completed on 4/3/2023</p>
                        <br/>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Complete 2 Wellness badges</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p><Link to="/mindfulness" className="linkUnderline">Mindfulness</Link> completed on 4/2/2023</p>
                        <br/>
                        <p><Link to="/reader" className="linkUnderline">Reader</Link> completed on 16/2/2023</p>
                        <br/>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Complete another badge of your choice which makes you happy</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence"> 
                      <p>I completed my <Link to ="/baker" className="linkUnderline">Baker</Link> badge on 12th January, that made me (and quite a few other people) happy!</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Take time at least once a week to do something which brings you happiness</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>I try to make a habit of doing only things that bring me happiness (although we'll exclude housework from the happiness clause!)</p>
                        <br/>
                        <p>I have been very busy working on badges and on my Maverick award which have all made me feel happy and brought a sense of accomplishment with them.</p>
                        <br/>
                        <p>I have signed up to more training courses in a diverse range of subjects which gives me a sense of purpose going forward.</p>
                        <br/>
                        <p>I have spent extra time with family which always makes me happy!</p>
                        <br/>
                        <p>I meet Dad online twice a week to do crosswords, that's always fun and a good de-stresser for both of us!</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">At least once a week do something to bring happiness to others </div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>Again, this is something that I try to do naturally anyway. Once a week, I help my Mum with her Church admin, capably dealing with a temperamental photocpier and folding and stapling hundreds of sheets of hymns and newsletters! That makes her very happy, because she doesn't want to have to contend with the technology alone!</p>
                        <br/>
                        <p>I like to think I bring happiness to Dad with our Sunday morning coffee sessions and our twice weekly crossword sessions! I jump in to provide tech support whenever he needs it!</p>
                        <br/>
                        <p>I helped a gentleman in a wheelchair who wasn't able to get served at our local deli by taking his order and buying him a coffee from inside, which wasn't accessible to him.</p>
                        <br/>
                        <p>I booked time out with Mum so we could go for lunch, shopping and do some flower arranging. I helped her decipher her crochet pattern and put a few pieces in her jigsaw for her!!</p>
                        <br/>
                        <p>I hope maybe I've brought happiness to other people in other ways, I like to approach everything as Max Goodwin from New Amsterdam would: "How can I help?"</p>
                        <br/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Happy2023