import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Pasta from '../images/rebelhalloween24-pasta.jpg';



const October24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"I'd Survive a Zombie Apocalypse"} />
            <p><strong>This page provides my evidence for the I'd survive a Zombie Apocalypse monthly badge.  The information for this badge is available publicly on the Rebel Badge Store Noticeboard so descriptions haven't been removed.</strong></p>
            <br/>
            <p>The information on this page is a summary of what I did to meet the clauses - it isn't necessarily representative of all my research and work.</p>
            
            <div className="badgePageItem">
                <div className="badgePageItemTitle orange">Learn about the Survival Rule of 3</div>
                <div className="badgePageItemContent">
                   <p>The rule of three describes the basic rules of survival:</p>
                   <ul className="list">
                    <li>3 minutes without air</li>
                    <li>3 hours without shelter</li>
                    <li>3 days without water</li>
                    <li>3 weeks without food</li>
                   </ul>
                   <br/>
                   <p>As we're talking in threes, I referred to these three sources for information:</p>
                   <p>https://trueprepper.com/survival-rule-of-3</p>
                   <p>https://www.theadventuregears.com/blogs/news/understanding-the-survival-rule-of-three-a-comprehensive-guide</p>
                   <p>https://thesurvivaljournal.com/survival-rule-of-threes/</p>
                   <br/>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle orange">Research ways to locate and treat water to make it drinkable. Are there any hidden places in your house you can find drinkable water?</div>
                <div className="badgePageItemContent">
                    <p>Natural sources of water:</p>
                    <ul className="list">
                        <li>Natural Sources: Streams, rivers, and lakes are prime spots. Look for green vegetation or follow animal tracks; they'll often lead you to water.</li>
                        <li>Rainwater: Collect it with containers or plastic sheets if you have them.</li>
                        <li>Morning Dew: Use a cloth to soak up dew from grass and plants.</li>
                        <li>Plants: Some plants like cacti (careful with those thorns!) store water.</li>
                    </ul>
                    <br/>
                    <p>More concealed sources of water:</p>
                    <ul className="list">
                        <li>Rock Crevices: Rainwater and dew can collect in crevices. Watch out for insect activity as it often indicates water.</li>
                        <li>Underground: Digging in dry riverbeds or valleys might reveal hidden groundwater. Look for moist soil as a clue.</li>
                        <li>Tree Roots: Some trees, like willow trees, indicate the presence of water underground.</li>
                        <li>Transpiration: Tie a plastic bag around a leafy branch and the condensation will collect in the bag.</li>
                        <li>Solar Still: Dig a hole, place a container in the center, cover with plastic, and put a small stone in the center of the plastic. The sun will evaporate moisture which then condenses on the plastic and drips into the container.</li>
                    </ul>
                    <br/>
                    <p>Treating water to make it drinkable:</p>
                    <ul className="list">
                        <li>Boiling: The gold standard. Boil water for at least one minute (or three minutes at higher altitudes) to kill pathogens.</li>
                        <li>Filtration: Use a commercial filter if you have one. In a pinch, a DIY filter made with layers of sand, charcoal, and cloth can help.</li>
                        <li>Purification Tablets: Follow the instructions on the packaging. Usually, it takes around 30 minutes to make the water safe.</li>
                        <li>UV Light Purifiers: Portable UV purifiers can kill bacteria and viruses.</li>
                        <li>Distillation: If you can, distill water by collecting steam from boiling water and letting it condense into a clean container</li>
                    </ul>
                    <br/>
                    <p>Water sources in the home:</p>
                    <ul className="list">
                        <li>Water Heater Tank: Your water heater is a goldmine. Use the drain valve to access it.</li>
                        <li>Toilet Tank: Not the bowl, but the tank (as long as there are no cleaning chemicals in it).</li>
                        <li>Pipes: Shut off your home's main water valve and drain the pipes by opening the lowest faucet in your house.</li>
                        <li>Ice Cubes: If you've got a freezer, melt the ice cubes.</li>
                        <li>Canned Foods: Foods like fruits and veggies are packed in liquid which can be consumed.</li>
                    </ul>
                    <br/>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle orange">Research the origins of Zombie Folklore</div>
                <div className="badgePageItemContent">
                   <p>The roots of zombies lie in 17th century Haitian Voodoo traditions, when West African slaves work on sugar plantations. Zombies are a representation of the horrors of slavery. </p>
                   <p>In Voodoo, it is thought that zombies are people wh have been revived by a Bokor, using a mixture of herbs, shells and other ingredients. First, a powder is given to introduce a death-like state and the person is buried and later dug up and revived. The bokor captures the ti bon ange of the victim - the part of the soul directly connected to the person - and keeps it in a small container, which gives them control over the person.</p>
                   <p>The Movie "The Night of the Living Dead" is responsible for the modern portrayal of zombies and the flesh-eating undead.</p>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle orange">Find out about real life zombies in the natural world</div>
                <div className="badgePageItemContent">
                   <p>In the natural world, zombies are organisms that are taken over by parasites or fungi. Here are some examples:</p>
                   <ul className="list">
                    <li>Zombie Ants - A parasitic fungus, infects carpenter ants and takes over their bodies. The fungus compels the ants to climb to the top of vegetation, where they die and the fungus grows out of their bodies to release spores.</li>
                    <li>Zombie Cockroaches - The emerald cockroach wasp injects venom into a cockroach, paralyzing it and taking control of its brain. The wasp then leads the cockroach to its burrow to feed its larvae.</li>
                    <li>Zombie Caterpillars - Some caterpillars are infected by parasitic wasps that lay eggs inside them. The larvae eventually burst out of the caterpillar, killing it.</li>
                    <li>Zombie Crickets - Horsehair worms infect crickets and take over their brains, compelling them to jump into water where the worms can complete their life cycle.</li>
                    <li>Zombie Rats - Toxoplasma gondii, a parasite that needs to get into cats to reproduce, infects rats and alters their behavior, making them attracted to the smell of cat urine and more likely to be eaten by cats.</li>
                   </ul>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle orange">Prepare a meal using long-life or non-perishable foods</div>
                <div className="badgePageItemContent">
                   <p>Simple and tasty!</p>
                   <br/>
                   <p><strong>Pasta with Tomato Sauce</strong></p>
                    <p>Ingredients: Pasta, canned tomato sauce, dried basil, dried oregano, garlic powder, salt, pepper, olive oil (optional). Instructions:</p>
                    <p>Cook pasta according to package instructions.</p>
                    <p>In a saucepan, heat tomato sauce with dried herbs, garlic powder, salt, and pepper.</p>
                    <p>Combine sauce with cooked pasta. Drizzle olive oil on top for extra flavor.</p>
                    <br/><img src={Pasta} alt="Pasta on a plate" className="imgThird" />
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle orange">Make a zombie cocktail</div>
                <div className="badgePageItemContent">
                   <p><strong>Zombie Cocktail</strong></p>
                   <p>Ingredients:</p>
                    <ul className="list">
                        <li>1 oz white rum</li>
                        <li>1 oz golden rum</li>
                        <li>1 oz dark rum</li>
                        <li>1/2 oz apricot brandy</li>
                        <li>1 oz pineapple juice</li>
                        <li>1 oz papaya juice (or passion fruit juice)</li>
                        <li>1/2 oz lime juice</li>
                        <li>1/2 oz grenadine</li>
                        <li>Dash of bitters</li>
                        <li>Mint leaves and a cherry for garnish</li>
                    </ul>
                    <br/>
                    <p><strong>Instructions:</strong></p>
                    <p>In a cocktail shaker, combine the white rum, golden rum, dark rum, apricot brandy, pineapple juice, papaya juice, lime juice, and grenadine.</p>
                    <p>Add ice and shake well until the mixture is chilled.</p>
                    <p>Strain into a tall glass filled with ice.</p>
                    <p>Add a dash of bitters on top.</p>
                    <p>Garnish with mint leaves and a cherry.</p>
                    <br/><br/><br/>

                    <p><strong>Zombie Mocktail</strong></p>
                    <p><strong>Ingredients:</strong></p>
                    <ul className="list">
                        <li>2 oz pineapple juice</li>
                        <li>2 oz orange juice</li>
                        <li>1 oz lime juice</li>
                        <li>1/2 oz grenadine</li>
                        <li>1/2 oz passion fruit syrup (or any tropical fruit syrup)</li>
                        <li>Dash of bitters (optional)</li>
                        <li>Mint leaves and a cherry for garnish</li>
                    </ul>
                    <br/>
                    <p><strong>Instructions:</strong></p>
                    <p>In a shaker, combine pineapple juice, orange juice, lime juice, grenadine, and passion fruit syrup.</p>
                    <p>Add ice and shake well until chilled.</p>
                    <p>Strain into a tall glass filled with ice.</p>
                    <p>Optional: Add a dash of bitters for a little extra depth.</p>
                    <p>Garnish with mint leaves and a cherry.</p>
                </div>
                <br/>
                <p>OK, I was having so much fun I forgot photos. I'll share them next time!</p>
            </div>
            
        </div>
    )
}

export default October24