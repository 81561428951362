import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Pumpkin1 from '../images/pumpkin1.jpg';
import Pumpkin2 from '../images/pumpkin2.jpg';
import Pumpkin3 from '../images/pumpkin3.jpg';
import Pumpkin4 from '../images/pumpkin4.jpg';
import PumpkinSoup1 from '../images/pumpkinsoup1.jpg';
import PumpkinSoup2 from '../images/pumpkinsoup2.jpg';
import HalloweenBook1 from '../images/HalloweenBook1.jpg';
import HalloweenBook2 from '../images/HalloweenBook2.png';
import HalloweenPumpkin from '../images/halloweenpumpkin.png';
import HalloweenLeaf from '../images/HalloweenLeaf.png';
import HalloweenMermaid from '../images/HalloweenMermaid.png';
import PumpkinCake1 from '../images/baker-cake2a.jpg';
import PumpkinCake2 from '../images/baker-cake2b.jpg';
import PumpkinCake3 from '../images/baker-cake2c.jpg';
import PumpkinCake4 from '../images/baker-cake2d.jpg';
import PumpkinCake5 from '../images/baker-cake2e.jpg';
import HalloweenLeaves1 from '../images/HalloweenLeaves1.jpg';
import HalloweenLeaves2 from '../images/HalloweenLeaves2.jpeg';

const Halloween22 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Halloween 2022"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle orange">Carve a pumpkin</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo orange-light"></div>
                    <div className="badgePageItemEvidence">
                        I couldn't get to a pumpkin patch but my eldest niece picked one for me, and she got a brilliant one! Perfect size and plenty of flesh for Halloween baking!
                        <br/><br/>
                        I'm no good at carving elaborate designs, but I gave it a face. It looks more startled than startling though!
                        <br/><br/>
                        <div className="hRow">
                            <img src={Pumpkin1} alt="Pumpkin before being carved"/>
                            <img src={Pumpkin2} alt="Pumpkin with face drawn on before carving"/>
                            <img src={Pumpkin3} alt="Pumpkin hollowed out with face carved"/>
                            <img src={Pumpkin4} alt="Carved pumpkin with a candle lit inside by a fire place"/>
                            <br/><br/>            
                        </div>       
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle black">Use your pumpkin scraps in a recipe</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo orange-light"></div>
                    <div className="badgePageItemEvidence">
                        Unfortunately I seem to have forgotten to take many photos of this process, but I used some of my pumpkin scraps to make soup.
                        <br/><br/>
                        I had a pumpkin soup recipe in a Hungarian cookbook so I used that.
                        <br/><br/>
                        <div className="hRow">
                            <img src={PumpkinSoup1} alt="Pumpkin and other vegetables chopped and in a pan ready for cooking." />
                            <img src={PumpkinSoup2} alt="Pumpkin soup in a white soup bowl." />
                            <br/><br/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle orange">Read a gothic novel and review it</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo orange-light"></div>
                    <div className="badgePageItemEvidence">
                        I read two novels for this, because the first one I chose was only 27 pages long.
                        <br/><br/>
                        <table className="halloweenTable">
                            <thead>
                                <tr>
                                    <th><img className="hSmall" src={HalloweenBook1} alt="Book titled The Life Principle" /></th>
                                    <th>The Life Principle</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td>
                                        This is an unusual book, self-published and only 27 pages long. However, it still manages to tell a complete and intriguing story in those few pages with vivid imagery.
                                        <br/><br/>
                                        Following a murder-suicide and a letter from a friend, a person undertakes an investigation at the location of the horror. What they find is a disturbing tale of children caught up in a bloody experiment into identifying the Life Principle and the ability of the strong to fortify the weak.
                                        <br/><br/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <table className="halloweenTable">
                            <thead>
                                <tr>
                                    <th><img className="hSmall" src={HalloweenBook2} alt="Book titled Girl in the Walls" /></th>
                                    <th>Girl in the Walls</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td>
                                        This book tells the story of Elise, a young girl who was tragically orphaned and escaped from foster care to return to her family's home. Finding her home occupied, she used crawl spaces to live secretly in the attic.
                                        <br/><br/>
                                        This tells the story of how she survived, how she found friendship and got careless and how two young boys living in the house with their parents became aware of her presence and the terrifying result of their attempt to justify their suspicions.
                                        <br/><br/>
                                        It's a very imaginative and cleverly written book which at first had me confused as to whether or not Elise was real. A sad story of a forgotten child, the dangers of the internet and how you should trust your instinct and not mis-trust what you don't know.
                                        <br/><br/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle black">Research the origins of Halloween</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo orange-light"></div>
                    <div className="badgePageItemEvidence">
                        <table className="halloweenTable">
                            <thead>
                                <tr>
                                    <th rowspan="2"><img src={HalloweenPumpkin} alt="Cartoon pumpkin wth light shining out of the eyes" /></th>
                                    <th>Halloween</th>
                                </tr>
                                <tr>
                                    <th>
                                        Halloween originated over 2,000 years ago among the Celts .
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colspan="2">
                                        The Celts celebrated their New Year on 1st November. They believed that the veil between the living world and the dead was thin at that time, allowing the dead to revisit, so they began to celebrate Samhain (sow-in)  on October 31st, lighting bonfires and wearing costumes to scare off ghosts.
                                        <br/><br/>
                                        The Samhain celebrations included lighting bonfires where crops and animals were sacrificed  o Celtic deities to assure protection for the coming year. Each household would put out their hearth fire and then re-light it from the celebratory bonfire to bring them fortune in the year to come.  If the hearth would not re-light, this was an omen of mis-fortune.
                                        <br/><br/>
                                        Halloween is not, as often portrayed in the modern world, a celebration of the Devil, of Witchcraft or Satanism, but a celebration of the end of the traditional growing season and a welcome of the changing winter season.
                                        <br/><br/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <span class="srcCite">Source:  https://www.history.com/topics/halloween/history-of-halloween, https://halloween.com/2020/the-history-and-origin-of-halloween</span>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle orange">Research alternative autumn festivals to halloween</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo orange-light"></div>
                    <div className="badgePageItemEvidence">
                        <table className="halloweenTable">
                            <thead>
                                <tr>
                                    <th rowspan="2"><img src={HalloweenLeaf} alt="Clip art orange and yellow coloured leaf" /></th>
                                    <th>Autumn Festivals</th>
                                </tr>
                                <tr>
                                    <th>
                                        There are various festivals that take place around the world to celebrate Autumn, but they all have many features in common such as costumes, music, dance, food and drink as well as decorating homes and buildings with traditional ornaments, arts and crafts.
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colspan="2">
                                        Harvest Festival takes place in the UK and started back in pagan times to celebrate a successful harvest. Food is often collected as part of celebrations and donated to those in need. We also have Bonfire night in the UIK which remembers the unsuccessful attempt of Guy Fawkes to blow up the palace of Westminster.
                                        <br/><br/>
                                        China, Malaysia, the Philippines and other Asian countries celebrate Moon Festival , which occurs on the 15th day of the eighth month in the Chinese lunar calendar so usually falls in September. This also gives thanks for a successful harvest and is a celebration of the full moon.
                                        <br/><br/>
                                        Oktoberfest originated in Germany but as with other festivals has now transferred around the world.  The first Oktoberfest took place at the wedding of the Bavarian Crown Prince Ludwig but is now a two week celebration that involves a lot of beer, as well as Bavarian music, food and clothing.
                                        <br/><br/>
                                        Mexico celebrates Dia de Los Muertos on the 1st and 2nd of November. To remember those who have passed and also involves food, drink and music. 
                                        <br/><br/>
                                        Hindus celebrate Diwali—the festival of lights—which represents  the victory of light over darkness or good over evil and knowledge over ignorance. The Hindu calendar is based on the moon, so the date varies but it takes place between October and November each year. 
                                        <br/><br/>
                                        In Thailand, the Floating Lantern Festival thakes place on the full moon of the 12th month in the Thai Lunar Calendar.
                                        <br/><br/>
                                        In the Philippines there is the Masskara Festival featuring street dance, beauty pageant, carnivals, concerts and many other events.
                                        <br/><br/>
                                        France celebrate Nuit Blanche which is an all-night arts festival where museums, art galleries and other locations open free of charge.
                                        <br/><br/>
                                        The USA hold the International Balloon Fiesta in Albuquerque and the largest Halloween Parade in the world that takes place annually in New York.
                                        <br/><br/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <span class="srcCite">Source:  https://www.readersdigest.co.uk/inspire/life/autumn-celebrations-around-the-world, https://www.fit2trip.es/en/blog/10-top-autumn-festivals-around-the-world/</span>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle black">Research the origins of a supernatural being</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo orange-light"></div>
                    <div className="badgePageItemEvidence">
                    <table className="halloweenTable">
                            <thead>
                                <tr>
                                    <th rowspan="2"><img src={HalloweenMermaid} alt="Colourful illustration of a mermaid with her back to the viewer" /></th>
                                    <th>Mermaids</th>
                                </tr>
                                <tr>
                                    <th>
                                        Mermaids are mythical creatures with a very mixed story! Many tales present them as beautiful, kind and seductive beings whereas in others they are represented as all those things but with a grotesque conversion and vicious nature.
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colspan="2">
                                        The origin story of the mermaids is that of the goddess of fertility, Atargartis, who is said to have thrown herself into a lake and transformed into a mermaid. This story comes from Assyria as long ago as 1000BC.
                                        <br/><br/>
                                        Sailors are said to have seen mermaids for many years, with accounts from Christopher Columbus and Blackbeard.  In all accounts they appear as beautiful women with fish tails but Some cultures see mermaids as a good omen, where others tell of them luring unsuspecting sailors and dragging them to the bottom of the ocean.
                                        <br/><br/>
                                        Chinese and Korean folklore paint mermaids as being a good omen.
                                        <br/><br/>
                                        Japanese, British and Brazilian myths show them to be a bad omen of storms and catastrophe.
                                        <br/><br/>
                                        Ancient Greece has a slightly different take and are thought ot be closest to what the modern representation of mermaids is. In their myths, Thessalonike (the sister of Alexader the Great) transformed into a mermaid and lived in the Aegean Sea when she died. If she saw a ship she would issue a challenge and if the ship returned the correct answer would allow them to proceed. If she didn't receive the correct response  she would send the ship to the bottom of the ocean.
                                        <br/><br/>
                                        Mermaids appear often in literature and movies, again with very opposing characteristics.  One of the most widely known movies is Splash, showing a positive spin on a mermaid on land, falling for a human. The Little Mermaid is a well-known animated (now live-action) children's movie showing a young mermaid longing to be able to walk.
                                        <br/><br/>
                                        New TV series Sirens has a different view, depicting the mer-people as vicious creatures who have contempt for the land-based world and is a story of how  they start to integrate.
                                        <br/><br/>
                                        They are fascinating creatures and are even more fascinating because of the opposing stories, which sets them apart from many other mythical creatures.
                                        <br/><br/>
                                        Could mermaids be real? It's possible. So much of the ocean is still unexplored so who knows. Let's hope that if our worlds ever cross, they are friendly because the alternative is a horror story ;)
                                        <br/><br/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <span class="srcCite">Source:  https://en.wikipedia.org/wiki/Mermaids_in_popular_culture, https://oceaninfo.com/exploration/myths-and-legends/mermaid-origins/</span>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle orange">Do some halloween related baking</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo orange-light"></div>
                    <div className="badgePageItemEvidence">
                        I used the rest of my pumpkin flesh to bake a pumpkin cake!
                        <br/><br/>
                        I used a <a href="https://www.bbcgoodfood.com/recipes/halloween-pumpkin-cake">recipe from BBC Good Food</a>
                        <br/><br/>
                        Unfortunately I made an error in the recipe and used dark sugar instead of light, but it worked just fine and had good feedback from my taste testers!
                        <br/><br/>
                        <div className="hRow">
                            <img src={PumpkinCake1} alt="Pumpkin cake ingredients in a mixing bowl"/>
                            <img src={PumpkinCake2} alt="Pumpkin cake just removed from the oven after baking"/>
                            <img src={PumpkinCake3} alt="Cream cheese icing being mixed in a bowl"/>
                            <img src={PumpkinCake4} alt="Pumpkin cake conmplete with icing"/>
                            <img src={PumpkinCake5} alt="Close up of the pumpkin cake"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle black">Use Autumn leaves for a beautiful photo or craft</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo orange-light"></div>
                    <div className="badgePageItemEvidence">
                        For this task, I collected leaves from the beach and from the pavements on my walks to and from the beach. I found quite a few different types with plenty of different colours. The autumn leaf colours are so beautiful.
                        <br/><br/>
                        I had brought them home to perhaps doing something crafty with them, but left it far too long and they dried out too much to work with.
                        <br/><br/>
                        So, I turned to my backup plan - photography! I'm no expert, but I had an idea. I watched a video on Instagram about using acorn cups to make mini candles so I had picked some up. I decided to turn it into a tiny little boat floating on a little puddle in a leaf.
                        <br/><br/>
                        This was staged indoors and I used a spray bottle of water to wet the leaves. I picked the largest leaf that looked like it could hold water and placed that on the top.
                        <br/><br/>
                        Things got a bit wet, but that's why I have hard-wood floors instead of carpet!!
                        <br/><br/>
                        Here's the staged shot and the final still. My criticism would be that it's not easy to tell that the leaf contains a puddle of water.
                        <br/><br/>
                        <div className="hRow">
                            <img src={HalloweenLeaves1} alt="Staged shot of leaves with a spray bottle and small jug"/>
                            <img src={HalloweenLeaves2} alt="Final shot from the staged shoot of an acorn cup floating on a puddle in a leaf " />
                    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Halloween22