import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const Story = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"My Story"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Clause 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>Due to the very personal nature of this, I won't be sharing any story evidence on this page, so you will just have to trust that I have done it.</p>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default Story