import React from 'react';
import { Link } from 'react-router-dom';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';


const GoWild23 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Rebel Cup Bonus Points"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Complete Nature Lover, Stargazer or Both</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>I had already completed both of these badges, you can find them here:</p>
                        <p><Link className="linkUnderline" to="/naturelover">Nature Lover page</Link></p>  
                        <p><Link className="linkUnderline" to="/stargazer">Stargazer page</Link></p>                    
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Complete 2 other Wild badges</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>For this, I completed my Observer and Scientist badges, you can see them here:</p>
                        <p><Link className="linkUnderline" to="/scientist">Scientist page</Link></p>  
                        <p><Link className="linkUnderline" to="/observer">Observer page</Link></p>    
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Write a list of 10 adventures, complete one</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>I am not adventurous, but here are a few things that I would do, given the opportunity:</p>
                        <ul className="list">
                            <li>Do instructor led local kayak tour</li>
                            <li>Regular sea swimming</li>
                            <li>Paddleboarding</li>
                            <li>Kayaking</li>
                            <li>Scuba diving</li>
                            <li>Trip out to see the Needles lighthouse</li>
                            <li>Trip on Steamship Shieldhall</li>
                            <li>Trip with Moonfleet</li>
                            <li>Foraging</li>
                            <li>Lifeguard training</li>
                        </ul>
                        <br/>
                        <p>Kayaking is the one thing I have achieved.</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Once a week do a different outdoor activity</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>Activities that I completed:</p>
                        <ul className="list">
                            <li>Weeding</li>
                            <li>Walk</li> 
                            <li>Beach Clean Event</li>
                            <li>Post Event Clean Up</li>
                            <li>Coffee and people watching for Observer</li>
                            <li>Re-potting plants and harvesting fruit</li>
                            <li>Trip to the park with the kids</li>
                            <li>Beach Clean</li>
                            <li>Litter sorting</li> 
                            <li>Topping up borrow boxes at the beach</li> 
                            <li>Documenting local wildlife for Observer badge</li>
                            <li>Big microplastic hunt</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GoWild23