import React, {useEffect} from 'react'
import RebelLogo from '../images/rebel-logo.png'

export default function EvidenceHeader({title, area}) {

    useEffect(() => {
        listen();
    },[])
    
    let pageArea = "/bookone";
    if(area === undefined)
    {
        area = "bookone";
    }
    else if (area !== "bookone" && area !== null)
    {
        pageArea = "/" + area;
    }
    else if (area !== "booktwo" && area !== null)
    {
        pageArea = "/" + area;
    }
    else
    {
        area = "bookone";
    }

    if (area === "bookone")
    { 
        area = "Book One";
    }
    else if (area=== "booktwo")
    {
        area = "Book Two";
    }

    return (
        <div>
            <div className="row bookPageTop">
                <div className="rebelLeft"><img src={RebelLogo} alt="Sticker of the word rebel" className="rebelLogoBookLeft" /></div>
                <div className="rebelMid">
                    <h2>{title}</h2>
                </div>
                <div className="rebelRight"><img src={RebelLogo} alt="Sticker of the word rebel" className="rebelLogoBookRight" /></div>
            </div>
            <div className="row pageIntroduction">
                {pageArea === "/bookone" && <p><br />This page follows my progress with the {title} badge.</p>}
                {pageArea === "/booktwo" && <p><br />This page follows my progress with the {title} badge.</p>}
                {pageArea === "/bronze" && <p><br />This page follows my progress with the {title} section of the Bronze Award.</p>}
            </div>
            <div className="row pageText">
                <p>Clause titles and descriptions have been removed / abbreviated to protect the content of the book. To find out the full instructions and see what I am talking about in my evidence, you'll just have to buy a copy from <a href="https://www.rebelbadgestore.com/" target="_blank" rel="noopener noreferrer">Rebel Badge Book</a></p>
                <p>Note: the content on these pages may not represent the full work completed for each badge. Often, I do a lot more than is evidenced here.</p>
            </div>
        </div>
    );
}


function listen () {

    document.querySelectorAll(".badgePageItemContent").forEach((elI) => {elI.classList.add("hidden")})
    document.querySelectorAll(".icon-down-open").forEach((elI) => {elI.classList.add("shown")})
    document.querySelectorAll(".icon-up-open").forEach((elI) => {elI.classList.add("hidden")})

    const elements = document.querySelectorAll(".badgePageItemTitle");
    elements.forEach((element) => {

        element.addEventListener("click",function(){
            var elementContent = element.parentElement.querySelector(".badgePageItemContent");
            
            if(elementContent.classList.contains("hidden"))
            {
                elementContent.classList.remove("hidden");
                elementContent.classList.add("shownBlock");
            } else {
                elementContent.classList.remove("shownBlock");
                elementContent.classList.add("hidden");
            }
        })
    })
};