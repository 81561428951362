import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const MyFitness = () => {
    return (
        <div>
            <ActionBar area={"silver"}  />
            <EvidenceHeader title={"My Fitness"} />
            <div className="mavPageItem">
                <div className="mavPageItemTitle silver">My Fitness</div>
                <div className="mavPageItemContent">
                    <div className="mavPageItemInfo silver-light">Over a period of at least six months, work towards a personal fitness goal. Choose the goal specific to your body and your capabilities. The goal should be specific, measurable and achievable in 6-12 months. Complete the goal.</div>
                    <div className="mavPageItemEvidence">
                    
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyFitness