import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const  HealthCheck24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Rebel Health Check 2024"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Sugar</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>I consume a very minimal amount of sugar as I follow a low carb diet and sugars are not allowed. I have small amounts of sugar in juice and fizzy drinks if I choose to have them. I have sugary items only as an occasional treat.</p>
                        <p>Fortunately, I don't have a sweet tooth.</p>
                        <p>Prediabetes - borderline diabetes - where the blood sugar level is higher than normal but not high enough to be type 2 diabetes. Can lead to type 2 diabetes.</p>
                        <p>Type 2 Diabetes - when the body can't use insulin properly to regulate bloood sugar levels. Can lead to serious health issues like heart disease, kidney problems and stroke.</p>
                        <p>Both types of diabetes are on the rise for the following reasons:</p>
                        <ul className="list">
                            <li>Obesity epidemic - obesity has surged, impacting insulin sensitivity and leading to insulin resistance. As weight is gained, bodies require more insulin which increases the risk of diabetes</li>
                            <li>Changing demographics - diabetes in young adults </li>
                        </ul>
                        why are they on the rise
                        what positive differences can you make to your diet
                        Understand the difference between type 1 and type 2 diabetes
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">More movement</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>As part of my maverick and various other challenges, I am currently walking at least 1 mile a day either indoors or outdoors.</p>
                        <p>I have also now included Boxing in my daily routine so get up to an hour of movement a day.</p>
                        <p>I have a new sit to stand desk, that helps with movement as well.</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Skin and Hair</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>I've never really had a skin or hair routine. I only wash my hair twice a week now, it is highly coloured and gets re-bleached once a quarter with top up colour done in-between. It doesn't get cut, I dislike hairdressers, so it is very long now and kind of takes care of itself! </p>
                        <p>I have changed up my skin care routine lately to make sure I am looking after it better. I have various lotions and potions that I use to make sure my makeup is fully removed and my skin is clean and hydrated.</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Medicines and Supplements</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>I have reviewed my medications recently and removed myself from all medical interventions - I am not sharing details here as this is personal information but all is fine and I am continuing to thrive without them.</p>
                        <p>I have also reviewed the supplements that I take and made some changes, largely to try and save some money as they cost a lot. This caused a few problems and I have had to re-adjust to compensate. Striking a balance is hard! Again, not sharing any further here as this is personal information. </p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Mindfulness</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>I have become quite good now at identifying when I need to take a minute and at accepting that some times you just have to listen to what your body is telling you. Trying to force productivity is, ni itself, unproductive. If your body is telling you to sit and stare at a wall all day, just do it!</p>
                        <p>When I get stressed, or I'm struggling to focus, or whatever the problem, music is the answer. I have headphones everywhere in my house and not much can't be solved by pumping music very loudly into my head!</p>
                        <p>Caution: I have tinnitus and hearing loss in one ear most likely due to past ear-bud abuse. Be careful with your habits!</p>
                        <p>I don't take a lot of down time, but if I need a break then a I like to reaad, watch some TV or get stuck in to arts and crafts.</p>
                        <p>When I'm really overwhelmed and need escape, I head for the beach.</p>
                        <p>I set aside time to exercise daily. Using a music boxing machine has been really helpful because it forces my brain to switch off for a while - if it doesn't, I lose the rhythm! That gives me a half hour window of nothingness usually after work to switch off, wind down and prepare me for the evening ahead.</p>
                        <p>Just getting to understand what's a baseline reaction and what's an overwhelm has been enlightening and I wish I had managed that before, becuase setting boundaries becomes a lot easier now.</p>
                        <p>We roll with the punches these days, make the most of the highdays and enjoy the time out on the low days. No time is wasted time when you're recharging.</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Unhelpful Habits</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>I think probably the only unhelpful habit I have - aside from chronic procrastination - is an addiction to my phone and scrolling!</p>
                        <p>I have made a positive change in that I use it for productivity instead of just doom scrolling and spend a lot of time language learning on various apps instead of wasting time on social media.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HealthCheck24