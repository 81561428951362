import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Skirt1 from '../images/cup-april23-pins1.jpg';
import Skirt2 from '../images/cup-april23-pins2.jpg';
import Skirt3 from '../images/cup-april23-pins3.jpg';
import Skirt4 from '../images/cup-april23-pins4.jpg';
import Skirt5 from '../images/cup-april23-pins5.jpg';
import Skirt6 from '../images/cup-april23-pins6.jpg';
import Skirt7 from '../images/cup-april23-pins7.jpg';
import Skirt8 from '../images/cup-april23-pins8.jpg';
import Skirt9 from '../images/cup-april23-pins9.jpg';
import Skirt10 from '../images/cup-april23-pins10.jpg';
import Skirt11 from '../images/cup-april23-pins11.jpg';
import Skirt12 from '../images/cup-april23-pins12.jpg';
import Skirt13 from '../images/cup-april23-pins13.jpg';
import Skirt14 from '../images/cup-april23-pins14.jpg';
import Skirt15 from '../images/cup-april23-pins15.jpg';
import Skirt16 from '../images/cup-april23-pins16.jpg';
import Skirt17 from '../images/cup-april23-pins17.jpg';
import Skirt18 from '../images/cup-april23-pins18.jpg';
import Skirt19 from '../images/cup-april23-pins19.jpg';
import Skirt20 from '../images/cup-april23-pins20.jpg';
import Skirt21 from '../images/cup-april23-pins21.jpg';
import Skirt22 from '../images/cup-april23-pins22.jpg';
import Skirt23 from '../images/cup-april23-pins23.jpg';
import Skirt24 from '../images/cup-april23-pins24.jpg';
import Skirt25 from '../images/cup-april23-pins25.jpg';
import Skirt26 from '../images/cup-april23-pins26.jpg';
import Skirt27 from '../images/cup-april23-pins27.jpg';
import Skirt28 from '../images/cup-april23-pins28.jpg';
import Skirt29 from '../images/cup-april23-pins29.jpg';
import Skirt30 from '../images/cup-april23-pins30.jpg';
import Skirt31 from '../images/cup-april23-pins31.jpg';
import Skirt32 from '../images/cup-april23-pins32.jpg';
import Skirt33 from '../images/cup-april23-pins33.jpg';
import Skirt34 from '../images/cup-april23-pins34.jpg';
import Skirt35 from '../images/cup-april23-pins35.jpg';
import Skirt36 from '../images/cup-april23-pins36.jpg';
import Skirt37 from '../images/cup-april23-pins37.jpg';
import Skirt38 from '../images/cup-april23-pins38.jpg';
import Skirt39 from '../images/cup-april23-pins39.jpg';
import Skirt40 from '../images/cup-april23-pins40.jpg';
import Skirt41 from '../images/cup-april23-pins41.jpg';
import Skirt42 from '../images/cup-april23-pins42.jpg';
import Bag1 from '../images/designer-bag1.jpg';
import Bag2 from '../images/designer-bag2.jpg';
import Bag3 from '../images/designer-bag3.jpg';
import Bag4 from '../images/designer-bag4.jpg';
import Bag5 from '../images/designer-bag5.jpg';
import Bag6 from '../images/designer-bag7.jpg';
import Bag7 from '../images/designer-bag8.jpg';
import Bag8 from '../images/designer-bag9.jpg';
import Bag9 from '../images/designer-bag10.jpg';
import Bag10 from '../images/designer-bag11.jpg';
import Bag11 from '../images/designer-bag12.jpg';
import Bag12 from '../images/designer-bag13.jpg';
import Bag13 from '../images/designer-bag14.jpg';
import Bag14 from '../images/designer-bag15.jpg';
import Bag15 from '../images/designer-bag16.jpg';
import Bag16 from '../images/designer-bag17.jpg';
import Night1 from '../images/designer-nightwear1.jpg';
import Night2 from '../images/designer-nightwear2.jpg';
import Night3 from '../images/designer-nightwear3.jpg';
import Night4 from '../images/designer-nightwear4.jpg';
import Night5 from '../images/designer-nightwear5.jpg';
import Night6 from '../images/designer-nightwear6.jpg';
import Night7 from '../images/designer-nightwear7.jpg';
import Night8 from '../images/designer-nightwear8.jpg';
import Night9 from '../images/designer-nightwear9.jpg';
import Night10 from '../images/designer-nightwear10.jpg';
import Night11 from '../images/designer-nightwear11.jpg';
import Night12 from '../images/designer-nightwear12.jpg';
import Night13 from '../images/designer-nightwear13.jpg';
import Night14 from '../images/designer-nightwear14.jpg';
import Night15 from '../images/designer-nightwear15.jpg';
import Child1 from '../images/designer-child1.jpg';
import Child2 from '../images/designer-child2.jpg';
import Child3 from '../images/designer-child3.jpg';
import Child4 from '../images/designer-child4.jpg';
import Child5 from '../images/designer-child5.jpg';
import Child6 from '../images/designer-child6.jpg';
import Child7 from '../images/designer-child7.jpg';
import Child8 from '../images/designer-child8.jpg';
import Child9 from '../images/designer-child9.jpg';
import Child10 from '../images/designer-child10.jpg';
import Child11 from '../images/designer-child11.jpg';
import Child12 from '../images/designer-child12.jpg';
import Child13 from '../images/designer-child13.jpg';
import Child14 from '../images/designer-child14.jpg';
import Child15 from '../images/designer-child15.jpg';
import Child16 from '../images/designer-child16.jpg';
import Child17 from '../images/designer-child17.jpg';
import Child18 from '../images/designer-child18.jpg';
import Child19 from '../images/designer-child19.jpg';
import Scrapbook from '../documents/RebelDesigner.pdf';
import DesignOutfit from '../images/designer-outfit.jpg';
import Design1 from '../images/designer-outfitdesign1.jpg'
import Design2 from '../images/designer-outfitdesign2.jpg'
import Design3 from '../images/designer-outfitdesign3.jpg'
import Design4 from '../images/designer-outfitdesign4.jpg'
import Design5 from '../images/designer-outfitdesign5.jpg'
import Design6 from '../images/designer-outfitdesign6.jpg'
import Design7 from '../images/designer-outfitdesign7.jpg'
import Design8 from '../images/designer-outfitdesign8.jpg'
import Design9 from '../images/designer-outfitdesign9.jpg'
import Design10 from '../images/designer-outfitdesign10.jpg'
import Design11 from '../images/designer-outfitdesign11.jpg'
import Design12 from '../images/designer-outfitdesign12.jpg'
import Design13 from '../images/designer-outfitdesign13.jpg'
import Design14 from '../images/designer-outfitdesign14.jpg'
import Design15 from '../images/designer-outfitdesign15.jpg'
import Design16 from '../images/designer-outfitdesign16.jpg'
import Design17 from '../images/designer-outfitdesign17.jpg'
import Design18 from '../images/designer-outfitdesign18.jpg'
import Design19 from '../images/designer-outfitdesign19.jpg'
import Design20 from '../images/designer-outfitdesign20.jpg'
import Design21 from '../images/designer-outfitdesign21.jpg'
import Design22 from '../images/designer-outfitdesign22.jpg'
import Design23 from '../images/designer-outfitdesign23.jpg'
import Design24 from '../images/designer-outfitdesign24.jpg'
import Design25 from '../images/designer-outfitdesign25.jpg'
import Design26 from '../images/designer-outfitdesign26.jpg'
import Design27 from '../images/designer-outfitdesign27.jpg'
import Design28 from '../images/designer-outfitdesign28.jpg'
import Design29 from '../images/designer-outfitdesign29.jpg'
import Design30 from '../images/designer-outfitdesign30.jpg'
import DesignCapsule from '../images/designer-capsule.png';


const Designer = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Designer"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">01</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Create a garment for you</div>
                    <div className="badgePageItemInfo reverse hidden">Create a garment for yourself using a shop-bought pattern. Make sure to choose appropriate fabric and accessories.</div>
                    <div className="badgePageItemEvidence">
                        <p>I decided for this clause that I wanted to make nightwear rather than day wear as I have more of a need for that!  I found a pattern for shorts and vest intended to be nightwear and ordered that. Mum took me for a trip out to a nearby fabric shop where we battled with yards, probably gave the staff something to talk about and, ultimately, came away with something resembling suitable material!!</p>
                        <br/>
                        <p>There wasn't a huge choice, certainly in the price range I was happy to pay, but I kinda loved the space fabric (which is, of course, aimed at kids - fortunately I am a very large child!)</p>
                        <br/>
                        <p>I picked some plain black tshirt fabric for the top.</p>
                        <br/>
                        <p>I have never made anything like this before and have never used jersey fabric. It's been a long time since I last used a sewing machine - in fact the last one I borrowed from Mum quite literally caught fire when I tried to use it so I was a little nervous to use one again!! </p>
                        <br/>
                        <p>I wrestled with the pattern, managed to find the pieces I required and deciphered the layouts, cutting patterns and assembly instructions.</p>
                        <br/><br/>
                        <p>Now, the eagle-eyed among you may notice my - naturally very deliberate - design choice ;)</p>
                        <br/>
                        <p>Here are the WIP photos and the final piece.</p>
                        <br/><br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Night1} alt="Clothing pattern laid out on a floor" /></div>
                            <div className="galleryItem"><img src={Night2} alt="Clothing patterna laid out on a floor" /></div>
                            <div className="galleryItem"><img src={Night3} alt="Folded space themed fabric laid out on a floor" /></div>
                            <div className="galleryItem"><img src={Night4} alt="Clothing pattern placed on space themed fabric" /></div>
                            <div className="galleryItem"><img src={Night5} alt="Clothing pattern placed on space themed fabric" /></div>
                            <div className="galleryItem"><img src={Night6} alt="Fabric cut from a clothing pattern" /></div>
                            <div className="galleryItem"><img src={Night7} alt="Fabric being stitched on a sewing machine" /></div>
                            <div className="galleryItem"><img src={Night8} alt="Fabric being stitched on a sewing machine" /></div>
                            <div className="galleryItem"><img src={Night9} alt="Elastic being threaded through an item of clothing" /></div>
                            <div className="galleryItem"><img src={Night10} alt="Elastic being threaded through an item of clothing" /></div>
                            <div className="galleryItem"><img src={Night11} alt="Pair of shorts made using a space themed fabric" /></div>
                            <div className="galleryItem"><img src={Night12} alt="Cutting a tshirt pattern out of black jersey fabric" /></div>
                            <div className="galleryItem"><img src={Night13} alt="Black jersey fabric being stitched on a sewing machine" /></div>
                            <div className="galleryItem"><img src={Night14} alt="Black tshirt made from jersey fabric" /></div>
                            <div className="galleryItem"><img src={Night15} alt="Completed black vest and space themed shorts laid out on a floor" /></div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">02</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Create a garment for a child or baby</div>
                    <div className="badgePageItemInfo reverse hidden">Create a garment for a child or baby following a shop-bought pattern. Make sure to choose appropriate fabric and accessories.</div>
                    <div className="badgePageItemEvidence">
                        <p>For this clause I have made an outfit for child size Age 12. I went for older because there's a chance that one of my nieces may be able to wear the outfit - we don't have younger children in the family.</p>
                        <br/>
                        <p>I used black, stretch velvet for the leggings, white jersey fabric for the t-shirt and cotton for the jacket.</p>
                        <br/>
                        <p>I won't be hurrying to work with stretchy fabric again!!</p>
                        <br/><br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Child1} alt="Sewing pattern laid out on a floor" /></div>
                            <div className="galleryItem"><img src={Child2} alt="Sewing pattern cut and placed on fabric" /></div>
                            <div className="galleryItem"><img src={Child3} alt="Velvet stretch fabric being sewn" /></div>
                            <div className="galleryItem"><img src={Child4} alt="Finished leggings made with black velvet stretch fabric" /></div>
                            <div className="galleryItem"><img src={Child5} alt="Sewing pattern laid out on a floor" /></div>
                            <div className="galleryItem"><img src={Child6} alt="A bobbin on a sewing machine ready to be wound" /></div>
                            <div className="galleryItem"><img src={Child7} alt="White jersey fabric being sewn into a tshirt" /></div>
                            <div className="galleryItem"><img src={Child8} alt="White jersey fabric being sewn into a tshirt" /></div>
                            <div className="galleryItem"><img src={Child9} alt="White jersey fabric being sewn into a tshirt" /></div>
                            <div className="galleryItem"><img src={Child10} alt="White jersey fabric being sewn into a tshirt" /></div>
                            <div className="galleryItem"><img src={Child11} alt="White jersey fabric being sewn into a tshirt" /></div>
                            <div className="galleryItem"><img src={Child12} alt="Completed tshirt made from tshirt fabric with lace on the bottom" /></div>
                            <div className="galleryItem"><img src={Child13} alt="Sewing pattern laid out on a floor" /></div>
                            <div className="galleryItem"><img src={Child14} alt="Bright cotton fabric with black background, pink and white flowers and green stalks." /></div>
                            <div className="galleryItem"><img src={Child15} alt="Bright cotton fabric with black background, pink and white flowers and green stalks." /></div>
                            <div className="galleryItem"><img src={Child16} alt="Bright cotton fabric with black background, pink and white flowers and green stalks." /></div>
                            <div className="galleryItem"><img src={Child17} alt="Bright cotton fabric with black background, pink and white flowers and green stalks." /></div>
                            <div className="galleryItem"><img src={Child18} alt="Jacket made from bright cotton fabric with black background, pink and white flowers and green stalks." /></div>
                            <div className="galleryItem"><img src={Child19} alt="Completed child's outfit of black leggings, white tshirt and bright jacket hanging on a door frame." /></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">03</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Create with second-hand items.</div>
                    <div className="badgePageItemInfo reverse hidden">Create a new garment from at least two second-hand garments. The new item should be different to its origins - e.g. a dress made from two different shirts, or a hat from a jacket or dress.</div>
                    <div className="badgePageItemEvidence">
                        <p>I created this for the April 2023 extra cup challenge points. I am very happy that not only did it earn me the 3 cup points, but Charly also gave me 5 bonus points!!</p>
                        <br/>
                        <p>To create this, I used one pair of old jeans and the fabric from a tshirt, another pair of trousers and some mosquito net! This met one of the designer badge clauses nicely with a change in purpose and re-using multiple articles of clothing.</p>
                        <br/>
                        <ul className="list">
                            <li>It uses 92 eyelets - all were individually hand-hammered</li>
                            <li>It uses 45 large coloured safety pins</li>
                            <li>It uses 59 dinky coloured safety pins</li>
                            <li>It is entirely hand-sewn</li>
                        </ul>       
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Skirt1} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt2} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt3} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt4} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt5} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt6} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt7} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt8} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt9} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt10} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt11} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt12} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt13} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt14} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt15} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt16} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt17} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt18} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt19} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt20} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt21} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt22} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt23} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt24} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt25} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt26} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt27} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt28} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt29} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt30} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt31} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt32} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt33} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt34} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt35} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt36} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt37} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt38} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt39} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt40} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt41} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt42} alt="Turning jeans into a skirt, progress shot" /></div>
                        </div> 
                        <br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">04</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Design a full outfit</div>
                    <div className="badgePageItemInfo reverse hidden">Design a full outfit for yourself, and make at least one of the garments.</div>
                    <div className="badgePageItemEvidence">
                        <p>OK, so it's not pretty and definitely isn't winning any awards, but but here's a very rough sketch of what I designed for myself - a little summer outfit.</p>
                        <br/>
                        <img className="imgThird" src={DesignOutfit} alt="Pencil sketch of a three piece outfit" />
                        <br/>
                        <p>My outfit is a vest, crops and a light jacket, with a star theme!  I love having clothes that are half one colour and half another, it confuses people! So I went with the crops.</p>
                        <p>This is obviously not made entirely from scratch, I decided to use pre-made clothing that I took apart to create what I wanted.</p>
                        <p>This saved the cost of fabric and ensured that I got exactly what I wanted.</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Design1} alt=" " /></div>
                            <div className="galleryItem"><img src={Design2} alt=" " /></div>
                            <div className="galleryItem"><img src={Design3} alt=" " /></div>
                            <div className="galleryItem"><img src={Design4} alt=" " /></div>
                            <div className="galleryItem"><img src={Design5} alt=" " /></div>
                            <div className="galleryItem"><img src={Design6} alt=" " /></div>
                            <div className="galleryItem"><img src={Design7} alt=" " /></div>
                            <div className="galleryItem"><img src={Design8} alt=" " /></div>
                            <div className="galleryItem"><img src={Design9} alt=" " /></div>
                            <div className="galleryItem"><img src={Design10} alt=" " /></div>
                            <div className="galleryItem"><img src={Design11} alt=" " /></div>
                            <div className="galleryItem"><img src={Design12} alt=" " /></div>
                            <div className="galleryItem"><img src={Design13} alt=" " /></div>
                            <div className="galleryItem"><img src={Design14} alt=" " /></div>
                            <div className="galleryItem"><img src={Design15} alt=" " /></div>
                            <div className="galleryItem"><img src={Design16} alt=" " /></div>
                            <div className="galleryItem"><img src={Design17} alt=" " /></div>
                            <div className="galleryItem"><img src={Design18} alt=" " /></div>
                            <div className="galleryItem"><img src={Design19} alt=" " /></div>
                            <div className="galleryItem"><img src={Design20} alt=" " /></div>
                            <div className="galleryItem"><img src={Design21} alt=" " /></div>
                            <div className="galleryItem"><img src={Design22} alt=" " /></div>
                            <div className="galleryItem"><img src={Design23} alt=" " /></div>
                            <div className="galleryItem"><img src={Design24} alt=" " /></div>
                            <div className="galleryItem"><img src={Design25} alt=" " /></div>
                            <div className="galleryItem"><img src={Design26} alt=" " /></div>
                            <div className="galleryItem"><img src={Design27} alt=" " /></div>
                            <div className="galleryItem"><img src={Design28} alt=" " /></div>
                            <div className="galleryItem"><img src={Design29} alt=" " /></div>
                            <div className="galleryItem"><img src={Design30} alt=" " /></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">05</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Scrapbook</div>
                    <div className="badgePageItemInfo reverse hidden">Make a scrapbook about a fashion designer of your choice.</div>
                    <div className="badgePageItemEvidence">
                        <p>I chose to focus on House of Holland. I don't follow fashion or designers so I had to do some research, but I instantly fell for this designer when I found a pearly rainbow dress!</p>
                        <br/>
                        <p>I have made a digital scrapbook to save resources.</p>
                        <br/>
                        <p><a href={Scrapbook} target="_blank" rel="noopener noreferrer" className="linkUnderline">View my scrapbook</a></p>
                        <br/>
                        <p><strong>Important</strong>: This scrapbook has been made for personal use only for the purposes of this project and is not intended for sharing or distribution. Images have been taken from the main website and cropped and from screenshots from Google. They may not be licensed for use and no sources are provided. All copyright and credits to the original owners. if there are any problems with this content, please contact me and I will remove the link to the document. </p>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">06</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Capsule collection</div>
                    <div className="badgePageItemInfo reverse hidden">Design a capsule collection. Use sketches, fabric swatches, and any other images you need to bring it to life.</div>
                    <div className="badgePageItemEvidence">
                        <p>I took a slightly different approach to this as many of the examples I researched are not at all representative of what I do and would wear.</p>
                        <p>I worked with my current wardrobe and reduced it to 26 core items. I used my limited photoshop and office skills to turn the items into sketches for this clause.</p>
                        <p>It is, admittedly, very monochrome, but that's the way I work!</p>
                        <br/>
                        <img src={DesignCapsule} alt="A representation of a capsule wardrobe" />
                        <br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">07</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Non-clothing item</div>
                    <div className="badgePageItemInfo reverse hidden">Make a non-clothing item - for example a home furnishing, a bag or a toy. Ideally use recycled fabrics.</div>
                    <div className="badgePageItemEvidence">
                        <p>For this clause, I used one of my favourite t-shirts to make a tote bag. I loved this tshirt but it had little holes everywhere and wasn't really good enough to wear any more. Now it has a new lease of life!!</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Bag1} alt="Making a tote bag from a tshirt, progress shot." /></div>
                            <div className="galleryItem"><img src={Bag2} alt="Making a tote bag from a tshirt, progress shot." /></div>
                            <div className="galleryItem"><img src={Bag3} alt="Making a tote bag from a tshirt, progress shot." /></div>
                            <div className="galleryItem"><img src={Bag4} alt="Making a tote bag from a tshirt, progress shot." /></div>
                            <div className="galleryItem"><img src={Bag5} alt="Making a tote bag from a tshirt, progress shot." /></div>
                            <div className="galleryItem"><img src={Bag6} alt="Making a tote bag from a tshirt, progress shot." /></div>
                            <div className="galleryItem"><img src={Bag7} alt="Making a tote bag from a tshirt, progress shot." /></div>
                            <div className="galleryItem"><img src={Bag8} alt="Making a tote bag from a tshirt, progress shot." /></div>
                            <div className="galleryItem"><img src={Bag9} alt="Making a tote bag from a tshirt, progress shot." /></div>
                            <div className="galleryItem"><img src={Bag10} alt="Making a tote bag from a tshirt, progress shot." /></div>
                            <div className="galleryItem"><img src={Bag11} alt="Making a tote bag from a tshirt, progress shot." /></div>
                            <div className="galleryItem"><img src={Bag12} alt="Making a tote bag from a tshirt, progress shot." /></div>
                            <div className="galleryItem"><img src={Bag13} alt="Making a tote bag from a tshirt, progress shot." /></div>
                            <div className="galleryItem"><img src={Bag14} alt="Making a tote bag from a tshirt, progress shot." /></div>
                            <div className="galleryItem"><img src={Bag15} alt="Making a tote bag from a tshirt, progress shot." /></div>
                            <div className="galleryItem"><img src={Bag16} alt="Making a tote bag from a tshirt, progress shot." /></div>
                        </div>
                        <br/>
                    </div>
                </div>
            </div>            
        </div>
    )
}

export default Designer