import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import CrossStitch1 from '../images/crossstitch1.jpg';
import Tiger1 from '../images/advancedcraft-tiger1.jpg';
import Tiger2 from '../images/advancedcraft-tiger2.jpg';
import Tiger3 from '../images/advancedcraft-tiger3.jpg';
import Tiger4 from '../images/advancedcraft-tiger4.jpg';
import Tiger5 from '../images/advancedcraft-tiger5.jpg';
import Tiger6 from '../images/advancedcraft-tiger6.jpg';
import Tiger7 from '../images/advancedcraft-tiger7.jpg';
import Tiger8 from '../images/advancedcraft-tiger8.jpg';
import Tiger9 from '../images/advancedcraft-tiger9.jpg';
import Tiger10 from '../images/advancedcraft-tiger10.jpg';
import Tiger11 from '../images/advancedcraft-tiger11.jpg';
import Origami1 from '../images/advancedcrafter-origami1.jpg';
import Origami2 from '../images/advancedcrafter-origami2.jpg';
import Origami3 from '../images/advancedcrafter-origami3.jpg';
import Origami4 from '../images/advancedcrafter-origami4.jpg';
import Origami5 from '../images/advancedcrafter-origami5.jpg';
import Origami6 from '../images/advancedcrafter-origami6.jpg';
import Origami7 from '../images/advancedcrafter-origami7.jpg';
import Origami8 from '../images/advancedcrafter-origami8.jpg';
import Origami9 from '../images/advancedcrafter-origami9.jpg';
import Origami10 from '../images/advancedcrafter-origami10.jpg';
import Origami11 from '../images/advancedcrafter-origami11.jpg';
import Origami12 from '../images/advancedcrafter-origami12.jpg';
import Origami13 from '../images/advancedcrafter-origami13.jpg';
import Origami14 from '../images/advancedcrafter-origami14.jpg';
import Origami15 from '../images/advancedcrafter-origami15.jpg';
import Origami16 from '../images/advancedcrafter-origami16.jpg';
import Origami17 from '../images/advancedcrafter-origami17.jpg';
import Origami18 from '../images/advancedcrafter-origami22.jpg';
import Pergamano1 from '../images/advancedcrafter-pergamano1.jpg';
import Pergamano2 from '../images/advancedcrafter-pergamano2.jpg';
import Pergamano3 from '../images/advancedcrafter-pergamano3.jpg';
import Pergamano4 from '../images/advancedcrafter-pergamano4.jpg';
import Pergamano5 from '../images/advancedcrafter-pergamano5.jpg';
import Pergamano6 from '../images/advancedcrafter-pergamano6.jpg';
import Pergamano7 from '../images/advancedcrafter-pergamano7.jpg';
import Pergamano8 from '../images/advancedcrafter-pergamano8.jpg';
import Pergamano9 from '../images/advancedcrafter-pergamano9.jpg';
import Pergamano10 from '../images/advancedcrafter-pergamano10.jpg';
import Pergamano11 from '../images/advancedcrafter-pergamano11.jpg';
import Pergamano12 from '../images/advancedcrafter-pergamano12.jpg';
import Pergamano13 from '../images/advancedcrafter-pergamano13.jpg';
import Pergamano14 from '../images/advancedcrafter-pergamano14.jpg';
import Pergamano15 from '../images/advancedcrafter-pergamano15.jpg';
import Pergamano16 from '../images/advancedcrafter-pergamano16.jpg';
import Pergamano17 from '../images/advancedcrafter-pergamano17.jpg';
import Pergamano18 from '../images/advancedcrafter-pergamano18.jpg';
import Pergamano19 from '../images/advancedcrafter-pergamano19.jpg';
import Pergamano20 from '../images/advancedcrafter-pergamano20.jpg';
import Pergamano21 from '../images/advancedcrafter-pergamano21.jpg';
import Pergamano22 from '../images/advancedcrafter-pergamano22.jpg';
import Pergamano23 from '../images/advancedcrafter-pergamano23.jpg';
import Pergamano24 from '../images/advancedcrafter-pergamano24.jpg';
import Pergamano25 from '../images/advancedcrafter-pergamano25.jpg';
import Pergamano26 from '../images/advancedcrafter-pergamano26.jpg';
import Pergamano27 from '../images/advancedcrafter-pergamano27.jpg';
import Pergamano28 from '../images/advancedcrafter-pergamano28.jpg';
import Pergamano29 from '../images/advancedcrafter-pergamano29.jpg';
import Pergamano30 from '../images/advancedcrafter-pergamano30.jpg';
import Pergamano31 from '../images/advancedcrafter-pergamano31.jpg';
import Pergamano32 from '../images/advancedcrafter-pergamano32.jpg';



const AdvancedCrafter = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"Advanced Crafter"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Cross Stitch</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        Very many years ago I brought myself a cross stitch Tiger to do. 
                        <br/>
                        Very many years later, I still hadn't got any further than having added the black outline.
                        <br />
                        So, for this section I am reviving this poor forgotten cross stitch and I'm going to complete it!
                        <br/><br/>
                        Here's a picture of me getting started!
                        <br/><br/>
                        <img className="imgHalf" src={CrossStitch1} alt="Cross stitch canvas on an embroidery hoop with the threads and pattern."  loading="lazy" />
                        <br/><br/>
                        I started working one colour at a time, but eventually I decided to take the piece by quarters and complete one quarter at a time - that way, I could see progress easily and it felt like I was getting somewhere! It was more manageable chunks for keeping track of the pattern too.
                        <br/><br/>
                        After probably at least 50 hours of work, I finally finished it as I had wanted to for so long, it took a very dedicated period of hyperfocus to achieve!!
                        <br/><br/>
                        Here's the finished piece and a gallery of progress:
                        <br/><br/>
                        <img className="imgThird" src={Tiger1} alt="Cross-stitch Tiger" loading="lazy" />
                        <img className="imgThird" src={Tiger2} alt="Cross-stitch Tiger" loading="lazy" />
                        <br/><br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Tiger3} alt="Cross-stitch Tiger progress shot"  loading="lazy" /></div>
                            <div className="galleryItem"><img src={Tiger4} alt="Cross-stitch Tiger progress shot"  loading="lazy" /></div>
                            <div className="galleryItem"><img src={Tiger5} alt="Cross-stitch Tiger progress shot" loading="lazy" /></div>
                            <div className="galleryItem"><img src={Tiger6} alt="Cross-stitch Tiger progress shot" loading="lazy" /></div>
                            <div className="galleryItem"><img src={Tiger7} alt="Cross-stitch Tiger progress shot" loading="lazy" /></div>
                            <div className="galleryItem"><img src={Tiger8} alt="Cross-stitch Tiger progress shot" loading="lazy" /></div>
                            <div className="galleryItem"><img src={Tiger9} alt="Cross-stitch Tiger progress shot" loading="lazy" /></div>
                            <div className="galleryItem"><img src={Tiger10} alt="Cross-stitch Tiger progress shot" loading="lazy" /></div>
                            <div className="galleryItem"><img src={Tiger11} alt="Cross-stitch Tiger progress shot" loading="lazy" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Origami</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        When I was investigating origami for the crafter badge, I came across some advanced projects and decided I needed to try one in particular.
                        <br/><br/>
                        Pattern: <a className="linkUnderline"href="https://www.instructables.com/Modular-Origami-Mini-Winged-Swan-181-Pieces/" target="_blank" rel="noopoener noreferrer">Mini Origami Swan</a>
                        <br/><br/>
                        This is going to be a challenge, I need to get my cuts and folds super clean!
                        <br/><br/>
                        It went ok in the end. The instructions were good but were missing one or two guidance points, took me a while to figure out! I got there eventually (we won't talk about the extra units I have that seem to be missing from somewhere!)
                        <br/><br/>
                        <div className='gallery'>
                            <div className='galleryItem'><img src={Origami1} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Origami2} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Origami3} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Origami4} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Origami5} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Origami6} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Origami7} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Origami8} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Origami9} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Origami10} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Origami11} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Origami12} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Origami13} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Origami14} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Origami15} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Origami16} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Origami17} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Origami18} alt="" loading="lazy" /></div>
                        </div>
                    </div>
                </div>
            </div>          
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Pergamano</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>Years ago, I was introduced to the art of Pergamano by my Auntie. I absolutely love it but don't do it very often any more, not least because supplies are expensive!</p>
                        <br/>
                        <p>Pergamano involves tracing a pattern with white ink onto parchment paper, then decorating it using various techniques such as pricking, embossing and cutting and colouring using tinted inks, pinta paints, dorso pastels or pergamano pens.</p>
                        <br/>
                        <p>These are three of the most elaborate designs that I have made. I only have progress shots for the umbrella as they are backdated. The umbrella is my absolute favourite, I have made this a few times!</p>
                        <br/>
                        <div className="gallery">
                            <div className='galleryItem'><img src={Pergamano30} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Pergamano31} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Pergamano32} alt="" loading="lazy" /></div>
                        </div>
                        <br/><br/>
                        <div className="gallery">
                            <div className='galleryItem'><img src={Pergamano1} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Pergamano2} alt=""loading="lazy" /></div>
                            <div className='galleryItem'><img src={Pergamano3} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Pergamano4} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Pergamano5} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Pergamano6} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Pergamano7} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Pergamano8} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Pergamano9} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Pergamano10} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Pergamano11} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Pergamano12} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Pergamano13} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Pergamano14} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Pergamano15} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Pergamano16} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Pergamano17} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Pergamano18} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Pergamano19} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Pergamano20} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Pergamano21} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Pergamano22} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Pergamano23} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Pergamano24} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Pergamano25} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Pergamano26} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Pergamano27} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Pergamano28} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Pergamano29} alt="" loading="lazy" /></div>
                            <div className='galleryItem'><img src={Pergamano30} alt="" loading="lazy" /></div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default AdvancedCrafter