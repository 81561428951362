import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const MyBuddy = () => {
    return (
        <div>
            <ActionBar area={"silver"}  />
            <EvidenceHeader title={"My Buddy"} />
            <div className="mavPageItem">
                <div className="mavPageItemTitle silver">My Buddy</div>
                <div className="mavPageItemContent">
                    <div className="mavPageItemInfo silver-light">Pair up with at least one fellow rebel and plan and execute either an expedition, an exploration of a country or region you have never been to before, or a project with a positive impact for others. Prepare a presentation about your group activity.  The buddy does not need to be completing their Maverick Award, however they do need to be an active Rebel. You could work with a Rebel in another country over Zoom, or recruit a friend or family member to the Rebel tribe.</div>
                    <div className="mavPageItemEvidence">
                    
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyBuddy