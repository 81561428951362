import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Donate1 from '../images/choc23-donate1.jpg';
import Dessert1 from '../images/choc23-mousse1.jpg';
import Dessert2 from '../images/choc23-mousse2.jpg';
import Dessert3 from '../images/choc23-mousse3.jpg';
import Dessert4 from '../images/choc23-mousse4.jpg';
import Dessert5 from '../images/choc23-mousse5.jpg';
import Sculpture1 from '../images/choc23-sculpture1.jpg';
import Sculpture2 from '../images/choc23-sculpture2.jpg';
import Sculpture3 from '../images/choc23-sculpture3.jpg';
import Sculpture4 from '../images/choc23-sculpture4.jpg';
import Sculpture5 from '../images/choc23-sculpture5.jpg';
import Sculpture6 from '../images/choc23-sculpture6.jpg';
import Sculpture7 from '../images/choc23-sculpture7.jpg';
import Sculpture8 from '../images/choc23-sculpture8.jpg';
import Sculpture9 from '../images/choc23-sculpture9.jpg';
import Sculpture10 from '../images/choc23-sculpture10.jpg';
import Sculpture11 from '../images/choc23-sculpture10.jpg';
import Sculpture12 from '../images/choc23-sculpture12.jpg';
import Sculpture13 from '../images/choc23-sculpture13.jpg';
import Sculpture14 from '../images/choc23-sculpture14.jpg';
import Sculpture15 from '../images/choc23-sculpture15.jpg';
import Sculpture16 from '../images/choc23-sculpture16.jpg';
import Sculpture17 from '../images/choc23-sculpture17.jpg';
import Sculpture18 from '../images/choc23-sculpture18.jpg';
import Sculpture19 from '../images/choc23-sculpture19.jpg';
import Sculpture20 from '../images/choc23-sculpture20.jpg';
import Sculpture21 from '../images/choc23-sculpture21.jpg';
import Sculpture22 from '../images/choc23-sculpture22.jpg';
import Sculpture23 from '../images/choc23-sculpture23.jpg';
import Sculpture24 from '../images/choc23-sculpture24.jpg';
import Sculpture25 from '../images/choc23-sculpture25.jpg';
import Sculpture26 from '../images/choc23-sculpture26.jpg';
import Sculpture27 from '../images/choc23-sculpture27.jpg';
import Sculpture28 from '../images/choc23-sculpture28.jpg';
import Sculpture29 from '../images/choc23-sculpture29.jpg';
import Sculpture30 from '../images/choc23-sculpture30.jpg';
import Sculpture31 from '../images/choc23-sculpture31.jpg';
import Sculpture32 from '../images/choc23-sculpture32.jpg';
import Sculpture33 from '../images/choc23-sculpture33.jpg';
import Types from '../images/RebelChocolate.jpg';

const ChocApr23 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Chocolate Challenge"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle green-light">Clause 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-lighter">Research the history of chocolate and how it has changed over the years</div>
                    <div className="badgePageItemEvidence"> 
                        <p>Although chocolate was first known to exist around 1000BC, it was only available in liquid form until the 19th Century when Dutchman Conrad van Houten developed the "dutching" process.</p>         
                        <br/>
                        <p>The original name of "Kakawa" was key in deciphering the Mayan phonetic writing!</p>
                        <br/>
                        <p>Chocolate was always considered to be connected to the divine, with the Maya believed it was the food of the gods. It has been used as a face paint in rituals and as part of burial ceremonies.</p>
                        <br/>
                        <p>In the 18th Century, it was classified as "theobroma cacao" (drink of the gods) by Linnaeus, a Swedish botanist.</p>
                        <br/>
                        <p>In 1502, Christopher Columbus took cocoa beans to Spain but the exact time that chocolate arrived in Europe is unclear.</p>
                        <br/>
                        <p>Other reports suggest it arrived in Spain in the 16th century via Hernan Cortes who brought it back from Mesoamerica.</p>
                        <br/>
                        <p>In 1585 cocoa beans were recorded on a shipment from Mexico to Spain.</p>
                        <br/>
                        <p>The 16th Century also saw chocolate become currency - in Nicargua 100 beans would buy you a slave and for 10 beans you could procure a prostitute.</p>
                        <br/>
                        <p>It is reported that chocolate was introduced to France in 1651, being transported by a Spanish maid to Anne of Austria when she married Louis XIII. </p>
                        <br/>
                        <p>Shops opened in London in 1657 to sell solid chocolate, but only the wealthy could afford it. These turned into fashionable chocolate houses around Europe and in London became meeting places for politicians.</p>
                        <br/>
                        <p>It was in 1700 when the English added Milk to chocolate, improving it, but it wasn't until the mid-19th Century that duty on imported beans was reduced enough to make chocolate popular.</p>
                        <br/>
                        <p>The process was gradually refined over the years.</p>
                        <br/>
                        <ul className="list">
                            <li>1765 - Chocolate manufacture started in the american colonies. Beans were ground in a mill using water power.</li>
                            <li>1828 - A process for pressing was invented in the Netherlands </li>
                            <li>1847 - Cocoa butter, chocolate liquor and sugar were combined to create sweet (eating) chocolate in England</li>
                            <li>1873 - The first chocolate Easter Egg was created</li>
                            <li>1876 - Dried milk was added to make milk chocolate in Switzerland</li>
                        </ul>
                        <br/><br/>
                        <p>A more recent development is Ruby chocolate.  This is created from the cross-bred ruby cocaa beans, cultivated in the Ivory Coast, Equador and Brazil and was introduced in 2017 by Barry Callebaut. </p>
                        <br/>
                        <p>The beans are mixed with white chocolate and it has a sweet, fruity flavour.</p>
                        <br/><br/>
                        <p>Various spices and flavours were added to chocolate in its liquid form and it was used for celebrations and for medicinal purposes alike. Popular additions were maize, chilli, aniseed, flowers, sugar, vanilla and honey. </p>
                        <br/>
                        <p>These days you can buy chocolate in very many different flavours!</p>
                        <br/><br/>
                        <p>Unfortunately it also has some negative connotations as slavery is endemic in chocolate production. In reports as recent as 2003, it was suggested that over 200,000 children were still working on plantations on the Ivory Coast.</p>
                        <br/><br/>
                        <p>Allegedly, the Nazis plotted to kill Winston Churchill with an exploding bar ofchocolate during World War 2!</p>
                        <br/><br/><br/><br/>
                        <p>Sources:</p>
                        <br/>
                        <ul className='list'>
                            <li>https://www.theguardian.com/lifeandstyle/2007/apr/07/foodanddrink</li>
                            <li>https://www.bbc.co.uk/bitesize/articles/zngsqp3</li>
                            <li>https://www.britannica.com/topic/chocolate</li>
                            <li>https://www.cocoaandheart.co.uk/the-ultimate-guide-to-ruby-chocolate/</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle chocolate">Clause 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo chocolate-light">Find out about the fair trade mark and ethical chocolate production</div>
                    <div className="badgePageItemEvidence"> 
                        <p>The Fairtrade Mark is an indicator that the people involved in the production process have been paid a fair wage.</p>                    
                        <br/>
                        <p>The main principles are:</p>
                        <br/>
                        <ul className='list'>
                            <li>""Trade not aid". This is a phrase that sums up the belief that people should be empowered to improve their circumstances through trade, rather than being reliant on charity from richer countries."</li>
                            <li>"All producers are entitled to safe and dignified working conditions, free from discrimination of any kind."</li>
                            <li>"To be considered Fair Trade, transactions must benefit all parties. Trading arrangements should be based on financial partnership, trust, and mutual growth."</li>
                        </ul>
                        <br/><br/>
                        <p>There is some debate over whether Fairtrade's work is positive, claiming that only 0-50% of the money generated from consumers goes back to the workers. Fairtrade acknowledge that their work is not an "instant solution" rather a "step in the right direction".</p>
                        <br/><br/><br/><br/>
                        <p>Ethical chocolate producers have a clean record on human rights and environmental issues. Ethical chocolate production requires that the process is both socially responsible and sustainable, ensuring that farmers receive fair pay and safe working conditions as well as the use of sustainable farming practices. </p>
                        <br/>
                        <p>Not all companies are equal when it comes to this. The main concerns are:</p>
                        <br/>
                        <ul className="list">
                            <li>Child labour - 2 million children engaged in hazardous labour</li>
                            <li>Environmental issues - Deforestation</li>
                            <li>Palm Oil</li>
                            <li>Poverty</li>
                        </ul>
                        <br/><br/>
                        <p>Always look for Fairtrade or Rainforest Alliance marks when buying chocolate as they support deforestation prevention and fair pricing.</p>
                        <br/>
                        <p>The certifications from these organisations make the production chains more transparent and help to support farmer orgsniation.</p>
                        <br/><br/>
                        <p>Ethical consumer have a nice graphic showing which companies to support and which to avoid when it comes to buying chocolate.</p>
                        <br/>
                        <p>https://www.ethicalconsumer.org/food-drink/shopping-guide/ethical-chocolate</p>
                        <br/><br/>
                        <br/><br/>
                        <p>Sources:</p>
                        <br/>
                        <ul className='list'>
                            <li>https://www.sourcetrace.com/blog/is-your-chocolate-ethical</li>
                            <li>https://www.ethicalconsumer.org/food-drink/shopping-guide/ethical-chocolate</li>
                            <li>https://www.fairtrade.org.uk/media-centre/blog/10-facts-about-fairtrade-chocolate/</li>
                            <li>https://www.twinkl.co.uk/teaching-wiki/fair-trade</li>
                            <li>https://www.fairtrade.org.uk/fairtrade-mark/</li>
                        </ul>
                        <br/><br/>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green-light">Clause 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-lighter">Give someone a chocolate bar as a random act of kindness</div>
                    <div className="badgePageItemEvidence"> 
                        Sadly, I forgot to photograph the bars, but I purchased two Cadbury's Dairy Milk bars in the flavour of "Hot Cross Bun" and gave them away over Easter!
                        <br/><br/>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle chocolate">Clause 4</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo chocolate-light">Learn about chocolate classification. Make a visual aid to explain the different types of chocolate.</div>
                    <div className="badgePageItemEvidence"> 
                        <img src={Types} alt="Types of chocolate" />
                        <br/><br/>
                        <p>Source: https://en.wikipedia.org/wiki/Types_of_chocolate</p>
                        <br/><br/>
                        <p>Images sourced from Pixabay</p>
                        <br/><br/>
                        <p>The Cocoa and Chocolate Products (England) Regulations 2003: https://www.legislation.gov.uk/uksi/2003/1659/schedule/1/made</p>
                        <br/><br/>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green-light">Clause 5</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-lighter">Make a chocolate-based dessert</div>
                    <div className="badgePageItemEvidence"> 
                        <p>For this clause, I made White Chocolate and Cardamom mousse. The cardamom flavour wasn't very strong, but I suspect my pods aren't very fresh! The family enjoyed them anyway, they are cream fans!!</p>                                         
                        <br/><br/>
                        <img className="imgThird" src={Dessert1} alt="A mini pestle and mortar containing cardamom pods ready for grinding" />
                        <img className="imgThird" src={Dessert2} alt="Two saucepands on a gas hob, one melting white chocolate, the other heating cream with cardamom pods" />
                        <img className="imgThird" src={Dessert3} alt="A mixing bowl with ingredients mixed together making a fluffy, creamy mixture." />
                        <img className="imgThird" src={Dessert4} alt="Three pots containing a creamy dessert" />
                        <img className="imgThird" src={Dessert5} alt="Close up of a single creamy dessert in a pot with little bubbles on top" />
                        <br/><br/>
                        <p>Recipe: <a href="https://www.bbcgoodfood.com/recipes/white-choc-cardamom-mousses" target="_blank" rel="noopener noreferrer">BBC Good Food - White Choc and Cardamom Mousses</a></p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle chocolate">Clause 6</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo chocolate-light">Donate an easter egg to a food bank</div>
                    <div className="badgePageItemEvidence"> 
                        I refuse to take photos of me physically donating items, but these are the items that I donated to the food bank. 
                        <br/><br/>
                        <img className="imgThird" src={Donate1} alt="Milkybar easter egg, freddo chocolate bars and KitKat easter bunnies" />
                        <br/><br/>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green-light">Clause 7</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-lighter">Create a chocolate sculpture</div>
                    <div className="badgePageItemEvidence"> 
                        <p>I'm not sure that sculpture is going to be my thing, but I gave it a good shot!!</p>
                        <br/>
                        <p>I purchased a small bag of modelling chocolate and chose some helpful fimo designs on google!</p>
                        <br/>
                        <p>Despite having washed my hands twice before starting and them looking spotless, the chocolate picked up tiny fibres from my hand towel which was a nuisance. I made the first one as a prototype and then wore gloves to do the rest. Actually, using the gloves was much easier and gave a much smoother finish.</p>
                        <br /><br/>
                        <p>The next issue I encountered was that I had no food colouring, so the little models were going to look very plain. So, I put my thinking cap on and looked at what else I had that I could use.</p>
                        <br/>
                        <p>The pink highlights were created using icing sugar and cherries & berries squash!</p>
                        <br/>
                        <p>The orange highlights were trickier. Using icing sugar with Tropical squash didn't work, the orange colour wasn't string enough. Eventually I realised I had custard powder, so when mixed in thtat was perfect!</p>
                        <br/>
                        <p>The little brown eyes were pieces of sultana!</p>
                        <br/><br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Sculpture1} alt="Modelling chocolate on a choppig board" /></div>
                            <div className="galleryItem"><img src={Sculpture2} alt="Bunny made from modelling chocolate" /></div>
                            <div className="galleryItem"><img src={Sculpture3} alt="Bunny made from modelling chocolate" /></div>
                            <div className="galleryItem"><img src={Sculpture4} alt="Bunny made from modelling chocolate" /></div>
                            <div className="galleryItem"><img src={Sculpture5} alt="Bunny made from modelling chocolate" /></div>
                            <div className="galleryItem"><img src={Sculpture6} alt="Bunny made from modelling chocolate" /></div>
                            <div className="galleryItem"><img src={Sculpture7} alt="Bunny made from modelling chocolate" /></div>
                            <div className="galleryItem"><img src={Sculpture8} alt="Bunny made from modelling chocolate" /></div>
                            <div className="galleryItem"><img src={Sculpture9} alt="Bunny made from modelling chocolate" /></div>
                            <div className="galleryItem"><img src={Sculpture10} alt="Bunny made from modelling chocolate" /></div>
                            <div className="galleryItem"><img src={Sculpture11} alt="Bunny made from modelling chocolate" /></div>
                            <div className="galleryItem"><img src={Sculpture12} alt="Bunny made from modelling chocolate" /></div>
                            <div className="galleryItem"><img src={Sculpture13} alt="Bunny made from modelling chocolate" /></div>
                            <div className="galleryItem"><img src={Sculpture14} alt="Icing sugar in a small ceramic dish mixed with cherries and berries squash" /></div>
                            <div className="galleryItem"><img src={Sculpture15} alt="Bunny holding an easter egg made from modelling chocolate" /></div>
                            <div className="galleryItem"><img src={Sculpture16} alt="Bunny holding an easter egg made from modelling chocolate" /></div>
                            <div className="galleryItem"><img src={Sculpture17} alt="Two bunnies made from modelling chocolate" /></div>
                            <div className="galleryItem"><img src={Sculpture18} alt="Two bunnies made from modelling chocolate" /></div>
                            <div className="galleryItem"><img src={Sculpture19} alt="Two bunnies made from modelling chocolate" /></div>
                            <div className="galleryItem"><img src={Sculpture20} alt="Two bunnies made from modelling chocolate" /></div>
                            <div className="galleryItem"><img src={Sculpture21} alt="Two bunnies made from modelling chocolate" /></div>
                            <div className="galleryItem"><img src={Sculpture22} alt="Two bunnies made from modelling chocolate" /></div>
                            <div className="galleryItem"><img src={Sculpture23} alt="Two bunnies made from modelling chocolate" /></div>
                            <div className="galleryItem"><img src={Sculpture24} alt="Three chicks made from modelling chocolate" /></div>
                            <div className="galleryItem"><img src={Sculpture25} alt="Two bunnies and three chicks made from modelling chocolate" /></div>
                            <div className="galleryItem"><img src={Sculpture26} alt="Chocolate bunnies and chicks on top of an iced cake" /></div>
                            <div className="galleryItem"><img src={Sculpture27} alt="Chocolate bunnies and chicks on top of an iced cake" /></div>
                            <div className="galleryItem"><img src={Sculpture28} alt="Chocolate bunnies and chicks on top of an iced cake with candles" /></div>
                            <div className="galleryItem"><img src={Sculpture29} alt="Chocolate bunnies and chicks on top of an iced cake with candles" /></div>
                            <div className="galleryItem"><img src={Sculpture30} alt="Bunny made from modelling chocolate" /></div>
                            <div className="galleryItem"><img src={Sculpture31} alt="Bunny made from modelling chocolate" /></div>
                            <div className="galleryItem"><img src={Sculpture32} alt="Bunny made from modelling chocolate" /></div>
                            <div className="galleryItem"><img src={Sculpture33} alt="Bunny made from modelling chocolate" /></div>
                        </div>
                        <br/><br/>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle chocolate">Clause 8</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo chocolate-light">Send someone on a chocolate hunt</div>
                    <div className="badgePageItemEvidence"> 
                        <p>I don't have photos for this, but I bought a bag of Ferrero Rocher eggs and hid them around the parents' house then sent my nieces and nephew on an egg hunt!</p>          
                        <br/><br/>
                        <p>There were 10 eggs - one of them jumped out at my sister-in-law when she arrived and she ate it so there were nine left for three children, which actually worked out better!!</p>
                        <br/><br/>
                        <p>My nephew had spotted an egg earlier in the day so had made it his mission to covertly locate them ahead of time. So, when they found the eggs, I made the children share them out between themselves, then suggested they actually have one each and offer the rest among the adults, which they did.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChocApr23