import React from 'react';
import {Link} from 'react-router-dom';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Book1 from '../images/annual-52books24-1.jpg';

const  Books24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"52 Books in a Year"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle darkblue">Read 52 Books in a Year</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>I am going to partly back-date this one. I read 42 books last year for the Pop Sugar 2023 reading challenge to complete my <Link to="/advancedreader" className="linkUnderline">Advanced Reader</Link> badge, and another 3 that I know of on top of that.</p>
                        <p>I need to read another 7 books by the end of February to complete this challenge.</p>       
                        <br/>
                        <p>Here are the additional books I have read:</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Book1} alt="Book title One August Night" /></div>
                        </div>              
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Books24