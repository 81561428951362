import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import MyPassion from '../videos/MyPassion.mp4';

const Passion = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"My Passion"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Clause 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>I started beach cleaning in 2018 and it turned into my passion, along with plastic free projects. I have maintained that regularly since, including over the last 6 months.</p>
                        <p>Rather than actually showcasing litter picking - it's not the most enticing subject! - I have put together a video of my journey from nothing to running multiple community groups, which illustrates how pursuing my love of the ocean got me in to a lot of things!</p>
                        <br/>
                        <video width="1024" height="768" controls>
                            <source src={MyPassion} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default Passion