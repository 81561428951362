import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import MasterLoft from '../images/room2-masterloft.jpg';
import AdventureOne from '../images/bronze-adventure1.jpg';
import AdventureTwo from '../images/bronze-adventure2.jpg';
import AdventureThree from '../images/bronze-adventure3.jpg';
import AdventureFour from '../images/bronze-adventure4.jpg';
import AdventureSix from '../images/bronze-adventure6.jpg';
import AdventureSeven from '../images/bronze-adventure7.jpg';
import AdventureEight from '../images/bronze-adventure8.jpg';
import AdventureNine from '../images/bronze-adventure9.jpg';
import AdventureTen from '../images/bronze-adventure10.jpg';
import AdventureEleven from '../images/bronze-adventure11.jpg';
import AdventureTwelve from '../images/bronze-adventure12.jpg';
import AdventureFourteen from '../images/bronze-adventure14.jpg';
import AdventureSixteen from '../images/bronze-adventure16.jpg';
import AdventureSeventeen from '../images/bronze-adventure17.jpg';
import AdventureEighteen from '../images/bronze-adventure18.jpg';
import AdventureNineteen from '../images/bronze-adventure19.jpg';
import AdventureTwenty from '../images/bronze-adventure20.jpg';
import AdventureTwentyOne from '../images/bronze-adventure21.jpg';
import AdventureTwentyTwo from '../images/bronze-adventure22.jpg';
import AdventureTwentyThree from '../images/bronze-adventure23.jpg';
import AdventureTwentyFour from '../images/bronze-adventure24.jpg';
import AdventureTwentyFive from '../images/bronze-adventure25.jpg';
import AdventureTwentySix from '../images/bronze-adventure26.jpg';
import AdventureTwentySeven from '../images/bronze-adventure27.jpg';
import AdventureTwentyEight from '../images/bronze-adventure28.jpg';
import AdventureTwentyNine from '../images/bronze-adventure29.jpg';
import AdventureThirty from '../images/bronze-adventure30.jpg';
import AdventureThirtyOne from '../images/bronze-adventure31.jpg';
import AdventureThirtyTwo from '../images/bronze-adventure32.jpg';
import AdventureThirtyThree from '../images/bronze-adventure33.jpg';
import AdventureThirtyFour from '../images/bronze-adventure34.jpg';
import AdventureThirtyFive from '../images/bronze-adventure35.jpg';
import AdventureThirtySix from '../images/bronze-adventure36.jpg';
import AdventureThirtySeven from '../images/bronze-adventure37.jpg';
import AdventureThirtyEight from '../images/bronze-adventure38.jpeg';

const MyAdventure = () => {
    return (
        <div>
            <ActionBar area={"bronze"} />
            <EvidenceHeader title={"My Adventure"} area={"bronze"} />
            <div className="mavPageItem">
                <div className="mavPageItemTitle brown">My Adventure</div>
                <div className="mavPageItemContent">
                    <div className="mavPageItemInfo brown-light">Plan and execute an adventure for YOU which involves at least two nights away from home.</div>
                    <div className="mavPageItemEvidence">
                        <p>Now that I have completed and passed my assessment, I have redacted a lot of the background to this clause due to the personal nature of it as this site is linked to my professional profile.</p>
                        <br/>
                        <p>Anyone is welcome to get in touch and ask me more about it.</p>
                        <br/>
                        <p>Essentially, I convinced my daughter we needed to go on an adventure and she was more than happy to oblige! I told her I would pay if she would drive!</p>
                        <br/>
                        <p>I decided that going away did not mean that we had to go long-distance. There are plenty of places nearby to explore.</p>
                        <br/><br/>
                        <p>I started by looking for Christmas markets. We love christmas and a good market so that was a good starting point. I live in Christchurch on the south coast and she is an hour away from me closer to Andover. The nearest option was Bath - 2 hours in the wrong direction - or Exeter - 2 hours in the right direction but still 2 hours of additional driving for her! </p>
                        <br/>
                        <p>Bournemouth is way too close to home and I'm not a fan. Next stop was Southampton.</p>
                        <br/>
                        <p>Southampton is due to have a christmas market this year, christmas lights and various family activities. It's not far to drive, there are plenty of places to stay and explore.</p>
                        <br/>
                        <p>I started looking at hotels. I wanted to stay somewhere nice, she works in hotels so I didn't want it to be a busman's holiday. I found a couple of nice options but settled eventually on room2.</p>
                        <br/>
                        <p>I sent her the details to make sure she was happy with it. We both liked the look of it because it is niche, has great facilities - more like a apartment than a hotel room - and is <a href="https://room2.com/southampton/sustainability/" target="_blank" rel="noopener noreferrer">focussed on sustainability</a>, which is something we are both very keen on!</p>
                        <br/>
                        <p>We managed to find some dates that worked for us both aaaand ...... tonight (12/9/22) I've booked it :)</p>
                        <br/>
                        <img src={MasterLoft} alt="Inside the Master Loft at room2" className="mavInline" />
                        <p>Inside the Master Loft</p>
                        <br/><br/>
                        <p>I am just waiting on the hotel now for confirmation that the room I've booked is not pet friendly. I am allergic to dogs so cannot stay in a "pet friendly" room. Honestly, dogs can go to more places than I can these days, everything is pet friendly but not human friendly any more.</p>
                        <br/><br/>
                        <p>I was slightly annoyed after booking to find that I had to pay extra to book a dedicated parking space for two nights - the 20% I saved on the room for being a member paid for that though so I suppose net save!</p>
                        <br/><br/>
                        <p>Watch this space!</p>
                        <br/><br/>
                        <p>2nd - 4th December we will be exploring what Southampton has to offer.</p>
                        <br/><br/>
                        <hr />
                        <br/><br/>
                        <p><h3 className="bronze">Update 1:</h3></p>
                        <p>Good news, the hotel have a pet-free floor, so they've made sure my room is on that floor. That's a relief!</p>
                        <br/><br/>
                        <hr />
                        <br/><br/>
                        <p><h3 className="bronze">Update 2:</h3></p>
                        <p>Bad news, Southampton christmas market has been cancelled</p>
                        <br/><br/>
                        <hr />
                        <br/><br/>
                        <p><h3 className="bronze">Update 3:</h3></p>
                        <p>Good news, the christmas market has been saved by another operator! </p>
                        <br/><br/>
                        <hr />
                        <br/><br/>
                        <p><h3 className="bronze">Update 4:</h3></p>
                        <p>December 2nd 2022</p>
                        <p>We found the hotel!! That's step one complete. </p>
                        <img src={AdventureOne} alt="room2 Hometel front view from the road" className="adventureImg" />
                        <p>room2 Hometel front view from the road</p>
                        <br/>
                        <p>It wasn't the best view and it was very grey, but we could see the harbour</p>
                        <img src={AdventureTwo} alt="View from the window of our room2 hotel room" className="adventureImg" />
                        <p><h3 className="bronze">Here's the inside of the room</h3></p>
                        <div className="adventureGallery">
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureEighteen} alt="" /></div>
                                <div className="adventureGalleryItemDesc">Arriving at our room!</div>
                            </div>
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureThree} alt="" /></div>
                                <div className="adventureGalleryItemDesc">Walking into the room, kitchen and table to the left, huge sofa and bed one to the right.</div>
                            </div>
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureFour} alt="" /></div>
                                <div className="adventureGalleryItemDesc">Bed one and the loft!</div>
                            </div>
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureSix} alt="" /></div>
                                <div className="adventureGalleryItemDesc">Kitchen</div>
                            </div>
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureSeven} alt="" /></div>
                                <div className="adventureGalleryItemDesc">Bathroom</div>
                            </div>
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureEight} alt="" /></div>
                                <div className="adventureGalleryItemDesc">The treacherous stairs to my bed!! (Fun with rum)</div>
                            </div>
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureNine} alt="" /></div>
                                <div className="adventureGalleryItemDesc">The loft bed</div>
                            </div>
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureTen} alt="" /></div>
                                <div className="adventureGalleryItemDesc">My view from the loft</div>
                            </div>
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureEleven} alt="" /></div>
                                <div className="adventureGalleryItemDesc">A warning about the stairs!</div>
                            </div>
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureTwelve} alt="" /></div>
                                <div className="adventureGalleryItemDesc">This kitchen is better stocked than mine!</div>
                            </div>
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureFourteen} alt="" /></div>
                                <div className="adventureGalleryItemDesc">"Home is a feeling" - I agree</div>
                            </div>
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureSixteen} alt="" /></div>
                                <div className="adventureGalleryItemDesc">Dishwasher and fridge</div>
                            </div>
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureSeventeen} alt="" /></div>
                                <div className="adventureGalleryItemDesc">Storage, safe and ironing</div>
                            </div>
                        </div>
                        <br/>
                        <hr />
                        <p><h3 className="bronze">The Hometel life</h3></p>
                        <p>So, what's so special about a Hometel? It's a cross between a hotel and an apartment. Our loft was a huge space with a bathroom and kitchen so we had everything we needed to cater for ourselves with the added benefit of hotel facilities.</p>
                        <br/>
                        <p>I really have an aversion to being indoors (in shared spaces) right now, so I wasn't keen on having to eat out. Having a kitchen in the room was perfect and meant we could also meal plan and cook together too.</p>
                        <br/>
                        <p>The hotel had a little pantry where you could go to help yourself to anything you might be missing while cooking or to borrow games, swap your breakfast and more!</p>
                        <br/>
                        <p>There was also a bar, lounge, laundry and gym but because we're not very people-y we mostly dodged those - exept to get our milk topped up at the bar!!</p>
                        <br/>
                        <p>All in all, highly recommended</p>
                        <br/><br/>
                        <hr />
                        <br/><br/>
                        <p><h3 className="bronze">Here are a few of the sustainability features in the room</h3></p>
                        <br/>
                        <div className="adventureGallery">
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureNineteen} alt="" /></div>
                                <div className="adventureGalleryItemDesc">Zero waste collection bins</div>
                            </div>
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureTwenty} alt="" /></div>
                                <div className="adventureGalleryItemDesc">Rubbish, recycling and food bins</div>
                            </div>
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureTwentyOne} alt="" /></div>
                                <div className="adventureGalleryItemDesc">Milk bottle which we took to the bar to be filled</div>
                            </div>
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureTwentyTwo} alt="" /></div>
                                <div className="adventureGalleryItemDesc">All the drinks and snacks sachets were plastic free</div>
                            </div>
                        </div>
                        <br/>
                        <p>They are fuelled by renewable energy, have a bug hotel on site, use refillable options and have various other eco measures. They have won the green tourism gold award. For environment conscious travellers they are the perfect solution. OK, this isn't a paid advert, honest, I just think this is really good!</p>
                        <br/>
                        <hr />
                        <p><h3 className="bronze">Breakfast</h3></p>
                        <p>The breakfast fairies came overnight and left our goodies on the doorstep!.</p>
                        <p>On arrival we were asked about allergies, to which we both claimed none. I do have food allergies, but nothing I'd be worried about, particularly for breakfast.</p>
                        <p>I realised my mistake when I got chocolate cereal and a pain au chocolat on morning 1 - I can't eat chocolate. Lesson learned!</p>
                        <p>I had a leftover doughnut, so I had that instead - I mean, there's literally no one policing this!!</p>
                        <br/>
                        <div className="adventureGallery">
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureTwentyThree} alt="" /></div>
                                <div className="adventureGalleryItemDesc">Breakfast was left by the fairies for the morning</div>
                            </div>
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureTwentyFour} alt="" /></div>
                                <div className="adventureGalleryItemDesc">A nice breakfast selection</div>
                            </div>
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureTwentyFive} alt="" /></div>
                                <div className="adventureGalleryItemDesc">Doughnut and coffee for breakfast</div>
                            </div>
                        </div>
                        <br/>
                        <hr />
                        <p><h3 className="bronze">Christmas Shopping</h3></p>
                        <p>It has been a long, long time since I've been out shopping in real shops, particularly anywhere large like Southampton! We had a fab time taking our time browsing around, taking advantage of all the geeky, gifty, gadgety places we could find! We bought some gifts (mostly for ourselves) and some bits and pieces for our big night in! </p>
                        <br/>
                        <p>Primark was a step too far, I had to bail out of there, too many people; Just as well, I don't need any more clothes! After a while it started getting much busier, so it was time for a change. I didn't want to sit indoors so we looked around at the outdoor bars, but they were all quite busy.</p>
                        <br/>
                        <p>On the way in we had spotted the ice rink and that had a bar so we headed away from the centre back to there and it was a lot quieter!</p>
                        <br/>
                        <p>I don't have photos of the market, but we found a quiet corner in the bar at the skating rink to sit and have mulled wine and chips.</p>
                        <br/>
                        <div className="adventureGallery">
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureTwentySix} alt="" /></div>
                                <div className="adventureGalleryItemDesc">Arriving in Southampton</div>
                            </div>
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureTwentySeven} alt="" /></div>
                                <div className="adventureGalleryItemDesc">A quiet corner in the bar at the skating rink</div>
                            </div>
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureTwentyEight} alt="" /></div>
                                <div className="adventureGalleryItemDesc">Mulled wine, mulled cider and chips!</div>
                            </div>
                        </div>
                        <br/>
                        <hr />
                        <p><h3 className="bronze">Fun and Games</h3></p>
                        <p>When we were all shopped out we went back to our home from home, where we ate, drank, crafted and played games. It has been such a long time since we've had this much time to ourselves and very many years since I last played games.</p>
                        <br/>
                        <p>Being the classy girls that we are, we set up our moveable table with rum, crisps and dips and set about winning at KeyWe, which was ridiculous, and hilarious!</p>
                        <br/>
                        <div className="adventureGallery">
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureTwentyNine} alt="" /></div>
                                <div className="adventureGalleryItemDesc">Re-familiarising with a controller</div>
                            </div>
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureThirty} alt="" /></div>
                                <div className="adventureGalleryItemDesc">KeyWe</div>
                            </div>
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureThirtyOne} alt="" /></div>
                                <div className="adventureGalleryItemDesc">Making cardboard stars</div>
                            </div>
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureThirtyTwo} alt="" /></div>
                                <div className="adventureGalleryItemDesc">Using paperclips from the "Bits and Bobs" box in the room to hold things together while they dried</div>
                            </div>
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureThirtyThree} alt="" /></div>
                                <div className="adventureGalleryItemDesc">Attaching hanging ribbon</div>
                            </div>
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureThirtyFour} alt="" /></div>
                                <div className="adventureGalleryItemDesc">Putting the stars together</div>
                            </div>
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureThirtyFive} alt="" /></div>
                                <div className="adventureGalleryItemDesc">Completed stars</div>
                            </div>
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureThirtySix} alt="" /></div>
                                <div className="adventureGalleryItemDesc">One finished star</div>
                            </div>
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureThirtySeven} alt="" /></div>
                                <div className="adventureGalleryItemDesc">Decorating our room with our stars (using the paperclips as hooks!)</div>
                            </div>
                        </div>
                        <br/>
                        <hr />
                        <p><h3 className="bronze">Summing Up</h3></p>
                        <br/>
                        <p>We had a very lazy, slow start to Sunday morning, making the most of our 2pm checkout! Even if I did have to eventually wake the girl up by shouting "Wake up, Mumma needs coffee" from the safety of my loft bed!</p>
                        <br/>
                        <p>Honestly, this has been an amazing couple of days and will hopefully remind us that we need to get away together occasionally.</p>
                        <br/><br/>
                        <p>We have both faced extreme challenges - shared and individual - and we are working together now to ensure we support each other. There is safety and peace in knowing that no matter how weird you get there's always one person who will understand, will do anything to help (and will only laugh at you once they're sure you're in a stronger place!)</p>
                        <br/>
                        <p>I was worried about this one, I've seen so many Rebels doing amazing things and pushing themselves, but that's just a step beyond me at the moment.</p>
                        <br/>
                        <p>Two nights away in a nearby hotel may not sound like much of an adventure or very exciting, but we beg to differ! We've not had so much fun in a long time and the Hometel was perfect!</p>
                        <br/>
                        <hr />
                        <p><h3 className="bronze">Two happy girls</h3></p>
                        <br/>
                        <div className="adventureGallery">
                            <div className="adventureGalleryItem">
                                <div className="adventureGalleryItemImage"><img src={AdventureThirtyEight} alt="" /></div>
                                <div className="adventureGalleryItemDesc"></div>
                            </div>
                        </div>
                        <p>That's me with my rainbow hair and my beautiful curly girlie.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyAdventure