import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const RebelBuddy = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"Rebel Buddy"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Work on a badge with someone else</div>
                    <div className="badgePageItemInfo purple-light hidden">Over 3 months, work on a badge with a rebel in a different area.</div>
                    <div className="badgePageItemEvidence">
                       
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default RebelBuddy