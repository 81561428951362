import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Diploma from '../images/photographer1.png';
import Course from '../images/photographer2.png';
import Book from '../images/photographer3.jpg';
import Types1 from '../images/photographer-types1.jpg';
import Types2 from '../images/photographer-types2.jpg';
import Types3 from '../images/photographer-types3.jpg';
import Types4 from '../images/photographer-types4.jpg';
import Types5 from '../images/photographer-types5.jpg';
import Types6 from '../images/photographer-types6.jpg';
import Types7 from '../images/photographer-types7.jpg';
import Types8 from '../images/photographer-types8.jpg';
import Types9 from '../images/photographer-types9.jpg';
import Types10 from '../images/photographer-types10.jpg';
import Types11 from '../images/photographer-types11.jpg';
import Types12 from '../images/photographer-types12.jpg';
import Types13 from '../images/photographer-types13.jpg';
import Types14 from '../images/photographer-types14.jpg';
import Types15 from '../images/photographer-types15.jpg';
import Types16 from '../images/photographer-types16.jpg';
import Types17 from '../images/photographer-types17.jpg';
import Types18 from '../images/photographer-types18.jpg';
import Types19 from '../images/photographer-types19.jpg';
import Types20 from '../images/photographer-types20.jpg';
import Types21 from '../images/photographer-types21.jpg';
import Types22 from '../images/photographer-types22.jpg';
import Types23 from '../images/photographer-types23.jpg';
import Types24 from '../images/photographer-types24.jpg';
import Types25 from '../images/photographer-types25.jpg';
import Types26 from '../images/photographer-types26.jpg';
import Types27 from '../images/photographer-types27.jpg';
import Types28 from '../images/photographer-types28.jpg';
import Types29 from '../images/photographer-types29.jpg';
import Types30 from '../images/photographer-types30.jpg';
import Types31 from '../images/photographer-types31.jpg';
import Types32 from '../images/photographer-types32.jpg';
import Types33 from '../images/photographer-types33.jpg';
import Types34 from '../images/photographer-types34.jpg';
import Types35 from '../images/photographer-types35.jpg';
import Types36 from '../images/photographer-types36.jpg';
import Types37 from '../images/photographer-types37.jpg';
import Types38 from '../images/photographer-types38.jpg';
import Types39 from '../images/photographer-types39.jpg';
import Types40 from '../images/photographer-types40.jpg';
import Types41 from '../images/photographer-types41.jpg';
import Types42 from '../images/photographer-types42.jpg';
import Types43 from '../images/photographer-types43.jpg';
import Types44 from '../images/photographer-types44.jpg';
import Types45 from '../images/photographer-types45.jpg';
import Types46 from '../images/photographer-types46.jpg';
import Types47 from '../images/photographer-types47.jpg';
import Types48 from '../images/photographer-types48.jpg';



const Photographer = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Photographer"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">01</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Training</div>
                    <div className="badgePageItemInfo reverse hidden">Take a Photography Course</div>
                    <div className="badgePageItemEvidence">
                        <p>For this clause, I completed a Diploma in Digital Photography with Centre of Excellence.</p>
                        <br/><br/>
                        <p>It was a very interesting course and I have been using my new knowledge when taking photos, it has already helped with some of my rebel photos!</p>
                        <br/><br/>
                        <img className='imgThird' src={Diploma} alt="Diploma certificate for Digital Photography course from Centre of Excellence"/>
                        <br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">03</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Experiment</div>
                    <div className="badgePageItemInfo reverse hidden">Experiment with different types of photography - portrait, landscape, action, time lapse etc.</div>
                    <div className="badgePageItemEvidence">
                        <p>Over the yeaars I have taken many photos. Some of these are back dated and the quality isn't great because older phone cameras weren't so good! I have included them because they likelihood of me having the opportunity to capture these types of events again is very low.</p>
                        <br/>
                        <p>Here's a small selection of some different types, from landscape, cityscape, seascape to action shots, black and white and self-portraiture, nature shots, staged montage, night shots, light painting, beach cleaning composites and product photography.</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={ Types1 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types2 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types3 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types4 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types5 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types6 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types7 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types8 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types9 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types10 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types11 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types12 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types13 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types14 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types15 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types16 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types17 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types18 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types19 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types20 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types21 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types22 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types23 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types24 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types25 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types26 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types27 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types28 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types29 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types30 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types31 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types32 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types33 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types34 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types35 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types36 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types37 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types38 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types39 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types40 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types41 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types42 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types43 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types44 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types45 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types46 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types47 } alt="" /></div>
                            <div className="galleryItem"><img src={ Types48 } alt="" /></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">06</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Digital cameras</div>
                    <div className="badgePageItemInfo reverse hidden">Learn about the main functions of a digital camera.</div>
                    <div className="badgePageItemEvidence">
                        <p>This was covered in the Diploma course that I studied.</p>
                        <br/>
                        <img className="imgThird" src={Course} alt="Digital Photography course syllabus" />
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">08</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Create a book</div>
                    <div className="badgePageItemInfo reverse hidden">Create a book or exhibition of at least 20 prints.</div>
                    <div className="badgePageItemEvidence">
                        <p>My photobook has arrived! It's nice to see my favourite photos in full colour, glossy print.</p>
                        <br/>
                        <img className="imgHalf" src={Book} alt="Front cover of a photography book showing a view through a port hole with the caption Capturing Moment" />
                        <br/><br/>
                        <p>I don't have an exhibition as such, but I do now have a store on Picfair where my photos are exhibited and available for purchase. You can view then here: <a className="linkUnderline" href="https://momentsandmoxie.picfair.com/" target="_blank" rel="noreferrer noopener">momentsandmoxie.picfair.com</a>.</p>
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default Photographer