import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';


const CupNov23 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Rebel Cup Bonus Points"} />
            <div className="badgePageItem">Rebel Cup challenges are only posted in the facebook group for confirmed members. You will have to join the club to find out why I am doing these things!</div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">National Absurdity Day</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light"></div>
                    <div className="badgePageItemEvidence"> 
                      <p>I have decided not to share my entry here, but suffice to say that I became a panda for a day!</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Random Acts of Kindness</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>For this clause I took three pay it forward actions. Whilst I couldn't make the purchases anonymously, I do not know who the items will go to and the recipient will not know who donated, so it does satisfy the anonymous requirement of the clause.</p>
                        <br/><p>1 x Ticket Mate donation to The Design Museum - allows entry to an exhibition</p>
                        <br/><p>1 x Pay It Forward Ticket to see a show with Rubber Chicken Theatre</p>
                        <br/><p>2 x Pay it Forward Meals with a Play Cafe</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CupNov23