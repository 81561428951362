import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import FamilyTree from '../images/FamilyTree.png';     

const MyRoots = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"My Roots"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Explore your family tree</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light">Research</div>
                    <div className="badgePageItemInfo reverse hidden">Spend at least six months researching your family tree. Source images, record interviews, present findings in a way that is accessible to other family members.</div>
                    <div className="badgePageItemEvidence">
                        <p>I am very fortunate that a couple of family members have already done some research so I have some starting points for both sides of the family.</p>
                        <br/><p>I am collating all the work that has been done offline and putting everything into Ancestry.com to pull it all together in one tree.</p>
                        <br/><p>This means that I can send invites to other family members so everyone can have a look. I know my parents are already intrigued!</p>
                        <br/><p>I have also started a Google Map to map all the locations associated with the family so I can see how widely we spread!</p>
                        <br/><p>I've quite enjoyed this so far, I have found it very interesting. I'm looking forward to updating the next batch.</p>
                        <br/><br/>
                        <p><strong>February Update</strong></p>
                        <p>I have now entered all the information that I had for my Mum's side of the family into Ancestry.</p>
                        <p>I have also now received the extensive research that my Dad did for his side of the family so will be adding that soon!</p>
                        <br/><br/>
                        <p><strong>June update</strong></p>
                        <p>I have now added all my Dad's information into the tree and hooked into a couple of related trees!</p>
                        <p>I have sent a link to the family so they can view / contribute.</p>
                        <p>I make sure I take notes any time we talk about the family, I get snippets out of the parents occasionally to add to my collection. We have recently travelled to some areas where parts of the family have existed in the past so I have taken down some information and tried to ask questions!  I don't have any grandparents living now, so I can't interview them for more information. Dad collected information from his Mum which has been included.</p>
                        <br/><br/>
                        <p>I don't want to display the whole chart publicly, but here's a zoomed out screenshot so you can see the levels I've traversed so far.</p>
                        <br/>
                        <img src={FamilyTree} alt="Zoomed out screenshot of a family tree in vertical mode on the Ancestry website" />
                        <br/><br/>
                        <p>I do have photographs, copies of birth and death certs and other information, but I do not want to share them online.</p>
                        <br/><br/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyRoots