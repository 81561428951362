import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Mug1 from '../images/CupFeb24-Mug1.jpg';
import Mug2 from '../images/CupFeb24-Mug2.jpg';
import Mug3 from '../images/CupFeb24-Mug3.jpg';
import Mug4 from '../images/CupFeb24-Mug4.jpg';
import Mug5 from '../images/CupFeb24-Mug5.jpg';
import Mug6 from '../images/CupFeb24-Mug6.jpg';
import Mug7 from '../images/CupFeb24-Mug7.jpg';
import Mug8 from '../images/CupFeb24-Mug8.jpg';
import Mug9 from '../images/CupFeb24-Mug9.jpg';
import Mug10 from '../images/CupFeb24-Mug10.jpg';
import Mug11 from '../images/CupFeb24-Mug11.jpg';
import Mug12 from '../images/CupFeb24-Mug12.jpg';
import Mug13 from '../images/CupFeb24-Mug13.jpg';
import Mug14 from '../images/CupFeb24-Mug14.jpg';
import Mug15 from '../images/CupFeb24-Mug15.jpg';
import Mug16 from '../images/CupFeb24-Mug16.jpg';
import Mug17 from '../images/CupFeb24-Mug17.jpg';


const CupFeb24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Rebel Cup February 2024 Bonus Points"} />
            <div className="badgePageItem">Rebel Cup challenges are only posted in the facebook group for confirmed members. You will have to join the club to find out why I am doing these things!</div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Bonus 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light">Cup</div>
                    <div className="badgePageItemEvidence"> 
                        <p>Here's my WIP and final product. This earned me one bonus point!</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Mug1} alt="A packet of air dry clay, a bottle of waterproofing solution and a rebel badge on a table" /></div>
                            <div className="galleryItem"><img src={Mug2} alt="Process photograph of making a mug with air dry clay" /></div>
                            <div className="galleryItem"><img src={Mug3} alt="Process photograph of making a mug with air dry clay" /></div>
                            <div className="galleryItem"><img src={Mug4} alt="Process photograph of making a mug with air dry clay" /></div>
                            <div className="galleryItem"><img src={Mug5} alt="Process photograph of making a mug with air dry clay" /></div>
                            <div className="galleryItem"><img src={Mug6} alt="Process photograph of making a mug with air dry clay" /></div>
                            <div className="galleryItem"><img src={Mug7} alt="Process photograph of making a mug with air dry clay" /></div>
                            <div className="galleryItem"><img src={Mug8} alt="Process photograph of making a mug with air dry clay" /></div>
                            <div className="galleryItem"><img src={Mug9} alt="Process photograph of making a mug with air dry clay" /></div>
                            <div className="galleryItem"><img src={Mug10} alt="A sheet of tracing paper on a craft board" /></div>
                            <div className="galleryItem"><img src={Mug11} alt="A mug with tracing paper stuck to it for transferring text" /></div>
                            <div className="galleryItem"><img src={Mug12} alt="Text traced onto a mug" /></div>
                            <div className="galleryItem"><img src={Mug13} alt="Finished mug made from Air Dry Clay - left side view" /></div>
                            <div className="galleryItem"><img src={Mug14} alt="Finished mug made from Air Dry Clay - front view" /></div>
                            <div className="galleryItem"><img src={Mug15} alt="Finished mug made from Air Dry Clay - right side view" /></div>
                            <div className="galleryItem"><img src={Mug16} alt="Finished mug made from Air Dry Clay - pictured from above showing it holding water" /></div>
                            <div className="galleryItem"><img src={Mug17} alt="Finished mug made from Air Dry Clay - being held to show that the R shaped handle works!" /></div>
                        </div>                       
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CupFeb24