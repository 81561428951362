import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Crab1 from '../images/cupjuly24-crab1.jpg';
import Crab2 from '../images/cupjuly24-crab2.jpg';
import Crab3 from '../images/cupjuly24-crab3.jpg';
import Crab4 from '../images/cupjuly24-crab4.jpg';
import Crab5 from '../images/cupjuly24-crab5.jpg';
import Crab6 from '../images/cupjuly24-crab6.jpg';
import Crab7 from '../images/cupjuly24-crab7.jpg';
import Crab8 from '../images/cupjuly24-crab8.jpg';
import Ocean1 from '../images/cupjuly24-Ocean1.jpg';
import Ocean2 from '../images/cupjuly24-Ocean2.jpg';
import Ocean3 from '../images/cupjuly24-Ocean3.jpg';
import Ocean4 from '../images/cupjuly24-Ocean4.jpg';
import Ocean5 from '../images/cupjuly24-Ocean5.jpg';
import Ocean6 from '../images/cupjuly24-Ocean6.jpg';
import Ocean7 from '../images/cupjuly24-Ocean7.jpg';
import Ocean8 from '../images/cupjuly24-Ocean8.jpg';
import Ocean9 from '../images/cupjuly24-Ocean9.jpg';


const CupJul24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Rebel Cup Bonus Points"} />
            <div className="badgePageItem">Rebel Cup challenges are only posted in the facebook group for confirmed members. You will have to join the club to find out why I am doing these things!</div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Bonus 1 - Life's a Beach</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light"></div>
                    <div className="badgePageItemEvidence"> 
                       <p>We'll classify this one under "I tried"!! I'm sticking to pencils!</p>
                       <div className="gallery">
                        <div className="galleryItem"><img src={Ocean1} alt="Progress shot of an ocean scene using watercolours" /></div>
                        <div className="galleryItem"><img src={Ocean2} alt="Progress shot of an ocean scene using watercolours" /></div>
                        <div className="galleryItem"><img src={Ocean3} alt="Progress shot of an ocean scene using watercolours" /></div>
                        <div className="galleryItem"><img src={Ocean4} alt="Progress shot of an ocean scene using watercolours" /></div>
                        <div className="galleryItem"><img src={Ocean5} alt="Progress shot of an ocean scene using watercolours" /></div>
                        <div className="galleryItem"><img src={Ocean6} alt="Progress shot of an ocean scene using watercolours" /></div>
                        <div className="galleryItem"><img src={Ocean7} alt="Progress shot of an ocean scene using watercolours" /></div>
                        <div className="galleryItem"><img src={Ocean8} alt="Progress shot of an ocean scene using watercolours" /></div>
                        <div className="galleryItem"><img src={Ocean9} alt="Progress shot of an ocean scene using watercolours" /></div>
                       </div>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Bonus 2 - Junkyard Crab</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light"></div>
                    <div className="badgePageItemEvidence"> 
                       <p>I made my crab from a takeaway cup, nurdles, takeaway drink stirrers and a takeaway drink lid.</p>
                       <div className="gallery">
                        <div className="galleryItem"><img src={Crab1} alt="Materials laid out on a table ready for crafting"/></div>
                        <div className="galleryItem"><img src={Crab2} alt="Materials laid out on a table ready for crafting"/></div>
                        <div className="galleryItem"><img src={Crab3} alt="Materials laid out on a table ready for crafting"/></div>
                        <div className="galleryItem"><img src={Crab4} alt="Materials laid out on a table ready for crafting"/></div>
                        <div className="galleryItem"><img src={Crab5} alt="A crab being made out of junk items"/></div>
                        <div className="galleryItem"><img src={Crab6} alt="A crab being made out of junk items"/></div>
                        <div className="galleryItem"><img src={Crab7} alt="A crab being made out of junk items"/></div>
                        <div className="galleryItem"><img src={Crab8} alt="A crab made out of junk items"/></div>
                       </div>
                    </div>
                </div>
            </div>
           
        </div>
    )
}

export default CupJul24