import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const MyGoals = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"My Goals"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Health</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence">
                        <p><strong>1. Fitness</strong></p>
                        <ul className="list">
                            <li>500 mile walk - 12 months</li>
                            <li>Earth Runs 1% Club - 12 months</li>
                            <li>Step up to an extra challenge each quarter (at least)</li>
                        </ul>
                        <br/>
                        <p><strong>2. Diet</strong></p>
                        <ul className="list">
                            <li>Maintain 16-8 Fast (at least 12 hour fast) at least 5 days a week</li>
                            <li>Maintain Low Carb &lt;30g at least 5 days a week</li>
                            <li>Cook from scratch at least 5 days a week</li>
                        </ul>
                        <br/>
                        <p><strong>January Update:</strong></p>
                        <p>Walked 45.45 miles, climbed 252 floors towards challenges</p>
                        <p>Planted 31 trees for being active 31 days of the month</p>
                        <p>Maintained at least 12 hour fast for 5 days a week</p>
                        <p>Maintained low carb diet at least 5 days a week</p>
                        <p>Meal planned and cooked from scratch at least 5 days a week</p>
                        <br/>
                        <br/>
                        <p><strong>February Update:</strong></p>
                        <p>Walked 35.46 miles, climbed 176 floors towards challenges</p>
                        <p>Planted 20 trees for being active 20 days of the month</p>
                        <p>Maintained at least 12 hour fast for 5 days a week when well. I wasn't able to do this for around two weeks due to illness.</p>
                        <p>Maintained low carb diet at least 5 days a week when well. I wasn't able to do this for around two weeks due to illness.</p>
                        <p>Meal planned and cooked from scratch at least 5 days a week when well. I wasn't able to do this for around two weeks due to illness.</p>
                        <br/>
                        <p><strong>March Update:</strong></p>
                        <p>Walked 55.05 miles, climbed 337 floors towards challenges</p>
                        <p>Planted 25 trees for being active 20 days of the month</p>
                        <p>Maintained at least 12 hour fast for 5 days a week.</p>
                        <p>Maintained low carb diet at least 5 days a week.</p>
                        <p>Meal planned and cooked from scratch at least 5 days a week.</p>
                        <br/>
                        <p><strong>April Update:</strong></p>
                        <p>Walked 49.35 miles, climbed 251 floors towards challenges</p>
                        <p>Planted 30 trees for being active 30 days of the month</p>
                        <p>Maintained at least 12 hour fast for 5 days a week.</p>
                        <p>Maintained low carb diet at least 5 days a week.</p>
                        <p>Meal planned and cooked from scratch at least 5 days a week.</p>
                        <br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Career</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence">
                        <p><strong>1. Continuous Learning</strong></p>
                        <ul className="list">
                            <li>Always use monthly learning day</li>
                            <li>Keep up with news and trends</li>
                            <li>Work on modernisation program, improving skills in new technologies</li>
                        </ul>
                        <br/>
                        <p><strong>January Update:</strong></p>
                        <p>Learning day taken as scheduled</p>
                        <p>Using Daily.Dev daily and TLDR newsletter at least weekly to keep up with trends</p>
                        <p>Modernisation program on hold, but new tech being used for new projects</p>
                        <br/>
                        <p><strong>February Update:</strong></p>
                        <p>Learning day taken as scheduled</p>
                        <p>Using Daily.Dev daily and TLDR newsletter at least weekly to keep up with trends</p>
                        <p>Modernisation program on hold, but new tech used on at least 5 new projects this month</p>
                        <br/>
                        <p><strong>March Update:</strong></p>
                        <p>Learning day taken as scheduled</p>
                        <p>Using Daily.Dev daily and TLDR newsletter at least weekly to keep up with trends</p>
                        <p>Implementing new tech and standards in new projects</p>
                        <br/>
                        <p><strong>April Update:</strong></p>
                        <p>Learning day taken as scheduled</p>
                        <p>Using Daily.Dev daily and TLDR newsletter at least weekly to keep up with trends</p>
                        <p>Learned how to implement canvas in new projects.</p>
                        <br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Wealth</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence">
                        <p><strong>1. Savings</strong></p>
                        <ul className="list">
                            <li>Put dedicated money into savings each month</li>
                        </ul>
                        <br/>
                        <p><strong>2. Side Hustles</strong></p>
                        <ul className="list">
                            <li>Work on small businesses to turn profit</li>
                            <li>Rebrand Gifted Cards, write strategy</li>
                            <li>Moments and Moxie, write strategy</li>
                            <li>investigate other options</li>
                        </ul>
                        <br/>
                        <p><strong>January Update:</strong></p>
                        <p>Savings budgeted and set aside</p>
                        <p>Gifted cards rebrand underway, starting with deactivating current store so that old stock can be cleared out</p>
                        <p>Moments and Moxie strategy started with new posts sent out. Hashtag research done.</p>
                        <br/>
                        <p><strong>February Update:</strong></p>
                        <p>Side hustles haven't progressed this month due to illness. Schedule got messed up. Back on track for March.</p>
                        <br/>
                        <p><strong>March Update:</strong></p>
                        <p>GC and MM strategies in progress.</p>
                        <br/>
                        <p><strong>April Update:</strong></p>
                        <p>Photos taken for MM, awaiting edit for upload.</p>
                        <br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Travel</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence">
                        <p><strong>1. Travel</strong></p>
                        <ul className="list">
                            <li>Continue with family holidays</li>
                            <li>3 nights away for Silver Maverick</li>
                        </ul>
                        <br/>
                        <p><strong>January - December Update:</strong></p>
                        <p>Booked trip for Silver Maverick</p>
                        <p>Other than the family annual holiday, I have no travel goals, I don't travel and have no desire to.</p>
                        <br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Family</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence">
                        <p><strong>1. Family</strong></p>
                        <ul className="list">
                            <li>Continue to see family as much as possible</li>
                        </ul>
                        <br/>
                        <p><strong>January - December Update:</strong></p>
                        <p>No goal required, already maximised.</p>
                        <br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Lifestyle</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence">
                        <p><strong>1. RBC</strong></p>
                        <ul className="list">
                            <li>Be more interactive in RBC online</li>
                            <li>Complete all planned badges</li>
                        </ul>
                        <br/>
                        <p><strong>2. Environment</strong></p>
                        <ul className="list">
                            <li>Set up CMARC events</li>
                            <li>Set up PFH Activities</li>
                        </ul>
                        <br/>
                        <p><strong>January Update:</strong></p>
                        <p>Trying hard to be a more active rebel and I'm on schedule with my planned badges!</p>
                        <p>CMARC events awaiting council approval, to be set up asap</p>
                        <p>PFH Activities not yet agreed</p>
                        <br/>
                        <p><strong>February Update:</strong></p>
                        <p>Trying hard to be a more active rebel and I'm on schedule with my planned badges!</p>
                        <p>CMARC events approved, awaiting setup</p>
                        <p>PFH Activities not yet decided</p>
                        <br/>
                        <p><strong>March Update:</strong></p>
                        <p>Still trying hard to be a more active rebel and I'm on schedule with my planned badges! Organised a Big Rebel Spring Clean but no one turned up. No takers for online meetup.</p>
                        <p>CMARC events setup in eventbrite.</p>
                        <p>PFH Activities not yet decided</p>
                        <br/>
                        <p><strong>April Update:</strong></p>
                        <p>Still trying hard to be a more active rebel and I'm on schedule with my planned badges! No takers for online meetup.</p>
                        <p>CMARC events setup on Facebook.</p>
                        <p>PFH Activities not yet decided</p>
                        <br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Creativity</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence">
                        <p><strong>1. Online</strong></p>
                        <ul className="list">
                            <li>Continue 365 days of Creativity course</li>
                            <li>Keep up to date with digital design</li>
                        </ul>
                        <br/>
                        <p><strong>2. Offline</strong></p>
                        <ul className="list">
                            <li>Do more drawing</li>
                            <li>Develop products for GC</li>
                        </ul>
                        <br/>
                        <p><strong>January Update:</strong></p>
                        <p>Up to date with 365 days course - one module per day as required</p>
                        <p>Continued drawing efforts with January Cup's Cornucopia</p>
                        <br/>
                        <p><strong>February Update:</strong></p>
                        <p>Up to date with 365 days course - I have had to play catch up at some times due to illness.</p>
                        <p>Continued drawing efforts with some Hazbin Hotel artwork, now in progress.</p>
                        <br/>
                        <p><strong>March Update:</strong></p>
                        <p>Up to date with 365 days course.</p>
                        <p>Finished Hazbin Hotel artwork. Found previous prototype for GC to work on and purchased some materials.</p>
                        <br/>
                        <p><strong>April Update:</strong></p>
                        <p>Up to date with 365 days course.</p>
                        <p>Completed first Young Royals portrait artwork.</p>
                        <br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Knowledge</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence">
                        <p><strong>1. Training</strong></p>
                        <ul className="list">
                            <li>Do at least one training course per month</li>
                        </ul>
                        <br/>
                        <p><strong>2. Learning</strong></p>
                        <ul className="list">
                            <li>Read more non-fiction</li>
                            <li>Listen to more podcasts</li>
                            <li>Watch more documentaries</li>
                        </ul>
                        <br/>
                        <p><strong>January Update:</strong></p>
                        <p>Completed OpenAI Bootcamp course</p>
                        <p>One non-fiction book completed</p>
                        <br/>
                        <p><strong>February Update:</strong></p>
                        <p>Completed Introduction to Branding Diploma course</p>
                        <p>One non-fiction book completed for Historian badge.</p>
                        <br/>
                        <p><strong>March Update:</strong></p>
                        <p>Continued with 365 Days of Creativity course. Started another course.</p>
                        <p>non-fiction books identified for Historian and Global Citizen badges.</p>
                        <br/>
                        <p><strong>April Update:</strong></p>
                        <p>Continued with 365 Days of Creativity course. Followed "5 Modern CSS Features You Should Know In 2024".</p>
                        <p>Started Duolingo with French, Spanish and Swedish.</p>
                        <p>Read "Black Box Thinking" by Matthew Syed.</p>
                        <br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Relationships</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence">
                        <p><strong>1. Relationships</strong></p>
                        <ul className="list">
                            <li>Make sure to check in on the one remaining friend</li>
                        </ul>
                        <br/>
                        <p><strong>January - December Update:</strong></p>
                        <p>I don't have relationships beyond my immediate family.</p>
                        <p>I have one friend who I have intermittent contact with, I have been in touch with them this month.</p>
                        <p>Arguably I have work relationships, these are all good to the best of my knowledge. I work hard to keep a good reputation amongst colleagues.</p>
                        <br/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyGoals