import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Clutter1 from '../images/monthly-jan24-1.jpg';
import Clutter2 from '../images/monthly-jan24-2.jpg';
import Clutter3 from '../images/monthly-jan24-3.jpg';

const  January24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Living My Best Life"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Take some time to reflect on the following areas of your life.Where could you make positive changes this year? Diet / Fitness / Sleep / Work / Free Time / Home / Family / Friends / Learning. Plan some changes to your year based n the results.</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo yellow-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <ul className="list">
                            <li>Diet - I have been low carb for many years. The last two years I'e experimented with Veggie and Vegan diets, but my body just doesn't like it. This year, I have a whole new low carb plan and will be cooking from scratch at least 5 days a week. One month down! I am also fasting, 16-8, at least 5 days a week.</li>
                            <li>Fitness - already in hand as part of Silver Maverick. I am walking 500 miles and climbing mountains (well, stairs!!)</li>
                            <li>Sleep - This one is already sorted. I have a strict 7 hour an night rule and 99% of the time I achieve it!</li>
                            <li>Work - I'm happy at work and my hours are already set up for perfect work/life balance. I work a compressed week, so I do full time hours but over 4 days instead of 3. I work Mon-Thurs, 6.30am to 4.05pm (9.25 hours), I then get a three day weekend. So my evenings and weekends are long, plenty of time for rebelling ;)</li>
                            <li>Free Time - see work! I have plenty of free time. Although to be honest not much of it is very free, because I'm always up to some kind of mischief!! I get a fine combination of downtime and non-work activity time.</li>
                            <li>Home - Not much to reflect on here. I'm making an effort to do housework more regularly / consistently, I just have so many better things to do!</li>
                            <li>Family - My family are number one priority. Over everything. I will always opt to spend my time with them rather than anything else whenever possible.</li>
                            <li>Friends - I have none, so this is easy. Due to toxic people, I blew up my entire social group and walked away. Life has been a lot more positive since!</li>
                            <li>Learning - I am a lifelong learner. I am always studying something. At the moment, it's Herbalism, AI Bootcamp and 365 Days of Creativity (Creative Cloud). I will never stop! I ahve a natural curiosity for a lot of things and my work also requires me to be on top of the latest developments, so I always have my nose in at least one course.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Set yourself a challenge you've always wanted to achieve. Spend the month taking necessary steps to completing it this year.</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo yellow-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>For many years I have had a small business to sell craft items. However, I can't say I've been very invested!</p>
                        <p>The original intention was for something quite different than it has become, so I am starting again, taking it back to its roots. I'm hoping that this might inspire me to make more of it.</p>
                        <p>I don't want a high-earning business, but I'd like it to turn a profit and become the channel through which I can start doing things I love again.</p>
                        <p>I love making things, but I don't do much making these days because there's no point unless you have something to do with that thing you made! If I can get the formula right, hopefully I can make to order some new and exciting products.</p>
                        <br/>
                        <p>I have closed down my current store in preparation and so I can get rid of old stock. I have also ordered some tools that I need for creating products and done some research for product design.</p>
                        <br/>
                        <p>Plans are well underway to get this up and running once and for all.</p>
                        <br/>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Choose at least one of the Rebel Annual Badges to work on this year, start working on it.</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo yellow-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>I am actually working on all four Annual Badges! Here's what I'm up to:</p><br/>
                        <ul className="list">
                            <li>The Big Rebel Adventure - I am doing this virtually and am doing both the distance and elevation. I have already finished the distance, earning 3 medals towards it. I am using floor climbs at home for the elevation - I have to do 6 floors a day to achieve the correct distance. See the next clause for the distances I have already covered.</li>
                            <li>52 Books in a Year - I am using my Advanced Reader badge and topping up with an additional 7 books before the end of Feb to earn this. At the end of January, I have 3 books left to read!</li>
                            <li>More Time Outdoors - I haven't set a target for this, more a general intention. Target setting will be easier in Spring / Summer. I have already spent more time outdoors sorting out my shed area!</li>
                            <li>Rebel with a cause 2024 - I'm always on a cause! I'll update with what I'm working on as the year progresses.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Make a positive step towards something you'd like to change in your life - e.g. updating your cv, joining a gym</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo yellow-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>The biggest things I need to change in my life are to exercise more and use my time better. I am addressing these as part of my Silver Maverick award so I am already working on this and have just completed my first month with the changes in place!</p>
                        <p>I am using ToDoist and TrevorAI to plan my time properly and to make sure I'm on top of everything I need to be doing..</p>
                        <p>I am walking at least mile every day - this is for a 500 mile challenge. I walk a mile indoors on the days when I can't get out and about.</p>
                        <p>I am climbing floors as part of the Rebel Adventure so I do at least 6 floors a day!</p>
                        <br/>
                        <p>My job is very sedentary, so it's vital that I do something each day, even if it is just twenty minutes of walking around the front room!</p>
                        <br/>
                        <p>As at the end of January I have completed the following towards my targets:</p>
                        <ul className="list">
                            <li>Walked 45.45 miles</li>
                            <li>Climbed 252 floors</li>
                        </ul>
                        <br/>
                        <p>I have been active every day so since the end of December I have planted 47 trees!!</p>
                        <br/>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Get rid of any items in your wardrobe which don't make you feel good.</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo yellow-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>After a huge sort out, I have donated 6 bags to charity this month - 4 of these were from my wardrobe. The collection goes towards Ecologi.</p>
                        <img src={Clutter1} alt="A pile of clothes on a bedroom floor" className="imgThird" />
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Declutter at least one room and donate any useful items to charity.</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo yellow-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>I have sorted out my loft. I have removed a huge amount of clutter and either donated or disposed of items as required.</p>
                        <p>I have also decluttered my spare room of craft items - they were going to be sold but I decided it was time to let them go and start fresh.</p>
                        <p>I have also decluttered my bedroom and reduced the amount of storage required!</p>
                        <br/>
                        <p>I just need to check all my electronic devices and donate them - I found a site called Shutter Hub to send cameras to, I need someone for mobile phones but a lot of charities take them. I also have laptops to check and donate, I believe Currys take these.</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Clutter2} alt="Charity bags ready for donation" /></div>
                            <div className="galleryItem"><img src={Clutter3} alt="Electrical items ready for recycling" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default January24