import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Pudding from '../images/pudding.jpg';
import PuddingCollage from '../images/pudding-collage.png';
import Biscuits1 from '../images/baker-biscuits1.jpg';
import Biscuits2 from '../images/baker-biscuits2.jpg';
import Biscuits3 from '../images/baker-biscuits3.jpg';
import Cake1 from '../images/baker-cake1.jpg';
import Cake2d from '../images/baker-cake2d.jpg';
import Cake3b from '../images/baker-cake3b.jpg';
import Bread1 from '../images/baker-bread1.jpg';
import Bread2 from '../images/baker-bread2.jpg';
import Bread3 from '../images/baker-bread3.jpg';
import BatchBake from '../images/baker-batchbake1.jpg';
import SavouryTart1 from '../images/baker-savourytart1.jpg';
import SavouryTart2 from '../images/baker-savourytart2.jpg';
import SweetTart from '../images/baker-sweettart1.jpg';
import Eclairs from '../images/baker-eclairs.jpg';
import MilleFeuille from '../images/baker-millefeuille.jpg';

const Baker = () => {
    return (
        <div id="bakerPage">
            <ActionBar />
            <EvidenceHeader title={"Baker"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">01</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Bread</div>
                    <div className="badgePageItemEvidence">
                        <div className="evRow">
                            <div className="evDate">18/10/2022</div>
                            <div className="evImage"><img src={Bread1} alt="Rustic oat and treacle soda bread" width="380px" height="380px" /></div>
                            <div className="evContent">
                                <strong>Rustic Oat and Treacle Soda Bread</strong>
                                <br/>
                                <a href="https://www.bbcgoodfood.com/recipes/rustic-oat-treacle-soda-bread" target="_blank" rel="noopener noreferrer">Click for the recipe from BBC Good Food (opens in new tab)</a>
                            </div>
                        </div>
                        <div className="evRow">
                            <div className="evDate">23/12/2022</div>
                            <div className="evImage"><img src={Bread2} alt="Twisted Spiced Bread with tahini and honey butter displayed on a plate" width="380px" height="380px" /></div>
                            <div className="evContent">
                                <strong>Twisted Spiced Bread with Tahini and Honey Butter</strong>
                                <br/>
                                <a href="https://www.bbcgoodfood.com/recipes/twisted-spiced-bread-honey-tahini-butter" target="_blank" rel="noopener noreferrer">Click for the recipe from BBC Good Food (opens in new tab)</a>
                            </div>
                        </div>
                         <div className="evRow">
                            <div className="evDate">12/01/2023</div>
                            <div className="evImage"><img src={Bread3} alt="No-knead bread displayed on a plate" width="380px" height="380px" /></div>
                            <div className="evContent">
                                <strong>No-knead Bread</strong>
                                <br/>
                                <p>No need to knead! I'm up for anything that means I don't have to knead dough for 10mins!</p>
                                <br/>
                                <a href="https://www.bbcgoodfood.com/recipes/no-knead-beginners-loaf" target="_blank" rel="noopener noreferrer">Click for the recipe from BBC Good Food (opens in new tab)</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">02</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Cake</div>
                    <div className="badgePageItemEvidence">
                        <div className="evRow">
                            <div className="evDate">18/10/2022</div>
                            <div className="evImage"><img src={Cake1} alt="Elderflower crunch cake on a plate" width="380px" height="380px" /></div>
                            <div className="evContent">
                                <strong>Elderflower Crunch Cake</strong>
                                <br/>
                                Baked this to take away on holiday with the parents, it was very much enjoyed with a cuppa in the afternoons!
                                <br/>
                                <a href="https://www.bbcgoodfood.com/recipes/elderflower-crunch-cake" target="_blank" rel="noopener noreferrer">Click for the recipe from BBC Good Food (opens in new tab)</a>
                            </div>
                        </div>
                        <div className="evRow">
                            <div className="evDate">02/11/2022</div>
                            <div className="evImage"><img src={Cake2d} alt="Halloween Pumpkin Cake" width="380px" height="380px" /></div>
                            <div className="evContent">
                                <strong>Halloween Pumpkin Cake</strong>
                                <br/>
                                I used some of the flesh from my halloween pumpkin to make this cake, it was very tasty (despite me using dark instead of light sugar!).
                                <br/>
                                <a href="https://www.bbcgoodfood.com/recipes/halloween-pumpkin-cake" target="_blank" rel="noopener noreferrer">Click for the recipe from BBC Good Food (opens in new tab)</a>
                            </div>
                        </div>
                        <div className="evRow">
                            <div className="evDate">26/11/2022</div>
                            <div className="evImage"><img src={Cake3b} alt="Courgette and Orange Cake" width="380px" height="380px" /></div>
                            <div className="evContent">
                               <strong>Courgette and Orange Cake</strong>
                                <br/>
                                I enjoyed this one a lot, grating courgette was a weird experience but the cake tasty lovely!
                                <br/>
                                <a href="https://www.bbcgoodfood.com/recipes/courgette-orange-cake-cream-cheese-frosting" target="_blank" rel="noopener noreferrer">Click for the recipe from BBC Good Food (opens in new tab)</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">03</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Biscuits</div>
                    <div className="badgePageItemEvidence">
                    <div className="evRow">
                            <div className="evDate">03/09/2022</div>
                            <div className="evImage"><img src={Biscuits1} alt="Rosemary shortbread with lemon drizzle shown on a plate" width="380px" height="380px" /></div>
                            <div className="evContent">
                               <strong>Rosemary shortbread with lemon drizzle </strong>
                                <br/>
                                I would never have thought of using rosemary in a biscuit, but these are definitely very good!
                                <br/>
                                <a href="https://realfood.tesco.com/recipes/rosemary-shortbreads-with-lemon-icing.html" target="_blank" rel="noopener noreferrer">Click for the recipe from Tesco (opens in new tab)</a>
                            </div>
                        </div>
                        <div className="evRow">
                            <div className="evDate">23/09/2022</div>
                            <div className="evImage"><img src={Biscuits2} alt="Pistachio and cranberry cookies on a plate" width="380px" height="380px" /></div>
                            <div className="evContent">
                               <strong>Pistachio and Cranberry Cookies</strong>
                                <br/>
                                Another new favourite! These are very tasty, the pistachio and cranberry work really well together.
                                <br/>
                                <a href="https://www.bbcgoodfood.com/recipes/pistachio-cranberry-cookies" target="_blank" rel="noopener noreferrer">Click for the recipe from BBC Good Food (opens in new tab)</a>
                            </div>
                        </div>
                        <div className="evRow">
                            <div className="evDate">15/10/2022</div>
                            <div className="evImage"><img src={Biscuits3} alt="Custard and white chocolate biscuits on a plate" width="380px" height="380px" /></div>
                            <div className="evContent">
                               <strong>Custard and White Chocolate Biscuits</strong>
                                <br/>
                                These ones possibly went down better than the others! A third new favourite for the recipe collection.
                                <br/>
                                <a href="https://www.bbcgoodfood.com/recipes/custard-white-chocolate-biscuits" target="_blank" rel="noopener noreferrer">Click for the recipe from BBC Good Food (opens in new tab)</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">04</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Pudding</div>
                    <div className="badgePageItemEvidence">
                        Now this one I am going to backdate slightly, because my Mum demanded that we bake the winning pudding in the Great British Bake Off Jubilee Pudding competition!
                        <br/><br/>
                        One week ago as I write, the Queen sadly passed away, which makes this choice even more poignant and it seems appropriate to include it here so it's given a memory.
                        <br/><br/>
                        It was actually great fun (and I even managed to not drink all the amaretto). Mum was all for taking shortcuts but I told her that as she had demanded we bake, we were baking everything from scratch!
                        <br/><br/>
                        Here, in all its glory and with far too many ingredients, is our Jubilee Pudding.
                        <br/><br/>
                        <img src={PuddingCollage} alt="A collage of pictures of each stage of the baking process" />
                        <br/><br/>
                        <img src={Pudding} alt="A finished Jubliee Pudding in a glass pudding bowl" />
                        <br/><br/>
                        Rest in Peace, your Majesty.
                        <br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">05</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Savoury pie</div>
                    <div className="badgePageItemEvidence">
                        <div className="evRow">
                            <div className="evDate">10/10/2022</div>
                            <div className="evImage">
                                <img src={SavouryTart1} alt="Homity Pie in a flan dish" width="380px" height="380px" />
                                <img src={SavouryTart2} alt="A slice of Homity Pie on a plate" width="380px" height="380px" />
                            </div>
                            <div className="evContent">
                                <strong>Homity Pie</strong>
                                <br/>
                                I chose Homity pie for my savoury tart. it is always warming and filling.
                                <br/>
                                <a href="https://www.bbcgoodfood.com/recipes/homity-pie" target="_blank" rel="noopener noreferrer">Click for the recipe from BBC Good Food (opens in new tab)</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">06</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Eclairs</div>
                    <div className="badgePageItemEvidence">
                        <div className="evRow">
                            <div className="evDate">23/12/2022</div>
                            <div className="evImage">
                                <img src={Eclairs} alt="Eclairs on a plate" width="380px" height="380px" />
                            </div>
                            <div className="evContent">
                               <strong>Salted Caramel and Popcorn Eclairs</strong>
                                <br/>
                                These are absolutely wicked!! The first time I have made choux pastry, wasn't perfect but wasn't awful and the salted caramel is fab! These went down very well with the family for christmas.
                                <br/>
                                <a href="https://www.bbcgoodfood.com/recipes/salted-caramel-popcorn-eclairs" target="_blank" rel="noopener noreferrer">Click for the recipe from BBC Good Food (opens in new tab)</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">07</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Sweet tart</div>
                    <div className="badgePageItemEvidence">
                        <div className="evRow">
                            <div className="evDate">13/10/2022</div>
                            <div className="evImage">
                                <img src={SweetTart} alt="Custard Tarts on a plate" width="380px" height="380px" />
                            </div>
                            <div className="evContent">
                               <strong>Egg Custard Tarts</strong>
                                <br/>
                                I chose custard tarts for my sweet tart option because they are my Mum's favourite treat! She often brings them when she comes over so I thought I'd try my own.
                                <br/><br/>
                                I can't pretend they were a resounding success visually - I didn't have a bun tin so had to improvise with a muffin tin. I substituted the cream with an Elmlea Plant Based cream which thankfully worked well. However, I got very favourable feedback from the family, so I'll try them again sometime!
                                <br/>
                                <a href="https://www.bbcgoodfood.com/recipes/custard-tarts" target="_blank" rel="noopener noreferrer">Click for the recipe from BBC Good Food (opens in new tab)</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">08e</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">A batch bak</div>
                    <div className="badgePageItemEvidence">
                        <div className="evRow">
                            <div className="evDate">30/12/2022</div>
                            <div className="evImage">
                                <img src={BatchBake} alt="Meringues piled on a plate" width="380px" height="380px" />
                            </div>
                            <div className="evContent">
                               <strong>Meringues</strong>
                                <br/>
                                I was using my custard tarts as a batch bake as there were 12 of them, but as I did some extra baking I thought I'd add these as a bonus.
                                <br/><br/>
                                As a result of the eclairs and mille-feuille, I had a lot of egg whites unused! The obvious thing to make is meringues.
                                <br/><br/>
                                I had a disaster when I added the sugar much too quickly and no amount of whipping was making the mixture suitable. It did thicken, just not enough to hold a shape on the baking tray. I tried adding cornflour and salt but that didn't work. So, to try and rescue them and prevent waste of the ingredients, I spooned the mixture into cupcake cases.
                                <br/><br/>
                                Turns out that was a resounding success (and Mum wants more!)
                                <br/><br/>
                                <a href="https://www.deliciousmagazine.co.uk/recipes/basic-meringue/" target="_blank" rel="noopener noreferrer">Click for the recipe from Delicious Magazine (opens in new tab)</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">09</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Mille-Feuille</div>
                    <div className="badgePageItemEvidence">
                        <div className="evRow">
                            <div className="evDate">23/12/2022</div>
                            <div className="evImage">
                                <img src={MilleFeuille} alt="Mille Feuille pastries displayed on a plate" width="380px" height="380px" />
                            </div>
                            <div className="evContent">
                               <strong>Mille Feuille</strong>
                                <br/>
                                Well this was an interesting one. These went really well and looked perfect ... until I tried to cut them!
                                <br/><br/>
                                There must be a secret that I need to learn to do that cleanly. It also highlighted that I had messed up the marbling pattern on the top and put the lines in the wrong place. None of that affected that taste though and they were very much enjoyed by the family!
                                <br/><br/>
                                <a href="https://realfood.tesco.com/step-by-step/how-to-make-mille-feuille.html" target="_blank" rel="noopener noreferrer">Click for the recipe from Tesco (opens in new tab)</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Baker