import React from 'react'
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Sculpture1 from '../images/artist-sculpture1.jpg';
import Sculpture2 from '../images/artist-sculpture2.jpg';
import Portrait from '../images/artist-portrait.jpg';
import Portrait2a from '../images/artist-portrait2a.jpg';
import Portrait2b from '../images/artist-portrait2b.jpg';
import Portrait2c from '../images/artist-portrait2b.jpg';
import Portrait2d from '../images/artist-portrait2d.jpg';
import Portrait2e from '../images/artist-portrait2e.jpg';
import Portrait2f from '../images/artist-portrait2f.jpg';
import Still1 from '../images/artist-stilllife1.jpg';
import Still2 from '../images/artist-stilllife2.jpg';
import Still3 from '../images/artist-stilllife3.jpg';
import Still4 from '../images/artist-stilllife4.jpg';
import Mosaic1 from '../images/artist-mosaic2.jpg';
import Mosaic2 from '../images/artist-mosaic3.jpg';
import Mosaic3 from '../images/artist-mosaic4.jpg';
import Mosaic4 from '../images/artist-mosaic5.jpg';
import Mosaic5 from '../images/artist-mosaic1.jpg';

const Artist = () => {

    return (
        <div>
            <ActionBar area="bookone"/>
            <EvidenceHeader title={"Artist"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">03</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo">Sculpture</div>
                    <div className="badgePageItemEvidence">
                        I'm not going to win any prizes here, but I am using the solar system (plus extras) that I made out of air-drying clay for my Stargazing badge for this clause. Enjoy!!
                        <br/><br/>
                        <div className="evRowGap">
                            <img src={Sculpture1} alt="Solar system made from air-dried clay displayed on a plastic, rotating stand" width="400px" height="400px" className="imgQuarter" />
                            <img src={Sculpture2} alt="Space characters made from air-dried clay" width="400px" height="400px" className="imgQuarter" />
                        </div>
                    </div> 
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">06</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo">Portrait</div>
                    <div className="badgePageItemEvidence">
                            <div>I have done a few of these in the last year or two. These can be seen on the <a href="https://ameliaraine.me.uk/art">Art</a> page on my main website.</div>
                            <div>In the spirit of badge-work and to avoid back-dating, I added a new one to my portfolio</div>     
                            <br /><br />
                            Following his momentous court case, here is Johnny Depp, captured in a moment which I believe is from a Dior photo shoot.
                            <br/><br/>
                            <div className="evImage">
                                <img src={Portrait} alt="Portrait of Johnny Depp shown next to the original and with the pencils used." width="400px" height="400px" />
                            </div>
                            <br/><br/>
                            This is a bonus picture, I had already completed the badge, but then I joined Nelson partrol and did a portrait of Nelson Mandela for cup points! 
                            <br/>
                             <div className="evRow">
                                <img src={Portrait2a} alt="Portrait progress." width="400px" height="400px" className="imgThird" />
                                <img src={Portrait2b} alt="Portrait progress." width="400px" height="400px" className="imgThird" />
                                <img src={Portrait2c} alt="Portrait progress." width="400px" height="400px" className="imgThird" />
                                <img src={Portrait2d} alt="Portrait progress." width="400px" height="400px" className="imgThird" />
                                <img src={Portrait2e} alt="Portrait progress." width="400px" height="400px" className="imgThird" />
                                <img src={Portrait2f} alt="Portrait progress." width="400px" height="400px" className="imgThird" />
                            </div>
                            <br/><br/>

                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">07</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo">Still life</div>
                    <div className="badgePageItemEvidence">
                        This was a relatively quick one, done in a new mini sketch book that I received for christmas!
                        <br/><br/>
                        <div className="evRow">
                            <img src={Still1} alt="Drawing of a shell shown on a page of a small sketchbook" className="imgThird" width="400px" height="400px" />
                            <img src={Still2} alt="Drawing of a shell shown on a page of a small sketchbook" className="imgThird" width="400px" height="400px" />
                            <img src={Still3} alt="Drawing of a shell shown on a page of a small sketchbook" className="imgThird" width="400px" height="400px" />
                        </div>
                        <br/>
                         <img src={Still4} alt="Drawing of a shell shown on a page of a small sketchbook" className="imgThird" width="400px" height="400px" />
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">08</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo">Mosaic</div>
                    <div className="badgePageItemEvidence">
                        This mosaic is created using plastic bottle tops. Many were retrieved from beach cleans and others collected from family.
                        <br/><br/>
                        In the centre is a magnifying lense that was also found on a beach clean!
                        <br/><br/>
                        <div class="evRow">
                            <img src={Mosaic1} alt="Bottle tops forming the start of a mosaic design" className="imgQuarter" width="400px" height="400px" />
                            <img src={Mosaic2} alt="Bottle tops forming the start of a mosaic design" className="imgQuarter" width="400px" height="400px" />
                            <img src={Mosaic3} alt="Bottle tops forming the start of a mosaic design" className="imgQuarter" width="400px" height="400px" />
                            <img src={Mosaic4} alt="Bottle tops forming the start of a mosaic design" className="imgQuarter" width="400px" height="400px" />
                        </div>
                      <br/><br/>
                       <img src={Mosaic5} alt="Bottle tops forming the a mosaic design" className="imgThird" width="400px" height="400px" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Artist