import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Candles1 from '../images/craft-candles1.jpg';
import Candles2 from '../images/craft-candles2.jpg';
import Candles3 from '../images/craft-candles3.jpg';
import Candles4 from '../images/craft-candles4.jpg';
import Candles5 from '../images/craft-candles5.jpg';
import Candles6 from '../images/craft-candles6.jpg';
import Candles7 from '../images/craft-candles7.jpg';
import Candles8 from '../images/craft-candles8.jpg';
import Soap from '../images/craft-soap.jpg';
import Soap1 from '../images/craft-soap1.jpg';
import Soap2 from '../images/craft-soap2.jpg';
import CrochetGloves from '../images/craft-crochetgloves.jpg';
import CrochetGiraffe from '../images/craft-crochetgiraffe.jpg';
import Crochet1 from '../images/craft-crochet1.jpg';
import Crochet2 from '../images/craft-crochet2.jpg';
import Crochet3 from '../images/craft-crochet3.jpg';
import Crochet4 from '../images/craft-crochet4.jpg';
import Crochet5 from '../images/craft-crochet5.jpg';
import Crochet6 from '../images/craft-crochet6.jpg';
import Origami1 from '../images/craft-origami1.jpg';
import Origami2 from '../images/craft-origami2.jpg';
import Origami3 from '../images/craft-origami3.jpg';
import Origami4 from '../images/craft-origami4.jpg';
import Origami5 from '../images/craft-origami5.jpg';
import Origami6 from '../images/craft-origami6.jpg';
import Origami7 from '../images/craft-origami7.jpg';
import Origami8 from '../images/craft-origami8.jpg';
import Origami9 from '../images/craft-origami9.jpg';
import Origami10 from '../images/craft-origami10.jpg';
import Origami11 from '../images/feb23love-rose.jpg';
import Jewellery1 from '../images/craft-jewellery1.jpg';
import Jewellery2 from '../images/craft-jewellery2.jpg';
import Jewellery3 from '../images/craft-jewellery3.jpg';
import Jewellery4 from '../images/craft-jewellery4.jpg';
import Jewellery5 from '../images/craft-jewellery5.jpg';
import Jewellery6 from '../images/craft-jewellery6.jpg';
import Jewellery7 from '../images/craft-jewellery7.jpg';
import Jewellery8 from '../images/craft-jewellery8.jpg';
import Jewellery9 from '../images/craft-jewellery9.jpg';
import Jewellery10 from '../images/craft-jewellery10.jpg';
import Jewellery11 from '../images/craft-jewellery11.jpg';
import Jewellery12 from '../images/craft-jewellery12.jpg';
import Jewellery13 from '../images/craft-jewellery13.jpg';
import Jewellery14 from '../images/craft-jewellery14.jpg';
import Jewellery15 from '../images/craft-jewellery15.jpg';
import Jewellery16 from '../images/craft-jewellery16.jpg';
import Jewellery17 from '../images/craft-jewellery17.jpg';
import Jewellery18 from '../images/craft-jewellery18.jpg';
import Jewellery19 from '../images/craft-jewellery19.jpg';
import Jewellery20 from '../images/craft-jewellery20.jpg';
import Jewellery21 from '../images/craft-jewellery21.jpg';
import Jewellery22 from '../images/craft-jewellery22.jpg';
import Jewellery23 from '../images/craft-jewellery23.jpg';
import Jewellery24 from '../images/craft-jewellery24.jpg';
import Jewellery25 from '../images/craft-jewellery25.jpg';
import Jewellery26 from '../images/craft-jewellery26.jpg';


const Craft = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Craft"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Candle Making</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>This clause is, in part, back dated as I have made candles before but am not planning to make any more.</p>
                        <br/><br/>
                        <p>I had a lot of bits of wax left from burning candles and tea lights, I kept them to re-use. I put the remnants in a bowl and melted them down. </p>
                        <br/>
                        <p>I had bought some hemp wicks to use and kept the empty tea light holders from ones I had already burned. I poured the melted wax into the holders and held the wicks in place with pegs!</p>
                        <br/>
                        <p>With the combinations of all the bits of wax, I ended up with pretty pale pink tealights!</p>
                        <br/>
                        <img className="imgHalf" src={Candles1} alt="The process of making tea lights" />
                        <br/><br/><br/>
                        <p>In the spirit of the badges and so that this isn't entirely backdated, I made up some beeswax candles from a kit that I purchased from a bee farm in Cornwall. I rolled three different sizes in two different shapes. They fit perfectly in these cute holders that I got for christmas!</p>
                        <br/>
                        <div className="gallery">
                            <img className="imgQuarter" src={Candles2} alt="Beeswax sheets and candle instructions" />
                            <img className="imgQuarter" src={Candles3} alt="Rolling beeswax candles" />
                            <img className="imgQuarter" src={Candles4} alt="Rolling beeswax candles" />
                            <img className="imgQuarter" src={Candles5} alt="Rolling beeswax candles" />
                            <img className="imgQuarter" src={Candles6} alt="Rolling beeswax candles" />
                            <img className="imgQuarter" src={Candles7} alt="Beeswax candles" />
                            <img className="imgQuarter" src={Candles8} alt="Beeswax candles in moon holders" />
                        </div>


                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Soap Making</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        I love soap making. I've been making my own for a while as I stopped buying shower gels and soaps in plastic bottles.
                        <br/><br/>
                        <p>Here's some very purple soap I made in a new round mold. It is peppermint scented!</p>
                        <br/>
                        <img className="imgHalf" src={Soap} alt="Home made melt and pour soap" />
                        <br/><br/><br/>
                        <p>Here are two batches I've made previously! The blue soap is cherry scented and the brown contains coffee grounds for exfoliation and has a coffee scent.</p>
                        <br/>
                        <img className="imgThird" src={Soap1} alt="Bright blue home made melt and pour soap" />&nbsp;
                        <img className="imgThird" src={Soap2} alt="Brown coloured home made melt and pour soap" />
                        <br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Origami</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>Inspired by the rose that I made for the February 2023 Love badge, I decided to have a look at other origami patterns.</p>
                        <br/>
                        <img className="imgQuarter" src={Origami11} alt="Paper rose" />
                        <br/><br/>
                        <p>I love stars so when I came across a 16-pointed star folding pattern, I decided to give that a go.</p>
                        <br/>
                        <img className="imgQuarter" src={Origami1} alt="Paper squares ready for origami folding" />
                        <img className="imgQuarter" src={Origami2} alt="Paper squares ready for origami folding" />
                        <img className="imgQuarter" src={Origami3} alt="Paper squares ready for origami folding" />
                        <img className="imgQuarter" src={Origami4} alt="Paper squares ready for origami folding" />
                        <img className="imgQuarter" src={Origami5} alt="Paper squares ready for origami folding" />
                        <img className="imgQuarter" src={Origami6} alt="Paper squares ready for origami folding" />
                        <img className="imgQuarter" src={Origami7} alt="Paper squares ready for origami folding" />
                        <img className="imgQuarter" src={Origami8} alt="Completed 16 pointed origami square" />
                        <br/><br/>
                        <p>Well, it's a 16 pointed star! The pattern didn't give a size for the squares and I made them too large for what I like. So, rather than waste this, I cut smaller squares and layered the smaller star on top of the larger one to give it more substance!</p>
                        <br/><br/>
                        <img className="imgQuarter" src={Origami9} alt="Completed 16 pointed origami square" />
                        <img className="imgQuarter" src={Origami10} alt="Completed 16 pointed origami square" />
                        <br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Jewellery Making</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>This clause is back-dated. I could have picked another craft, but I don't want to buy any more materials and it's nice to be able to use things I have already done towards my badges.</p>
                        <br/><br/>
                        <p>I have done a lot of jewellery making in the past and I had decided not to do any more, in fact I gave away most of my supplies so someone else could benefit from it!</p>
                        <br/><br/>
                        <p>Here's a selection of jewellery - I have mostly used either plastic or glass beads, with some wire work. Some of these are from patterns, others are my own design.</p>
                        <br/>
                        <p>From Patterns:</p>
                        <div className="gallery">
                            <img className="imgQuarter" src={Jewellery6} alt="Beaded rings" />
                            <img className="imgQuarter" src={Jewellery13} alt="Beaded bracelet" />
                            <img className="imgQuarter" src={Jewellery23} alt="Beaded bracelet" />
                            <img className="imgQuarter" src={Jewellery24} alt="Beaded bracelet" />
                        </div>
                        <br/><br/>
                        <p>My Designs:</p>
                        <div className="gallery">
                            <img className="imgQuarter" src={Jewellery1} alt="Beaded bracelet" />  
                            <img className="imgQuarter" src={Jewellery2} alt="Beaded bracelet" />
                            <img className="imgQuarter" src={Jewellery3} alt="Beaded bracelet" />
                            <img className="imgQuarter" src={Jewellery4} alt="Beaded ring" />
                            <img className="imgQuarter" src={Jewellery5} alt="Beaded bracelet and silver earrings" />
                            <img className="imgQuarter" src={Jewellery7} alt="Beaded anklets" />
                            <img className="imgQuarter" src={Jewellery8} alt="Beaded bracelet" />
                            <img className="imgQuarter" src={Jewellery9} alt="Beaded anklet" />
                            <img className="imgQuarter" src={Jewellery10} alt="Beaded Bracelet" />
                            <img className="imgQuarter" src={Jewellery11} alt="Beaded Bracelet" />
                            <img className="imgQuarter" src={Jewellery12} alt="Beaded earrings" />
                            <img className="imgQuarter" src={Jewellery14} alt="Beaded bracelet with metal bells" />
                            <img className="imgQuarter" src={Jewellery15} alt="Beaded anklet" />
                            <img className="imgQuarter" src={Jewellery16} alt="Beaded necklace" />
                            <img className="imgQuarter" src={Jewellery17} alt="Beaded bracelet" />
                            <img className="imgQuarter" src={Jewellery18} alt="Beaded jewellery set" />
                            <img className="imgQuarter" src={Jewellery19} alt="Beaded jewellery set in handmade box" />
                            <img className="imgQuarter" src={Jewellery20} alt="Crystal and glass bead bracelet in a gift box" />
                            <img className="imgQuarter" src={Jewellery21} alt="Beaded earrings" />
                            <img className="imgQuarter" src={Jewellery22} alt="Wire earrings with tiny beads and sequins" />
                            <img className="imgQuarter" src={Jewellery25} alt="Beaded hand jewellery" />
                            <img className="imgQuarter" src={Jewellery26} alt="Beaded hand jewellery" />
                        </div>

                        
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Crochet</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>I love crochet! I was taught when I was little by my Mum. I spent a lot of time ill and in bed during my childhood and she taught me crochet to try and fill the time where I was confined to a duvet.</p>
                        <br/><br/>
                        <p>For this clause, I decided to crochet something useful - a washcloth set. I came across this when I was searching for plastic free alternatives and it's perfect! I acquired the things I needed from Hobbycraft and set about making each item.</p>
                        <img className="imgQuarter" src={Crochet1} alt="Crochet yarn and a crochet hook" />
                        <img className="imgQuarter" src={Crochet2} alt="Crochet in progress" />
                        <img className="imgQuarter" src={Crochet3} alt="Crocheted face cloth" />
                        <img className="imgQuarter" src={Crochet4} alt="Crocheted makeup remover pad" />
                        <img className="imgQuarter" src={Crochet5} alt="Crocheted soap saver bag" />
                        <img className="imgQuarter" src={Crochet6} alt="Crocheted wash cloth, makeup remover pad and soap saver bag" />
                        <br/><br/>
                        <p>Link to the pattern: <a className="linkUnderline" href="https://www.hobbycraft.co.uk/ideas/how-to-crochet-a-reusable-washcloth-set.html" target="_blank" rel="noopener noreferrer">https://www.hobbycraft.co.uk/ideas/how-to-crochet-a-reusable-washcloth-set.html</a></p>
                        <br/><br/><br/>
                        <p>Here are a couple of things I have crocheted in the past that I am particularly proud of. I love these gloves. So much! I still wear them when it gets chilly and they are so easy to make.</p>
                        <br/>
                        <p>The litte giraffe was from a kit, I had never done anything more than squares so this was a challenge. I spotted the kits when I went on a day out with the family. Little did I know that Mum waited until I had moved on then picked one up for a gift (sneaky!). It now sits on the bed in my spare room.</p>
                        <br/><br/>
                        <img className="imgThird" src={CrochetGloves} alt="Crocheted gloves in a dragon scale style using multicoloured wool" />
                        <img className="imgThird" src={CrochetGiraffe} alt="Crocheted giraffe in a light green wool" />
                        <br/><br/>
                    </div>
                </div>
            </div>           
        </div>
    )
}

export default Craft