import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import Rebel from "../images/rebel-logo.png";

const Book = () => {

useEffect(() => {
        const elements = document.querySelectorAll(".badgeItem");
        var currDate = new Date();

        elements.forEach((el) => {
            var oVal = 0;
            var oProg;
            var mProg;
            var count =  el.querySelectorAll(".badgeClauseStatus").length;
            var maxval = 100 / count;

            el.querySelectorAll(".badgeClauseStatus").forEach((elS) => {
                var cID = elS.id;
                var cStatus = elS.textContent;
                var cStart = el.querySelector("#" + cID.replace("Status","Start")).textContent;
                var cEnd = el.querySelector("#" + cID.replace("Status","End")).textContent;
                var cProg = el.querySelector("#" + cID.replace("Status","Progress"));
                oProg = el.querySelector("#" + cID.substring(0, cID.length-7).replace("1","") + "Progress");
                mProg = el.querySelector("#" + cID.substring(0, cID.length-7).replace("1","") + "ProgressMini");

                var duration = cProg.getAttribute("data-duration");

                if(duration != null)
                {
                    duration = parseInt(duration) * 31;
                }

                if (cStatus === "In progress")
                {
                    if (cEnd === "" && cStart !== "")
                    {
                        var startString = cStart.split("/");
                        var startDate = new Date(startString[2],startString[1]-1,startString[0]);
                        var diff = new Date(startDate - currDate);
                        var days =  Math.ceil(diff/1000/60/60/24);
                        var maxdays = -90;

                        if(duration != null) 
                        {
                            maxdays = -duration;
                        }

                        cProg.value = (Math.ceil(days/maxdays*100));

                        var setP = days * maxdays / 100;

                        if(setP > maxval)
                        {
                            setP = maxval / 2;
                        }
                        oVal = oVal + setP;

                        cProg.classList.add("orange");
                        cProg.classList.remove("green");
                        cProg.classList.remove("red");
                    }
                    else if (cEnd !== "" && cStart !== "")
                    {
                        cProg.value = 100;
                        cProg.classList.add("green");
                        cProg.classList.remove("orange");
                        cProg.classList.remove("red");
                        oVal = oVal + maxval;
                    }
                }
                else if (cStatus === "Complete")
                {
                    cProg.value = 100;
                    cProg.classList.add("green");
                    cProg.classList.remove("orange");
                    cProg.classList.remove("red");
                    oVal = oVal + maxval;
                }
                else if (cStatus === "Not started")
                {
                    cProg.value = 1;
                    cProg.classList.remove("green");
                    cProg.classList.remove("orange");
                    cProg.classList.add("red");
                    oVal = oVal + 0;
                }
            })

            var oCalc = Math.ceil(oVal);
            oProg.value = oCalc;
            mProg.value = oCalc;

            
            if(oCalc <= 1)
            {
                oProg.classList.add("red");
                oProg.classList.remove("orange");
                oProg.classList.remove("green");
                mProg.classList.add("red");
                mProg.classList.remove("orange");
                mProg.classList.remove("green");
            }
            else if (oCalc < 100)
            {
                oProg.classList.remove("red");
                oProg.classList.add("orange");
                oProg.classList.remove("green");
                mProg.classList.remove("red");
                mProg.classList.add("orange");
                mProg.classList.remove("green");
            }
            else
            {
                oProg.classList.remove("red");
                oProg.classList.remove("orange");
                oProg.classList.add("green");
                mProg.classList.remove("red");
                mProg.classList.remove("orange");
                mProg.classList.add("green");
            }    
        })


        document.querySelectorAll(".badgeItemIntro").forEach((elI) => {elI.classList.add("hidden")})
        document.querySelectorAll(".badgeClause").forEach((elC) => {elC.classList.add("hidden")})
        document.querySelectorAll(".badgeProgress").forEach((elP) => {elP.classList.add("hidden")})
        document.querySelectorAll(".icon-down-open").forEach((elID) => {elID.classList.add("shown")})
        document.querySelectorAll(".icon-up-open").forEach((elIO) => {elIO.classList.add("hidden")})

        document.querySelectorAll(".badgeItemTitle").forEach((mit) => {addListener(mit);})

    }, [])

    return (
        <div>
        <div className="actionBar">
            <div className="actionBarItem"><Link to="/">Back to Dashboard</Link></div>
        </div>
            <div className="row bookPageTop">
                <div className="rebelLeft"><img src={Rebel} alt="Sticker of the word rebel" className="rebelLogoBookLeft" /></div>
                <div className="rebelMid">
                    <h2>Rebel Badge Book One</h2>
                </div>
                <div className="rebelRight"><img src={Rebel} alt="Sticker of the word rebel" className="rebelLogoBookRight" /></div>
            </div>
            <div className="row pageIntroduction">
                <p><br/>This page follows my progress in completing the badges in the first Rebel Badge Book. It doesn't detail the entire contents of the book, just the badges and options that I have chosen from those offered.</p>
                <p><br/>I have intentionally abbreviated or removed the clauses to protect the content of the book - if you want to see the full details, go and buy the <a className="linkUnderline"  href="https://www.rebelbadgestore.com/" target="_blank" rel="noopener noreferrer">Rebel Badge Book</a> &#x1F609;</p>
                <p><br/>I am still in the process of updating the evidence pages so you'll have to come back to see how I have achieved each badge!</p>
            </div>
            <div className="bookSectionTitle bstpurple">Creative Rebel</div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle purple" tabIndex={0} role="button" aria-expanded="false">
                        <div>Artist</div>
                        <div>
                            <progress id="artistProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro purple-light">Complete four out of eight possible tasks <Link to="/artist">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 1 - Sculpture</div>
                        <div className="badgeClauseStatus" id="artist1Status">Complete</div>
                        <div className="badgeClauseStart" id="artist1Start">15/9/2022</div>
                        <div className="badgeClauseEnd" id="artist1End">16/9/2022</div>
                        <div className="badgeClauseProgress"><progress id="artist1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 2 - Portrait</div>
                        <div className="badgeClauseStatus" id="artist2Status">Complete</div>
                        <div className="badgeClauseStart" id="artist2Start">23/8/2022</div>
                        <div className="badgeClauseEnd" id="artist2End">2/9/2022</div>
                        <div className="badgeClauseProgress"><progress id="artist2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3 - Still Life</div>
                        <div className="badgeClauseStatus" id="artist3Status">Complete</div>
                        <div className="badgeClauseStart" id="artist3Start">30/12/2022</div>
                        <div className="badgeClauseEnd" id="artist3End">30/12/2022</div>
                        <div className="badgeClauseProgress"><progress id="artist3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 4 - Mosaic</div>
                        <div className="badgeClauseStatus" id="artist4Status">Complete</div>
                        <div className="badgeClauseStart" id="artist4Start">15/9/2022</div>
                        <div className="badgeClauseEnd" id="artist4End">12/01/2023</div>
                        <div className="badgeClauseProgress"><progress id="artist4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="artistProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle  purple" tabIndex={0} role="button" aria-expanded="false">
                        <div>Craft</div>
                        <div>
                            <progress id="craftProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro purple-light">Complete at least five out of 20 possible tasks <Link to="/craft">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 1 - Candles</div>
                        <div className="badgeClauseStatus" id="craft1Status">Complete</div>
                        <div className="badgeClauseStart" id="craft1Start">17/2/2023</div>
                        <div className="badgeClauseEnd" id="craft1End">17/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="craft1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 2 - Soap</div>
                        <div className="badgeClauseStatus" id="craft2Status">Complete</div>
                        <div className="badgeClauseStart" id="craft2Start">18/9/2022</div>
                        <div className="badgeClauseEnd" id="craft2End">18/9/2022</div>
                        <div className="badgeClauseProgress"><progress id="craft2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3 - Origami</div>
                        <div className="badgeClauseStatus" id="craft3Status">Complete</div>
                        <div className="badgeClauseStart" id="craft3Start">18/2/2023</div>
                        <div className="badgeClauseEnd" id="craft3End">18/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="craft3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 4 - Jewellery</div>
                        <div className="badgeClauseStatus" id="craft5Status">Complete</div>
                        <div className="badgeClauseStart" id="craft5Start">19/2/2023</div>
                        <div className="badgeClauseEnd" id="craft5End">19/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="craft5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 5 - Crochet</div>
                        <div className="badgeClauseStatus" id="craft6Status">Complete</div>
                        <div className="badgeClauseStart" id="craft6Start">17/2/2023</div>
                        <div className="badgeClauseEnd" id="craft6End">18/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="craft6Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="craftProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle  purple" tabIndex={0} role="button" aria-expanded="false">
                        <div>Designer</div>
                        <div>
                            <progress id="designerProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro purple-light">Complete all 7 clauses <Link to="/designer">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 1</div>
                        <div className="badgeClauseStatus" id="designer1Status">Complete</div>
                        <div className="badgeClauseStart" id="designer1Start">25/04/2023</div>
                        <div className="badgeClauseEnd" id="designer1End">26/04/2023</div>
                        <div className="badgeClauseProgress"><progress id="designer1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 2</div>
                        <div className="badgeClauseStatus" id="designer2Status">Complete</div>
                        <div className="badgeClauseStart" id="designer2Start">28/4/2023</div>
                        <div className="badgeClauseEnd" id="designer2End">04/05/2023</div>
                        <div className="badgeClauseProgress"><progress id="designer2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3</div>
                        <div className="badgeClauseStatus" id="designer3Status">Complete</div>
                        <div className="badgeClauseStart" id="designer3Start">14/4/2023</div>
                        <div className="badgeClauseEnd" id="designer3End">19/4/2023</div>
                        <div className="badgeClauseProgress"><progress id="designer3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 4</div>
                        <div className="badgeClauseStatus" id="designer4Status">Complete</div>
                        <div className="badgeClauseStart" id="designer4Start">1/6/2023</div>
                        <div className="badgeClauseEnd" id="designer4End">30/6/2023</div>
                        <div className="badgeClauseProgress"><progress id="designer4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 5</div>
                        <div className="badgeClauseStatus" id="designer5Status">Complete</div>
                        <div className="badgeClauseStart" id="designer5Start">1/6/2023</div>
                        <div className="badgeClauseEnd" id="designer5End">30/06/2023</div>
                        <div className="badgeClauseProgress"><progress id="designer5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 6</div>
                        <div className="badgeClauseStatus" id="designer6Status">Complete</div>
                        <div className="badgeClauseStart" id="designer6Start">1/6/2023</div>
                        <div className="badgeClauseEnd" id="designer6End">30/06/2023</div>
                        <div className="badgeClauseProgress"><progress id="designer6Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 7</div>
                        <div className="badgeClauseStatus" id="designer7Status">Complete</div>
                        <div className="badgeClauseStart" id="designer7Start">14/4/2023</div>
                        <div className="badgeClauseEnd" id="designer7End">15/4/2023</div>
                        <div className="badgeClauseProgress"><progress id="designer7Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="designerProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle  purple" tabIndex={0} role="button" aria-expanded="false">
                        <div>Florist</div>
                        <div>
                            <progress id="floristProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro purple-light">Complete all nine clauses <Link to="/florist">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 1 - Toolkit</div>
                        <div className="badgeClauseStatus" id="florist1Status">Complete</div>
                        <div className="badgeClauseStart" id="florist1Start">1/3/2023</div>
                        <div className="badgeClauseEnd" id="florist1End">21/12/2023</div>
                        <div className="badgeClauseProgress"><progress id="florist1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 2 - Principles</div>
                        <div className="badgeClauseStatus" id="florist2Status">Complete</div>
                        <div className="badgeClauseStart" id="florist2Start">1/3/2023</div>
                        <div className="badgeClauseEnd" id="florist2End">21/12/2023</div>
                        <div className="badgeClauseProgress"><progress id="florist2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3 - Foam and tape</div>
                        <div className="badgeClauseStatus" id="florist3Status">Complete</div>
                        <div className="badgeClauseStart" id="florist3Start">1/3/2023</div>
                        <div className="badgeClauseEnd" id="florist3End">21/12/2023</div>
                        <div className="badgeClauseProgress"><progress id="florist3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 4 - Preservation</div>
                        <div className="badgeClauseStatus" id="florist4Status">Complete</div>
                        <div className="badgeClauseStart" id="florist4Start">1/3/2023</div>
                        <div className="badgeClauseEnd" id="florist4End">21/12/2023</div>
                        <div className="badgeClauseProgress"><progress id="florist4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 5 - Vases</div>
                        <div className="badgeClauseStatus" id="florist5Status">Complete</div>
                        <div className="badgeClauseStart" id="florist5Start">1/3/2023</div>
                        <div className="badgeClauseEnd" id="florist5End">21/12/2023</div>
                        <div className="badgeClauseProgress"><progress id="florist5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 6 - Experiments</div>
                        <div className="badgeClauseStatus" id="florist6Status">Complete</div>
                        <div className="badgeClauseStart" id="florist6Start">03/03/2023</div>
                        <div className="badgeClauseEnd" id="florist6End">21/12/2023</div>
                        <div className="badgeClauseProgress"><progress id="florist6Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 7 - Arrangements</div>
                        <div className="badgeClauseStatus" id="florist7Status">Complete</div>
                        <div className="badgeClauseStart" id="florist7Start">03/03/2023</div>
                        <div className="badgeClauseEnd" id="florist7End">21/12/2023</div>
                        <div className="badgeClauseProgress"><progress id="florist7Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 8 - Blooms</div>
                        <div className="badgeClauseStatus" id="florist8Status">Complete</div>
                        <div className="badgeClauseStart" id="florist8Start">03/03/2023</div>
                        <div className="badgeClauseEnd" id="florist8End">21/12/2023</div>
                        <div className="badgeClauseProgress"><progress id="florist8Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 9 - Record</div>
                        <div className="badgeClauseStatus" id="florist9Status">Complete</div>
                        <div className="badgeClauseStart" id="florist9Start">03/03/2023</div>
                        <div className="badgeClauseEnd" id="florist9End">21/12/2023</div>
                        <div className="badgeClauseProgress"><progress id="florist9Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="floristProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle  purple" tabIndex={0} role="button" aria-expanded="false">
                        <div>Musician</div>
                        <div>
                            <progress id="musicianProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro purple-light">Complete all five clauses <Link to="/musician">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 1 - Practice</div>
                        <div className="badgeClauseStatus" id="musician1Status">In progress</div>
                        <div className="badgeClauseStart" id="musician1Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="musician1End"></div>
                        <div className="badgeClauseProgress"><progress id="musician1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 2 - Learn</div>
                        <div className="badgeClauseStatus" id="musician2Status">In progress</div>
                        <div className="badgeClauseStart" id="musician2Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="musician2End"></div>
                        <div className="badgeClauseProgress"><progress id="musician2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3 - Sight reading</div>
                        <div className="badgeClauseStatus" id="musician3Status">In progress</div>
                        <div className="badgeClauseStart" id="musician3Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="musician3End"></div>
                        <div className="badgeClauseProgress"><progress id="musician3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 4 - Performance</div>
                        <div className="badgeClauseStatus" id="musician4Status">In progress</div>
                        <div className="badgeClauseStart" id="musician4Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="musician4End"></div>
                        <div className="badgeClauseProgress"><progress id="musician4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 5 - Composition</div>
                        <div className="badgeClauseStatus" id="musician5Status">In progress</div>
                        <div className="badgeClauseStart" id="musician5Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="musician5End"></div>
                        <div className="badgeClauseProgress"><progress id="musician5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="musicianProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle  purple" tabIndex={0} role="button" aria-expanded="false">
                        <div>Photographer</div>
                        <div>
                            <progress id="photographerProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro purple-light">Complete at least four of a possible eight clauses <Link to="/photographer">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 1 - Course</div>
                        <div className="badgeClauseStatus" id="photographer1Status">Complete</div>
                        <div className="badgeClauseStart" id="photographer1Start">27/8/2022</div>
                        <div className="badgeClauseEnd" id="photographer1End">7/4/2023</div>
                        <div className="badgeClauseProgress"><progress id="photographer1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 2 - Types</div>
                        <div className="badgeClauseStatus" id="photographer2Status">Complete</div>
                        <div className="badgeClauseStart" id="photographer2Start">27/8/2022</div>
                        <div className="badgeClauseEnd" id="photographer2End">31/5/2023</div>
                        <div className="badgeClauseProgress"><progress id="photographer2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3 - Digital cameras</div>
                        <div className="badgeClauseStatus" id="photographer3Status">Complete</div>
                        <div className="badgeClauseStart" id="photographer3Start">27/8/2022</div>
                        <div className="badgeClauseEnd" id="photographer3End">7/4/2023</div>
                        <div className="badgeClauseProgress"><progress id="photographer3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 5 - Book</div>
                        <div className="badgeClauseStatus" id="photographer5Status">Complete</div>
                        <div className="badgeClauseStart" id="photographer5Start">1/5/2023</div>
                        <div className="badgeClauseEnd" id="photographer5End">5/5/2023</div>
                        <div className="badgeClauseProgress"><progress id="photographer5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="photographerProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle  purple" tabIndex={0} role="button" aria-expanded="false">
                        <div>Writer</div>
                        <div>
                            <progress id="writerProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro purple-light">Choose one of a possible eight projects and work on it over 6 months, finishing at least a first draft <Link to="/writer">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">A Poetry Anthology</div>
                        <div className="badgeClauseStatus" id="writer2Status">Complete</div>
                        <div className="badgeClauseStart" id="writer2Start">1/11/2022</div>
                        <div className="badgeClauseEnd" id="writer2End">7/4/2023</div>
                        <div className="badgeClauseProgress"><progress id="writer2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="writerProgress" value="25" max="100"></progress></div>
                </div>
            </div>

            <div className="bookSectionTitle bstblue">Global Rebel</div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle blue" tabIndex={0} role="button" aria-expanded="false">
                        <div>Activist</div>
                        <div>
                            <progress id="activistProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro blue-light">Complete all seven clauses <Link to="/activist">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 1 - Identify</div>
                        <div className="badgeClauseStatus" id="activist1Status">Complete</div>
                        <div className="badgeClauseStart" id="activist1Start">23/8/2022</div>
                        <div className="badgeClauseEnd" id="activist1End">23/8/2022</div>
                        <div className="badgeClauseProgress"><progress id="activist1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 2 - Educate</div>
                        <div className="badgeClauseStatus" id="activist2Status">Complete</div>
                        <div className="badgeClauseStart" id="activist2Start">23/8/2022</div>
                        <div className="badgeClauseEnd" id="activist2End">23/8/2022</div>
                        <div className="badgeClauseProgress"><progress id="activist2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3 - Immerse</div>
                        <div className="badgeClauseStatus" id="activist3Status">Complete</div>
                        <div className="badgeClauseStart" id="activist3Start">23/8/2022</div>
                        <div className="badgeClauseEnd" id="activist3End">23/8/2022</div>
                        <div className="badgeClauseProgress"><progress id="activist3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 4 - Show Up</div>
                        <div className="badgeClauseStatus" id="activist4Status">Complete</div>
                        <div className="badgeClauseStart" id="activist4Start">23/8/2022</div>
                        <div className="badgeClauseEnd" id="activist4End">23/8/2022</div>
                        <div className="badgeClauseProgress"><progress id="activist4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 5 - Be Counted</div>
                        <div className="badgeClauseStatus" id="activist5Status">Complete</div>
                        <div className="badgeClauseStart" id="activist5Start">23/8/2022</div>
                        <div className="badgeClauseEnd" id="activist5End">23/8/2022</div>
                        <div className="badgeClauseProgress"><progress id="activist5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 6 - Funds</div>
                        <div className="badgeClauseStatus" id="activist6Status">Complete</div>
                        <div className="badgeClauseStart" id="activist6Start">03/02/2023</div>
                        <div className="badgeClauseEnd" id="activist6End">03/02/2023</div>
                        <div className="badgeClauseProgress"><progress id="activist6Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 7 - Activism</div>
                        <div className="badgeClauseStatus" id="activist7Status">Complete</div>
                        <div className="badgeClauseStart" id="activist7Start">03/02/2023</div>
                        <div className="badgeClauseEnd" id="activist7End">03/02/2023</div>
                        <div className="badgeClauseProgress"><progress id="activist7Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="activistProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle blue" tabIndex={0} role="button" aria-expanded="false">
                        <div>Conscious Consumer</div>
                        <div>
                            <progress id="consumerProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro blue-light">Complete all six clauses <Link to="/consciousconsumer">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 1 - Restrict</div>
                        <div className="badgeClauseStatus" id="consumer1Status">Complete</div>
                        <div className="badgeClauseStart" id="consumer1Start">7/2/2023</div>
                        <div className="badgeClauseEnd" id="consumer1End">7/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="consumer1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 2 - Second hand</div>
                        <div className="badgeClauseStatus" id="consumer2Status">Complete</div>
                        <div className="badgeClauseStart" id="consumer2Start">7/2/2023</div>
                        <div className="badgeClauseEnd" id="consumer2End">7/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="consumer2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3 - Reduce</div>
                        <div className="badgeClauseStatus" id="consumer3Status">Complete</div>
                        <div className="badgeClauseStart" id="consumer3Start">7/2/2023</div>
                        <div className="badgeClauseEnd" id="consumer3End">7/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="consumer3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 4 - Research</div>
                        <div className="badgeClauseStatus" id="consumer4Status">Complete</div>
                        <div className="badgeClauseStart" id="consumer4Start">7/2/2023</div>
                        <div className="badgeClauseEnd" id="consumer4End">7/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="consumer4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 5 - Fix</div>
                        <div className="badgeClauseStatus" id="consumer5Status">Complete</div>
                        <div className="badgeClauseStart" id="consumer5Start">7/2/2023</div>
                        <div className="badgeClauseEnd" id="consumer5End">7/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="consumer5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 6 - Make</div>
                        <div className="badgeClauseStatus" id="consumer6Status">Complete</div>
                        <div className="badgeClauseStart" id="consumer6Start">7/2/2023</div>
                        <div className="badgeClauseEnd" id="consumer6End">7/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="consumer6Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="consumerProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle blue" tabIndex={0} role="button" aria-expanded="false">
                        <div>Community Service</div>
                        <div>
                            <progress id="communityProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro blue-light">Volunteer <Link to="/communityservice">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Volunteer work</div>
                        <div className="badgeClauseStatus" id="community1Status">Complete</div>
                        <div className="badgeClauseStart" id="community1Start">23/8/2022</div>
                        <div className="badgeClauseEnd" id="community1End">23/8/2022</div>
                        <div className="badgeClauseProgress"><progress id="community1Progress" value="25" max="100"></progress></div>
                    </div>
                   <div className="badgeProgress"><progress id="communityProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle blue" tabIndex={0} role="button" aria-expanded="false">
                        <div>Emergency Helper</div>
                        <div>
                            <progress id="emergencyProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro blue-light">Complete all 10 clauses <Link to="/emergencyhelper">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 1</div>
                        <div className="badgeClauseStatus" id="emergency1Status">Complete</div>
                        <div className="badgeClauseStart" id="emergency1Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="emergency1End">30/4/2024</div>
                        <div className="badgeClauseProgress"><progress id="emergency1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 2</div>
                        <div className="badgeClauseStatus" id="emergency2Status">Complete</div>
                        <div className="badgeClauseStart" id="emergency2Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="emergency2End">30/4/2024</div>
                        <div className="badgeClauseProgress"><progress id="emergency2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3</div>
                        <div className="badgeClauseStatus" id="emergency3Status">Complete</div>
                        <div className="badgeClauseStart" id="emergency3Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="emergency3End">30/4/2024</div>
                        <div className="badgeClauseProgress"><progress id="emergency3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 4</div>
                        <div className="badgeClauseStatus" id="emergency4Status">Complete</div>
                        <div className="badgeClauseStart" id="emergency4Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="emergency4End">30/4/2024</div>
                        <div className="badgeClauseProgress"><progress id="emergency4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 5</div>
                        <div className="badgeClauseStatus" id="emergency5Status">Complete</div>
                        <div className="badgeClauseStart" id="emergency5Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="emergency5End">30/4/2024</div>
                        <div className="badgeClauseProgress"><progress id="emergency5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 6</div>
                        <div className="badgeClauseStatus" id="emergency6Status">Complete</div>
                        <div className="badgeClauseStart" id="emergency6Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="emergency6End">30/4/2024</div>
                        <div className="badgeClauseProgress"><progress id="emergency6Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 7</div>
                        <div className="badgeClauseStatus" id="emergency7Status">Complete</div>
                        <div className="badgeClauseStart" id="emergency7Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="emergency7End">30/4/2024</div>
                        <div className="badgeClauseProgress"><progress id="emergency7Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 8</div>
                        <div className="badgeClauseStatus" id="emergency8Status">Complete</div>
                        <div className="badgeClauseStart" id="emergency8Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="emergency8End">30/4/2024</div>
                        <div className="badgeClauseProgress"><progress id="emergency8Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 9</div>
                        <div className="badgeClauseStatus" id="emergency9Status">Complete</div>
                        <div className="badgeClauseStart" id="emergency9Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="emergency9End">30/4/2024</div>
                        <div className="badgeClauseProgress"><progress id="emergency9Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 10</div>
                        <div className="badgeClauseStatus" id="emergency10Status">Complete</div>
                        <div className="badgeClauseStart" id="emergency10Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="emergency10End">30/4/2024</div>
                        <div className="badgeClauseProgress"><progress id="emergency10Progress" value="25" max="100"></progress></div>
                    </div>
                   <div className="badgeProgress"><progress id="emergencyProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle blue" tabIndex={0} role="button" aria-expanded="false">
                        <div>Entrepreneur</div>
                        <div>
                            <progress id="entrepreneurProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro blue-light">Complete clauses 1-6 and three tasks from clause 7 <Link to="/entrepreneur">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 1 - Brainstorm</div>
                        <div className="badgeClauseStatus" id="entrepreneur1Status">Complete</div>
                        <div className="badgeClauseStart" id="entrepreneur1Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="entrepreneur1End">30/8/2024</div>
                        <div className="badgeClauseProgress"><progress id="entrepreneur1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 2 - Create</div>
                        <div className="badgeClauseStatus" id="entrepreneur2Status">Complete</div>
                        <div className="badgeClauseStart" id="entrepreneur2Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="entrepreneur2End">30/8/2024</div>
                        <div className="badgeClauseProgress"><progress id="entrepreneur2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3 - Write</div>
                        <div className="badgeClauseStatus" id="entrepreneur3Status">Complete</div>
                        <div className="badgeClauseStart" id="entrepreneur3Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="entrepreneur3End">30/8/2024</div>
                        <div className="badgeClauseProgress"><progress id="entrepreneur3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 4 - Research</div>
                        <div className="badgeClauseStatus" id="entrepreneur4Status">Complete</div>
                        <div className="badgeClauseStart" id="entrepreneur4Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="entrepreneur4End">30/8/2024</div>
                        <div className="badgeClauseProgress"><progress id="entrepreneur4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 5 - Register</div>
                        <div className="badgeClauseStatus" id="entrepreneur5Status">Complete</div>
                        <div className="badgeClauseStart" id="entrepreneur5Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="entrepreneur5End">30/8/2024</div>
                        <div className="badgeClauseProgress"><progress id="entrepreneur5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 6 - Market research</div>
                        <div className="badgeClauseStatus" id="entrepreneur6Status">In progress</div>
                        <div className="badgeClauseStart" id="entrepreneur6Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="entrepreneur6End"></div>
                        <div className="badgeClauseProgress"><progress id="entrepreneur6Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 7</div>
                        <div className="badgeClauseStatus" id="entrepreneur7Status">Complete</div>
                        <div className="badgeClauseStart" id="entrepreneur7Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="entrepreneur7End">30/8/2024</div>
                        <div className="badgeClauseProgress"><progress id="entrepreneur7Progress" value="25" max="100"></progress></div>
                    </div>
                   <div className="badgeProgress"><progress id="entrepreneurProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle blue" tabIndex={0} role="button" aria-expanded="false">
                        <div>Environmentalist</div>
                        <div>
                            <progress id="environmentalistProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro blue-light">Complete clauses 1 and 2 and 6 activities from clause 3 <Link to="/environmentalist">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 1 - Track</div>
                        <div className="badgeClauseStatus" id="environmentalist1Status">Complete</div>
                        <div className="badgeClauseStart" id="environmentalist1Start">6/2/2023</div>
                        <div className="badgeClauseEnd" id="environmentalist1End">6/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="environmentalist1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">task 2 - Reduce</div>
                        <div className="badgeClauseStatus" id="environmentalist2Status">Complete</div>
                        <div className="badgeClauseStart" id="environmentalist2Start">6/2/2023</div>
                        <div className="badgeClauseEnd" id="environmentalist2End">6/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="environmentalist2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3a - Switch</div>
                        <div className="badgeClauseStatus" id="environmentalist3Status">Complete</div>
                        <div className="badgeClauseStart" id="environmentalist3Start">6/2/2023</div>
                        <div className="badgeClauseEnd" id="environmentalist3End">6/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="environmentalist3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3b - Reduce travel</div>
                        <div className="badgeClauseStatus" id="environmentalist4Status">Complete</div>
                        <div className="badgeClauseStart" id="environmentalist4Start">6/2/2023</div>
                        <div className="badgeClauseEnd" id="environmentalist4End">6/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="environmentalist4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3c - Water use</div>
                        <div className="badgeClauseStatus" id="environmentalist5Status">Complete</div>
                        <div className="badgeClauseStart" id="environmentalist5Start">6/2/2023</div>
                        <div className="badgeClauseEnd" id="environmentalist5End">6/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="environmentalist5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3d - Lightbulbs</div>
                        <div className="badgeClauseStatus" id="environmentalist6Status">Complete</div>
                        <div className="badgeClauseStart" id="environmentalist6Start">6/2/2023</div>
                        <div className="badgeClauseEnd" id="environmentalist6End">6/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="environmentalist6Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3e - Litter pick</div>
                        <div className="badgeClauseStatus" id="environmentalist7Status">Complete</div>
                        <div className="badgeClauseStart" id="environmentalist7Start">6/2/2023</div>
                        <div className="badgeClauseEnd" id="environmentalist7End">6/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="environmentalist7Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3f - Chemicals</div>
                        <div className="badgeClauseStatus" id="environmentalist8Status">Complete</div>
                        <div className="badgeClauseStart" id="environmentalist8Start">6/2/2023</div>
                        <div className="badgeClauseEnd" id="environmentalist8End">6/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="environmentalist8Progress" value="25" max="100"></progress></div>
                    </div>
                   <div className="badgeProgress"><progress id="environmentalistProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle blue" tabIndex={0} role="button" aria-expanded="false">
                        <div>Fundraiser</div>
                        <div>
                            <progress id="fundraiserProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro blue-light">Complete all six clauses <Link to="/fundraiser">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 1 - Donate</div>
                        <div className="badgeClauseStatus" id="fundraiser1Status">Complete</div>
                        <div className="badgeClauseStart" id="fundraiser1Start">2/11/2022</div>
                        <div className="badgeClauseEnd" id="fundraiser1End">2/11/2022</div>
                        <div className="badgeClauseProgress"><progress id="fundraiser1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 2 - Volunteer</div>
                        <div className="badgeClauseStatus" id="fundraiser2Status">Complete</div>
                        <div className="badgeClauseStart" id="fundraiser2Start">1/8/2022</div>
                        <div className="badgeClauseEnd" id="fundraiser2End">31/10/2022</div>
                        <div className="badgeClauseProgress"><progress id="fundraiser2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3 - Sponsor</div>
                        <div className="badgeClauseStatus" id="fundraiser3Status">Complete</div>
                        <div className="badgeClauseStart" id="fundraiser3Start">1/8/2022</div>
                        <div className="badgeClauseEnd" id="fundraiser3End">21/10/2023</div>
                        <div className="badgeClauseProgress"><progress id="fundraiser3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 4 - Food Bank</div>
                        <div className="badgeClauseStatus" id="fundraiser4Status">Complete</div>
                        <div className="badgeClauseStart" id="fundraiser4Start">23/8/2022</div>
                        <div className="badgeClauseEnd" id="fundraiser4End">21/10/2023</div>
                        <div className="badgeClauseProgress"><progress id="fundraiser4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 5 - Research</div>
                        <div className="badgeClauseStatus" id="fundraiser5Status">Complete</div>
                        <div className="badgeClauseStart" id="fundraiser5Start">21/10/2023</div>
                        <div className="badgeClauseEnd" id="fundraiser5End">21/10/2023</div>
                        <div className="badgeClauseProgress"><progress id="fundraiser5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 6 - Spring clean</div>
                        <div className="badgeClauseStatus" id="fundraiser6Status">Complete</div>
                        <div className="badgeClauseStart" id="fundraiser6Start">1/8/2022</div>
                        <div className="badgeClauseEnd" id="fundraiser6End">1/8/2022</div>
                        <div className="badgeClauseProgress"><progress id="fundraiser6Progress" value="25" max="100"></progress></div>
                    </div>
                   <div className="badgeProgress"><progress id="fundraiserProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle blue" tabIndex={0} role="button" aria-expanded="false">
                        <div>Linguist</div>
                        <div>
                            <progress id="linguistProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro blue-light">Complete all seven clauses <Link to="/linguist">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Clause 1 - Practice</div>
                        <div className="badgeClauseStatus" id="linguist1Status">In progress</div>
                        <div className="badgeClauseStart" id="linguist1Start">4/4/2024</div>
                        <div className="badgeClauseEnd" id="linguist1End"></div>
                        <div className="badgeClauseProgress"><progress id="linguist1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Clause 2 - Dedicate time</div>
                        <div className="badgeClauseStatus" id="linguist2Status">In progress</div>
                        <div className="badgeClauseStart" id="linguist2Start">4/4/2024</div>
                        <div className="badgeClauseEnd" id="linguist2End"></div>
                        <div className="badgeClauseProgress"><progress id="linguist2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Clause 3 - App</div>
                        <div className="badgeClauseStatus" id="linguist3Status">In progress</div>
                        <div className="badgeClauseStart" id="linguist3Start">4/4/2024</div>
                        <div className="badgeClauseEnd" id="linguist3End"></div>
                        <div className="badgeClauseProgress"><progress id="linguist3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Clause 4 - Conversation</div>
                        <div className="badgeClauseStatus" id="linguist4Status">In progress</div>
                        <div className="badgeClauseStart" id="linguist4Start">4/4/2024</div>
                        <div className="badgeClauseEnd" id="linguist4End"></div>
                        <div className="badgeClauseProgress"><progress id="linguist4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Clause 5 - Translate</div>
                        <div className="badgeClauseStatus" id="linguist5Status">In progress</div>
                        <div className="badgeClauseStart" id="linguist5Start">4/4/2024</div>
                        <div className="badgeClauseEnd" id="linguist5End"></div>
                        <div className="badgeClauseProgress"><progress id="linguist5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Clause 6 - Cook</div>
                        <div className="badgeClauseStatus" id="linguist6Status">Complete</div>
                        <div className="badgeClauseStart" id="linguist6Start">4/4/2024</div>
                        <div className="badgeClauseEnd" id="linguist6End">5/7/2024</div>
                        <div className="badgeClauseProgress"><progress id="linguist6Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Clause 7 - Watch</div>
                        <div className="badgeClauseStatus" id="linguist7Status">In progress</div>
                        <div className="badgeClauseStart" id="linguist7Start">4/4/2024</div>
                        <div className="badgeClauseEnd" id="linguist7End"></div>
                        <div className="badgeClauseProgress"><progress id="linguist7Progress" value="25" max="100"></progress></div>
                    </div>
                   <div className="badgeProgress"><progress id="linguistProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle blue" tabIndex={0} role="button" aria-expanded="false">
                        <div>World Traveller</div>
                        <div>
                            <progress id="worldtravellerProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro blue-light">Complete at least six of a possible ten clauses <Link to="/worldtraveller">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 1 - Destinations</div>
                        <div className="badgeClauseStatus" id="worldtraveller1Status">Complete</div>
                        <div className="badgeClauseStart" id="worldtraveller1Start">29/4/2023</div>
                        <div className="badgeClauseEnd" id="worldtraveller1End">29/4/2023</div>
                        <div className="badgeClauseProgress"><progress id="worldtraveller1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 2 - Food</div>
                        <div className="badgeClauseStatus" id="worldtraveller2Status">Complete</div>
                        <div className="badgeClauseStart" id="worldtraveller2Start">29/4/2023</div>
                        <div className="badgeClauseEnd" id="worldtraveller2End">31/5/2023</div>
                        <div className="badgeClauseProgress"><progress id="worldtraveller2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3 - Rights</div>
                        <div className="badgeClauseStatus" id="worldtraveller3Status">Complete</div>
                        <div className="badgeClauseStart" id="worldtraveller3Start">29/4/2023</div>
                        <div className="badgeClauseEnd" id="worldtraveller3End">29/4/2023</div>
                        <div className="badgeClauseProgress"><progress id="worldtraveller3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 4 - Visas</div>
                        <div className="badgeClauseStatus" id="worldtraveller4Status">Complete</div>
                        <div className="badgeClauseStart" id="worldtraveller4Start">29/4/2023</div>
                        <div className="badgeClauseEnd" id="worldtraveller4End">29/4/2023</div>
                        <div className="badgeClauseProgress"><progress id="worldtraveller4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 5 - Guide</div>
                        <div className="badgeClauseStatus" id="worldtraveller5Status">Complete</div>
                        <div className="badgeClauseStart" id="worldtraveller5Start">1/5/2023</div>
                        <div className="badgeClauseEnd" id="worldtraveller5End">30/6/2023</div>
                        <div className="badgeClauseProgress"><progress id="worldtraveller5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 6 - Festivals</div>
                        <div className="badgeClauseStatus" id="worldtraveller6Status">Complete</div>
                        <div className="badgeClauseStart" id="worldtraveller6Start">29/04/2023</div>
                        <div className="badgeClauseEnd" id="worldtraveller6End">29/04/2023</div>
                        <div className="badgeClauseProgress"><progress id="worldtraveller6Progress" value="25" max="100"></progress></div>
                    </div>
                   <div className="badgeProgress"><progress id="worldtravellerProgress" value="25" max="100"></progress></div>
                </div>
            </div>

            <div className="bookSectionTitle bstolive">Grown Up Rebel</div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle olive" tabIndex={0} role="button" aria-expanded="false">
                        <div>Adulting</div>
                        <div>
                            <progress id="adultingProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro olive-light">Complete at least six of twelve possible tasks <Link to="/adulting">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 1 - Freezer</div>
                        <div className="badgeClauseStatus" id="adulting1Status">Complete</div>
                        <div className="badgeClauseStart" id="adulting1Start">01/09/2022</div>
                        <div className="badgeClauseEnd" id="adulting1End">01/09/2022</div>
                        <div className="badgeClauseProgress"><progress id="adulting1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 2 - Spring clean</div>
                        <div className="badgeClauseStatus" id="adulting2Status">Complete</div>
                        <div className="badgeClauseStart" id="adulting2Start">23/8/2022</div>
                        <div className="badgeClauseEnd" id="adulting2End">23/8/2022</div>
                        <div className="badgeClauseProgress"><progress id="adulting2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3 - Bed</div>
                        <div className="badgeClauseStatus" id="adulting3Status">Complete</div>
                        <div className="badgeClauseStart" id="adulting3Start">23/08/2022</div>
                        <div className="badgeClauseEnd" id="adulting3End">11/9/2022</div>
                        <div className="badgeClauseProgress"><progress id="adulting3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 4 - Washing up</div>
                        <div className="badgeClauseStatus" id="adulting4Status">Complete</div>
                        <div className="badgeClauseStart" id="adulting4Start">23/8/2022</div>
                        <div className="badgeClauseEnd" id="adulting4End">29/8/2022</div>
                        <div className="badgeClauseProgress"><progress id="adulting4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 5 - Laundry</div>
                        <div className="badgeClauseStatus" id="adulting5Status">Complete</div>
                        <div className="badgeClauseStart" id="adulting5Start">21/8/2022</div>
                        <div className="badgeClauseEnd" id="adulting5End">21/9/2022</div>
                        <div className="badgeClauseProgress"><progress id="adulting5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 6 - Tidy</div>
                        <div className="badgeClauseStatus" id="adulting6Status">Complete</div>
                        <div className="badgeClauseStart" id="adulting6Start">23/8/2022</div>
                        <div className="badgeClauseEnd" id="adulting6End">23/8/2022</div>
                        <div className="badgeClauseProgress"><progress id="adulting6Progress" value="25" max="100"></progress></div>
                    </div>
                   <div className="badgeProgress"><progress id="adultingProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle olive" tabIndex={0} role="button" aria-expanded="false">
                        <div>Baker</div>
                        <div>
                            <progress id="bakerProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro olive-light">Over three months, perfect all nine clauses <Link to="/baker">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Bread</div>
                        <div className="badgeClauseStatus" id="baker1Status">Complete</div>
                        <div className="badgeClauseStart" id="baker1Start">23/10/2022</div>
                        <div className="badgeClauseEnd" id="baker1End">12/01/2023</div>
                        <div className="badgeClauseProgress"><progress id="baker1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Cake</div>
                        <div className="badgeClauseStatus" id="baker2Status">Complete</div>
                        <div className="badgeClauseStart" id="baker2Start">18/10/2022</div>
                        <div className="badgeClauseEnd" id="baker2End">26/11/2022</div>
                        <div className="badgeClauseProgress"><progress id="baker2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Biscuit</div>
                        <div className="badgeClauseStatus" id="baker3Status">Complete</div>
                        <div className="badgeClauseStart" id="baker3Start">03/09/2022</div>
                        <div className="badgeClauseEnd" id="baker3End">15/10/2022</div>
                        <div className="badgeClauseProgress"><progress id="baker3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Pudding</div>
                        <div className="badgeClauseStatus" id="baker4Status">Complete</div>
                        <div className="badgeClauseStart" id="baker4Start">04/06/2022</div>
                        <div className="badgeClauseEnd" id="baker4End">04/06/2022</div>
                        <div className="badgeClauseProgress"><progress id="baker4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Pie</div>
                        <div className="badgeClauseStatus" id="baker5Status">Complete</div>
                        <div className="badgeClauseStart" id="baker5Start">10/10/2022</div>
                        <div className="badgeClauseEnd" id="baker5End">10/10/2022</div>
                        <div className="badgeClauseProgress"><progress id="baker5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Eclairs</div>
                        <div className="badgeClauseStatus" id="baker6Status">Complete</div>
                        <div className="badgeClauseStart" id="baker6Start">23/12/2022</div>
                        <div className="badgeClauseEnd" id="baker6End">23/12/2022</div>
                        <div className="badgeClauseProgress"><progress id="baker6Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Tart</div>
                        <div className="badgeClauseStatus" id="baker7Status">Complete</div>
                        <div className="badgeClauseStart" id="baker7Start">13/10/2022</div>
                        <div className="badgeClauseEnd" id="baker7End">13/10/2022</div>
                        <div className="badgeClauseProgress"><progress id="baker7Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Batch bake</div>
                        <div className="badgeClauseStatus" id="baker8Status">Complete</div>
                        <div className="badgeClauseStart" id="baker8Start">23/12/2022</div>
                        <div className="badgeClauseEnd" id="baker8End">23/12/2022</div>
                        <div className="badgeClauseProgress"><progress id="baker8Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Mille-Feuille</div>
                        <div className="badgeClauseStatus" id="baker9Status">Complete</div>
                        <div className="badgeClauseStart" id="baker9Start">23/12/2022</div>
                        <div className="badgeClauseEnd" id="baker9End">23/12/2022</div>
                        <div className="badgeClauseProgress"><progress id="baker9Progress" value="25" max="100"></progress></div>
                    </div>
                   <div className="badgeProgress"><progress id="bakerProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle olive" tabIndex={0} role="button" aria-expanded="false">
                        <div>Chef</div>
                        <div>
                            <progress id="chefProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro olive-light">Complete all nine tasks <Link to="/chef">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 1 - Skills</div>
                        <div className="badgeClauseStatus" id="chef1Status">Complete</div>
                        <div className="badgeClauseStart" id="chef1Start">1/8/2022</div>
                        <div className="badgeClauseEnd" id="chef1End">31/10/2022</div>
                        <div className="badgeClauseProgress"><progress id="chef1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 2 - Meals</div>
                        <div className="badgeClauseStatus" id="chef2Status">Complete</div>
                        <div className="badgeClauseStart" id="chef2Start">1/8/2022</div>
                        <div className="badgeClauseEnd" id="chef2End">31/10/2022</div>
                        <div className="badgeClauseProgress"><progress id="chef2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3 - New cuisine</div>
                        <div className="badgeClauseStatus" id="chef3Status">Complete</div>
                        <div className="badgeClauseStart" id="chef3Start">1/1/2022</div>
                        <div className="badgeClauseEnd" id="chef3End">31/10/2022</div>
                        <div className="badgeClauseProgress"><progress id="chef3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 4 - Knives</div>
                        <div className="badgeClauseStatus" id="chef4Status">Complete</div>
                        <div className="badgeClauseStart" id="chef4Start">31/10/2022</div>
                        <div className="badgeClauseEnd" id="chef4End">31/10/2022</div>
                        <div className="badgeClauseProgress"><progress id="chef4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 5 - Three course meal</div>
                        <div className="badgeClauseStatus" id="chef5Status">Complete</div>
                        <div className="badgeClauseStart" id="chef5Start">09/02/2023</div>
                        <div className="badgeClauseEnd" id="chef5End">09/02/2023</div>
                        <div className="badgeClauseProgress"><progress id="chef5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 6 - Recipes</div>
                        <div className="badgeClauseStatus" id="chef6Status">Complete</div>
                        <div className="badgeClauseStart" id="chef6Start">13/10/2022</div>
                        <div className="badgeClauseEnd" id="chef6End">13/10/2022</div>
                        <div className="badgeClauseProgress"><progress id="chef6Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 7 - Dinner party</div>
                        <div className="badgeClauseStatus" id="chef7Status">Complete</div>
                        <div className="badgeClauseStart" id="chef7Start">31/5/2023</div>
                        <div className="badgeClauseEnd" id="chef7End">31/5/2023</div>
                        <div className="badgeClauseProgress"><progress id="chef7Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 8 - Alternatives</div>
                        <div className="badgeClauseStatus" id="chef8Status">Complete</div>
                        <div className="badgeClauseStart" id="chef8Start">1/1/2022</div>
                        <div className="badgeClauseEnd" id="chef8End">31/10/2022</div>
                        <div className="badgeClauseProgress"><progress id="chef8Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 9 - Recipe book</div>
                        <div className="badgeClauseStatus" id="chef9Status">Complete</div>
                        <div className="badgeClauseStart" id="chef9Start">1/1/2022</div>
                        <div className="badgeClauseEnd" id="chef9End">04/02/2023</div>
                        <div className="badgeClauseProgress"><progress id="chef9Progress" value="25" max="100"></progress></div>
                    </div>
                   <div className="badgeProgress"><progress id="chefProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle olive" tabIndex={0} role="button" aria-expanded="false">
                        <div>DIY</div>
                        <div>
                            <progress id="diyProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro olive-light">Complete all seven clauses <Link to="/diy">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 1 - Supplies</div>
                        <div className="badgeClauseStatus" id="diy1Status">Complete</div>
                        <div className="badgeClauseStart" id="diy1Start">31/10/2022</div>
                        <div className="badgeClauseEnd" id="diy1End">31/10/2022</div>
                        <div className="badgeClauseProgress"><progress id="diy1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 2 - Lightbulbs</div>
                        <div className="badgeClauseStatus" id="diy2Status">Complete</div>
                        <div className="badgeClauseStart" id="diy2Start">31/10/2022</div>
                        <div className="badgeClauseEnd" id="diy2End">31/10/2022</div>
                        <div className="badgeClauseProgress"><progress id="diy2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3 - Washers</div>
                        <div className="badgeClauseStatus" id="diy3Status">Complete</div>
                        <div className="badgeClauseStart" id="diy3Start">4/2/2023</div>
                        <div className="badgeClauseEnd" id="diy3End"></div>
                        <div className="badgeClauseProgress"><progress id="diy3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 4 - Plugs</div>
                        <div className="badgeClauseStatus" id="diy4Status">Complete</div>
                        <div className="badgeClauseStart" id="diy4Start">4/2/2023</div>
                        <div className="badgeClauseEnd" id="diy4End"></div>
                        <div className="badgeClauseProgress"><progress id="diy4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 5 - Circuit breakers</div>
                        <div className="badgeClauseStatus" id="diy5Status">Complete</div>
                        <div className="badgeClauseStart" id="diy5Start">31/10/2022</div>
                        <div className="badgeClauseEnd" id="diy5End">31/10/2022</div>
                        <div className="badgeClauseProgress"><progress id="diy5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 6 - Toolkit</div>
                        <div className="badgeClauseStatus" id="diy6Status">Complete</div>
                        <div className="badgeClauseStart" id="diy6Start">4/2/2023</div>
                        <div className="badgeClauseEnd" id="diy6End">4/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="diy6Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 7 - Projects</div>
                        <div className="badgeClauseStatus" id="diy7Status">Complete</div>
                        <div className="badgeClauseStart" id="diy7Start">4/2/2023</div>
                        <div className="badgeClauseEnd" id="diy7End">4/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="diy7Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 7 - Project 1</div>
                        <div className="badgeClauseStatus" id="diy8Status">Complete</div>
                        <div className="badgeClauseStart" id="diy8Start">31/10/2022</div>
                        <div className="badgeClauseEnd" id="diy8End">31/10/2022</div>
                        <div className="badgeClauseProgress"><progress id="diy8Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 7 - Project 2</div>
                        <div className="badgeClauseStatus" id="diy9Status">Complete</div>
                        <div className="badgeClauseStart" id="diy9Start">31/10/2022</div>
                        <div className="badgeClauseEnd" id="diy9End">31/10/2022</div>
                        <div className="badgeClauseProgress"><progress id="diy9Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 7 - Project 3</div>
                        <div className="badgeClauseStatus" id="diy10Status">Complete</div>
                        <div className="badgeClauseStart" id="diy10Start">31/10/2022</div>
                        <div className="badgeClauseEnd" id="diy10End">31/10/2022</div>
                        <div className="badgeClauseProgress"><progress id="diy10Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 7 - Project 4</div>
                        <div className="badgeClauseStatus" id="diy11Status">Complete</div>
                        <div className="badgeClauseStart" id="diy11Start">31/10/2022</div>
                        <div className="badgeClauseEnd" id="diy11End">31/10/2022</div>
                        <div className="badgeClauseProgress"><progress id="diy11Progress" value="25" max="100"></progress></div>
                    </div>
                   <div className="badgeProgress"><progress id="diyProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle olive" tabIndex={0} role="button" aria-expanded="false">
                        <div>Gardener</div>
                        <div>
                            <progress id="gardenerProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro olive-light">Complete all eight clauses <Link to="/gardener">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 1 - Cultivate</div>
                        <div className="badgeClauseStatus" id="gardener1Status">In progress</div>
                        <div className="badgeClauseStart" id="gardener1Start">27/8/2022</div>
                        <div className="badgeClauseEnd" id="gardener1End"></div>
                        <div className="badgeClauseProgress"><progress id="gardener1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 2 - Tend</div>
                        <div className="badgeClauseStatus" id="gardener2Status">In progress</div>
                        <div className="badgeClauseStart" id="gardener2Start">27/8/2022</div>
                        <div className="badgeClauseEnd" id="gardener2End"></div>
                        <div className="badgeClauseProgress"><progress id="gardener2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3 - Grow plants</div>
                        <div className="badgeClauseStatus" id="gardener3Status">In progress</div>
                        <div className="badgeClauseStart" id="gardener3Start">16/9/2022</div>
                        <div className="badgeClauseEnd" id="gardener3End"></div>
                        <div className="badgeClauseProgress"><progress id="gardener3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 4 - Seeds and bulbs</div>
                        <div className="badgeClauseStatus" id="gardener4Status">In progress</div>
                        <div className="badgeClauseStart" id="gardener4Start">16/9/2022</div>
                        <div className="badgeClauseEnd" id="gardener4End"></div>
                        <div className="badgeClauseProgress"><progress id="gardener4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 5 - Equipment</div>
                        <div className="badgeClauseStatus" id="gardener5Status">Complete</div>
                        <div className="badgeClauseStart" id="gardener5Start">16/9/2022</div>
                        <div className="badgeClauseEnd" id="gardener5End"></div>
                        <div className="badgeClauseProgress"><progress id="gardener5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 6 - Soil</div>
                        <div className="badgeClauseStatus" id="gardener6Status">In progress</div>
                        <div className="badgeClauseStart" id="gardener6Start">16/9/2022</div>
                        <div className="badgeClauseEnd" id="gardener6End"></div>
                        <div className="badgeClauseProgress"><progress id="gardener6Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 7 - Bees</div>
                        <div className="badgeClauseStatus" id="gardener7Status">Complete</div>
                        <div className="badgeClauseStart" id="gardener7Start">16/9/2022</div>
                        <div className="badgeClauseEnd" id="gardener7End">04/03/2023</div>
                        <div className="badgeClauseProgress"><progress id="gardener7Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 8 - First aid</div>
                        <div className="badgeClauseStatus" id="gardener8Status">Complete</div>
                        <div className="badgeClauseStart" id="gardener8Start">15/5/2021</div>
                        <div className="badgeClauseEnd" id="gardener8End">15/5/2021</div>
                        <div className="badgeClauseProgress"><progress id="gardener8Progress" value="25" max="100"></progress></div>
                    </div>
                   <div className="badgeProgress"><progress id="gardenerProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle olive" tabIndex={0} role="button" aria-expanded="false">
                        <div>Indoor Gardener</div>
                        <div>
                            <progress id="indoorgardenerProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro olive-light">Complete all six clauses <Link to="/indoorgardener">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 1 - Houseplants</div>
                        <div className="badgeClauseStatus" id="indoorgardener1Status">Complete</div>
                        <div className="badgeClauseStart" id="indoorgardener1Start">02/9/2022</div>
                        <div className="badgeClauseEnd" id="indoorgardener1End">04/03/2023</div>
                        <div className="badgeClauseProgress"><progress id="indoorgardener1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 2 - Light</div>
                        <div className="badgeClauseStatus" id="indoorgardener2Status">Complete</div>
                        <div className="badgeClauseStart" id="indoorgardener2Start">16/9/2022</div>
                        <div className="badgeClauseEnd" id="indoorgardener2End">31/08/2023</div>
                        <div className="badgeClauseProgress"><progress id="indoorgardener2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3 - Water</div>
                        <div className="badgeClauseStatus" id="indoorgardener3Status">Complete</div>
                        <div className="badgeClauseStart" id="indoorgardener3Start">16/9/2022</div>
                        <div className="badgeClauseEnd" id="indoorgardener3End">31/08/2023</div>
                        <div className="badgeClauseProgress"><progress id="indoorgardener3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 4 - Tools</div>
                        <div className="badgeClauseStatus" id="indoorgardener4Status">Complete</div>
                        <div className="badgeClauseStart" id="indoorgardener4Start">16/9/2022</div>
                        <div className="badgeClauseEnd" id="indoorgardener4End">31/08/2023</div>
                        <div className="badgeClauseProgress"><progress id="indoorgardener4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 5 - Transplant</div>
                        <div className="badgeClauseStatus" id="indoorgardener5Status">Complete</div>
                        <div className="badgeClauseStart" id="indoorgardener5Start">16/9/2022</div>
                        <div className="badgeClauseEnd" id="indoorgardener5End">31/08/2023</div>
                        <div className="badgeClauseProgress"><progress id="indoorgardener5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 6 - Nurture</div>
                        <div className="badgeClauseStatus" id="indoorgardener6Status">Complete</div>
                        <div className="badgeClauseStart" id="indoorgardener6Start">9/3/2023</div>
                        <div className="badgeClauseEnd" id="indoorgardener6End">31/08/2023</div>
                        <div className="badgeClauseProgress"><progress id="indoorgardener6Progress" value="25" max="100"></progress></div>
                    </div>
                   <div className="badgeProgress"><progress id="indoorgardenerProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle olive" tabIndex={0} role="button" aria-expanded="false">
                        <div>Interior Designer</div>
                        <div>
                            <progress id="interiordesignerProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro olive-light">Complete at least four of eight possible clauses <Link to="/interiordesigner">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 1 - Upcycle</div>
                        <div className="badgeClauseStatus" id="interiordesigner1Status">Complete</div>
                        <div className="badgeClauseStart" id="interiordesigner1Start">23/8/2022</div>
                        <div className="badgeClauseEnd" id="interiordesigner1End">23/8/2022</div>
                        <div className="badgeClauseProgress"><progress id="interiordesigner1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 2 - Colours</div>
                        <div className="badgeClauseStatus" id="interiordesigner2Status">Complete</div>
                        <div className="badgeClauseStart" id="interiordesigner2Start">23/8/2022</div>
                        <div className="badgeClauseEnd" id="interiordesigner2End">23/8/2022</div>
                        <div className="badgeClauseProgress"><progress id="interiordesigner2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3 - Flat pack</div>
                        <div className="badgeClauseStatus" id="interiordesigner3Status">Complete</div>
                        <div className="badgeClauseStart" id="interiordesigner3Start">23/8/2022</div>
                        <div className="badgeClauseEnd" id="interiordesigner3End">23/8/2022</div>
                        <div className="badgeClauseProgress"><progress id="interiordesigner3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 4 - Rearrange</div>
                        <div className="badgeClauseStatus" id="interiordesigner4Status">Complete</div>
                        <div className="badgeClauseStart" id="interiordesigner4Start">4/2/2023</div>
                        <div className="badgeClauseEnd" id="interiordesigner4End">4/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="interiordesigner4Progress" value="25" max="100"></progress></div>
                    </div>
                   <div className="badgeProgress"><progress id="interiordesignerProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle olive" tabIndex={0} role="button" aria-expanded="false">
                        <div>Investor</div>
                        <div>
                            <progress id="investorProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro olive-light">Complete all six clauses <Link to="/investor">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 1 - Stock Markets</div>
                        <div className="badgeClauseStatus" id="investor1Status">Complete</div>
                        <div className="badgeClauseStart" id="investor1Start">1/11/2023</div>
                        <div className="badgeClauseEnd" id="investor1End">30/11/2023</div>
                        <div className="badgeClauseProgress"><progress id="investor1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 2 - Share prices</div>
                        <div className="badgeClauseStatus" id="investor2Status">Complete</div>
                        <div className="badgeClauseStart" id="investor2Start">1/2/2023</div>
                        <div className="badgeClauseEnd" id="investor2End">30/4/2023</div>
                        <div className="badgeClauseProgress"><progress id="investor2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3 - Savings</div>
                        <div className="badgeClauseStatus" id="investor3Status">Complete</div>
                        <div className="badgeClauseStart" id="investor3Start">4/11/2023</div>
                        <div className="badgeClauseEnd" id="investor3End">4/11/2023</div>
                        <div className="badgeClauseProgress"><progress id="investor3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 4 - Funds</div>
                        <div className="badgeClauseStatus" id="investor4Status">Complete</div>
                        <div className="badgeClauseStart" id="investor4Start">4/11/2023</div>
                        <div className="badgeClauseEnd" id="investor4End">4/11/2023</div>
                        <div className="badgeClauseProgress"><progress id="investor4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 5 - Pensions</div>
                        <div className="badgeClauseStatus" id="investor5Status">Complete</div>
                        <div className="badgeClauseStart" id="investor5Start">4/11/2023</div>
                        <div className="badgeClauseEnd" id="investor5End">4/11/2023</div>
                        <div className="badgeClauseProgress"><progress id="investor5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 6 - Read</div>
                        <div className="badgeClauseStatus" id="investor6Status">Complete</div>
                        <div className="badgeClauseStart" id="investor6Start">1/11/2023</div>
                        <div className="badgeClauseEnd" id="investor6End">30/11/2023</div>
                        <div className="badgeClauseProgress"><progress id="investor6Progress" value="25" max="100"></progress></div>
                    </div>
                   <div className="badgeProgress"><progress id="investorProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle olive" tabIndex={0} role="button" aria-expanded="false">
                        <div>Money saver</div>
                        <div>
                            <progress id="moneysaverProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro olive-light">Complete all six clauses <Link to="/moneysaver">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 1 - Reduce</div>
                        <div className="badgeClauseStatus" id="moneysaver1Status">Complete</div>
                        <div className="badgeClauseStart" id="moneysaver1Start">1/8/2022</div>
                        <div className="badgeClauseEnd" id="moneysaver1End">31/10/2022</div>
                        <div className="badgeClauseProgress"><progress id="moneysaver1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 2 - Payments</div>
                        <div className="badgeClauseStatus" id="moneysaver2Status">Complete</div>
                        <div className="badgeClauseStart" id="moneysaver2Start">1/8/2022</div>
                        <div className="badgeClauseEnd" id="moneysaver2End">31/10/2022</div>
                        <div className="badgeClauseProgress"><progress id="moneysaver2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3 - Savings</div>
                        <div className="badgeClauseStatus" id="moneysaver3Status">Complete</div>
                        <div className="badgeClauseStart" id="moneysaver3Start">4/2/2023</div>
                        <div className="badgeClauseEnd" id="moneysaver3End">31/5/2023</div>
                        <div className="badgeClauseProgress"><progress id="moneysaver3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 4 - Save money</div>
                        <div className="badgeClauseStatus" id="moneysaver4Status">Complete</div>
                        <div className="badgeClauseStart" id="moneysaver4Start">4/2/2023</div>
                        <div className="badgeClauseEnd" id="moneysaver4End">4/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="moneysaver4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 5 - Repair</div>
                        <div className="badgeClauseStatus" id="moneysaver5Status">Complete</div>
                        <div className="badgeClauseStart" id="moneysaver5Start">4/2/2023</div>
                        <div className="badgeClauseEnd" id="moneysaver5End">14/4/2023</div>
                        <div className="badgeClauseProgress"><progress id="moneysaver5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 6 - Educate</div>
                        <div className="badgeClauseStatus" id="moneysaver6Status">Complete</div>
                        <div className="badgeClauseStart" id="moneysaver6Start">4/2/2023</div>
                        <div className="badgeClauseEnd" id="moneysaver6End">31/05/2023</div>
                        <div className="badgeClauseProgress"><progress id="moneysaver6Progress" value="25" max="100"></progress></div>
                    </div>
                   <div className="badgeProgress"><progress id="moneysaverProgress" value="25" max="100"></progress></div>
                </div>
            </div>

            <div className="bookSectionTitle bstpink">Self Aware Rebel</div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle pink" tabIndex={0} role="button" aria-expanded="false">
                        <div>My brand</div>
                        <div>
                            <progress id="mybrandProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro pink-light">Complete all six clauses and the given exercise <Link to="/mybrand">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 1</div>
                        <div className="badgeClauseStatus" id="mybrand1Status">Complete</div>
                        <div className="badgeClauseStart" id="mybrand1Start">1/1/24</div>
                        <div className="badgeClauseEnd" id="mybrand1End">10/1/24</div>
                        <div className="badgeClauseProgress"><progress id="mybrand1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 2</div>
                        <div className="badgeClauseStatus" id="mybrand2Status">Complete</div>
                        <div className="badgeClauseStart" id="mybrand2Start">1/1/24</div>
                        <div className="badgeClauseEnd" id="mybrand2End">10/1/24</div>
                        <div className="badgeClauseProgress"><progress id="mybrand2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3</div>
                        <div className="badgeClauseStatus" id="mybrand3Status">Complete</div>
                        <div className="badgeClauseStart" id="mybrand3Start">1/1/24</div>
                        <div className="badgeClauseEnd" id="mybrand3End">10/1/24</div>
                        <div className="badgeClauseProgress"><progress id="mybrand3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 4</div>
                        <div className="badgeClauseStatus" id="mybrand4Status">Complete</div>
                        <div className="badgeClauseStart" id="mybrand4Start">1/1/24</div>
                        <div className="badgeClauseEnd" id="mybrand4End">10/1/24</div>
                        <div className="badgeClauseProgress"><progress id="mybrand4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 5</div>
                        <div className="badgeClauseStatus" id="mybrand5Status">Complete</div>
                        <div className="badgeClauseStart" id="mybrand5Start">1/1/2024</div>
                        <div className="badgeClauseEnd" id="mybrand5End">19/1/2024</div>
                        <div className="badgeClauseProgress"><progress id="mybrand5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 6</div>
                        <div className="badgeClauseStatus" id="mybrand6Status">Complete</div>
                        <div className="badgeClauseStart" id="mybrand6Start">1/1/2024</div>
                        <div className="badgeClauseEnd" id="mybrand6End">31/01/2024</div>
                        <div className="badgeClauseProgress"><progress id="mybrand6Progress" value="25" max="100"></progress></div>
                    </div>
                   <div className="badgeProgress"><progress id="mybrandProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle pink" tabIndex={0} role="button" aria-expanded="false">
                        <div>My Goals</div>
                        <div>
                            <progress id="mygoalsProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro pink-light">Work on at least one goal for each area over 6-12 months <Link to="/mygoals">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Health</div>
                        <div className="badgeClauseStatus" id="mygoals1Status">In progress</div>
                        <div className="badgeClauseStart" id="mygoals1Start">01/01/2024</div>
                        <div className="badgeClauseEnd" id="mygoals1End"></div>
                        <div className="badgeClauseProgress"><progress id="mygoals1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Career</div>
                        <div className="badgeClauseStatus" id="mygoals2Status">In progress</div>
                        <div className="badgeClauseStart" id="mygoals2Start">01/01/2024</div>
                        <div className="badgeClauseEnd" id="mygoals2End"></div>
                        <div className="badgeClauseProgress"><progress id="mygoals2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Wealth</div>
                        <div className="badgeClauseStatus" id="mygoals3Status">In progress</div>
                        <div className="badgeClauseStart" id="mygoals3Start">01/01/2024</div>
                        <div className="badgeClauseEnd" id="mygoals3End"></div>
                        <div className="badgeClauseProgress"><progress id="mygoals3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Travel</div>
                        <div className="badgeClauseStatus" id="mygoals4Status">In progress</div>
                        <div className="badgeClauseStart" id="mygoals4Start">01/01/2024</div>
                        <div className="badgeClauseEnd" id="mygoals4End"></div>
                        <div className="badgeClauseProgress"><progress id="mygoals4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Family</div>
                        <div className="badgeClauseStatus" id="mygoals5Status">In progress</div>
                        <div className="badgeClauseStart" id="mygoals5Start">01/01/2024</div>
                        <div className="badgeClauseEnd" id="mygoals5End"></div>
                        <div className="badgeClauseProgress"><progress id="mygoals5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Lifestyle</div>
                        <div className="badgeClauseStatus" id="mygoals6Status">In progress</div>
                        <div className="badgeClauseStart" id="mygoals6Start">01/01/2024</div>
                        <div className="badgeClauseEnd" id="mygoals6End"></div>
                        <div className="badgeClauseProgress"><progress id="mygoals6Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Creativity</div>
                        <div className="badgeClauseStatus" id="mygoals7Status">In progress</div>
                        <div className="badgeClauseStart" id="mygoals7Start">01/01/2024</div>
                        <div className="badgeClauseEnd" id="mygoals7End"></div>
                        <div className="badgeClauseProgress"><progress id="mygoals7Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Knowledge</div>
                        <div className="badgeClauseStatus" id="mygoals8Status">In progress</div>
                        <div className="badgeClauseStart" id="mygoals8Start">01/01/2024</div>
                        <div className="badgeClauseEnd" id="mygoals8End"></div>
                        <div className="badgeClauseProgress"><progress id="mygoals8Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Relationships</div>
                        <div className="badgeClauseStatus" id="mygoals9Status">In progress</div>
                        <div className="badgeClauseStart" id="mygoals9Start">01/01/2024</div>
                        <div className="badgeClauseEnd" id="mygoals9End"></div>
                        <div className="badgeClauseProgress"><progress id="mygoals9Progress" value="25" max="100"></progress></div>
                    </div>
                   <div className="badgeProgress"><progress id="mygoalsProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle pink" tabIndex={0} role="button" aria-expanded="false">
                        <div>My Roots</div>
                        <div>
                            <progress id="myrootsProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro pink-light">Spend at least six months researching your family tree. <Link to="/myroots">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Family Tree</div>
                        <div className="badgeClauseStatus" id="myroots1Status">Complete</div>
                        <div className="badgeClauseStart" id="myroots1Start">01/01/2024</div>
                        <div className="badgeClauseEnd" id="myroots1End">30/06/2024</div>
                        <div className="badgeClauseProgress"><progress id="myroots1Progress" value="25" max="100"></progress></div>
                    </div>
                   <div className="badgeProgress"><progress id="myrootsProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle pink" tabIndex={0} role="button" aria-expanded="false">
                        <div>My Style</div>
                        <div>
                            <progress id="mystyleProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro pink-light">Complete all six clauses <Link to="/mystyle">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 1</div>
                        <div className="badgeClauseStatus" id="mystyle1Status">Complete</div>
                        <div className="badgeClauseStart" id="mystyle1Start">1/9/2022</div>
                        <div className="badgeClauseEnd" id="mystyle1End">30/9/2022</div>
                        <div className="badgeClauseProgress"><progress id="mystyle1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 2</div>
                        <div className="badgeClauseStatus" id="mystyle2Status">Complete</div>
                        <div className="badgeClauseStart" id="mystyle2Start">4/2/2023</div>
                        <div className="badgeClauseEnd" id="mystyle2End">4/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="mystyle2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3</div>
                        <div className="badgeClauseStatus" id="mystyle3Status">Complete</div>
                        <div className="badgeClauseStart" id="mystyle3Start">4/2/2023</div>
                        <div className="badgeClauseEnd" id="mystyle3End">4/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="mystyle3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 4</div>
                        <div className="badgeClauseStatus" id="mystyle4Status">Complete</div>
                        <div className="badgeClauseStart" id="mystyle4Start">4/2/2023</div>
                        <div className="badgeClauseEnd" id="mystyle4End">4/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="mystyle4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 5</div>
                        <div className="badgeClauseStatus" id="mystyle5Status">Complete</div>
                        <div className="badgeClauseStart" id="mystyle5Start">4/2/2023</div>
                        <div className="badgeClauseEnd" id="mystyle5End">4/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="mystyle5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 6</div>
                        <div className="badgeClauseStatus" id="mystyle6Status">Complete</div>
                        <div className="badgeClauseStart" id="mystyle6Start">4/2/2023</div>
                        <div className="badgeClauseEnd" id="mystyle6End">4/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="mystyle6Progress" value="25" max="100"></progress></div>
                    </div>
                   <div className="badgeProgress"><progress id="mystyleProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle pink" tabIndex={0} role="button" aria-expanded="false">
                        <div>My Talents</div>
                        <div>
                            <progress id="mytalentsProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro pink-light">Complete all six clauses <Link to="/mytalents">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 1</div>
                        <div className="badgeClauseStatus" id="mytalents1Status">Complete</div>
                        <div className="badgeClauseStart" id="mytalents1Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="mytalents1End">31/03/2024</div>
                        <div className="badgeClauseProgress"><progress id="mytalents1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 2</div>
                        <div className="badgeClauseStatus" id="mytalents2Status">Complete</div>
                        <div className="badgeClauseStart" id="mytalents2Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="mytalents2End">31/03/2024</div>
                        <div className="badgeClauseProgress"><progress id="mytalents2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3</div>
                        <div className="badgeClauseStatus" id="mytalents3Status">Complete</div>
                        <div className="badgeClauseStart" id="mytalents3Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="mytalents3End">31/03/2024</div>
                        <div className="badgeClauseProgress"><progress id="mytalents3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 4</div>
                        <div className="badgeClauseStatus" id="mytalents4Status">Complete</div>
                        <div className="badgeClauseStart" id="mytalents4Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="mytalents4End">31/03/2024</div>
                        <div className="badgeClauseProgress"><progress id="mytalents4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 5</div>
                        <div className="badgeClauseStatus" id="mytalents5Status">Complete</div>
                        <div className="badgeClauseStart" id="mytalents5Start">31/03/2024</div>
                        <div className="badgeClauseEnd" id="mytalents5End"></div>
                        <div className="badgeClauseProgress"><progress id="mytalents5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 6</div>
                        <div className="badgeClauseStatus" id="mytalents6Status">Complete</div>
                        <div className="badgeClauseStart" id="mytalents6Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="mytalents6End">31/03/2024</div>
                        <div className="badgeClauseProgress"><progress id="mytalents6Progress" value="25" max="100"></progress></div>
                    </div>
                   <div className="badgeProgress"><progress id="mytalentsProgress" value="25" max="100"></progress></div>
                </div>
            </div>

            <div className="bookSectionTitle bstgreen">Wellness Rebel</div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle green" tabIndex={0} role="button" aria-expanded="false">
                        <div>Apothecary</div>
                        <div>
                            <progress id="apothecaryProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro green-light">Make at least five of a possible eight items <Link to="/apothecary">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 1</div>
                        <div className="badgeClauseStatus" id="apothecary1Status">Complete</div>
                        <div className="badgeClauseStart" id="apothecary1Start">02/04/2023</div>
                        <div className="badgeClauseEnd" id="apothecary1End">02/04/2023</div>
                        <div className="badgeClauseProgress"><progress id="apothecary1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 2</div>
                        <div className="badgeClauseStatus" id="apothecary2Status">Complete</div>
                        <div className="badgeClauseStart" id="apothecary2Start">06/04/2023</div>
                        <div className="badgeClauseEnd" id="apothecary2End">06/04/2023</div>
                        <div className="badgeClauseProgress"><progress id="apothecary2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3</div>
                        <div className="badgeClauseStatus" id="apothecary3Status">Complete</div>
                        <div className="badgeClauseStart" id="apothecary3Start">17/02/2023</div>
                        <div className="badgeClauseEnd" id="apothecary3End">17/02/2023</div>
                        <div className="badgeClauseProgress"><progress id="apothecary3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 4</div>
                        <div className="badgeClauseStatus" id="apothecary4Status">Complete</div>
                        <div className="badgeClauseStart" id="apothecary4Start">02/04/2023</div>
                        <div className="badgeClauseEnd" id="apothecary4End">02/04/2023</div>
                        <div className="badgeClauseProgress"><progress id="apothecary4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 5</div>
                        <div className="badgeClauseStatus" id="apothecary5Status">Complete</div>
                        <div className="badgeClauseStart" id="apothecary5Start">02/04/2023</div>
                        <div className="badgeClauseEnd" id="apothecary5End">02/04/2023</div>
                        <div className="badgeClauseProgress"><progress id="apothecary5Progress" value="25" max="100"></progress></div>
                    </div>
                   <div className="badgeProgress"><progress id="apothecaryProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle green" tabIndex={0} role="button" aria-expanded="false">
                        <div>Diarist</div>
                        <div>
                            <progress id="diaristProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro green-light">Keep a diary <Link to="/diarist">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Clause 1</div>
                        <div className="badgeClauseStatus" id="diarist1Status">Complete</div>
                        <div className="badgeClauseStart" id="diarist1Start">1/5/2023</div>
                        <div className="badgeClauseEnd" id="diarist1End">30/11/2023</div>
                        <div className="badgeClauseProgress"><progress id="diarist1Progress" value="25" max="100"></progress></div>
                    </div>
                   <div className="badgeProgress"><progress id="diaristProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle green" tabIndex={0} role="button" aria-expanded="false">
                        <div>Fitness</div>
                        <div>
                            <progress id="fitnessProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro green-light">Exercise regularly over the next three months <Link to="/fitness">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Exercise</div>
                        <div className="badgeClauseStatus" id="fitness1Status">Complete</div>
                        <div className="badgeClauseStart" id="fitness1Start">1/8/2022</div>
                        <div className="badgeClauseEnd" id="fitness1End">31/10/2022</div>
                        <div className="badgeClauseProgress"><progress id="fitness1Progress" value="25" max="100"></progress></div>
                    </div>
                   <div className="badgeProgress"><progress id="fitnessProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle green" tabIndex={0} role="button" aria-expanded="false">
                        <div>Good Habits</div>
                        <div>
                            <progress id="goodhabitsProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro green-light">Complete all three clauses <Link to="/goodhabits">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 1 - Change</div>
                        <div className="badgeClauseStatus" id="goodhabits1Status">Complete</div>
                        <div className="badgeClauseStart" id="goodhabits1Start">1/2/2023</div>
                        <div className="badgeClauseEnd" id="goodhabits1End">4/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="goodhabits1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 2 - Habits</div>
                        <div className="badgeClauseStatus" id="goodhabits2Status">Complete</div>
                        <div className="badgeClauseStart" id="goodhabits2Start">1/2/2023</div>
                        <div className="badgeClauseEnd" id="goodhabits2End">30/04/2023</div>
                        <div className="badgeClauseProgress"><progress id="goodhabits2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3 - Read</div>
                        <div className="badgeClauseStatus" id="goodhabits3Status">Complete</div>
                        <div className="badgeClauseStart" id="goodhabits3Start">15/8/2022</div>
                        <div className="badgeClauseEnd" id="goodhabits3End">12/01/2023</div>
                        <div className="badgeClauseProgress"><progress id="goodhabits3Progress" value="25" max="100"></progress></div>
                    </div>
                   <div className="badgeProgress"><progress id="goodhabitsProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle green" tabIndex={0} role="button" aria-expanded="false">
                        <div>Mindfulness</div>
                        <div>
                            <progress id="mindfulnessProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro green-light">Complete all five clauses <Link to="/mindfulness">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 1 - Meditation</div>
                        <div className="badgeClauseStatus" id="mindfulness1Status">Complete</div>
                        <div className="badgeClauseStart" id="mindfulness1Start">23/8/2023</div>
                        <div className="badgeClauseEnd" id="mindfulness1End">4/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="mindfulness1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 2 - Yoga</div>
                        <div className="badgeClauseStatus" id="mindfulness2Status">Complete</div>
                        <div className="badgeClauseStart" id="mindfulness2Start">1/2/2023</div>
                        <div className="badgeClauseEnd" id="mindfulness2End">16/02/2023</div>
                        <div className="badgeClauseProgress"><progress id="mindfulness2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3 - Gratitude</div>
                        <div className="badgeClauseStatus" id="mindfulness3Status">Complete</div>
                        <div className="badgeClauseStart" id="mindfulness3Start">23/8/2022</div>
                        <div className="badgeClauseEnd" id="mindfulness3End">22/9/2022</div>
                        <div className="badgeClauseProgress"><progress id="mindfulness3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3 - Creativity</div>
                        <div className="badgeClauseStatus" id="mindfulness4Status">Complete</div>
                        <div className="badgeClauseStart" id="mindfulness4Start">23/8/2022</div>
                        <div className="badgeClauseEnd" id="mindfulness4End">4/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="mindfulness4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 4 - Indulge</div>
                        <div className="badgeClauseStatus" id="mindfulness5Status">Complete</div>
                        <div className="badgeClauseStart" id="mindfulness5Start">23/8/2022</div>
                        <div className="badgeClauseEnd" id="mindfulness5End">4/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="mindfulness5Progress" value="25" max="100"></progress></div>
                    </div>
                   <div className="badgeProgress"><progress id="mindfulnessProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle green" tabIndex={0} role="button" aria-expanded="false">
                        <div>Reader</div>
                        <div>
                            <progress id="readerProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro green-light">Complete all five clauses <Link to="/reader">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 1 - Read</div>
                        <div className="badgeClauseStatus" id="reader1Status">Complete</div>
                        <div className="badgeClauseStart" id="reader1Start">23/8/2022</div>
                        <div className="badgeClauseEnd" id="reader1End">30/11/2022</div>
                        <div className="badgeClauseProgress"><progress id="reader1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 2 - Topics</div>
                        <div className="badgeClauseStatus" id="reader2Status">Complete</div>
                        <div className="badgeClauseStart" id="reader2Start">23/8/2022</div>
                        <div className="badgeClauseEnd" id="reader2End">25/8/2022</div>
                        <div className="badgeClauseProgress"><progress id="reader2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3 - Review</div>
                        <div className="badgeClauseStatus" id="reader3Status">Complete</div>
                        <div className="badgeClauseStart" id="reader3Start">16/2/2023</div>
                        <div className="badgeClauseEnd" id="reader3End">16/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="reader3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 4 - Critique</div>
                        <div className="badgeClauseStatus" id="reader4Status">Complete</div>
                        <div className="badgeClauseStart" id="reader4Start">16/2/2023</div>
                        <div className="badgeClauseEnd" id="reader4End">16/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="reader4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 5 - Book club</div>
                        <div className="badgeClauseStatus" id="reader5Status">Complete</div>
                        <div className="badgeClauseStart" id="reader5Start">23/8/2022</div>
                        <div className="badgeClauseEnd" id="reader5End">15/9/2022</div>
                        <div className="badgeClauseProgress"><progress id="reader5Progress" value="25" max="100"></progress></div>
                    </div>
                   <div className="badgeProgress"><progress id="readerProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle green" tabIndex={0} role="button" aria-expanded="false">
                        <div>Self-Care</div>
                        <div>
                            <progress id="selfcareProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro green-light">Complete at least four of eight possible clauses <Link to="/selfcare">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 1 - Sleep</div>
                        <div className="badgeClauseStatus" id="selfcare1Status">Complete</div>
                        <div className="badgeClauseStart" id="selfcare1Start">23/8/2022</div>
                        <div className="badgeClauseEnd" id="selfcare1End">23/8/2022</div>
                        <div className="badgeClauseProgress"><progress id="selfcare1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 2 - Bedtime</div>
                        <div className="badgeClauseStatus" id="selfcare2Status">Complete</div>
                        <div className="badgeClauseStart" id="selfcare2Start">23/8/2022</div>
                        <div className="badgeClauseEnd" id="selfcare2End">23/8/2022</div>
                        <div className="badgeClauseProgress"><progress id="selfcare2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3 - Screens</div>
                        <div className="badgeClauseStatus" id="selfcare3Status">Complete</div>
                        <div className="badgeClauseStart" id="selfcare3Start">23/8/2022</div>
                        <div className="badgeClauseEnd" id="selfcare3End">31/10/2022</div>
                        <div className="badgeClauseProgress"><progress id="selfcare3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 4 - Pamper</div>
                        <div className="badgeClauseStatus" id="selfcare4Status">Complete</div>
                        <div className="badgeClauseStart" id="selfcare4Start">23/8/2022</div>
                        <div className="badgeClauseEnd" id="selfcare4End">11/9/2022</div>
                        <div className="badgeClauseProgress"><progress id="selfcare4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 5 - Diet</div>
                        <div className="badgeClauseStatus" id="selfcare5Status">Complete</div>
                        <div className="badgeClauseStart" id="selfcare5Start">23/8/2022</div>
                        <div className="badgeClauseEnd" id="selfcare5End">23/8/2022</div>
                        <div className="badgeClauseProgress"><progress id="selfcare5Progress" value="25" max="100"></progress></div>
                    </div>
                   <div className="badgeProgress"><progress id="selfcareProgress" value="25" max="100"></progress></div>
                </div>
            </div>

            <div className="bookSectionTitle bstbrown">Wild Rebel</div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle brown" tabIndex={0} role="button" aria-expanded="false">
                        <div>Codebreaker</div>
                        <div>
                            <progress id="codebreakerProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro brown-light">Complete at least five of ten possible clauses <Link to="/codebreaker">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 1 - Phonetics</div>
                        <div className="badgeClauseStatus" id="codebreaker1Status">Complete</div>
                        <div className="badgeClauseStart" id="codebreaker1Start">23/8/2022</div>
                        <div className="badgeClauseEnd" id="codebreaker1End">23/8/2022</div>
                        <div className="badgeClauseProgress"><progress id="codebreaker1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 2 - Ciphers</div>
                        <div className="badgeClauseStatus" id="codebreaker2Status">Complete</div>
                        <div className="badgeClauseStart" id="codebreaker2Start">5/2/2023</div>
                        <div className="badgeClauseEnd" id="codebreaker2End">5/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="codebreaker2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3 - Hide</div>
                        <div className="badgeClauseStatus" id="codebreaker3Status">Complete</div>
                        <div className="badgeClauseStart" id="codebreaker3Start">5/2/2023</div>
                        <div className="badgeClauseEnd" id="codebreaker3End">5/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="codebreaker3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 4 - Code wheel</div>
                        <div className="badgeClauseStatus" id="codebreaker4Status">Complete</div>
                        <div className="badgeClauseStart" id="codebreaker4Start">5/2/2023</div>
                        <div className="badgeClauseEnd" id="codebreaker4End">5/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="codebreaker4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 5 - Puzzle book</div>
                        <div className="badgeClauseStatus" id="codebreaker5Status">Complete</div>
                        <div className="badgeClauseStart" id="codebreaker5Start">23/8/2022</div>
                        <div className="badgeClauseEnd" id="codebreaker5End">5/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="codebreaker5Progress" value="25" max="100"></progress></div>
                    </div>
                   <div className="badgeProgress"><progress id="codebreakerProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle brown" tabIndex={0} role="button" aria-expanded="false">
                        <div>Stargazer</div>
                        <div>
                            <progress id="stargazerProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro brown-light">Complete all eight clauses <Link to="/stargazer">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 1 - Observe</div>
                        <div className="badgeClauseStatus" id="stargazer1Status">Complete</div>
                        <div className="badgeClauseStart" id="stargazer1Start">23/8/2022</div>
                        <div className="badgeClauseEnd" id="stargazer1End">31/03/2023</div>
                        <div className="badgeClauseProgress"><progress id="stargazer1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 2 - Research</div>
                        <div className="badgeClauseStatus" id="stargazer2Status">Complete</div>
                        <div className="badgeClauseStart" id="stargazer2Start">16/9/2022</div>
                        <div className="badgeClauseEnd" id="stargazer2End">31/03/2023</div>
                        <div className="badgeClauseProgress"><progress id="stargazer2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 3 - Solar system</div>
                        <div className="badgeClauseStatus" id="stargazer3Status">Complete</div>
                        <div className="badgeClauseStart" id="stargazer3Start">15/9/2022</div>
                        <div className="badgeClauseEnd" id="stargazer3End">31/03/2023</div>
                        <div className="badgeClauseProgress"><progress id="stargazer3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 4 - The Moon</div>
                        <div className="badgeClauseStatus" id="stargazer4Status">Complete</div>
                        <div className="badgeClauseStart" id="stargazer4Start">16/9/2022</div>
                        <div className="badgeClauseEnd" id="stargazer4End">16/9/2022</div>
                        <div className="badgeClauseProgress"><progress id="stargazer4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 5 - Constellations</div>
                        <div className="badgeClauseStatus" id="stargazer5Status">Complete</div>
                        <div className="badgeClauseStart" id="stargazer5Start">31/03/2023</div>
                        <div className="badgeClauseEnd" id="stargazer5End">31/03/2023</div>
                        <div className="badgeClauseProgress"><progress id="stargazer5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 6 - Space</div>
                        <div className="badgeClauseStatus" id="stargazer6Status">Complete</div>
                        <div className="badgeClauseStart" id="stargazer6Start">31/03/2023</div>
                        <div className="badgeClauseEnd" id="stargazer6End">31/03/2023</div>
                        <div className="badgeClauseProgress"><progress id="stargazer6Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 7 - Compass</div>
                        <div className="badgeClauseStatus" id="stargazer7Status">Complete</div>
                        <div className="badgeClauseStart" id="stargazer7Start">31/03/2023</div>
                        <div className="badgeClauseEnd" id="stargazer7End">31/03/2023</div>
                        <div className="badgeClauseProgress"><progress id="stargazer7Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Task 8 - Visit</div>
                        <div className="badgeClauseStatus" id="stargazer8Status">Complete</div>
                        <div className="badgeClauseStart" id="stargazer8Start">31/03/2023</div>
                        <div className="badgeClauseEnd" id="stargazer8End">31/03/2023</div>
                        <div className="badgeClauseProgress"><progress id="stargazer8Progress" value="25" max="100"></progress></div>
                    </div>
                   <div className="badgeProgress"><progress id="stargazerProgress" value="25" max="100"></progress></div>
                </div>
            </div>
        </div>
    )
}

function listenFunc(element)
{
    var elementIntro = element.parentElement.querySelector(".badgeItemIntro");
    var elementClause = element.parentElement.querySelectorAll(".badgeClause");
    var elementProgress = element.parentElement.querySelector(".badgeProgress");

    if(element.getAttribute("aria-expanded") === "false")
    {
        element.setAttribute("aria-expanded", "true");
    }
    else
    {
        element.setAttribute("aria-expanded", "false");
    }

    if(elementIntro.classList.contains("hidden"))
    {
        elementIntro.classList.remove("hidden");
        elementIntro.classList.add("shown");
    } else {
        elementIntro.classList.remove("shown");
        elementIntro.classList.add("hidden");
    }

    elementClause.forEach((ec) => {
        if(ec.classList.contains("hidden"))
        {
            ec.classList.remove("hidden");
            ec.classList.add("shown");
        } else {
            ec.classList.remove("shown");
            ec.classList.add("hidden");
        }
    })
    

    if(elementProgress.classList.contains("hidden"))
    {
        elementProgress.classList.remove("hidden");
        elementProgress.classList.add("shown");
    } else {
        elementProgress.classList.remove("shown");
        elementProgress.classList.add("hidden");
    }
}

function addListener(element)
{
    element.addEventListener("click",function(){
        listenFunc(element);
    })

    element.addEventListener("keydown", function(e){
    if (e.key !== undefined){
        if(e.key === "Enter" || e.key === " " || e.key === "ArrowDown" || e.key === "ArrowUp" || e.key === "ArrowLeft" || e.key === "ArrowRight")
        {
            listenFunc(element);
        }
    } else if (e.which !== undefined) {
        if(e.key === 13 || e.key === 32 || e.key === 40 || e.key === 38 || e.key ===37 || e.key === 39)
        {
            listenFunc(element);
        }
    }
    })
}

export default Book