import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const ExerciseJan23 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Exercise Every Day"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Exercise Every Day</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">For the January 2023 challenge badge</div>
                    <div className="badgePageItemEvidence"> 
                       This  was supposed to happen in January, but unfortunately I started the year ill, so I am doing this badge in Feb 2023.
                       <br/><br/>
                       So far so good, check back at the end of the month to see if I made it!
                       <br/><br/>
                       Update: 04/03/2023
                       <br/>
                       I did make it!!! Throughout the month I did a combination of 1.5hour walks at the weekends and half an hour a day on the Wii Fit during the week after work.
                       <br/>
                        Just got to keep it up now.                       
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExerciseJan23