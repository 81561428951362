import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const  SecondBirthday = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Rebel 2nd Birthday"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Complete Two Badges from Book Two</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>This is well evidenced across this site!</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Do a badge with TWO (or more) other Rebels</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence"> 

                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Attend two rebel meetups</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>I have now attended two Rebel Fests online.</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Design a Rebel themed birthday cake and either make a prototype from paper, or bake and decorate it.</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence"> 

                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Do the hokey cokey with as many people as you can!</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence"> 

                    </div>
                </div>
            </div>
        </div>
    )
}

export default SecondBirthday