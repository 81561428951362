import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Crown1 from '../images/cup-aug24-1.jpg';
import Crown2 from '../images/cup-aug24-2.jpg';
import Crown3 from '../images/cup-aug24-3.jpg';
import Crown4 from '../images/cup-aug24-4.jpg';
import Crown5 from '../images/cup-aug24-5.jpg';
import Crown6 from '../images/cup-aug24-6.jpg';
import Crown7 from '../images/cup-aug24-7.jpg';
import Crown8 from '../images/cup-aug24-8.jpg';
import Crown9 from '../images/cup-aug24-9.jpg';
import Crown10 from '../images/cup-aug24-10.jpg';
import Crown11 from '../images/cup-aug24-11.jpg';
import Crown12 from '../images/cup-aug24-12.jpg';
import Crown13 from '../images/cup-aug24-13.jpg';
import Crown14 from '../images/cup-aug24-14.jpg';


const CupAug24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Rebel Cup Bonus Points"} />
            <div className="badgePageItem">Rebel Cup challenges are only posted in the facebook group for confirmed members. You will have to join the club to find out why I am doing these things!</div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Bonus 1 - Crown</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light"></div>
                    <div className="badgePageItemEvidence"> 
                      <p>Here is my metal work crown for August cup points</p>
                      <div className="gallery">
                        <div className="galleryItem"><img src={Crown1} alt="Process of making a twisted metal crown"/></div>
                        <div className="galleryItem"><img src={Crown2} alt="Process of making a twisted metal crown"/></div>
                        <div className="galleryItem"><img src={Crown3} alt="Process of making a twisted metal crown"/></div>
                        <div className="galleryItem"><img src={Crown4} alt="Process of making a twisted metal crown"/></div>
                        <div className="galleryItem"><img src={Crown5} alt="Process of making a twisted metal crown"/></div>
                        <div className="galleryItem"><img src={Crown6} alt="Process of making a twisted metal crown"/></div>
                        <div className="galleryItem"><img src={Crown7} alt="Process of making a twisted metal crown"/></div>
                        <div className="galleryItem"><img src={Crown8} alt="Process of making a twisted metal crown"/></div>
                        <div className="galleryItem"><img src={Crown9} alt="Process of making a twisted metal crown"/></div>
                        <div className="galleryItem"><img src={Crown10} alt="Process of making a twisted metal crown"/></div>
                        <div className="galleryItem"><img src={Crown11} alt="Process of making a twisted metal crown"/></div>
                        <div className="galleryItem"><img src={Crown12} alt="Process of making a twisted metal crown"/></div>
                        <div className="galleryItem"><img src={Crown13} alt="Completed metal work crown with bow and bead decorations"/></div>
                        <div className="galleryItem"><img src={Crown14} alt="metal work crown being worn on colourful hair!"/></div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CupAug24