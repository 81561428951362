import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Fairy from '../images/causemay23-1.jpg';
import Garden from '../images/causemay23-2.jpg';

const CauseMay23 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Rebel with a Cause - May 2023"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Clause 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light">Make a positive impact for a charity of cause of your choice.</div>
                    <div className="badgePageItemEvidence"> 
                        <p>My cause is long standing and ongoing due to my commitment to beach cleaning and keeping the local area looking clean and tidy.</p>                                          
                        <br/>
                        <p>The best place to track this and see my evidence is through my website <a href="https://www.seashorty.co.uk" className="linkUnderline" target="_blank" rel="noopener noreferrer">www.seashorty.co.uk</a> or through my social media channels.</p>
                        <br/><br/>
                        <p>In the spirit of badges and making sure I do something new, I have also done the following this month:</p>
                        <ul className="list">
                            <li>Joined the Book Fairies cause, to distribute free books to the community. This was a cost of £20 to the cause.</li>
                            <li>Twinned my garden through Ripple Effect garden twinning. This will benefit families in rural Kenya and provides me with resources to make my own garden more useful and productive. This was a donation of £60 to the charity.</li>
                        </ul>
                        <br/>
                        <img className="imgHalf" src={Fairy} alt="Book Fairy stickers and bookmarks" />
                        <img className="imgHalf" src={Garden} alt="Garden Twinning wooden plaque and resources" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CauseMay23