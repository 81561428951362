import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Outfit1 from '../images/outfit1.jpg';
import Outfit2 from '../images/outfit2.jpg';
import Outfit3 from '../images/outfit3.jpg';
import Outfit4 from '../images/outfit4.jpg';
import Outfit5 from '../images/outfit5.jpg';
import Outfit6 from '../images/outfit6.jpg';
import Outfit7 from '../images/outfit7.jpg';
import Outfit8 from '../images/outfit8.jpg';
import Outfit10 from '../images/outfit10.jpg';
import Outfit11 from '../images/outfit11.jpg';
import Outfit12 from '../images/outfit12.jpg';
import Outfit13 from '../images/outfit13.jpg';
import Outfit14 from '../images/outfit14.jpg';
import Outfit15 from '../images/outfit15.jpg';
import Outfit16 from '../images/outfit16.jpg';
import Outfit17 from '../images/outfit17.jpg';
import Outfit18 from '../images/outfit18.jpg';
import Outfit19 from '../images/outfit19.jpg';
import Outfit20 from '../images/outfit20.jpg';
import Outfit21 from '../images/outfit22.jpg';
import Outfit22 from '../images/outfit23.jpg';
import Outfit23 from '../images/outfit24.jpg';
import Outfit24 from '../images/outfit25.jpg';
import Outfit25 from '../images/outfit26.jpg';
import Outfit26 from '../images/outfit27.jpg';
import Outfit27 from '../images/outfit28.jpg';
import Outfit28 from '../images/outfit29.jpg';
import Outfit29 from '../images/outfit30.jpg';
import Outfit30 from '../images/outfit31.jpg';
import OutfitA from '../images/outfitA.jpg';
import MyStyle1 from '../images/mystyle1.jpg';
import MyStyle2 from '../images/mystyle2.jpg';
import MyStyle3 from '../images/mystyle3.jpg';
import MyStyle4 from '../images/mystyle4.jpg';
import MyStyle5 from '../images/mystyle5.jpg';
import MyStyle6 from '../images/mystyle6.jpg';
import MyStyle7 from '../images/mystyle7.jpg';
import MyStyle8 from '../images/mystyle8.jpg';

const MyStyle = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"My Style"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">01</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light">Months worth of outfits</div>
                    <div className="badgePageItemEvidence">
                        <div className="styleGallery">
                            <div className="styleItem"><img src={Outfit1} alt="Summer outfit laid out on a bed" /></div>
                            <div className="styleItem"><img src={Outfit2} alt="Summer outfit laid out on a bed" /></div>
                            <div className="styleItem"><img src={Outfit3} alt="Summer outfit laid out on a bed" /></div>
                            <div className="styleItem"><img src={Outfit4} alt="Summer outfit laid out on a bed" /></div>
                            <div className="styleItem"><img src={Outfit5} alt="Summer outfit laid out on a bed" /></div>
                            <div className="styleItem"><img src={Outfit6} alt="Summer outfit laid out on a bed" /></div>
                            <div className="styleItem"><img src={Outfit7} alt="Summer outfit laid out on a bed" /></div>
                            <div className="styleItem"><img src={Outfit8} alt="Summer outfit laid out on a bed" /></div>
                            <div className="styleItem"><img src={Outfit10} alt="Summer outfit laid out on a bed" /></div>
                            <div className="styleItem"><img src={Outfit11} alt="Summer outfit laid out on a bed" /></div>
                            <div className="styleItem"><img src={Outfit12} alt="Summer outfit laid out on a bed" /></div>
                            <div className="styleItem"><img src={Outfit13} alt="Summer outfit laid out on a bed" /></div>
                            <div className="styleItem"><img src={Outfit14} alt="Summer outfit laid out on a bed" /></div>
                            <div className="styleItem"><img src={Outfit15} alt="Summer outfit laid out on a bed" /></div>
                            <div className="styleItem"><img src={Outfit16} alt="Summer outfit laid out on a bed" /></div>
                            <div className="styleItem"><img src={Outfit17} alt="Summer outfit laid out on a bed" /></div>
                            <div className="styleItem"><img src={Outfit18} alt="Summer outfit laid out on a bed" /></div>
                            <div className="styleItem"><img src={Outfit19} alt="Summer outfit laid out on a bed" /></div>
                            <div className="styleItem"><img src={Outfit20} alt="Summer outfit laid out on a bed" /></div>
                            <div className="styleItem"><img src={Outfit21} alt="Summer outfit laid out on a bed" /></div>
                            <div className="styleItem"><img src={Outfit22} alt="Summer outfit laid out on a bed" /></div>
                            <div className="styleItem"><img src={Outfit23} alt="Summer outfit laid out on a bed" /></div>
                            <div className="styleItem"><img src={Outfit24} alt="Summer outfit laid out on a bed" /></div>
                            <div className="styleItem"><img src={Outfit25} alt="Summer outfit laid out on a bed" /></div>
                            <div className="styleItem"><img src={Outfit26} alt="Summer outfit laid out on a bed" /></div>
                            <div className="styleItem"><img src={Outfit27} alt="Summer outfit laid out on a bed" /></div>
                            <div className="styleItem"><img src={Outfit28} alt="Summer outfit laid out on a bed" /></div>
                            <div className="styleItem"><img src={Outfit29} alt="Summer outfit laid out on a bed" /></div>
                            <div className="styleItem"><img src={Outfit30} alt="Summer outfit laid out on a bed" /></div>
                        </div>
                        <br/><br/>
                        As a bonus, have a photo of my favourite heels that I don't get to wear anymore!!
                        <br/>
                        <img className="imgHalf" src={OutfitA} alt="Heeled shoes displayed on boxes" />
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">02</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light">What do you wear most</div>
                    <div className="badgePageItemEvidence">
                        I wear only a very small percentage of my wardrobe currently. I have lots of nice clothes but nowhere to wear them!
                        <br/><br/>
                        Black is my dominant colour, but I am getting better at adding colour.
                        <br/><br/>
                        Throughout the summer when these were taken I was mostly living in crops and shorts with a vest top.
                        <br/><br/>
                        I like skinny bottoms and big tops!! Makes me feel comfortable as I'm not very happy with my weight at the moment. When I lose weight I get happier in more close-fitting things.
                        <br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">03</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light">Impact of your style</div>
                    <div className="badgePageItemEvidence">
                        I don't have a style. I wear whatever takes my fancy at any given time and have a number of different styles of clothing. It will depend largely on my mood, what I am dressing for and who I will be with.
                        <br/><br/>
                        Eclectic maybe.
                        <br/><br/>
                        It doesn't make me feel any kind of way! I know that I do sometimes dress differently to other people my age, I always look at others and think how lovely they always look and feel that maybe I don't look as nice, but honestly I am me and I look like me.
                        <br/><br/>
                        I don't know what messages my looks share with others. Probably that I'm trying to be younger than I am! If I'm wearing alternative clothing I get a lot of attention, but I don't think that sends any other message than it's different.
                        <br/><br/>
                        I do tend to wear a lot of black, some people find that intimidating, others see it as a power colour.
                        <br/><br/>
                        If I'm wearing short things, my tattoos are on display and those get very mixed reactions. I cover them for more formal looks.
                        <br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">04</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light">Styles you love</div>
                    <div className="badgePageItemEvidence">
                        I've never really been a fashion lover, I've never really followed the trends. I've spent quite some time tonight browsing through websites, pInterest and magazines and I really struggled to find anything I "love", certainly beyond those stles I already embrace.
                        <br/><br/>
                        I had a free year's subscription to Cafeyne which gives me access to different magazines, so before it expires I looked at these:
                        <br/>
                        <ul className="list">
                            <li>Elle UK - Feb 2023</li>
                            <li>Cosmopolitan UK - Jan 23</li>
                            <li>Stylist UK - Dec 2022</li>
                            <li>Red - Feb 2023</li>
                            <li>Harper's Bazaar UK - Jan 2023</li>
                            <li>Woman and Home - Feb 2023</li>
                            <li>Platinum Magazine - Jan 2023</li>
                        </ul>
                        <br/>
                        I found one outfit in Platinum that I liked:
                        <br/>
                        <img className="imgQuarter" src={MyStyle8} alt="Outfit as shown in Platinum Magazine" height="400px" width="400px" />
                        <br/><br/>
                        Browsing a general search, I found these:
                        <br/><br/>
                        <div className="styleGallery">
                            <div className="styleItem"> <img src={MyStyle1} alt="" width="400px" height="400px" /></div>
                            <div className="styleItem"> <img src={MyStyle2} alt="" width="400px" height="400px" /></div>
                            <div className="styleItem"> <img src={MyStyle3} alt="" width="400px" height="400px" /></div>
                            <div className="styleItem"> <img src={MyStyle4} alt="" width="400px" height="400px" /></div>
                            <div className="styleItem"> <img src={MyStyle5} alt="" width="400px" height="400px" /></div>
                            <div className="styleItem"> <img src={MyStyle6} alt="" width="400px" height="400px" /></div>
                            <div className="styleItem"> <img src={MyStyle7} alt="" width="400px" height="400px" /></div>
                        </div>
                        <br/><br/>
                        There isn't anything here though that I haven't worn at some point and most of it is quite classic.
                        <br/><br/>
                        Perhaps that's a good sign that I'm true to myself though and wear what I like rather than what I think I should wear.
                        <br/><br/>
                        Last photo, the magazine cover, I may have included just because I love Taraji!! I personally wouldn't do the trouser-less look, but she pulls it off pretty well here! I do like th ebaggy tshirt and jacket though.
                        <br/><br/>
                        Here are the links for the image sources:
                        <br/><br/>
                        <a href="https://static01.nyt.com/images/2016/02/04/fashion/04NOTED3/04NOTED3-articleLarge.jpg?year=2016&h=823&w=600&sig=0x991fd48af3757f37aa575e5c5be3d7df&tw=1">nyt.com</a>
                        <a href="https://allforfashiondesign.com/wp-content/uploads/2013/12/o-16-600x900.jpg">allforfashion.com</a>
                        <a href="https://ae01.alicdn.com/kf/HTB1Hqd1JpXXXXXHXXXXq6xXFXXXT/High-Quality-Russian-Fashion-Designer-2018-Women-Autumn-Winter-Floral-Printed-Trench-Long-Sleeve-Medium-long.jpg">alicdn.com</a>
                        <a href="https://worthminer.com/wp-content/uploads/2017/09/Outfit-5.jpg">worthminer.com</a>
                        <a href="https://www.stylesgap.com/wp-content/uploads/2016/11/LATEST-PAKISTANI-CAPE-STYLE-DRESSES-FOR-FORMAL-WEAR-3-759x1024.jpg">stylesgap.com</a>
                        <a href="https://glorytrends.com/wp-content/uploads/2020/05/women-clothes-2021.jpg">glorytrends.com</a>
                        <a href="https://www.outfittrends.com/wp-content/uploads/2015/02/9ee44141d3a432beae5243b0c657617e.jpg">outfittrends.com</a>

                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">05</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light">Review wardrobe, create a wishlist</div>
                    <div className="badgePageItemEvidence">
                        There isn't really anything that I need to do to my current wardrobe to achieve my favourite looks, I could re-create most of these fairly closely.
                        <br/><br/>
                        If I had to put anything on my wishlist it would be these:
                        <br/>
                        <ul className="list">
                            <li>Biker jacket</li>
                            <li>Denim jacket</li>
                            <li>More "grown up" tops, rather than my usual tshirts!</li>
                            <li>I do need new skinny jeans, with and without rips!</li>
                            <li>Heels, heels and more heels!</li>
                        </ul>
                        <br/><br/>
                        Most of all though, I need to lose a little weight on my fitness badge so I can feel comfortable in my favourite styles!
                        <br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">06</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light">Donate unwanted styles to charity</div>
                    <div className="badgePageItemEvidence">
                        This is an exercise that I have already done recently. I donated 7 bags to charity that included a lot of items that I was no longer wearing.
                        <br/><br/>
                        Maybe there are more, but due to the pandemic there hasn't been anywhere to go to wear a lot of it, so some things have been given a temporary reprieve!
                        <br/><br/>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyStyle