import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Community1 from '../images/bronze-community1.png';
import Community2 from '../images/bronze-community2.png';
import Community3 from '../images/bronze-community3.png';
import Community4 from '../images/bronze-community4.png';
import Community5 from '../images/bronze-community5.png';
import Community6 from '../images/bronze-community6.png';
import Community7 from '../images/bronze-community7.jpg';

const MyCommunity = () => {
    return (
        <div>
            <ActionBar  area={"bronze"} />
            <EvidenceHeader title={"My Community"} />
            <div className="mavPageItem">
                <div className="mavPageItemTitle blue">My Community</div>
                <div className="mavPageItemContent">
                    <div className="mavPageItemInfo blue-light">Over a period of at least six months complete at least 30 hours of volunteer work.</div>
                    <div className="mavPageItemEvidence">
                        <p>My community service comes in the form of beach cleaning and litter picking and is something that I do regularly both solo and as a group leader.</p>
                        <p>During the course of this, I collect a lot of data which I then make available publicly and provide to national and international organisations to contribute to community marine conservation projects.</p>
                        <br/>
                        <hr/>
                        <br/>
                        <strong>Solo cleaning</strong>
                        <br/>
                        <p>I solo clean under the handle "Seashorty". When solo cleaning I take photos of everything that I pick up. When I get home I then upload them to the laptop, every photograph is tagged with Author, Copyright, Location and various categories. I have a script that automates some of this for me but I sitll manually review all the photos and update the tags. Once that is done, I have another script that extracts all the image data and puts it in a spreadsheet so I can prepare stats.</p>
                        <br/>
                        <p>I take an overview photo of everything I have found laid out in a square (insta friendly!). Every photo is then uploaded to Flickr, they are then uploaded to OpenLitterMap where they are all individually tagged into that system. Lastly, I updated the back-end website database with the stats.</p>
                        <br/>
                        <p>Finally, I updated my social media channels with the day's finds and highlight anything strange, unusual or anything that might be reunited with someone!</p>
                        <br/>
                        <p>This all takes around 1-4 hours at least, given that I can collect anything from 40 items to 400 items, so is a labour of love and can be very time consuming! Worth it for the data contribution though.</p>
                        <br/>
                        <p>You can see the result of all that work here:</p>
                        <ul className="mavList">
                        <li><a href="https://seashorty.co.uk/" target="_blank" rel="noopener noreferrer">Seashorty website</a></li>
                        <li><a href="https://seashorty.co.uk/stats.aspx" target="_blank" rel="noopener noreferrer">Seashorty stats</a></li>
                        <li><a href="https://www.flickr.com/photos/seashorty/sets/72157709285986612/" target="_blank" rel="noopener noreferrer">Flickr</a></li>
                        <li><a href="https://openlittermap.com/global?lat=50.731570459739714&lon=-1.725135647035974&zoom=15.3" target="_blank" rel="noopener noreferrer">OpenLitterMap</a></li>
                        </ul>
                        <br/><br/>
                        <p><strong>In the time covered under this award I have:</strong></p>
                        <br/>
                        <ul className="mavList">
                            <li>Completed 45 solo beach cleans</li>
                            <li>Collected 7327 pieces of litter weighing in at 75.18kg</li>
                        </ul>
                        <br/><br/>
                        <hr/>
                        <br/>
                        <strong>Group Events</strong>
                        <br/>
                        <p>I run three beach clean / litter picking groups:</p>
                        <ul className="mavList">
                            <li><a href="https://mudefordquaycleanup.org.uk/" target="_blank" rel="noopener noreferrer">Mudeford Quay Cleanup Crew - meets one a month for 2 hours</a></li>
                            <li><a href="https://highcliffebeachcleanteam.org.uk/" target="_blank" rel="noopener noreferrer">Highcliffe Beach Clean Team - meets once a quarter for 2 hours</a></li>
                            <li><a href="https://friarscliffbeachcare.org.uk/" target="_blank" rel="nooprnrt noreferrer">Friars Cliff Beach Care - meets once a quarter for 2 hours</a></li>
                        </ul>
                        <br/>
                        <p>Mudeford is my baby! I run that on my own, provide my own kit and have my own insurance cover. I have a little hand cart that I use to transport the kit to the location. I'm on site at least 15 mins before a clean, often more, then leave when I've cleared up.</p>
                        <br/>
                        <p>For the other two, I have a team of 2 who join me - my brother and a very kind friend who volunteered to join as they are a first aider which is required for insurance cover for these groups. These are supported by the Council and by a local organisation called Litter Free Dorset, who kindly provide the insurance cover and have also funded swing signs for me.  We collect equipment from a store that is provided by the Council - I am well known to them now so they make sure I have independent access! We then head to the location half an hour before the start to prepare.</p>
                        <br/>
                        <p>We provide re-usable bags to attendees and at the end of each event I weigh every bag, the consolidate into as few black bags as possible and dispose of in the Council bins.</p>
                        <br/>
                        <p>It doesn't stop there, once I get home I then calculate all the attendance and stats figures, email a thank you out to the mailing list and update the social channels and the website with the latest information.</p>
                        <br/>
                        <p>As well as undertaking the physical work, there is a lot of administration to organise and co-ordinate between other organisations to get approval for events as well as communications through mailing lists and social media. This takes a lot of time to sort out each year, but I do it all a year in advance so I just have to deal with any changes as they pop up!</p>
                        <br/>
                        <br/>
                        <p>I also try to advocate for the community and ensure that events are accessible to everyone. For example, new guidelines came out from Litter Free Dorset about holding beach cleans and litter picks.</p>
                        <br/>
                        <p>The guidance stated that everyone should: 1. meet together at the beginning of an event, there's a big safety talk, then everyone starts. 2. Only attend in pairs</p>
                        <br/>
                        <p>I pushed back against this because I prefer to run events in a 2 hour window, allowing people to turn up and leave whenever they like in that time. A lot of people are regulars so don't need safety talks every time - they know it all as well as I do - and for new people, I give out advice as needed. For some people, 2 hours of litter picking is too much, if someone wants to come and do 10 minutes I want them to be able to do that. Maybe they need company for a while, maybe they need the fresh air or exercise, maybe they aren't physically able to be active for the whole period - no one should be excluded</p>
                        <br/>
                        <p>I started my beach clean endeavours attending an event on my own and I have many people who do come on their own. I don't want to deter anyone because they don't have anyone to attend with or may not be comfortable being paired with a stranger. It is perfectly possible for people to solo clean safely and the team are always on site and available to help when required.</p>
                        <br/><br/><br/>
                        <p><strong>In the period covered by this award I have:</strong></p>
                        <ul className="mavList">
                            <li>Hosted 11 group beach cleans and litter picks</li>
                            <li>Welcomed 202 volunteers in total</li>
                            <li>Removed 231.65kg of litter from the local seafronts</li>
                        </ul>
                        <br/><br/>
                        <hr/>
                        <p>On 19th November 2022, I spent a few hours coming up with a schedule for the year. This typically means creating a calendar consisting of weekends, blocking out those to avoid and trying to find slots for everything that works with each group's schedules as well as various annual / bi-annual events to get involved in!</p>
                        <br/>
                        <img className='imgHalf' src={Community1} alt="word document showing a calendar schedule with some dates scheduled" />
                        <br/><br/>
                        <p>Then starts a process of negotiation and scheduling!</p>
                        <ol className="mavList">
                            <li>When I've come up with what looks like a sensible plan, I then comtact my team-mates to make sure the dates work for them too</li>
                            <li>When we have all agreed, I contact the Council to request approval for the dates/times</li>
                            <li>I also notify Litter Free Dorset of the agreed dates for the two quarterly groups as they provide my insurance cover</li>
                            <li>When all of that is agreed, I start producing risk assessments and procuring copies of relevant insurance policies, which are then sent to the Council</li>
                            <li>The Council then provides me with a Letter of Agreement containing all my dates - this ensures that I have proof of authorisation to be at a location if anyone should challenge me (trust me, it happens!)</li>
                            <li>Next, I have to come up with my artwork for the year - all the social channels need recognisable branding for events and a dates list to be pinned to profiles</li>
                            <li>Once I have produced those, I create all the Facebook events - I don't actively post in these usually but it helps for visibility</li>
                            <li>All the socials get updated with the new batch of dates</li>
                            <li>The websites get updated for the new year - this includes dates as well as updating the links to the risk assesment, insurance and LoA documents</li>
                            <li>Finally, I send a notification to each mailing list with their schedule for the year</li>
                        </ol>
                        <br/>
                        Here are some samples of my dates images and a folder containing all the artwork pre-prepared for the year for Mudeford, including reminders! I sat and and sorted out all my artwork templates on 31st December - happy new year!!
                        <br/><br/>
                        <div className="challengeGallery">
                            <img src={Community2} alt="" className="imgQuarter" />
                            <img src={Community3} alt="" className="imgQuarter" />
                            <img src={Community4} alt="" className="imgQuarter" />
                            <img src={Community5} alt="" className="imgQuarter" />
                            <img src={Community6} alt="" className="imgHalf" />
                        </div>
                        <br/>
                        <p>I'm sure I've forgotten something! I</p>
                        <br/><br/>
                        <p>Here is my advert in the local "Mudeford Mag" publication. They are really good and as I'm a volunteer and this is community work, they let me advertise for free.</p>
                        <br/>
                        <img src={Community7} alt="beach Clean dates listed on a magazine page" className="imgHalf" />
                        <br/><br/>
                        <p>After February's group clean I was chatting to one of our local Councillors and they have since provided me with black bags and we are following up on hi-vis vests as they are often an insurance requirement.</p>
                        <br/><br/>
                        <p>I have had several new contacts asking about attending so 2023 is looking good! I just need to find a buddy to help me at Mudeford!</p>
                        <br/><br/>
                        <p><strong>In the period covered by this award I have:</strong></p>
                        <ul className="mavList">
                            <li>Organised all of the 2023 beach cleans, including getting approvals, creating events and updating all social media.</li>
                        </ul>
                        <br/><br/>
                        <hr/>
                        <p>Here is a record of my hours:</p>
                        <br/><br/>
                        <table>
                            <thead>
                                <tr>
                                    <td>Date</td>
                                    <td>Activity</td>
                                    <td>Time Spent</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>19/08/2022</td>
                                    <td>Solo beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>20/08/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>21/08/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>26/08/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>27/08/2022</td>
                                    <td>Mudeford Quay Cleanup Crew monthly litter pick</td>
                                    <td>2 hours</td>
                                </tr>
                                <tr>
                                    <td>28/08/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>02/09/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>03/09/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>04/09/2022</td>
                                    <td>Friars Cliff Beach Care quarterly beach clean</td>
                                    <td>2 hours</td>
                                </tr>
                                <tr>
                                    <td>09/09/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>10/09/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>24/09/2022</td>
                                    <td>Mudeford Quay Cleanup Crew monthly litter pick</td>
                                    <td>2 hours</td>
                                </tr>
                                <tr>
                                    <td>16/09/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>17/09/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>18/09/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>24/09/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>25/09/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>30/09/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>01/10/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>08/10/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>09/10/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>14/10/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>15/10/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>16/10/2022</td>
                                    <td>Highcliffe Beach Clean Team quarterly beach clean</td>
                                    <td>2 hours</td>
                                </tr>
                                <tr>
                                    <td>28/10/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>29/10/2022</td>
                                    <td>Mudeford Quay Cleanup Crew monthly litter pick</td>
                                    <td>2 hours</td>
                                </tr>
                                <tr>
                                    <td>30/10/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>04/11/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>11/11/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>13/11/2022</td>
                                    <td>Friars Cliff Beach Care quarterly beach clean</td>
                                    <td>2 hours</td>
                                </tr>
                                <tr>
                                    <td>18/11/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>20/11/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>25/11/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>26/11/2022</td>
                                    <td>Mudeford Quay Cleanup Crew monthly litter pick</td>
                                    <td>2 hours</td>
                                </tr>
                                <tr>
                                    <td>27/11/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>09/12/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>16/12/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>17/12/2022</td>
                                    <td>Mudeford Quay Cleanup Crew monthly litter pick</td>
                                    <td>2 hours</td>
                                </tr>
                                <tr>
                                    <td>24/12/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>25/12/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>13/01/2023</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>15/01/2023</td>
                                    <td>Highcliffe Beach Clean Team quarterly beach clean</td>
                                    <td>2 hours</td>
                                </tr>
                                <tr>
                                    <td>21/01/2023</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>22/01/2023</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>28/01/2023</td>
                                    <td>Mudeford Quay Cleanup Crew monthly litter pick</td>
                                    <td>2 hours</td>
                                </tr>
                                <tr>
                                    <td>29/01/2023</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>03/02/2023</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>04/02/2023</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>05/02/2023</td>
                                    <td>Friars Cliff Beach Care quarterly beach clean</td>
                                    <td>2 hours</td>
                                </tr>
                                <tr>
                                    <td>11/02/2023</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>12/02/2023</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyCommunity