import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Scrapheap1 from '../images/Scrapheap1.jpg';
import Scrapheap2 from '../images/Scrapheap2.jpg';
import Scrapheap3 from '../images/Scrapheap3.jpg';
import Scrapheap4 from '../images/Scrapheap4.jpg';
import Scrapheap5 from '../images/Scrapheap5.jpg';
import Scrapheap6 from '../images/Scrapheap6.jpg';
import Scrapheap8 from '../images/Scrapheap8.jpg';
import Scrapheap9 from '../images/Scrapheap9.jpg';
import Scrapheap10 from '../images/Scrapheap10.jpg';
import Scrapheap11 from '../images/Scrapheap11.jpg';
import Scrapheap12 from '../images/Scrapheap12.jpg';
import Scrapheap13 from '../images/Scrapheap13.jpg';
import Scrapheap14 from '../images/Scrapheap14.jpg';
import Scrapheap15 from '../images/Scrapheap15.jpg';
import Scrapheap16 from '../images/Scrapheap16.jpg';
import Scrapheap17 from '../images/Scrapheap17.jpg';
import Scrapheap18 from '../images/Scrapheap18.jpg';
import Scrapheap19 from '../images/Scrapheap19.jpg';
import Scrapheap20 from '../images/Scrapheap20.jpg';
import Scrapheap21 from '../images/Scrapheap21.jpg';
import Scrapheap22 from '../images/Scrapheap22.jpg';
import Scrapheap23 from '../images/Scrapheap23.jpg';
import Scrapheap24 from '../images/Scrapheap24.jpg';
import Scrapheap25 from '../images/Scrapheap25.jpg';


const ScrapheapSep23 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Scrapheap Challenge September 2023"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Challenge</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Check out the syllabus for this one on the <a className="linkUnderline" href="https://www.rebelbadgestore.com/rebelblog/september-challenge-super-scavenger" target="_blank" rel="noopener noreferrer">noticeboard at the Rebel Badge Store website</a>.</div>
                    <div className="badgePageItemEvidence"> 
                            <p>Well, I went real scrapheap with this one! I took my recycling bag and started with a few items, adding more as I went.</p>
                            <p>I made a lamp! Details in a copy of my post to the group below.</p>
                            <br/>     
                            This is my Scrapheap Challenge lamp. I think this might qualify as the daftest thing I've ever made, but it's also kinda cool!! It doesn't quite have the aesthetic I imagined before I started but it's certainly unique 🙈. At one point it crossed my mind that it looked a bit like a Dalek, and now I can't unsee it but I'm told it also looks a bit christmassy. I'm not sure the photos really do it justice! 
                            <br/><br/>The base is an upside down yoghurt pot, it conveniently hides the power pack for the lights and holds it all in place inside.
                            <br/>The base is covered using bits cut out from toilet roll packaging - the ones I get are wrapped in paper.  The "pole" is two toilet roll inserts, one covered using aluminium from a frozen ready meal container, the other covered by a crisp packet turned inside out.
                            <br/><br/>The "shade" is made from two milk cartons - the inside of one was brown and the other silver. I did try painting the brown one, but that was a whole disaster so I undid it rapidly!! It is covered with cups from egg cartons, cut into flowers and painted with white poster paint. I've poked holes through them and the "shade" so the lights shine through. To make the finish a bit cleaner, I added some hole reinforcement rings that I've had probably since school but refuse to throw away as it feels wasteful!
                            <br/><br/>The top is covered in the same materials as the poles, it has the base of the coke bottle on the top, painted with silver acrylic and forming a cap, with an egg carton cup on the top to finish it off.
                            <br/><br/>Inside the shade is a plastic cone made from the coke bottle, this forms the lighting base as the light strip is stuck to it. The LED light strip was in the recycling pile as it has a dodgy connection, so the whole thing is very random, it has a mind of its own and a limited colour range, I've added a video to show that!!
                            <br/><br/>Lastly, it has two butterflies that were made from the plastic of the coke bottle. They are painted with old nail polish and finished with some little beads and wire.
                            <br/><br/>Complete list of items salvaged:
                            <br/>1 x 2l plastic bottle
                            <br/>1 x yoghurt pot
                            <br/>2 x milk cartons
                            <br/>3 x egg cartons
                            <br/>2 x toilet roll inserts
                            <br/>1 x pizza box
                            <br/>1 x led light strip with battery pack
                            <br/>1 x toilet roll packaging
                            <br/>1 x foil baking tray
                            <br/>1 x bag of crisps
                            <br/>1 x Ring Reinforcements
                            <br/><br/>
                            <div className="gallery">
                                <div className="galleryItem"><img src={Scrapheap1} alt="Using recycled items to make a lamp" /></div>
                                <div className="galleryItem"><img src={Scrapheap2} alt="Using recycled items to make a lamp" /></div>
                                <div className="galleryItem"><img src={Scrapheap3} alt="Using recycled items to make a lamp" /></div>
                                <div className="galleryItem"><img src={Scrapheap4} alt="Using recycled items to make a lamp" /></div>
                                <div className="galleryItem"><img src={Scrapheap5} alt="Using recycled items to make a lamp" /></div>
                                <div className="galleryItem"><img src={Scrapheap6} alt="Using recycled items to make a lamp" /></div>
                                <div className="galleryItem"><img src={Scrapheap8} alt="Using recycled items to make a lamp" /></div>
                                <div className="galleryItem"><img src={Scrapheap9} alt="Using recycled items to make a lamp" /></div>
                                <div className="galleryItem"><img src={Scrapheap10} alt="Using recycled items to make a lamp" /></div>
                                <div className="galleryItem"><img src={Scrapheap11} alt="Using recycled items to make a lamp" /></div>
                                <div className="galleryItem"><img src={Scrapheap12} alt="Using recycled items to make a lamp" /></div>
                                <div className="galleryItem"><img src={Scrapheap13} alt="Using recycled items to make a lamp" /></div>
                                <div className="galleryItem"><img src={Scrapheap14} alt="Using recycled items to make a lamp" /></div>
                                <div className="galleryItem"><img src={Scrapheap15} alt="Using recycled items to make a lamp" /></div>
                                <div className="galleryItem"><img src={Scrapheap16} alt="Using recycled items to make a lamp" /></div>
                                <div className="galleryItem"><img src={Scrapheap17} alt="Using recycled items to make a lamp" /></div>
                                <div className="galleryItem"><img src={Scrapheap18} alt="Using recycled items to make a lamp" /></div>
                                <div className="galleryItem"><img src={Scrapheap19} alt="Using recycled items to make a lamp" /></div>
                                <div className="galleryItem"><img src={Scrapheap20} alt="Using recycled items to make a lamp" /></div>
                                <div className="galleryItem"><img src={Scrapheap21} alt="Using recycled items to make a lamp" /></div>
                                <div className="galleryItem"><img src={Scrapheap22} alt="Using recycled items to make a lamp" /></div>
                                <div className="galleryItem"><img src={Scrapheap23} alt="Using recycled items to make a lamp" /></div>
                                <div className="galleryItem"><img src={Scrapheap24} alt="Using recycled items to make a lamp" /></div>
                                <div className="galleryItem"><img src={Scrapheap25} alt="Using recycled items to make a lamp" /></div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScrapheapSep23