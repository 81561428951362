import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import System1 from '../images/stargazer-solarsystem1.jpg';
import System5 from '../images/stargazer-solarsystem5.jpg';
import System6 from '../images/stargazer-solarsystem6.jpg';
import System7 from '../images/stargazer-solarsystem7.jpg';
import System8 from '../images/stargazer-solarsystem8.jpg';
import System9 from '../images/stargazer-solarsystem9.jpg';
import System10 from '../images/stargazer-solarsystem10.jpg';
import System11 from '../images/stargazer-solarsystem11.jpg';
import System12 from '../images/stargazer-solarsystem12.jpg';
import System13 from '../images/stargazer-solarsystem13.jpg';
import System14 from '../images/stargazer-solarsystem14.jpg';
import System15  from '../images/stargazer-solarsystem15.jpg';
import System16  from '../images/stargazer-solarsystem23.jpg';
import Moon1 from '../images/stargazer-moon1.jpg';
import Events1 from '../images/stargazer-events1.jpg';
import Events2 from '../images/stargazer-events2.jpg';
import Events3 from '../images/stargazer-events3.jpg';
import Events4 from '../images/stargazer-events4.jpg';
import Events5 from '../images/stargazer-events5.jpg';
import Events6 from '../images/stargazer-events6.jpg';
import Events7 from '../images/stargazer-events7.jpg';
import Events8 from '../images/stargazer-events8.jpg';
import Events9 from '../images/stargazer-events9.jpg';
import Events10 from '../images/stargazer-events10.jpg';
import Events11 from '../images/stargazer-events11.jpg';
import Events12 from '../images/stargazer-events12.jpg';
import Events13 from '../images/stargazer-events13.jpg';
import Events14 from '../images/stargazer-events14.jpg';
import Events15 from '../images/stargazer-events15.jpg';
import Events16 from '../images/stargazer-events16.jpg';
import Events17 from '../images/stargazer-events17.jpg';
import Events18 from '../images/stargazer-events18.jpg';
import Events19 from '../images/stargazer-events19.jpg';
import Events20 from '../images/stargazer-events20.jpg';
import Events21 from '../images/stargazer-events21.jpg';
import Events22 from '../images/stargazer-events22.jpg';
import Events23 from '../images/stargazer-events23.jpg';
import Events24 from '../images/stargazer-events24.jpg';
import Events25 from '../images/stargazer-events25.jpg';
import Events26 from '../images/stargazer-events26.jpg';
import Events27 from '../images/stargazer-events27.jpg';
import Events28 from '../images/stargazer-events28.jpg';
import Events29 from '../images/stargazer-events29.jpg';
import Events30 from '../images/stargazer-events30.jpg';
import Events31 from '../images/stargazer-events31.jpg';
import Events32 from '../images/stargazer-events32.jpg';
import Events33 from '../images/stargazer-events33.jpg';
import Observer1 from '../images/stargazer-observer1.jpg';
import Observer2 from '../images/stargazer-observer2.jpg';
import Observer3 from '../images/stargazer-observer3.jpg';
import Observer4 from '../images/stargazer-observer4.jpg';
import Observer5 from '../images/stargazer-observer5.jpg';
import Observer6 from '../images/stargazer-observer6.jpg';
import Observer7 from '../images/stargazer-observer7.jpg';
import Observer8 from '../images/stargazer-observer8.jpg';
import App1 from '../images/stargazer-app1.jpg';
import App2 from '../images/stargazer-app2.jpg';
import App3 from '../images/stargazer-app3.jpg';
import App4 from '../images/stargazer-app4.jpg';
import App5 from '../images/stargazer-app5.jpg';
import App6 from '../images/stargazer-app6.jpg';
import App7 from '../images/stargazer-app7.jpg';
import Meme1 from '../images/stargazer-meme1.jpg';
import Timeline from '../images/stargazer-timeline.png';
import Badge1 from '../images/stargazer-badge1.png';
import Drawing1 from '../images/stargazer-drawing1.jpg';
import Drawing2 from '../images/stargazer-drawing2.jpg';
import Drawing3 from '../images/stargazer-drawing3.jpg';
import Drawing4 from '../images/stargazer-drawing4.jpg';
import Drawing5 from '../images/stargazer-drawing5.jpg';
import Drawing6 from '../images/stargazer-drawing6.jpg';
import Drawing7 from '../images/stargazer-drawing7.jpg';
import Drawing8 from '../images/stargazer-drawing8.jpg';
import Drawing9 from '../images/stargazer-drawing9.jpg';

const Stargazer = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Stargazer"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">01 Logbook</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light">Observe</div>
                    <div className="badgePageItemInfo reverse hidden">Over a period of 2 months regularly observe the night sky, at least twice a week. Keep a logbook of the constellations, moon and any planets you see.</div>
                    <div className="badgePageItemEvidence">
                        <p>I have enjoyed stargazing, I did it a lot during the summer of lockdown and had forgotten how amazing it can be!</p>
                        <p>Constellations and other celestial objects visible from the limited and light polluted space in my back garden are:</p>
                        <ul className="list">
                            <li>Orion</li>
                            <li>Ursa Major</li>
                            <li>Mars</li>
                            <li>Gemini</li>
                            <li>Cancer</li>
                        </ul>
                        <br/>
                        <p>I am sure there are more I could have spotted, but these are the ones I noted being able to spot.</p>
                        <br/>
                        <p>My constellation recognition isn't great, so I have to use apps to help me identify what I am looking at. Here are some screenshots of the app that I use, helping me see what is in the sky that I can see, and the things that are too far away for me to see!</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={App1} alt="" /></div>
                            <div className="galleryItem"><img src={App2} alt="" /></div>
                            <div className="galleryItem"><img src={App3} alt="" /></div>
                            <div className="galleryItem"><img src={App4} alt="" /></div>
                            <div className="galleryItem"><img src={App5} alt="" /></div>
                            <div className="galleryItem"><img src={App6} alt="" /></div>
                            <div className="galleryItem"><img src={App7} alt="" /></div>
                            <div className="galleryItem"><img src={App7} alt="" /></div>
                        </div>
                        <br/>
                        <p>I've always known the Big and Little Dippers because of their saucepan shapes! Now though, thanks to the Big Star Count, I can also easily spot Orion!</p>
                        <br/>
                        <p>I signed up for the Big Star Count and downloaded the information aimed at children! </p>
                        <br/>
                        <img src={Observer2} alt="" className="imgThird" />
                        <img src={Observer3} alt="" className="imgThird" />
                        <br/><br/>
                        <p>I duly spent a couple of night looking at Orion and desparately trying to spot as many stars as possible, but sadly I could only really see 5 with the naked eye, which is really bad.</p>
                        <br/>
                        <p>Here are a few photos from my Orion watching:</p>
                        <br/>
                        <div class="gallery">
                            <div className="galleryItem"><img src={Observer4} alt="" /></div>
                            <div className="galleryItem"><img src={Observer5} alt="" /></div>
                            <div className="galleryItem"><img src={Observer6} alt="" /></div>
                        </div>
                        <br/><br/>
                        <p>Looking in the opposite direction, here's Ursa Major:</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Observer7} alt="" /></div>
                            <div className="galleryItem"><img src={Observer8} alt="" /></div>
                        </div>
                        <br/><br/>
                        <p>I love the moon, here it is peeking around the building behind me. I'd love to get a camera that can take good night photos</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Observer1} alt="" /></div>
                        </div>
                        <br/><br/>
                        <p>I also did a course on stargazing with OpenLearn, here's my badge to prove I passed the course!</p>
                        <br/>
                        <img className="imgThird" src={Badge1} alt="" />
                        <br/><br/>

                        <p>I must remember to go out and stargaze more often, but I might wait now until it's a little warmer!</p>
                        <br/><br/>
                        <img className="imgThird" src={Meme1} alt="What if they aren't stars at all, but holes poked in the top of the container so we can breathe." />
                        
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">02 Upcoming Events</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light">Research</div>
                    <div className="badgePageItemInfo reverse hidden">Research any major astronomical events happening in the next few months.</div>
                    <div className="badgePageItemEvidence">
                        These are the events that I have discovered from researching online:
                        <br/>
                        <ul className="list">
                            <li>05/09 : Perseids Meteor Shower, 5-21 September</li>
                            <li>09/09 : Mercury retrograde (see my journal!)</li>
                            <li>10/09 : Full Moon - Harvest Moon</li>
                            <li>14/09 : Lunar occultation of Uranus</li>
                            <li>16/09 : Conjunction of Moon and Mars</li>
                            <li>23/09 : Autumn Equinox (some sources say 22/09)</li>
                            <li>25/09 : Observe some nebulae and a galaxy</li>
                            <li>25/09 : New Moon</li>
                            <li>26/09 : Jupiter at Opposition - lies directly opposite the Sun and visible all night</li>
                            <li>08/10 : Mercury at greatest elongation west - its farthest distance from the Sun in the morning sky</li>
                            <li>89/10 : Draconid Meteor Shower (08 - 09 Sept)</li>
                            <li>09/10 : Full Moon - Hunter's Moon</li>
                            <li>21/10 : Orionid meteor shower</li>
                            <li>25/10 : New Moon</li>
                            <li>07/11 : Total Lunar Eclipse (07-08 Nov)</li>
                            <li>08/11 : Full Moon - Beaver Moon</li>
                            <li>17/11 : Leonid Meteor Shower (17-18 Nov)</li>
                            <li>23/11 : New Moon</li>
                            <li>Generally - Northern Lights</li>
                        </ul>
                        <br/>
                        <p>These are the events I have been tracking through Star Tracker apps on my phone</p>
                        <ul className="list">
                            <li>08 Oct - Jupiter beside the moon </li>
                            <li>22 Oct - Andromeda Galaxy (M31) visible</li>
                            <li>25 Oct - Partial Solar Eclipse</li>
                            <li>25 Oct - New Moon</li>
                            <li>08 Nov - Frost Blood Moon Total Lunar Eclipse</li>
                            <li>11 Nov - Mars visible with a bright moon</li>
                            <li>17 Nov - Leonids Meteor Shower Peaks</li>
                            <li>24 Nov - Jupiter stands still</li>
                            <li>26 Nov - Andromeda Galaxy (M31) visible with binoculars</li>
                            <li>30 Nov - Mars Closest to Earth</li>
                            <li>04 Dec - Uranus beside the moon</li>
                            <li>07 Dec - Full Oak Moon occults Mars</li>
                            <li>19 Dec - Mars beside the Hyades Cluster</li>
                            <li>21 Dec - Mercury at Greatest Eastern Elevation</li>
                        </ul>
                        <br/><br/>
                        Update: March 2023
                        <br/>
                        <ul className="list">
                            <li>January : Quandrantids Meteor Shower</li>
                            <li>Jamuary 30 : Mercury at Greatest Western Elongation</li>
                            <li>05 Feb - Mini Full Snow Moon</li>
                            <li>11 Feb - Comet C/2022 E3 (ZTF)</li>
                            <li>16 Feb - Venus passes Neptune</li>
                            <li>18 Feb - Orion the Hunter</li>
                            <li>20 Feb - New Moon</li>
                            <li>23 Feb - Slim moon near Jupiter and Venus</li>
                            <li>25 Feb - Moon near the Seven Sisters</li>
                            <li>04 Mar - Sirius - the Dog Star</li>
                            <li>06 Mar - Full Worm Moon</li>
                            <li>10 Mar - The Big Dipper</li>
                            <li>16 Mar - Orion the Hunter</li>
                            <li>18 Mar - Slim moon near Saturn</li>
                            <li>20 Mar - March Equinox</li>
                            <li>23 Mar - Young Moon meets Venus</li>
                            <li>27 Mar - Moon Meets Mars</li>
                            <li>29 Mar - Bright moon between Gemini's Twins</li>
                            <li>Northern Lights - update 31/3/2023: the Northern Lights made it to Dorset!!! sadly, I didn't see them </li>
                        </ul>
                        <br/>
                        <img className="imgHalf" src={Events1} alt="The Northern Lights taken over Dorset" />
                        <br/><br/>
                        <p><strong>Gallery of alerts from startracker apps and news feeds</strong></p>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Events2} alt="Alert notification from an app on a mobile device" /></div>
                            <div className="galleryItem"><img src={Events3} alt="Alert notification from an app on a mobile device" /></div>
                            <div className="galleryItem"><img src={Events4} alt="Alert notification from an app on a mobile device" /></div>
                            <div className="galleryItem"><img src={Events5} alt="Alert notification from an app on a mobile device" /></div>
                            <div className="galleryItem"><img src={Events6} alt="Alert notification from an app on a mobile device" /></div>
                            <div className="galleryItem"><img src={Events7} alt="Alert notification from an app on a mobile device" /></div>
                            <div className="galleryItem"><img src={Events8} alt="Alert notification from an app on a mobile device" /></div>
                            <div className="galleryItem"><img src={Events9} alt="Alert notification from an app on a mobile device" /></div>
                            <div className="galleryItem"><img src={Events10} alt="Alert notification from an app on a mobile device" /></div>
                            <div className="galleryItem"><img src={Events11} alt="Alert notification from an app on a mobile device" /></div>
                            <div className="galleryItem"><img src={Events12} alt="Alert notification from an app on a mobile device" /></div>
                            <div className="galleryItem"><img src={Events13} alt="Alert notification from an app on a mobile device" /></div>
                            <div className="galleryItem"><img src={Events14} alt="Alert notification from an app on a mobile device" /></div>
                            <div className="galleryItem"><img src={Events15} alt="Alert notification from an app on a mobile device" /></div>
                            <div className="galleryItem"><img src={Events16} alt="Alert notification from an app on a mobile device" /></div>
                            <div className="galleryItem"><img src={Events17} alt="Alert notification from an app on a mobile device" /></div>
                            <div className="galleryItem"><img src={Events18} alt="Alert notification from an app on a mobile device" /></div>
                            <div className="galleryItem"><img src={Events19} alt="Alert notification from an app on a mobile device" /></div>
                            <div className="galleryItem"><img src={Events20} alt="Alert notification from an app on a mobile device" /></div>
                            <div className="galleryItem"><img src={Events21} alt="Alert notification from an app on a mobile device" /></div>
                            <div className="galleryItem"><img src={Events22} alt="Alert notification from an app on a mobile device" /></div>
                            <div className="galleryItem"><img src={Events23} alt="Alert notification from an app on a mobile device" /></div>
                            <div className="galleryItem"><img src={Events24} alt="Alert notification from an app on a mobile device" /></div>
                            <div className="galleryItem"><img src={Events25} alt="Alert notification from an app on a mobile device" /></div>
                            <div className="galleryItem"><img src={Events26} alt="Alert notification from an app on a mobile device" /></div>
                            <div className="galleryItem"><img src={Events27} alt="Alert notification from an app on a mobile device" /></div>
                            <div className="galleryItem"><img src={Events28} alt="Alert notification from an app on a mobile device" /></div>
                            <div className="galleryItem"><img src={Events29} alt="Alert notification from an app on a mobile device" /></div>
                            <div className="galleryItem"><img src={Events30} alt="Alert notification from an app on a mobile device" /></div>
                            <div className="galleryItem"><img src={Events31} alt="Alert notification from an app on a mobile device" /></div>
                            <div className="galleryItem"><img src={Events32} alt="Alert notification from an app on a mobile device" /></div>
                            <div className="galleryItem"><img src={Events33} alt="Alert notification from an app on a mobile device" /></div>
                        </div>
                        <br/>

                        <strong>Sources:</strong>
                        <ul className="list">
                            <li><a href="https://brightly.eco/september-celestial-events-guide/" target="_blank" rel="noopener noreferrer">https://brightly.eco/september-celestial-events-guide/</a></li>
                            <li><a href="https://www.rmg.co.uk/stories/blog/astronomy/night-sky-highlights-september-2022" target="_blank" rel="noopener noreferrer">https://www.rmg.co.uk/stories/blog/astronomy/night-sky-highlights-september-2022</a></li>
                            <li><a href="https://spacetourismguide.com/september-night-sky/" target="_blank" rel="noopener noreferrer">https://spacetourismguide.com/september-night-sky/</a></li>
                            <li><a href="https://www.timeanddate.com/astronomy/sights-to-see.html" target="_blank" rel="noopener noreferrer">https://www.timeanddate.com/astronomy/sights-to-see.html</a></li>
                            <li><a href="http://www.seasky.org/astronomy/astronomy-calendar-2023.html" target="_blank" rel="noopener noreferrer">http://www.seasky.org/astronomy/astronomy-calendar-2023.html</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">03 Solar System</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light">Learn about the planets</div>
                    <div className="badgePageItemInfo reverse hidden">Learn about the planets - their relative size and positions. Build a model of the solar system.</div>
                    <div className="badgePageItemEvidence">
                        <p>I have been combining learning about the planets with building the solar system as I found a great educational kit that includes cards with planet facts along with everything needed to make a model of the solar system.</p>
                        <br/>
                        <img src={System1} alt="Planet information cards laid out in a line on a table" />
                        <br/><br/>
                        <p>The reverse side of the cards tells you what you need to make your planet and a template for the size it should be so that you can ensure that everything is to scale</p>
                        <br/>
                        <img src={System1} alt="Planet information cards laid out in a line on a table" />
                        <br/><br/>
                        <p>Here are my "in progress" photos of creating my solar system!</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={System5} alt="Planet made from air-drying clay being shown on a template card for scale" /></div>
                            <div className="galleryItem"><img src={System6} alt="Planet made from air-drying clay being shown on a template card for scale" /></div>
                            <div className="galleryItem"><img src={System7} alt="Planet made from air-drying clay being shown on a template card for scale" /></div>
                            <div className="galleryItem"><img src={System8} alt="Planet made from air-drying clay being shown on a template card for scale" /></div>
                            <div className="galleryItem"><img src={System9} alt="Planet made from air-drying clay being shown on a template card for scale" /></div>
                            <div className="galleryItem"><img src={System10} alt="Planet made from air-drying clay being shown on a template card for scale" /></div>
                            <div className="galleryItem"><img src={System11} alt="Planet made from air-drying clay being shown on a template card for scale" /></div>
                            <div className="galleryItem"><img src={System12} alt="Planet made from air-drying clay being shown on a template card for scale" /></div>
                            <div className="galleryItem"><img src={System13} alt="Planet made from air-drying clay being shown on a template card for scale" /></div>
                            <div className="galleryItem"><img src={System14} alt="Planet made from air-drying clay being shown on a template card for scale" /></div>
                        </div>
                    </div>
                    <br/><br/>
                    <p>And here is the finished product:</p>
                    <br/>
                    <img className="imgHalf" src={System15} alt="Scale model of the solar system made from a kit with air-drying clay." />                    
                    <img className="imgHalf" src={System16} alt="Scale model of the solar system made from a kit with air-drying clay." />                    
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">04 The Moon</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light">Phases of the moon</div>
                    <div className="badgePageItemInfo reverse hidden">Learn about the phases of the moon and the effect it has on the Earth.</div>
                    <div className="badgePageItemEvidence">
                        The moon and its phases are something I am acutely aware of and have been for years!!  My sleep - at least - gets affected by the phase of the moon, with strangeness usually starting around a week before full moon.
                        <br/><br/>
                        I use an app on my phone to track the moon phases!
                        <br/><br/>
                        The phases of the moon are:
                        <br/>
                        <ul className="list">
                            <li>New</li>
                            <li>Waxing crescent</li>
                            <li>First quarter</li>
                            <li>Waxing gibbous</li>
                            <li>Full</li>
                            <li>Waning gibbous</li>
                            <li>Third quarter</li>
                            <li>Waning crescent</li>
                        </ul>
                        <br/>
                        Source: <a href="https://spaceplace.nasa.gov/moon-phases/en/" target="_blank" rel="noopener noreferrer">https://spaceplace.nasa.gov/moon-phases/en/</a>
                        <br/><br/>
                        The moon has a huge impact on the planet and living things. The planet could exist without it, but it would be a very different place!
                        <br/><br/>
                        The moon has an impact on:
                        <br/>
                        <ul className="list">
                            <li>tides - without the moon our tides would still exist but be a lot weaker.</li>
                            <li>brightness of the night sky</li>
                            <li>Coral, crabs, worms and fish that sense the moonlight and use it as a trigger for reproduction</li>
                            <li>The intertidal zone - without the moon it would narrow, limiting the space for competiting species to live</li>
                            <li>Reproduction - Some species, such as California grunion fish and some turtles would die out as they rely on the tide to time egg-laying and hatching</li>
                            <li>Fishing - near shore sea fishing would be much more difficult as species move with the tides</li>
                            <li>Animal migration, navigation and reproduction</li>
                            <li>Human circadian rhythms</li>
                            <li>Crustaceans with two compasses - Sun compass in the brain, Moon compass in their antennae. Without the moon they would be vulnerable to predators as their navigation would be off. See Sand Hoppers as an example</li>
                            <li>Coral - mass spawning is triggered by the Moon</li>
                        </ul>
                        <br/>
                        Ultimately, if we didn't have the Moon there would be extreme climate change with huge differences in temperature and light and ice ageas forming in different locations every few thousand years.
                        <br/><br/>
                        Sources:
                        <br/>
                        <ul className="list">
                            <li><a href="https://www.iop.org/explore-physics/moon/how-does-moon-affect-earth" target="_blank" rel="noopener noreferrer">https://www.iop.org/explore-physics/moon/how-does-moon-affect-earth</a></li>
                            <li><a href="https://www.nhm.ac.uk/discover/how-does-the-moon-affect-life-on-earth.html" target="_blank" rel="noopener noreferrer">https://www.nhm.ac.uk/discover/how-does-the-moon-affect-life-on-earth.html</a></li>
                        </ul>
                        <br/><br/>
                        <img className="imgHalf" src={Moon1} alt="If the earth didn't have the moon, our days would only be 6-8 hours ling and there would be betwen 1,100-1,400 days in a year" />
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">05 The Stars</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light">Constellation</div>
                    <div className="badgePageItemInfo reverse hidden">Draw at least 10 constellations. Make clear which are visible in the Northern Hemosphere or Southern Hemisphere.</div>
                    <div className="badgePageItemEvidence">
                    <p>I found some brilliant drawing resources which helped me get the shapes for my constellations: https://www.supercoloring.com/88constellations</p>
                    <br/>
                    <p>I reduced the sizes so I could get two to a page in my little sketchbook and then pricked holes where each of the stars were! I then used a sharp pencil to put dots on the paper using the pricked patterns as a guide. When I finished, I decided to add a background to each one with watercolour pencils.</p>
                    <br/>
                    <div className="gallery">
                        <div className="galleryItem"><img src={Drawing1} alt="" /></div>
                        <div className="galleryItem"><img src={Drawing2} alt="" /></div>
                        <div className="galleryItem"><img src={Drawing3} alt="" /></div>
                        <div className="galleryItem"><img src={Drawing4} alt="" /></div>
                        <div className="galleryItem"><img src={Drawing5} alt="" /></div>
                        <div className="galleryItem"><img src={Drawing6} alt="" /></div>
                        <div className="galleryItem"><img src={Drawing7} alt="" /></div>
                        <div className="galleryItem"><img src={Drawing8} alt="" /></div>
                        <div className="galleryItem"><img src={Drawing9} alt="" /></div>
                    </div>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">06 History</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light">Space exploration</div>
                    <div className="badgePageItemInfo reverse hidden">Research the history of space exploration and write a timeline of major occurrences from the 1950s onwards.</div>
                    <div className="badgePageItemEvidence">
                        Rather than just reproducing text from a link about the timeline, here is a school homework level graphic with the details!! 
                        <br/><br/>
                        <img src={Timeline} alt="Timeline of space exploration with a galaxy style background and space related graphic images." />
                        <br/><br/>
                        <p>Wikipedia contains even more events such as quakes, production of oxygen on another planet, flybys of other objects, other objects passing through the solar system.</p>
                        <br/><br/>
                        <p>Events since 2019, following on from my graphic:</p>
                        <ul className="list">
                            <li>10 April 2019: First direct photograph of a black hole and its vicinity</li>
                            <li>19 April 2021: First aerodynamically-powered flight on another celestial body (Mars)</li>
                            <li>14 Dec 2021: First spacecraft to fly through the atmosphere of a star (the Sun's corona)</li>
                            <li>25 Dec 2021: Launch of the largest space telescope to date - James Webb Telescope</li>
                            <li>26 Sept 2022: First Spacecraft to impact an asteroid moon (Dimorphos)</li>
                        </ul>
                        <br/><br/>
                        <p><strong>Sources:</strong></p>
                        <ul className="list">
                            <li>https://education.nationalgeographic.org/resource/history-space-exploration</li>
                            <li>https://www.britannica.com/science/space-exploration/Major-milestones</li>
                            <li>https://en.wikipedia.org/wiki/Timeline_of_space_exploration</li>
                        </ul>
                        <br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">07 Compass Directions</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light">Night sky navigation</div>
                    <div className="badgePageItemInfo reverse hidden">Understand how to work out compass directions from the night sky.</div>
                    <div className="badgePageItemEvidence">
                    <p>In the northern hemisphere, the easiest way to do this is by finding Polaris - the North Star.</p>
                    <br/>
                    <p>Polaris can be found at the end of the Little Dipper constellation - Ursa Minor - I've always recognised this from its saucepan shape!</p>
                    <br/>
                    Polaris is always in line with the rotation axis of Earth so is always pointing North.
                    <br/><br/>
                    <p>There are more technical ways of using the night sky to navigate and these are often used by seafarers.</p>
                    <br/>
                    <p>There are 58 stars that are officially used for navigation and each one can be located using its celestial latitude and longitude - Declination and Sidereal</p>
                    <br/>
                    <p>With this information, you can work out your direction, tell the time, measure distance travelled and get your latitude and longitude.</p>
                    <br/><br/>
                    <p>You can use a tool called a "Sextant" to help you find your latitude and longitude. This can be used to find the angle between the horizon and the moon or a star, by moving an arm on the device until the star is reflected into the sextant's mirror. When the angel is set, this can be used along with the current time to read the latitude using pre-published tables.</p>
                    <br/><br/>
                    <p>Sources:</p>
                    <ul className="list">
                        <li>https://littleastronomy.com/how-to-find-north-east-or-any-other-direction-by-watching-the-sky/</li>
                        <li>https://www.yachtingmonthly.com/sailing-skills/celestial-navigation-understanding-the-night-sky-76487</li>
                        <li>https://www.britannica.com/technology/sextant-instrument</li>
                    </ul>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">08 Visit</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light">Visit</div>
                    <div className="badgePageItemInfo reverse hidden">Visit a planetarium, observatory or science centre.</div>
                    <div className="badgePageItemEvidence">
                    <p>I can't get to the locations suggested, but I have been to the London Planetarium in the past, so I'm happy to backdate that.</p>
                    <br/>
                    <p>To ensure that I have been doing something towards this clause, I have been visiting online tours and shows.</p>
                    <br/><br/>
                    <p>I have watched these virtual observatory and planetarium shows, some of which are the same as you would get when visiting the real world locations:</p>
                    <br/>
                    <ul className="list">
                        <li><a className="linkUnderline" targer="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=apULwlLq-Fk">ESO 60th anniversary virtual tour</a></li>
                        <li><a className="linkUnderline" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=JOXplus8Mbk">Looking Up: A Virtual Night Sky Tour with Royal Observatory, Edinburgh</a></li>
                        <li><a className="linkUnderline" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=OUcv3ORj2jc">ESO "From Earth to the Universe" Free Planetarium Show in 4K FullDome</a></li>
                        <li><a className="linkUnderline" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=eNw9r4yfki8">ROE Open Days: Virtual Planetarium Show</a></li>
                    </ul>
                    <br/>
                    <p>I have also done this free course with OpenLearn:</p>
                    <br/>
                    <ul className="list">
                        <li><a className="linkUnderline" target="_blank" rel="noopener noreferrer" href="https://www.open.edu/openlearn/science-maths-technology/astronomy/astronomy-online-telescope/content-section-overview?active-tab=description-tab">Astronoy with an online telescope</a></li>
                    </ul>
                    <br/>
                    <p>Hee's the badge I earned when I completed it:</p>
                    <br/>
                    <img className="imgThird" src={Badge1} alt="" />
                    <br/><br/>
                    <p>Hopefully that compensates to top up the backdated visit.</p>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Stargazer