import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import Rebel from "../images/rebel-logo.png";
import Maverick1 from '../images/maverickaward1.jpg';
import Maverick2 from '../images/maverickaward2.jpg';

const Bronze = () => {

    useEffect(() => {
        const elements = document.querySelectorAll(".mavItem");
        var currDate = new Date();

        elements.forEach((el) => {
            var oVal = 0;
            var oProg;
            var mProg;
            var count =  el.querySelectorAll(".mavClauseStatus").length;
            var maxval = 100 / count;

            el.querySelectorAll(".mavClauseStatus").forEach((elS) => {

                var cID = elS.id;
                var cStatus = elS.textContent;
                var cStart = el.querySelector("#" + cID.replace("Status","Start")).textContent;
                var cEnd = el.querySelector("#" + cID.replace("Status","End")).textContent;
                var cProg = el.querySelector("#" + cID.replace("Status","Progress"));
                oProg = el.querySelector("#" + cID.substring(0, cID.length-7).replace("1","") + "Progress");
                mProg = el.querySelector("#" + cID.substring(0, cID.length-7).replace("1","") + "ProgressMini");

                if (cStatus === "In progress")
                {
                    if (cEnd === "" && cStart !== "")
                    {
                        var startString = cStart.split("/");
                        var startDate = new Date(startString[2],startString[1]-1,startString[0]);
                        var diff = new Date(startDate - currDate);
                        var days =  Math.ceil(diff/1000/60/60/24);
                        var maxdays = -90;
                        cProg.value = (Math.ceil(days/maxdays*100));

                        var setP = days * maxdays / 100;

                        if(setP > maxval)
                        {
                            setP = maxval / 2;
                        }
                        oVal = oVal + setP;

                        cProg.classList.add("orange");
                        cProg.classList.remove("green");
                        cProg.classList.remove("red");
                    }
                    else if (cEnd !== "" && cStart !== "")
                    {
                        cProg.value = 100;
                        cProg.classList.add("green");
                        cProg.classList.remove("orange");
                        cProg.classList.remove("red");
                        oVal = oVal + maxval;
                    }
                }
                else if (cStatus === "Complete")
                {
                    cProg.value = 100;
                    cProg.classList.add("green");
                    cProg.classList.remove("orange");
                    cProg.classList.remove("red");
                    oVal = oVal + maxval;
                }
                else if (cStatus === "Not started")
                {
                    cProg.value = 1;
                    cProg.classList.remove("green");
                    cProg.classList.remove("orange");
                    cProg.classList.add("red");
                    oVal = oVal + 0;
                }
            })

            var oCalc = Math.ceil(oVal);
            oProg.value = oCalc;
            mProg.value = oCalc;

            
            if(oCalc <= 1)
            {
                oProg.classList.add("red");
                oProg.classList.remove("orange");
                oProg.classList.remove("green");
                mProg.classList.add("red");
                mProg.classList.remove("orange");
                mProg.classList.remove("green");
            }
            else if (oCalc < 100)
            {
                oProg.classList.remove("red");
                oProg.classList.add("orange");
                oProg.classList.remove("green");
                mProg.classList.remove("red");
                mProg.classList.add("orange");
                mProg.classList.remove("green");
            }
            else
            {
                oProg.classList.remove("red");
                oProg.classList.remove("orange");
                oProg.classList.add("green");
                mProg.classList.remove("red");
                mProg.classList.remove("orange");
                mProg.classList.add("green");
            }    
        })


        document.querySelectorAll(".mavItemIntro").forEach((elI) => {elI.classList.add("hidden")})
        document.querySelectorAll(".mavClause").forEach((elI) => {elI.classList.add("hidden")})
        document.querySelectorAll(".mavProgress").forEach((elI) => {elI.classList.add("hidden")})
        document.querySelectorAll(".icon-down-open").forEach((elI) => {elI.classList.add("shown")})
        document.querySelectorAll(".icon-up-open").forEach((elI) => {elI.classList.add("hidden")})

        document.querySelectorAll(".mavItemTitle").forEach((mit) => {addListener(mit);})

    }, [])



    return (
        <div>
            <div className="actionBar">
                <div className="actionBarItem"><Link to="/">Back to Dashboard</Link></div>
            </div>
            <div className="row bookPageTop">
                <div className="rebelLeft"><img src={Rebel} alt="Sticker of the word rebel" className="rebelLogoBookLeft" width="200px" height="127px" /></div>
                <div className="rebelMid">
                    <h2>Bronze Maverick Award</h2>
                </div>
                <div className="rebelRight"><img src={Rebel} alt="Sticker of the word rebel" className="rebelLogoBookRight" width="200px" height="127px"     /></div>
            </div>
            <div className="row pageIntroduction bronzeIntro">
                <div>This page follows my progress in completing my Bronze award.</div>
                <br/>
                <div>Very happy to have passed my Bronze Maverick Assessment on 5th March 2023 to become Bronze Maverick 11!</div>
                <br/><br/>
                <div className="gallery">
                <img className="imgThird" src={Maverick1} alt="Maverick Award book and badges" />
                <img className="imgThird" src={Maverick2} alt="Maverick Award pin badge in display box" />
                </div>
            </div>

            <div className="mavItem">
                <div className="mavItemTitle blue" tabIndex={0} role="button" aria-expanded="false">
                    <div>My Community</div>
                    <div>
                        <progress id="communityProgressMini" value="73" max="100" className="orange"></progress>
                        <span className="icon-down-open"></span></div>
                    </div>
                <div className="mavItemIntro blue-light">Complete at least 30 hours of volunteer work over at least six months<br/><Link to="/mycommunity">View evidence page</Link></div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month One</div>
                    <div className="mavClauseStatus" id="community1Status">Complete</div>
                    <div className="mavClauseStart" id="community1Start">15/08/2022</div>
                    <div className="mavClauseEnd" id="community1End">14/09/2022</div>
                    <div className="mavClauseProgress"><progress id="community1Progress" value="100" max="100" className="orange"></progress></div>
                </div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month Two</div>
                    <div className="mavClauseStatus" id="community2Status">Complete</div>
                    <div className="mavClauseStart" id="community2Start">15/09/2022</div>
                    <div className="mavClauseEnd" id="community2End">14/10/2022</div>
                    <div className="mavClauseProgress"><progress id="community2Progress" value="1" max="100" className="red"></progress></div>
                </div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month Three</div>
                    <div className="mavClauseStatus" id="community3Status">Complete</div>
                    <div className="mavClauseStart" id="community3Start">15/10/2022</div>
                    <div className="mavClauseEnd" id="community3End">14/11/2022</div>
                    <div className="mavClauseProgress"><progress id="community3Progress" value="1" max="100" className="red"></progress></div>
                </div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month Four</div>
                    <div className="mavClauseStatus" id="community4Status">Complete</div>
                    <div className="mavClauseStart" id="community4Start">15/11/2022</div>
                    <div className="mavClauseEnd" id="community4End">14/12/2022</div>
                    <div className="mavClauseProgress"><progress id="community4Progress" value="1" max="100" className="red"></progress></div>
                </div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month Five</div>
                    <div className="mavClauseStatus" id="community5Status">Complete</div>
                    <div className="mavClauseStart" id="community5Start">15/12/2022</div>
                    <div className="mavClauseEnd" id="community5End">14/01/2023</div>
                    <div className="mavClauseProgress"><progress id="community5Progress" value="1" max="100" className="red"></progress></div>
                </div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month Six</div>
                    <div className="mavClauseStatus" id="community6Status">Complete</div>
                    <div className="mavClauseStart" id="community6Start">15/01/2023</div>
                    <div className="mavClauseEnd" id="community6End">14/02/2023</div>
                    <div className="mavClauseProgress"><progress id="community6Progress" value="73" max="100" className="red"></progress></div>
                </div>
                <div className="mavProgress"><progress id="communityProgress" value="73" max="100" className="orange"></progress></div>
            </div>

            <div className="mavItem">
                <div className="mavItemTitle brown" tabIndex={0} role="button" aria-expanded="false">
                    <div>My Beliefs</div>
                    <div>
                        <progress id="beliefsProgressMini" value="5" max="100" className="orange"></progress>
                        <span className="icon-down-open"></span></div>
                    </div>
                <div className="mavItemIntro brown-light">Complete a project related to a cause or belief which you feel strongly about<br/><Link to="/mybeliefs">View evidence page</Link></div>
                <div className="mavClause">
                    <div className="mavClauseTask">Project</div>
                    <div className="mavClauseStatus" id="beliefs1Status">Complete</div>
                    <div className="mavClauseStart" id="beliefs1Start">15/08/2022</div>
                    <div className="mavClauseEnd" id="beliefs1End">4/02/2023</div>
                    <div className="mavClauseProgress"><progress id="beliefs1Progress" value="5" max="100" className="orange"></progress></div>
                </div>  
                <div className="mavClause">
                    <div className="mavClauseTask">Project Presentation</div>
                    <div className="mavClauseStatus" id="beliefs2Status">Complete</div>
                    <div className="mavClauseStart" id="beliefs2Start">07/01/2023</div>
                    <div className="mavClauseEnd" id="beliefs2End">10/01/2023</div>
                    <div className="mavClauseProgress"><progress id="beliefs2Progress" value="1" max="100" className="red"></progress></div>
                </div>
                <div className="mavProgress"><progress id="beliefsProgress" value="5" max="100" className="orange"></progress></div>
            </div>

            <div className="mavItem">
                <div className="mavItemTitle blue" tabIndex={0} role="button" aria-expanded="false">
                    <div>My Skills</div>
                    <div>
                        <progress id="skillsProgressMini" value="2" max="100" className="orange"></progress>
                        <span className="icon-down-open"></span></div>
                    </div>
                <div className="mavItemIntro blue-light">Over at least six months improve on a skill of your choice<br/><Link to="/myskills">View evidence page</Link></div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month One</div>
                    <div className="mavClauseStatus" id="skills1Status">Complete</div>
                    <div className="mavClauseStart" id="skills1Start">15/08/2022</div>
                    <div className="mavClauseEnd" id="skills1End">14/09/2022</div>
                    <div className="mavClauseProgress"><progress id="skills1Progress" value="2" max="100" className="orange"></progress></div>
                </div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month Two</div>
                    <div className="mavClauseStatus" id="skills2Status">Complete</div>
                    <div className="mavClauseStart" id="skills2Start">15/09/2022</div>
                    <div className="mavClauseEnd" id="skills2End">14/10/2022</div>
                    <div className="mavClauseProgress"><progress id="skills2Progress" value="1" max="100" className="red"></progress></div>
                </div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month Three</div>
                    <div className="mavClauseStatus" id="skills3Status">Complete</div>
                    <div className="mavClauseStart" id="skills3Start">15/10/2022</div>
                    <div className="mavClauseEnd" id="skills3End">14/11/2022</div>
                    <div className="mavClauseProgress"><progress id="skills3Progress" value="1" max="100" className="red"></progress></div>
                </div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month Four</div>
                    <div className="mavClauseStatus" id="skills4Status">Complete</div>
                    <div className="mavClauseStart" id="skills4Start">15/11/2022</div>
                    <div className="mavClauseEnd" id="skills4End">14/12/2023</div>
                    <div className="mavClauseProgress"><progress id="skills4Progress" value="1" max="100" className="red"></progress></div>
                </div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month Five</div>
                    <div className="mavClauseStatus" id="skills5Status">Complete</div>
                    <div className="mavClauseStart" id="skills5Start">15/12/2022</div>
                    <div className="mavClauseEnd" id="skills5End">14/01/2023</div>
                    <div className="mavClauseProgress"><progress id="skills5Progress" value="1" max="100" className="red"></progress></div>
                </div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month Six</div>
                    <div className="mavClauseStatus" id="skills6Status">Complete</div>
                    <div className="mavClauseStart" id="skills6Start">15/01/2023</div>
                    <div className="mavClauseEnd" id="skills6End">10/02/2023</div>
                    <div className="mavClauseProgress"><progress id="skills6Progress" value="1" max="100" className="red"></progress></div>
                </div>
                <div className="mavClause">
                    <div className="mavClauseTask">Project Presentation</div>
                    <div className="mavClauseStatus" id="skills7Status">Complete</div>
                    <div className="mavClauseStart" id="skills7Start">10/02/2023</div>
                    <div className="mavClauseEnd" id="skills7End">10/02/2023</div>
                    <div className="mavClauseProgress"><progress id="skills7Progress" value="1" max="100" className="red"></progress></div>
                </div>
                <div className="mavProgress"><progress id="skillsProgress" value="5" max="100" className="orange"></progress></div>
            </div>

            <div className="mavItem">
                <div className="mavItemTitle brown" tabIndex={0} role="button" aria-expanded="false">
                    <div>My Adventure</div>
                    <div>
                        <progress id="adventureProgressMini" value="100" max="100" className="green"></progress>
                        <span className="icon-down-open"></span></div>
                    </div>
                <div className="mavItemIntro brown-light">Plan and execute an adventure for you which involves at least two nights away from home<br/><Link to="/myadventure">View evidence page</Link></div>
                <div className="mavClause">
                    <div className="mavClauseTask">My adventure</div>
                    <div className="mavClauseStatus" id="adventure1Status">Complete</div>
                    <div className="mavClauseStart" id="adventure1Start">12/09/2022</div>
                    <div className="mavClauseEnd" id="adventure1End">04/12/2022</div>
                    <div className="mavClauseProgress"><progress id="adventure1Progress" value="100" max="100" className="green"></progress></div>
                </div>
                <div className="mavProgress"><progress id="adventureProgress" value="100" max="100" className="green"></progress></div>
            </div>

            <div className="mavItem">
                <div className="mavItemTitle blue" tabIndex={0} role="button" aria-expanded="false">
                    <div>My Challenge</div>
                    <div>
                        <progress id="challengeProgressMini" value="5" max="100" className="orange"></progress>
                        <span className="icon-down-open"></span></div>
                    </div>
                <div className="mavItemIntro blue-light">Set yourself a measurable challenge that takes you at least six months to complete<br/><Link to="/mychallenge">View evidence page</Link></div>
                <div className="mavClause">
                    <div className="mavClauseTask">My challenge</div>
                    <div className="mavClauseStatus" id="challenge1Status">Complete</div>
                    <div className="mavClauseStart" id="challenge1Start">15/08/2022</div>
                    <div className="mavClauseEnd" id="challenge1End">06/02/2023</div>
                    <div className="mavClauseProgress"><progress id="challenge1Progress" value="5" max="100" className="orange"></progress></div>
                </div>
                <div className="mavProgress"><progress id="challengeProgress" className="orange" value="5" max="100"></progress></div>
            </div>
        </div>
    )
}

function listenFunc(element)
{
    var elementIntro = element.parentElement.querySelector(".mavItemIntro");
    var elementClause = element.parentElement.querySelectorAll(".mavClause");
    var elementProgress = element.parentElement.querySelector(".mavProgress");

    if(element.getAttribute("aria-expanded") === "false")
    {
        element.setAttribute("aria-expanded", "true");
    }
    else
    {
        element.setAttribute("aria-expanded", "false");
    }

    if(elementIntro.classList.contains("hidden"))
    {
        elementIntro.classList.remove("hidden");
        elementIntro.classList.add("shown");
    } else {
        elementIntro.classList.remove("shown");
        elementIntro.classList.add("hidden");
    }
    
    elementClause.forEach((ec) => {
        if(ec.classList.contains("hidden"))
        {
            ec.classList.remove("hidden");
            ec.classList.add("shown");
        } else {
            ec.classList.remove("shown");
            ec.classList.add("hidden");
        }
    })

    if(elementProgress.classList.contains("hidden"))
    {
        elementProgress.classList.remove("hidden");
        elementProgress.classList.add("shown");
    } else {
        elementProgress.classList.remove("shown");
        elementProgress.classList.add("hidden");
    }
}

function addListener(element)
{
    element.addEventListener("click",function(){
        listenFunc(element);
    })

    element.addEventListener("keydown", function(e){
        if (e.key !== undefined){
            if(e.key === "Enter" || e.key === " " || e.key === "ArrowDown" || e.key === "ArrowUp" || e.key === "ArrowLeft" || e.key === "ArrowRight")
            {
                listenFunc(element);
            }
        } else if (e.which !== undefined) {
            if(e.key === 13 || e.key === 32 || e.key === 40 || e.key === 38 || e.key ===37 || e.key === 39)
            {
                listenFunc(element);
            }
        }
    })
}

export default Bronze