import React from 'react';
import { Link } from 'react-router-dom';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import CC1 from '../images/consciousconsumer1.jpg';
import CC2 from '../images/consciousconsumer2.jpg';
import CC3 from '../images/consciousconsumer3.jpg';
import CC4 from '../images/consciousconsumer4.jpg';
import CC5 from '../images/consciousconsumer5.jpg';
import CCJ1 from '../images/consciousconsumer-jewellery1.jpg';
import CCJ2 from '../images/consciousconsumer-jewellery2.jpg';
import CCJ3 from '../images/consciousconsumer-jewellery3.jpg';
import CCJ4 from '../images/consciousconsumer-jewellery4.jpg';

const ConsciousConsumer = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Conscious Consumer"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">01</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Reduce buying</div>
                    <div className="badgePageItemEvidence">
                        This is easy to do as I only buy essentials anyway. 
                        <br/><br/>
                        My shopping is all done online so I don't make many impulse purchases in stores.
                        <br/><br/>
                        Although a bit of a silly example - I wanted new ramekin dishes as all of mine (except one) seem to have vanished! Mum came for lunch and bought little puddings with her - I saved the glass jars to use instead of buying new ones!
                        <br/><br/>
                        When re-organising my kitchen I had some shelves to put in the cupboard. I had one spare. I wanted to have a rack for rolls of cling film, foil etc and was looking at storage solutions to buy. In the end, I used the brackets from the spare shelf to create my own instead of buying new.
                        <br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">02</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Buy second hand</div>
                    <div className="badgePageItemEvidence">
                        I have not bought any new clothes in the last six months and have no need for anything new currently.
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">03</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Explore long-term options</div>
                    <div className="badgePageItemEvidence">
                        <ul className="list">
                            <li>Switching diets will help to reduce packaging waste</li>
                            <li>Moving to plastic free and zero waste alternatives reduces waste</li>
                            <li>I have a fixed budget and only buy essentials</li>
                            <li>I save money on travel by working from home and only travelling by walking</li>
                            <li>I am growing my own herbs from seed for my Gardener badge - this will save me money on chillis and herbs</li>
                            <li>I have some reusable sanitary products, I haven't made a complete switch yet but I have reduced the disposables I use</li>
                            <li>Mum and I book swap, so we each save money on buying books!</li>
                            <li>I did a spend audit and cut down on all subscriptions that weren't required - as part of good habits I'll be doing more - particularly reducing TV subscriptions services!</li>
                            <li>I save money on training courses by using YouTube and other free sources such as EdX</li>
                            <li>I have a student card which helps to save money - I saved a huge amount on software subscription. I was entitled to it through one of my training providers and it was a small fee to pay for huge returns</li>
                            <li>We love a good flick around cahrity shops, always a great way to find new clothes</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">04</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Ethical Shopping</div>
                    <div className="badgePageItemEvidence">
                        I will confesss that I am really bad at using Amazon! It's just so convenient.
                        <br/><br/>
                        However, if I am buying gifts for birthdays and Christmas I use small UK businesses and often purchase from Etsy who are powered by renewables and usually carbon offset.
                        <br/><br/>
                        I love finding new small businesses to buy from, its very important to support independent creators.
                        <br/><br/>
                        I always look around for plastic free and zero waste alternatives and gifts - there are a lot of sources for these online and we are fortunate to have two zero waste stores nearby.
                        <br/><br/>
                        Identifying and buying Fairtrade products is important to support workers worldwide.
                        <br/><br/>
                        It's also important to understand food sources / origins and make sure you are purchasing ethical products - such as using the MCS Fish Guide and using free range products when you can to stop horrendous treatment of animals.
                        <br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">05</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Fix</div>
                    <div className="badgePageItemEvidence">
                        <strong>Item 1 - Purse</strong><br/>
                        Usually I would just replace a purse that no longer zips up, this time I took it apart, ordered a replacement zip and fixed it. This is documented on my <Link to="/moneysaver" className="linkUnderline">Money Saver page</Link>.
                        <br/><br/><br/>
                        <strong>Item 2 - Jewellery</strong><br/>
                        I frequently get sent jewellery to repair because I have a reputation for saving it! If something breaks it's usually easy to put back together again, there's no need to ditch it. I have a small supply of necessary parts and tools.
                        <br/><br/>
                        I've saved a few items of jewellery from abandonment. If my Mum turns up and says "Auntie says she loves you very much ..." I know there's something to be fixed!!!
                        <br/><br/>
                        <p>Here's my most recent save for Mum</p>
                        <br/>
                        <div className="gallery">
                            <img className="imgQuarter" src={CCJ1} alt="Necklace ready for repair" />
                            <img className="imgQuarter" src={CCJ2} alt="Necklace being repaired" />
                            <img className="imgQuarter" src={CCJ3} alt="Repaired necklace" />
                            <img className="imgQuarter" src={CCJ4} alt="Repaired necklace" />
                        </div>
                        <br/><br/><br/>
                        <strong>Item 3 - Oil Burner</strong><br/>
                        Mum presented me with an oil burner that she couldn't use. When I investigated the wick crumbled in my hands. I identified the manufacturer, part and ordered a replacement - lamp saved! Unfortunately, I didn't take any photos of this one.
                        <br/><br/><br/>
                        <strong>Item 4 - Exterior Lamp</strong><br/>
                        I have a mechanics lamp that sits in a lean-to shed outside the house. It has been very useful but it's now very battered and the bulb holder is broken.
                        <br/><br/>
                        I have dismantled that and repaired it so it can be used again. This is documented on my <Link to="/moneysaver" className="linkUnderline">Money Saver page</Link>.
                        <br/><br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                    <div className="badgePageItemTitle olive">06</div>
                    <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Make</div>
                    <div className="badgePageItemEvidence">
                        Here we have:
                        <br/>
                        <ul className="list">
                            <li>A paper flower wreath given as a gift to a relative</li>
                            <li>A paper Dahlia made for my Mum for Mother's Day as she didn't want anything!</li>
                            <li>A gift card using Pergamano</li>
                            <li>A tea light holder made from an old CD and some pistachio shells</li>
                            <li>A little angel made from wood and glass beads</li>
                        </ul>
                        <br/>
                        <div className="styleGallery">
                            <div className="styleItem"><img src={CC1} alt="Paper flower wreath" /></div>
                            <div className="styleItem"><img src={CC2} alt="Paper Dahlia" /></div>
                            <div className="styleItem"><img src={CC3} alt="Pergamano umbrella card" /></div>
                            <div className="styleItem"><img src={CC4} alt="Pistachio candle holder" /></div>
                            <div className="styleItem"><img src={CC5} alt="Beaded Angel" /></div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    )
}

export default ConsciousConsumer