import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import DIY1 from '../images/diy1.jpg';
import DIY2 from '../images/diy2.jpg';
import DIY3 from '../images/diy3.jpg';
import DIY4 from '../images/diy4.jpg';
import DIY5 from '../images/diy5.jpg';

const DIY = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"DIY"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">01</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Water, electricity and gas supplies</div>
                    <div className="badgePageItemEvidence">
                        Evidence not really needed here, but I know where each of these are to turn them off!
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">02</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Lightbulbs</div>
                    <div className="badgePageItemEvidence">
                        Something I do on a regular basis, again evidence not really needed here.
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">03</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Washers</div>
                    <div className="badgePageItemEvidence">
                        Washers need to be changed when the tap is dripping and turning it off doesn't completely stop the flow of water.
                        <br/>
                        Nothing needs changing here currently, but I have done this in the past.
                        <br/>
                        Here's a handy WikiHow article that provides instructions on how to undertake this task.
                        <br/>
                        <a href="https://www.wikihow.com/Change-a-Tap-Washer" target="_blank" rel="noopener noreferrer">Change a Tap Washer</a>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">04</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Plugs</div>
                    <div className="badgePageItemEvidence">
                        No evidence for this one, but this is something I've done more than once!
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">05</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Circuit Breaker and Fuses</div>
                    <div className="badgePageItemEvidence">
                        No evidence for this one, but again this is something I have done multiple times before. I have a new fuse box in my kitchen, it was oversensitive when first installed so I am more than used to resetting it!
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">06</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Toolkit</div>
                    <div className="badgePageItemEvidence">
                        I have a toolkit (well, a tool box!) stored in my kitchen. I've always been taught to be handy so I make sure I at least have the basics on hand. I hate not being able to do something due to lack of equpiment!
                        <br/><br/>
                        <ul className="list">
                            <li>Hammer - for bashing nails (and any other item that needs a swift hit!)</li>
                            <li>Spanner - for turning those tricky nuts and bolts</li>
                            <li>Pliers - for generally gripping, twisting and squeezing</li>
                            <li>Push Lock Groove Joint Pliers - for when you need to grip and twist something too big for your pliers</li>
                            <li>Junior Hacksaw - because who doesn't need to saw things occassionally! used for sawing metal.</li>
                            <li>Screwdrivers - a variety in flathead and philips styles at sizes from tiny electrical screwdrivers to more heavy duty options.</li>
                            <li>Screws and Nails - a wide variety of both that I have collected over the years for different projects</li>
                            <li>Silicone Gun - because sometimes getting into a sticky mess is necessary. Used for grip glues or silicone sealant</li>
                            <li>Chisel - no idea why I still have this, but I do! For cutting wood</li>
                            <li>Bradawl - very useful when I need to make holes</li>
                            <li>Stanley knife - for when I need to cut shit up. Always useful.</li>
                            <li>Tape meaures - those metal ones that like to try and remove your fingers when you retract them. For measuring anything!</li>
                            <li>Spirit level - because it's no good if your books all slide off your shelves. For putting things up straight!</li>
                        </ul>
                        <br/>
                        Pretty sure I've forgotten something, but that should cover the basics! No, I don't have a drill, I can barely be trusted with manual tools! I have a Dad who comes armed with power tools when I need them and I'm not afraid to use him!!
                        <br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">07 - Project 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Colour scheme</div>
                    <div className="badgePageItemEvidence">
                        I have decorated all of my house in the last few years and updated all the colours schemes:
                        <ul className="list">
                            <li>Master bedroom: Black and Purple (light wood floor unchanged)</li>
                            <li>Second bedroom: Cucumber green (light wood floor unchanged)</li>
                            <li>Office: White and Black (light wood floor unchanged)</li>
                            <li>Bathroomw: Grey with black/silver lino in floorboard style</li>
                            <li>Hall and Stairs: Light coffee colour with deep red carpet</li>
                            <li>Sitting Room: Metallic Silver with wallpaper feature separating the back room  (light wood floor unchanged).</li>
                            <li>Dining/Music room: Light yellow  (light wood floor unchanged)</li>
                            <li>Kitchen: Light grey walls with black woodwork and new wall tile stickers in shades of black, grey and white</li>
                        </ul>
                        <br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">07 - Project 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Flat pack</div>
                    <div className="badgePageItemEvidence">
                        The last item I assembled was this TV unit. I don't have photos of the process, but let's just say I put things together and took them apart again before I got to the correct configuration!!
                        <br/><br/>
                        <img className="imgQuarter" src={DIY1} alt="Solid wood television cabinet in a grey colour with light rustic wooden top" width="500px" height="500px" />
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">08 - Project 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Upcycle</div>
                    <div className="badgePageItemEvidence">
                        I upcycled a wooden chest that I've had a few years. I had a big fish tank that I needed a stand for. This piece of furniture was salvaged from the tip for £10.
                        <br/><br/>
                        As my room at the time was all natural wood, I varnished it but I always hated the colour, it wasn't natural or particularly nice!!
                        <br/><br/>
                        I wanted to keep the chest because it's really good storage and actually it can double-up as spare seating, so I decided to change it completely to fit my new silver sitting room.
                        <br/><br/>
                        Unfortunately I didn't take any before and after shots because I wasn't anticipating having to evidence a badge at the time! Here is a photo of it before, the best I could find from the archive and what it looks like now.
                        <br/><br/>
                        <img className="imgHalf" src={DIY2} alt="Wooden furniture varnished with an orange/brown shade" />
                        <img className="imgHalf" src={DIY3} alt="Wooden furniture painted with a grey gloss" />
                        <br/><br/>
                        I sanded it down and changed the colour to a grey gloss, I replaced the wooden handles with some nice metal alternatives.
                        <br/><br/>
                        It now sits in the bay window where it looks very attractive and can be used for seating if needed. It is actually a large drawer unit which you can get into either by pulling the drawer out or by lifting the lid.
                        <br/>
                        <img className="imgQuarter" src={DIY4} alt="Wooden storage unit painted with grey gloss, located in a bay window" />
                        <br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">08 - Project 4</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Decorate a wall</div>
                    <div className="badgePageItemEvidence">
                        I have decorated a lot of walls in the last few years! However, I'll highlight this one because it's currently my favourite!
                        <br/><br/>
                        The downstairs of my house is open plan. The front  half and back half of the main room have always been treated as one room and decorated accordingly. 
                        <br/><br/>
                        This time, I decided to give them separate identities! I didn't want to have a dodgy-looking paint line in the middle of the room where the two sides collided though.
                        <br/><br/>
                        So, I used a feature wallpaper in a gorgeous silver velvet style and outlined it with "crystals" (I know, I'm such a diva!).
                        <br/><br/>
                        The crystals didn't go as smoothly as I planned so I may re-do them at some point, but from a distance they look fine!
                        <br/><br/>
                        This is what the join in the two colour schemes looks like:
                        <br/>
                        <img className="imgHalf" src={DIY5} alt="Painted walls with a feature wallpaper" />
                        <br/>
                        There is an RSJ above so it's a perfect room divider and the wallpaper feature goes up each side of the wall and across the bottom of the beam to make a full arch.
                        <br/><br/>
                        I have a soft yellow in the dining / music room and the lovely metallic silver in the living room. That was a real gamble and a lot of hard work to do, you can still see brush strokes in a lot of places but it gives it character!
                        <br/><br/>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default DIY