import React from 'react';
import {Link} from 'react-router-dom';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader'
import Diamond1 from '../images/diamond1.jpg';
import Diamond2 from '../images/diamond2.jpg';
import Diamond3 from '../images/diamond3.jpg';
import Diamond4 from '../images/diamond4.jpg';
import Diamond5 from '../images/diamond5.jpg';
import Diamond6 from '../images/diamond6.jpg';
import Diamond7 from '../images/diamond7.jpg';
import Diamond8 from '../images/diamond8.jpg';
import Diamond9 from '../images/diamond9.jpg';
import Diamond10 from '../images/diamond10.jpg';
import Latch1 from '../images/latch1.jpg';
import Latch2 from '../images/latch2.jpg';
import Latch3 from '../images/latch3.jpg';
import Latch4 from '../images/latch4.jpg';
import Latch5 from '../images/latch5.jpg';
import Latch6 from '../images/latch6.jpg';
import Latch7 from '../images/latch7.jpg';
import Latch8 from '../images/latch8.jpg';
import Latch9 from '../images/latch9.jpg';
import Latch10 from '../images/latch10.jpg';
import Embroidery1 from '../images/embroidery1.jpg';
import Embroidery2 from '../images/embroidery2.jpg';
import Embroidery3 from '../images/embroidery3.jpg';
import Embroidery4 from '../images/embroidery4.jpg';
import Embroidery5 from '../images/embroidery5.jpg';
import Embroidery6 from '../images/embroidery6.jpg';
import Embroidery7 from '../images/embroidery7.jpg';
import Embroidery8 from '../images/embroidery8.jpg';
import Embroidery9 from '../images/embroidery9.jpg';
import Embroidery10 from '../images/embroidery10.jpg';
import Embroidery11 from '../images/embroidery11.jpg';
import Embroidery12 from '../images/embroidery12.jpg';
import Embroidery13 from '../images/embroidery13.jpg';
import Lace1 from '../images/craftpart2-lace1.jpg';
import Lace2 from '../images/craftpart2-lace2.jpg';
import Lace3 from '../images/craftpart2-lace3.jpg';
import Lace4 from '../images/craftpart2-lace4.jpg';
import Lace5 from '../images/craftpart2-lace5.jpg';
import Lace6 from '../images/craftpart2-lace6.jpg';
import Lace7 from '../images/craftpart2-lace7.jpg';
import Lace8 from '../images/craftpart2-lace8.jpg';

const CraftPart2 = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"Craft Part 2"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Diamond Painting</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>I picked this pretty picture to make as a gift for someone special (so I hope she's not looking here until after her birthday!!)</p>
                        <br/>
                        <div className="gallery">
                        <div className="galleryItem"><img src={Diamond1} alt="Diamond painting in progress" /></div>
                        <div className="galleryItem"><img src={Diamond2} alt="Diamond painting in progress" /></div>
                        <div className="galleryItem"><img src={Diamond3} alt="Diamond painting in progress" /></div>
                        <div className="galleryItem"><img src={Diamond4} alt="Diamond painting in progress" /></div>
                        <div className="galleryItem"><img src={Diamond5} alt="Diamond painting in progress" /></div>
                        <div className="galleryItem"><img src={Diamond6} alt="Diamond painting in progress" /></div>
                        <div className="galleryItem"><img src={Diamond7} alt="Diamond painting in progress" /></div>
                        <div className="galleryItem"><img src={Diamond8} alt="Diamond painting in progress" /></div>
                        <div className="galleryItem"><img src={Diamond9} alt="Diamond painting in progress" /></div>
                        <div className="galleryItem"><img src={Diamond10} alt="Diamond painting in progress" /></div>
                        </div>
                    </div>
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Latch Hook</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>I love doing latch hook work - my Grandma introduced me to it and years ago I made a latch hook rug with Tigger on for my room! I still have it somewhere, I couldn't bear to get rid of it!</p>
                        <br/>
                        <p>This time, I opted for a latch hook cushion, which I thought was quite unusual! I chose this pretty ladybird one and decided to make it for Mum for Mother's Day. Unfortunately I hadn't realised how close Mother's Day was when I started so I had to speed up my work!!</p>
                        <br/>
                        <p>All done in time and I think she liked it!</p>
                        <br/><br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Latch1} alt="Latch hook cushion progress" /></div>
                            <div className="galleryItem"><img src={Latch2} alt="Latch hook cushion progress" /></div>
                            <div className="galleryItem"><img src={Latch3} alt="Latch hook cushion progress" /></div>
                            <div className="galleryItem"><img src={Latch4} alt="Latch hook cushion progress" /></div>
                            <div className="galleryItem"><img src={Latch5} alt="Latch hook cushion progress" /></div>
                            <div className="galleryItem"><img src={Latch6} alt="Latch hook cushion progress" /></div>
                            <div className="galleryItem"><img src={Latch7} alt="Latch hook cushion progress" /></div>
                            <div className="galleryItem"><img src={Latch8} alt="Latch hook cushion progress" /></div>
                            <div className="galleryItem"><img src={Latch9} alt="Latch hook cushion progress" /></div>
                            <div className="galleryItem"><img src={Latch10} alt="Latch hook cushion finished with cushion insert" /></div>
                        </div>
                    </div>
                </div>
            </div>          
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Embroidery</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>It has been quite a long time since I've done any embroidery so I thought I'd try a new project.  I chose this pretty bag to give to my Auntie as a gift for her birthday. It is all one colour, but it's a pretty design.</p>
                        <br/><br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Embroidery1} alt="Embroidery progress" /></div>
                            <div className="galleryItem"><img src={Embroidery2} alt="Embroidery progress" /></div>
                            <div className="galleryItem"><img src={Embroidery3} alt="Embroidery progress" /></div>
                            <div className="galleryItem"><img src={Embroidery4} alt="Embroidery progress" /></div>
                            <div className="galleryItem"><img src={Embroidery5} alt="Embroidery progress" /></div>
                            <div className="galleryItem"><img src={Embroidery6} alt="Embroidery progress" /></div>
                            <div className="galleryItem"><img src={Embroidery7} alt="Embroidery work completed" /></div>
                        </div>
                        <br/>
                        <p>So far, so good. Time to put it together!</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Embroidery8} alt="Embroidery progress" /></div>
                            <div className="galleryItem"><img src={Embroidery9} alt="Embroidery progress" /></div>
                            <div className="galleryItem"><img src={Embroidery10} alt="Embroidery progress" /></div>
                            <div className="galleryItem"><img src={Embroidery11} alt="Embroidery progress" /></div>
                        </div>
                        <br/>
                        <p>Looks good, just one tiny problem - I managed to mis-align something and had a gap between the front layer and lining. So, another round of stitching!</p>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Embroidery12} alt="Showing a gap between the fabric where there shouldn't be one!" /></div>
                        </div>
                        <br/>
                        <p>OK, I think we're good! This has turned out smaller than I expected, but hopefully still useful as a bag to put in a handbag for small things when out shopping!</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Embroidery13} alt="Finished embroidered bag" /></div>
                        </div>

                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Sculpture</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        For this clause, I am using my sculpture of Mother Earth that I did for April's Cup Points. You can see the evidence of this on the <Link to="/CupApr23" className="linkUnderline">April Cup page</Link> under the heading "Bonus 1".
                        <br/><br/>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Lace Making</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>It has been quite some time since I did any lace making, so I loaded up the lace pillow once again to make a torchon edge that can be used as a bookmark.</p>
                        <br/><br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Lace7} alt="Preparing lace-making bobbins"/></div>
                            <div className="galleryItem"><img src={Lace8} alt="Preparing lace-making bobbins"/></div>
                            <div className="galleryItem"><img src={Lace1} alt="Lace making pillow set up with pattern and bobbins"/></div>
                            <div className="galleryItem"><img src={Lace2} alt="Lace making in progress"/></div>
                            <div className="galleryItem"><img src={Lace3} alt="Lace making in progress"/></div>
                            <div className="galleryItem"><img src={Lace4} alt="Lace bookmark complete on lace pillow"/></div>
                            <div className="galleryItem"><img src={Lace5} alt="Lace bookmark complete on lace pillow"/></div>
                            <div className="galleryItem"><img src={Lace6} alt="Close up of lace bookmark on lace pillow"/></div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default CraftPart2