import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const  September24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"The Poop Challenge"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle darkblue"></div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <div className="hidden">
                        <p>What is here doesn't necessarily represent all of the work and research undertaken to meet the clauses of this badge. It is an overview only.</p>
                            <p><strong>Eco-friendly toilets, toilet paper and cleaning options</strong></p>
                            <p>
                                <ol>
                                    <li>Low-Flow Toilets
                                        <ul>
                                            <li>Features: Use significantly less water per flush (1.28 gallons or less) compared to older models (up to 7 gallons).</li>
                                            <li>Benefits: Reduce water usage and save on water bills.</li>
                                        </ul>
                                    </li>
                                    <li>Dual-Flush Toilets
                                        <ul>
                                            <li>Features: Offer two flush options: a low-volume flush for liquid waste and a higher-volume flush for solid waste.</li>
                                            <li>Benefits: Provide flexibility and further water savings.</li>
                                        </ul>
                                    </li>
                                    <li>Composting Toilets
                                        <ul>
                                            <li>Features: Use little to no water and turn human waste into compost.</li>
                                            <li>Benefits: Ideal for off-grid living and reducing water usage.</li>
                                        </ul>
                                    </li>
                                    <li>Vacuum Toilets
                                        <ul>
                                            <li>Features: Use a vacuum system to flush waste with minimal water.</li>
                                            <li>Benefits: Commonly used in airplanes and increasingly in eco-friendly homes.</li>
                                        </ul>
                                    </li>
                                </ol>
                            </p>
                            <br/>
                            <p><strong>Eco-Friendly Toilet Paper</strong></p>
                            <p>
                                <ol>
                                    <li>Bamboo Toilet Paper
                                        <ul>
                                            <li>Features: Made from fast-growing bamboo, which requires less water and no pesticides.</li>
                                            <li>Benefits: Soft, strong, and more sustainable than traditional tree-based paper</li>
                                        </ul>
                                    </li>
                                    <li> Recycled Toilet Paper
                                        <ul>
                                            <li>Features: Made from post-consumer recycled paper.</li>
                                            <li>Benefits: Reduces the need for virgin tree pulp and minimizes waste.</li>
                                        </ul>
                                    </li>
                                    <li>Unbleached Toilet Paper
                                        <ul>
                                            <li>Features: Free from chlorine and other bleaching chemicals.</li>
                                            <li>Benefits: Gentler on the skin and better for the environment4.</li>
                                        </ul>
                                    </li>
                                </ol>
                            </p>
                            <br/>
                            <p><strong>Eco-Friendly Cleaning Options</strong></p>
                            <ol>
                                <li>Natural Ingredients
                                    <ul>
                                        <li>Baking Soda: Versatile cleaner for multiple surfaces.</li>
                                        <li>White Vinegar: Effective for disinfection and stain removal.</li>
                                        <li>Castile Soap: Plant-based soap that can clean various surfaces.</li>
                                    </ul>
                                </li>
                                <li>2Refillable and Zero-Waste Products
                                    <ul>
                                        <li>OceanSaver EcoDrops: Concentrated cleaning tablets that dissolve in water, reducing plastic waste.</li>
                                        <li>Ecover Products: Plant-based cleaners with recyclable packaging.</li>
                                    </ul>
                                </li>
                                <li>Multi-Purpose Cleaners
                                    <ul>
                                        <li>For example: Method Anti-Bac All Purpose Cleaner: Plant-based formula that kills 99.9% of bacteria and comes in recycled plastic bottles.</li>
                                    </ul>
                                </li>
                            </ol>
                            <br/><br/>
                            <p><strong>Learn about a toilet from another country</strong></p>
                            <br/>
                            <p>On looking at the options, this clause is looking at the "Toilet with a Spray Hose" a.k.a. Bum Gun</p>
                            <p>Used in Malaysia, but also found in Indonesia, Brunei and Middle Eastern countires.</p>
                            <p>Concept similar to European bidet, but requiring less space.</p>
                            <p>More info here: https://ummigoeswhere.com/travelers-guide-toilets-around-world/</p>
                            <br/><br/>

                            <p><strong>Learn how palaeontologists use poop in their work </strong></p>
                            <p>Use Coprolites - fossilised faeces.</p>
                            <p>These give insights into diet, behaviour and environment.</p>
                            <br/>
                            <ol>
                                <li>Understanding Diets: Coprolites can reveal what ancient animals ate. By analyzing the contents of coprolites, paleontologists can identify plant material, bones, shells, and other food remnants. For example, coprolites from herbivorous dinosaurs might contain leaf matter, while those from carnivorous dinosaurs could have bone fragments.</li>
                                <li>Reconstructing Ancient Ecosystems: Coprolites can provide clues about the ecosystems in which ancient animals lived. They might contain remains of other organisms, such as parasites, that lived in or on the animal. This helps scientists understand the relationships between different species and their environments.</li>
                                <li>Behavioral Insights: The presence of certain materials in coprolites can hint at unexpected behaviors. For instance, finding rotting wood in herbivorous dinosaur coprolites suggests these dinosaurs might have consumed decaying wood, which is not a common diet for most herbivores.</li>
                                <li>Preservation Conditions: The way coprolites are preserved can tell paleontologists about the conditions under which they fossilized. Rapid burial and mineralization are crucial for the preservation of feces, which otherwise decompose quickly.</li>
                                <li>Trace Fossils: Coprolites are considered trace fossils, meaning they provide evidence of the activity of ancient organisms rather than their physical form. This makes them invaluable for understanding the daily lives and interactions of prehistoric creatures.</li>
                                <li>Historical Significance: Coprolites have also played a role in the history of science, particularly in the study of paleontology. Notably, Mary Anning, a pioneering paleontologist, made significant contributions to the field through her work with coprolites.</li>
                            </ol>
                            <br/><br/> 
                            <p><strong>Learn about gut microbiome and what you can do to support it.</strong></p> 
                            <p>The gut microbiome is made up of trillions of microorganisms, including bacteria, viruses, fungi and other microbes that live in the digestive tract.</p>
                            <p>They influence digestion, immune function and mental health.</p>    
                            <br/>
                            <p>Importance of the Gut Microbiome</p>  
                            <ol>
                                <li>Digestion and Nutrient Absorption: Gut bacteria help break down food, particularly complex carbohydrates and fibers, and assist in the absorption of nutrients.</li>
                                <li>Immune System Support: A healthy gut microbiome helps regulate the immune system, protecting against pathogens and reducing inflammation.</li>
                                <li>Mental Health: The gut-brain axis is a communication network linking the gut and the brain. A balanced microbiome can positively affect mood and cognitive function.</li>
                                <li>Metabolism and Weight Management: Gut bacteria influence metabolism and can impact weight management and the risk of metabolic diseases.</li>
                            </ol>  
                            <br/>
                            <p>Supporting your gut microbiome</p> 
                            <ol>
                                <li>Eat a Diverse Range of Foods
                                <ul>
                                    <li>Variety: Aim for a wide variety of fruits, vegetables, whole grains, legumes, nuts, seeds, herbs, and spices. This diversity helps promote a diverse microbiome.</li>
                                    <li>Fiber: Foods high in fiber, such as fruits, vegetables, pulses, nuts, and whole grains, feed healthy bacteria.</li>
                                </ul>
                                </li>
                                <li>Include Fermented Foods
                                    <ul>
                                        <li>Examples: Yogurt, kefir, sauerkraut, kimchi, miso, and kombucha. These foods contain beneficial probiotics that can enhance gut health.</li>
                                    </ul>
                                </li>
                                <li>Consume Prebiotics
                                    <ul>
                                        <li>Sources: Prebiotics are non-digestible fibers that feed beneficial bacteria. Good sources include garlic, onions, leeks, asparagus, bananas, and whole grains.</li>
                                    </ul>
                                </li>
                                <li>Limit Processed Foods and Sugars
                                    <ul>
                                        <li>Impact: Diets high in processed foods, sugars, and artificial sweeteners can negatively affect the gut microbiome.</li>
                                    </ul>
                                </li>
                                <li>Stay Hydrated
                                    <ul>
                                        <li>Benefit: Drinking plenty of water supports the mucosal lining of the intestines and the balance of good bacteria.</li>
                                    </ul>
                                </li>
                                <li>Exercise Regularly
                                    <ul>
                                        <li>Effect: Regular physical activity promotes the growth of beneficial gut bacteria.</li>
                                    </ul>
                                </li>
                                <li>Manage Stress
                                    <ul>   
                                        <li>Connection: Chronic stress can negatively impact gut health. Practices like mindfulness, meditation, and yoga can help reduce stress levels.</li>
                                    </ul>
                                </li>
                                <li>Consider Probiotic and Prebiotic Supplements
                                    <ul>
                                        <li>Usage: Supplements can be beneficial, especially if your diet lacks certain nutrients. However, it's best to consult with a healthcare provider before starting any new supplement.</li>
                                    </ul>
                                </li>
                            </ol>       
                            <br/><br/>
                            <p><strong>Learn about ways to protect and improve your bowel health</strong></p>       
                            <ol>
                                <li>Increase Fiber Intake
                                    <ul>
                                        <li>Sources: Whole grains, fruits, vegetables, legumes, nuts, and seeds.</li>
                                        <li>Benefits: Fiber helps to keep the digestive system running smoothly by adding bulk to the stool and promoting regular bowel movements.</li>
                                    </ul>
                                </li>
                                <li>Stay Hydrated
                                    <ul>
                                        <li>Recommendation: Drink plenty of water throughout the day.</li>
                                        <li>Benefits: Water helps to soften the stool, making it easier to pass and preventing constipation.</li>
                                    </ul>
                                </li>
                                <li>Eat a Balanced Diet
                                    <ul>
                                        <li>Include: A variety of foods rich in nutrients, such as lean proteins, healthy fats, and complex carbohydrates.</li>
                                        <li>Avoid: Processed foods, excessive sugar, and high-fat foods, which can disrupt digestion.</li>
                                    </ul>
                                </li>
                                <li>Regular Physical Activity
                                    <ul>
                                        <li>Activities: Walking, jogging, yoga, or any form of exercise you enjoy.</li>
                                        <li>Benefits: Exercise helps stimulate intestinal activity and can reduce the risk of constipation.</li>
                                    </ul>
                                </li>
                                <li>Manage Stress
                                    <ul>
                                        <li>Techniques: Mindfulness, meditation, deep breathing exercises, and adequate sleep.</li>
                                        <li>Benefits: Reducing stress can help prevent digestive issues like irritable bowel syndrome (IBS) and other gastrointestinal problems.</li>
                                    </ul>
                                </li>
                                <li>Avoid Smoking and Limit Alcohol
                                    <ul>
                                        <li>Impact: Smoking and excessive alcohol consumption can irritate the digestive tract and lead to various bowel issues.</li>
                                    </ul>
                                </li>
                                <li>Probiotics and Prebiotics
                                    <ul>
                                        <li>Probiotics: Found in fermented foods like yogurt, kefir, sauerkraut, and kimchi.</li>
                                        <li>Prebiotics: Found in foods like garlic, onions, bananas, and whole grains.</li>
                                        <li>Benefits: These support a healthy gut microbiome, which is essential for good digestion and overall bowel health.</li>
                                    </ul>
                                </li>
                                <li>Regular Check-Ups
                                    <ul>
                                        <li>Action: Schedule regular visits with your healthcare provider.</li>
                                        <li>Purpose: Early detection and management of any potential bowel issues, including screening for conditions like bowel cancer.</li>
                                    </ul>
                                </li>
                                <li>Listen to Your Body
                                    <ul>
                                        <li>Signs: Pay attention to symptoms like persistent changes in bowel habits, unexplained weight loss, or blood in the stool.</li>
                                        <li>Action: Seek medical advice if you notice any concerning symptoms.</li>
                                    </ul>
                                </li>

                            </ol>
                            <br/><br/>
                            <p><strong>Learn about the role of the King's Groom of the Stool</strong></p>
                            <br/>
                            <p>"Groom of the Stool" was a role in the court of King Henry VIII, which consisted of helping the king with all his toileting needs and monitoring his diet and mealtimes.This included providing a portable commode, water, towels and a washbowl. The groom became a close confidant over time, progressing to handling personal and admin tasks as well as involvement in finances. The role enabled influence over the king and so was sought after among the court.</p>
                            <br/><br/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default September24