import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const  July24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Big Kid Summer"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle darkblue"></div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>I didn't do many of the BKS clauses this year, but blowing bubbles, daisy chains and finger painting may have happened! Not uploading evidence for this one.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default July24