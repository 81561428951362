import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';


const HealthCheckJun23 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Rebel Health Check 2023"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Self Checks</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light">Check yourself out</div>
                    <div className="badgePageItemEvidence"> 
                        <p>There won't be evidence placed here, you'll have to trust that this is complete!</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Water</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light">Review your water intake - are you drinking enough?</div>
                    <div className="badgePageItemEvidence"> 
                        <p>I'm bad at remembering to drink water - I have never liked drinking it, but I am much better now. I also have a fasting app that prompts me at set intervals throughout the day to drink water with a set level per day.</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Exercise</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light">Review your exercise levels</div>
                    <div className="badgePageItemEvidence"> 
                        <p>My exercise levels are never great, my job is very sedentary. However, I walk three days a week for about an hour and a half at a time to litter pick. I use my Wii Fit in between to top up if I haven't been able to get out.</p>
                        <p>Whilst not directly exercise, I have just installed a sit to stand desk in my office, which hopefully will get me more mobile during working days.</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Sleep</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light">Are you getting enough?</div>
                    <div className="badgePageItemEvidence"> 
                        <p>I have a pretty good sleep schedule. Since childhood I have been a night owl, and I will always love late nights. However, due to work patterns I have forced myself into an early bird routine, most of the time it's fine.</p>
                        <br/>
                        <p>Also, as part of managing chronic migraine, sleep hygiene is essential, so I have a reasonably strict schedule to ensure I get 7 hours a night to help manage that. Along with diet changes and supplements the condition is much improved.</p>
                        <br/>
                        <p>I have alway felt stupid going to bed early, but now if I'm struggling by 9pm, I will go early. More often I'm in bed between 10pm and 11pm - no later than 11 as I wake at 6am at the latest.</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">First Aid Kit</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light">Check stock and replace medicines</div>
                    <div className="badgePageItemEvidence"> 
                        Kit checked, all is up to date. Topped up my portable kit. Also checked my beach clean first aid supplies and made sure those were up to date.
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Balanced Diet</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light">Check your diet</div>
                    <div className="badgePageItemEvidence"> 
                        <p>This is something I've been doing over the last year. My diet is fine and balanced with a flexitarian approach to food, I eat vegetarian and vegan regularly but not solely.</p>
                        <br/>
                        <p>I don't eat junk food or takeaways regularly - I simply don't buy it with the monthly shop, if it's not in the house I can't eat it!! I have one treat evening per week, which will be my weekly dose of crisps!</p>
                        <br/>
                        <p>I occassionally do a 16:8 fasting routine to keep everything in check.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HealthCheckJun23