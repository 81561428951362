import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const Fundraiser = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Fundraiser"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">01</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Calculate</div>
                    <div className="badgePageItemInfo reverse hidden">Work out what percentage of your salary you can afford to regularly donate to charity. What charity did you choose and why?</div>
                    <div className="badgePageItemEvidence">
                        <p>I make monthly donations to:</p>
                        <ul className="list">
                            <li>RNLI</li>
                            <li>Sufers Against Sewage</li>
                            <li>Marine Conservation Society</li>
                        </ul>
                        <p>I am also a supporter of NCI Hengistbury Head which I support through buying monthly lottery tickets on the BH Coastal Lottery.</p>
                        <p>I also make a monthly donation to The Lifeboat Station Project - Jack is visiting all the UK lifeboat stations and capturing the crews on glass plate. </p>
                        <br/>
                        <p>I chose my charities because they provide vital services either to people using our coast or to preserve the coast and waters. We are an island nation and it is vital to look after our homeland.</p>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">02</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Donate your time</div>
                     <div className="badgePageItemInfo reverse hidden">Donate your time to a good cause - over a period of three months, spend at least 24 hours volunteering.</div>
                    <div className="badgePageItemEvidence">
                        <p>I have backdated this clause in part as over the years I have donated a lot of time to charity.</p>
                        <p>Most recently, I worked on an artwork to donate to charity for auction - taking six months and ultimately raising £100 for Explorers Against Extinction.</p>
                        <br/>
                        <p>Since 2010, I have been involved in raising over 30k for various charities through performing with various choirs. I donated my time to help organise, to perform and to manage the advertising materials and promotion. These are some of the charities supported:</p>
                        <ul className="list">
                            <li>Diabetes UK</li>
                            <li>Help for Heroes</li>
                            <li>Macmillan</li>
                            <li>Autism Wessex</li>
                            <li>Dementia UK</li>
                            <li>British Heart Foundation</li>
                            <li>Breast Cancer Care</li>
                            <li>RNLI Mudeford</li>
                            <li>New Milton Memorial Centre</li>
                            <li>Naomi House and Jacksplace</li>
                        </ul>
                        <br/>
                        <p>I have also given time to fun runs for Alabare and Naomi House & Jacksplace.</p>
                        <p>I have also often donated time on Zooniverse.</p>
                        <br/>
                        <p>I volunteer my time weekly to beach clean and both monthly and quarterly to run beach cleaning groups.</p>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">03</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Sponsored event</div>
                    <div className="badgePageItemInfo reverse hidden">Take part in a sponsored event and where possible encourage at least two friends to join you in this challenge. Set individual and group target amounts.</div>
                    <div className="badgePageItemEvidence">
                        <p>This one is fully backdated as it's not something I am likely to do again.</p>
                        <br/>
                        <p>I took part in a Foam Fest fun run for Naomi House and Jacksplace with two friends. We did raise money through sponsorship, but I don't have a record of the amount. It went towards a total of over £3000 for that charity.</p>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">04</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Food bank</div>
                    <div className="badgePageItemInfo reverse hidden">Collect and donate food for a local food bank at least once a month for three months.</div>
                    <div className="badgePageItemEvidence">Evidence not required. Complete.</div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">05</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Non-donation support</div>
                    <div className="badgePageItemInfo reverse hidden">Research ways you can benefit charities without simply donating money - for example collecting used postage stamps.</div>
                    <div className="badgePageItemEvidence">
                        <ul className='list'>
                            <li>Donate your time to a charity - organising, fundraising etc.</li>
                            <li>Donate supplies to food banks, homelessness or animal charities</li>
                            <li>Advocate on behalf of charities to your MP and Government</li>
                            <li>Use Give As You Shop programs such as Amazon Smile</li>
                            <li>Donate clothes, shoes, household goods etc. through charity collections</li>
                            <li>Donate blood</li>
                            <li>Donate hair for companies who make wigs</li>
                            <li>Charity runs and fun runs</li>
                            <li>Purchase from charity shops instead of other retailers</li>
                            <li>Create a charity outreach team to connect people with charities</li>
                            <li>Some charities collect milk bottle tops</li>
                            <li>Some charities collect stamps - inc. Oxfam, Macmillan, Parkinsons UK, RNIB</li>
                            <li>Ink cartridges - these can be recycled and £1 given to charity for each cartridge recycled</li>
                            <li>Mobile phones - Some charities accept old mobile phones for recycling</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">06</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Spring clean</div>
                    <div className="badgePageItemInfo reverse hidden">Have a spring clean and donate clothes and bric-a-brac to your local charity shop.</div>
                    <div className="badgePageItemEvidence">
                        <p>I have donated about 10 bags of clothes and bric-a-brac recently through my spring clean!</p>
                        <p>These were collected by Anglo Doorstep Collections and went to Wendy's Wish.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Fundraiser