import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const CommunityService = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Community Service"} />
            <div className="badgePageItem">
                <summary className="badgePageItemTitle olive">Clause 1</summary>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Volunteer</div>
                    <div className="badgePageItemEvidence">
                        I have been doing this for quite some time and will be continuing to do so. This includes:
                        <ul>
                            <li>three days a week solo litter picks for approx 1.5 hours each</li>
                            <li>Once a month leading a litter pick for other people - 2 hours</li>
                            <li>Once a quarter leading a beach clean at one location for other people to attend - 2 hours</li>
                            <li>Once a quarter leading a beach clean at a second location for other people to attend - 2 hours</li>
                            <li>Ad-hoc extra time to help at community events</li>
                        </ul>
                        <br/><br/>
                        <table>
                            <thead>
                                <tr>
                                    <td>Date</td>
                                    <td>Activity</td>
                                    <td>Time Spent</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>19/8/2022</td>
                                    <td>Solo beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>20/8/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>27/8/2022</td>
                                    <td>Mudeford Quay Cleanup Crew monthly litter pick</td>
                                    <td>2 hours</td>
                                </tr>
                                <tr>
                                    <td>28/8/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>2/9/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>3/9/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>4/9/2022</td>
                                    <td>Friars Cliff Beach Care quarterly beach clean</td>
                                    <td>2 hours</td>
                                </tr>
                                <tr>
                                    <td>9/9/2022</td>
                                    <td>Solo Beach Clean</td>
                                    <td>1.5 hours</td>
                                </tr>
                                <tr>
                                    <td>24/9/2022</td>
                                    <td>Mudeford Quay Cleanup Crew monthly litter pick</td>
                                    <td>2 hours</td>
                                </tr>
                                <tr>
                                    <td>16/10/2022</td>
                                    <td>Highcliffe Beach Clean Team quarterly beach clean</td>
                                    <td>2 hours</td>
                                </tr>
                                <tr>
                                    <td>29/10/2022</td>
                                    <td>Mudeford Quay Cleanup Crew monthly litter pick</td>
                                    <td>2 hours</td>
                                </tr>
                                <tr>
                                    <td>13/11/2022</td>
                                    <td>Friars Cliff Beach Care quarterly beach clean</td>
                                    <td>2 hours</td>
                                </tr>
                                <tr>
                                    <td>26/11/2022</td>
                                    <td>Mudeford Quay Cleanup Crew monthly litter pick</td>
                                    <td>2 hours</td>
                                </tr>
                                <tr>
                                    <td>17/12/2022</td>
                                    <td>Mudeford Quay Cleanup Crew monthly litter pick</td>
                                    <td>2 hours</td>
                                </tr>
                                <tr>
                                    <td>8/1/2023</td>
                                    <td>Highcliffe Beach Clean Team quarterly beach clean</td>
                                    <td>2 hours</td>
                                </tr>
                                <tr>
                                    <td>5/2/2023</td>
                                    <td>Friars Cliff Beach Care quarterly beach clean</td>
                                    <td>2 hours</td>
                                </tr>
                                <tr>
                                    <td>25/2/2023</td>
                                    <td>Mudeford Quay Cleanup Crew monthly litter pick</td>
                                    <td>2 hours</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommunityService