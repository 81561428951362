import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Postcard1 from '../images/February24-1.jpg';
import Postcard2 from '../images/February24-2.jpg';
import Postcard3 from '../images/February24-3.jpg';
import Gift1 from '../images/February24-4.jpg';
import Envelope1 from '../images/February24-5.jpg';

const  February24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"You've got Rebel mail"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue-light">A parcel containing a homemade gift</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-lighter"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>Here's a little angel that I made, winging its way to a fellow rebel via the Rebel Pen Pals group.</p>
                        <br/>
                        <img src={Gift1} alt="An angel made out of glass pearl beads in a gift box ready to be sent." className='imgThird' />
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue-light">Something in a handmade envelope</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-lighter"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>Here's my handmade envelope. It was made using Pergamano parchment and techniques. The "something" inside was two butterflies, upcycled from a plastic bottle, painted with nail polish and decorated with beads.</p>
                        <br/>
                        <img src={Envelope1} alt="A pergamano envelope" className='imgThird' />
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue-light">A handmade postcard to another rebel</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-lighter"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>Here's my postcard. This was handmade by me - the design was for an assignment on one of my courses!</p>
                        <div className='gallery'>
                            <div className='galleryItem'><img src={Postcard1} alt="A postcard with a repeating pattern of mushrooms and leaves and the words Wonderful Winter." /></div>
                            <div className='galleryItem'><img src={Postcard2} alt="The front side of a handmade postcard with a repeating pattern of mushrooms and leaves and the words Wonderful Winter." /></div>
                            <div className='galleryItem'><img src={Postcard3} alt="The reverse side of a handmade postcard showing the blank writing and address areas." /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default February24