import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Tracker1 from '../images/CupMar24-Tracker1.jpg';
import Tracker2 from '../images/CupMar24-Tracker2.jpg';
import Tracker3 from '../images/CupMar24-Tracker3.jpg';
import Tracker4 from '../images/CupMar24-Tracker4.jpg';
import Tracker5 from '../images/CupMar24-Tracker5.jpg';
import TrackerDoc from '../documents/Cup-PointsTracker.pdf';
import Lantern1 from '../images/Cup-Mar24-Lantern1.jpg';
import Lantern2 from '../images/Cup-Mar24-Lantern2.jpg';
import Lantern3 from '../images/Cup-Mar24-Lantern3.jpg';
import Lantern4 from '../images/Cup-Mar24-Lantern4.jpg';
import Lantern5 from '../images/Cup-Mar24-Lantern5.jpg';
import Lantern6 from '../images/Cup-Mar24-Lantern6.jpg';
import Lantern7 from '../images/Cup-Mar24-Lantern7.jpg';
import Lantern8 from '../images/Cup-Mar24-Lantern8.jpg';
import Lantern9 from '../images/Cup-Mar24-Lantern9.jpg';
import Lantern10 from '../images/Cup-Mar24-Lantern10.jpg';
import Lantern11 from '../images/Cup-Mar24-Lantern11.jpg';
import Lantern12 from '../images/Cup-Mar24-Lantern12.jpg';
import Lantern14 from '../images/Cup-Mar24-Lantern14.jpg';
import Lantern15 from '../images/Cup-Mar24-Lantern15.jpg';
import Lantern16 from '../images/Cup-Mar24-Lantern16.jpg';
import Lantern17 from '../images/Cup-Mar24-Lantern17.jpg';
import Lantern18 from '../images/Cup-Mar24-Lantern18.jpg';
import Lantern19 from '../images/Cup-Mar24-Lantern19.jpg';
import Lantern20 from '../images/Cup-Mar24-Lantern20.jpg';
import Lantern21 from '../images/Cup-Mar24-Lantern21.jpg';
import Lantern22 from '../images/Cup-Mar24-Lantern22.jpg';
import Lantern23 from '../images/Cup-Mar24-Lantern23.jpg';
import Lantern24 from '../images/Cup-Mar24-Lantern24.jpg';
import Lantern25 from '../images/Cup-Mar24-Lantern25.jpg';
import Lantern26 from '../images/Cup-Mar24-Lantern26.jpg';
import Lantern27 from '../images/Cup-Mar24-Lantern27.jpg';
import Lantern28 from '../images/Cup-Mar24-Lantern28.jpg';
import Lantern29 from '../images/Cup-Mar24-Lantern29.mp4';
import Lantern30 from '../images/Cup-Mar24-Lantern30.jpg';

const CupMar24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Rebel Cup March 2024 Bonus Points"} />
            <div className="badgePageItem">Rebel Cup challenges are only posted in the facebook group for confirmed members. You will have to join the club to find out why I am doing these things!</div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Bonus 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light">Lanterns</div>
                    <div className="badgePageItemEvidence"> 
                        <p>I did a google search for inspiration and chose this design as the basis for my creation:</p>
                        <img src={Lantern30} alt="A chinese lantern fish - taken from Google images" className="imgThird" />
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Lantern1} alt="" /></div>
                            <div className="galleryItem"><img src={Lantern2} alt="" /></div>
                            <div className="galleryItem"><img src={Lantern3} alt="" /></div>
                            <div className="galleryItem"><img src={Lantern4} alt="" /></div>
                            <div className="galleryItem"><img src={Lantern5} alt="" /></div>
                            <div className="galleryItem"><img src={Lantern6} alt="" /></div>
                            <div className="galleryItem"><img src={Lantern7} alt="" /></div>
                            <div className="galleryItem"><img src={Lantern8} alt="" /></div>
                            <div className="galleryItem"><img src={Lantern9} alt="" /></div>
                            <div className="galleryItem"><img src={Lantern10} alt="" /></div>
                            <div className="galleryItem"><img src={Lantern11} alt="" /></div>
                            <div className="galleryItem"><img src={Lantern12} alt="" /></div>
                            <div className="galleryItem"><img src={Lantern14} alt="" /></div>
                            <div className="galleryItem"><img src={Lantern15} alt="" /></div>
                            <div className="galleryItem"><img src={Lantern16} alt="" /></div>
                            <div className="galleryItem"><img src={Lantern17} alt="" /></div>
                            <div className="galleryItem"><img src={Lantern18} alt="" /></div>
                            <div className="galleryItem"><img src={Lantern19} alt="" /></div>
                            <div className="galleryItem"><img src={Lantern20} alt="" /></div>
                            <div className="galleryItem"><img src={Lantern21} alt="" /></div>
                            <div className="galleryItem"><img src={Lantern22} alt="" /></div>
                            <div className="galleryItem"><img src={Lantern23} alt="" /></div>
                            <div className="galleryItem"><img src={Lantern24} alt="" /></div>
                            <div className="galleryItem"><img src={Lantern25} alt="" /></div>
                            <div className="galleryItem"><img src={Lantern26} alt="" /></div>
                            <div className="galleryItem"><img src={Lantern27} alt="" /></div>
                            <div className="galleryItem"><img src={Lantern28} alt="" /></div>
                        </div>                       
                        <br/>
                        <p>Here is a video of the finished lantern lit up with LEDs</p>
                        <video width="320" height="240" controls>
                            <source src={Lantern29} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Bonus 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light">Chart</div>
                    <div className="badgePageItemEvidence"> 
                        <p>I was trying hard to come up with something that other Rebels hadn't already done. In the end, I decided to lose the aesthetic of a pretty coluring in sheet and use a more structured sheet that can be cut out an dused to create a rosette at the end of the year.</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Tracker1} alt="A printed page with parts of a rosette that can be coloured in" /></div>
                            <div className="galleryItem"><img src={Tracker2} alt="Parts of a paper rosette cut and laid out on a table" /></div>
                            <div className="galleryItem"><img src={Tracker3} alt="Parts of a paper rosette cut and laid out on a table" /></div>
                            <div className="galleryItem"><img src={Tracker4} alt="Paper fan folded ready to make a rosette" /></div>
                            <div className="galleryItem"><img src={Tracker5} alt="A paper folded Rosette" /></div>
                        </div> 
                        <br/>
                        <p><a href={TrackerDoc} className="linkUnderline">Download the tracker pattern</a></p>                      
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CupMar24