import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Investor1 from '../images/investor1.jpg';
import Investor2 from '../images/investor2.jpg';
import Investor3 from '../images/investor3.jpg';
import Investor4 from '../images/investor4.jpg';

const Investor = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Investor"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">01</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Stock Markets</div>
                    <div className="badgePageItemEvidence">
                        <p><strong>Stock Markets</strong></p>
                        <br/><p>The stock market is a little like an auction house, where  investors buy and sell shares in stocks or equity. Rather than buying and selling from and to a company, you are buying from and selling to other investors.</p>
                        <p>Stocks provide shareholders voting rights as well as a residual clain on corporate earnings through capital gains and dividends.</p>
                        <p>There are two main types of stock:</p>
                        <ul className='list'>
                            <li>Common shares - have voting rights</li>
                            <li>Preferred shares - do not have voting rights</li>
                        </ul>
                        <br/>
                        <p>Investors negotiate on the price of shares by bidding and share brices are set by supply and demand.</p>
                        <br/><br/>
                        <p><strong>Stocks and Shares</strong></p>
                        <p>There is only a subtle difference in these two terms and they are often used interchangebly in financial markets. They refer to financial equities or securities that show ownership in a public company.</p>
                        <br/><p>Stocks is a more general term to refer to ownership in any company, whilst Shares refers to specific units of ownership in a specific company.</p>
                        <br/><br/>
                        <p><strong>Rise and fall of share prices</strong></p>
                        <p>Share prices are driven by supply and demand from investors. If more people want ot buy a stock, the price rises. If the supply is greater than the demand, the prices will fall.</p>
                        <p>This can be influenced by:</p>
                        <ul className='list'>
                            <li>Company performance: Demand can increase if investors think a company's performance will improve (rising profits, faster sales growth, new management). It can fall if the financial performance is disappointing, i.e. falling profits or management warnings of difficult times ahead.</li>
                            <li>Market sentiment: can be influenced by the news and can cause stock prices to go up or down. Positive news drives demand, negative news increases selling.</li>
                            <li>Economic indicators: inflation, unemployment, economic policy decisions - can all lead to fluctuations</li>
                            <li>Changes in supply: Sometimes companies buy their own shares to reduce supply or issue large numbers of new shares to increase supply.</li>
                            <li>Takeovers: When one company takes over another and buys all its shares. Usually agreed at a rate above current market price.</li>
                            <li>Last completed trade: each time a new trade is agreed, a company's stock price can change.</li>
                        </ul>
                        <br/><br/>
                        <p><strong>Dividends</strong></p>
                        <p>Dividends are a portion of a company's profit that it chooses to return to its shareholders and are one fo the ways that shareholders can earn money from their investment without having to sell shares.</p>
                        <br/><p>They are paid according to how much stock an investor owns and can be paid monthly, quarterly semi-annually or annually.</p>
                        <br/><p>The company's board of directors will decide and administer the dividends, but it must be approved by shareholders before it is announced officially.</p>
                        <br/><p>Some dividends are paid in cash, others may be paid with property or shares instead. Some companies choose to reinvest in the business rather than pay dividends.</p>
                        <br/><p>There are also special and preferred dividends that take priority over regular dividends.</p>
                        <br/><p>Special dividends are paid on common stock, but are only paid when a company wants to distribute accumulated profits after a number of years.</p>
                        <br/><p>Preferred dividends are issued to shareholders that own "preferred" stock - which acts more like a bond and has a fixed dividend amount.</p>
                        <br/><br/>
                        <p>Source: https://www.ig.com/uk/trading-strategies/what-are-dividends-and-how-do-they-work-190719</p>
                        <p>Source: https://www.investopedia.com/terms/d/dividend.asp</p>
                        <p>Source: https://www.forbes.com/advisor/investing/what-is-dividend/</p>
                        <br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">02</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Track share prices</div>
                    <div className="badgePageItemEvidence">
                        <p>From February - April 2023 I tracked the share prices of these companies:</p>
                        <ul className="list">
                            <li>Distell (DGH)</li>
                            <li>Google (GOOGL)</li>
                            <li>Bitcoin (BTC)</li>
                            <li>Amazon (AMZN)</li>
                            <li>Spotify (SPOT)</li>
                            <li>Meta (META)</li>
                        </ul>
                        <br/>
                        <p>Here are the opening and closing figures:</p><br/>
                        <table>
                            <thead>
                                <tr>
                                    <th>Company</th>
                                    <th>Symbol</th>
                                    <th>Feb £ / %change</th>
                                    <th>Apr £ / %change</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Distell</td>
                                    <td>DGH</td>
                                    <td>17,449 / 0.12%</td>
                                    <td>18,050 / -0.33%</td>
                                </tr>
                                <tr>
                                    <td>Google</td>
                                    <td>GOOGL</td>
                                    <td>100.85 / 0.02%</td>
                                    <td>108.22 / -0.14%</td>
                                </tr>
                                <tr>
                                    <td>Bitcoin</td>
                                    <td>BTC</td>
                                    <td>19,628.02 / </td>
                                    <td>23,443.48 / 0.17%</td>
                                </tr>
                                <tr>
                                    <td>Amazon</td>
                                    <td>AMZN</td>
                                    <td>105.77 / 0.06%</td>
                                    <td>105.45 / -3.98%</td>
                                </tr>
                                <tr>
                                    <td>Spotify</td>
                                    <td>SPOT</td>
                                    <td>118/75 / -0.33%</td>
                                    <td>133.6 / 1.08%</td>
                                </tr>
                                <tr>
                                    <td>Meta</td>
                                    <td>META</td>
                                    <td>153.12 / -0.12%</td>
                                    <td>240.32 / 0.74%</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/>
                        <p>The largest variances were a 14.92% change for Meta in Apr and a -36% change for Spotify in March.</p>
                        <br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">03</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Savings</div>
                    <div className="badgePageItemEvidence">
                        <br/><p>Looking at the different types of savings options, there are a few different types available, including:</p>
                        <br/><p><strong>Easy-access savings</strong><br/>Allow you to deposit and withdraw whenever you like</p>
                        <br/><p><strong>Easy-access ISAs</strong><br/>Save with tax-free interest</p>
                        <br/><p><strong>Lifetime ISAs</strong><br/>Provide a 25% savings bonus for first-time buyers</p>
                        <br/><p><strong>Help to Save</strong><br/>Provides a 50% savings bonus to low-income earners</p>
                        <br/><p><strong>Children's accounts</strong><br/>Good way for children to save with a higher rate of interest than adult savings accounts</p>
                        <br/><p><strong>Fixed-term</strong><br/>Locks your money for a period of time so you can't access it but provides a higher fixed interest rate</p>
                        <br/><p><strong>Notice savings</strong><br/>You can access your savings if you give notice before hand of withdrawals</p>
                        <br/><p><strong>Stocks and Shares ISAs</strong><br/>For investing in the stock market</p>
                        <br/><br/>
                        <p>The available types of savings bonds are:</p>
                        <ul className="list">
                            <li>Fixed rate bonds - fixed rate over the term</li>
                            <li>Tracker bonds - tracks the Bank of England base rate. Money is locked away for a defined period and returns vary</li>
                            <li>Income bonds - Interest paid at regular intervals like an income</li>
                            <li>Government bonds - Fixed rate through National Savings and Investments, backed by HM Treasury</li>
                            <li>Premium bonds - No interest, can win money in prize draws each month. Back by the Government</li>
                            <li>Green bonds - fixed return, money invested in green projects like renewable energy, conservation, clean transportation and climate change</li>
                        </ul>
                        <br/><br/>
                        <p>For saving with stocks and shares, there are various options including:</p>
                        <ul className='list'>
                            <li>Stocks & Shares ISAs - use all or part of an ISA account to invest in the stock market. You can invest in funds, bonds and shares. Don't pay dividend, capital gains or income tax on any gains or income from these investments.</li>
                            <li>Self-Invested Personal Pensions (SIPPs) - make your own investment decisions. Free from income and capital gains tax</li>
                            <li>General Trading or Investment Account - Shares and funds held in a general trading or investment account</li>
                            <li>Investment Platforms - e.g. AJ Bell, Hargreaves Lansdown, Freetrade</li>
                        </ul>
                        <br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">04</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Investment funds</div>
                    <div className="badgePageItemEvidence">
                        <p>An investment fund is a supply of capital that belongs to many people and is used to purchase securities collectively whilst ensuring each person maintains ownership and control of their own shares.</p>
                        <br/><p>The benefit of this is that it enables a wider range of investment opportunities, management expertise and lower investment fees than if you were to try and manage things on your own.</p>
                        <br/><p>They can include stocks, bonds and other securities</p>
                        <p>They can be broad and track funds like the S&P 500, or focus more specifically on something such as ETF that invests only in small technology stocks.</p>
                        <br/><p>Investors don't make the decisions about how their funds are invested, this is overseen by a fund manager who decides what should be invested where, what it holds, the quantities and when securities should be bought and sold.</p>
                        <br/><br/>
                        <p>These are three examples of investments funds:</p>
                        <br/><p><strong>Fundsmith Equity Fund</strong><br/>Ongoing charge: 0.10%<br/>Follows a three-step strategy:</p>
                        <ul className='list'>
                            <li>Buy good companies</li>
                            <li>Don't overpay</li>
                            <li>Do nothing</li>
                        </ul>
                        <p>Managed by Terry Smith, focus on investing in companies such as Microsoft and Visa and holding them for the long term.</p>
                        <br/><p>One of the most popular equity funds in the UK. </p>
                        <p>Holds only sahres in between twenty and thirty well established and durable companies on a buy and hold basis.</p>
                        <p>1% annual management fee, no performance fees.</p>
                        <br/><br/>
                        <p><strong>Vanguard US Equity Index Fund</strong><br/>Ongoing charge: 0.10%<br/>Strategy:</p>
                        <ul className='list'>
                            <li>Passive fund with an indexing investment strategy designed to track the performance of the S&P Total Market Index by investing in a sample of the component shares of the index.</li>
                        </ul>
                        <br/><p>Keeps costs low by constructing it sindex funds using less than the total number of assets in an index.</p>
                        <p>Offers funds that track a wide variety of market indices, large and small</p>
                        <p>Fees are kept low by using passively-managed funds and by replicating existing indices to lower research and analysis costs</p>
                        <p>Has large economies of scale lowering costs for the company and passing savings on to customers.</p>
                        <br/><br/>
                        <p><strong>Fidelity UK Smaller Companies Fund</strong><br/>Ongoing charge: 0.92%<br/>Strategy:</p>
                        <ul className='list'>
                            <li>Invests at least 60% in equities and related securities of smaller sized UK companies</li>
                        </ul>
                        <br/>
                        <p>Focus on companies believed to be undervalued, not restricted by industry but not recognised as having recovery potential</p>
                        <p>May also invest in other transferable securities, collective investment schemes, money market instruments, cash and deposits.</p>
                        <br/><br/>
                        <p>Source: https://www.jonathanlea.net/blog/the-fundsmith-equity-funds-investment-strateg/</p>
                        <p>Source: https://www.investopedia.com/articles/investing/111715/how-vanguard-index-funds-work.asp</p>
                        <p>Source: https://www.trustnet.com/factsheets/o/gmug/fidelity-uk-smaller-companies</p>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">05</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Pensions</div>
                    <div className="badgePageItemEvidence">
                        <p>Some of the different pension options include:</p>
                        <br/><p><strong>Workplace pensions</strong><br/>Arranged by employers, often paid into by both you and your employer. Depends on the type of scheme offered.</p>
                        <br/><p><strong>Personal and stakeholder pensions</strong><br/>Private pensions. Employers can pay into them as a workplace pension scheme. Depends on how much is paid in and how well the investment performs</p>
                        <br/><p><strong>State pension from the Government</strong><br/>Depends on your National Insurance contributions and credits</p>
                        <br/><p><strong>Pension Credit</strong><br/>For people on a low income, tops up weekly income. Extra may be paid to  carers, severely disabled people, people with responsiblity for a child or with certain housing costs.</p>
                        <br/><p><strong>Protected payment</strong><br/>Any amunt over the state pension that you get from your NI contributions or credits before 6/4/2016 is protected. Paid on top of the new state pension.</p>
                        <br/><br/>
                        <p>I don't have a Financial Advisor, but I do have pension plans. I am not detailing that here due to the personal and sensitive nature of the information. There are probably things I could do to improve my pension prospects and I will be investigating that to ensure my future is secure.</p>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">06</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Read and listen</div>
                    <div className="badgePageItemEvidence">
                        <p>Quite some time ago, after a conversation with my Dad, he gifted me the book "Investing for Dummies". Clearly, he has understood my expertise level in this!! I never got round to reading it, so this clause has given me the reason to get round to it finally!</p>
                        <img src={Investor1} alt="The cover of a book titled Investing for Dummies" className="galleryItem" />
                        <p>I picked three podcast episodes on Spotify to listen to:</p>
                        <ul className="list">
                            <li>How to start investing in the UK - Stocks and Savings</li>
                            <li>Investing (For Beginners) - The Art of Money and Communication</li>
                            <li>Investing Mistakes Beginners Make - Girls that Invest</li>
                        </ul>
                        <br/>
                        <div className='gallery'>
                            <div className='galleryItem'><img src={Investor2} alt="Screenshot of a podcast episode on Spotify, showing two people and the words Stocks & Savings." /></div>
                            <div className='galleryItem'><img src={Investor3} alt="Screenshot of a podcast episode on Spotify, showing a man holding a jar of money and the words The Art of Money and Communication." /></div>
                            <div className='galleryItem'><img src={Investor4} alt="Screenshot of a podcast episode on Spotify, showing the worlds Girls that Invest - Investing Education" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Investor