import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import Rebel from "../images/rebel-logo.png";

const Book = () => {

useEffect(() => {
        const elements = document.querySelectorAll(".badgeItem");
        var currDate = new Date();

        elements.forEach((el) => {

            var oVal = 0;
            var oProg;
            var mProg;
            var count =  el.querySelectorAll(".badgeClauseStatus").length;
            var maxval = 100 / count;

            el.querySelectorAll(".badgeClauseStatus").forEach((elS) => {
                var cID = elS.id;
                var cStatus = elS.textContent;
                var cStart = el.querySelector("#" + cID.replace("Status","Start")).textContent;
                var cEnd = el.querySelector("#" + cID.replace("Status","End")).textContent;
                var cProg = el.querySelector("#" + cID.replace("Status","Progress"));
                oProg = el.querySelector("#" + cID.substring(0, cID.length-7).replace("1","") + "Progress");
                mProg = el.querySelector("#" + cID.substring(0, cID.length-7).replace("1","") + "ProgressMini");

                if (cStatus === "In progress")
                {
                    if (cEnd === "" && cStart !== "")
                    {
                        var startString = cStart.split("/");
                        var startDate = new Date(startString[2],startString[1]-1,startString[0]);
                        var diff = new Date(startDate - currDate);
                        var days =  Math.ceil(diff/1000/60/60/24);
                        var maxdays = -90;
                        cProg.value = (Math.ceil(days/maxdays*100));

                        var setP = days * maxdays / 100;

                        if(setP > maxval)
                        {
                            setP = maxval / 2;
                        }
                        oVal = oVal + setP;

                        cProg.classList.add("orange");
                        cProg.classList.remove("green");
                        cProg.classList.remove("red");
                    }
                    else if (cEnd !== "" && cStart !== "")
                    {
                        cProg.value = 100;
                        cProg.classList.add("green");
                        cProg.classList.remove("orange");
                        cProg.classList.remove("red");
                        oVal = oVal + maxval;
                    }
                }
                else if (cStatus === "Complete")
                {
                    cProg.value = 100;
                    cProg.classList.add("green");
                    cProg.classList.remove("orange");
                    cProg.classList.remove("red");
                    oVal = oVal + maxval;
                }
                else if (cStatus === "Not started")
                {
                    cProg.value = 1;
                    cProg.classList.remove("green");
                    cProg.classList.remove("orange");
                    cProg.classList.add("red");
                    oVal = oVal + 0;
                }
            })

            var oCalc = Math.ceil(oVal);
            oProg.value = oCalc;
            mProg.value = oCalc;

            
            if(oCalc <= 1)
            {
                oProg.classList.add("red");
                oProg.classList.remove("orange");
                oProg.classList.remove("green");
                mProg.classList.add("red");
                mProg.classList.remove("orange");
                mProg.classList.remove("green");
            }
            else if (oCalc < 100)
            {
                oProg.classList.remove("red");
                oProg.classList.add("orange");
                oProg.classList.remove("green");
                mProg.classList.remove("red");
                mProg.classList.add("orange");
                mProg.classList.remove("green");
            }
            else
            {
                oProg.classList.remove("red");
                oProg.classList.remove("orange");
                oProg.classList.add("green");
                mProg.classList.remove("red");
                mProg.classList.remove("orange");
                mProg.classList.add("green");
            }    
        })


        document.querySelectorAll(".badgeItemIntro").forEach((elI) => {elI.classList.add("hidden")})
        document.querySelectorAll(".badgeClause").forEach((elC) => {elC.classList.add("hidden")})
        document.querySelectorAll(".badgeProgress").forEach((elP) => {elP.classList.add("hidden")})
        document.querySelectorAll(".icon-down-open").forEach((elID) => {elID.classList.add("shown")})
        document.querySelectorAll(".icon-up-open").forEach((elIO) => {elIO.classList.add("hidden")})
        document.querySelectorAll(".badgeItem").forEach((elBI) => {elBI.classList.add("hidden")})


        document.querySelectorAll(".badgeItemTitle").forEach((mit) => {addListener(mit);})
        document.querySelectorAll(".extrasYear").forEach((bi) => {addListener(bi);})

    }, [])

    return (
        <div>
        <div className="actionBar">
            <div className="actionBarItem"><Link to="/">Back to Dashboard</Link></div>
        </div>
            <div className="row bookPageTop">
                <div className="rebelLeft"><img src={Rebel} alt="Sticker of the word rebel" className="rebelLogoBookLeft" /></div>
                <div className="rebelMid">
                    <h2>Extra challenges and badges</h2>
                </div>
                <div className="rebelRight"><img src={Rebel} alt="Sticker of the word rebel" className="rebelLogoBookRight" /></div>
            </div>
            <div className="row pageIntroduction">
                <p><br/>This page follows my progress in completing extra challenges and badges. These aren't in the books</p>
                <p><br/>The details of these are usually available on the <a href="https://www.rebelbadgestore.com/rebelblog" target="_blank" rel="noopener noreferrer">Rebel Badge Book Blog</a> so I haven't hidden anything here - if you want to see the full details, go and check out the blog and buy the <a className="linkUnderline"  href="https://www.rebelbadgestore.com/" target="_blank" rel="noopener noreferrer">Rebel Badge Book</a> while you're there &#x1F609;</p>
                <p><br/>Where details aren't publicly available through the official site, I won't publish them here. You'll just have to get your book and join the group for the instructions!</p>
            </div>
            <div className="extras">
                <div className="row extrasrow">Monthly badges</div>
                <div className="row extrasYear">
                    <div className="extrasYear-Title">2022<span className="icon-down-open shown"></span></div>
                    <div className="badgeItem">
                        <div className="badgeItemTitle orange" tabIndex={0} role="button" aria-expanded="false">
                            <div>Halloween - October 2022</div>
                            <div>
                                <progress id="halloween22ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span></div>
                            </div>
                        <div className="badgeItemIntro orange-light">Complete at least eight of sixteen clauses <Link to="/halloween22">View evidence page</Link></div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Task 1</div>
                            <div className="badgeClauseStatus" id="halloween221Status">Complete</div>
                            <div className="badgeClauseStart" id="halloween221Start">31/10/2022</div>
                            <div className="badgeClauseEnd" id="halloween221End">31/10/2022</div>
                            <div className="badgeClauseProgress"><progress id="halloween221Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Task 2</div>
                            <div className="badgeClauseStatus" id="halloween222Status">Complete</div>
                            <div className="badgeClauseStart" id="halloween222Start">2/11/2022</div>
                            <div className="badgeClauseEnd" id="halloween222End">3/11/2022</div>
                            <div className="badgeClauseProgress"><progress id="halloween222Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Task 3</div>
                            <div className="badgeClauseStatus" id="halloween223Status">Complete</div>
                            <div className="badgeClauseStart" id="halloween223Start">25/10/2022</div>
                            <div className="badgeClauseEnd" id="halloween223End">31/10/2022</div>
                            <div className="badgeClauseProgress"><progress id="halloween223Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Task 4</div>
                            <div className="badgeClauseStatus" id="halloween224Status">Complete</div>
                            <div className="badgeClauseStart" id="halloween224Start">31/10/2022</div>
                            <div className="badgeClauseEnd" id="halloween224End">31/10/2022</div>
                            <div className="badgeClauseProgress"><progress id="halloween224Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Task 5</div>
                            <div className="badgeClauseStatus" id="halloween225Status">Complete</div>
                            <div className="badgeClauseStart" id="halloween225Start">31/10/2022</div>
                            <div className="badgeClauseEnd" id="halloween225End">31/10/2022</div>
                            <div className="badgeClauseProgress"><progress id="halloween225Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Task 6</div>
                            <div className="badgeClauseStatus" id="halloween226Status">Complete</div>
                            <div className="badgeClauseStart" id="halloween226Start">31/10/2022</div>
                            <div className="badgeClauseEnd" id="halloween226End">31/10/2022</div>
                            <div className="badgeClauseProgress"><progress id="halloween226Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Task 7</div>
                            <div className="badgeClauseStatus" id="halloween227Status">Complete</div>
                            <div className="badgeClauseStart" id="halloween227Start">2/11/2022</div>
                            <div className="badgeClauseEnd" id="halloween227End">3/11/2022</div>
                            <div className="badgeClauseProgress"><progress id="halloween227Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Task 8</div>
                            <div className="badgeClauseStatus" id="halloween228Status">Complete</div>
                            <div className="badgeClauseStart" id="halloween228Start">31/10/2022</div>
                            <div className="badgeClauseEnd" id="halloween228End">31/10/2022</div>
                            <div className="badgeClauseProgress"><progress id="halloween228Progress" value="25" max="100"></progress></div>
                        </div>
                    <div className="badgeProgress"><progress id="halloween22Progress" value="25" max="100"></progress></div>
                    </div>
                </div>
                <div className="row extrasYear">
                    <div className="extrasYear-Title">2023<span className="icon-down-open shown"></span></div>
                    <div className="badgeItem">
                        <div className="badgeItemTitle purple" tabIndex={0} role="button" aria-expanded="false">
                            <div>Write Every Day - November 2022</div>
                            <div>
                                <progress id="write22ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span></div>
                            </div>
                        <div className="badgeItemIntro purple-light">For the month, write every day <Link to="/write22">View evidence page</Link></div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Write every day</div>
                            <div className="badgeClauseStatus" id="write221Status">Complete</div>
                            <div className="badgeClauseStart" id="write221Start">01/11/2022</div>
                            <div className="badgeClauseEnd" id="write221End">30/11/2022</div>
                            <div className="badgeClauseProgress"><progress id="write221Progress" value="25" max="100"></progress></div>
                        </div>
                    <div className="badgeProgress"><progress id="write22Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                        <div className="badgeItemTitle green" tabIndex={0} role="button" aria-expanded="false">
                            <div>Christmas - December 2022</div>
                            <div>
                                <progress id="xmas22ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span></div>
                            </div>
                        <div className="badgeItemIntro green-light">Over the month, complete at least 8 of 16 clauses <Link to="/christmas22">View evidence page</Link></div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Task 1</div>
                            <div className="badgeClauseStatus" id="xmas221Status">Complete</div>
                            <div className="badgeClauseStart" id="xmas221Start">19/12/2022</div>
                            <div className="badgeClauseEnd" id="xmas221End">19/12/2022</div>
                            <div className="badgeClauseProgress"><progress id="xmas221Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Task 2</div>
                            <div className="badgeClauseStatus" id="xmas222Status">Complete</div>
                            <div className="badgeClauseStart" id="xmas222Start">3/12/2022</div>
                            <div className="badgeClauseEnd" id="xmas222End">3/12/2022</div>
                            <div className="badgeClauseProgress"><progress id="xmas222Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Task 3</div>
                            <div className="badgeClauseStatus" id="xmas223Status">Complete</div>
                            <div className="badgeClauseStart" id="xmas223Start">20/12/2022</div>
                            <div className="badgeClauseEnd" id="xmas223End">20/12/2022</div>
                            <div className="badgeClauseProgress"><progress id="xmas223Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Task 4</div>
                            <div className="badgeClauseStatus" id="xmas224Status">Complete</div>
                            <div className="badgeClauseStart" id="xmas224Start">20/12/2022</div>
                            <div className="badgeClauseEnd" id="xmas224End">20/12/2022</div>
                            <div className="badgeClauseProgress"><progress id="xmas224Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Task 5</div>
                            <div className="badgeClauseStatus" id="xmas225Status">Complete</div>
                            <div className="badgeClauseStart" id="xmas225Start">20/12/2022</div>
                            <div className="badgeClauseEnd" id="xmas225End">20/12/2022</div>
                            <div className="badgeClauseProgress"><progress id="xmas225Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Task 6</div>
                            <div className="badgeClauseStatus" id="xmas226Status">Complete</div>
                            <div className="badgeClauseStart" id="xmas226Start">3/12/2022</div>
                            <div className="badgeClauseEnd" id="xmas226End">3/12/2022</div>
                            <div className="badgeClauseProgress"><progress id="xmas226Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Task 7</div>
                            <div className="badgeClauseStatus" id="xmas227Status">Complete</div>
                            <div className="badgeClauseStart" id="xmas227Start">1/12/2022</div>
                            <div className="badgeClauseEnd" id="xmas227End">1/12/2022</div>
                            <div className="badgeClauseProgress"><progress id="xmas227Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Task 8</div>
                            <div className="badgeClauseStatus" id="xmas228Status">Complete</div>
                            <div className="badgeClauseStart" id="xmas228Start">20/12/2022</div>
                            <div className="badgeClauseEnd" id="xmas228End">20/12/2022</div>
                            <div className="badgeClauseProgress"><progress id="xmas228Progress" value="25" max="100"></progress></div>
                        </div>
                    <div className="badgeProgress"><progress id="xmas22Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                            <div className="badgeItemTitle green-light" tabIndex={0} role="button" aria-expanded="false">
                                <div>Exercise Every Day - January 2023</div>
                                <div>
                                    <progress id="exercisejan23ProgressMini" value="25" max="100"></progress>
                                    <span className="icon-down-open"></span></div>
                                </div>
                            <div className="badgeItemIntro green-lighter">Exercise every day. <Link to="/exercisejan23">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Task 1</div>
                                <div className="badgeClauseStatus" id="exercisejan231Status">Complete</div>
                                <div className="badgeClauseStart" id="exercisejan231Start">01/02/2023</div>
                                <div className="badgeClauseEnd" id="exercisejan231End">28/02/2023</div>
                                <div className="badgeClauseProgress"><progress id="exercisejan231Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="exercisejan23Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                            <div className="badgeItemTitle pink-light" tabIndex={0} role="button" aria-expanded="false">
                                <div>The Love Badge - February 2023</div>
                                <div>
                                    <progress id="lovefeb23ProgressMini" value="25" max="100"></progress>
                                    <span className="icon-down-open"></span></div>
                                </div>
                            <div className="badgeItemIntro pink-lighter">The Love Badge.<br/><Link to="/lovefeb23">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Treat yourself once a week</div>
                                <div className="badgeClauseStatus" id="lovefeb231Status">Complete</div>
                                <div className="badgeClauseStart" id="lovefeb231Start">01/02/2023</div>
                                <div className="badgeClauseEnd" id="lovefeb231End">28/02/2023</div>
                                <div className="badgeClauseProgress"><progress id="lovefeb231Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Make a rose</div>
                                <div className="badgeClauseStatus" id="lovefeb232Status">Complete</div>
                                <div className="badgeClauseStart" id="lovefeb232Start">12/2/2023</div>
                                <div className="badgeClauseEnd" id="lovefeb232End">12/2/2023</div>
                                <div className="badgeClauseProgress"><progress id="lovefeb232Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Flowers</div>
                                <div className="badgeClauseStatus" id="lovefeb233Status">Complete</div>
                                <div className="badgeClauseStart" id="lovefeb233Start">10/2/2023</div>
                                <div className="badgeClauseEnd" id="lovefeb233End">10/2/2023</div>
                                <div className="badgeClauseProgress"><progress id="lovefeb233Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Love languages</div>
                                <div className="badgeClauseStatus" id="lovefeb234Status">Complete</div>
                                <div className="badgeClauseStart" id="lovefeb234Start">11/2/2023</div>
                                <div className="badgeClauseEnd" id="lovefeb234End">11/2/2023</div>
                                <div className="badgeClauseProgress"><progress id="lovefeb234Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">History</div>
                                <div className="badgeClauseStatus" id="lovefeb235Status">Complete</div>
                                <div className="badgeClauseStart" id="lovefeb235Start">11/2/2023</div>
                                <div className="badgeClauseEnd" id="lovefeb235End">11/2/2023</div>
                                <div className="badgeClauseProgress"><progress id="lovefeb235Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Cupid</div>
                                <div className="badgeClauseStatus" id="lovefeb236Status">Complete</div>
                                <div className="badgeClauseStart" id="lovefeb236Start">14/2/2023</div>
                                <div className="badgeClauseEnd" id="lovefeb236End">14/2/2023</div>
                                <div className="badgeClauseProgress"><progress id="lovefeb236Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Hearts</div>
                                <div className="badgeClauseStatus" id="lovefeb237Status">Complete</div>
                                <div className="badgeClauseStart" id="lovefeb237Start">11/2/2023</div>
                                <div className="badgeClauseEnd" id="lovefeb237End">11/2/2023</div>
                                <div className="badgeClauseProgress"><progress id="lovefeb237Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="lovefeb23Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                            <div className="badgeItemTitle blue-light" tabIndex={0} role="button" aria-expanded="false">
                                <div>Be a Hero - March 2023</div>
                                <div>
                                    <progress id="heromar23ProgressMini" value="25" max="100"></progress>
                                    <span className="icon-down-open"></span></div>
                                </div>
                            <div className="badgeItemIntro blue-lighter">Complete 7 clauses<br/><Link to="/heromar23">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">My Powers</div>
                                <div className="badgeClauseStatus" id="heromar231Status">Complete</div>
                                <div className="badgeClauseStart" id="heromar231Start">01/03/2023</div>
                                <div className="badgeClauseEnd" id="heromar231End">31/03/2023</div>
                                <div className="badgeClauseProgress"><progress id="heromar231Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Equity and Equality</div>
                                <div className="badgeClauseStatus" id="heromar232Status">Complete</div>
                                <div className="badgeClauseStart" id="heromar232Start">01/03/2023</div>
                                <div className="badgeClauseEnd" id="heromar232End">31/03/2023</div>
                                <div className="badgeClauseProgress"><progress id="heromar232Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Suffrage</div>
                                <div className="badgeClauseStatus" id="heromar233Status">Complete</div>
                                <div className="badgeClauseStart" id="heromar233Start">01/03/2023</div>
                                <div className="badgeClauseEnd" id="heromar233End">31/03/2023</div>
                                <div className="badgeClauseProgress"><progress id="heromar233Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Period Poverty</div>
                                <div className="badgeClauseStatus" id="heromar234Status">Complete</div>
                                <div className="badgeClauseStart" id="heromar234Start">01/03/2023</div>
                                <div className="badgeClauseEnd" id="heromar234End">31/03/2023</div>
                                <div className="badgeClauseProgress"><progress id="heromar234Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">IWD</div>
                                <div className="badgeClauseStatus" id="heromar235Status">Complete</div>
                                <div className="badgeClauseStart" id="heromar235Start">01/03/2023</div>
                                <div className="badgeClauseEnd" id="heromar235End">31/03/2023</div>
                                <div className="badgeClauseProgress"><progress id="heromar235Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Women's Rights</div>
                                <div className="badgeClauseStatus" id="heromar236Status">Complete</div>
                                <div className="badgeClauseStart" id="heromar236Start">01/03/2023</div>
                                <div className="badgeClauseEnd" id="heromar236End">31/03/2023</div>
                                <div className="badgeClauseProgress"><progress id="heromar236Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Intersectional Feminism</div>
                                <div className="badgeClauseStatus" id="heromar237Status">Complete</div>
                                <div className="badgeClauseStart" id="heromar237Start">01/03/2023</div>
                                <div className="badgeClauseEnd" id="heromar237End">31/03/2023</div>
                                <div className="badgeClauseProgress"><progress id="heromar237Progress" value="25" max="100"></progress></div>
                            </div>
                            
                        <div className="badgeProgress"><progress id="heromar23Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                            <div className="badgeItemTitle green-light" tabIndex={0} role="button" aria-expanded="false">
                                <div>The Chocolate Challenge - April 2023</div>
                                <div>
                                    <progress id="chocapr23ProgressMini" value="25" max="100"></progress>
                                    <span className="icon-down-open"></span></div>
                                </div>
                            <div className="badgeItemIntro green-lighter">Complete 8 of 16 clauses<br/><Link to="/chocapr23">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Clause 1</div>
                                <div className="badgeClauseStatus" id="chocapr231Status">Complete</div>
                                <div className="badgeClauseStart" id="chocapr231Start">28/4/2023</div>
                                <div className="badgeClauseEnd" id="chocapr231End">28/4/2023</div>
                                <div className="badgeClauseProgress"><progress id="chocapr231Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Clause 2</div>
                                <div className="badgeClauseStatus" id="chocapr232Status">Complete</div>
                                <div className="badgeClauseStart" id="chocapr232Start">28/4/2023</div>
                                <div className="badgeClauseEnd" id="chocapr232End">28/4/2023</div>
                                <div className="badgeClauseProgress"><progress id="chocapr232Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Clause 3</div>
                                <div className="badgeClauseStatus" id="chocapr233Status">Complete</div>
                                <div className="badgeClauseStart" id="chocapr233Start">2/4/2023</div>
                                <div className="badgeClauseEnd" id="chocapr233End">8/4/2023</div>
                                <div className="badgeClauseProgress"><progress id="chocapr233Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Clause 4</div>
                                <div className="badgeClauseStatus" id="chocapr234Status">Complete</div>
                                <div className="badgeClauseStart" id="chocapr234Start">28/4/2023</div>
                                <div className="badgeClauseEnd" id="chocapr234End">28/4/2023</div>
                                <div className="badgeClauseProgress"><progress id="chocapr234Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Clause 5</div>
                                <div className="badgeClauseStatus" id="chocapr235Status">Complete</div>
                                <div className="badgeClauseStart" id="chocapr235Start">02/04/2023</div>
                                <div className="badgeClauseEnd" id="chocapr235End">7/4/2023</div>
                                <div className="badgeClauseProgress"><progress id="chocapr235Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Clause 6</div>
                                <div className="badgeClauseStatus" id="chocapr236Status">Complete</div>
                                <div className="badgeClauseStart" id="chocapr236Start">02/04/2023</div>
                                <div className="badgeClauseEnd" id="chocapr236End">7/4/2023</div>
                                <div className="badgeClauseProgress"><progress id="chocapr236Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Clause 7</div>
                                <div className="badgeClauseStatus" id="chocapr237Status">Complete</div>
                                <div className="badgeClauseStart" id="chocapr237Start">02/04/2023</div>
                                <div className="badgeClauseEnd" id="chocapr237End">8/4/2023</div>
                                <div className="badgeClauseProgress"><progress id="chocapr237Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Clause 8</div>
                                <div className="badgeClauseStatus" id="chocapr238Status">Complete</div>
                                <div className="badgeClauseStart" id="chocapr238Start">02/04/2023</div>
                                <div className="badgeClauseEnd" id="chocapr238End">8/4/2023</div>
                                <div className="badgeClauseProgress"><progress id="chocapr238Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="chocapr23Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                            <div className="badgeItemTitle pink" tabIndex={0} role="button" aria-expanded="false">
                                <div>Rebel with a Cause - May 2023</div>
                                <div>
                                    <progress id="causemay23ProgressMini" value="25" max="100"></progress>
                                    <span className="icon-down-open"></span></div>
                                </div>
                            <div className="badgeItemIntro pink-lighter">Complete 1 of 3 clauses<br/><Link to="/causemay23">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Clause 1</div>
                                <div className="badgeClauseStatus" id="causemay231Status">Complete</div>
                                <div className="badgeClauseStart" id="causemay231Start">1/5/2023</div>
                                <div className="badgeClauseEnd" id="causemay231End">31/5/2023</div>
                                <div className="badgeClauseProgress"><progress id="causemay231Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="causemay23Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                            <div className="badgeItemTitle green-light" tabIndex={0} role="button" aria-expanded="false">
                                <div>Rebel Health Check - June 2023</div>
                                <div>
                                    <progress id="healthcheckjun23ProgressMini" value="25" max="100"></progress>
                                    <span className="icon-down-open"></span></div>
                                </div>
                            <div className="badgeItemIntro green-lighter">Complete 8 of 16 clauses<br/><Link to="/healthcheckjun23">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Clause 1</div>
                                <div className="badgeClauseStatus" id="healthcheckjun231Status">Complete</div>
                                <div className="badgeClauseStart" id="healthcheckjun231Start">1/6/2023</div>
                                <div className="badgeClauseEnd" id="healthcheckjun231End">30/6/2023</div>
                                <div className="badgeClauseProgress"><progress id="healthcheckjun231Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Clause 2</div>
                                <div className="badgeClauseStatus" id="healthcheckjun232Status">Complete</div>
                                <div className="badgeClauseStart" id="healthcheckjun232Start">1/6/2023</div>
                                <div className="badgeClauseEnd" id="healthcheckjun232End">30/6/2023</div>
                                <div className="badgeClauseProgress"><progress id="healthcheckjun232Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Clause 3</div>
                                <div className="badgeClauseStatus" id="healthcheckjun233Status">Complete</div>
                                <div className="badgeClauseStart" id="healthcheckjun233Start">1/6/2023</div>
                                <div className="badgeClauseEnd" id="healthcheckjun233End">30/6/2023</div>
                                <div className="badgeClauseProgress"><progress id="healthcheckjun233Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Clause 4</div>
                                <div className="badgeClauseStatus" id="healthcheckjun234Status">Complete</div>
                                <div className="badgeClauseStart" id="healthcheckjun234Start">1/6/2023</div>
                                <div className="badgeClauseEnd" id="healthcheckjun234End">30/6/2023</div>
                                <div className="badgeClauseProgress"><progress id="healthcheckjun234Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Clause 5</div>
                                <div className="badgeClauseStatus" id="healthcheckjun235Status">Complete</div>
                                <div className="badgeClauseStart" id="healthcheckjun235Start">1/6/2023</div>
                                <div className="badgeClauseEnd" id="healthcheckjun235End">30/6/2023</div>
                                <div className="badgeClauseProgress"><progress id="healthcheckjun235Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Clause 6</div>
                                <div className="badgeClauseStatus" id="healthcheckjun236Status">Complete</div>
                                <div className="badgeClauseStart" id="healthcheckjun236Start">1/6/2023</div>
                                <div className="badgeClauseEnd" id="healthcheckjun236End">30/6/2023</div>
                                <div className="badgeClauseProgress"><progress id="healthcheckjun236Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Clause 7</div>
                                <div className="badgeClauseStatus" id="healthcheckjun237Status">Complete</div>
                                <div className="badgeClauseStart" id="healthcheckjun237Start">1/6/2023</div>
                                <div className="badgeClauseEnd" id="healthcheckjun237End">30/6/2023</div>
                                <div className="badgeClauseProgress"><progress id="healthcheckjun237Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Clause 8</div>
                                <div className="badgeClauseStatus" id="healthcheckjun238Status">Complete</div>
                                <div className="badgeClauseStart" id="healthcheckjun238Start">1/6/2023</div>
                                <div className="badgeClauseEnd" id="healthcheckjun238End">30/6/2023</div>
                                <div className="badgeClauseProgress"><progress id="healthcheckjun238Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="healthcheckjun23Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                            <div className="badgeItemTitle pink" tabIndex={0} role="button" aria-expanded="false">
                                <div>Big Kid Summer - July 2023</div>
                                <div>
                                    <progress id="bigkidjul23ProgressMini" value="25" max="100"></progress>
                                    <span className="icon-down-open"></span></div>
                                </div>
                            <div className="badgeItemIntro blue-lighter">Complete any of 30 clauses<br/><Link to="/bigkidjul23">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Challenges</div>
                                <div className="badgeClauseStatus" id="bigkidjul231Status">Complete</div>
                                <div className="badgeClauseStart" id="bigkidjul231Start">1/7/2023</div>
                                <div className="badgeClauseEnd" id="bigkidjul231End">31/7/2023</div>
                                <div className="badgeClauseProgress"><progress id="bigkidjul231Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="bigkidjul23Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                            <div className="badgeItemTitle pink" tabIndex={0} role="button" aria-expanded="false">
                                <div>Scavenger Hunt - August 2023</div>
                                <div>
                                    <progress id="scavengeraug23ProgressMini" value="25" max="100"></progress>
                                    <span className="icon-down-open"></span></div>
                                </div>
                            <div className="badgeItemIntro blue-lighter">Collect any of 30 items<br/><Link to="/scavengeraug23">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Challenges</div>
                                <div className="badgeClauseStatus" id="scavengeraug231Status">Complete</div>
                                <div className="badgeClauseStart" id="scavengeraug231Start">1/8/2023</div>
                                <div className="badgeClauseEnd" id="scavengeraug231End">31/8/2023</div>
                                <div className="badgeClauseProgress"><progress id="scavengeraug231Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="scavengeraug23Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                            <div className="badgeItemTitle blue" tabIndex={0} role="button" aria-expanded="false">
                                <div>Scrapheap Challenge - September 2023</div>
                                <div>
                                    <progress id="scrapheapaug23ProgressMini" value="25" max="100"></progress>
                                    <span className="icon-down-open"></span></div>
                                </div>
                            <div className="badgeItemIntro blue-lighter">Complete either of two challenges<br/><Link to="/scrapheapsep23">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Challenge</div>
                                <div className="badgeClauseStatus" id="scrapheapaug231Status">Complete</div>
                                <div className="badgeClauseStart" id="scrapheapaug231Start">1/9/2023</div>
                                <div className="badgeClauseEnd" id="scrapheapaug231End">30/9/2023</div>
                                <div className="badgeClauseProgress"><progress id="scrapheapaug231Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="scrapheapaug23Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                            <div className="badgeItemTitle orange" tabIndex={0} role="button" aria-expanded="false">
                                <div>Halloween - October 2023</div>
                                <div>
                                    <progress id="halloween23ProgressMini" value="25" max="100"></progress>
                                    <span className="icon-down-open"></span></div>
                                </div>
                            <div className="badgeItemIntro orange-lighter">Complete eight of sixteen challenges<br/><Link to="/halloween23">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Challenge</div>
                                <div className="badgeClauseStatus" id="halloween231Status">Complete</div>
                                <div className="badgeClauseStart" id="halloween231Start">01/10/2023</div>
                                <div className="badgeClauseEnd" id="halloween231End">21/10/2023</div>
                                <div className="badgeClauseProgress"><progress id="halloween231Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Challenge</div>
                                <div className="badgeClauseStatus" id="halloween232Status">Complete</div>
                                <div className="badgeClauseStart" id="halloween232Start">01/10/2023</div>
                                <div className="badgeClauseEnd" id="halloween232End">21/10/2023</div>
                                <div className="badgeClauseProgress"><progress id="halloween232Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Challenge</div>
                                <div className="badgeClauseStatus" id="halloween233Status">Complete</div>
                                <div className="badgeClauseStart" id="halloween233Start">01/10/2023</div>
                                <div className="badgeClauseEnd" id="halloween233End">21/10/2023</div>
                                <div className="badgeClauseProgress"><progress id="halloween233Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Challenge</div>
                                <div className="badgeClauseStatus" id="halloween234Status">Complete</div>
                                <div className="badgeClauseStart" id="halloween234Start">01/10/2023</div>
                                <div className="badgeClauseEnd" id="halloween234End">21/10/2023</div>
                                <div className="badgeClauseProgress"><progress id="halloween234Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Challenge</div>
                                <div className="badgeClauseStatus" id="halloween235Status">Complete</div>
                                <div className="badgeClauseStart" id="halloween235Start">21/10/2023</div>
                                <div className="badgeClauseEnd" id="halloween235End">21/10/2023</div>
                                <div className="badgeClauseProgress"><progress id="halloween235Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Challenge</div>
                                <div className="badgeClauseStatus" id="halloween236Status">Complete</div>
                                <div className="badgeClauseStart" id="halloween236Start">01/10/2023</div>
                                <div className="badgeClauseEnd" id="halloween236End">29/10/2023</div>
                                <div className="badgeClauseProgress"><progress id="halloween236Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Challenge</div>
                                <div className="badgeClauseStatus" id="halloween237Status">Complete</div>
                                <div className="badgeClauseStart" id="halloween237Start">01/10/2023</div>
                                <div className="badgeClauseEnd" id="halloween237End">28/10/2023</div>
                                <div className="badgeClauseProgress"><progress id="halloween237Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Challenge</div>
                                <div className="badgeClauseStatus" id="halloween238Status">Complete</div>
                                <div className="badgeClauseStart" id="halloween238Start">01/10/2023</div>
                                <div className="badgeClauseEnd" id="halloween238End">27/10/23</div>
                                <div className="badgeClauseProgress"><progress id="halloween238Progress" value="25" max="100"></progress></div>
                            </div>

                        <div className="badgeProgress"><progress id="halloween23Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                            <div className="badgeItemTitle brown" tabIndex={0} role="button" aria-expanded="false">
                                <div>Science Fair - November 2023</div>
                                <div>
                                    <progress id="sciencefair23ProgressMini" value="25" max="100"></progress>
                                    <span className="icon-down-open"></span></div>
                                </div>
                            <div className="badgeItemIntro brown-light">Complete one of 15 projects<br/><Link to="/sciencefair23">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Challenge</div>
                                <div className="badgeClauseStatus" id="sciencefair231Status">Complete</div>
                                <div className="badgeClauseStart" id="sciencefair231Start">2/11/2023</div>
                                <div className="badgeClauseEnd" id="sciencefair231End">5/11/2023</div>
                                <div className="badgeClauseProgress"><progress id="sciencefair231Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="sciencefair23Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                        <div className="badgeItemTitle silver" tabIndex={0} role="button" aria-expanded="false">
                            <div>Winter Rebel - December 2023</div>
                            <div>
                                <progress id="xmas23ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span></div>
                            </div>
                            <div className="badgeItemIntro silver-light">Complete at least 6 of 16 clauses<br/><Link to="/christmas23">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Make a Winter Wreath</div>
                                <div className="badgeClauseStatus" id="xmas231Status">Complete</div>
                                <div className="badgeClauseStart" id="xmas231Start">2/12/2023</div>
                                <div className="badgeClauseEnd" id="xmas231End">2/12/2023</div>
                                <div className="badgeClauseProgress"><progress id="xmas231Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Make a snow globe</div>
                                <div className="badgeClauseStatus" id="xmas232Status">Complete</div>
                                <div className="badgeClauseStart" id="xmas232Start">1/12/2023</div>
                                <div className="badgeClauseEnd" id="xmas232End">31/12/2023</div>
                                <div className="badgeClauseProgress"><progress id="xmas232Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Make a Sculpture out of ice</div>
                                <div className="badgeClauseStatus" id="xmas233Status">Complete</div>
                                <div className="badgeClauseStart" id="xmas233Start">1/12/2023</div>
                                <div className="badgeClauseEnd" id="xmas233End">31/12/2023</div>
                                <div className="badgeClauseProgress"><progress id="xmas233Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Watch a film or documentary about penguins</div>
                                <div className="badgeClauseStatus" id="xmas234Status">Complete</div>
                                <div className="badgeClauseStart" id="xmas234Start">1/12/2023</div>
                                <div className="badgeClauseEnd" id="xmas234End">31/12/2023</div>
                                <div className="badgeClauseProgress"><progress id="xmas234Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Build an igloo</div>
                                <div className="badgeClauseStatus" id="xmas235Status">Complete</div>
                                <div className="badgeClauseStart" id="xmas235Start">1/12/2023</div>
                                <div className="badgeClauseEnd" id="xmas235End">31/12/2023</div>
                                <div className="badgeClauseProgress"><progress id="xmas235Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Make a Penguin</div>
                                <div className="badgeClauseStatus" id="xmas236Status">Complete</div>
                                <div className="badgeClauseStart" id="xmas236Start">1/12/2023</div>
                                <div className="badgeClauseEnd" id="xmas236End">31/12/2023</div>
                                <div className="badgeClauseProgress"><progress id="xmas236Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="xmas23Progress" value="25" max="100"></progress></div>
                    </div>
                </div>
                <div className="row extrasYear">
                    <div className="extrasYear-Title" tabIndex={0} role="button" aria-expanded="false">2024<span className="icon-down-open shown"></span></div>
                    <div className="badgeItem">
                        <div className="badgeItemTitle dark blue" tabIndex={0} role="button" aria-expanded="false">
                            <div>Living My Best Life - January 2024</div>
                            <div>
                                <progress id="jan24ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span></div>
                            </div>
                            <div className="badgeItemIntro olive-light">Complete at least 6 clauses<br/><Link to="/january2024">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Take some time to reflect on areas of your life. Plan some changes to your year based on the results.</div>
                                <div className="badgeClauseStatus" id="jan241Status">Complete</div>
                                <div className="badgeClauseStart" id="jan241Start">01/01/2024</div>
                                <div className="badgeClauseEnd" id="jan241End">10/01/2024</div>
                                <div className="badgeClauseProgress"><progress id="jan241Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Set yourself a challenge you've always wanted to achieve. Spend the month taking necessary steps to completing it this year.</div>
                                <div className="badgeClauseStatus" id="jan242Status">Complete</div>
                                <div className="badgeClauseStart" id="jan242Start">01/01/2024</div>
                                <div className="badgeClauseEnd" id="jan242End">31/01/2024</div>
                                <div className="badgeClauseProgress"><progress id="jan242Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Choose at least one of the Rebel Annual Badges to work on this year, start working on it.</div>
                                <div className="badgeClauseStatus" id="jan243Status">Complete</div>
                                <div className="badgeClauseStart" id="jan243Start">01/01/2024</div>
                                <div className="badgeClauseEnd" id="jan243End">31/01/2024</div>
                                <div className="badgeClauseProgress"><progress id="jan243Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Make a positive step towards something you'd like to change in your life</div>
                                <div className="badgeClauseStatus" id="jan244Status">Complete</div>
                                <div className="badgeClauseStart" id="jan244Start">01/01/2024</div>
                                <div className="badgeClauseEnd" id="jan244End">31/01/2024</div>
                                <div className="badgeClauseProgress"><progress id="jan244Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Get rid of any items in your wardrobe which don't make you feel good.</div>
                                <div className="badgeClauseStatus" id="jan245Status">Complete</div>
                                <div className="badgeClauseStart" id="jan245Start">12/1/2024</div>
                                <div className="badgeClauseEnd" id="jan245End">14/1/2024</div>
                                <div className="badgeClauseProgress"><progress id="jan245Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Declutter at least one room and donate any useful items to charity.</div>
                                <div className="badgeClauseStatus" id="jan246Status">Complete</div>
                                <div className="badgeClauseStart" id="jan246Start">14/1/2024</div>
                                <div className="badgeClauseEnd" id="jan246End">14/1/2024</div>
                                <div className="badgeClauseProgress"><progress id="jan246Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="jan24Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                        <div className="badgeItemTitle dark blue" tabIndex={0} role="button" aria-expanded="false">
                            <div>You've Got Rebel Mail - February 2024</div>
                            <div>
                                <progress id="feb24ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span></div>
                            </div>
                            <div className="badgeItemIntro olive-light">Complete at least 3 clauses out of 20<br/><Link to="/february24">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">A parcel containing a homemade gift</div>
                                <div className="badgeClauseStatus" id="feb241Status">Complete</div>
                                <div className="badgeClauseStart" id="feb241Start">01/02/2024</div>
                                <div className="badgeClauseEnd" id="feb241End">29/02/2024</div>
                                <div className="badgeClauseProgress"><progress id="feb241Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Something in a handmade envelope</div>
                                <div className="badgeClauseStatus" id="feb242Status">Complete</div>
                                <div className="badgeClauseStart" id="feb242Start">01/02/2024</div>
                                <div className="badgeClauseEnd" id="feb242End">29/02/2024</div>
                                <div className="badgeClauseProgress"><progress id="feb242Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">A handmade postcard to another rebel</div>
                                <div className="badgeClauseStatus" id="feb243Status">Complete</div>
                                <div className="badgeClauseStart" id="feb243Start">01/02/2024</div>
                                <div className="badgeClauseEnd" id="feb243End">29/02/2024</div>
                                <div className="badgeClauseProgress"><progress id="feb243Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="feb24Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                        <div className="badgeItemTitle brown" tabIndex={0} role="button" aria-expanded="false">
                            <div>The Chocolate Challege - March 2024</div>
                            <div>
                                <progress id="mar24ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span></div>
                            </div>
                            <div className="badgeItemIntro brown-light">Complete at least 6 clauses out of 16<br/><Link to="/march24">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Learn</div>
                                <div className="badgeClauseStatus" id="mar241Status">Complete</div>
                                <div className="badgeClauseStart" id="mar241Start">01/03/2024</div>
                                <div className="badgeClauseEnd" id="mar241End">03/03/2024</div>
                                <div className="badgeClauseProgress"><progress id="mar241Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Read</div>
                                <div className="badgeClauseStatus" id="mar242Status">Complete</div>
                                <div className="badgeClauseStart" id="mar242Start">01/03/2024</div>
                                <div className="badgeClauseEnd" id="mar242End">03/03/2024</div>
                                <div className="badgeClauseProgress"><progress id="mar242Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Design</div>
                                <div className="badgeClauseStatus" id="mar243Status">Complete</div>
                                <div className="badgeClauseStart" id="mar243Start">01/03/2024</div>
                                <div className="badgeClauseEnd" id="mar243End">03/03/2024</div>
                                <div className="badgeClauseProgress"><progress id="mar243Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Donate</div>
                                <div className="badgeClauseStatus" id="mar244Status">Complete</div>
                                <div className="badgeClauseStart" id="mar244Start">01/03/2024</div>
                                <div className="badgeClauseEnd" id="mar244End">24/03/2024</div>
                                <div className="badgeClauseProgress"><progress id="mar244Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Make</div>
                                <div className="badgeClauseStatus" id="mar245Status">Complete</div>
                                <div className="badgeClauseStart" id="mar245Start">01/03/2024</div>
                                <div className="badgeClauseEnd" id="mar245End">24/03/2024</div>
                                <div className="badgeClauseProgress"><progress id="mar245Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Paint</div>
                                <div className="badgeClauseStatus" id="mar246Status">Complete</div>
                                <div className="badgeClauseStart" id="mar246Start">01/03/2024</div>
                                <div className="badgeClauseEnd" id="mar246End">24/03/2024</div>
                                <div className="badgeClauseProgress"><progress id="mar246Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="mar24Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                        <div className="badgeItemTitle yellow" tabIndex={0} role="button" aria-expanded="false">
                            <div>Sewing Bee - April 2024</div>
                            <div>
                                <progress id="apr24ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span></div>
                            </div>
                            <div className="badgeItemIntro yellow-light">Complete at least 1 project from the list<br/><Link to="/april24">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Project 1</div>
                                <div className="badgeClauseStatus" id="apr241Status">Complete</div>
                                <div className="badgeClauseStart" id="apr241Start">01/04/2024</div>
                                <div className="badgeClauseEnd" id="apr241End">28/04/2024</div>
                                <div className="badgeClauseProgress"><progress id="apr241Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="apr24Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                        <div className="badgeItemTitle pink" tabIndex={0} role="button" aria-expanded="false">
                            <div>Rebel Vision - May 2024</div>
                            <div>
                                <progress id="may24ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span></div>
                            </div>
                            <div className="badgeItemIntro pink-light">Complete at least 6 options from the list<br/><Link to="/may24">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Option 1</div>
                                <div className="badgeClauseStatus" id="may241Status">Complete</div>
                                <div className="badgeClauseStart" id="may241Start">01/05/2024</div>
                                <div className="badgeClauseEnd" id="may241End">31/05/2024</div>
                                <div className="badgeClauseProgress"><progress id="may241Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Option 2</div>
                                <div className="badgeClauseStatus" id="may242Status">Complete</div>
                                <div className="badgeClauseStart" id="may242Start">01/05/2024</div>
                                <div className="badgeClauseEnd" id="may242End">31/05/2024</div>
                                <div className="badgeClauseProgress"><progress id="may242Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Option 3</div>
                                <div className="badgeClauseStatus" id="may243Status">Complete</div>
                                <div className="badgeClauseStart" id="may243Start">01/05/2024</div>
                                <div className="badgeClauseEnd" id="may243End">31/05/2024</div>
                                <div className="badgeClauseProgress"><progress id="may243Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Option 4</div>
                                <div className="badgeClauseStatus" id="may244Status">Complete</div>
                                <div className="badgeClauseStart" id="may244Start">01/05/2024</div>
                                <div className="badgeClauseEnd" id="may244End">31/05/2024</div>
                                <div className="badgeClauseProgress"><progress id="may244Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Option 5</div>
                                <div className="badgeClauseStatus" id="may245Status">Complete</div>
                                <div className="badgeClauseStart" id="may245Start">01/05/2024</div>
                                <div className="badgeClauseEnd" id="may245End">31/05/2024</div>
                                <div className="badgeClauseProgress"><progress id="may245Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Option 6</div>
                                <div className="badgeClauseStatus" id="may246Status">Complete</div>
                                <div className="badgeClauseStart" id="may246Start">01/05/2024</div>
                                <div className="badgeClauseEnd" id="may246End">31/05/2024</div>
                                <div className="badgeClauseProgress"><progress id="may246Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="may24Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                        <div className="badgeItemTitle darkblue" tabIndex={0} role="button" aria-expanded="false">
                            <div>Carbon Footprint - June 2024</div>
                            <div>
                                <progress id="jun24ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span></div>
                            </div>
                            <div className="badgeItemIntro blue-light">Complete at least 2 of three clauses.<br/><Link to="/june24">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Clause 1</div>
                                <div className="badgeClauseStatus" id="jun241Status">Complete</div>
                                <div className="badgeClauseStart" id="jun241Start">01/06/2024</div>
                                <div className="badgeClauseEnd" id="jun241End">15/6/2024</div>
                                <div className="badgeClauseProgress"><progress id="jun241Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Clause 2</div>
                                <div className="badgeClauseStatus" id="jun242Status">Complete</div>
                                <div className="badgeClauseStart" id="jun242Start">01/06/2024</div>
                                <div className="badgeClauseEnd" id="jun242End">30/6/2024</div>
                                <div className="badgeClauseProgress"><progress id="jun242Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Clause 3</div>
                                <div className="badgeClauseStatus" id="jun243Status">Complete</div>
                                <div className="badgeClauseStart" id="jun243Start">01/06/2024</div>
                                <div className="badgeClauseEnd" id="jun243End">15/6/2024</div>
                                <div className="badgeClauseProgress"><progress id="jun243Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="jun24Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                        <div className="badgeItemTitle pink" tabIndex={0} role="button" aria-expanded="false">
                            <div>Big Kid Summer- July 2024</div>
                            <div>
                                <progress id="jul24ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span></div>
                            </div>
                            <div className="badgeItemIntro pink-light">Complete as many clauses as possible.<br/><Link to="/july24">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Clause 1</div>
                                <div className="badgeClauseStatus" id="jul241Status">Complete</div>
                                <div className="badgeClauseStart" id="jul241Start">01/07/2024</div>
                                <div className="badgeClauseEnd" id="jul241End">31/7/2024</div>
                                <div className="badgeClauseProgress"><progress id="jul241Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="jul24Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                        <div className="badgeItemTitle yellow" tabIndex={0} role="button" aria-expanded="false">
                            <div>Scavenger Hunt - August 2024</div>
                            <div>
                                <progress id="aug24ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span></div>
                            </div>
                            <div className="badgeItemIntro yellow-light">Complete as many clauses as possible.<br/><Link to="/august24">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Clause 1</div>
                                <div className="badgeClauseStatus" id="aug241Status">Complete</div>
                                <div className="badgeClauseStart" id="aug241Start">01/08/2024</div>
                                <div className="badgeClauseEnd" id="aug241End">31/08/2024</div>
                                <div className="badgeClauseProgress"><progress id="aug241Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="aug24Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                        <div className="badgeItemTitle brown" tabIndex={0} role="button" aria-expanded="false">
                            <div>The Poop Challenge - September 2024</div>
                            <div>
                                <progress id="sep24ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span></div>
                            </div>
                            <div className="badgeItemIntro brown-light">Complete as many clauses as possible.<br/><Link to="/september24">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Clause 1</div>
                                <div className="badgeClauseStatus" id="sep241Status">Complete</div>
                                <div className="badgeClauseStart" id="sep241Start">01/09/2024</div>
                                <div className="badgeClauseEnd" id="sep241End">30/09/2024</div>
                                <div className="badgeClauseProgress"><progress id="sep241Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="sep24Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                        <div className="badgeItemTitle brown" tabIndex={0} role="button" aria-expanded="false">
                            <div>I Survived the Zombie Apocalypse - October 2024</div>
                            <div>
                                <progress id="oct24ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span></div>
                            </div>
                            <div className="badgeItemIntro brown-light">Complete as many clauses as possible.<br/><Link to="/halloween24">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Clause 1</div>
                                <div className="badgeClauseStatus" id="oct241Status">Complete</div>
                                <div className="badgeClauseStart" id="oct241Start">01/10/2024</div>
                                <div className="badgeClauseEnd" id="oct241End">31/10/2024</div>
                                <div className="badgeClauseProgress"><progress id="oct241Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Clause 2</div>
                                <div className="badgeClauseStatus" id="oct242Status">Complete</div>
                                <div className="badgeClauseStart" id="oct242Start">01/10/2024</div>
                                <div className="badgeClauseEnd" id="oct242End">31/10/2024</div>
                                <div className="badgeClauseProgress"><progress id="oct242Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Clause 3</div>
                                <div className="badgeClauseStatus" id="oct243Status">Complete</div>
                                <div className="badgeClauseStart" id="oct243Start">01/10/2024</div>
                                <div className="badgeClauseEnd" id="oct243End">31/10/2024</div>
                                <div className="badgeClauseProgress"><progress id="oct243Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Clause 4</div>
                                <div className="badgeClauseStatus" id="oct244Status">Complete</div>
                                <div className="badgeClauseStart" id="oct244Start">01/10/2024</div>
                                <div className="badgeClauseEnd" id="oct244End">31/10/2024</div>
                                <div className="badgeClauseProgress"><progress id="oct244Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Clause 5</div>
                                <div className="badgeClauseStatus" id="oct245Status">Complete</div>
                                <div className="badgeClauseStart" id="oct245Start">01/10/2024</div>
                                <div className="badgeClauseEnd" id="oct245End">31/10/2024</div>
                                <div className="badgeClauseProgress"><progress id="oct245Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Clause 6</div>
                                <div className="badgeClauseStatus" id="oct246Status">Complete</div>
                                <div className="badgeClauseStart" id="oct246Start">01/10/2024</div>
                                <div className="badgeClauseEnd" id="oct246End">31/10/2024</div>
                                <div className="badgeClauseProgress"><progress id="oct246Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="oct24Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                        <div className="badgeItemTitle brown" tabIndex={0} role="button" aria-expanded="false">
                            <div>Ready Rebel Cook - November 2024</div>
                            <div>
                                <progress id="nov24ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span></div>
                            </div>
                            <div className="badgeItemIntro brown-light">Complete as many clauses as you like.<br/><Link to="/november24">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Clause 1</div>
                                <div className="badgeClauseStatus" id="nov241Status">Not started</div>
                                <div className="badgeClauseStart" id="nov241Start">01/11/2024</div>
                                <div className="badgeClauseEnd" id="nov241End"></div>
                                <div className="badgeClauseProgress"><progress id="nov241Progress" value="25" max="100"></progress></div>
                            </div>
                            
                        <div className="badgeProgress"><progress id="nov24Progress" value="25" max="100"></progress></div>
                    </div>
                </div>
            </div>

            <div className="extras">
                <div className="row extrasrow">Quarterly badges</div>
                <div className="row extrasYear">
                    <div className="extrasYear-Title" tabIndex={0} role="button" aria-expanded="false">2022<span className="icon-down-open shown"></span></div>
                    <div className="badgeItem">
                        <div className="badgeItemTitle purple" tabIndex={0} role="button" aria-expanded="false">
                            <div>Rebel 1st Birthday - Q4 2022</div>
                            <div>
                                <progress id="qfour22ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span>
                            </div>
                        </div>
                        <div className="badgeItemIntro purple-light"><Link to="/firstbirthday">View evidence page</Link></div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Complete one badge from every section of the badge book</div>
                            <div className="badgeClauseStatus" id="qfour221Status">Complete</div>
                            <div className="badgeClauseStart" id="qfour221Start">31/12/2023</div>
                            <div className="badgeClauseEnd" id="qfour221End">31/12/2023</div>
                            <div className="badgeClauseProgress"><progress id="qfour221Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Make a birthday cake and eat a slice</div>
                            <div className="badgeClauseStatus" id="qfour222Status">Complete</div>
                            <div className="badgeClauseStart" id="qfour222Start">25/02/2024</div>
                            <div className="badgeClauseEnd" id="qfour222End">25/02/2024</div>
                            <div className="badgeClauseProgress"><progress id="qfour222Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Learn how to sing or sign Happy Birthday in another language</div>
                            <div className="badgeClauseStatus" id="qfour223Status">Complete</div>
                            <div className="badgeClauseStart" id="qfour223Start">1/1/2024</div>
                            <div className="badgeClauseEnd" id="qfour223End">31/1/2024</div>
                            <div className="badgeClauseProgress"><progress id="qfour223Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Make a Rebel Birthday card and share it on the group</div>
                            <div className="badgeClauseStatus" id="qfour224Status">Complete</div>
                            <div className="badgeClauseStart" id="qfour224Start">22/03/2024</div>
                            <div className="badgeClauseEnd" id="qfour224End">22/03/2024</div>
                            <div className="badgeClauseProgress"><progress id="qfour224Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeProgress"><progress id="qfour22Progress" value="25" max="100"></progress></div>
                    </div>
                </div>
                <div className="row extrasYear">
                    <div className="extrasYear-Title" tabIndex={0} role="button" aria-expanded="false">2023<span className="icon-down-open shown"></span></div>
                    <div className="badgeItem">
                            <div className="badgeItemTitle pink" tabIndex={0} role="button" aria-expanded="false">
                                <div>Happy 2023 - Q1 2023</div>
                                <div>
                                    <progress id="qone23ProgressMini" value="25" max="100"></progress>
                                    <span className="icon-down-open"></span></div>
                                </div>
                            <div className="badgeItemIntro pink-light"><Link to="/happy2023">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Complete 2 Self Aware Badges</div>
                                <div className="badgeClauseStatus" id="qone231Status">Complete</div>
                                <div className="badgeClauseStart" id="qone231Start">01/01/2023</div>
                                <div className="badgeClauseEnd" id="qone231End">4/3/2023</div>
                                <div className="badgeClauseProgress"><progress id="qone231Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Complete 2 Wellness Badges</div>
                                <div className="badgeClauseStatus" id="qone232Status">Complete</div>
                                <div className="badgeClauseStart" id="qone232Start">01/01/2023</div>
                                <div className="badgeClauseEnd" id="qone232End">4/3/2023</div>
                                <div className="badgeClauseProgress"><progress id="qone232Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Complete another badge of your choice which makes you happy</div>
                                <div className="badgeClauseStatus" id="qone233Status">Complete</div>
                                <div className="badgeClauseStart" id="qone233Start">01/01/2023</div>
                                <div className="badgeClauseEnd" id="qone233End">4/3/2023</div>
                                <div className="badgeClauseProgress"><progress id="qone233Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Do something once a week to make you happy</div>
                                <div className="badgeClauseStatus" id="qone234Status">Complete</div>
                                <div className="badgeClauseStart" id="qone234Start">01/01/2023</div>
                                <div className="badgeClauseEnd" id="qone234End">31/03/2023</div>
                                <div className="badgeClauseProgress"><progress id="qone234Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Do something once a week to make someone else happy</div>
                                <div className="badgeClauseStatus" id="qone235Status">Complete</div>
                                <div className="badgeClauseStart" id="qone235Start">01/01/2023</div>
                                <div className="badgeClauseEnd" id="qone235End">31/03/2023</div>
                                <div className="badgeClauseProgress"><progress id="qone235Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="qone23Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                            <div className="badgeItemTitle pink" tabIndex={0} role="button" aria-expanded="false">
                                <div>Life Less Ordinary - Q2 2023</div>
                                <div>
                                    <progress id="qtwo23ProgressMini" value="25" max="100"></progress>
                                    <span className="icon-down-open"></span></div>
                                </div>
                            <div className="badgeItemIntro pink-light"><Link to="/lifelessordinary23">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Complete "My Story"</div>
                                <div className="badgeClauseStatus" id="qtwo231Status">Complete</div>
                                <div className="badgeClauseStart" id="qtwo231Start">1/4/2023</div>
                                <div className="badgeClauseEnd" id="qtwo231End">30/6/2023</div>
                                <div className="badgeClauseProgress"><progress id="qtwo231Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Complete 1 Global Badge</div>
                                <div className="badgeClauseStatus" id="qtwo232Status">Complete</div>
                                <div className="badgeClauseStart" id="qtwo232Start">1/4/2023</div>
                                <div className="badgeClauseEnd" id="qtwo232End">30/6/2023</div>
                                <div className="badgeClauseProgress"><progress id="qtwo232Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Complete a badge from the section of the badge book which you have completed the least from so far</div>
                                <div className="badgeClauseStatus" id="qtwo233Status">Complete</div>
                                <div className="badgeClauseStart" id="qtwo233Start">1/4/2023</div>
                                <div className="badgeClauseEnd" id="qtwo233End">30/6/2023</div>
                                <div className="badgeClauseProgress"><progress id="qtwo233Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Let someone else choose a badge for you to complete</div>
                                <div className="badgeClauseStatus" id="qtwo234Status">Complete</div>
                                <div className="badgeClauseStart" id="qtwo234Start">10/04/2023</div>
                                <div className="badgeClauseEnd" id="qtwo234End">30/6/2023</div>
                                <div className="badgeClauseProgress"><progress id="qtwo234Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Write yourself a list of at least 25 things you'd like to do in your life. Complete one.</div>
                                <div className="badgeClauseStatus" id="qtwo235Status">Complete</div>
                                <div className="badgeClauseStart" id="qtwo235Start">1/4/2023</div>
                                <div className="badgeClauseEnd" id="qtwo235End">30/6/2023</div>
                                <div className="badgeClauseProgress"><progress id="qtwo235Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Once a week, for 12 weeks, do something you've never done before.</div>
                                <div className="badgeClauseStatus" id="qtwo236Status">Complete</div>
                                <div className="badgeClauseStart" id="qtwo236Start">1/4/2023</div>
                                <div className="badgeClauseEnd" id="qtwo236End">30/6/2023</div>
                                <div className="badgeClauseProgress"><progress id="qtwo236Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="qtwo23Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                            <div className="badgeItemTitle pink" tabIndex={0} role="button" aria-expanded="false">
                                <div>Go Wild - Q3 2023</div>
                                <div>
                                    <progress id="qthree23ProgressMini" value="25" max="100"></progress>
                                    <span className="icon-down-open"></span></div>
                                </div>
                            <div className="badgeItemIntro pink-light"><Link to="/GoWild23">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Complete Stargazer, Nature Lover, or Both</div>
                                <div className="badgeClauseStatus" id="qthree231Status">Complete</div>
                                <div className="badgeClauseStart" id="qthree231Start">1/7/2023</div>
                                <div className="badgeClauseEnd" id="qthree231End">1/7/2023</div>
                                <div className="badgeClauseProgress"><progress id="qthree231Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Complete 2 other wild badges</div>
                                <div className="badgeClauseStatus" id="qthree232Status">Complete</div>
                                <div className="badgeClauseStart" id="qthree232Start">1/7/2023</div>
                                <div className="badgeClauseEnd" id="qthree232End">30/09/2023</div>
                                <div className="badgeClauseProgress"><progress id="qthree232Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Write a list of 10 adventures - complete one</div>
                                <div className="badgeClauseStatus" id="qthree233Status">Complete</div>
                                <div className="badgeClauseStart" id="qthree233Start">1/7/2023</div>
                                <div className="badgeClauseEnd" id="qthree233End">30/09/2023</div>
                                <div className="badgeClauseProgress"><progress id="qthree233Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Once a week do different outdoor activity</div>
                                <div className="badgeClauseStatus" id="qthree234Status">Complete</div>
                                <div className="badgeClauseStart" id="qthree234Start">1/7/2023</div>
                                <div className="badgeClauseEnd" id="qthree234End">30/09/2023</div>
                                <div className="badgeClauseProgress"><progress id="qthree234Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="qthree23Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                            <div className="badgeItemTitle pink" tabIndex={0} role="button" aria-expanded="false">
                                <div>Rebel 2nd Birthday - Q4 2023</div>
                                <div>
                                    <progress id="qfour23ProgressMini" value="25" max="100"></progress>
                                    <span className="icon-down-open"></span></div>
                                </div>
                            <div className="badgeItemIntro pink-light"><Link to="/secondbirthday">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Complete Two Badges from Book Two</div>
                                <div className="badgeClauseStatus" id="qfour231Status">Complete</div>
                                <div className="badgeClauseStart" id="qfour231Start">1/10/2023</div>
                                <div className="badgeClauseEnd" id="qfour231End">31/12/2023</div>
                                <div className="badgeClauseProgress"><progress id="qfour231Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Do a badge with TWO (or more) other Rebels</div>
                                <div className="badgeClauseStatus" id="qfour232Status">Not started</div>
                                <div className="badgeClauseStart" id="qfour232Start"></div>
                                <div className="badgeClauseEnd" id="qfour232End"></div>
                                <div className="badgeClauseProgress"><progress id="qfour232Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Attend two rebel meetups</div>
                                <div className="badgeClauseStatus" id="qfour233Status">Complete</div>
                                <div className="badgeClauseStart" id="qfour233Start">1/10/2023</div>
                                <div className="badgeClauseEnd" id="qfour233End">31/12/2023</div>
                                <div className="badgeClauseProgress"><progress id="qfour233Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Design a Rebel themed birthday cake and either make a prototype from paper, or bake and decorate it.</div>
                                <div className="badgeClauseStatus" id="qfour234Status">Not started</div>
                                <div className="badgeClauseStart" id="qfour234Start"></div>
                                <div className="badgeClauseEnd" id="qfour234End"></div>
                                <div className="badgeClauseProgress"><progress id="qfour234Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Do the hokey cokey with as many people as you can!</div>
                                <div className="badgeClauseStatus" id="qfour235Status">Not started</div>
                                <div className="badgeClauseStart" id="qfour235Start"></div>
                                <div className="badgeClauseEnd" id="qfour235End"></div>
                                <div className="badgeClauseProgress"><progress id="qfour235Progress" value="25" max="100"></progress></div>
                            </div>
                            
                        <div className="badgeProgress"><progress id="qfour23Progress" value="25" max="100"></progress></div>
                    </div>
                </div>
                <div className="row extrasYear">
                    <div className="extrasYear-Title" tabIndex={0} role="button" aria-expanded="false">2024<span className="icon-down-open shown"></span></div>
                    <div className="badgeItem">
                            <div className="badgeItemTitle green" tabIndex={0} role="button" aria-expanded="false">
                                <div>Kickstart 2024 - Q1 2024</div>
                                <div>
                                    <progress id="qone24ProgressMini" value="25" max="100"></progress>
                                    <span className="icon-down-open"></span></div>
                                </div>
                            <div className="badgeItemIntro green-light"><Link to="/kickstart24">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Complete either My Goals or Good Habits</div>
                                <div className="badgeClauseStatus" id="qone241Status">Complete</div>
                                <div className="badgeClauseStart" id="qone241Start">1/1/2024</div>
                                <div className="badgeClauseEnd" id="qone241End">10/1/2024</div>
                                <div className="badgeClauseProgress"><progress id="qone241Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Complete a badge from the Wellness section</div>
                                <div className="badgeClauseStatus" id="qone242Status">Complete</div>
                                <div className="badgeClauseStart" id="qone242Start">1/1/2024</div>
                                <div className="badgeClauseEnd" id="qone242End">31/3/24</div>
                                <div className="badgeClauseProgress"><progress id="qone242Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Complete a badge from the Grown Up section</div>
                                <div className="badgeClauseStatus" id="qone243Status">Complete</div>
                                <div className="badgeClauseStart" id="qone243Start">1/1/2024</div>
                                <div className="badgeClauseEnd" id="qone243End">31/3/24</div>
                                <div className="badgeClauseProgress"><progress id="qone243Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Start a badge which takes longer than 3 months to complete</div>
                                <div className="badgeClauseStatus" id="qone244Status">Complete</div>
                                <div className="badgeClauseStart" id="qone244Start">1/1/2024</div>
                                <div className="badgeClauseEnd" id="qone244End">31/3/24</div>
                                <div className="badgeClauseProgress"><progress id="qone244Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Write a list of at least 12 goals or targets for 2024</div>
                                <div className="badgeClauseStatus" id="qone245Status">Complete</div>
                                <div className="badgeClauseStart" id="qone245Start">1/1/2024</div>
                                <div className="badgeClauseEnd" id="qone245End">31/3/2024</div>
                                <div className="badgeClauseProgress"><progress id="qone245Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Choose at least 4 of the goals and work towards them over the next three months. Share your progress.</div>
                                <div className="badgeClauseStatus" id="qone246Status">Complete</div>
                                <div className="badgeClauseStart" id="qone246Start">1/1/2024</div>
                                <div className="badgeClauseEnd" id="qone246End">31/3/2024</div>
                                <div className="badgeClauseProgress"><progress id="qone246Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="qone24Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                            <div className="badgeItemTitle green" tabIndex={0} role="button" aria-expanded="false">
                                <div>Deeds Not Words - Q2 2024</div>
                                <div>
                                    <progress id="qtwo24ProgressMini" value="25" max="100"></progress>
                                    <span className="icon-down-open"></span></div>
                                </div>
                            <div className="badgeItemIntro green-light"><Link to="/bebrave24">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Complete either Activist or Rebel History</div>
                                <div className="badgeClauseStatus" id="qtwo241Status">Complete</div>
                                <div className="badgeClauseStart" id="qtwo241Start">1/2/2024</div>
                                <div className="badgeClauseEnd" id="qtwo241End">1/4/2024</div>
                                <div className="badgeClauseProgress"><progress id="qtwo241Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Complete another badge from the Global section</div>
                                <div className="badgeClauseStatus" id="qtwo242Status">Complete</div>
                                <div className="badgeClauseStart" id="qtwo242Start">1/4/2024</div>
                                <div className="badgeClauseEnd" id="qtwo242End">1/4/2024</div>
                                <div className="badgeClauseProgress"><progress id="qtwo242Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Complete or plan to complete Rebel with a Cause</div>
                                <div className="badgeClauseStatus" id="qtwo243Status">Complete</div>
                                <div className="badgeClauseStart" id="qtwo243Start">1/5/2024</div>
                                <div className="badgeClauseEnd" id="qtwo243End">31/5/2024</div>
                                <div className="badgeClauseProgress"><progress id="qtwo243Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Complete a badge which requires a practical or manual skill</div>
                                <div className="badgeClauseStatus" id="qtwo244Status">Complete</div>
                                <div className="badgeClauseStart" id="qtwo244Start">1/4/2024</div>
                                <div className="badgeClauseEnd" id="qtwo244End">30/6/2024</div>
                                <div className="badgeClauseProgress"><progress id="qtwo244Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">List 12 ways you can make a difference, complete 4 this quarter</div>
                                <div className="badgeClauseStatus" id="qtwo245Status">Complete</div>
                                <div className="badgeClauseStart" id="qtwo245Start">1/4/2024</div>
                                <div className="badgeClauseEnd" id="qtwo245End">30/6/2024</div>
                                <div className="badgeClauseProgress"><progress id="qtwo245Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Do something you've been putting off</div>
                                <div className="badgeClauseStatus" id="qtwo246Status">Complete</div>
                                <div className="badgeClauseStart" id="qtwo246Start">1/4/2024</div>
                                <div className="badgeClauseEnd" id="qtwo246End">30/4/2024</div>
                                <div className="badgeClauseProgress"><progress id="qtwo246Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="qtwo24Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                            <div className="badgeItemTitle green" tabIndex={0} role="button" aria-expanded="false">
                                <div>Be Brave - Q3 2024</div>
                                <div>
                                    <progress id="qthree24ProgressMini" value="25" max="100"></progress>
                                    <span className="icon-down-open"></span></div>
                                </div>
                            <div className="badgeItemIntro green-light"><Link to="/bebrave24">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Comfort Zone</div>
                                <div className="badgeClauseStatus" id="qthree241Status">Complete</div>
                                <div className="badgeClauseStart" id="qthree241Start">1/7/2024</div>
                                <div className="badgeClauseEnd" id="qthree241End">30/9/2024</div>
                                <div className="badgeClauseProgress"><progress id="qthree241Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Wild</div>
                                <div className="badgeClauseStatus" id="qthree242Status">Complete</div>
                                <div className="badgeClauseStart" id="qthree242Start">1/7/2024</div>
                                <div className="badgeClauseEnd" id="qthree242End">30/9/2024</div>
                                <div className="badgeClauseProgress"><progress id="qthree242Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Health Check</div>
                                <div className="badgeClauseStatus" id="qthree243Status">Complete</div>
                                <div className="badgeClauseStart" id="qthree243Start">1/7/2024</div>
                                <div className="badgeClauseEnd" id="qthree243End">30/7/2024</div>
                                <div className="badgeClauseProgress"><progress id="qthree243Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Badge Roulette</div>
                                <div className="badgeClauseStatus" id="qthree244Status">Complete</div>
                                <div className="badgeClauseStart" id="qthree244Start">1/7/2024</div>
                                <div className="badgeClauseEnd" id="qthree244End">30/9/2024</div>
                                <div className="badgeClauseProgress"><progress id="qthree244Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Be scared</div>
                                <div className="badgeClauseStatus" id="qthree245Status">Complete</div>
                                <div className="badgeClauseStart" id="qthree245Start">1/7/2024</div>
                                <div className="badgeClauseEnd" id="qthree245End">30/9/2024</div>
                                <div className="badgeClauseProgress"><progress id="qthree245Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="qthree24Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                            <div className="badgeItemTitle green" tabIndex={0} role="button" aria-expanded="false">
                                <div>Rebel 3rd Birthday - Q4 2024</div>
                                <div>
                                    <progress id="qfour24ProgressMini" value="25" max="100"></progress>
                                    <span className="icon-down-open"></span></div>
                                </div>
                            <div className="badgeItemIntro green-light"><Link to="/thirdbirthday24">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Community</div>
                                <div className="badgeClauseStatus" id="qfour241Status">In progress</div>
                                <div className="badgeClauseStart" id="qfour241Start">1/10/2024</div>
                                <div className="badgeClauseEnd" id="qfour241End"></div>
                                <div className="badgeClauseProgress"><progress id="qfour241Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Complete</div>
                                <div className="badgeClauseStatus" id="qfour242Status">In progress</div>
                                <div className="badgeClauseStart" id="qfour242Start">1/10/2024</div>
                                <div className="badgeClauseEnd" id="qfour242End"></div>
                                <div className="badgeClauseProgress"><progress id="qfour242Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">In Progress</div>
                                <div className="badgeClauseStatus" id="qfour243Status">In progress</div>
                                <div className="badgeClauseStart" id="qfour243Start">1/10/2024</div>
                                <div className="badgeClauseEnd" id="qfour243End"></div>
                                <div className="badgeClauseProgress"><progress id="qfour243Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Party</div>
                                <div className="badgeClauseStatus" id="qfour244Status">Not started</div>
                                <div className="badgeClauseStart" id="qfour244Start">1/10/2024</div>
                                <div className="badgeClauseEnd" id="qfour244End"></div>
                                <div className="badgeClauseProgress"><progress id="qfour244Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Hat</div>
                                <div className="badgeClauseStatus" id="qfour245Status">Not started</div>
                                <div className="badgeClauseStart" id="qfour245Start">1/10/2024</div>
                                <div className="badgeClauseEnd" id="qfour245End"></div>
                                <div className="badgeClauseProgress"><progress id="qfour245Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Help</div>
                                <div className="badgeClauseStatus" id="qfour246Status">Not started</div>
                                <div className="badgeClauseStart" id="qfour246Start">1/10/2024</div>
                                <div className="badgeClauseEnd" id="qfour246End"></div>
                                <div className="badgeClauseProgress"><progress id="qfour246Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="qfour24Progress" value="25" max="100"></progress></div>
                    </div>
                </div>
            </div>

            <div className="extras">
                <div className="row extrasrow">Annual badges</div>
                <div className="row extrasYear">
                    <div className="extrasYear-Title" tabIndex={0} role="button" aria-expanded="false">2023<span className="icon-down-open shown"></span></div>
                    <div className="badgeItem">
                        <div className="badgeItemTitle darkblue" tabIndex={0} role="button" aria-expanded="false">
                            <div>Annual Roundup 2023</div>
                            <div>
                                <progress id="roundup23ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span></div>
                            </div>
                        <div className="badgeItemIntro blue-light"><Link to="/roundup2023">View evidence page</Link></div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Complete a roundup of your Rebel 2023</div>
                            <div className="badgeClauseStatus" id="roundup231Status">Complete</div>
                            <div className="badgeClauseStart" id="roundup231Start">19/12/2023</div>
                            <div className="badgeClauseEnd" id="roundup231End">19/12/2023</div>
                            <div className="badgeClauseProgress"><progress id="roundup231Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeProgress"><progress id="roundup23Progress" value="25" max="100"></progress></div>
                    </div>
                </div>
                <div className="row extrasYear">
                    <div className="extrasYear-Title" tabIndex={0} role="button" aria-expanded="false">2024<span className="icon-down-open shown"></span></div>
                    <div className="badgeItem">
                        <div className="badgeItemTitle pink" tabIndex={0} role="button" aria-expanded="false">
                            <div>The Big Rebel Adventure 2024</div>
                            <div>
                                <progress id="adventure24ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span></div>
                            </div>
                        <div className="badgeItemIntro green-light"><Link to="/bigadventure2024">View evidence page</Link></div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Walk 26 miles or climb 3000m</div>
                            <div className="badgeClauseStatus" id="adventure241Status">In progress</div>
                            <div className="badgeClauseStart" id="adventure241Start">1/10/2023</div>
                            <div className="badgeClauseEnd" id="adventure241End"></div>
                            <div className="badgeClauseProgress"><progress id="adventure241Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeProgress"><progress id="adventure24Progress" value="25" max="100"></progress></div>
                    </div>     
                    <div className="badgeItem">
                        <div className="badgeItemTitle darkblue" tabIndex={0} role="button" aria-expanded="false">
                            <div>52 Books in a Year</div>
                            <div>
                                <progress id="books24ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span></div>
                            </div>
                        <div className="badgeItemIntro green-light"><Link to="/52books2024">View evidence page</Link></div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Read 52 books in a year</div>
                            <div className="badgeClauseStatus" id="books241Status">In progress</div>
                            <div className="badgeClauseStart" id="books241Start">1/3/2023</div>
                            <div className="badgeClauseEnd" id="books241End"></div>
                            <div className="badgeClauseProgress"><progress id="books241Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeProgress"><progress id="books24Progress" value="25" max="100"></progress></div>
                    </div>   
                    <div className="badgeItem">
                        <div className="badgeItemTitle green" tabIndex={0} role="button" aria-expanded="false">
                            <div>More Time Outdoors 2024</div>
                            <div>
                                <progress id="outdoors24ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span></div>
                            </div>
                        <div className="badgeItemIntro green-light"><Link to="/outdoors2024">View evidence page</Link></div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Spend more time outdoors</div>
                            <div className="badgeClauseStatus" id="outdoors241Status">In progress</div>
                            <div className="badgeClauseStart" id="outdoors241Start">1/1/2024</div>
                            <div className="badgeClauseEnd" id="outdoors241End"></div>
                            <div className="badgeClauseProgress"><progress id="outdoors241Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeProgress"><progress id="outdoors24Progress" value="25" max="100"></progress></div>
                    </div> 
                    <div className="badgeItem">
                        <div className="badgeItemTitle blue" tabIndex={0} role="button" aria-expanded="false">
                            <div>Rebel with a cause 2024</div>
                            <div>
                                <progress id="rebelcause24ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span></div>
                            </div>
                        <div className="badgeItemIntro blue-light"><Link to="/rebelcause2024">View evidence page</Link></div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Raise money or give time to a cause of your choice</div>
                            <div className="badgeClauseStatus" id="rebelcause241Status">In progress</div>
                            <div className="badgeClauseStart" id="rebelcause241Start">1/1/2024</div>
                            <div className="badgeClauseEnd" id="rebelcause241End"></div>
                            <div className="badgeClauseProgress"><progress id="rebelcause241Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeProgress"><progress id="rebelcause24Progress" value="25" max="100"></progress></div>
                    </div> 
                    <div className="badgeItem">
                        <div className="badgeItemTitle blue" tabIndex={0} role="button" aria-expanded="false">
                            <div>Rebel Health Check 2024</div>
                            <div>
                                <progress id="healthcheck24ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span></div>
                            </div>
                        <div className="badgeItemIntro blue-light"><Link to="/healthcheck2024">View evidence page</Link></div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Clause 1</div>
                            <div className="badgeClauseStatus" id="healthcheck241Status">Complete</div>
                            <div className="badgeClauseStart" id="healthcheck241Start">1/7/2024</div>
                            <div className="badgeClauseEnd" id="healthcheck241End">30/7/2024</div>
                            <div className="badgeClauseProgress"><progress id="healthcheck241Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Clause 2</div>
                            <div className="badgeClauseStatus" id="healthcheck242Status">Complete</div>
                            <div className="badgeClauseStart" id="healthcheck242Start">1/7/2024</div>
                            <div className="badgeClauseEnd" id="healthcheck242End">30/7/2024</div>
                            <div className="badgeClauseProgress"><progress id="healthcheck242Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Clause 3</div>
                            <div className="badgeClauseStatus" id="healthcheck243Status">Complete</div>
                            <div className="badgeClauseStart" id="healthcheck243Start">1/7/2024</div>
                            <div className="badgeClauseEnd" id="healthcheck243End">30/7/2024</div>
                            <div className="badgeClauseProgress"><progress id="healthcheck243Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Clause 4</div>
                            <div className="badgeClauseStatus" id="healthcheck244Status">Complete</div>
                            <div className="badgeClauseStart" id="healthcheck244Start">1/7/2024</div>
                            <div className="badgeClauseEnd" id="healthcheck244End">30/7/2024</div>
                            <div className="badgeClauseProgress"><progress id="healthcheck244Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Clause 5</div>
                            <div className="badgeClauseStatus" id="healthcheck245Status">Complete</div>
                            <div className="badgeClauseStart" id="healthcheck245Start">1/7/2024</div>
                            <div className="badgeClauseEnd" id="healthcheck245End">30/7/2024</div>
                            <div className="badgeClauseProgress"><progress id="healthcheck245Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Clause 6</div>
                            <div className="badgeClauseStatus" id="healthcheck246Status">Complete</div>
                            <div className="badgeClauseStart" id="healthcheck246Start">1/7/2024</div>
                            <div className="badgeClauseEnd" id="healthcheck246End">30/7/2024</div>
                            <div className="badgeClauseProgress"><progress id="healthcheck246Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeProgress"><progress id="healthcheck24Progress" value="25" max="100"></progress></div>
                    </div>                         
                </div>
            </div>
            
            <div className="extras">
                <div className="row extrasrow">The Rebel Cup</div>
                <div className="row extrastext"><p>New for 2023! I have joined Team Nelson for the Rebel cup this year as it was the least represented, so I'll be working on earning points to help Team Nelson win the Rebel cup!!</p></div>
                <div className="row extrastext"><p>I won't be doing all the challenges, but every little helps and I'll add what I can, when I can.</p></div>
                <div className='row extrastext'><p>Update for 2024: Nelson Patrol won the cup in 2023 and I have stayed with them for 2024!</p></div>
                <div className="row extrasYear">
                    <div className="extrasYear-Title" tabIndex={0} role="button" aria-expanded="false">2023<span className="icon-down-open shown"></span></div>
                    <div className="badgeItem">
                            <div className="badgeItemTitle silver" tabIndex={0} role="button" aria-expanded="false">
                                <div>January 2023 - Cat Puzzles</div>
                                <div>
                                    <progress id="cupcatjan23ProgressMini" value="25" max="100"></progress>
                                    <span className="icon-down-open"></span></div>
                                </div>
                            <div className="badgeItemIntro silver-light">Complete a cat themed puzzle.<a href="https://ameliaraine.me.uk/rebel/catpuzzle/cat.html" target="_blank" rel="noopener noreferrer">View evidence page</a></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Task 1</div>
                                <div className="badgeClauseStatus" id="cupcatjan231Status">Complete</div>
                                <div className="badgeClauseStart" id="cupcatjan231Start">14/01/2023</div>
                                <div className="badgeClauseEnd" id="cupcatjan231End">15/01/2023</div>
                                <div className="badgeClauseProgress"><progress id="cupcatjan231Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="cupcatjan23Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                            <div className="badgeItemTitle silver" tabIndex={0} role="button" aria-expanded="false">
                                <div>March 2023 - Soup</div>
                                <div>
                                    <progress id="soupmar23ProgressMini" value="25" max="100"></progress>
                                    <span className="icon-down-open"></span></div>
                                </div>
                            <div className="badgeItemIntro silver-light">Donate soup to a food bank. <Link to="/SoupMar23">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Task 1</div>
                                <div className="badgeClauseStatus" id="soupmar231Status">Complete</div>
                                <div className="badgeClauseStart" id="soupmar231Start">03/03/2023</div>
                                <div className="badgeClauseEnd" id="soupmar231End">03/03/2023</div>
                                <div className="badgeClauseProgress"><progress id="soupmar231Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="soupmar23Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                            <div className="badgeItemTitle silver" tabIndex={0} role="button" aria-expanded="false">
                                <div>April 2023 Bonus Points</div>
                                <div>
                                    <progress id="cupgenapr23ProgressMini" value="25" max="100"></progress>
                                    <span className="icon-down-open"></span></div>
                                </div>
                            <div className="badgeItemIntro silver-light">Complete any of three clauses<Link to="/CupApr23">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Make a sculpture inspired by Mother Earth</div>
                                <div className="badgeClauseStatus" id="cupgenapr231Status">Complete</div>
                                <div className="badgeClauseStart" id="cupgenapr231Start">22/4/2023</div>
                                <div className="badgeClauseEnd" id="cupgenapr231End">23/4/2023</div>
                                <div className="badgeClauseProgress"><progress id="cupgenapr231Progress" value="25" max="100"></progress></div>
                            </div>
                             <div className="badgeClause">
                                <div className="badgeClauseTask">Make an item of clothing using at least 30 safety pins</div>
                                <div className="badgeClauseStatus" id="cupgenapr232Status">Complete</div>
                                <div className="badgeClauseStart" id="cupgenapr232Start">14/4/2023</div>
                                <div className="badgeClauseEnd" id="cupgenapr232End">19/4/2023</div>
                                <div className="badgeClauseProgress"><progress id="cupgenapr232Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="cupgenapr23Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                            <div className="badgeItemTitle silver" tabIndex={0} role="button" aria-expanded="false">
                                <div>May 2023 Bonus Points</div>
                                <div>
                                    <progress id="cupgenmay23ProgressMini" value="25" max="100"></progress>
                                    <span className="icon-down-open"></span></div>
                                </div>
                            <div className="badgeItemIntro silver-light">Complete any of three clauses<Link to="/CupMay23">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Decorate a straw hat creatively with a design which includes at least 10 buttons</div>
                                <div className="badgeClauseStatus" id="cupgenmay231Status">Complete</div>
                                <div className="badgeClauseStart" id="cupgenmay231Start">1/5/2023</div>
                                <div className="badgeClauseEnd" id="cupgenmay231End">31/5/2023</div>
                                <div className="badgeClauseProgress"><progress id="cupgenmay231Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="cupgenmay23Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                            <div className="badgeItemTitle silver" tabIndex={0} role="button" aria-expanded="false">
                                <div>July 2023 Bonus Points</div>
                                <div>
                                    <progress id="cupgenjul23ProgressMini" value="25" max="100"></progress>
                                    <span className="icon-down-open"></span></div>
                                </div>
                            <div className="badgeItemIntro silver-light">Complete any of three challenges. <Link to="/CupJul23">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Pecan Pie and Ice Cream Sundae</div>
                                <div className="badgeClauseStatus" id="cupgenjul231Status">Complete</div>
                                <div className="badgeClauseStart" id="cupgenjul231Start">2/7/2023</div>
                                <div className="badgeClauseEnd" id="cupgenjul231End"></div>
                                <div className="badgeClauseProgress"><progress id="cupgenjul231Progress" value="25" max="100"></progress></div>
                            </div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Poem</div>
                                <div className="badgeClauseStatus" id="cupgenjul232Status">Complete</div>
                                <div className="badgeClauseStart" id="cupgenjul232Start">2/7/2023</div>
                                <div className="badgeClauseEnd" id="cupgenjul232End"></div>
                                <div className="badgeClauseProgress"><progress id="cupgenjul232Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="cupgenjul23Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                        <div className="badgeItemTitle silver" tabIndex={0} role="button" aria-expanded="false">
                            <div>October 2023 Bonus Points</div>
                            <div>
                                <progress id="cupgenoct23ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span></div>
                            </div>
                        <div className="badgeItemIntro silver-light">Complete any of three challenges. <Link to="/CupOct23">View evidence page</Link></div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Viking Boat</div>
                            <div className="badgeClauseStatus" id="cupgenoct231Status">Complete</div>
                            <div className="badgeClauseStart" id="cupgenoct231Start">14/10/2023</div>
                            <div className="badgeClauseEnd" id="cupgenoct231End">16/10/2023</div>
                            <div className="badgeClauseProgress"><progress id="cupgenoct231Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Pretzel</div>
                            <div className="badgeClauseStatus" id="cupgenoct232Status">Complete</div>
                            <div className="badgeClauseStart" id="cupgenoct232Start">21/10/2023</div>
                            <div className="badgeClauseEnd" id="cupgenoct232End">21/10/2023</div>
                            <div className="badgeClauseProgress"><progress id="cupgenoct232Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeProgress"><progress id="cupgenoct23Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                        <div className="badgeItemTitle silver" tabIndex={0} role="button" aria-expanded="false">
                            <div>November 2023 Bonus Points</div>
                            <div>
                                <progress id="cupgennov23ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span></div>
                            </div>
                        <div className="badgeItemIntro silver-light">Complete any of three challenges <Link to="/CupNov23">View evidence page</Link></div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">National Absurdity Day</div>
                            <div className="badgeClauseStatus" id="cupgennov231Status">Complete</div>
                            <div className="badgeClauseStart" id="cupgennov231Start">20/10/2023</div>
                            <div className="badgeClauseEnd" id="cupgennov231End">20/10/2023</div>
                            <div className="badgeClauseProgress"><progress id="cupgennov231Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Random Acts of Kindness</div>
                            <div className="badgeClauseStatus" id="cupgennov232Status">Complete</div>
                            <div className="badgeClauseStart" id="cupgennov232Start">01/11/2023</div>
                            <div className="badgeClauseEnd" id="cupgennov232End">30/11/2023</div>
                            <div className="badgeClauseProgress"><progress id="cupgennov232Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeProgress"><progress id="cupgennov23Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                        <div className="badgeItemTitle silver" tabIndex={0} role="button" aria-expanded="false">
                            <div>December 2023 Bonus Points</div>
                            <div>
                                <progress id="cupgendec23ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span></div>
                            </div>
                        <div className="badgeItemIntro silver-light">Art Competition</div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">I submitted my patrol leader drawing to the art competition and it was chosen by Nelson patrol to go through to the final. Sadly it didn't win so we didn't get the points for Nelson :(</div>
                            <div className="badgeClauseStatus" id="cupgendec231Status">Complete</div>
                            <div className="badgeClauseStart" id="cupgendec231Start">20/10/2023</div>
                            <div className="badgeClauseEnd" id="cupgendec231End">20/10/2023</div>
                            <div className="badgeClauseProgress"><progress id="cupgendec231Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeProgress"><progress id="cupgendec23Progress" value="25" max="100"></progress></div>
                    </div>
                    
                </div>
                <div className="row extrasYear">
                    <div className="extrasYear-Title" tabIndex={0} role="button" aria-expanded="false">2024<span className="icon-down-open shown"></span></div>
                    <div className="badgeItem">
                        <div className="badgeItemTitle silver" tabIndex={0} role="button" aria-expanded="false">
                            <div>2024 General Bonus Points</div>
                            <div>
                                <progress id="cupgen24ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span></div>
                            </div>
                        <div className="badgeItemIntro silver-light">Complete any of the annual challenges <Link to="/CupGen24">View evidence page</Link></div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Friendship Bracelet</div>
                            <div className="badgeClauseStatus" id="cupgen241Status">Complete</div>
                            <div className="badgeClauseStart" id="cupgen241Start">24/01/2024</div>
                            <div className="badgeClauseEnd" id="cupgen241End">24/01/2024</div>
                            <div className="badgeClauseProgress"><progress id="cupgen241Progress" value="25" max="100"></progress></div>
                        </div>

                        <div className="badgeProgress"><progress id="cupgen24Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                        <div className="badgeItemTitle silver" tabIndex={0} role="button" aria-expanded="false">
                            <div>January 2024 Bonus Points</div>
                            <div>
                                <progress id="cupjan24ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span></div>
                            </div>
                        <div className="badgeItemIntro silver-light">Complete any of two challenges <Link to="/CupJan24">View evidence page</Link></div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Cornucopia drawing</div>
                            <div className="badgeClauseStatus" id="cupjan241Status">Complete</div>
                            <div className="badgeClauseStart" id="cupjan241Start">21/01/2024</div>
                            <div className="badgeClauseEnd" id="cupjan241End">21/01/2024</div>
                            <div className="badgeClauseProgress"><progress id="cupjan241Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Tropic of Capricorn</div>
                            <div className="badgeClauseStatus" id="cupjan242Status">Complete</div>
                            <div className="badgeClauseStart" id="cupjan242Start">27/01/2024</div>
                            <div className="badgeClauseEnd" id="cupjan242End">27/01/2024</div>
                            <div className="badgeClauseProgress"><progress id="cupjan242Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeProgress"><progress id="cupjan24Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                        <div className="badgeItemTitle silver" tabIndex={0} role="button" aria-expanded="false">
                            <div>February 2024 Bonus Points</div>
                            <div>
                                <progress id="cupfeb24ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span></div>
                            </div>
                        <div className="badgeItemIntro silver-light">Complete any of two challenges <Link to="/CupFeb24">View evidence page</Link></div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Cup</div>
                            <div className="badgeClauseStatus" id="cupfeb241Status">Complete</div>
                            <div className="badgeClauseStart" id="cupfeb241Start">01/02/2024</div>
                            <div className="badgeClauseEnd" id="cupfeb241End">29/02/2024</div>
                            <div className="badgeClauseProgress"><progress id="cupfeb241Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeProgress"><progress id="cupfeb24Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                        <div className="badgeItemTitle silver" tabIndex={0} role="button" aria-expanded="false">
                            <div>March 2024 Bonus Points</div>
                            <div>
                                <progress id="cupmar24ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span></div>
                            </div>
                        <div className="badgeItemIntro silver-light">Complete any of four challenges <Link to="/CupMar24">View evidence page</Link></div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Paper Lanterns</div>
                            <div className="badgeClauseStatus" id="cupmar241Status">Complete</div>
                            <div className="badgeClauseStart" id="cupmar241Start">01/03/2024</div>
                            <div className="badgeClauseEnd" id="cupmar241End">23/03/2024</div>
                            <div className="badgeClauseProgress"><progress id="cupmar241Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Chart</div>
                            <div className="badgeClauseStatus" id="cupmar242Status">Complete</div>
                            <div className="badgeClauseStart" id="cupmar242Start">01/03/2024</div>
                            <div className="badgeClauseEnd" id="cupmar242End">22/03/2024</div>
                            <div className="badgeClauseProgress"><progress id="cupmar242Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeProgress"><progress id="cupmar24Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                        <div className="badgeItemTitle silver" tabIndex={0} role="button" aria-expanded="false">
                            <div>April 2024 Bonus Points</div>
                            <div>
                                <progress id="cupapr24ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span></div>
                            </div>
                        <div className="badgeItemIntro silver-light">Complete any of four challenges <Link to="/CupApr24">View evidence page</Link></div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Sheep</div>
                            <div className="badgeClauseStatus" id="cupapr241Status">Complete</div>
                            <div className="badgeClauseStart" id="cupapr241Start">01/04/2024</div>
                            <div className="badgeClauseEnd" id="cupapr241End">29/04/2024</div>
                            <div className="badgeClauseProgress"><progress id="cupapr241Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeProgress"><progress id="cupapr24Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                        <div className="badgeItemTitle silver" tabIndex={0} role="button" aria-expanded="false">
                            <div>May 2024 Bonus Points</div>
                            <div>
                                <progress id="cupmay24ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span></div>
                            </div>
                        <div className="badgeItemIntro silver-light">Complete any of four challenges <Link to="/CupMay24">View evidence page</Link></div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Mosaic</div>
                            <div className="badgeClauseStatus" id="cupmay241Status">Complete</div>
                            <div className="badgeClauseStart" id="cupmay241Start">01/05/2024</div>
                            <div className="badgeClauseEnd" id="cupmay241End">29/05/2024</div>
                            <div className="badgeClauseProgress"><progress id="cupmay241Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeProgress"><progress id="cupmay24Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                        <div className="badgeItemTitle silver" tabIndex={0} role="button" aria-expanded="false">
                            <div>July 2024 Bonus Points</div>
                            <div>
                                <progress id="cupjul24ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span></div>
                            </div>
                        <div className="badgeItemIntro silver-light">Complete any of four challenges <Link to="/CupJul24">View evidence page</Link></div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Life's a Beach</div>
                            <div className="badgeClauseStatus" id="cupjul241Status">Complete</div>
                            <div className="badgeClauseStart" id="cupjul241Start">01/07/2024</div>
                            <div className="badgeClauseEnd" id="cupjul241End">31/7/2024</div>
                            <div className="badgeClauseProgress"><progress id="cupjul241Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Junkyard Crabs</div>
                            <div className="badgeClauseStatus" id="cupjul242Status">Complete</div>
                            <div className="badgeClauseStart" id="cupjul242Start">01/07/2024</div>
                            <div className="badgeClauseEnd" id="cupjul242End">31/7/2024</div>
                            <div className="badgeClauseProgress"><progress id="cupjul242Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeProgress"><progress id="cupjul24Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeItem">
                        <div className="badgeItemTitle silver" tabIndex={0} role="button" aria-expanded="false">
                            <div>August 2024 Bonus Points</div>
                            <div>
                                <progress id="cupaug24ProgressMini" value="25" max="100"></progress>
                                <span className="icon-down-open"></span></div>
                            </div>
                        <div className="badgeItemIntro silver-light">Complete any of four challenges <Link to="/CupAug24">View evidence page</Link></div>
                        <div className="badgeClause">
                            <div className="badgeClauseTask">Crown</div>
                            <div className="badgeClauseStatus" id="cupaug241Status">Complete</div>
                            <div className="badgeClauseStart" id="cupaug241Start">01/08/2024</div>
                            <div className="badgeClauseEnd" id="cupaug241End">31/08/2024</div>
                            <div className="badgeClauseProgress"><progress id="cupaug241Progress" value="25" max="100"></progress></div>
                        </div>
                        <div className="badgeProgress"><progress id="cupaug24Progress" value="25" max="100"></progress></div>
                    </div>
                </div>
                <div className="row">
                    <div className="badgeItem">
                            <div className="badgeItemTitle silver" tabIndex={0} role="button" aria-expanded="false">
                                <div>One Off Cup Challenge</div>
                                <div>
                                    <progress id="cupgenjan23ProgressMini" value="25" max="100"></progress>
                                    <span className="icon-down-open"></span></div>
                                </div>
                            <div className="badgeItemIntro silver-light">Portrait of your patrol leader.<Link to="/nelson">View evidence page</Link></div>
                            <div className="badgeClause">
                                <div className="badgeClauseTask">Task 1</div>
                                <div className="badgeClauseStatus" id="cupgenjan231Status">Complete</div>
                                <div className="badgeClauseStart" id="cupgenjan231Start">14/01/2023</div>
                                <div className="badgeClauseEnd" id="cupgenjan231End">14/01/2023</div>
                                <div className="badgeClauseProgress"><progress id="cupgenjan231Progress" value="25" max="100"></progress></div>
                            </div>
                        <div className="badgeProgress"><progress id="cupgenjan23Progress" value="25" max="100"></progress></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function listenFunc(element, event)
{

    if(element.classList.contains("extrasYear")){
        var elementBadges = element.querySelectorAll(".badgeItem");
        var elementArrow = element.querySelector("span");

        if(element.getAttribute("aria-expanded") === "false")
        {
            element.setAttribute("aria-expanded", "true");
        }
        else
        {
            element.setAttribute("aria-expanded", "false");
        }

        if(elementArrow.classList.contains("icon-down-open")){
            elementArrow.classList.remove("icon-down-open");
            elementArrow.classList.add("icon-up-open");
        } else {
            elementArrow.classList.add("icon-down-open");
            elementArrow.classList.remove("icon-up-open");
        }

        elementBadges.forEach((eb) => {
            if(eb.classList.contains("hidden"))
            {
                eb.classList.remove("hidden");
                eb.classList.add("shown");
            } else {
                eb.classList.remove("shown");
                eb.classList.add("hidden");
            }
        })



    }
    else
    {

        event.stopPropagation();

        var elementIntro = element.parentElement.querySelector(".badgeItemIntro");
        var elementClause = element.parentElement.querySelectorAll(".badgeClause");
        var elementProgress = element.parentElement.querySelector(".badgeProgress");

        if(element.getAttribute("aria-expanded") === "false")
        {
            element.setAttribute("aria-expanded", "true");
        }
        else
        {
            element.setAttribute("aria-expanded", "false");
        }

        if(elementIntro.classList.contains("hidden"))
        {
            elementIntro.classList.remove("hidden");
            elementIntro.classList.add("shown");
        } else {
            elementIntro.classList.remove("shown");
            elementIntro.classList.add("hidden");
        }

        elementClause.forEach((ec) => {
            if(ec.classList.contains("hidden"))
            {
                ec.classList.remove("hidden");
                ec.classList.add("shown");
            } else {
                ec.classList.remove("shown");
                ec.classList.add("hidden");
            }
        })
        

        if(elementProgress.classList.contains("hidden"))
        {
            elementProgress.classList.remove("hidden");
            elementProgress.classList.add("shown");
        } else {
            elementProgress.classList.remove("shown");
            elementProgress.classList.add("hidden");
        }
    }
}

function addListener(element)
{
    element.addEventListener("click",function(e){
        listenFunc(element,e);
    })

    element.addEventListener("keydown", function(e){
    if (e.key !== undefined){
        if(e.key === "Enter" || e.key === " " || e.key === "ArrowDown" || e.key === "ArrowUp" || e.key === "ArrowLeft" || e.key === "ArrowRight")
        {
            listenFunc(element,e);
        }
    } else if (e.which !== undefined) {
        if(e.key === 13 || e.key === 32 || e.key === 40 || e.key === 38 || e.key ===37 || e.key === 39)
        {
            listenFunc(element,e);
        }
    }
    })
}

export default Book