import React from 'react'
import "../styles/styles.css";
import "../styles/rebel.css";
import "../styles/fontello.css";
import Logo from "../images/SiteImage.png";
 
const Header = () => {

    return (
        <header>
            <div className="row site-header">
                <div className="site-logo"><a href="https://www.ameliaraine.me.uk"><img src={Logo} alt="Close in head shot of Amelia Raine" /></a></div>
                <div className="site-cover"><h1>Amelia Raine</h1><br/>
                </div>
                    <div className="site-social"><div>
                        <a href="https://www.linkedin.com/in/ameliaraine" target="_blank" rel="noreferrer" aria-label="Open LinkedIn (New Window)"><span className="icon-linkedin"></span></a>
                    </div>
                    <div><a href="https://twitter.com/msrainea" target="_blank" rel="noreferrer" aria-label="Open Twitter (New Window)"><span className="icon-twitter"></span></a></div>
                </div>
            </div>
        </header>
    )
}

export default Header