import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import LipBalm1 from '../images/apothecary-lipbalm1.jpg';
import LipBalm2 from '../images/apothecary-lipbalm2.jpg';
import LipBalm3 from '../images/apothecary-lipbalm3.jpg';
import LipBalm4 from '../images/apothecary-lipbalm4.jpg';
import LipBalm5 from '../images/apothecary-lipbalm5.jpg';
import Toner2 from '../images/apothecary-toner2.jpg';
import Toner3 from '../images/apothecary-toner3.jpg';
import Exfoliator2 from '../images/apothecary-exfoliator2.jpg';
import Exfoliator3 from '../images/apothecary-exfoliator3.jpg';
import Exfoliator4 from '../images/apothecary-exfoliator4.jpg';
import ShowerGel2 from '../images/apothecary-showergel2.jpg';
import ShowerGel3 from '../images/apothecary-showergel3.jpg';
import ShowerGel4 from '../images/apothecary-showergel4.jpg';
import ShowerGel5 from '../images/apothecary-showergel5.jpg';
import Book from '../images/apothecary-book.jpg';
import Oils from '../images/apothecary-oils.jpg';
import Moisturiser1 from '../images/apothecary-moisturiser1.jpg';
import Moisturiser2 from '../images/apothecary-moisturiser2.jpg';
import Moisturiser3 from '../images/apothecary-moisturiser3.jpg';
import Moisturiser4 from '../images/apothecary-moisturiser4.jpg';
import Moisturiser5 from '../images/apothecary-moisturiser5.jpg';
import Moisturiser6 from '../images/apothecary-moisturiser6.jpg';
import Moisturiser7 from '../images/apothecary-moisturiser7.jpg';
import Moisturiser8 from '../images/apothecary-moisturiser8.jpg';


const Apothecary = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Apothecary"} />
            <div className="badgePageItem pageIntro">
                For all clauses except the lip balm, I have used an amazing book called "The Compassionate Chick's Guide to DIY Beauty". It has gorgeous, vegan, cruelty free recipes for just about anything you could want!
                <br/><br/>
                I also purchased a box of essential oils from Amazon, as they are something I will use again and buying the box worked out cheaper than buying the oils I needed individually.
                <br/><br/>
                <img className="imgThird" src={Book} alt="The Compassionate Chick's Guide to DIY Beauty" />
                <img className="imgThird" src={Oils} alt="Various essential oils pictured in a collection box." />
                <br/><br/>
                </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Toner</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence">
                        <p><h2>Toner for Acne Prone Skin</h2></p>
                        <br/>
                        <p>This recipe uses Cold brew green tea, Apple cider vinegar and Lavender essential oil. It is used as a spritz, in a spray bottle.</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Toner2} alt="Cold brew green tea, Cider apple vinegar and lavender oil ready to mix" /></div>
                            <div className="galleryItem"><img src={Toner3} alt="Cold brew green tea, Cider apple vinegar and lavender oil mixed into a spray bottle for spritzing" /></div>
                        </div>
                        <br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Moisturiser</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence">
                        <p><h2>Unicorn Kisses Shimmer Lotion</h2></p>
                        <br/>
                        <p>This recipe uses Cocoa Butter, Coconut oil, mica powder, orange lemon and peppermint essential oils</p>
                        <br/>
                        <p>The recipe called for gold mica powder, I chose silver. Consequently my moisturiser ended up an odd grey colour!!!  Next time - white pearly powder!</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Moisturiser1} alt="Silver mica powder in a mixing bowl" /></div>
                            <div className="galleryItem"><img src={Moisturiser2} alt="Silver mica powder on a teaspoon laid over a bag of the powder" /></div>
                            <div className="galleryItem"><img src={Moisturiser3} alt="Spoon in a bowl of mixed, melted ingredients" /></div>
                            <div className="galleryItem"><img src={Moisturiser4} alt="Hand whisk over a bowl containing mixed ingredients" /></div>
                            <div className="galleryItem"><img src={Moisturiser5} alt="Mixed ingredients in a bowl with a spoon" /></div>
                            <div className="galleryItem"><img src={Moisturiser6} alt="Three pots containing home made moisturiser" /></div>
                            <div className="galleryItem"><img src={Moisturiser7} alt="Close up of a pot of home made moisturiser showing the grey colour and silver sparkles" /></div>
                            <div className="galleryItem"><img src={Moisturiser8} alt="Close up of a pot of home made moisturiser showing the grey colour and silver sparkles" /></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Lip Balm</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence">
                        <p><h2>Beeswax and Peppermint Lip Balm</h2></p>
                        <br/><br/>
                        <p>When I was away on my Maverick adventure, I came across a lip balm kit which was very useful! It feels like cheating to use a kit, but it's better to do it this way to save wasting any ingredients - they have exactly what you need.</p>
                        <br/>
                        <p>It uses beeswax, coconut oil and shea butter and I added some drops of my own spearmint essential oil to it.</p>
                        <br/>
                        <div className="gallery">
                            <img className="imgQuarter" src={LipBalm1} alt="Kit for making your own lip balm" />
                            <img className="imgQuarter" src={LipBalm2} alt="Melting the lip balm ingredients over a pan of water" />
                            <img className="imgQuarter" src={LipBalm3} alt="Melted lip balm mixture poured into the containers to set" />
                            <img className="imgQuarter" src={LipBalm4} alt="Lip balm mixture set in the containers" />
                            <img className="imgQuarter" src={LipBalm5} alt="Finished lip balms" />
                        </div>
                        <br/>
                        <p>I just need to find a willing recipient for one of these now!</p>
                        <br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Exfoliator</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence">
                        <p><h2>Coffee Body Scrub</h2></p>
                        <br/><br/>
                        <p>This recipe uses coffee grounds, brown sugar and coconut oil. It makes a really effective exfoliator and it smells amazing!</p>
                        <br/><br/>
                         <div className="gallery">
                            <div className="galleryItem"><img src={Exfoliator2} alt="Coffee grounds, brown sugar and coconut oil on a kitchen surface" /></div>
                            <div className="galleryItem"><img src={Exfoliator3} alt="Coffee grounds being mixed in a bowl with brown sugar and coconut oil" /></div>
                            <div className="galleryItem"><img src={Exfoliator4} alt="Coffee body scrub in a tupperware pot" /></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Shower Gel</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence">
                        <p><h2>Invigorate Me Body Wash</h2></p>
                        <br/><br/>
                        <p>This is the recipe with the most ingredients and I had to purchase some special items.</p>
                        <br/><br/>
                        <p>This recipe uses: Castile soap, Vegetable Glycerin, Sweet Almond Oil, Lavender essential oil, Rosemary essential oil, Peppermint essential oil.</p>
                        <br/><br/>
                        <p>At least I won't need to make more bar soap for a while!</p>
                        <br/><br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={ShowerGel2} alt="Castile soap, vegetable gelatine, Sweet almond oil, glass pump bottle and a measuring jug on a kitchen surface" /></div>
                            <div className="galleryItem"><img src={ShowerGel3} alt="Soap ingredients mixed in a jug" /></div>
                            <div className="galleryItem"><img src={ShowerGel4} alt="Soap ingredients mixed in a jug with three bottles of essential oil in front" /></div>
                            <div className="galleryItem"><img src={ShowerGel5} alt="Home made shower gel in a glass bottle with a plastic pump top" /></div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Apothecary