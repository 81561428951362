import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Wreath1 from '../images/winterrebel23-wreath1.jpg';
import Wreath2 from '../images/winterrebel23-wreath2.jpg';
import Wreath3 from '../images/winterrebel23-wreath3.jpg';
import Wreath4 from '../images/winterrebel23-wreath4.jpg';
import Wreath5 from '../images/winterrebel23-wreath5.jpg';
import Wreath6 from '../images/winterrebel23-wreath6.jpg';
import Wreath7 from '../images/winterrebel23-wreath7.jpg';
import Wreath8 from '../images/winterrebel23-wreath8.jpg';
import Wreath10 from '../images/winterrebel23-wreath10.jpg';
import Sculpture1 from '../images/winterrebel23-sculpture1.jpg';
import Sculpture2 from '../images/winterrebel23-sculpture2.jpg';
import Sculpture3 from '../images/winterrebel23-sculpture3.jpg';
import Sculpture4 from '../images/winterrebel23-sculpture4.jpg';
import Sculpture5 from '../images/winterrebel23-sculpture5.jpg';
import Sculpture6 from '../images/winterrebel23-sculpture6.jpg';
import Sculpture7 from '../images/winterrebel23-sculpture7.jpg';
import Sculpture8 from '../images/winterrebel23-sculpture8.jpg';
import Sculpture9 from '../images/winterrebel23-sculpture9.jpg';
import Sculpture10 from '../images/winterrebel23-sculpture10.jpg';
import Sculpture11 from '../images/winterrebel23-sculpture11.jpg';
import Sculpture12 from '../images/winterrebel23-sculpture12.jpg';
import Sculpture13 from '../images/winterrebel23-sculpture13.jpg';
import Sculpture14 from '../images/winterrebel23-sculpture14.jpg';
import Igloo1 from '../images/winterrebel23-igloo1.jpg';
import Igloo2 from '../images/winterrebel23-igloo2.jpg';
import Igloo3 from '../images/winterrebel23-igloo3.jpg';
import Igloo4 from '../images/winterrebel23-igloo4.jpg';
import Igloo5 from '../images/winterrebel23-igloo5.jpg';
import Igloo6 from '../images/winterrebel23-igloo6.jpg';
import Igloo7 from '../images/winterrebel23-igloo7.jpg';
import Penguin1 from '../images/winterrebel23-penguin1.jpg';
import Penguin2 from '../images/winterrebel23-penguin2.jpg';
import Penguin3 from '../images/winterrebel23-penguin3.jpg';
import Penguin4 from '../images/winterrebel23-penguin4.jpg';
import Penguin5 from '../images/winterrebel23-penguin5.jpg';
import Penguin6 from '../images/winterrebel23-penguin6.jpg';
import Globe1 from '../images/winterrebel23-snowglobe1.jpg';
import Globe2 from '../images/winterrebel23-snowglobe2.jpg';
import Globe3 from '../images/winterrebel23-snowglobe3.jpg';
import Globe4 from '../images/winterrebel23-snowglobe4.jpg';

const Xmas23 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Christmas 2023"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Make a festive wreath</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>I made my wreath during Charly's session at Winter Rebel Fest.</p>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Wreath1} alt="A wreath ring and dried moss on a table" /></div>
                            <div className="galleryItem"><img src={Wreath2} alt="Adding moss to a wreath ring" /></div>
                            <div className="galleryItem"><img src={Wreath3} alt="Adding moss to a wreath ring" /></div>
                            <div className="galleryItem"><img src={Wreath4} alt="Adding moss to a wreath ring" /></div>
                            <div className="galleryItem"><img src={Wreath5} alt="Adding moss to a wreath ring" /></div>
                            <div className="galleryItem"><img src={Wreath6} alt="Adding a pine branch to a mossed wreath ring" /></div>
                            <div className="galleryItem"><img src={Wreath7} alt="Adding a pine branch to a mossed wreath ring" /></div>
                            <div className="galleryItem"><img src={Wreath8} alt="Adding pine cones, pine needles and red berries to a mossed wreath ring" /></div>
                            <div className="galleryItem"><img src={Wreath10} alt="Completed Christmas wreath" /></div>
                        </div>
                        <br/><br/>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Make a snow globe</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>Sigh, let's add this to the list of winter badge disasters. I guess my head wasn't all in on this one! I read you could use baby oil instead of glycerine to thicken the water - I should have known better. I did re-do it with just water but the oil had impacted the jar by that point.</p>
                        <br/>
                        <p>At least the insert was kinda pretty, even if the overall effect was not what I would have liked.</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Globe1} alt="A small decoration of a gold fabric leaf, red and white berries, an acorn and a wooden star placed into oasis" /></div>
                            <div className="galleryItem"><img src={Globe2} alt="A glass jar, baby oil, glitter and a small arrangement ready to be placed in the jar" /></div>
                            <div className="galleryItem"><img src={Globe3} alt="A jar of water containing glitter, ready to be used as a snow globe" /></div>
                            <div className="galleryItem"><img src={Globe4} alt="A jar of water and glitter with a small, floral insert being shown as a snow globe." /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Make a Sculpture out of ice</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>OK, let's all have a good laugh! The clause says to make a sculpture, not that it has to be recognisable or successful, right?!</p>
                        <p>Being Christmas, I thought a Christmas tree might be fairly straightforward - how wrong I was!</p>
                        <br/>
                        <p>I needed to start with a tube, or at least that's what I thought at the time. I put a plastic bag inside a toilet roll tube and filled it with water then froze it.</p>
                        <p>It gave a rough shape, a block to start with at least. Over a few days, I started working on it a bit at a time as I had to keep re-freezing.</p>
                        <br/>
                        <p>I got to point where I could kind of see a tree - but I could kind of see something else (let's not summon badge 502 - if you know, you know!). My daughter confirmed my suspicion!!</p>
                        <p>I sent more time on it the following day, improving things slightly, but then I broke it while trying to take a video!</p>
                        <br/>
                        <p>So here it is, my, um, xmas tree ice sculpture!</p>
                        <br/>
                        <div className='gallery'>
                            <div className='galleryItem'><img src={Sculpture1} alt="A small block of ice being carved into a christmas tree shape (sort of!)" /></div>
                            <div className='galleryItem'><img src={Sculpture2} alt="A small block of ice being carved into a christmas tree shape (sort of!)" /></div>
                            <div className='galleryItem'><img src={Sculpture3} alt="A small block of ice being carved into a christmas tree shape (sort of!)" /></div>
                            <div className='galleryItem'><img src={Sculpture4} alt="A small block of ice being carved into a christmas tree shape (sort of!)" /></div>
                            <div className='galleryItem'><img src={Sculpture5} alt="A small block of ice being carved into a christmas tree shape (sort of!)" /></div>
                            <div className='galleryItem'><img src={Sculpture6} alt="A small block of ice being carved into a christmas tree shape (sort of!)" /></div>
                            <div className='galleryItem'><img src={Sculpture7} alt="A small block of ice being carved into a christmas tree shape (sort of!)" /></div>
                            <div className='galleryItem'><img src={Sculpture8} alt="A small block of ice being carved into a christmas tree shape (sort of!)" /></div>
                            <div className='galleryItem'><img src={Sculpture9} alt="A small block of ice being carved into a christmas tree shape (sort of!)" /></div>
                            <div className='galleryItem'><img src={Sculpture10} alt="A small block of ice being carved into a christmas tree shape (sort of!)" /></div>
                            <div className='galleryItem'><img src={Sculpture11} alt="A small block of ice being carved into a christmas tree shape (sort of!)" /></div>
                            <div className='galleryItem'><img src={Sculpture12} alt="A small block of ice being carved into a christmas tree shape (sort of!)" /></div>
                            <div className='galleryItem'><img src={Sculpture13} alt="A small block of ice being carved into a christmas tree shape (sort of!)" /></div>
                            <div className='galleryItem'><img src={Sculpture14} alt="A small block of ice being carved into a christmas tree shape (sort of!)" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Watch a film or documentary about penguins</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>I went on to the Waterbear app and watched Penguins in Rehab and Return to Penguin City. I can't evidence it here because the app does not allow screenshots to be taken.</p>          
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Build an igloo</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>In the second of my christmas disasters, I present an "igloo".  I thought it would be great to try and make it out of ice. I was wrong!!</p>
                        <br/>
                        <p>I tried. It's impossible! I had to do this over several days and hours again because I had to re-freeze at each layer - I'll let you decide at which layer I got fed up!!</p>
                        <br/>
                        <p>Using a plate as a base wasn't working well, so I froze a puddle of ice onto the plate first and that worked much better for "sticking" the base layer. At least one part went relatively well!</p>
                        <br/>
                        <p>I thought my ice cubes were a good shape, but square / rectangle ones would have been easier. At the end, I had to put something in the middle to hold the top ones in place until they had frozen.</p>
                        <br/>
                        <div className='gallery'>
                            <div className='galleryItem'><img src={Igloo1} alt="A bag of ice cubes with a plate and some table salt, ready to arrange into an igloo."/></div>
                            <div className='galleryItem'><img src={Igloo2} alt="A plate with a layer of frozen water"/></div>
                            <div className='galleryItem'><img src={Igloo3} alt="Ice cubes placed in a circle on a plate to form the base of an igloo"/></div>
                            <div className='galleryItem'><img src={Igloo4} alt="A partial igloo made from ice cubes arranged on a plate"/></div>
                            <div className='galleryItem'><img src={Igloo5} alt="A partial igloo made from ice cubes arranged on a plate"/></div>
                            <div className='galleryItem'><img src={Igloo6} alt="A partial igloo made from ice cubes arranged on a plate"/></div>
                            <div className='galleryItem'><img src={Igloo7} alt="An igloo (sort of!) made of ice cubes"/></div>
                        </div>          
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Make a Penguin</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>To fulfil this clause, I made an origami penguin bookmark. Sounds easy, doesn't it! This took me entirely too long, despite the very clear instructions. Got there eventually!</p>
                        <br/>
                        <p>Source: https://jonakashima.com.br/2017/03/23/origami-penguin-bookmark/</p>
                        <br/><br/>
                        <div className='gallery'>
                            <div className='galleryItem'><img src={Penguin1} alt="A small piece of paper being folded into a penguin."/></div>
                            <div className='galleryItem'><img src={Penguin2} alt="A small piece of paper being folded into a penguin."/></div>
                            <div className='galleryItem'><img src={Penguin3} alt="A small piece of paper being folded into a penguin."/></div>
                            <div className='galleryItem'><img src={Penguin4} alt="A small piece of paper being folded into a penguin."/></div>
                            <div className='galleryItem'><img src={Penguin5} alt="A small piece of paper being folded into a penguin."/></div>
                            <div className='galleryItem'><img src={Penguin6} alt="An origami penguin bookmark holding a place in a book"/></div>
                        </div>     
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Xmas23