import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Interior1 from '../images/diy2.jpg';
import Interior2 from '../images/diy3.jpg';
import Interior3 from '../images/diy4.jpg';
import Interior4 from '../images/diy1.jpg';
import Interior5 from '../images/diy5.jpg';
import Visual1 from '../images/interior-visual1.jpg';
import Visual2 from '../images/interior-visual2.jpg';
import Visual3 from '../images/interior-visual3.jpg';
import Visual4 from '../images/interior-visual4.jpg';
import Visual5 from '../images/interior-visual5.jpg';
import Visual6 from '../images/interior-visual6.jpg';
import Visual7 from '../images/interior-visual7.jpg';
import Visual8 from '../images/interior-visual8.jpg';


const InteriorDesigner = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Interior Designer"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">01 Upcycle</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Repaint or reupholster</div>
                    <div className="badgePageItemEvidence">
                        I upcycled a wooden chest that I've had a few years. I had a big fish tank that I needed a stand for. This piece of furniture was salvaged from the tip for £10.
                        <br/><br/>
                        As my room at the time was all natural wood, I varnished it but I always hated the colour, it wasn't natural or particularly nice!!
                        <br/><br/>
                        I wanted to keep the chest because it's really good storage and actually it can double-up as spare seating, so I decided to change it completely to fit my new silver sitting room.
                        <br/><br/>
                        Unfortunately I didn't take any before and after shots because I wasn't anticipating having to evidence a badge at the time! Here is a photo of it before, the best I could find from the archive and what it looks like now.
                        <br/><br/>
                        <img className="imgHalf" src={Interior1} alt="Wooden furniture varnished with an orange/brown shade" />
                        <img className="imgHalf" src={Interior2} alt="Wooden furniture painted with a grey gloss" />
                        <br/><br/>
                        I sanded it down and changed the colour to a grey gloss, I replaced the wooden handles with some nice metal alternatives.
                        <br/><br/>
                        It now sits in the bay window where it looks very attractive and can be used for seating if needed. It is actually a large drawer unit which you can get into either by pulling the drawer out or by lifting the lid.
                        <br/>
                        <img className="imgQuarter" src={Interior3} alt="Wooden storage unit painted with grey gloss, located in a bay window" />
                        <br/>
                        </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">02 Colour Scheme</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">New colour scheme</div>
                    <div className="badgePageItemEvidence">
                        I have decorated a lot of walls in the last few years! However, I'll highlight this one because it's currently my favourite!
                        <br/><br/>
                        The downstairs of my house is open plan. The front  half and back half of the main room have always been treated as one room and decorated accordingly. 
                        <br/><br/>
                        This time, I decided to give them separate identities! I didn't want to have a dodgy-looking paint line in the middle of the room where the two sides collided though.
                        <br/><br/>
                        It's difficult to produce a visual as this is retrospective, but this was my process:
                        <br/><br/>
                        I had chosen the colours that I wanted: silver and pale yellow
                        <br/><br/>
                        I needed a way to "join" them. I started by looking at mosaic tiles in various colours, or mirrored.
                        <br/>
                        <img className="imgQuarter" src={Visual1} alt="Mosaic tiles" width="200px" height="200px" />
                        <img className="imgQuarter" src={Visual2} alt="Mosaic tiles" width="200px" height="200px" />
                        <img className="imgQuarter" src={Visual3} alt="Mosaic tiles" width="200px" height="200px" />
                        <img className="imgQuarter" src={Visual4} alt="Mosaic tiles" width="200px" height="200px" />
                        <br/><br/>
                        I decided that as much as I liked them that wasn't the way forward because they would stand away from the wall significantly and I didn't want that, I thought it would look odd.
                        <br/><br/>
                        I then thought about something flatter - wallpaper! I started looking around for silver wallpapers and was amazed by the selection! Here are some from B&Q:
                        <br/>
                        <img className="imgQuarter" src={Visual5} alt="B&Q search results for silver wallpaper" width="200px" height="200px" />
                        <br/><br/>

                        I wanted something a bit different, maybe slightly textured. I found this stunning arthouse velvet roll - I did think it actually was velvet, it isn't, it is a velvet effect. 
                        <br/>
                        <img className="imgQuarter" src={Visual6} alt="Silver wallpaper in a velvet style" width="200px" height="200px" />
                        <br/><br/>
                        So, I ordered that but I still wasn't happy because potentially I'd still have dodgy looking lines at the join - depending on how well I cut the wallpaper!
                        <br/><br/>
                        My next move was to look at crystals (I know, I'm such a diva!). I started searching for flatback rhinestones.
                        <br/>
                        <img className="imgQuarter" src={Visual7} alt="Search results for silver rhinestones" width="200px" height="200px" />
                        <br/><br/>
                        I came across these on Amazon which were perfect because you could cut them in strips. I bought these to edge the wallpaper and hide any wonky lines!
                        <br/>
                        <img className="imgQuarter" src={Visual8} alt="Search results for silver rhinestones" width="200px" height="200px" />
                        <br/><br/>
                        The crystals didn't go as smoothly as I planned so I may re-do them at some point, but from a distance they look fine!
                        <br/><br/>
                        This is what the join in the two colour schemes looks like:
                        <br/>
                        <img className="imgQuarter" src={Interior5} alt="Painted walls with a feature wallpaper" />
                        <br/>
                        There is an RSJ above so it's a perfect room divider and the wallpaper feature goes up each side of the wall and across the bottom of the beam to make a full arch.
                        <br/><br/>
                        I have a soft yellow in the dining / music room and the lovely metallic silver in the living room. That was a real gamble and a lot of hard work to do, you can still see brush strokes in a lot of places but it gives it character!
                        <br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">04 Flat Pack</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Flat-pack</div>
                    <div className="badgePageItemEvidence">
                        I have back-dated this one as it's unlikely I will be buying any more flat-pack furniture at least in the near future.
                        <br/><br/>
                        The last item I assembled was this TV unit. I don't have photos of the process as I wasn't anticipating having to evidence it, but let's just say I put things together and took them apart again before I got to the correct configuration!!
                        <br/><br/>
                        <img className="imgQuarter" src={Interior4} alt="Solid wood television cabinet in a grey colour with light rustic wooden top" width="500px" height="500px" />
                        <br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">05 Rearrange</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Rearrange furniture</div>
                    <div className="badgePageItemEvidence">
                        I have backdated this as I won't be making any more changes to the house any time soon and the furniture is in the best configuration with not a lot of room for change.
                        <br/><br/>
                        I re-decorated my daughter's old room - now my spare room! It wasn't that I wanted to erase her presence, but I wanted to create a nicer, more adult space that she could return to if she wanted to (as in to visit, not to move back in!!)
                        <br/><br/>
                        Her bed had been placed in the far right corner of the room with the chest of drawers on the opposite wall. She had storage which she took with her when she moved that was at the end of her bed.
                        <br/><br/>
                        I got rid of her bed, which was a huge solid divan and dismantled other storage. I kept just one solid wooden chest of drawers.
                        <br/><br/>
                        Part of the reason for losing the bed was because it was a single and I wanted to have a larger option for visitors.
                        <br/><br/>
                        I installed a gorgeous metal day bed which is a single but has a pull-out second bed that comes up to the same level, turning it in to a king size.
                        <br/><br/>
                        I placed that to the far-left of the room so that it is near the window and radiator and moved the chest of drawers to the far right, in line with the bed.
                        <br/><br/>
                        There is a lot of floor space now and the room has really good light, it's lovely for sitting and reading and can be used either as a sitting / living space or as a bedroom for guests.
                        <br/><br/>
                        I added one small bookcase for extra shelf space and a lamp for reading. 
                        <br/><br/>
                        I really like this room. Green of any shade isn't a colour I would usually embrace, but I know (certainly at the time) she liked the cucumber / minty shades and teal colours, so it seemed like the right choice. 
                        <br/><br/>
                        I added silver cushions and bedding which compliments the green really nicely and I upcycled her large ornate gold mirror with a light-coloured crackle paint which was very effective.
                        <br/><br/>
                        The bed just fits - this became the secondary bedroom because I couldn't fit my king-size bed in it - not if I wanted to be able to move and have a wardrobe anyway! When the second bed is put up, it fits very snuggly into the spare space with just enough room to close the door!! It's cosy but fine for overnight guests!
                        <br/><br/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InteriorDesigner