import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Chilli1 from '../images/Farmer-Chillies1.jpg';
import Chilli2 from '../images/Farmer-Chillies2.jpg';
import Chilli3 from '../images/Farmer-Chillies3.jpg';
import Chilli4 from '../images/Farmer-Chillies4.jpg';
import Chilli5 from '../images/Farmer-Chillies5.jpg';
import Chilli6 from '../images/Farmer-Chillies6.jpg';
import Chutney1 from '../images/Farmer-Chutney1.jpg';
import Chutney2 from '../images/Farmer-Chutney2.jpg';
import Chutney3 from '../images/Farmer-Chutney3.jpg';
import Chutney4 from '../images/Farmer-Chutney4.jpg';
import Pickle1 from '../images/Farmer-Pickle1.jpg';
import Pickle2 from '../images/Farmer-Pickle2.jpg';
import Pickle3 from '../images/Farmer-Pickle3.jpg';
import Butter1 from '../images/Farmer-Butter1.jpg';
import Butter2 from '../images/Farmer-Butter2.jpg';
import Butter3 from '../images/Farmer-Butter3.jpg';


const Farmer = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"Farmer"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 1 - Pickle Vegetables</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>Pickling is not something that I have done before, but I found a basic method and I pickled red onion. I have very much enjoyed it so will definitely be doing more!</p>
                        <div className="gallery">
                            <div className="imgthird"><img src={Pickle1} alt="Slices of red onion on a chopping board with salt and a jug of vinegar."/></div>
                            <div className="imgthird"><img src={Pickle2} alt="Slices of red onion in a jar ready for pickling."/></div>
                            <div className="imgthird"><img src={Pickle3} alt="Pickled red onion in a jar."/></div>
                        </div>
                    </div>
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 2 - Bake Bread</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>I have baked bread for my Baker badge, you can see that on my <a href="/baker" className="linkUnderline">Baker page</a>.</p>
                    </div>
                </div>
            </div>          
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 3 - Make Butter</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>I made my own butter for this clause, following the instructions here:</p>
                        <p>https://www.bbcgoodfood.com/recipes/home-churned-butter</p>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Butter1} alt="Double cream after shaking to form butter."/></div>
                            <div className="galleryItem"><img src={Butter2} alt="Freshly made butter in a sieve."/></div>
                            <div className="galleryItem"><img src={Butter3} alt="Freshly made butter in small bowls."/></div>
                        </div>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 4 - Grow Onions, Garlic or Chilli Peppers from seed</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>I grew chilli plants as part of my Gardener badge. It wasn't planned! After dinner one night I stuck 4 chilli seeds in a tiny plant pot that I had and kept it on the windowsill in the kitchen. Before I knew it, I had four very healthy chilli plants!! I had a huge crop of both green and red chillis, definitely be growing those again!</p>
                        <div className="gallery">
                            <div className="imgthird"><img src={Chilli1} alt="A small plant pot with a single seedling showing."/></div>
                            <div className="imgthird"><img src={Chilli2} alt="A small plant pot with three seedlings showing."/></div>
                            <div className="imgthird"><img src={Chilli3} alt="Four chilli seedlings in pots on a windowsill."/></div>
                            <div className="imgthird"><img src={Chilli4} alt="A small chilli plant growing in a pot with a single red chilli."/></div>
                            <div className="imgthird"><img src={Chilli5} alt="A single green chilli growing on a small chilli plant in a pot."/></div>
                            <div className="imgthird"><img src={Chilli6} alt="Three home-grown red chillis ready for eating."/></div>
                        </div>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 5 - Make Jam, Marmalade or Chutney</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>My mum had a couple of marrows and didn't know what to do with them. So, we turned them into marrow chutney! I had not tried marrow before, but it was very tasty.</p>
                        <div className="gallery">
                            <div className="imgthird"><img src={Chutney1} alt="Two marrows of different sizes."/></div>
                            <div className="imgthird"><img src={Chutney2} alt="Ingredients chopped and measured out ready for combining."/></div>
                            <div className="imgthird"><img src={Chutney3} alt="Chutney ingredients being mixed in a white mixing bowl."/></div>
                            <div className="imgthird"><img src={Chutney4} alt="Home made marrow chutney in a variety of glass jars."/></div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default Farmer