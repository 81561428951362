import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import CupidMeme from '../images/feb23love-cupid.jpg';
import Rose from '../images/feb23love-rose.jpg';
import Cupid1 from '../images/feb23love-cupid1.jpg';
import Cupid2 from '../images/feb23love-cupid2.jpg';
import LawNerd from '../images/feb23love-lawnerd.jpg';

const LoveFeb23 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Love"} />
            <p><strong>This page provides my evidence for the February 2023 Monthly Badge - Love.  The information for this badge is available publicly on the <a className="linkUnderline" href="https://www.rebelbadgestore.com/rebelblog/february-monthly-challenge-the-love-badge" target="_blank" rel="noopener noreferrer">blog</a> so descriptions haven't been removed, but I have only included those items that I am planning to do.</strong></p>
            <br/>

            <br/><br/>
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple-light">Treat yourself once a week (Mandatory)</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        This is easy. I do this anyway, because I'm always following a strict diet so one day a week is always treat day! It usually involves a cheat meal - something not diet friendly that I don't have to cook from scratch - a few drinks and some crisps!
                        <br/><br/>
                        Combined with a pamper, it makes me feel good!
                        <br/><br/>
                        Extra treats I have bought this month:
                        <br/>
                        New tshirts for beach clean outings (very much needed and long delayed!)
                        <br/>
                        New jeans as my old ones have finally given up the ghost! 
                        <br/>
                        Merch from my favourite LawTuber - Emily D. Baker! I'm a big fan of these slogans!
                        <br/>
                        <img className="imgQuarter" src={LawNerd} alt="Tshirt with the slogan Facts not Fuckery and mug with the slogan Coffee & Cursey Words" />
                        <br/>
                        <p>With Conscious Consumer in mind, it's been a long time since I've made any such purchases!</p>
                        <br/>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink-light">Make a rose</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>I have made hundreds of paper roses, so I tried one using a different technique. I made it using neon paper for something different.</p>
                        <br/>
                        <img src={Rose} alt="Rose made from neon pink paper with neon green leaves" className="imgQuarter" />
                        <br/>
                        <p>I followed the technique used in this YouTube video: <a href="https://www.youtube.com/watch?v=LCakEjLffwI" target="_blank" rel="noopener noreferrer">https://www.youtube.com/watch?v=LCakEjLffwI</a></p>
                        <br/>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple-light">Give someone you care about a bunch of flowers</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        I forgot to take a photo! I bought some orange roses for my daughter, unfortunately she was unwell and I wasn't able to visit so I gave them to my Mum!
                    </div>
                </div>
            </div>
                <div className="badgePageItem">
                <div className="badgePageItemTitle pink-light">Learn about the five love languages and recognise which apply to you</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence">
                        The five love languages are:
                        <br/>
                        <ul className="list">
                            <li>Words of affirmation - saying supportive things</li>
                            <li>Acts of service - doing helpful things</li>
                            <li>Receiving gifts - giving gifts</li>
                            <li>Quality time - Spending time together</li>
                            <li>Physical touch - being close physically</li>
                        </ul>
                        <br/>
                        Source: <a className="linkUnderline" href="https://www.psychologytoday.com/intl/blog/click-here-happiness/202009/what-are-the-5-love-languages-definition-and-examples" target="_blank" rel="noopener noreferrer">https://www.psychologytoday.com/intl/blog/click-here-happiness/202009/what-are-the-5-love-languages-definition-and-examples</a>
                        <br/><br/><br/>
                        My language would be Quality Time and possibly Acts of Service.
                        <br/><br/>
                        I don't require or desire gifts, I don't require affirmation and I don't crave physical touch.
                        <br/><br/>
                        It's more important to me to spend time with those I love, to listen, to hear and understand them and to do whatever I can to support them.
                        <br/><br/>
                        
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple-light">Learn about the history of valentine's day</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        <strong>History</strong>
                        <br/>
                        The history of valentine's day is a little blurry. Both Christian and ancient Roman traditions have typically celebrated romance in February, but the reason is unclear. 
                        <br/><br/>
                        Of all the possible "real Valentine" candidates, all of them are seen to be heroic and romantic!
                        <br/><br/>
                        Is it:
                        <br/>
                        <ul className="list">
                            <li>The priest Valentine - performed marriages in secret when marriage was outlawed for young men by Emperor Claudius II. He was put to death.</li>
                            <li>The bishop Valentine - Saint Valentine of Terni - beheaded by Claudius II</li>
                            <li>The other Valentine - helped Christians escape harsh Roman prisons. May have been the source of the original Valentine card, sent from prison and signed "From your Valentine</li>
                        </ul>
                        <br/><br/>
                        <p>Maybe we'll never know but they all seem like worthy candidates!</p>
                        <br/><br/>
                        <p>The origin of Valentine's day, as with many festivals has its roots in Paganism. Whilst some believed it to be a a reminder of Valentine's death, other believe it was a move by the Church to Christianise the pagan "Lupercalia" festival.</p>
                        <br/><br/>
                        <p>Lupercalia is a fertility festival, dedicated to the god of Agriculture.</p>
                        <br/><br/>
                        <p>The festival was outlawed for being "un-Christian"</p>
                        <br/><br/>
                        <p>Pope Gelsius declared February 14th Valentine's Day in the 5th Century. </p>
                        <br/><br/>
                        <p>Whilst many think about human fertility when celebrating Valentine's day, in the Middle Ages it was known to be the season for birds to mate and this is consistent with Lupercalia being dedicated to the god of Agrciculture.</p>
                        <br/><br/><br/>
                        <strong>Cards and Gifts</strong><br/>
                        <p>Many people will have sent Valentine's cards over the years, the first written Valentines appeared in the 1400s. A lot of sources claim that the first ever Valentine was written by Charles d'Orleans from prison, this has been refuted by the Medieval Manuscripts Blog. It's interesting, check out the story here: <a href="https://blogs.bl.uk/digitisedmanuscripts/2021/02/charles-dorl%C3%A9ans.html" target="_blank" rel="noopener noreferrer">Charles d'Orleans, earliest known Valentine?</a></p>
                        <br/><br/>
                        <p>Around the world, people send cards and gifts. Card started to become printed in the 1900s. Valentine's Day is now the second largest card-sending holiday of the year (behind Christmas).</p>
                        <br/><br/>
                        <p>It hasn't all been smushy romance though, the Victorians took the opportunity to try and offend their "enemies"!!</p>
                        <br/>
                        <p><quote>"To My Valentine / 'Tis a lemon that I hand you and bid you now 'skidoo', because I love another - there is no chance for you"</quote></p>
                        <br/>
                        <p>Very romantic!!  Have a look at history.com, they have more about it here: <a className="linkUnderline"  href="https://www.history.com/news/victorian-valentines-day-cards-vinegar?l" target="_blank" rel="noopener noreferrer">https://www.history.com/news/victorian-valentines-day-cards-vinegar?l</a></p>
                        <br/><br/>
                        <strong>Cupid</strong>
                        <br/>
                        <p>Cupid is usually seen as a cherub, shooting arrows at unsuspecting lovers. Again, he has a very mixed up origin story:</p>
                        <br/>
                        <ul className="list">
                            <li>Greek mythology says he is Eros, the god of love</li>
                            <li>He is the son of Nyx and Erebus</li>
                            <li>He is the son of Aphrodite and Ares</li>
                            <li>He is the son of Iris and Zephyrus</li>
                            <li>He is the son of Aphrodite and Zeus</li>
                        </ul>
                        <br/><br/>
                        <p>That's quite some confusion!</p>
                        <br/><br/>
                        <p>To the Greek poets, he was handsome and immortal, playing with emotions using golden arrows for love and lead arrows for aversion.</p>
                        <br/><br/>
                        <p>It wasn't until later that he became a symbol of mischief!</p>
                        <br/><br/>
                        <p>There is more about Cupid in the History.com website here: <a className="linkUnderline"  href="https://www.history.com/news/who-is-cupid" target="_blank" rel="noopener noreferrer">https://www.history.com/news/who-is-cupid</a></p>
                        <br/><br/><br/>
                        <p>The source of all of the above is: <a className="linkUnderline" href="https://www.history.com/topics/valentines-day/history-of-valentines-day-2" target="_blank" rel="noopener noreferrer">https://www.history.com/topics/valentines-day/history-of-valentines-day-2</a></p>
                        <br/><br/><br/>
                        <p>Cupid and I do not have a good relationship, so here is my favourite Valentine meme!</p>
                        <br/>
                        <img className="imgThird" src={CupidMeme} alt="if you see Cupid, bitch-slap that little punk for me, will ya?" />
                        <br/><br/>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink-light">Draw or paint a picture of cupid, or make a model of him</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence">
                        I set about doing a drawing of cupid, it turned more into a sketch. I just couldn't get his face right so I erased it!! Eventually I managed to get a face that looked something like human, if not cherubic!
                        <br/><br/>
                        Here are both versions!
                        <br/><br/>
                        <img src={Cupid1} alt="Sketch drawing of Cupid with no face!" className="imgQuarter" />
                        <img src={Cupid2} alt="Sketch drawing of Cupid with a face!" className="imgQuarter" />
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple-light">Research the origins of the heart symbol and how hearts came to represent love</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>There are a few theories about the origin of the heart symbol for love:</p>
                        <br/>
                        <ul className="list">
                            <li>Used in decorative art by societies, incorporating ivy which is a symbol of fidelity.</li>
                            <li>Cyrene in Northern Africa put the heart-shaped seed pods of the giant fennel plant on the coins. Giant Fennel is thought to be a contraceptive, so has an association to sex</li>
                            <li>A depiction of human anatomy - curved shape of breasts, buttocks or genitalia</li>
                            <li>Represents the heart as the seat of the soul as believed by philosophers</li>
                        </ul>
                    </div>
                    <br/><br/>
                    <p>It wasn't until the 13th Century that there started to be a connecion between hearts and love. The first depiction was in a 1250's French manuscript.</p>
                    <br/><br/>
                    <p>At first, it was shown upside down, gradually moving to how we know it now in the 14th Century, when it was added to playing cards!</p>
                    <br/><br/><br/>
                    <p>The Catholic Church have staked their claim on the origin of the heart symbol as being that portrayed in the Vision of Jesus' Sacred Heart, but the shape was in frequent use before this came to be.</p>
                    <br/><br/>
                    Source: <a className="linkUnderline" href="https://www.artandobject.com/news/history-heart-shape" target="_blank" rel="noopener noreferrer">https://www.artandobject.com/news/history-heart-shape</a>
                    <br/>
                    Source: <a className="linkUnderline" href="https://www.thevintagenews.com/2017/03/10/the-origin-of-the-heart-shape-ideograph-as-a-symbol-of-love/?chrome=1" target="_blank" rel="noopener noreferrer">https://www.thevintagenews.com/2017/03/10/the-origin-of-the-heart-shape-ideograph-as-a-symbol-of-love</a>
                    <br/><br/>

                </div>
            </div>
        </div>
    )
}

export default LoveFeb23