import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Freezer from '../images/freezer.jpg';

const Adulting = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Adulting"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Defrost the freezer</div>
                    <div className="badgePageItemEvidence">
                        My freezer doesn't frost up like this fortunately. However, it probably needs organising and cleaning, so that's on the plan!
                        <br/><br/>
                        On 1st September I emptied it out, cleaned everything, threw away anything beyond saving, re-ordered everything whilst putting away the month's shopping.
                        <br/><br/>
                        <img src={Freezer} alt="Inside of my freezer" />
                        <br/><br/>
                        <table>
                            <thead>
                                <tr>
                                    <td>Date</td>
                                    <td>Complete</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>01/09/2022</td>
                                    <td>Yes</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
            <div className="badgePageItem">
               <div className="badgePageItemTitle green">Clause 2</div>
               <div className="badgePageItemContent">         
                    <div className="badgePageItemInfo green-light">Spring clean</div>
                    <div className="badgePageItemEvidence">
                        Now this one I am going to have to back date. It was only a few months ago I had a huge spring clean and went through everything. In the end I released 7 bags to charity! I really don't have anything else to sort now, so this will have to be a retrospective.
                    </div>
                </div>
            </div>
            
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Make the bed</div>
                    <div className="badgePageItemEvidence">
                        I make my bed every day, but wouldn't usually change sheets this often - let alone iron them!! I am definitely not an ironning fan. OK, challenge accepted.
                        <br/><br/>
                        <strong>Changing the sheets</strong>
                        <table>
                            <thead>
                                <tr>
                                    <td>Date</td>
                                    <td>Complete</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>28/8/2022</td>
                                    <td>Yes</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 4</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Washing up</div>
                    <div className="badgePageItemEvidence">
                        Hmmm. This one doesn't sit too well with me. I don't usually wash up every night because it helps to save water - cross-reference the Environmentalist badge which asks you to consider your water usage. I'll do it though, for a week, I'll just minimise the amount of water used!
                        <br/><br/>
                        <table>
                            <thead>
                                <tr>
                                    <td>Date</td>
                                    <td>Complete</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>23/08/2022</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>24/08/2022</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>25/08/2022</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>26/08/2022</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>27/08/2022</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>28/08/2022</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>29/08/2022</td>
                                    <td>Yes</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 5</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Laundry</div>
                    <div className="badgePageItemEvidence">
                        I generally do one wash per week, sometimes less. It's always put away as soon as it is dry but
                         I iron as I go.<br/><br/>
                         <strong>Weekly Wash</strong>
                         <table>
                            <thead>
                                <tr>
                                    <td>Date</td>
                                    <td>Complete</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>21/8/2022</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>28/8/2022</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>4/9/2022</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>11/9/2022</td>
                                    <td>Yes</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 6</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Tidy</div>
                    <div className="badgePageItemEvidence">
                        This is difficult to evidence but easy to complete! I keep areas clear in the house most of the time because I hate clutter! My brain works better in a clutter free space. <br/><br/>
                        Sometimes I fall behind, but it's not long before I sort it, because piles cannot be left!!<br/><br/>
                        Everything is clear. No little piles.
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Adulting