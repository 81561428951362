import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import Rebel from "../images/rebel-logo.png";

const Book2 = () => {

useEffect(() => {
        const elements = document.querySelectorAll(".badgeItem");
        var currDate = new Date();

        elements.forEach((el) => {
            var oVal = 0;
            var oProg;
            var mProg;
            var count =  el.querySelectorAll(".badgeClauseStatus").length;
            var maxval = 100 / count;

            el.querySelectorAll(".badgeClauseStatus").forEach((elS) => {
                var cID = elS.id;
                var cStatus = elS.textContent;
                var cStart = el.querySelector("#" + cID.replace("Status","Start")).textContent;
                var cEnd = el.querySelector("#" + cID.replace("Status","End")).textContent;
                var cProg = el.querySelector("#" + cID.replace("Status","Progress"));
                oProg = el.querySelector("#" + cID.substring(0, cID.length-7).replace("1","") + "Progress");
                mProg = el.querySelector("#" + cID.substring(0, cID.length-7).replace("1","") + "ProgressMini");
                
                var duration = cProg.getAttribute("data-duration");

                if(duration != null)
                {
                    duration = parseInt(duration) * 31;
                }
                
                if (cStatus === "In progress")
                {
                    if (cEnd === "" && cStart !== "")
                    {
                        var startString = cStart.split("/");
                        var startDate = new Date(startString[2],startString[1]-1,startString[0]);
                        var diff = new Date(startDate - currDate);
                        var days =  Math.ceil(diff/1000/60/60/24);
                        var maxdays = -90;

                        if(duration != null) 
                        {
                            maxdays = -duration;
                        }
                        
                        cProg.value = (Math.ceil(days/maxdays*100));

                        var setP = days * maxdays / 100;

                        if(setP > maxval)
                        {
                            setP = maxval / 2;
                        }
                        oVal = oVal + setP;

                        cProg.classList.add("orange");
                        cProg.classList.remove("green");
                        cProg.classList.remove("red");
                    }
                    else if (cEnd !== "" && cStart !== "")
                    {
                        cProg.value = 100;
                        cProg.classList.add("green");
                        cProg.classList.remove("orange");
                        cProg.classList.remove("red");
                        oVal = oVal + maxval;
                    }
                }
                else if (cStatus === "Complete")
                {
                    cProg.value = 100;
                    cProg.classList.add("green");
                    cProg.classList.remove("orange");
                    cProg.classList.remove("red");
                    oVal = oVal + maxval;
                }
                else if (cStatus === "Not started")
                {
                    cProg.value = 1;
                    cProg.classList.remove("green");
                    cProg.classList.remove("orange");
                    cProg.classList.add("red");
                    oVal = oVal + 0;
                }
            })

            var oCalc = Math.ceil(oVal);
            oProg.value = oCalc;
            mProg.value = oCalc;

            
            if(oCalc <= 1)
            {
                oProg.classList.add("red");
                oProg.classList.remove("orange");
                oProg.classList.remove("green");
                mProg.classList.add("red");
                mProg.classList.remove("orange");
                mProg.classList.remove("green");
            }
            else if (oCalc < 100)
            {
                oProg.classList.remove("red");
                oProg.classList.add("orange");
                oProg.classList.remove("green");
                mProg.classList.remove("red");
                mProg.classList.add("orange");
                mProg.classList.remove("green");
            }
            else
            {
                oProg.classList.remove("red");
                oProg.classList.remove("orange");
                oProg.classList.add("green");
                mProg.classList.remove("red");
                mProg.classList.remove("orange");
                mProg.classList.add("green");
            }    
        })


        document.querySelectorAll(".badgeItemIntro").forEach((elI) => {elI.classList.add("hidden")})
        document.querySelectorAll(".badgeClause").forEach((elI) => {elI.classList.add("hidden")})
        document.querySelectorAll(".badgeProgress").forEach((elI) => {elI.classList.add("hidden")})
        document.querySelectorAll(".icon-down-open").forEach((elI) => {elI.classList.add("shown")})
        document.querySelectorAll(".icon-up-open").forEach((elI) => {elI.classList.add("hidden")})

        document.querySelectorAll(".badgeItemTitle").forEach((mit) => {addListener(mit);})

    }, [])

    return (
        <div>
            <div className="actionBar">
                <div className="actionBarItem"><a href="./">Back to Dashboard</a></div>
            </div>
            <div className="row bookPageTop">
                <div className="rebelLeft"><img src={Rebel} alt="Sticker of the word rebel" className="rebelLogoBookLeft" /></div>
                <div className="rebelMid">
                    <h2>Rebel Badge Book Two</h2>
                </div>
                <div className="rebelRight"><img src={Rebel} alt="Sticker of the word rebel" className="rebelLogoBookRight" /></div>
            </div>
            <div className="row pageIntroduction">
                <p><br/>This page follows my progress in completing the badges in the second Rebel Badge Book. It doesn't detail the entire contents of the book, just the badges and options that I have chosen from those offered.</p>
                <p><br/>I have intentionally abbreviated or removed the clauses to protect the content of the book - if you want to see the full details, go and buy the <a className="linkUnderline"  href="https://www.rebelbadgestore.com/" target="_blank" rel="noopener noreferrer">Rebel Badge Book</a> &#x1F609;</p>
            </div>
           
            <div className="bookSectionTitle bstpurple">Creative Rebel</div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle purple" tabIndex={0} role="button" aria-expanded="false">
                        <div>Advanced Crafter</div>
                        <div>
                            <progress id="crafterProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro purple-light">3 different projects<br/><Link to="/advancedcrafter">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 1</div>
                        <div className="badgeClauseStatus" id="crafter1Status">Complete</div>
                        <div className="badgeClauseStart" id="crafter1Start">23/08/2022</div>
                        <div className="badgeClauseEnd" id="crafter1End">3/3/2023</div>
                        <div className="badgeClauseProgress"><progress id="crafter1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 2</div>
                        <div className="badgeClauseStatus" id="crafter2Status">Complete</div>
                        <div className="badgeClauseStart" id="crafter2Start">29/7/2023</div>
                        <div className="badgeClauseEnd" id="crafter2End">31/7/2023</div>
                        <div className="badgeClauseProgress"><progress id="crafter2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Project 3</div>
                        <div className="badgeClauseStatus" id="crafter3Status">Complete</div>
                        <div className="badgeClauseStart" id="crafter3Start">1/7/2023</div>
                        <div className="badgeClauseEnd" id="crafter3End">31/7/2023</div>
                        <div className="badgeClauseProgress"><progress id="crafter3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="crafterProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle purple" tabIndex={0} role="button" aria-expanded="false">
                        <div>Advanced Writer</div>
                        <div>
                            <progress id="writerProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro purple-light">Extend book 1 writing project<br/><Link to="/advancedwriter">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Task 1</div>
                        <div className="badgeClauseStatus" id="writer1Status">Complete</div>
                        <div className="badgeClauseStart" id="writer1Start">22/10/2022</div>
                        <div className="badgeClauseEnd" id="writer1End">31/12/2023</div>
                        <div className="badgeClauseProgress"><progress id="writer1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Task 2</div>
                        <div className="badgeClauseStatus" id="writer2Status">Complete</div>
                        <div className="badgeClauseStart" id="writer2Start">01/10/2023</div>
                        <div className="badgeClauseEnd" id="writer2End">31/12/2023</div>
                        <div className="badgeClauseProgress"><progress id="writer2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Task 3</div>
                        <div className="badgeClauseStatus" id="writer3Status">Complete</div>
                        <div className="badgeClauseStart" id="writer3Start">1/11/2023</div>
                        <div className="badgeClauseEnd" id="writer3End">17/11/2023</div>
                        <div className="badgeClauseProgress"><progress id="writer3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Task 4</div>
                        <div className="badgeClauseStatus" id="writer4Status">Complete</div>
                        <div className="badgeClauseStart" id="writer4Start">6/2/2023</div>
                        <div className="badgeClauseEnd" id="writer4End">31/12/2023</div>
                        <div className="badgeClauseProgress"><progress id="writer4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="writerProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle purple" tabIndex={0} role="button" aria-expanded="false">
                        <div>Builder</div>
                        <div>
                            <progress id="builderProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro purple-light">Complete 4 of 8 possible projects<br/><Link to="/builder">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Project 1</div>
                        <div className="badgeClauseStatus" id="builder1Status">Not started</div>
                        <div className="badgeClauseStart" id="builder1Start"></div>
                        <div className="badgeClauseEnd" id="builder1End"></div>
                        <div className="badgeClauseProgress"><progress id="builder1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Project 2</div>
                        <div className="badgeClauseStatus" id="builder2Status">Not started</div>
                        <div className="badgeClauseStart" id="builder2Start"></div>
                        <div className="badgeClauseEnd" id="builder2End"></div>
                        <div className="badgeClauseProgress"><progress id="builder2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Project 3</div>
                        <div className="badgeClauseStatus" id="builder3Status">Not started</div>
                        <div className="badgeClauseStart" id="builder3Start"></div>
                        <div className="badgeClauseEnd" id="builder3End"></div>
                        <div className="badgeClauseProgress"><progress id="builder3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Project 4</div>
                        <div className="badgeClauseStatus" id="builder4Status">Not started</div>
                        <div className="badgeClauseStart" id="builder4Start"></div>
                        <div className="badgeClauseEnd" id="builder4End"></div>
                        <div className="badgeClauseProgress"><progress id="builder4Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="builderProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle purple" tabIndex={0} role="button" aria-expanded="false">
                        <div>Craft Part 2</div>
                        <div>
                            <progress id="craft2ProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro purple-light">Try 5 of 20 crafts<br/><Link to="/craftpart2">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Diamond Painting</div>
                        <div className="badgeClauseStatus" id="craft21Status">Complete</div>
                        <div className="badgeClauseStart" id="craft21Start">4/3/2023</div>
                        <div className="badgeClauseEnd" id="craft21End">9/3/2023</div>
                        <div className="badgeClauseProgress"><progress id="craft21Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                     <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Latch Hook</div>
                        <div className="badgeClauseStatus" id="craft22Status">Complete</div>
                        <div className="badgeClauseStart" id="craft22Start">9/3/2023</div>
                        <div className="badgeClauseEnd" id="craft22End">13/03/2023</div>
                        <div className="badgeClauseProgress"><progress id="craft22Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                     <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Embroidery</div>
                        <div className="badgeClauseStatus" id="craft23Status">Complete</div>
                        <div className="badgeClauseStart" id="craft23Start">20/03/2023</div>
                        <div className="badgeClauseEnd" id="craft23End">25/03/2023</div>
                        <div className="badgeClauseProgress"><progress id="craft23Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                     <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Sculpture</div>
                        <div className="badgeClauseStatus" id="craft24Status">Complete</div>
                        <div className="badgeClauseStart" id="craft24Start">22/4/2023</div>
                        <div className="badgeClauseEnd" id="craft24End">14/4/2023</div>
                        <div className="badgeClauseProgress"><progress id="craft24Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                     <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Lace Making</div>
                        <div className="badgeClauseStatus" id="craft25Status">Complete</div>
                        <div className="badgeClauseStart" id="craft25Start">1/5/2023</div>
                        <div className="badgeClauseEnd" id="craft25End">24/05/2023</div>
                        <div className="badgeClauseProgress"><progress id="craft25Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="craft2Progress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle purple" tabIndex={0} role="button" aria-expanded="false">
                        <div>Music Lover</div>
                        <div>
                            <progress id="musicProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro purple-light">Complete 5 clauses <Link to="/musiclover">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Task 1</div>
                        <div className="badgeClauseStatus" id="music1Status">Complete</div>
                        <div className="badgeClauseStart" id="music1Start">01/01/2024</div>
                        <div className="badgeClauseEnd" id="music1End">31/03/2024</div>
                        <div className="badgeClauseProgress"><progress id="music1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Task 2</div>
                        <div className="badgeClauseStatus" id="music2Status">Complete</div>
                        <div className="badgeClauseStart" id="music2Start">01/01/2024</div>
                        <div className="badgeClauseEnd" id="music2End">31/03/2024</div>
                        <div className="badgeClauseProgress"><progress id="music2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Task 3</div>
                        <div className="badgeClauseStatus" id="music3Status">Complete</div>
                        <div className="badgeClauseStart" id="music3Start">01/01/2024</div>
                        <div className="badgeClauseEnd" id="music3End">31/03/2024</div>
                        <div className="badgeClauseProgress"><progress id="music3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Task 4</div>
                        <div className="badgeClauseStatus" id="music4Status">Complete</div>
                        <div className="badgeClauseStart" id="music4Start">01/01/2024</div>
                        <div className="badgeClauseEnd" id="music4End">31/03/2024</div>
                        <div className="badgeClauseProgress"><progress id="music4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Task 5</div>
                        <div className="badgeClauseStatus" id="music5Status">Complete</div>
                        <div className="badgeClauseStart" id="music5Start">01/01/2024</div>
                        <div className="badgeClauseEnd" id="music5End">31/03/2024</div>
                        <div className="badgeClauseProgress"><progress id="music5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="musicProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle purple" tabIndex={0} role="button" aria-expanded="false">
                        <div>TV Critic</div>
                        <div>
                            <progress id="tvcriticProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro purple-light">Complete 5 clauses <Link to="/tvcritic">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Task 1</div>
                        <div className="badgeClauseStatus" id="tvcritic1Status">Complete</div>
                        <div className="badgeClauseStart" id="tvcritic1Start">01/04/2024</div>
                        <div className="badgeClauseEnd" id="tvcritic1End">28/04/2024</div>
                        <div className="badgeClauseProgress"><progress id="tvcritic1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Task 2</div>
                        <div className="badgeClauseStatus" id="tvcritic2Status">Complete</div>
                        <div className="badgeClauseStart" id="tvcritic2Start">01/04/2024</div>
                        <div className="badgeClauseEnd" id="tvcritic2End">28/04/2024</div>
                        <div className="badgeClauseProgress"><progress id="tvcritic2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Task 3</div>
                        <div className="badgeClauseStatus" id="tvcritic3Status">Complete</div>
                        <div className="badgeClauseStart" id="tvcritic3Start">01/04/2024</div>
                        <div className="badgeClauseEnd" id="tvcritic3End">28/04/2024</div>
                        <div className="badgeClauseProgress"><progress id="tvcritic3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Task 4</div>
                        <div className="badgeClauseStatus" id="tvcritic4Status">Complete</div>
                        <div className="badgeClauseStart" id="tvcritic4Start">01/04/2024</div>
                        <div className="badgeClauseEnd" id="tvcritic4End">28/04/2024</div>
                        <div className="badgeClauseProgress"><progress id="tvcritic4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Task 5</div>
                        <div className="badgeClauseStatus" id="tvcritic5Status">Complete</div>
                        <div className="badgeClauseStart" id="tvcritic5Start">01/04/2024</div>
                        <div className="badgeClauseEnd" id="tvcritic5End">28/04/2024</div>
                        <div className="badgeClauseProgress"><progress id="tvcritic5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="tvcriticProgress" value="25" max="100"></progress></div>
                </div>
            </div>

            <div className="bookSectionTitle bstblue">Global Rebel</div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle blue" tabIndex={0} role="button" aria-expanded="false">
                        <div>Advanced Community Service</div>
                        <div>
                            <progress id="communityProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro blue-light">Extended volunteering <Link to="/advancedcommunity">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Continuous volunteering</div>
                        <div className="badgeClauseStatus" id="community1Status">In progress</div>
                        <div className="badgeClauseStart" id="community1Start">1/1/2022</div>
                        <div className="badgeClauseEnd" id="community1End"></div>
                        <div className="badgeClauseProgress"><progress id="community1Progress" data-duration="18" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Opportunity 1</div>
                        <div className="badgeClauseStatus" id="community2Status">Not started</div>
                        <div className="badgeClauseStart" id="community2Start"></div>
                        <div className="badgeClauseEnd" id="community2End"></div>
                        <div className="badgeClauseProgress"><progress id="community2Progress" data-duration="18"  value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Opportunity 2</div>
                        <div className="badgeClauseStatus" id="community3Status">Not started</div>
                        <div className="badgeClauseStart" id="community3Start"></div>
                        <div className="badgeClauseEnd" id="community3End"></div>
                        <div className="badgeClauseProgress"><progress id="community3Progress" data-duration="18"  value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Opportunity 3</div>
                        <div className="badgeClauseStatus" id="community4Status">Not started</div>
                        <div className="badgeClauseStart" id="community4Start"></div>
                        <div className="badgeClauseEnd" id="community4End"></div>
                        <div className="badgeClauseProgress"><progress id="community4Progress" data-duration="18" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="communityProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle blue" tabIndex={0} role="button" aria-expanded="false">
                        <div>Global Citizen</div>
                        <div>
                            <progress id="globalProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro blue-light">Complete all six clauses<br/><Link to="/globalcitizen">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 1</div>
                        <div className="badgeClauseStatus" id="global1Status">Complete</div>
                        <div className="badgeClauseStart" id="global1Start">01/02/2023</div>
                        <div className="badgeClauseEnd" id="global1End">06/05/2024</div>
                        <div className="badgeClauseProgress"><progress id="global1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 2</div>
                        <div className="badgeClauseStatus" id="global2Status">Complete</div>
                        <div className="badgeClauseStart" id="global2Start">01/02/2023</div>
                        <div className="badgeClauseEnd" id="global2End">31/7/2024</div>
                        <div className="badgeClauseProgress"><progress id="global2Progress" value="25" max="100"></progress></div>
                    </div><div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 3</div>
                        <div className="badgeClauseStatus" id="global3Status">Complete</div>
                        <div className="badgeClauseStart" id="global3Start">01/02/2023</div>
                        <div className="badgeClauseEnd" id="global3End">06/05/2024</div>
                        <div className="badgeClauseProgress"><progress id="global3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 4</div>
                        <div className="badgeClauseStatus" id="global4Status">Complete</div>
                        <div className="badgeClauseStart" id="global4Start">01/02/2023</div>
                        <div className="badgeClauseEnd" id="global4End">05/05/2024</div>
                        <div className="badgeClauseProgress"><progress id="global4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 5</div>
                        <div className="badgeClauseStatus" id="global5Status">Complete</div>
                        <div className="badgeClauseStart" id="global5Start">01/02/2023</div>
                        <div className="badgeClauseEnd" id="global5End">06/05/2024</div>
                        <div className="badgeClauseProgress"><progress id="global5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Clause 6</div>
                        <div className="badgeClauseStatus" id="global6Status">Complete</div>
                        <div className="badgeClauseStart" id="global6Start">01/02/2023</div>
                        <div className="badgeClauseEnd" id="global6End">31/7/2024</div>
                        <div className="badgeClauseProgress"><progress id="global6Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="globalProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle blue" tabIndex={0} role="button" aria-expanded="false">
                        <div>Historian</div>
                        <div>
                            <progress id="historianProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro blue-light">Complete 6 clauses <br/><Link to="/rebelhistorian">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="6">Clause 1</div>
                        <div className="badgeClauseStatus" id="historian1Status">Complete</div>
                        <div className="badgeClauseStart" id="historian1Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="historian1End">27/02/2024</div>
                        <div className="badgeClauseProgress"><progress id="historian1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="6">Clause 2</div>
                        <div className="badgeClauseStatus" id="historian2Status">Complete</div>
                        <div className="badgeClauseStart" id="historian2Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="historian2End">27/02/2024</div>
                        <div className="badgeClauseProgress"><progress id="historian2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="6">Clause 3</div>
                        <div className="badgeClauseStatus" id="historian3Status">Complete</div>
                        <div className="badgeClauseStart" id="historian3Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="historian3End">27/02/2024</div>
                        <div className="badgeClauseProgress"><progress id="historian3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="6">Clause 4</div>
                        <div className="badgeClauseStatus" id="historian4Status">Complete</div>
                        <div className="badgeClauseStart" id="historian4Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="historian4End">27/02/2024</div>
                        <div className="badgeClauseProgress"><progress id="historian4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="6">Clause 5</div>
                        <div className="badgeClauseStatus" id="historian5Status">Complete</div>
                        <div className="badgeClauseStart" id="historian5Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="historian5End">27/02/2024</div>
                        <div className="badgeClauseProgress"><progress id="historian5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="6">Clause 6</div>
                        <div className="badgeClauseStatus" id="historian6Status">Complete</div>
                        <div className="badgeClauseStart" id="historian6Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="historian6End">06/05/2024</div>
                        <div className="badgeClauseProgress"><progress id="historian6Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="historianProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle blue" tabIndex={0} role="button" aria-expanded="false">
                        <div>Journalist</div>
                        <div>
                            <progress id="journalProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro blue-light">Complete 1 of 6 possible tasks over 6 months <br/><Link to="/journalist">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Task 1</div>
                        <div className="badgeClauseStatus" id="journal1Status">In progress</div>
                        <div className="badgeClauseStart" id="journal1Start">7/2/2023</div>
                        <div className="badgeClauseEnd" id="journal1End"></div>
                        <div className="badgeClauseProgress"><progress id="journal1Progress" data-duration="6" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="journalProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle blue" tabIndex={0} role="button" aria-expanded="false">
                        <div>Politician</div>
                        <div>
                            <progress id="politicianProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro blue-light">Complete 5 clauses <br/><Link to="/politician">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="6">Task 1</div>
                        <div className="badgeClauseStatus" id="politician1Status">Complete</div>
                        <div className="badgeClauseStart" id="politician1Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="politician1End">27/02/2024</div>
                        <div className="badgeClauseProgress"><progress id="politician1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Task 2</div>
                        <div className="badgeClauseStatus" id="politician2Status">Complete</div>
                        <div className="badgeClauseStart" id="politician2Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="politician2End">27/02/2024</div>
                        <div className="badgeClauseProgress"><progress id="politician2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Task 3</div>
                        <div className="badgeClauseStatus" id="politician3Status">Complete</div>
                        <div className="badgeClauseStart" id="politician3Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="politician3End">1/4/24</div>
                        <div className="badgeClauseProgress"><progress id="politician3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Task 4</div>
                        <div className="badgeClauseStatus" id="politician4Status">Complete</div>
                        <div className="badgeClauseStart" id="politician4Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="politician4End">27/02/2024</div>
                        <div className="badgeClauseProgress"><progress id="politician4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Task 5</div>
                        <div className="badgeClauseStatus" id="politician5Status">Complete</div>
                        <div className="badgeClauseStart" id="politician5Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="politician5End">1/4/24</div>
                        <div className="badgeClauseProgress"><progress id="politician5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="politicianProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle blue" tabIndex={0} role="button" aria-expanded="false">
                        <div>Rebel History</div>
                        <div>
                            <progress id="rebelProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro blue-light">Complete 6 clauses <br/><Link to="/rebelhistory">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="6">Clause 1</div>
                        <div className="badgeClauseStatus" id="rebel1Status">Complete</div>
                        <div className="badgeClauseStart" id="rebel1Start">04/11/2023</div>
                        <div className="badgeClauseEnd" id="rebel1End">04/11/2023</div>
                        <div className="badgeClauseProgress"><progress id="rebel1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="6">Clause 2</div>
                        <div className="badgeClauseStatus" id="rebel2Status">Complete</div>
                        <div className="badgeClauseStart" id="rebel2Start">10/11/2023</div>
                        <div className="badgeClauseEnd" id="rebel2End">10/11/2023</div>
                        <div className="badgeClauseProgress"><progress id="rebel2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="6">Clause 3</div>
                        <div className="badgeClauseStatus" id="rebel3Status">Complete</div>
                        <div className="badgeClauseStart" id="rebel3Start">4/11/2023</div>
                        <div className="badgeClauseEnd" id="rebel3End">4/11/2023</div>
                        <div className="badgeClauseProgress"><progress id="rebel3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="6">Clause 4</div>
                        <div className="badgeClauseStatus" id="rebel4Status">Complete</div>
                        <div className="badgeClauseStart" id="rebel4Start">4/11/2023</div>
                        <div className="badgeClauseEnd" id="rebel4End">4/11/2023</div>
                        <div className="badgeClauseProgress"><progress id="rebel4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="6">Clause 5</div>
                        <div className="badgeClauseStatus" id="rebel5Status">Complete</div>
                        <div className="badgeClauseStart" id="rebel5Start">08/11/2023</div>
                        <div className="badgeClauseEnd" id="rebel5End">08/11/2023</div>
                        <div className="badgeClauseProgress"><progress id="rebel5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="6">Clause 6</div>
                        <div className="badgeClauseStatus" id="rebel6Status">Complete</div>
                        <div className="badgeClauseStart" id="rebel6Start">09/11/2023</div>
                        <div className="badgeClauseEnd" id="rebel6End">09/11/2023</div>
                        <div className="badgeClauseProgress"><progress id="rebel6Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="rebelProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle blue" tabIndex={0} role="button" aria-expanded="false">
                        <div>Rebel Buddy</div>
                        <div>
                            <progress id="rebelbuddyProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro blue-light">Complete 1 of 2 clauses <br/><Link to="/rebelbuddy">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="6">Clause 1</div>
                        <div className="badgeClauseStatus" id="rebelbuddy1Status">In progress</div>
                        <div className="badgeClauseStart" id="rebelbuddy1Start">01/04/2024</div>
                        <div className="badgeClauseEnd" id="rebelbuddy1End"></div>
                        <div className="badgeClauseProgress"><progress id="rebelbuddy1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="rebelbuddyProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            
            <div className="bookSectionTitle bstolive">Grown Up Rebel</div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle olive" tabIndex={0} role="button" aria-expanded="false">
                        <div>Advanced Adulting</div>
                        <div>
                            <progress id="adultProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro olive-light">Complete Chores! <br/><Link to="/advancedadulting">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask">Chore 1</div>
                        <div className="badgeClauseStatus" id="adult1Status">Complete</div>
                        <div className="badgeClauseStart" id="adult1Start">01/06/2023</div>
                        <div className="badgeClauseEnd" id="adult1End">30/11/2023</div>
                        <div className="badgeClauseProgress"><progress id="adult1Progress" data-duration="6" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="6">Chore 2</div>
                        <div className="badgeClauseStatus" id="adult2Status">Complete</div>
                        <div className="badgeClauseStart" id="adult2Start">01/06/2023</div>
                        <div className="badgeClauseEnd" id="adult2End">30/11/2023</div>
                        <div className="badgeClauseProgress" data-duration="6"><progress id="adult2Progress" data-duration="6" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="6">Chore 3</div>
                        <div className="badgeClauseStatus" id="adult3Status">Complete</div>
                        <div className="badgeClauseStart" id="adult3Start">01/06/2023</div>
                        <div className="badgeClauseEnd" id="adult3End">30/11/2023</div>
                        <div className="badgeClauseProgress" data-duration="6"><progress id="adult3Progress" data-duration="6" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="6">Chore 4</div>
                        <div className="badgeClauseStatus" id="adult4Status">Complete</div>
                        <div className="badgeClauseStart" id="adult4Start">01/06/2023</div>
                        <div className="badgeClauseEnd" id="adult4End">30/11/2023</div>
                        <div className="badgeClauseProgress" data-duration="6"><progress id="adult4Progress" data-duration="6" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="6">Chore 5</div>
                        <div className="badgeClauseStatus" id="adult5Status">Complete</div>
                        <div className="badgeClauseStart" id="adult5Start">01/06/2023</div>
                        <div className="badgeClauseEnd" id="adult5End">30/11/2023</div>
                        <div className="badgeClauseProgress" data-duration="6"><progress id="adult5Progress" data-duration="6" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="6">Chore 6</div>
                        <div className="badgeClauseStatus" id="adult6Status">Complete</div>
                        <div className="badgeClauseStart" id="adult6Start">01/06/2023</div>
                        <div className="badgeClauseEnd" id="adult6End">30/11/2023</div>
                        <div className="badgeClauseProgress" data-duration="6"><progress id="adult6Progress" data-duration="6" value="25" max="100"></progress></div>
                    </div>
                    
                    <div className="badgeProgress"><progress id="adultProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle olive" tabIndex={0} role="button" aria-expanded="false">
                        <div>Coder</div>
                        <div>
                            <progress id="coderProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro olive-light">Learn a new programming language <br/><Link to="/coder">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Part 1</div>
                        <div className="badgeClauseStatus" id="coder1Status">Complete</div>
                        <div className="badgeClauseStart" id="coder1Start">1/9/2023</div>
                        <div className="badgeClauseEnd" id="coder1End">30/11/2023</div>
                        <div className="badgeClauseProgress"><progress id="coder1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Part 2</div>
                        <div className="badgeClauseStatus" id="coder2Status">Complete</div>
                        <div className="badgeClauseStart" id="coder2Start">1/11/2023</div>
                        <div className="badgeClauseEnd" id="coder2End">30/11/2023</div>
                        <div className="badgeClauseProgress"><progress id="coder2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Part 3</div>
                        <div className="badgeClauseStatus" id="coder3Status">Complete</div>
                        <div className="badgeClauseStart" id="coder3Start">1/11/2023</div>
                        <div className="badgeClauseEnd" id="coder3End">30/11/2023</div>
                        <div className="badgeClauseProgress"><progress id="coder3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="coderProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle olive" tabIndex={0} role="button" aria-expanded="false">
                        <div>Confectioner</div>
                        <div>
                            <progress id="confProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro olive-light">Make at least 6 of 9 desserts <br/><Link to="/confectioner">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Dessert 1</div>
                        <div className="badgeClauseStatus" id="conf1Status">Complete</div>
                        <div className="badgeClauseStart" id="conf1Start">9/2/2023</div>
                        <div className="badgeClauseEnd" id="conf1End">9/2/2023</div>
                        <div className="badgeClauseProgress"><progress id="conf1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Dessert 2</div>
                        <div className="badgeClauseStatus" id="conf2Status">Complete</div>
                        <div className="badgeClauseStart" id="conf2Start">2/7/2023</div>
                        <div className="badgeClauseEnd" id="conf2End">2/7/2023</div>
                        <div className="badgeClauseProgress"><progress id="conf2Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Dessert 3</div>
                        <div className="badgeClauseStatus" id="conf3Status">Complete</div>
                        <div className="badgeClauseStart" id="conf3Start">24/12/2023</div>
                        <div className="badgeClauseEnd" id="conf3End">24/12/2023</div>
                        <div className="badgeClauseProgress"><progress id="conf3Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Dessert 4</div>
                        <div className="badgeClauseStatus" id="conf4Status">Complete</div>
                        <div className="badgeClauseStart" id="conf4Start">24/12/2023</div>
                        <div className="badgeClauseEnd" id="conf4End">24/12/2023</div>
                        <div className="badgeClauseProgress"><progress id="conf4Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Dessert 5</div>
                        <div className="badgeClauseStatus" id="conf5Status">Complete</div>
                        <div className="badgeClauseStart" id="conf5Start">24/12/2023</div>
                        <div className="badgeClauseEnd" id="conf5End">24/12/2023</div>
                        <div className="badgeClauseProgress"><progress id="conf5Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Dessert 6</div>
                        <div className="badgeClauseStatus" id="conf6Status">Complete</div>
                        <div className="badgeClauseStart" id="conf6Start">24/12/2023</div>
                        <div className="badgeClauseEnd" id="conf6End">24/12/2023</div>
                        <div className="badgeClauseProgress"><progress id="conf6Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="confProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle olive" tabIndex={0} role="button" aria-expanded="false">
                        <div>Detective</div>
                        <div>
                            <progress id="detectProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro olive-light">Complete 6 clauses <br/><Link to="/detective">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 1</div>
                        <div className="badgeClauseStatus" id="detect1Status">Complete</div>
                        <div className="badgeClauseStart" id="detect1Start">01/02/2023</div>
                        <div className="badgeClauseEnd" id="detect1End">29/02/2024</div>
                        <div className="badgeClauseProgress"><progress id="detect1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 2</div>
                        <div className="badgeClauseStatus" id="detect2Status">Complete</div>
                        <div className="badgeClauseStart" id="detect2Start">01/02/2023</div>
                        <div className="badgeClauseEnd" id="detect2End">29/02/2024</div>
                        <div className="badgeClauseProgress"><progress id="detect2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 3</div>
                        <div className="badgeClauseStatus" id="detect3Status">Complete</div>
                        <div className="badgeClauseStart" id="detect3Start">01/02/2023</div>
                        <div className="badgeClauseEnd" id="detect3End">29/02/2024</div>
                        <div className="badgeClauseProgress"><progress id="detect3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 4</div>
                        <div className="badgeClauseStatus" id="detect4Status">Complete</div>
                        <div className="badgeClauseStart" id="detect4Start">01/02/2023</div>
                        <div className="badgeClauseEnd" id="detect4End">29/02/2024</div>
                        <div className="badgeClauseProgress"><progress id="detect4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 5</div>
                        <div className="badgeClauseStatus" id="detect5Status">Complete</div>
                        <div className="badgeClauseStart" id="detect5Start">01/02/2023</div>
                        <div className="badgeClauseEnd" id="detect5End">29/02/2024</div>
                        <div className="badgeClauseProgress"><progress id="detect5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 6</div>
                        <div className="badgeClauseStatus" id="detect6Status">Complete</div>
                        <div className="badgeClauseStart" id="detect6Start">01/02/2023</div>
                        <div className="badgeClauseEnd" id="detect6End">29/02/2024</div>
                        <div className="badgeClauseProgress"><progress id="detect6Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="detectProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle olive" tabIndex={0} role="button" aria-expanded="false">
                        <div>Farmer</div>
                        <div>
                            <progress id="farmerProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro olive-light">Complete at least 5 clauses <br/><Link to="/farmer">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 1</div>
                        <div className="badgeClauseStatus" id="farmer1Status">Complete</div>
                        <div className="badgeClauseStart" id="farmer1Start">1/5/2024</div>
                        <div className="badgeClauseEnd" id="farmer1End">30/6/2024</div>
                        <div className="badgeClauseProgress"><progress id="farmer1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 2</div>
                        <div className="badgeClauseStatus" id="farmer2Status">Complete</div>
                        <div className="badgeClauseStart" id="farmer2Start">1/6/2024</div>
                        <div className="badgeClauseEnd" id="farmer2End">30/6/2024</div>
                        <div className="badgeClauseProgress"><progress id="farmer2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 3</div>
                        <div className="badgeClauseStatus" id="farmer3Status">Complete</div>
                        <div className="badgeClauseStart" id="farmer3Start">15/6/2024</div>
                        <div className="badgeClauseEnd" id="farmer3End">15/6/2024</div>
                        <div className="badgeClauseProgress"><progress id="farmer3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 4</div>
                        <div className="badgeClauseStatus" id="farmer4Status">Complete</div>
                        <div className="badgeClauseStart" id="farmer4Start">1/6/2024</div>
                        <div className="badgeClauseEnd" id="farmer4End">30/6/2024</div>
                        <div className="badgeClauseProgress"><progress id="farmer4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 5</div>
                        <div className="badgeClauseStatus" id="farmer5Status">Complete</div>
                        <div className="badgeClauseStart" id="farmer5Start">25/8/2023</div>
                        <div className="badgeClauseEnd" id="farmer5End">15/6/2024</div>
                        <div className="badgeClauseProgress"><progress id="farmer5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="farmerProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle olive" tabIndex={0} role="button" aria-expanded="false">
                        <div>Mixologist</div>
                        <div>
                            <progress id="mixProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro olive-light">Learn to make at least 6 of 12 given cocktails <br/><Link to="/mixologist">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Cocktail 1</div>
                        <div className="badgeClauseStatus" id="mix1Status">Complete</div>
                        <div className="badgeClauseStart" id="mix1Start">2/7/2023</div>
                        <div className="badgeClauseEnd" id="mix1End">2/7/2023</div>
                        <div className="badgeClauseProgress"><progress id="mix1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Cocktail 2</div>
                        <div className="badgeClauseStatus" id="mix2Status">Complete</div>
                        <div className="badgeClauseStart" id="mix2Start">24/12/2023</div>
                        <div className="badgeClauseEnd" id="mix2End">24/12/2023</div>
                        <div className="badgeClauseProgress"><progress id="mix2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Cocktail 3</div>
                        <div className="badgeClauseStatus" id="mix3Status">Complete</div>
                        <div className="badgeClauseStart" id="mix3Start">24/12/2023</div>
                        <div className="badgeClauseEnd" id="mix3End">24/12/2023</div>
                        <div className="badgeClauseProgress"><progress id="mix3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Cocktail 4</div>
                        <div className="badgeClauseStatus" id="mix4Status">Complete</div>
                        <div className="badgeClauseStart" id="mix4Start">24/12/2023</div>
                        <div className="badgeClauseEnd" id="mix4End">24/12/2023</div>
                        <div className="badgeClauseProgress"><progress id="mix4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Cocktail 5</div>
                        <div className="badgeClauseStatus" id="mix5Status">Complete</div>
                        <div className="badgeClauseStart" id="mix5Start">24/12/2023</div>
                        <div className="badgeClauseEnd" id="mix5End">24/12/2023</div>
                        <div className="badgeClauseProgress"><progress id="mix5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Cocktail 6</div>
                        <div className="badgeClauseStatus" id="mix6Status">Complete</div>
                        <div className="badgeClauseStart" id="mix6Start">24/12/2023</div>
                        <div className="badgeClauseEnd" id="mix6End">24/12/2023</div>
                        <div className="badgeClauseProgress"><progress id="mix6Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="mixProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle olive" tabIndex={0} role="button" aria-expanded="false">
                        <div>Sommelier</div>
                        <div>
                            <progress id="sommProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro olive-light">Complete at least 6 of 9 sections <br/><Link to="/sommelier">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Task 1</div>
                        <div className="badgeClauseStatus" id="somm1Status">Complete</div>
                        <div className="badgeClauseStart" id="somm1Start">28/10/2023</div>
                        <div className="badgeClauseEnd" id="somm1End">28/10/2023</div>
                        <div className="badgeClauseProgress"><progress id="somm1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Task 2</div>
                        <div className="badgeClauseStatus" id="somm2Status">Complete</div>
                        <div className="badgeClauseStart" id="somm2Start">28/10/2023</div>
                        <div className="badgeClauseEnd" id="somm2End">28/10/2023</div>
                        <div className="badgeClauseProgress"><progress id="somm2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Task 3</div>
                        <div className="badgeClauseStatus" id="somm3Status">Complete</div>
                        <div className="badgeClauseStart" id="somm3Start">28/10/2023</div>
                        <div className="badgeClauseEnd" id="somm3End">28/10/2023</div>
                        <div className="badgeClauseProgress"><progress id="somm3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Task 4</div>
                        <div className="badgeClauseStatus" id="somm4Status">Complete</div>
                        <div className="badgeClauseStart" id="somm4Start">28/10/2023</div>
                        <div className="badgeClauseEnd" id="somm4End">28/10/2023</div>
                        <div className="badgeClauseProgress"><progress id="somm4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Task 5</div>
                        <div className="badgeClauseStatus" id="somm5Status">Complete</div>
                        <div className="badgeClauseStart" id="somm5Start">28/10/2023</div>
                        <div className="badgeClauseEnd" id="somm5End">28/10/2023</div>
                        <div className="badgeClauseProgress"><progress id="somm5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Task 6</div>
                        <div className="badgeClauseStatus" id="somm6Status">Complete</div>
                        <div className="badgeClauseStart" id="somm6Start">28/10/2023</div>
                        <div className="badgeClauseEnd" id="somm6End">28/10/2023</div>
                        <div className="badgeClauseProgress"><progress id="somm6Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Task 7</div>
                        <div className="badgeClauseStatus" id="somm7Status">Complete</div>
                        <div className="badgeClauseStart" id="somm7Start">28/10/2023</div>
                        <div className="badgeClauseEnd" id="somm7End">28/10/2023</div>
                        <div className="badgeClauseProgress"><progress id="somm7Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="sommProgress" value="25" max="100"></progress></div>
                </div>
            </div>

            <div className="bookSectionTitle bstpink">Self Aware Rebel</div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle pink" tabIndex={0} role="button" aria-expanded="false">
                        <div>My Collection</div>
                        <div>
                            <progress id="collProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro pink-light">Complete 4 Tasks <br/><Link to="/mycollection">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="6">Task 1</div>
                        <div className="badgeClauseStatus" id="coll1Status">Complete</div>
                        <div className="badgeClauseStart" id="coll1Start">4/3/2023</div>
                        <div className="badgeClauseEnd" id="coll1End">4/3/2023</div>
                        <div className="badgeClauseProgress"><progress id="coll1Progress" data-duration="6" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="6">Task 2</div>
                        <div className="badgeClauseStatus" id="coll2Status">Complete</div>
                        <div className="badgeClauseStart" id="coll2Start">4/3/2023</div>
                        <div className="badgeClauseEnd" id="coll2End">4/3/2023</div>
                        <div className="badgeClauseProgress"><progress id="coll2Progress" data-duration="6" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="6">Task 3</div>
                        <div className="badgeClauseStatus" id="coll3Status">Complete</div>
                        <div className="badgeClauseStart" id="coll3Start">4/3/2023</div>
                        <div className="badgeClauseEnd" id="coll3End">4/3/2023</div>
                        <div className="badgeClauseProgress"><progress id="coll3Progress" data-duration="6" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="6">Task 4</div>
                        <div className="badgeClauseStatus" id="coll4Status">Complete</div>
                        <div className="badgeClauseStart" id="coll4Start">4/3/2023</div>
                        <div className="badgeClauseEnd" id="coll4End">4/3/2023</div>
                        <div className="badgeClauseProgress"><progress id="coll4Progress" data-duration="6" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="collProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle pink" tabIndex={0} role="button" aria-expanded="false">
                        <div>My Passion</div>
                        <div>
                            <progress id="passionProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro pink-light">Devote time to a hobby <br/><Link to="/mypassion">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="6">Task 1</div>
                        <div className="badgeClauseStatus" id="passion1Status">Complete</div>
                        <div className="badgeClauseStart" id="passion1Start">1/4/2023</div>
                        <div className="badgeClauseEnd" id="passion1End">28/10/2023</div>
                        <div className="badgeClauseProgress"><progress id="passion1Progress" data-duration="6" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="passionProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle pink" tabIndex={0} role="button" aria-expanded="false">
                        <div>My Powers</div>
                        <div>
                            <progress id="powerProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro pink-light">Take time to answer the questions in the book <br/><Link to="/mypowers">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Part 1</div>
                        <div className="badgeClauseStatus" id="power1Status">Complete</div>
                        <div className="badgeClauseStart" id="power1Start">01/03/2023</div>
                        <div className="badgeClauseEnd" id="power1End">31/03/2023</div>
                        <div className="badgeClauseProgress"><progress id="power1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Part 2</div>
                        <div className="badgeClauseStatus" id="power2Status">Complete</div>
                        <div className="badgeClauseStart" id="power2Start">01/03/2023</div>
                        <div className="badgeClauseEnd" id="power2End">31/03/2023</div>
                        <div className="badgeClauseProgress"><progress id="power2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="powerProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle pink" tabIndex={0} role="button" aria-expanded="false">
                        <div>My Story</div>
                        <div>
                            <progress id="storyProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro pink-light">Write the story of you <br/><Link to="/mystory">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">My Story</div>
                        <div className="badgeClauseStatus" id="story1Status">Complete</div>
                        <div className="badgeClauseStart" id="story1Start">1/3/3034</div>
                        <div className="badgeClauseEnd" id="story1End">30/06/2023</div>
                        <div className="badgeClauseProgress"><progress id="story1Progress" data-duration="3" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="storyProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle pink" tabIndex={0} role="button" aria-expanded="false">
                        <div>My Studies</div>
                        <div>
                            <progress id="studiesProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro pink-light">Complete three tasks related to continuous learning <br/><Link to="/mystudies">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Task 1</div>
                        <div className="badgeClauseStatus" id="studies1Status">Complete</div>
                        <div className="badgeClauseStart" id="studies1Start">1/7/2023</div>
                        <div className="badgeClauseEnd" id="studies1End">27/10/2023</div>
                        <div className="badgeClauseProgress"><progress id="studies1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Task 2</div>
                        <div className="badgeClauseStatus" id="studies2Status">Complete</div>
                        <div className="badgeClauseStart" id="studies2Start">1/7/2023</div>
                        <div className="badgeClauseEnd" id="studies2End">27/10/2023</div>
                        <div className="badgeClauseProgress"><progress id="studies2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Task 3</div>
                        <div className="badgeClauseStatus" id="studies3Status">Complete</div>
                        <div className="badgeClauseStart" id="studies3Start">1/7/2023</div>
                        <div className="badgeClauseEnd" id="studies3End">27/10/2023</div>
                        <div className="badgeClauseProgress"><progress id="studies3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="studiesProgress" value="25" max="100"></progress></div>
                </div>
            </div>

            <div className="bookSectionTitle bstgreen">Wellness Rebel</div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle green" tabIndex={0} role="button" aria-expanded="false">
                        <div>Advanced Reader</div>
                        <div>
                            <progress id="readingProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro green-light">6 month reading challenge <br/><Link to="/advancedreader">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Reading Challenge</div>
                        <div className="badgeClauseStatus" id="reading1Status">Complete</div>
                        <div className="badgeClauseStart" id="reading1Start">01/03/2023</div>
                        <div className="badgeClauseEnd" id="reading1End">31/12/2023</div>
                        <div className="badgeClauseProgress"><progress id="reading1Progress" data-duration="6" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="readingProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle green" tabIndex={0} role="button" aria-expanded="false">
                        <div>Advanced Self Care</div>
                        <div>
                            <progress id="careProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro green-light">3 self-care challenges <br/><Link to="/advancedselfcare">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Challenge 1</div>
                        <div className="badgeClauseStatus" id="care1Status">Complete</div>
                        <div className="badgeClauseStart" id="care1Start">01/08/2023</div>
                        <div className="badgeClauseEnd" id="care1End">31/10/2023</div>
                        <div className="badgeClauseProgress"><progress id="care1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Challenge 2</div>
                        <div className="badgeClauseStatus" id="care2Status">Complete</div>
                        <div className="badgeClauseStart" id="care2Start">01/08/2023</div>
                        <div className="badgeClauseEnd" id="care2End">31/10/2023</div>
                        <div className="badgeClauseProgress"><progress id="care2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Challenge 3</div>
                        <div className="badgeClauseStatus" id="care3Status">Complete</div>
                        <div className="badgeClauseStart" id="care3Start">01/08/2023</div>
                        <div className="badgeClauseEnd" id="care3End">31/10/2023</div>
                        <div className="badgeClauseProgress"><progress id="care3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="careProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle green" tabIndex={0} role="button" aria-expanded="false">
                        <div>Brain Games</div>
                        <div>
                            <progress id="brainProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro green-light">4 brain game tasks<br/><Link to="/braingames">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="2">Task 1</div>
                        <div className="badgeClauseStatus" id="brain1Status">Complete</div>
                        <div className="badgeClauseStart" id="brain1Start">1/11/2022</div>
                        <div className="badgeClauseEnd" id="brain1End">31/03/2023</div>
                        <div className="badgeClauseProgress"><progress id="brain1Progress" data-duration="2" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="2">Task 2</div>
                        <div className="badgeClauseStatus" id="brain2Status">Complete</div>
                        <div className="badgeClauseStart" id="brain2Start">1/11/2022</div>
                        <div className="badgeClauseEnd" id="brain2End">31/3/2023</div>
                        <div className="badgeClauseProgress"><progress id="brain2Progress" data-duration="2" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="2">Task 3</div>
                        <div className="badgeClauseStatus" id="brain3Status">Complete</div>
                        <div className="badgeClauseStart" id="brain3Start">1/11/2022</div>
                        <div className="badgeClauseEnd" id="brain3End">31/3/2023</div>
                        <div className="badgeClauseProgress"><progress id="brain3Progress" data-duration="2" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="2">Task 4</div>
                        <div className="badgeClauseStatus" id="brain4Status">Complete</div>
                        <div className="badgeClauseStart" id="brain4Start">1/11/2022</div>
                        <div className="badgeClauseEnd" id="brain4End">31/3/2023</div>
                        <div className="badgeClauseProgress"><progress id="brain4Progress" data-duration="2" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="brainProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle green" tabIndex={0} role="button" aria-expanded="false">
                        <div>Walker</div>
                        <div>
                            <progress id="walkerProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro green-light">Walker <br/><Link to="/walker">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Regular walking</div>
                        <div className="badgeClauseStatus" id="walker1Status">Complete</div>
                        <div className="badgeClauseStart" id="walker1Start">01/01/2024</div>
                        <div className="badgeClauseEnd" id="walker1End">31/3/2024</div>
                        <div className="badgeClauseProgress"><progress id="walker1Progress" data-duration="6" value="25" max="100"></progress></div>
                    </div> 
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Set targets</div>
                        <div className="badgeClauseStatus" id="walker2Status">Complete</div>
                        <div className="badgeClauseStart" id="walker2Start">01/01/2024</div>
                        <div className="badgeClauseEnd" id="walker2End">31/3/2024</div>
                        <div className="badgeClauseProgress"><progress id="walker2Progress" data-duration="6" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="3">Increase</div>
                        <div className="badgeClauseStatus" id="walker3Status">Complete</div>
                        <div className="badgeClauseStart" id="walker3Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="walker3End">31/3/2024</div>
                        <div className="badgeClauseProgress"><progress id="walker3Progress" data-duration="6" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="walkerProgress" value="25" max="100"></progress></div>
                </div>
            </div>

            <div className="bookSectionTitle bstbrown">Wild Rebel</div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle brown" tabIndex={0} role="button" aria-expanded="false">
                        <div>Inventor</div>
                        <div>
                            <progress id="inventProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro brown-light">Complete 8 Clauses<br/><Link to="/inventor">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 1</div>
                        <div className="badgeClauseStatus" id="invent1Status">Not started</div>
                        <div className="badgeClauseStart" id="invent1Start"></div>
                        <div className="badgeClauseEnd" id="invent1End"></div>
                        <div className="badgeClauseProgress"><progress id="invent1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 2</div>
                        <div className="badgeClauseStatus" id="invent2Status">Not started</div>
                        <div className="badgeClauseStart" id="invent2Start"></div>
                        <div className="badgeClauseEnd" id="invent2End"></div>
                        <div className="badgeClauseProgress"><progress id="invent2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 3</div>
                        <div className="badgeClauseStatus" id="invent3Status">Not started</div>
                        <div className="badgeClauseStart" id="invent3Start"></div>
                        <div className="badgeClauseEnd" id="invent3End"></div>
                        <div className="badgeClauseProgress"><progress id="invent3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="2">Clause 4</div>
                        <div className="badgeClauseStatus" id="invent4Status">Not started</div>
                        <div className="badgeClauseStart" id="invent4Start"></div>
                        <div className="badgeClauseEnd" id="invent4End"></div>
                        <div className="badgeClauseProgress"><progress id="invent4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 5</div>
                        <div className="badgeClauseStatus" id="invent5Status">Not started</div>
                        <div className="badgeClauseStart" id="invent5Start"></div>
                        <div className="badgeClauseEnd" id="invent5End"></div>
                        <div className="badgeClauseProgress"><progress id="invent5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 6</div>
                        <div className="badgeClauseStatus" id="invent6Status">Not started</div>
                        <div className="badgeClauseStart" id="invent6Start"></div>
                        <div className="badgeClauseEnd" id="invent6End"></div>
                        <div className="badgeClauseProgress"><progress id="invent6Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="2">Clause 7</div>
                        <div className="badgeClauseStatus" id="invent7Status">Not started</div>
                        <div className="badgeClauseStart" id="invent7Start"></div>
                        <div className="badgeClauseEnd" id="invent7End"></div>
                        <div className="badgeClauseProgress"><progress id="invent7Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="2">Clause 8</div>
                        <div className="badgeClauseStatus" id="invent8Status">Not started</div>
                        <div className="badgeClauseStart" id="invent8Start"></div>
                        <div className="badgeClauseEnd" id="invent8End"></div>
                        <div className="badgeClauseProgress"><progress id="invent8Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="inventProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle brown" tabIndex={0} role="button" aria-expanded="false">
                        <div>Nature Lover</div>
                        <div>
                            <progress id="natureProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro brown-light">Complete at least 8 of 16 clauses<br/><Link to="/naturelover">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 1 - Wildlife research</div>
                        <div className="badgeClauseStatus" id="nature1Status">Complete</div>
                        <div className="badgeClauseStart" id="nature1Start">1/6/2023</div>
                        <div className="badgeClauseEnd" id="nature1End">30/6/2023</div>
                        <div className="badgeClauseProgress"><progress id="nature1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 2 - nature reserve</div>
                        <div className="badgeClauseStatus" id="nature2Status">Complete</div>
                        <div className="badgeClauseStart" id="nature2Start">1/6/2023</div>
                        <div className="badgeClauseEnd" id="nature2End">30/6/2023</div>
                        <div className="badgeClauseProgress"><progress id="nature2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 3 - Documentaries</div>
                        <div className="badgeClauseStatus" id="nature3Status">Complete</div>
                        <div className="badgeClauseStart" id="nature3Start">1/6/2023</div>
                        <div className="badgeClauseEnd" id="nature3End">30/6/2023</div>
                        <div className="badgeClauseProgress"><progress id="nature3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 4 - Protect</div>
                        <div className="badgeClauseStatus" id="nature4Status">Complete</div>
                        <div className="badgeClauseStart" id="nature4Start">1/6/2023</div>
                        <div className="badgeClauseEnd" id="nature4End">30/6/2023</div>
                        <div className="badgeClauseProgress"><progress id="nature4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 5 - Survey</div>
                        <div className="badgeClauseStatus" id="nature5Status">Complete</div>
                        <div className="badgeClauseStart" id="nature5Start">1/6/2023</div>
                        <div className="badgeClauseEnd" id="nature5End">30/6/2023</div>
                        <div className="badgeClauseProgress"><progress id="nature5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 6 - Pond Dipping</div>
                        <div className="badgeClauseStatus" id="nature6Status">Complete</div>
                        <div className="badgeClauseStart" id="nature6Start">30/5/2023</div>
                        <div className="badgeClauseEnd" id="nature6End">30/5/2023</div>
                        <div className="badgeClauseProgress"><progress id="nature6Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 7 - National park</div>
                        <div className="badgeClauseStatus" id="nature7Status">Complete</div>
                        <div className="badgeClauseStart" id="nature7Start">1/6/2023</div>
                        <div className="badgeClauseEnd" id="nature7End">30/6/2023</div>
                        <div className="badgeClauseProgress"><progress id="nature7Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 8 - Countryside Code</div>
                        <div className="badgeClauseStatus" id="nature8Status">Complete</div>
                        <div className="badgeClauseStart" id="nature8Start">1/6/2023</div>
                        <div className="badgeClauseEnd" id="nature8End">30/6/2023</div>
                        <div className="badgeClauseProgress"><progress id="nature8Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="natureProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle brown" tabIndex={0} role="button" aria-expanded="false">
                        <div>Observer</div>
                        <div>
                            <progress id="observeProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro brown-light">Complete 8 clauses<br/><Link to="/observer">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 1</div>
                        <div className="badgeClauseStatus" id="observe1Status">Complete</div>
                        <div className="badgeClauseStart" id="observe1Start">1/7/2023</div>
                        <div className="badgeClauseEnd" id="observe1End">16/7/2023</div>
                        <div className="badgeClauseProgress"><progress id="observe1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 2</div>
                        <div className="badgeClauseStatus" id="observe2Status">Complete</div>
                        <div className="badgeClauseStart" id="observe2Start">1/7/2023</div>
                        <div className="badgeClauseEnd" id="observe2End">16/7/2023</div>
                        <div className="badgeClauseProgress"><progress id="observe2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 3</div>
                        <div className="badgeClauseStatus" id="observe3Status">Complete</div>
                        <div className="badgeClauseStart" id="observe3Start">1/7/2023</div>
                        <div className="badgeClauseEnd" id="observe3End">16/7/2023</div>
                        <div className="badgeClauseProgress"><progress id="observe3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 4</div>
                        <div className="badgeClauseStatus" id="observe4Status">Complete</div>
                        <div className="badgeClauseStart" id="observe4Start">1/7/2023</div>
                        <div className="badgeClauseEnd" id="observe4End">16/7/2023</div>
                        <div className="badgeClauseProgress"><progress id="observe4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 5</div>
                        <div className="badgeClauseStatus" id="observe5Status">In progress</div>
                        <div className="badgeClauseStart" id="observe5Start">1/7/2023</div>
                        <div className="badgeClauseEnd" id="observe5End">16/7/2023</div>
                        <div className="badgeClauseProgress"><progress id="observe5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 6</div>
                        <div className="badgeClauseStatus" id="observe6Status">In progress</div>
                        <div className="badgeClauseStart" id="observe6Start">1/7/2023</div>
                        <div className="badgeClauseEnd" id="observe6End">16/7/2023</div>
                        <div className="badgeClauseProgress"><progress id="observe6Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 7</div>
                        <div className="badgeClauseStatus" id="observe7Status">In progress</div>
                        <div className="badgeClauseStart" id="observe7Start">1/7/2023</div>
                        <div className="badgeClauseEnd" id="observe7End">16/7/2023</div>
                        <div className="badgeClauseProgress"><progress id="observe7Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 8</div>
                        <div className="badgeClauseStatus" id="observe8Status">In progress</div>
                        <div className="badgeClauseStart" id="observe8Start">1/7/2023</div>
                        <div className="badgeClauseEnd" id="observe8End">16/7/2023</div>
                        <div className="badgeClauseProgress"><progress id="observe8Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="observeProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle brown" tabIndex={0} role="button" aria-expanded="false">
                        <div>Palaeontologist</div>
                        <div>
                            <progress id="paleoProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro brown-light">Complete at least 6 clauses<br/><Link to="/palaeontologist">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 1</div>
                        <div className="badgeClauseStatus" id="paleo1Status">Complete</div>
                        <div className="badgeClauseStart" id="paleo1Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="paleo1End">27/02/2024</div>
                        <div className="badgeClauseProgress"><progress id="paleo1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 2</div>
                        <div className="badgeClauseStatus" id="paleo2Status">Complete</div>
                        <div className="badgeClauseStart" id="paleo2Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="paleo2End"></div>
                        <div className="badgeClauseProgress"><progress id="paleo2Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 3</div>
                        <div className="badgeClauseStatus" id="paleo3Status">Complete</div>
                        <div className="badgeClauseStart" id="paleo3Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="paleo3End"></div>
                        <div className="badgeClauseProgress"><progress id="paleo3Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 4</div>
                        <div className="badgeClauseStatus" id="paleo4Status">Complete</div>
                        <div className="badgeClauseStart" id="paleo4Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="paleo4End">5/5/2024</div>
                        <div className="badgeClauseProgress"><progress id="paleo4Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 5</div>
                        <div className="badgeClauseStatus" id="paleo5Status">In progress</div>
                        <div className="badgeClauseStart" id="paleo5Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="paleo5End"></div>
                        <div className="badgeClauseProgress"><progress id="paleo5Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 6</div>
                        <div className="badgeClauseStatus" id="paleo6Status">Complete</div>
                        <div className="badgeClauseStart" id="paleo6Start">01/02/2024</div>
                        <div className="badgeClauseEnd" id="paleo6End">5/5/2024</div>
                        <div className="badgeClauseProgress"><progress id="paleo6Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="paleoProgress" value="25" max="100"></progress></div>
                </div>
            </div>
            <div className="row">
                <div className="badgeItem">
                    <div className="badgeItemTitle brown" tabIndex={0} role="button" aria-expanded="false">
                        <div>Scientist</div>
                        <div>
                            <progress id="sciProgressMini" value="25" max="100"></progress>
                            <span className="icon-down-open"></span></div>
                        </div>
                    <div className="badgeItemIntro brown-light">Complete a science based project!<br/><Link to="/scientist">View evidence page</Link></div>
                    <div className="badgeClause">
                        <div className="badgeClauseTask" data-duration="">Clause 1</div>
                        <div className="badgeClauseStatus" id="sci1Status">Complete</div>
                        <div className="badgeClauseStart" id="sci1Start">1/8/2023</div>
                        <div className="badgeClauseEnd" id="sci1End">31/08/2023</div>
                        <div className="badgeClauseProgress"><progress id="sci1Progress" value="25" max="100"></progress></div>
                    </div>
                    <div className="badgeProgress"><progress id="sciProgress" value="25" max="100"></progress></div>
                </div>
            </div>
        </div>
    )
}

function listenFunc(element)
{
    var elementIntro = element.parentElement.querySelector(".badgeItemIntro");
    var elementClause = element.parentElement.querySelectorAll(".badgeClause");
    var elementProgress = element.parentElement.querySelector(".badgeProgress");

    if(element.getAttribute("aria-expanded") === "false")
    {
        element.setAttribute("aria-expanded", "true");
    }
    else
    {
        element.setAttribute("aria-expanded", "false");
    }

    if(elementIntro.classList.contains("hidden"))
    {
        elementIntro.classList.remove("hidden");
        elementIntro.classList.add("shown");
    } else {
        elementIntro.classList.remove("shown");
        elementIntro.classList.add("hidden");
    }

    elementClause.forEach((ec) => {
        if(ec.classList.contains("hidden"))
        {
            ec.classList.remove("hidden");
            ec.classList.add("shown");
        } else {
            ec.classList.remove("shown");
            ec.classList.add("hidden");
        }
    })
    

    if(elementProgress.classList.contains("hidden"))
    {
        elementProgress.classList.remove("hidden");
        elementProgress.classList.add("shown");
    } else {
        elementProgress.classList.remove("shown");
        elementProgress.classList.add("hidden");
    }
}

function addListener(element)
{
    element.addEventListener("click",function(){
        listenFunc(element);
    })

    element.addEventListener("keydown", function(e){
    if (e.key !== undefined){
        if(e.key === "Enter" || e.key === " " || e.key === "ArrowDown" || e.key === "ArrowUp" || e.key === "ArrowLeft" || e.key === "ArrowRight")
        {
            listenFunc(element);
        }
    } else if (e.which !== undefined) {
        if(e.key === 13 || e.key === 32 || e.key === 40 || e.key === 38 || e.key ===37 || e.key === 39)
        {
            listenFunc(element);
        }
    }
    })
}

export default Book2