import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Skills1 from '../images/bronze-myskills1.jpg';
import Skills2 from '../images/bronze-myskills2.jpg';
import Skills4 from '../images/bronze-myskills4.jpg';
import Skills5 from '../images/bronze-myskills5.jpg';
import Skills6 from '../images/bronze-myskills6.jpg';
import Skills7 from '../images/bronze-myskills7.jpg';
import Skills8 from '../images/bronze-myskills8.jpg';
import Skills9 from '../images/bronze-myskills9.jpg';
import Skills10 from '../images/bronze-myskills10.jpg';
import Skills11 from '../images/bronze-myskills11.jpg';
import Skills12 from '../images/bronze-myskills12.jpg';
import Skills13 from '../images/bronze-myskills13.jpg';
import Skills14 from '../images/bronze-myskills14.jpg';
import Skills15 from '../images/bronze-myskills15.jpg';
import Skills16 from '../images/bronze-myskills16.jpg';
import Skills17 from '../images/bronze-myskills17.jpg';
import Skills18 from '../images/bronze-myskills18.jpg';
import Skills19 from '../images/bronze-myskills19.jpg';
import Skills20 from '../images/bronze-myskills20.jpg';
import Skills21 from '../images/bronze-myskills21.jpg';
import Skills22 from '../images/bronze-myskills22.jpg';
import Skills23 from '../images/bronze-myskills23.jpg';
import Skills24 from '../images/bronze-myskills24.jpg';
import Skills25 from '../images/bronze-myskills25.jpg';
import Skills26 from '../images/bronze-myskills26.jpg';
import Skills27 from '../images/bronze-myskills27.jpg';
import Skills28 from '../images/bronze-myskills28.jpg';
import Skills29 from '../images/bronze-myskills29.jpg';
import Skills30 from '../images/bronze-myskills30.jpg';
import Skills32 from '../images/bronze-myskills32.jpg';
import Skills33 from '../images/bronze-myskills33.jpg';
import Skills34 from '../images/bronze-myskills34.jpg';
import Skills35 from '../images/bronze-myskills35.jpg';
import Skills36 from '../images/bronze-myskills36.jpg';
import Skills37 from '../images/bronze-myskills37.jpg';
import Skills38 from '../images/bronze-myskills38.jpg';
import Skills39 from '../images/bronze-myskills39.jpg';
import Skills40 from '../images/bronze-myskills40.jpg';
import Skills41 from '../images/bronze-myskills41.jpg';
import Skills42 from '../images/bronze-myskills42.jpg';
import Skills43 from '../images/bronze-myskills43.jpg';
import Skills44 from '../images/bronze-myskills44.jpg';
import Skills45 from '../images/bronze-myskills45.jpg';
import Skills46 from '../images/bronze-myskills46.jpg';
import Skills47 from '../images/bronze-myskills47.jpg';
import Skills49 from '../images/bronze-myskills49.jpg';
import Skills50 from '../images/bronze-myskills50.jpg';
import Skills51 from '../images/bronze-myskills51.jpg';
import Skills52 from '../images/bronze-myskills52.jpg';
import Skills53 from '../images/bronze-myskills53.jpg';
import Skills54 from '../images/bronze-myskills54.jpg';
import Skills55 from '../images/bronze-myskills55.jpg';
import Skills56 from '../images/bronze-myskills56.jpg';
import Skills57 from '../images/bronze-myskills57.jpg';
import Skills58 from '../images/bronze-myskills58.jpg';
import Skills59 from '../images/bronze-myskills59.jpg';
import Skills60 from '../images/bronze-myskills60.jpg';
import Skills61 from '../images/bronze-myskills61.jpg';
import Skills62 from '../images/bronze-myskills62.jpg';
import Skills63 from '../images/bronze-myskills63.jpg';
import Skills64 from '../images/bronze-myskills64.jpg';
import Skills65 from '../images/bronze-myskills65.jpg';
import Skills66 from '../images/bronze-myskills66.jpg';
import Skills67 from '../images/bronze-myskills67.jpg';
import Skills68 from '../images/bronze-myskills68.jpg';
import Skills69 from '../images/bronze-myskills69.jpg';
import Skills70 from '../images/bronze-myskills70.jpg';
import Skills71 from '../images/bronze-myskills71.jpg';
import Skills72 from '../images/bronze-myskills72.jpg';
import Skills73 from '../images/bronze-myskills73.jpg';
import Skills74 from '../images/bronze-myskills74.jpg';
import Skills75 from '../images/bronze-myskills75.jpg';
import Skills76 from '../images/bronze-myskills76.jpg';
import Skills77 from '../images/bronze-myskills77.jpg';
import Skills78 from '../images/bronze-myskills78.jpg';
import Skills79 from '../images/bronze-myskills79.jpg';
import Skills80 from '../images/bronze-myskills80.jpg';
import Skills81 from '../images/bronze-myskills81.jpg';
import Skills82 from '../images/bronze-myskills82.jpg';
import Skills83 from '../images/bronze-myskills83.jpg';
import Skills84 from '../images/bronze-myskills84.jpg';
import Skills85 from '../images/bronze-myskills85.jpg';
import Skills86 from '../images/bronze-myskills86.jpg';
import Skills87 from '../images/bronze-myskills87.jpg';
import Skills88 from '../images/bronze-myskills88.jpg';
import Skills89 from '../images/bronze-myskills89.jpg';
import Skills90 from '../images/bronze-myskills90.jpg';
import Skills91 from '../images/bronze-myskills91.jpg';
import Skills92 from '../images/bronze-myskills92.jpg';
import Skills93 from '../images/bronze-myskills93.jpg';
import Skills94 from '../images/bronze-myskills94.jpg';
import Skills95 from '../images/bronze-myskills95.jpg';
import Skills96 from '../images/bronze-myskills96.jpg';
import Skills97 from '../images/bronze-myskills97.jpg';
import Skills98 from '../images/bronze-myskills98.jpg';
import Skills99 from '../images/bronze-myskills99.jpg';
import Skills100 from '../images/bronze-myskills100.jpg';
import Skills101 from '../images/bronze-myskills101.jpg';
import Skills102 from '../images/bronze-myskills102.png';
import Skills103 from '../images/bronze-myskills103.png';
import Skills104 from '../images/bronze-myskills104.png';
import Skills105 from '../images/bronze-myskills105.png';
import SkillsP from '../documents/Bronze-MySkill.pptx';

const MySkills = () => {
    return (
        <div>
            <ActionBar area={"bronze"}  />
            <EvidenceHeader title={"My Skills"} />
            <div className="mavPageItem">
                <div className="mavPageItemTitle blue">My Skills</div>
                <div className="mavPageItemContent">
                    <div className="mavPageItemInfo blue-light">Over at least six months improve on a skill of your choice.</div>
                    <div className="mavPageItemEvidence">
                        <p>During lockdown I decided to teach myself to draw. Mostly I was working with graphite pencils and doing portraits but I also gave colours a go too.</p>
                        <br/>
                        <p>I came across a competition called <a href="https://explorersagainstextinction.co.uk/initiatives/sketch-for-survival/" target="_blank" rel="noopener noreferrer">"Sketch for Survival"</a> and decided to give it a go, submitting a <a href="https://ameliaraine.me.uk/art" target="_blank" rel="noopener noreferrer">graphite drawing of a Tiger</a>. It was in the top 300 submissions and made it to the Conservation Collection where it went on sale on the website.</p>
                        <br/>
                        <p>I made up my mind to enter again the following year but this time using colour. I didn't make it!  I've done a couple of drawings in colour but I'm much more scared of my colour pencils and I haven't fared so well with them.</p>
                        <br/>
                        <p>So, this part of my challenge will be to work on my colour drawing skills to produce a drawing in colour pencil that I can submit to next year's Sketch for Survival competition.</p>
                        <br/>
                        <p>This will involve watching tutorials and probably a couple of practice runs! </p>
                        <br/>
                        <p>I have chosen a Giraffe picture to draw. My Mum loves giraffes, so this one is for her and provides a suitable challenge.</p>
                        <br/>
                        <p>Progress updates to follow!</p>
                        <br/>
                        <hr/>
                        <p><h3 className="bronze">Tutorials</h3></p>
                        <p>I found a YouTube video to watch (there are loads though) for pointers - <a href="https://www.youtube.com/watch?v=v9p_ZSNNoBA" target="_blank" rel="noopener noreferrer">https://www.youtube.com/watch?v=v9p_ZSNNoBA</a></p>
                        <br/><br/>
                        <hr />
                        <br/><br/>
                        <p><h3 className="bronze">Getting started</h3></p>
                        <p>The first thing I needed to do was find the colours that I needed. I have a couple of tins of artist's coloured pencils and a big case full of kids colouring pencils! Nothing I had was really the right shade, but I found some that were close enough to work with.
                        <br/><br/>
                        I started scribbling some colour blocks on paper to match to my source image and put together a working set of colours.
                        <br/><br/>
                        One of the kids pencils was light enough for the general colouring, the rest were gathered from various collections!
                        <br/><br/>
                        </p>
                        <div className="skillsGallery">
                            <img className="imgQuarter" src={Skills1} alt="Coloured pencils lines on paper for testing colours" />
                        </div>
                        <p>I start by tracing the basic outline and some highlights, my eye isn't good enough to completely freehand yet, so the tracing gives me a baseline. The, I picked a spot to start, took a deep breath and started!!<br/></p>
                        <div className="skillsGallery">
                            <img src={Skills1} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills2} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills4} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills5} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills6} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills7} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills8} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills9} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                        </div>
                        <br/><br/>
                        <hr />
                        <p><h3 className="bronze">Filling in</h3></p>
                        <p>With the work underway, I carried on filling in more of the picture.<br/></p>
                        <div className="skillsGallery">
                            <img src={Skills10} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills11} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills12} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills13} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills14} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills15} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills16} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills17} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills18} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                        </div>
                        <br/><br/>
                        <hr />
                        <p><h3 className="bronze">Coming Together</h3></p>
                        <p><br/></p>
                        <div className="skillsGallery">
                            <img src={Skills19} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills20} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                        </div>
                        <br/><br/>
                        <hr />
                        <br/><br/>
                        <p><h3 className="bronze">Progress Report</h3></p>
                        <p>I'm not happy &#x1F60F;!!  It looks like a giraffe, but my strokes and shading are just off. The pencils I have (apart from the kids one) are soft leaded and I'm struggling to get the fur strokes that I want. It all looks a bit flat.
                        <br/><br/>
                        <p>The other problem I have is that the base colour I'm using is ok but it's rapidly running down with each sharpening! It's a random brand out of my big pencil box that doesn't seem to exist any more so I may need to think about working with different colours. Tricky. I don't have much of an alternative!</p>
                        <br/><br/>
                        If I'm being honest, I'm probably trying to do this too quickly! It's a curse, I have to try and do everything now.
                        <br/><br/>
                        Time to pause, do some more practice and slow it down a few notches.</p>
                        <br/><br/>
                        <hr/>
                        <br/>
                        <h3 className="bronze">Step Back</h3>
                        <p>OK, let's see what advice the YouTubers have for me!</p>
                        <br/>
                        <p>This was a nice short <a href="https://www.youtube.com/watch?v=RkQzQTPX_8o" target="_blank" rel="noopener noreferrer">video from Unmask Art</a> with some useful reminders</p>
                        <br/>
                        <p>I had a look at this <a href="https://www.youtube.com/watch?v=cdcDeXint4U" target="_blank" rel="noopener noreferrer">video about colouring fur</a> - nothing quite matches up to Giraffe fur, but it has very helpful techniques!</p>
                        <br/>
                        <p>My brain kicked in and I remembered that I had this lovely book, so I spent some time reading through for tips</p>
                        <br/>
                        <img src={Skills62} alt="The coloured pencil bible" className="imgQuarter" />
                        <br/><br/>
                        <hr/>
                        <p><h3 className="bronze">Breaking down the elements</h3></p>
                        <p>I needed to focus on smaller areas, rather than try to do everything at once. I got a new pencil selection for Christmas and there are some much more promising colours. The first thing I did was a new colour swatch.</p>
                        <div className="skillsGallery">
                            <img src={Skills22} alt="The head and neck of a Giraffe used as a reference with a tin of coloured pencils" className="imgQuarter" />
                            <img src={Skills23} alt="Colour test strips against a the reference Giraffe" className="imgQuarter" />
                        </div>
                        <br/>
                        <p>I started with one of the horns, it's a nice focussed area but with lots of different textures and shades.</p>
                        <div className="skillsGallery">
                            <img src={Skills24} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills25} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills26} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills27} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills28} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills29} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills30} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills32} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills33} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills34} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills35} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills36} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                        </div>
                        <br/>
                        <p>I feel better about this. I can already see a difference from my first attempts.</p>
                        <br/>
                        <hr />
                        <br/><br/>
                        <p><h3 className="bronze">Biting the bullet</h3></p>
                        <p>I was more excited to get going again now, I decided to break down other elements as I got to them (if I needed to), Back to the light box!
                        <br/><br/>I got this lovely wooden riser stand for my birthday, which means I can draw much more comfortably without being bent over all the time! I have this LED light box which has three different light intensities, but I also have a lovely wooden light box that I can use. I often put things like this on Christmas and birthday lists so people who want to get me something have something useful they can buy (I'm so spoilt!!).<br/><br/>
                        </p>
                        <div className="skillsGallery">
                            <img src={Skills21} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                        </div>
                        <br/>
                        <p>Previously, I used normal sketch pad paper - the type you find for kids in most shops - it's fine for a lot of drawing but it is possible to over-work it! This time, I chose a much heavier, smoother and bright white Bristol paper to work with.
                        <br/>
                        Time to get going &#128556; !! <br/><br/></p>
                        <div className="skillsGallery">
                            <img src={Skills37} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills38} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills39} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills40} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills41} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills42} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills43} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills44} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                        </div>
                        <br/>
                        <br/><br/>
                        <hr />
                        <br/><br/>
                        <p><h3 className="bronze">Progress Comparison</h3></p>
                        <p>Keeping fingers crossed for an improvement in my technique, I had a quick look at how I was progressing against my first attempt.<br/></p>
                        <div className="skillsGallery">
                            <img src={Skills45} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                        </div>
                        <p>I think we're looking good! Certainly better shading and texture. The quality paper is easier to work with, particularly with lots of different layers.</p>
                        <br/><br/>
                        <hr />
                        <br/><br/>
                        <p><h3 className="bronze">Progress Shots</h3></p>
                        <p>I continued to fill in the sections, taking a little bit at a time.<br/></p>
                        <div className="skillsGallery">
                            <img src={Skills46} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills49} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                        </div>
                        <br/><br/>
                        <p><h3 className="bronze">Progress Comparison</h3></p>
                        <p>After about 3.5 hours I decided to stop whilst I was ahead, before I got to a point where I'd lost interest for the day and started making silly mistakes!<br/></p>
                        <div className="skillsGallery">
                            <img src={Skills47} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills45} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                        </div>
                        <p>So far, so good! More tomorrow.</p>
                        <br/><br/>
                        <hr />
                        <p><h3 className="bronze">Progress Shots</h3></p>
                        <p>OK. Time to attempt the next section, this bit I was worried about! I took it slowly and it started to come together.<br/></p>
                        <div className="skillsGallery">
                            <img src={Skills50} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills51} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills52} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills53} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills54} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills55} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills56} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills57} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills58} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills59} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills60} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                        </div>
                        <br/><br/>
                        <p><h3 className="bronze">Progress Comparison</h3></p>
                        <p>Time to see how we're doing compared to the original attempt.<br/></p>
                        <div className="skillsGallery">
                            <img src={Skills61} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                        </div>
                        <p>I think it's ok after all. I can see some spots that need tweaking, but there will be time for touch ups once the baseline is complete.</p>
                        <p>I'm about half way. The next bit is even more daunting, so I may break that down into some test spots first!</p>
                        <br/><br/>
                        <hr />
                        <br/>
                        <p><h3 className="bronze">Progress Shots</h3></p>
                        <br/>
                        OK. Here we go. I've been procrastinating because I'm scared of this next bit!! Let's break it down!
                        <br/><br/>
                        <div className="skillsGallery">
                            <img src={Skills63} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills64} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills65} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills66} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills67} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills68} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills69} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills70} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills71} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills72} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                        </div>
                        I think it's ok. I'm still not sure but I've been mixing up the technique as I go.
                        <br/><br/>
                        <hr/>
                        <br/>
                        <p><h3 className="bronze">Progress Comparison</h3></p>
                        <br/>
                        <div className="skillsGallery">
                            <img src={Skills73} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills74} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills75} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                        </div>
                        Compared to the first attempt, it's looking much more substantial. Time to move back to the main drawing.
                        <br/><br/>
                        <hr/>
                        <p><h3 className="bronze">Progress Shots</h3></p>
                        <br/>
                        Wish me luck!!
                        <br/><br/>
                        <div className="skillsGallery">
                            <img src={Skills76} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills77} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills78} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills79} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills80} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills81} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills82} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                        </div>
                        We're looking good!
                        <br/><br/>
                         <hr/>
                        <p><h3 className="bronze">Progress Comparison</h3></p>
                        <br/>
                        Here's how it's looking now compared to the original drawing.
                        <br/><br/>
                        <div className="skillsGallery">
                            <img src={Skills83} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />

                        </div>
                        We're looking good!
                        <br/><br/>
                        <hr/>
                        <p><h3 className="bronze">Progress Shots</h3></p>
                        <br/>
                        Time to start work on the neck. Now, this bit I didn't do in the original drawing so I'm going to tempt fate and go straight to the final picture. It's all light and shade, right?!
                        <br/><br/>
                        <div className="skillsGallery">
                            <img src={Skills84} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills85} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills86} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills87} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills88} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills90} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                        </div>
                        That went better than I thought, maybe I'm getting more comfortable (a bit late, I know!)
                        <br/><br/>
                        <hr/>
                        <p><h3 className="bronze">Progress Comparison</h3></p>
                        <br/>
                        <div className="skillsGallery">
                            <img src={Skills89} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                        </div>
                        I actually really like this! It turned out much better than I imagined!
                        <br/><br/>
                        <hr/>
                        <p><h3 className="bronze">Progress Shots</h3></p>
                        <br/>
                        <p>The next thing is to fill in those eyes, I wanted to leave these to one of the last things because they always nicely finish off a piece! Last to go on are the whiskers.</p>
                        <br/><br/>
                        <div className="skillsGallery">
                            <img src={Skills91} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills92} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills93} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills94} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills95} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills96} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                        </div>
                        <br/><br/>
                        <hr/>
                        <p><h3 className="bronze">Progress Comparison</h3></p>
                        <br/>
                        <p>Final check in!</p>
                        <br/><br/>
                        <div className="skillsGallery">
                            <img src={Skills97} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                        </div>
                        <br/><br/>
                        <hr/>
                        <p><h3 className="bronze">Final Photos</h3></p>
                        <br/>
                        <p>My closing shots</p>
                        <br/><br/>
                        <div className="skillsGallery">
                            <img src={Skills98} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills99} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                        </div>
                        <br/><br/>
                        <hr />
                        <br/><br/>
                        <p><h3 className="bronze">The Final Piece next to the the First Attempt and the Reference Photo</h3></p>
                        <br/><br/>
                        <div className="skillsGallery">
                            <img src={Skills101} alt="Progress drawing of a giraffe in coloured pencils" className="imgLarge" />
                        </div>
                        <br/><br/>
                        <hr />
                        <br/><br/>
                        <p><h3 className="bronze">The Final Piece</h3></p>
                         <br/><br/>
                        <div className="skillsGallery">
                            <img src={Skills100} alt="Progress drawing of a giraffe in coloured pencils" className="imgLarge" />
                        </div>
                        <br/><br/>
                        <br/><br/>
                        <hr />
                        <br/><br/>
                        <p><h3 className="bronze">Competition Submission</h3></p>
                        <br/>
                        <p>The whole point of this was to improve enough to submit a drawing to the Sketch for Survival competition. The submission process opened on 1st Feb 2023, so here is the evidence of my submission, done on 10th Feb 2023.</p>
                        <br/><br/>
                        <div className="skillsGallery">
                            <img src={Skills102} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills103} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills104} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                            <img src={Skills105} alt="Progress drawing of a giraffe in coloured pencils" className="imgQuarter" />
                        </div>
                        <br/><br/>
                        <br/><br/>
                        <hr />
                        <br/><br/>
                        <p><h3 className="bronze">My Final Words</h3></p>
                        <br/>
                        <p>I'm never happy with my finished artwork, but I think that it's because by this point I've spent so much time looking at it that it no longer makes any kind of visual impact!!</p>
                        <br/>
                        <p>I do like this though, it did go better than I had imagined. I really wasn't sure that I could pull it off, particularly considering my first attempt! Drawing fur is a whole other thing and making it look fluffy is tricky!!</p>
                        <br/>
                        <p>I think my final piece shows significant progress from my first attempt. The texture, depth and detail are much better and it looks much less flat.</p>
                        <br/>
                        <p>Has this changed my mind on colour drawing? No!! I do kind of enjoy it, but I'm still terrified of those pencils. I guess if I want to purchase the beautiful artists sets though, I'm just going to have to commit to working in colour more!</p>
                        <br/>
                        <p>Unfortunately, when I was cleaning up at the end, I tore my paper when trying to erase a pencil mark, so my finished picture is now imperfect.</p>
                        <br/>
                        <p>I then proceeded to mess up drawing a border with a pen and ruler .... don't ask!!</p>
                        <br/>
                        <p>That said, I think the final product looks ok!</p>
                        <br/><br/>
                        The pencils that I used to complete this are:
                        <br/>
                        <ul className="list">
                            <li>Black Widow - SD016, Olive Brown</li>
                            <li>Black Widow - SD001, Leather</li>
                            <li>Black Widow - SD019, Light Mocha</li>
                            <li>Black Widow - SD018, Greythorn</li>
                            <li>Black Widow - SD013, Suede</li>
                            <li>Black Widow - SD022, Mud</li>
                            <li>Black Widow - SD023, Choctone</li>
                            <li>Black Widow - SD024, Midnight</li>
                            <li>Castle Arts - 005, Lemon Yellow</li>
                            <li>Castle Arts - 077, Yellow Ochre</li>
                            <li>Castle Arts - 071, Ivory Black</li>
                            <li>Faber Castelle Polychromos - 103, Ivory</li>
                            <li>Koh i Noor - White Coal</li>
                        </ul>
                        <br/>                        
                        <p>The Black Widow set was amazing, the perfect colour set - these are in the tin called "Dark Tone Skin"</p>
                        <br/>
                        <p>I hope you like my Giraffe. I hope my Mum will too .. I haven't shown her yet!</p>
                        <br/>
                        <p>First round of judging is in July, so I won't know until then whether I have made it, keep your fingers crossed for me &#x1F91E;</p>
                        <br/><br/>
                        <hr/>
                        <br/>
                        <div className="mavHeading"><h3>Presentation</h3></div>
                        <br/>
                        <iframe title="Link to Presentation on OneDrive" src="https://onedrive.live.com/embed?cid=0229995A9087012D&resid=229995A9087012D%21343311&authkey=AKYFoiiu0VncIMg&em=2" width="402" height="327" frameborder="0" scrolling="no"></iframe>
                        <p><a href={SkillsP} target="_blank" rel="nopener noreferrer">Download Presentation</a></p>
                        <br/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MySkills