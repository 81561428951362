import React from 'react';
import { Link } from 'react-router-dom';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import P1Image1 from '../images/MyStudies-P1-Image1.jpg';
import P1Image2 from '../images/MyStudies-P1-Image2.jpg';
import P1Image3 from '../images/MyStudies-P1-Image3.jpg';
import P1Image4 from '../images/MyStudies-P1-Image4.png';
import P1Image5 from '../images/MyStudies-P1-Image5.png';
import P1Image6 from '../images/MyStudies-P1-Image6.png';
import P1Image7 from '../images/MyStudies-P1-Image7.png';
import P1Image8 from '../images/MyStudies-P1-Image8.png';
import P1Image9 from '../images/MyStudies-P1-Image9.png';
import P1Image10 from '../images/MyStudies-P1-Image10.png';
import P1Image11 from '../images/MyStudies-P1-Image11.png';
import P2Image1 from '../images/MyStudies-P2-Image1.jpg';
import P2Image2 from '../images/MyStudies-P2-Image2.jpg';
import P2Image3 from '../images/MyStudies-P2-Image3.png';
import P2Image4 from '../images/MyStudies-P2-Image4.png';
import P2Image5 from '../images/MyStudies-P2-Image5.png';
import P2Image6 from '../images/MyStudies-P2-Image6.png';
import P2Image7 from '../images/MyStudies-P2-Image7.png';
import P2Image8 from '../images/MyStudies-P2-Image8.png';
import P2Image9 from '../images/MyStudies-P2-Image9.png';
import P2Image10 from '../images/MyStudies-P2-Image10.png';
import P2Image11 from '../images/MyStudies-P2-Image11.png';
import P2Image12 from '../images/MyStudies-P2-Image12.png';
import P2Image13 from '../images/MyStudies-P2-Image13.png';
import P2Image14 from '../images/MyStudies-P2-Image14.png';
import P2Image15 from '../images/MyStudies-P2-Image15.png';
import P2Image16 from '../images/MyStudies-P2-Image16.png';
import P2Image17 from '../images/MyStudies-P2-Image17.png';
import P2Image18 from '../images/MyStudies-P2-Image18.png';
import P2Image19 from '../images/MyStudies-P2-Image19.png';
import P2Image20 from '../images/MyStudies-P2-Image20.png';
import P2Image21 from '../images/MyStudies-P2-Image21.png';
import P2Image22 from '../images/MyStudies-P2-Image22.jpg';
import P2Image23 from '../images/MyStudies-P2-Image23.jpg';
import P2Image24 from '../images/MyStudies-P2-Image24.jpg';
import P2Image25 from '../images/MyStudies-P2-Image25.jpg';
import P2Image26 from '../images/MyStudies-P2-Image26.jpg';
import P3Image1 from '../images/MyStudies-P3-Image1.jpg';
import P3Image2 from '../images/MyStudies-P3-Image2.jpg';
import P3Image3 from '../images/MyStudies-P3-Image3.png';
import BonusImage1 from '../images/MyStudies-Bonus-Image1.png';
import BonusImage2 from '../images/MyStudies-Bonus-Image2.png';
import BonusImage3 from '../images/MyStudies-Bonus-Image3.png';
import BonusImage4 from '../images/MyStudies-Bonus-Image4.png';


const Studies = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"My Studies"} area={"booktwo"}/>
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Task 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light">Dedicated training time</div>
                    <div className="badgePageItemEvidence">
                        <p>My Studies seems almost a no-brainer for me, because I am always learning! However, it's harder to demonstrate consistant learning as required by this clause as I have a rather scattergun approach to my learning and cover a lot of subjects! All the details can be found on my <a href="https://ameliaraine.me.uk/training" target="_blank" rel="noopener noreferrer">Training</a> page of my main website!</p>
                        <br/><p>For this badge I am using my recent studies around AI.</p>
                        <br/><p>With ChatGPT everywhere in the news these days, it's important to keep up with AI developments. In my line of work in IT, it can be really hard to keep on top of everything, so sometimes you have to dedicate more resource to things that aren't directly work related, but that could influence decisions and choices.</p>
                        <br/>
                        <p>I have more use for AI outside of work than at work currently and that's why I chosen to use this to cover this badge.</p>
                        <br/>
                        <p>I have been taking as many courses as possible to cover chat based topics and image generation, combined with social media courses so that I can combine the two for various projects.</p>
                        <br/><p>Here are some of the courses I have taken - tutor led, online and over the hours required by the clause.</p>
                        <ul className="list">
                            <li>AI 101 fundamentals for managers & leaders - Udemy</li>
                            <li>ChatGPT Content Writing Masterclass - Prompt Engineering - Udemy</li>
                            <li>Dall-E Mastery - Create Impressive AI Art - Udemy</li>
                            <li>Adobe Firefly: A Guide to AI Art, Generative AI, Photoshop - Udemy</li>
                            <li>Learn Midjourney for your future 6 figure career - Udemy</li>
                            <li>Introduction to Stable Diffusion for Developers & Designers - Udemy</li>
                            <li>Master Class: Open AI Chat GPT Capabilities, Use Cases and Integrations - Fast Lane</li>
                            <li>Social Media Marketing - New Skills Academy</li>
                        </ul>
                        <br/>
                    </div>
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Task 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light">Consuming other resources</div>
                    <div className="badgePageItemEvidence">
                        <p>As part of ongoing studies, I use other resources to continue learning,including books, ebooks and podcasts. Here a couple of things I have read or listened to recently:</p>
                        <br/><ul className="list">
                            <li>YouTube Video:  Generative AI Is About To Reset Everything, And, Yes It Will Change Your Life - https://youtu.be/WY518YRfs5M</li>
                            <li>e-book: Ethical Artificial Intelligence - https://arxiv.org/ftp/arxiv/papers/1411/1411.1373.pdf</li>
                            <li>e-book: The Essential AI Handbook for Leaders - https://aihubtest-bucket.s3.eu-north-1.amazonaws.com/public/storage/resources/7yjR7QQgSCiU0KxqZ3nQKHmE0U6ww7b9V2vY28Ju.pdf</li>
                            <li>Podcast Episode: Google DeepMind Co-founder: AI Could Release A Deadly Virus - It's Getting More Threatening! Mustafa Suleyman - The Diary of a CEO with Steven Bartlett</li>
                        </ul>
                        <br/>
                        <p>I am also subscribed to various email newsletters that cover AI topics, including:</p>
                        <ul className='list'>
                            <li>TLDR Newsletter</li>
                            <li>Superhuman</li>
                        </ul>
                        <br/><p>My browser home page is set to Daily.Dev which covers all kinds of topics that I can click through and read.</p>
                        <br/>
                    </div>
                </div>
            </div>          
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Task 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light">Putting it into practice</div>
                    <div className="badgePageItemEvidence">

                        <h3>Project 1</h3>
                        <p>Take a look at my site image in the very top left of the site. Not all is as it seems!  But what is real and what isn't?</p>
                        <br/><p>No, it's not my hair that's fake, it really is that colour (well it's technically 7 colours because I am a living rainbow, but you can only see two here!)</p>
                        <br/>
                        <p>I decided it was time for a better head shot so I was messing around taking selfies one afternoon and captured one of probably about 2000 that I actually thought was ok!</p>
                        <p>I started making gentle edits to improve the lighting and got to a point where I was happy. However, I realised just before I uploaded that I hadn't put lipstick on before I took the photo and - pale as I am - it gave me a little washed-out look.</p>
                        <p>OK, so adding lipstick was more Photoshop than AI, but then I thought it would be fun to play with backgrounds!</p>
                        <br/>
                        <p>This is where AI came in: generative fill.</p>
                        <br/><p>I produced a series of images with different backgrounds. I selected a favourite - and got a valuable second opinion! - and it is now the image used across my professional workspaces.</p>
                        <br/>
                        <p>This is the image that I was using as a headshot before. Nothing wrong with it, but it is super zoomed in and as I spend much more time being virtual these days I thought that something a little less close up would be better.</p>
                        <img className='galleryItem' src={P1Image1} alt="A close up selfie" />
                        <br/>
                        <p>This is the image that I took. I am neither happy nor comfortable to be in front of a camera or sharing images, but it's not bad as selfies go. 
                        Yes, that's an actual black wall behind me in my house!<br/>I can't believe I've actually put this here. Moving on. Quickly.</p>
                        <img className='galleryItem' src={P1Image2} alt="A selfie taken against a black wall" />
                        <br/>
                        <p>I started with basic photo editing on my phone, changing the brightness, saturation, ambience etc.</p>
                        <p>Next, I loaded it into Photoshop and did some basic editing. I was wearing a hoodie, so I got rid of the bits of hood and zip that were visible.</p>
                        <p>Then I worked on my lipstick. I think I toned it down a bit after the first one, but it's very close to actual colours that I wear!</p>
                        <br/>
                        <p>Here's what I had after that:</p>
                        <div className='gallery'>
                            <div className='galleryItem'><img src={P1Image3} alt="Selfie edited in Snapseed to improve overall look" /></div>
                            <div className='galleryItem'><img src={P1Image4} alt="Selfie edited in Photoshop to remove clothing elements and add lipstick" /></div>
                        </div>
                        <br/>
                        <p>I then loaded the Photoshop Beta version and used the Generative Fill options to replace my background! I don't recall the exact prompt I used, but it was along the lines of "office environment with plain background".</p>
                        <br/><p>Here's what happened:</p>
                        <div className='gallery'>
                            <div className='galleryItem'><img src={P1Image5} alt="Selfie with auto generated background" /></div>
                            <div className='galleryItem'><img src={P1Image6} alt="Selfie with auto generated background" /></div>
                            <div className='galleryItem'><img src={P1Image7} alt="Selfie with auto generated background" /></div>
                            <div className='galleryItem'><img src={P1Image8} alt="Selfie with auto generated background" /></div>
                            <div className='galleryItem'><img src={P1Image9} alt="Selfie with auto generated background" /></div>
                            <div className='galleryItem'><img src={P1Image10} alt="Selfie with auto generated background" /></div>
                            <div className='galleryItem'><img src={P1Image11} alt="Selfie with auto generated background" /></div>
                        </div>
                        <br/>
                        <p>The chosen image was the last one in the gallery - the lighting looked good against my hair (it's all about the rainbow)!!</p>
                        <br/><br/>
                        <h3>Project 2</h3>
                        <br/>
                        <p>I needed to put my ChatGPT and image generation skills to the test and I had the perfect excuse.</p>
                        <br/><p>I run a page on social media for Plastic Free Highcliffe. I have a regular (kind of!) slot called "Plastic Free Fridays", but I quite often struggle to come up with content. I had however made a list a while ago of potential topics.</p>
                        <br/>
                        <p>So, I started using a combination of Bing's Dall-E image generator and ChatGPT to generate some content.</p>
                        <br/><p>In fact, I have also used the image generator for my Scientist badge!! Check out Sami Squirrel's Super Science from my <Link to="/scientist">Scientist page</Link> - Sami was generated using prompts in Dall-E! </p>
                        <br/><p>Back in plastic free land - I looked at my topic list and started working on prompts.</p>
                        <br/><p>It was mid-July when I started looking at it, so I went with the topic of plastic free suncream. Unfortunately my history won't go back far enough to allow me to pick up all the generated images and the prompts so I can only show the final product for this one.</p>
                        <br/><p>Iasked for an image of a plastic suncream bottle in the ocean and a picture of a happy coral reef. This is what I got: </p>
                        <img className="galleryItem" src={P2Image1} alt="" /><img className="galleryItem" src={P2Image2} alt="" />
                        <p>I put those together into a short video using Microsoft's ClipChamp.</p>
                        <br/><p>Next, I used ChatGPT to create the content to go with the video. The prompt I used and the response are in the image below:</p>
                        <img src={P2Image3} alt="" className="galleryItem" />
                        <br/>
                        <p>You can see the finished post on the facebook page here: <a href="https://fb.watch/nXw78FcVkY/" target="_blank" rel="noopener noreferrer" className="linkUnderline">https://fb.watch/nXw78FcVkY/</a></p>
                        <br/><br/>
                        <p>I have done more similar posts now, although I changed the format to have one image of plastic items and one image of non-plastic items. For example, toothbrush and toothpaste and plastic cleaning items. </p>
                        <br/><p>Here are some examples:</p>
                        <br/>
                        <p>Prompt 1: Plastic cleaning brushes, cloths and sponges in a photo realistic style</p>
                        <p>Prompt 2: Plastic free cleaning brushes, cloths and sponges in a photo realistic style</p>
                        <div className='gallery'>
                            <div className='galleryItem'><img src={P2Image4} alt="" /> </div>
                            <div className='galleryItem'><img src={P2Image5} alt="" /></div>
                        </div>
                        <br/><br/>
                        <p>Clearly I struggled getting an image I was happy with for this one as I tried multiple prompts. I found that Dall-E had strange ideas about brushes!! The first image I no longer have the prompt for.</p>
                        <p>Prompt 1: wood, bamboo and plastic-free cloths and sponges in a photo realistic style</p>
                        <p>Prompt 2: plastic-free cloths and sponges with wood scrubbing brushes in a photo realistic style</p>
                        <p>Prompt 3: bamboo cleaning cloths, plastic-free sponges and wood scrubbing brushes in a photo realistic style</p>
                        <p>Prompt 4: soft cleaning cloths made from bamboo, cleaning sponges made with natiral fibres and scrubbing brishes made from wood in a plastic free photo realistic style</p>
                        <p>Prompt 5: eco friendly sponges, cloths and brushes in a photo realistic style</p>
                        <div className='gallery'>
                            <div className='galleryItem'><img src={P2Image24} alt=""/></div>
                            <div className='galleryItem'><img src={P2Image6} alt=""/></div>
                            <div className='galleryItem'><img src={P2Image7} alt=""/></div>
                            <div className='galleryItem'><img src={P2Image8} alt=""/></div>
                            <div className='galleryItem'><img src={P2Image9} alt=""/></div>
                            <div className='galleryItem'><img src={P2Image10} alt=""/></div>
                        </div>
                        <br/><br/>
                        <p>Prompt 1: a photo of cing film and foil rolls on a kitchen side in a cartoon style</p>
                        <p>Prompt 2: a photo of beeswax wraps, reusable containers and silicon lids on a kitchen side in a cartoon style</p>
                        <p>Prompt 3: a photo of beeswax wrap cloths, reusable containers and silicon bowl covers on a kitchen side in a cartoon style</p>
                        <div className='gallery'>
                            <div className='galleryItem'><img src={P2Image11} alt=""/></div>
                            <div className='galleryItem'><img src={P2Image12} alt=""/></div>
                            <div className='galleryItem'><img src={P2Image13} alt=""/></div>
                        </div>
                        <br/><br/>
                        <p>Prompt 1: A photo of laundry liquids and powders in plastic bottles and tubs in a laundry room with a washing machine</p>
                        <p>Prompt 2: A photo of laundry sheets, soap nuts and plant-based pods in a laundry room with a washing machine</p>
                        <p>Prompt 3: A photo of laundry soap sheets, soap nuts and washing tablets in a laundry room with a washing machine</p>
                        <div className='gallery'>
                            <div className='galleryItem'><img src={P2Image14} alt=""/></div>
                            <div className='galleryItem'><img src={P2Image15} alt=""/></div>
                            <div className='galleryItem'><img src={P2Image16} alt=""/></div>
                        </div>
                        <br/><br/>
                        <p>These are earlier ones that I no longer have the alternatives or prompts for. The toothbrush and toothpaste is my favourite one!!</p>
                        <div className='gallery'>
                            <div className='galleryItem'><img src={P2Image22} alt=""/></div>
                            <div className='galleryItem'><img src={P2Image23} alt=""/></div>
                            <div className='galleryItem'><img src={P2Image25} alt=""/></div>
                            <div className='galleryItem'><img src={P2Image26} alt=""/></div>
                        </div>
                        <br/><br/>
                        <p>Here are some examples of my ChatGPT prompts, including where I have modified to summarise for different channels.  Sometimes I modify them and add to them, other times I will use them as they are.</p>
                        <div className='gallery'>
                            <div className='galleryItem'><img src={P2Image17} alt=""/></div>
                            <div className='galleryItem'><img src={P2Image18} alt=""/></div>
                            <div className='galleryItem'><img src={P2Image19} alt=""/></div>
                            <div className='galleryItem'><img src={P2Image20} alt=""/></div>
                            <div className='galleryItem'><img src={P2Image21} alt=""/></div>
                        </div>
                        <br/><br/>
                        <p>All of the resulting videos and content can be found on my public <a href="https://www.facebook.com/PlasticFreeHighcliffeOnSea" target="_blank" rel="noopener noreferrer">Plastic Free Highcliffe Facebook page</a>.</p>
                        <br/><br/>
                        <h3>Project 3 - Art</h3>
                        <p>This is just a small one. I joined a global art project where I had to paint a small tile to join a mosaic.  Freestyle drawing is not something I am yet good at, so I planned my concept and used a prompt to generate the image I wanted to use. Then I painted the picture in acrylic. </p>
                        <br/><br/>
                        <p>I was planning to use Midjourney, so I used ChatGPT to generate a Midjourney prompt for my artwork.</p>
                        <div className='gallery'>
                            <div className='galleryItem'><img src={P3Image3} alt=""/></div>
                        </div>
                        <p>It was interesting, but ultimately I didn't use it.</p>
                        <p>I tried the Imagine app on my phone, but it didn't matter what prompt I put in, I could not get a front-on view of beach huts!</p>
                        <p>So, I went back to Bing. Here's the generated image and my painted interpretation.</p>
                        <div className='gallery'>
                            <div className='galleryItem'><img src={P3Image1} alt=""/></div>
                            <div className='galleryItem'><img src={P3Image2} alt=""/></div>
                        </div>
                        <br/><br/><br/>
                        <h3>Bonus Content</h3>
                        <p>I wanted a 5th birthday cake for my beach cleaning page - what I learned from this is that image generators cannoy count to 5, but eventually I got a good picture!</p>
                        <br/><p>Finally, here are the frankly disturbing images that I originally got for Sami Squirrel!! Dall-E had an odd interpretation of "excited" but I finally got something that didn't look like it wanted to kill me!!  Using digital art worked where comic style failed.</p>
                        <br/><p>Prompt 1: a birthday cake with an ocean theme and five candles, on a beach</p>
                        <p>Prompt 2: an excited, happy squirrel in a kitchen, dressed as a scientist wearing a white lab coat and black rimmed glasses, holding a book of experiments in a comic style</p>
                        <p>Prompt 3: an excited, happy squirrel in a kitchen, dressed as a scientist wearing a white lab coat and black rimmed glasses, holding a book of experiments</p>
                        <p>Prompt 4: a happy squirrel in a kitchen, dressed as a scientist wearing a white lab coat and black rimmed glasses, holding a book of experiments, digital art</p>
                        <div className='gallery'>
                            <div className='galleryItem'><img src={BonusImage1} alt=""/></div>
                            <div className='galleryItem'><img src={BonusImage2} alt=""/></div>
                            <div className='galleryItem'><img src={BonusImage3} alt=""/></div>
                            <div className='galleryItem'><img src={BonusImage4} alt=""/></div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default Studies