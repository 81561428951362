import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Cert from '../images/MyTalents-Cert.png';

const MyTalents = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"My Talents"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">01</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light">Good at and improvements</div>
                    <div className="badgePageItemInfo reverse hidden">Write a list of 5 things you're good at, and 5 things you'd like to improve on.</div>
                    <div className="badgePageItemEvidence">
                        <table>
                            <thead>
                                <tr>
                                    <th>Good At</th>
                                    <th>Improve</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Coding</td>
                                    <td>Photography</td>
                                </tr>
                                <tr>
                                    <td>Craft</td>
                                    <td>Drawing</td>
                                </tr>
                                <tr>
                                    <td>Baking</td>
                                    <td>Cooking</td>
                                </tr>
                                <tr>
                                    <td>Organisation / Planning / Admin</td>
                                    <td>Design</td>
                                </tr>
                                <tr>
                                    <td>Piano</td>
                                    <td>Guitar</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">02</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light">Help others</div>
                    <div className="badgePageItemEvidence">
                        <p>I have, on many occasions, shared my skillsets with other people to help them with websites, databases or design work. Often this has been voluntary, sometimes for a small fee or gift.</p>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">03</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light">Share a talent</div>
                    <div className="badgePageItemEvidence">
                        <p>Craft is another of my talents that I have shared over many years. I have made gifts and I have made to sell. I have also taught others how to make and do things. I have my own craft store to sell the items I make, although this is currently going through a re-brand.</p>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">04</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light">Improvements</div>
                    <div className="badgePageItemEvidence">
                        <p>For this, I am looking to improve my design work. I am doing this by using the 365 Days of Creativity course that I am doing as part of my Silver Maverick.</p>
                        <br/>
                        <table>
                            <thead>
                                <tr>
                                    <th>SMART</th>
                                    <th>Detail</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Specific</td>
                                    <td>Focus on improving design skills by learning more about Adobe tools</td>
                                </tr>
                                <tr>
                                    <td>Measurable</td>
                                    <td>Complete first three months of design course and complete first three assignments</td>
                                </tr>
                                <tr>
                                    <td>Achievable</td>
                                    <td>Course is broken down into daily bite-size sections, making the three month target easily achievable</td>
                                </tr>
                                <tr>
                                    <td>Relevant</td>
                                    <td>Relevant to all design work for both work and hobby purposes</td>
                                </tr>
                                <tr>
                                    <td>Time-Bound</td>
                                    <td>Clause restricts to 3 months. Restricted time each day as each daily section is 15 minutes or less.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">05</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light">Learn</div>
                    <div className="badgePageItemInfo reverse hidden">Choose one of the 5 things on your improvement list and take a class, read a book or download an app which will help you with that skill.</div>
                    <div className="badgePageItemEvidence">
                        <p>For this I have taken the Introduction to Branding diploma course with Centre of Excellence. Here's my certificate:</p>
                        <br/>
                        <img src={Cert} alt="Certificate" />
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">06</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light">Praise for others.</div>
                     <div className="badgePageItemInfo reverse hidden">Think about your friends and family. What are their skills and talents? Actively be more vocal praising others for their skills.</div>
                    <div className="badgePageItemEvidence">
                        <p>Can't evidence this, but this is something I always do anyway. </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyTalents