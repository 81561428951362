import React from 'react';
import { Link } from 'react-router-dom';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Poll from '../images/lifelessordinary1.jpg';

const LifeLessOrdinary23 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Life Less Ordinary"} />
            <p><strong>This page provides my evidence for the second 2023 quarterly badge - Life Less Ordinary 2023.  The information for this badge is available publicly on the <a className="linkUnderline" href="https://www.rebelbadgestore.com/rebelblog/t55srab6z6gzrh9y9o8zzoyiuesg9u" target="_blank" rel="noopener noreferrer">blog</a> so descriptions haven't been removed.</strong></p>
            <br/>
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Clause 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light">Complete "My Story"</div>
                    <div className="badgePageItemEvidence"> 
                       
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Clause 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light">Complete 1 Global Badge</div>
                    <div className="badgePageItemEvidence"> 
                       <p>For this clause I have selected the World Traveller badge. You can follow my progress on my <Link to="/worldtraveller" className="linkUnderline">World Traveller page</Link>.</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Clause 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light">Complete a badge from the section of the badge book which you have completed the least from so far</div>
                    <div className="badgePageItemEvidence"> 
                        <p>Wild Rebel is the section I have completed least from so far, so for this clause I have selected Nature Lover. You can follow my progress on my <Link to="/naturelover" className="linkUnderline">Nature Lover page</Link>. </p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Clause 4</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light">Let someone else choose a badge for you to complete</div>
                    <div className="badgePageItemEvidence"> 
                        <p>I decided to put it to a poll for my family to decide! I couldn't give them a list of everything, so I narrowed it down to three badges that I hadn't started and felt were achievable within three months.</p>
                        <br/><br/>
                        <img className='imgThird' src={Poll} alt="Screenshot of a poll sent to family members via messaging app" />
                        <br/><br/>
                        <p>So, I guess I'm doing my Designer badge! You can follow my progress on my <Link className="linkUnderline" to="/designer">Designer page</Link>.</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Clause 5</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light">Write yourself a list of at least 25 things you'd like to do in your life. Complete one.</div>
                    <div className="badgePageItemEvidence"> 
                        <p>I'm not going to share this list here. You'll have to trust that I have done it.</p>
                        <br/>
                        <p>However, one thing I have always wanted to do is learn how to use a digital camera properly, with manual settings, so I completed a course. Details are on my <Link to="/photographer" className="linkUnderline">Photographer</Link> page.</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Clause 6</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light">Once a week, for 12 weeks, do something you've never done before.</div>
                    <div className="badgePageItemEvidence"> 
                        Well, maybe not particularly exciting, but a few things I hadn't done before:
                        <br/><br/>
                        <ul className="list">
                            <li>Sculpture with modelling chocolate</li>
                            <li>Converting / upcycling clothing</li>
                            <li>Sculpture of mother earth with salt dough</li>
                            <li>Making my own nightwear</li>
                            <li>Experiencing music with haptic feedback, courtesy of Woojer</li>
                            <li>Flower arranging with fake flowers</li>
                            <li>Using AI to generate images to accompany writing</li>
                            <li>Meditation with the Atom app</li>
                            <li>Sewing children's clothing using stretch velvet!</li>
                            <li>Making quiche for the coronation</li>
                            <li>Making flowers using crepe paper</li>
                            <li>Using a sit to stand desk for home working</li>
                        </ul>
                        <br/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LifeLessOrdinary23