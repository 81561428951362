import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Nelson1 from '../images/Nelson1.jpg'
import Nelson2 from '../images/Nelson2.jpg'

const Nelson23 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Portrait of Nelson Mandela"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Create a picture of your Patrol leader</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">For one-off points for the Rebel Cup</div>
                    <div className="badgePageItemEvidence"> 
                        <p>I joined Team Nelson. I wasn't sure which team to join but Nelson had the least members so I jumped on board.</p>
                        <p>Drawing is something I can do and enjoy doing so this was an easy 3 points.</p>
                        <br/><br/>
                        <img src={Nelson1} alt="Pencil portrait of Nelson Mandela" />
                        <br/>
                        <img src={Nelson2} alt="Pencil portrait of Nelson Mandela next to the reference" />
                        <br/>
            
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Nelson23