import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import SoilJar1 from '../images/gardener-soil1.jpg';
import SoilJar2 from '../images/gardener-soil2.jpg';
import SoilJar3 from '../images/gardener-soil3.jpg';
import Bee1 from '../images/gardener-beefriendly.jpg';
import Bulbs1 from '../images/gardener-bulbs.jpg';
import Bulbs2 from '../images/gardener-bulbs2.jpg';
import Bulbs3 from '../images/gardener-bulbs3.jpg';
import Bulbs4 from '../images/gardener-bulbs4.jpg';
import Bulbs5 from '../images/gardener-bulbs5.jpg';
import Bulbs6 from '../images/gardener-bulbs6.jpg';
import Bulbs7 from '../images/gardener-bulbs7.jpg';
import Bulbs8 from '../images/gardener-bulbs8.jpg';
import SeedsChilli1 from '../images/gardener-seeds1.jpg';
import SeedsChilli2 from '../images/gardener-seeds2.jpg';
import SeedsChilli3 from '../images/gardener-seeds3.jpg';
import SeedsChilli4 from '../images/gardener-seeds4.jpg';
import Maintenance1 from '../images/gardener-maintenance1.jpg';
import Maintenance2 from '../images/gardener-maintenance2.jpg';
import Cultivate1 from '../images/gardener-cultivate1.jpg';
import Cultivate2 from '../images/gardener-cultivate2.jpg';
import Cultivate3 from '../images/gardener-cultivate3.jpg';
import Cultivate4 from '../images/gardener-cultivate4.jpg';
import Cultivate5 from '../images/gardener-cultivate5.jpg';
import Tulip1 from '../images/gardener-tulip1.jpg';
import Tulip2 from '../images/gardener-tulip2.jpg';
import Frit1 from '../images/gardener-fritillaria1.jpg';
import Seeds1 from '../images/gardener-seeds1.jpg';

const Gardener = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Gardener"} />
            <div className="badgePageItem">
                <summary className="badgePageItemTitle olive">01</summary>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Cultivate</div>
                    <div className="badgePageItemInfo reverse hidden">Cultivate an outdoor area for at least six months.</div>
                    <div className="badgePageItemEvidence">
                        <p>As I am limited to what I can do in my own garden, I try and help Mum with hers occassionally as well. She did call for help clearing a patch in the front so I went over armed with my gloves and secateurs for a working party!</p>
                        <br/>
                        <p>We cut back all the ivy growing up the wall of the house to keep it under control.</p>
                        <br/>
                        <img className="imgThird" src={Cultivate1} alt="Ivy growing up a wall" />&nbsp;
                        <img className="imgThird" src={Cultivate2} alt="Cutting back ivy with secateurs" />&nbsp;
                        <img className="imgThird" src={Cultivate3} alt="Clearing ivy from a wall" />&nbsp;
                        <img className="imgThird" src={Cultivate4} alt="Dead-heading and clearing a plant" />&nbsp;
                        <img className="imgThird" src={Cultivate5} alt="Mushrooms growing in wood chips in a cleared garden area" />&nbsp;
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <summary className="badgePageItemTitle olive">02</summary>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Tend</div>
                    <div className="badgePageItemInfo reverse hidden">Regularly tend to your outdoor area - mowing, weeding, dead-heading, feeding.</div>
                    <div className="badgePageItemEvidence">
                        <p>My garden suffers a lot from weeds, even though it was supposed to be a low-maintenance solution! So, it requires regular weeding.</p>
                        <br/>
                        <p>Here's a typical before and after:</p>
                        <br/>
                        <img className="imgThird" src={Maintenance1} alt="Garden requiring weeding" />&nbsp;
                        <img className="imgThird" src={Maintenance2} alt="Garden after weeding" />
                        <br/><br/>
                        <p>There are limited things that I can do to satisfy this clause as my outdoor space is manufactured for low maintenance at the moment. It will need weeding again as spring 2023 progresses and I will be able to do some maintenance in the herb basin.</p>
                        <br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <summary className="badgePageItemTitle olive">03</summary>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Annual flowering plants and fruits or vegetables</div>
                    <div className="badgePageItemInfo reverse hidden">Grow at least 3 different annual flowering plants and 3 different fruits or vegetables.</div>
                    <div className="badgePageItemEvidence">
                        Well, I've selected my victims, let's see how this goes! 
                        <br/>
                        Note: I'm aware that chives are technically a plant, but they are filed under vegetables on the website so I'm going with it!
                        <br/><br/>
                        <table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Photo</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Lathyrus odoratus "Midnight"</td>
                                    <td>Annual from Seed</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Orlaya Grandiflora</td>
                                    <td>Annual from Seed</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Visnaga daucoides 'Green Mist'</td>
                                    <td>Annual from Seed</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Spring Onion</td>
                                    <td>Vegetable from Seed</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Chillis</td>
                                    <td>Vegetable from Seed</td>
                                    <td>
                                        <img className="imgThird" src={SeedsChilli1} alt="Chilli plants grown from seed." />
                                        <img className="imgThird" src={SeedsChilli2} alt="Chilli plants grown from seed." />
                                        <img className="imgThird" src={SeedsChilli3} alt="Chilli plants grown from seed." />
                                        <img className="imgThird" src={SeedsChilli4} alt="Chilli plants grown from seed, growing and re-potted into larger pots." />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Chives</td>
                                    <td>Vegetable from Seed</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Coffee</td>
                                    <td>From beans</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <summary className="badgePageItemTitle olive">04</summary>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Seeds and bulbs</div>
                     <div className="badgePageItemInfo reverse hidden">Grow at least 3 different plants from a seed. Plant at least 3 types of bulb.</div>
                    <div className="badgePageItemEvidence">
                    All of my vegetables and annuals are seeds, so I'm using those to satisfy the Grow from Seed part of this clause.
                    <br/><br/>
                    I have selected some bulbs to plant in a pot that I have - my Mum will be amazed, she likes to pick on my empty pot and laugh at me every time she comes round!
                    <br/><br/>
                    The bulbs I have picked are for winter planting and spring/summer flowering. I had some left over so I stuck them in a pot in Mum's garden! I've got the photo here so I know which pot has my bulbs in when they start sprouting!
                    <br/><br/>
                    <br/><br/>
                    5/5/2023: I planted my seeds today, they are inside at the moment as I don't have a greenhouse, so the end of my dining table has been turned into a nursery temporarily - it has a lot on light and sun there!
                    <br/><br/>
                    <img className="imgThird" src={Bulbs1} alt="Freshly planted bulbs" />&nbsp;
                    <img className="imgThird" src={Bulbs2} alt="Sprouting bulbs" />
                    <img className="imgThird" src={Bulbs4} alt="Sprouting bulbs" />
                    <img className="imgThird" src={Bulbs3} alt="Freshly planted bulbs" />
                    <img className="imgThird" src={Bulbs5} alt="Freshly planted bulbs" />
                    <img className="imgThird" src={Bulbs6} alt="Flowering bulbs" />
                    <img className="imgThird" src={Bulbs8} alt="Flowering bulbs" />
                    <img className="imgThird" src={Bulbs7} alt="Flowering bulbs" />
                    <img className="imgThird" src={Seeds1} alt="Seeds trays with fresh compost and newly planted seeds on a table" />
                    <br/>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Photo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Tulip, Queen of Night</td>
                                <td>Bulb</td>
                                <td>
                                    <img className="imgThird" src={Tulip1} alt="Close up of a black tulip" />
                                    <img className="imgThird" src={Tulip2} alt="Close up of a black tulip" />
                                </td>
                            </tr>
                            <tr>
                                <td>Fritillaria Snake's head</td>
                                <td>Bulb</td>
                                <td><img className="imgThird" src={Frit1} alt="Close up of a Fritillaria flower in bud" /></td>
                            </tr>
                            <tr>
                                <td>Allium Bulgaricum</td>
                                <td>Bulb</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Lathyrus odoratus "Midnight"</td>
                                <td>Annual from Seed</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Orlaya Grandiflora</td>
                                <td>Annual from Seed</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Visnaga daucoides 'Green Mist'</td>
                                <td>Annual from Seed</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>

                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <summary className="badgePageItemTitle olive">05</summary>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Equipment and storage</div>
                    <div className="badgePageItemInfo reverse hidden">What equipment do you need to maintain your garden? How do you store it to keep it safe and clean?</div>
                    <div className="badgePageItemEvidence">
                        The only equipment that I need is a little trowel. I only have one flower bed at the front of the house and it's not large, it doesn't require a lot of work.
                        <br/><br/>
                        I have an old ceramic sink on a cast iron base in the back garden where I'll be attempting to grow my veg, this only needs a little trowel.
                        <br/><br/>
                        I do have a small lawn at the front of the house and I have a strimmer and Flymo lawn-mower to look after that.
                        <br/><br/>
                        I have two areas of storage - a Keter storage shed and small a storage box for smaller items such as rubble bags. This keeps everything protected from the weather.
                        <br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <summary className="badgePageItemTitle olive">06</summary>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Soil and fertilisers</div>
                    <div className="badgePageItemInfo reverse hidden">What type of soil do you have in your area? What are the advantages and disadvantages of it? How can you improve it? Which manures and fertilisers might benefit your crops?</div>
                    <div className="badgePageItemEvidence">
                        OK, I don't have a clue what the type of soil is here! I have, however, found a useful website that is going to help me test it.
                        <br/><br/>
                        Here's the article: <a className="linkUnderline" href="https://www.nature-and-garden.com/gardening/identify-type-of-garden-soil.html" target="_blank" rel="noopener noreferrer">How to determine the type of soil in your garden</a>
                        <br/><br/>
                        I'm off to find a bottle and a trowel, BRB!!
                        <br/><br/>
                        Step one complete ... one jar of soil and water waiting to settle!
                        <br/>
                        <img className="imgThird" src={SoilJar1} alt="Glass jar containing soil and water that has been shaken and waiting to settle" />
                        <br/><br/>
                        <p>OK, it's settling</p>
                        <br/><br/>
                        <img className="imgThird" src={SoilJar2} alt="Glass jar containing soil and water that has started to settle" />
                        <img className="imgThird" src={SoilJar3} alt="Glass jar containing soil and water that has settled" />

                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <summary className="badgePageItemTitle olive">07</summary>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Bees</div>
                     <div className="badgePageItemInfo reverse hidden">Research the reasons why bee protection is so important to our environment. Plant at least one bee-friendly plant in your garden.</div>
                    <div className="badgePageItemEvidence">
                        I picked a type of lavender for my garden as I know bees love that (and so does my daughter!)
                        <br/><br/>
                        It's called "Lavandula angustifolia beezee pink ('Kerbeepink')" and is a new fully hardy plant.
                        <br/><br/>
                        <img className="imgHalf" src={Bee1} alt="Newly planted lavender plant" />
                        <br/><br/>
                        <strong>The need for bees</strong>
                        <br/><br/>
                        In short, bees are vital because of the pollination work that they do. They pollinate food crops as well as flowering plants and are responsible for producing one third of our food supply! Without bees, a lot of our food chain would be missing.
                        <br/><br/>
                        Plants and flowers provide essential food and shelter for a lot of wildlife and without bees these wouldn't grow.
                        <br/><br/>
                        According to the <a href="https://foe.org/blog/why-are-bees-important/" target="_blank" rel="noopener noreferrer">Friends of the Earth website</a>, they also:
                        <br/>
                        <ul className="list">
                            <li>Help provide ½ of the world's fibers, oils, and other raw materials</li>
                            <li>Help create many medicines</li>
                            <li>Provide food for wildlife</li>
                            <li>Help prevent soil erosion</li>
                        </ul>
                        <br/><br/>
                        Bees are under threat from humans, because of the pesticides and other chemicals that we use. Many governments still allow chemicals in agriculture that are lethal for bees, in fact the UK government have recently voted to re-allow these chemicals. <a href="https://www.change.org/p/uk-goverment-stop-the-use-of-pesticides-save-the-bees" target="_blank" rel="noopener noreferrer">This petition can be found on Change.org</a>, please sign.
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <summary className="badgePageItemTitle olive">08</summary>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">First aid</div>
                    <div className="badgePageItemInfo reverse hidden">Know basic first aid for garden-related injuries - cuts, splinters, crushing and bruising and heat related conditions such as heatstroke and hypothermia.</div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div>
        </div>
    )
}

export default Gardener