import React from 'react';
import {Link} from 'react-router-dom';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const  Deeds24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Deeds Not Words"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>I completed both <Link to="/activist">Activist</Link> and <Link to="/rebelhistory">Rebel History</Link> in 2023.</p>
                    </div>
                </div>
            </div>
             <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>To satisfy this clause I have completed my <Link to="/politician">Politician</Link> badge.</p>
                    </div>
                </div>
            </div>
             <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>I completed the RNLI Mayday Mile for Rebel with a Cause.</p>
                    </div>
                </div>
            </div>
             <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 4</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>For this clause I have completed my <Link to="/farmer">Farmer</Link> badge.</p>
                    </div>
                </div>
            </div>
             <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 5</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>To meet this clause, I have done the following:</p>
                        <br/>
                        <ul>
                            <li>Started learning sign language</li>
                            <li>Assisted with a project on Zooniverse to digitise museum records</li>
                            <li>Completed the RNL Mayday mile, raising just over £125</li>
                            <li>Planted one tree for every day that I exercised during the period</li>
                            <li>Responded to another Rebel asking for Swedish speakers or learners to exchange a letter with.</li>
                        </ul>
                    </div>
                </div>
            </div>
             <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 6</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>I had been putting off creating the events I needed to set up for silver maverick! I've now started that section, set up events and even have some people signed up! The first events went ahead successfully.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Deeds24