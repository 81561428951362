import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Documentary1 from '../images/naturelover-documentary1.jpg';
import Documentary2 from '../images/naturelover-documentary2.jpg';
import Documentary3 from '../images/naturelover-documentary3.jpg';
import Pond1 from '../images/naturelover-pond1.jpg';
import Pond2 from '../images/naturelover-pond2.jpg';
import Pond3 from '../images/naturelover-pond3.jpg';
import Pond4 from '../images/naturelover-pond4.jpg';

const NatureLover = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"Nature Lover"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>Attracting wildlife to a garden is easier when it is allowed to be as wild as possible. Wildflowers are easy to grow and leaving the grass to grow is great for wildlife too.</p>
                        <p>Not every garden is large enough for trees, mine isn't, but if there is space it's a good idea to plant trees.</p>
                        <p>You can attract lots of different species by providing:</p>
                        <ul className="list">
                            <li>Water</li>
                            <li>Bug and bee boxes</li>
                            <li>Hedgehog houses and highways</li>
                            <li>Butterfly houses and feeders</li> 
                            <li>Bird boxes and feeders</li>
                            <li>Bat boxes</li>
                        </ul>
                        <br/>
                        <p>Leaving piles of dead wood somewhere shady is really good for beetles and grubs as well as fungi.</p>
                        <p>Composting helps to make your soil better so that things can grow.</p>
                        <p>Rock gardens can help mason bees</p>
                        <br/><br/>
                        <p>Sources:</p>
                        <ul className="list">
                            <li>https://www.woodlandtrust.org.uk/blog/2020/06/attract-wildlife-to-your-garden/</li>
                            <li>https://www.rhs.org.uk/science/conservation-biodiversity/wildlife/encourage-wildlife-to-your-garden</li>
                            <li>https://www.which.co.uk/news/article/nine-ways-to-attract-wildlife-to-your-garden-this-spring-aqgH13G6vN7D</li>
                        </ul>
                        <br/>
                    </div>
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>For this clause, I decided to go super simple and use the coconut shells that I had left over from July's Sundae challenge! A few fat balls make up the content.</p>    
                    </div>
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>I am very fortunate to have two nature reserves within walking distance!</p>
                        <br/>
                        <p><strong>Stanpit Marsh</strong></p>
                        <p>In one direction, there is Stanpit Marsh. This is a Site of Special Scientific Interest and has over 300 plant species and 313 bird species.</p>
                        <p>It has 5 different habitats: salt marsh, reed beds, freshwater marsh, gravel estuarine banks and sandy scrub.</p>
                        <p>There are different walks to do and there is an information centre on site. It also features a board walk, beautifully sculptured benches and even a wrecked boat!</p>
                        <p>There are gorgeous views across both the harbour and looking towards Christchurch Priory. You get the best of both worlds - the rivers and the sea.</p>
                        <br/>
                        <p>This link has some photos from around the site: https://www.dorsetwalks.co.uk/stanpit-marsh-christchurch/</p>
                        <br/><br/>
                        <p><strong>Steamer Point</strong></p>
                        <p>Steamer Point is a Local Nature Reserve and it stretches across the top of the cliff between Friars Cliff and Highcliffe Castle.</p>
                        <p>It has beautiful woodland and ponds, an information centre and stunning views across the bay. There are picnic areas and seating along the route.</p>
                        <p>It contains three habitats: Broadleaf woodland, ponds and grassland and is home to Nuthatches, woodpeckers, butterflies and fungi among other things.</p>
                    
                        <br/><br/>
                        <p>Both are favourites haunts for Mum and me when we go walking.</p></div>
                </div>
            </div>        
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 4</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>The three documentaries I watched for this clause were:</p>
                        <ul className="list">
                            <li>Love Thy Nature</li>
                            <li>Seasons of the Forest</li>
                            <li>Rivers - Lifeblood</li>
                        </ul>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Documentary1} alt="Love Thy Nature"/></div>
                            <div className="galleryItem"><img src={Documentary2} alt="Seasons of the Forest"/></div>
                            <div className="galleryItem"><img src={Documentary3} alt="Rivers - Lifeblood"/></div>
                        </div>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 5</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>With the nature reserves in the area, we have a lot of diversity in habitats!</p>
                        <p>Some of the habitats in the area are:</p>
                        <ul className="list">
                            <li>Tidal Mudflats - by the sea, uncovered at low tide</li>
                            <li>Marshland</li>
                            <li>Reed Bed - transitional and found between water and land</li>
                            <li>Open Water - fresh and saltwater</li>
                            <li>Shingle Bank</li>
                            <li>Broadleaf Woodland</li>
                            <li>Ponds</li>
                            <li>Grassland - upland (above 300m) and lowland</li>
                            <li>Salt Marsh</li>
                            <li>Freshwater Marsh</li>
                            <li>Gravel Estuarine Banks</li>
                            <li>Sandy Scrub</li>
                            <li>Marine - the sea and sea bed</li>
                            <li>Coastal - found where the land meets the sea, inc. Beaches and strandlines, sand dunes and maritime cliffs</li>
                        </ul>
                        <br/>
                        <p>In addition to this, we have the New Forest right on the border and that has other habitats, so we can add these too:</p>
                        <ul className="list">
                            <li>Farmland</li>
                            <li>Grassland</li>
                            <li>Heathland and Morrland</li>
                            <li>Woodland</li>
                        </ul>
                        <br/>
                        <p>We can add Towns and gardens as well. Probably most habitats except anything mountainous!</p>
                        <br/>
                        <p>Sources:</p>
                        <p>https://www.dorsetwildlifetrust.org.uk/habitats</p>
                        <p>https://www.wildlifetrusts.org/habitats</p>
                        <p></p>

                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 6</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>I went pond dipping when I was away on holiday with the family.</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Pond1} alt="Pond dipping net resting on an identification sheet at the side of a pond." /></div>
                            <div className="galleryItem"><img src={Pond2} alt="Shallow tray of water containing the content of a pond dipping net." /></div>
                            <div className="galleryItem"><img src={Pond3} alt="A snail on the palm of a hand." /></div>
                            <div className="galleryItem"><img src={Pond4} alt="A fish caught whilst pond dipping in a shallow tray of water." /></div>
                        </div>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 7</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>Another thing that I am very fortunate to have on my doorstep - a National Park!  The New Forest is not far along the road and I've spent a lot of time there throughout my life!</p>
                        <p>We've done many walks and gathered many pieces of wildlife, been mushroom hunting and climbed a lot of trees.</p>
                        <p>The New Forest is also a designated Royal Forest.</p>
                        <br/>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 8</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light"></div>
                    <div className="badgePageItemEvidence">
                    <p><strong>Respect, Protect, Enjoy</strong> - the motto of the countryside.</p>
                    <br/>
                    <p>It is important to be respectful of the countryside and to leave it in the condition that you find it - or better!</p>
                    <p>The animals should be left alone, gates closed behind you. Give room to animals and for other walkers and vehicles to pass.</p>
                    <p>Sadly, there are still a lot of BBQs around and litter is a problem. Fires should not be lit in the countryside, to protect the animals and other wildlife. All litter should be removed.</p>
                    <p>Dogs need to be on leads around farm animals and all dog mess should be cleared up.</p>
                    <p>There are plenty of sites that help you plan your visit, so make sure you know all about the area you are going to and any guidance specific to that area.</p>
                    <p>Camping is only allowed in designated camp sites.</p>
                    <br/>
                    <p>Horses: can be a real nuisance if you are having a picnic and will steal your food</p>
                    <p>Cows: be mindful, they charge (at you, not a fee!)</p>
                    <br/>
                    <p>Sources:</p>
                    <ul className="list">
                        <li>https://www.countrysideonline.co.uk/articles/respect-protect-enjoy-the-countryside-code/</li>
                        <li>https://www.gov.uk/government/news/new-countryside-code-launched-to-help-people-enjoy-the-outdoors</li>
                        <li>https://www.nationaltrust.org.uk/who-we-are/about-us/follow-the-countryside-code</li>
                    </ul>
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default NatureLover