import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const AdvancedAdulting = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"AdvancedAdulting"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light"></div>
                    <div className="badgePageItemEvidence">
                        Make a list of at least six household chores which you regularly put off, or wish you did more often. Over the next 6 months, commit to doing each of those
                        chores at least four times per month.

                        <p>I decided to lump this all together rather than separating out into six different clauses, because there really isn't much I can do to evidence that I have actually done this!</p>
                        <p>I am not great at doing any kind of housework regularly - I always have other stuff on the brain. Not to say my house isn't clean and organised, it's just that sometimes things wait a little longer than they should!</p>
                        <p>Also, it's only me in my house so there isn't a lot that needs doing very regularly!</p>
                        <p>So, the six chores I have been working on are:</p>
                        <br/>
                        <ul className='list'>
                            <li>Cleaning</li>
                            <li>Washing up</li>
                            <li>Washing</li>
                            <li>Hoovering</li>
                            <li>Dusting</li>
                            <li>Bins</li>
                        </ul>
                        <br/>
                        <p>Cleaning - I used to religiously do the cleaning once a week on a specific day, and that kind of slipped! So, now Thursday evening is cleaning evening - after work abd ready for the weekend!</p>
                        <br/><p>Washing up - You can ask my brother (he lived with me for a while!), washing up I tend to leave. I still do sometimes, for water conservation. I have been doing this more regularly, so the kitchen isn't quite so untidy.  I use much more when cooking these days so it needs to be done more frequently.</p>
                        <br/><p>Working from home and little social life means I have very little washing - probably, realistically, not even enough for a load a week. However, Sunday is designated washing day and I've been making sure I do that each week.</p>
                        <br/><p>Hoovering - I suck at hoovering, There is always something better to do than hoovering!! However, I have started doing this with the cleaning on Thursday evenings as part of my weekend preparations.</p>
                        <br/><p>Dusting - Generally, I dust as often as the sun comes out and highlights it all!! For someone with a dust allergy, you'd think I'd be hotter on this, but here we are. Another one combined with Thursday evening cleaning!</p>
                        <br/><p>Bins - I don't put the bins out every week, I simply don't generate enough litter so I wait until they are full and then put them out. I don't have any other chores to count, so it has to be here.</p>
                        <br/>
                        <p>I'll add a bonus of cooking to make up for bins being less frequent!  I have a love/hate relationship with cooking, but I have been making a conscious effort to cook from scratch more often.</p>
                        <br/><p>That's about as much as I can say about adulting! It's on the schedule and I'm maintaining it.</p>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AdvancedAdulting