import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Flowers from '../images/Observer-Flowers.png';
import Tree1 from '../images/observer-tree1.png';
import Tree2 from '../images/observer-tree2.png';
import Tree3 from '../images/observer-tree3.png';
import Tree4 from '../images/observer-tree4.png';
import Tree5 from '../images/observer-tree5.png';
import Tree6 from '../images/observer-tree6.png';
import Tree7 from '../images/observer-tree7.png';
import Tree8 from '../images/observer-tree8.png';
import Tree9 from '../images/observer-tree9.png';
import Tree10 from '../images/observer-tree10.png';
import Birds from '../documents/RebelObserver-Birds.pdf';
import TempChart from '../images/Observer-TempChart.png';
import Beach1 from '../images/observer-beach1.jpg';
import Beach2 from '../images/observer-beach2.jpg';
import Beach3 from '../images/observer-beach3.jpg';
import Beach4 from '../images/observer-beach4.jpg';
import Beach5 from '../images/observer-beach5.jpg';
import Beach6 from '../images/observer-beach6.jpg';
import Beach7 from '../images/observer-beach7.jpg';
import Beach8 from '../images/observer-beach8.jpg';
import Beach9 from '../images/observer-beach9.jpg';
import Beach10 from '../images/observer-beach10.jpg';
import Beach11 from '../images/observer-beach11.jpg';
import Beach12 from '../images/observer-beach12.jpg';
import Beach13 from '../images/observer-beach13.jpg';
import Beach14 from '../images/observer-beach14.jpg';
import Beach15 from '../images/observer-beach15.jpg';
import Beach16 from '../images/observer-beach16.jpg';
import Beach17 from '../images/observer-beach17.jpg';
import Beach18 from '../images/observer-beach18.jpg';
import Walk1 from '../images/observer-runway1.jpg';
import Walk2 from '../images/observer-runway2.jpg';
import Walk3 from '../images/observer-runway3.jpg';
import Walk4 from '../images/observer-runway4.jpg';
import Walk5 from '../images/observer-runway5.jpg';
import Walk6 from '../images/observer-runway6.jpg';
import Walk7 from '../images/observer-runway7.jpg';
import Walk8 from '../images/observer-runway8.jpg';
import Walk9 from '../images/observer-runway9.jpg';
import Walk10 from '../images/observer-runway10.jpg';
import Walk11 from '../images/observer-runway11.jpg';
import Walk12 from '../images/observer-runway12.jpg';
import Walk13 from '../images/observer-runway13.jpg';
import Walk14 from '../images/observer-runway14.jpg';
import Walk15 from '../images/observer-runway15.jpg';
import Walk16 from '../images/observer-runway16.jpg';
import Walk17 from '../images/observer-runway17.jpg';
import Walk18 from '../images/observer-runway18.jpg';
import Walk19 from '../images/observer-runway19.jpg';
import Walk20 from '../images/observer-runway20.jpg';
import Walk21 from '../images/observer-runway21.jpg';
import Hedge1 from '../images/observer-hedgerow-blackberry.jpg';
import Hedge2 from '../images/observer-hedgerow-blackberry2.jpg';
import Hedge3 from '../images/observer-hedgerow-blackberry3.jpg';
import Hedge4 from '../images/observer-hedgerow-blackberry4.jpg';
import Hedge5 from '../images/observer-hedgerow-blackberry5.jpg';
import Hedge6 from '../images/observer-hedgerow-blackhawthorn.jpg';
import Hedge7 from '../images/observer-hedgerow-cleavers.jpg';
import Hedge8 from '../images/observer-hedgerow-commonnettle.jpg';
import Hedge9 from '../images/observer-hedgerow-cowparsley.jpg';
import Hedge10 from '../images/observer-hedgerow-general1.jpg';
import Hedge11 from '../images/observer-hedgerow-general2.jpg';
import Hedge12 from '../images/observer-hedgerow-general3.jpg';
import Hedge13 from '../images/observer-hedgerow-general4.jpg';
import Hedge14 from '../images/observer-hedgerow-general5.jpg';
import Hedge15 from '../images/observer-hedgerow-general6.jpg';
import Hedge17 from '../images/observer-hedgerow-general8.jpg';
import Hedge18 from '../images/observer-hedgerow-general9.jpg';
import Hedge19 from '../images/observer-hedgerow-hawthorn1.jpg';
import Hedge20 from '../images/observer-hedgerow-hawthorn2.jpg';
import Hedge21 from '../images/observer-hedgerow-hazel.jpg';
import Hedge22 from '../images/observer-hedgerow-holly.jpg';
import Hedge23 from '../images/observer-hedgerow-holly2.jpg';
import Hedge24 from '../images/observer-hedgerow-horsechestnut.jpg';
import Hedge25 from '../images/observer-hedgerow-Ivy.jpg';
import Hedge26 from '../images/observer-hedgerow-ivy2.jpg';
import Hedge27 from '../images/observer-hedgerow-ivy3.jpg';
import Hedge28 from '../images/observer-hedgerow-ivy4.jpg';
import Hedge29 from '../images/observer-hedgerow-oak.jpg';
import Hedge30 from '../images/observer-hedgerow-rowan.jpg';
import Hedge31 from '../images/observer-hedgerow-slipperyelm.jpg';
import Hedge32 from '../images/observer-hedgerow-sycamore.jpg';








const Observer = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"Observer"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>I have been recording my visits to the beach and my walk to and from the beach for some time now. I did both because although there are subtle changes to the beach, there aren't significant changes in short periods.</p>
                        <p>The photos of my walk home are more dramatic as you can see the change in season from Winter to Spring to Summer.</p>
                        <br/>
                        <p><strong>Beach</strong></p>
                        <p>I picked the smallest stretch of beach to record and took a photo from the same point on most occasions - this is Gundimore Beach in Christchurch. It has grown quite bit over the last year or so and when the tide is right out you can get out to the sandbanks - but you shouldn;t, because the tides changes quickly! It makes a nice shallow, warm lagoon for dipping in though.</p> 
                        <p>There are changes to the shoreline - when the weather has been stormy, we get extra seaweed. We get different shells sometimes depending on tides.</p>
                        <p>We see Seagulls - Herring Gulls and Black Faced Gulls -  and tiny little Turnstones. I always miss the Turnstones until I'm right on top of them and they scatter, because they blend in so well with the stones!</p>
                        <p>We have plenty of crabs, but those that you see on the beach are dead - we get a lot of crab shells and legs.</p>
                        <p>We also get Lobster washed up -  I have found full shells before.</p>   
                        <p>We also see Jellyfish washed up occassionally, both Barrel and Man o' War. I've also seen an Octopus and Cuttle Fish washed up but sadly not still alive.</p>
                        <p>We have had a seal in the bay, dolphins play further out but I've never seen them.</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Beach1} alt="View of Gundimore Beach" /></div>
                            <div className="galleryItem"><img src={Beach2} alt="View of Gundimore Beach" /></div>
                            <div className="galleryItem"><img src={Beach3} alt="View of Gundimore Beach" /></div>
                            <div className="galleryItem"><img src={Beach4} alt="View of Gundimore Beach" /></div>
                            <div className="galleryItem"><img src={Beach5} alt="View of Gundimore Beach" /></div>
                            <div className="galleryItem"><img src={Beach6} alt="View of Gundimore Beach" /></div>
                            <div className="galleryItem"><img src={Beach7} alt="View of Gundimore Beach" /></div>
                            <div className="galleryItem"><img src={Beach8} alt="View of Gundimore Beach" /></div>
                            <div className="galleryItem"><img src={Beach9} alt="View of Gundimore Beach" /></div>
                            <div className="galleryItem"><img src={Beach10} alt="View of Gundimore Beach" /></div>
                            <div className="galleryItem"><img src={Beach11} alt="View of Gundimore Beach" /></div>
                            <div className="galleryItem"><img src={Beach12} alt="View of Gundimore Beach" /></div>
                            <div className="galleryItem"><img src={Beach13} alt="View of Gundimore Beach" /></div>
                            <div className="galleryItem"><img src={Beach14} alt="View of Gundimore Beach" /></div>
                            <div className="galleryItem"><img src={Beach15} alt="View of Gundimore Beach" /></div>
                            <div className="galleryItem"><img src={Beach16} alt="View of Gundimore Beach" /></div>
                            <div className="galleryItem"><img src={Beach17} alt="View of Gundimore Beach" /></div>
                            <div className="galleryItem"><img src={Beach18} alt="View of Gundimore Beach" /></div>
                        </div>
                        <br/>
                        <p>The shape of the beach changes with the season, tides and weather. Sometimes we get heaped banks of stones or sand, othertimes it is completely flat. When the seaweed hits in the summer there can be big banks of that all along the front, which upsets everyone because it does not smell good!</p>
                        <br/><br/>
                        <p><strong>The road home</strong></p>
                        <p>My walk home has changed quite significantly! I started recording earlier in the year so the transition is quite dramatic. It looks quite bleak in the winter, the trees and hedgerows are bare, the gardens don't have colour and not much wildlife can be spotted - apart from the ever-present Magpies!</p>
                        <br/><p>As Spring came in, the trees started sprouting green buds and blossom. The verges got green and full of - well, weeds, but pretty ones!. Trees started blossoming and the flowers sprung into life in the gardens along the way. Now, the Horse Chestnuts are finally growing conkers.</p>
                        <p>I tried to take a photo at the same spot each time - I used a traffic sign as a marker and took the picutre as I went underneath it!</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Walk1} alt="View of a pavement showing a winter outlook." /></div>
                            <div className="galleryItem"><img src={Walk2} alt="View of a pavement showing a winter outlook." /></div>
                            <div className="galleryItem"><img src={Walk3} alt="View of a pavement showing a winter outlook." /></div>
                            <div className="galleryItem"><img src={Walk4} alt="View of a pavement showing a winter outlook." /></div>
                            <div className="galleryItem"><img src={Walk5} alt="View of a pavement showing a winter outlook." /></div>
                            <div className="galleryItem"><img src={Walk6} alt="View of a pavement showing a winter outlook." /></div>
                            <div className="galleryItem"><img src={Walk7} alt="View of a pavement showing a winter outlook." /></div>
                            <div className="galleryItem"><img src={Walk8} alt="View of a pavement showing a winter outlook." /></div>
                            <div className="galleryItem"><img src={Walk9} alt="View of a pavement showing signs of Spring." /></div>
                            <div className="galleryItem"><img src={Walk10} alt="View of a pavement showing signs of Spring." /></div>
                            <div className="galleryItem"><img src={Walk11} alt="View of a pavement showing signs of Spring." /></div>
                            <div className="galleryItem"><img src={Walk12} alt="View of a pavement showing signs of Spring." /></div>
                            <div className="galleryItem"><img src={Walk13} alt="View of a pavement showing signs of Spring." /></div>
                            <div className="galleryItem"><img src={Walk14} alt="View of a pavement showing signs of Spring." /></div>
                            <div className="galleryItem"><img src={Walk15} alt="View of a pavement showing signs of Spring." /></div>
                            <div className="galleryItem"><img src={Walk16} alt="View of a pavement showing signs of Spring." /></div>
                            <div className="galleryItem"><img src={Walk17} alt="View of a pavement showing signs of Spring." /></div>
                            <div className="galleryItem"><img src={Walk18} alt="View of a pavement showing signs of Spring." /></div>
                            <div className="galleryItem"><img src={Walk19} alt="View of a pavement showing signs of Spring." /></div>
                            <div className="galleryItem"><img src={Walk20} alt="View of a pavement showing signs of Summer." /></div>
                            <div className="galleryItem"><img src={Walk21} alt="View of a pavement showing signs of Summer." /></div>
                        </div>
                        <br/>
                        <p>One of the first changes was the little Forget-Me-Nots and the beautiful blossom. The Grape Hyacinths popped up and then Daffodils and Bluebells.</p>
                        <p>The most commonly seen wildlife is the Magpies! There are Squirrels that run around sometimes and the Pigeons like to hold a meeting one the pavement in the early mornings! Neighbourhood cats stroll around trying to catch the little birds - which are much too clever for them and use the hedgerows to their best advantage.</p>
                        <p>Slugs and snails and very common and so are spiders - there are spider lines everywhere some mornings! If you shone a light on it I'd probably refuse to walk down there, but when I can't see them I just go for it - I do the spider dance all the way down, brushing invisible webs off me!</p>
                        <p>My favourite trees are the beautiful Horse Chestnuts and I love collecting conkers!  There are a lot of different types of tree in among the hedgerows, including Elder, Holly and Blackthorn.</p>
                        <p>The grass verges do get cut by the Council, but often they will cut back what is needed to keep the pavements clear and maybe a strip of the grass but then the rest is left wild. There are wildflowers planted in some places. </p>
                        <p>There are some huge nettles by the stream as well!!</p>
                        <br/>
                    </div>
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>I have combined this with clause one whilst observing the area on my walks.</p>
                        <p>There is an amazing hedgerow down one of the roads that has a large number of different trees in it! I took photos as I walked along and used Google Lens to identify as many as possible. These are the ones I was fairly confident of:</p>
                        <ul className="list">
                            <li>Blackberry</li>
                            <li>Black Hawthorn</li>
                            <li>Cleavers</li>
                            <li>Nettle</li>
                            <li>Cow Parsley</li>
                            <li>Hawthorn</li>
                            <li>Hazel</li>
                            <li>Holly</li>
                            <li>Horse Chestnut</li>
                            <li>Ivy</li>
                            <li>Laurel</li>
                            <li>Oak</li>
                            <li>Rowan</li>
                            <li>Slippery Elm</li>
                            <li>Sycamore</li>
                        </ul>
                        <br/>
                        <p>I accept I may not have this completely right! This area is impacted by three human actions:</p>
                        <ul className="list">
                            <li>Grass Cutting</li>
                            <li>Litter</li>
                            <li>Traffic Pollution</li>
                        </ul>
                        <br/>
                        <p>This road is used as a shortcut and sees a fair amount of traffic as it leads to parking for the beaches in both directions. It also has bus stops. Occassionally we get takeaways thrown out of car windows but it's mostly dropped litter.</p>
                        <p>The Council do cut the verge and keep the nettles at bay!</p>
                        <p>It's hard to catch the wildlife, there are little birds hopping in and out of the trees, but they move too quick for easy identification.</p>
                        <br/><br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Hedge1} alt="Closeup of a Hedgerow" /></div>
                            <div className="galleryItem"><img src={Hedge2} alt="Closeup of a Hedgerow" /></div>
                            <div className="galleryItem"><img src={Hedge3} alt="Closeup of a Hedgerow" /></div>
                            <div className="galleryItem"><img src={Hedge4} alt="Closeup of a Hedgerow" /></div>
                            <div className="galleryItem"><img src={Hedge5} alt="Closeup of a Hedgerow" /></div>
                            <div className="galleryItem"><img src={Hedge6} alt="Closeup of a Hedgerow" /></div>
                            <div className="galleryItem"><img src={Hedge7} alt="Closeup of a Hedgerow" /></div>
                            <div className="galleryItem"><img src={Hedge8} alt="Closeup of a Hedgerow" /></div>
                            <div className="galleryItem"><img src={Hedge9} alt="Closeup of a Hedgerow" /></div>
                            <div className="galleryItem"><img src={Hedge10} alt="Closeup of a Hedgerow" /></div>
                            <div className="galleryItem"><img src={Hedge11} alt="Closeup of a Hedgerow" /></div>
                            <div className="galleryItem"><img src={Hedge12} alt="Closeup of a Hedgerow" /></div>
                            <div className="galleryItem"><img src={Hedge13} alt="Closeup of a Hedgerow" /></div>
                            <div className="galleryItem"><img src={Hedge14} alt="Closeup of a Hedgerow" /></div>
                            <div className="galleryItem"><img src={Hedge15} alt="Closeup of a Hedgerow" /></div>
                            <div className="galleryItem"><img src={Hedge17} alt="Closeup of a Hedgerow" /></div>
                            <div className="galleryItem"><img src={Hedge18} alt="Closeup of a Hedgerow" /></div>
                            <div className="galleryItem"><img src={Hedge19} alt="Closeup of a Hedgerow" /></div>
                            <div className="galleryItem"><img src={Hedge20} alt="Closeup of a Hedgerow" /></div>
                            <div className="galleryItem"><img src={Hedge21} alt="Closeup of a Hedgerow" /></div>
                            <div className="galleryItem"><img src={Hedge22} alt="Closeup of a Hedgerow" /></div>
                            <div className="galleryItem"><img src={Hedge23} alt="Closeup of a Hedgerow" /></div>
                            <div className="galleryItem"><img src={Hedge24} alt="Closeup of a Hedgerow" /></div>
                            <div className="galleryItem"><img src={Hedge25} alt="Closeup of a Hedgerow" /></div>
                            <div className="galleryItem"><img src={Hedge26} alt="Closeup of a Hedgerow" /></div>
                            <div className="galleryItem"><img src={Hedge27} alt="Closeup of a Hedgerow" /></div>
                            <div className="galleryItem"><img src={Hedge28} alt="Closeup of a Hedgerow" /></div>
                            <div className="galleryItem"><img src={Hedge29} alt="Closeup of a Hedgerow" /></div>
                            <div className="galleryItem"><img src={Hedge30} alt="Closeup of a Hedgerow" /></div>
                            <div className="galleryItem"><img src={Hedge31} alt="Closeup of a Hedgerow" /></div>
                            <div className="galleryItem"><img src={Hedge32} alt="Closeup of a Hedgerow" /></div>
                            <br/>
                        </div>
                    </div>
                </div>
            </div>          
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>The Natural history Museum have a very useful Tree Identification Guide that can be found here:</p>
                        <p>https://www.nhm.ac.uk/content/dam/nhmwww/take-part/identify-nature/tree-identification-key.pdf</p>
                        <br/>
                        <p>Identification is done using leaves, flowers, fruit and bark as well as the size and shape.</p>
                        <br/>
                        <p>The trees that I selected for this clause are:</p>
                        <br/>
                        <ul className="list">
                            <li>Oak Tree</li>
                            <li>Horse Chestnut</li>
                            <li>Silver Birch</li>
                            <li>Beech</li>
                            <li>Blackthorn</li>
                            <li>Crab Apple</li>
                            <li>Elder</li>
                            <li>Fir</li>
                            <li>Common Hawthorn</li>
                            <li>Sycamore</li>
                        </ul>
                        <br/>
                        <p>For the sketches, I have used an AI image generator. I have also included information on identification in summer and winter as well as sketches of the leaves and fruits.</p>
                        <br/>
                        <p>The text information has been taken from the Woodland Trust A-Z Guide which can be found here:</p>
                        <p>https://www.woodlandtrust.org.uk/trees-woods-and-wildlife/british-trees/a-z-of-british-trees</p>
                        <br/>
                        <img src={Tree1} className="imgHalf" alt="Tree information sheet - Oak Tree" />
                        <img src={Tree2} className="imgHalf" alt="Tree information sheet - Horse Chestnut" />
                        <img src={Tree3} className="imgHalf" alt="Tree information sheet - Silver Birch" />
                        <img src={Tree4} className="imgHalf" alt="Tree information sheet - Beech" />
                        <img src={Tree5} className="imgHalf" alt="Tree information sheet - Blackthorn" />
                        <img src={Tree6} className="imgHalf" alt="Tree information sheet - Crab Apple" />
                        <img src={Tree7} className="imgHalf" alt="Tree information sheet - Elder" />
                        <img src={Tree8} className="imgHalf" alt="Tree information sheet - Fir" />
                        <img src={Tree9} className="imgHalf" alt="Tree information sheet - Common Hawthorn" />
                        <img src={Tree10} className="imgHalf" alt="Tree information sheet - Sycamore" />
                        <br/>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 4</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light">Recognise 10 different birds, kids book</div>
                    <div className="badgePageItemEvidence">
                        <p>Birdspotting has come back into the family as people started downloading Merlin, so now we can't go anywhere without Mum whipping out her app to find out who is singing!!</p>
                        <br/>
                        <p>The birds that I selected are:</p>
                        <ul className="list">
                            <li>Chaffinch</li>
                            <li>Blue Tit</li>
                            <li>Goldfinch</li>
                            <li>Blackbird</li>
                            <li>Starling</li>
                            <li>Sparrow</li>
                            <li>Herring Gull</li>
                            <li>Turnstone</li>
                            <li>Magpie</li>
                            <li>Woodpecker</li>
                            <li>Robin</li>
                            <li>Song Thrush</li>
                            <li>ChiffChaff</li>
                            <li>Wren</li>
                        </ul>
                        <br/>
                        <p>Here is a link to my children's book. The information has come from the RSPB A-Z of birds.</p>
                        <br/>
                        <a href={Birds} className="linkUnderline" target="_blank" rel="noopener noreferrer">Cutie's Guide to Birds</a>
                        <br/>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 5</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>I chose some classic plants!!</p>
                        <ul className="list">
                            <li>Daffodil</li>
                            <li>Tulip</li>
                            <li>Rose</li>
                            <li>Passion Flower</li>
                            <li>Fuchsia</li>
                            <li>Sunflower</li>
                            <li>Gerbera</li>
                            <li>Poppy</li>
                            <li>Pansy</li>
                            <li>Primrose</li>
                        </ul>
                        <br/>
                        <p>Here are my drawings:</p>
                        <br/>
                        <img src={Flowers} alt="Sketchbook drawings of flowers" />
                        <br/>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 6</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>I actually started this earlier in the year, so this record is from Feb and March 2023.</p>
                        <br/>
                        <table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Temp Min (deg C)</th>
                                    <th>Temp Max (deg C)</th>
                                    <th>Wind Speed (km/h)</th>
                                    <th>Wind Direction</th>
                                    <th>Rainfall</th>
                                    <th>Cloud Cover</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1st Feb</td>
                                    <td>4</td>
                                    <td>12</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>2nd Feb</td>
                                    <td>7</td>
                                    <td>13</td>
                                    <td>20</td>
                                    <td>WSW</td>
                                    <td>0</td>
                                    <td>15%</td>
                                </tr>
                                <tr>
                                    <td>3rd Feb</td>
                                    <td>7</td>
                                    <td>13</td>
                                    <td>20</td>
                                    <td>WSW</td>
                                    <td>0</td>
                                    <td>15%</td>
                                </tr>
                                <tr>
                                    <td>4th Feb</td>
                                    <td>5</td>
                                    <td>10</td>
                                    <td>7-13</td>
                                    <td>W</td>
                                    <td>0</td>
                                    <td>2-99%</td>
                                </tr>
                                <tr>
                                    <td>5th Feb</td>
                                    <td>3</td>
                                    <td>8</td>
                                    <td>7-26</td>
                                    <td>WNW / NNW / N / NNE / NE</td>
                                    <td></td>
                                    <td>4 - 100%</td>
                                </tr>
                                <tr>
                                    <td>6th Feb</td>
                                    <td>2</td>
                                    <td>8</td>
                                    <td>5-10</td>
                                    <td>NNE / ENE / E / SE / ESE / ENE</td>
                                    <td>0</td>
                                    <td>4-52%</td>
                                </tr>
                                <tr>
                                    <td>7th Feb</td>
                                    <td>2</td>
                                    <td>9</td>
                                    <td>8-11</td>
                                    <td>NNE / NE / E</td>
                                    <td></td>
                                    <td>0-7%</td>
                                </tr>
                                <tr>
                                    <td>8th Feb</td>
                                    <td>2</td>
                                    <td>8</td>
                                    <td>5-15</td>
                                    <td>NE/ENE/E/SSE/S/SW/WSW</td>
                                    <td></td>
                                    <td>0-100%</td>
                                </tr>
                                <tr>
                                    <td>9th Feb</td>
                                    <td>3</td>
                                    <td>9</td>
                                    <td>4-10</td>
                                    <td>W/WNW/NNE</td>
                                    <td>0.3mm</td>
                                    <td>5-100%</td>
                                </tr>
                                <tr>
                                    <td>10th Feb</td>
                                    <td>2</td>
                                    <td>9</td>
                                    <td>8-15</td>
                                    <td>NW / WNW / W / WSW</td>
                                    <td>0</td>
                                    <td>13-100%</td>
                                </tr>
                                <tr>
                                    <td>11th Feb</td>
                                    <td>7</td>
                                    <td>11</td>
                                    <td>3-13</td>
                                    <td>W/WNW/NNW / NW / ENE</td>
                                    <td>0</td>
                                    <td>11-48%</td>
                                </tr>
                                <tr>
                                    <td>12th Feb</td>
                                    <td>6</td>
                                    <td>10</td>
                                    <td>9-17</td>
                                    <td>ESE / SE / E</td>
                                    <td>0</td>
                                    <td>5-84%</td>
                                </tr>
                                <tr>
                                    <td>13th Feb</td>
                                    <td>6</td>
                                    <td>10</td>
                                    <td>10-16</td>
                                    <td>ESE / SSE</td>
                                    <td>0</td>
                                    <td>0-12%</td>
                                </tr>
                                <tr>
                                    <td>14th Feb</td>
                                    <td>7</td>
                                    <td>11</td>
                                    <td>8-13</td>
                                    <td>S / SSW / SE</td>
                                    <td>0</td>
                                    <td>3-87%</td>
                                </tr>
                                <tr>
                                    <td>15th Feb</td>
                                    <td>7</td>
                                    <td>12</td>
                                    <td>8-23</td>
                                    <td>SSE / S / SSW / SW / WSW / W</td>
                                    <td>0.3mm</td>
                                    <td>7-100%</td>
                                </tr>
                                <tr>
                                    <td>16th Feb</td>
                                    <td>8</td>
                                    <td>12</td>
                                    <td>15-30</td>
                                    <td>WSW / SW</td>
                                    <td>0.8mm</td>
                                    <td>22-100%</td>
                                </tr>
                                <tr>
                                    <td>17th Feb</td>
                                    <td>10</td>
                                    <td>13</td>
                                    <td>19-35</td>
                                    <td>WSW / W / SW</td>
                                    <td>0.1mm</td>
                                    <td>39-100%</td>
                                </tr>
                                <tr>
                                    <td>18th Feb</td>
                                    <td>9</td>
                                    <td>12</td>
                                    <td>25-30</td>
                                    <td>WSW / W</td>
                                    <td>0</td>
                                    <td>34-100%</td>
                                </tr>
                                <tr>
                                    <td>19th Feb</td>
                                    <td>7</td>
                                    <td>14</td>
                                    <td>18-35</td>
                                    <td>WNW / NW / WSW</td>
                                    <td>0</td>
                                    <td>0-56%</td>
                                </tr>
                                <tr>
                                    <td>20th Feb</td>
                                    <td>6</td>
                                    <td>13</td>
                                    <td>10-19</td>
                                    <td>WSW / W</td>
                                    <td>0</td>
                                    <td>0-37%</td>
                                </tr>
                                <tr>
                                    <td>21st Feb</td>
                                    <td>8</td>
                                    <td>11</td>
                                    <td>5-12</td>
                                    <td>WSW / SSW / S</td>
                                    <td>0</td>
                                    <td>30-100%</td>
                                </tr>
                                <tr>
                                    <td>22nd Feb</td>
                                    <td>6</td>
                                    <td>10</td>
                                    <td>9-26</td>
                                    <td>SSW / W / WSW / WNW / NW / NNW</td>
                                    <td>1mm</td>
                                    <td>25-100%</td>
                                </tr>
                                <tr>
                                    <td>23rd Feb</td>
                                    <td>2</td>
                                    <td>8</td>
                                    <td>14-26</td>
                                    <td>NNW / N / WSW / ESE / NNE</td>
                                    <td>0</td>
                                    <td>4-71%</td>
                                </tr>
                                <tr>
                                    <td>24th Feb</td>
                                    <td>2</td>
                                    <td>8</td>
                                    <td>12-22</td>
                                    <td>NNE / N / NNW / NW / N / NNE</td>
                                    <td>0.1mm</td>
                                    <td>24-100%</td>
                                </tr>
                                <tr>
                                    <td>25th Feb</td>
                                    <td>0.3</td>
                                    <td>2.2</td>
                                    <td>15.8-22</td>
                                    <td>NNE</td>
                                    <td>0</td>
                                    <td>1/3%</td>
                                </tr>
                                <tr>
                                    <td>26th Feb</td>
                                    <td>0.6</td>
                                    <td>2.7</td>
                                    <td>20.2-24.7</td>
                                    <td>NNE</td>
                                    <td>0</td>
                                    <td>3-7%</td>
                                </tr>
                                <tr>
                                    <td>27th Feb</td>
                                    <td>1</td>
                                    <td>8</td>
                                    <td>15-27</td>
                                    <td>NNE / NE / ENE</td>
                                    <td>0</td>
                                    <td>4-66%</td>
                                </tr>
                                <tr>
                                    <td>28th Feb</td>
                                    <td>1</td>
                                    <td>9</td>
                                    <td>16-31</td>
                                    <td>NNE / NE</td>
                                    <td>0.2mm</td>
                                    <td>12-100%</td>
                                </tr>
                                <tr>
                                    <td>1st Mar</td>
                                    <td>4</td>
                                    <td>7</td>
                                    <td>17-22</td>
                                    <td>NE / NNE</td>
                                    <td>0.4mm</td>
                                    <td>69-98%</td>
                                </tr>
                                <tr>
                                    <td>2nd Mar</td>
                                    <td>1</td>
                                    <td>12</td>
                                    <td>15-19</td>
                                    <td>NE / NNE</td>
                                    <td>0.1mm</td>
                                    <td>0-82%</td>
                                </tr>
                                <tr>
                                    <td>3rd Mar</td>
                                    <td>1</td>
                                    <td>8</td>
                                    <td>24-31</td>
                                    <td>NNE / NE / N</td>
                                    <td>0</td>
                                    <td>21-100%</td>
                                </tr>
                                <tr>
                                    <td>4th Mar</td>
                                    <td>1</td>
                                    <td>3.3</td>
                                    <td>12.2-13.7</td>
                                    <td>NNE / N</td>
                                    <td>0</td>
                                    <td>3-19%</td>
                                </tr>
                                <tr>
                                    <td>5th Mar</td>
                                    <td>0.9</td>
                                    <td>3.1</td>
                                    <td>5-11.2</td>
                                    <td>NNE / ESE / SW / NNW / NW / WNW / W</td>
                                    <td>0</td>
                                    <td>11-73%</td>
                                </tr>
                                <tr>
                                    <td>6th Mar</td>
                                    <td>2</td>
                                    <td>9</td>
                                    <td>12-28</td>
                                    <td>NW / WNW / W / WSW</td>
                                    <td>0.3mm</td>
                                    <td>15-100%</td>
                                </tr>
                                <tr>
                                    <td>7th Mar</td>
                                    <td>3</td>
                                    <td>6</td>
                                    <td>9-25</td>
                                    <td>W / WSW / NW / NNE / NE / E</td>
                                    <td>0.9mm</td>
                                    <td>71-100%</td>
                                </tr>
                                <tr>
                                    <td>8th Mar</td>
                                    <td>2</td>
                                    <td>7</td>
                                    <td>8-33</td>
                                    <td>E / ENE / NE / SSW</td>
                                    <td>10.2mm</td>
                                    <td>100%</td>
                                </tr>
                                <tr>
                                    <td>9th March</td>
                                    <td>4.8</td>
                                    <td>9.7</td>
                                    <td>3.6 - 41.4</td>
                                    <td>W / WNW / SSE / S / SSW / SW</td>
                                    <td>0.3mm</td>
                                    <td>97-100%</td>
                                </tr>
                                <tr>
                                    <td>10th March</td>
                                    <td>2.9</td>
                                    <td>9.1</td>
                                    <td>41.8-61.6</td>
                                    <td>SW / WSW / W / WNW / NW</td>
                                    <td>1.3mm</td>
                                    <td>100%</td>
                                </tr>
                                <tr>
                                    <td>11th Mar</td>
                                    <td>3</td>
                                    <td>7</td>
                                    <td>12-25</td>
                                    <td>E / ESE / SE / SSE / SSW</td>
                                    <td>0.7mm</td>
                                    <td>75-100%</td>
                                </tr>
                                <tr>
                                    <td>12th Mar</td>
                                    <td>7</td>
                                    <td>12</td>
                                    <td>21-43</td>
                                    <td>W / WSW / SSW / S / SW</td>
                                    <td>2.1</td>
                                    <td>21-100%</td>
                                </tr>
                                <tr>
                                    <td>13th Mar</td>
                                    <td>9</td>
                                    <td>11</td>
                                    <td>25-55</td>
                                    <td>SW</td>
                                    <td>0.4mm</td>
                                    <td>90-100%</td>
                                </tr>
                                <tr>
                                    <td>14th Mar</td>
                                    <td>5</td>
                                    <td>9</td>
                                    <td>20-42</td>
                                    <td>NW / WNW / N / W</td>
                                    <td>0.1mm</td>
                                    <td>13-92%</td>
                                </tr>
                                <tr>
                                    <td>15th Mar</td>
                                    <td>2</td>
                                    <td>9</td>
                                    <td>3-30</td>
                                    <td>WNW / NW / N / SE / S / SSE</td>
                                    <td>0.6mm</td>
                                    <td>19-100%</td>
                                </tr>
                                <tr>
                                    <td>16th Mar</td>
                                    <td>9.2</td>
                                    <td>9.5</td>
                                    <td>34.2-38.2</td>
                                    <td>SSW</td>
                                    <td>0.2mm</td>
                                    <td>78-100%</td>
                                </tr>
                                <tr>
                                    <td>17th Mar</td>
                                    <td>9.4</td>
                                    <td>10.1</td>
                                    <td>14-24</td>
                                    <td>SSW / S</td>
                                    <td>1.2mm</td>
                                    <td>100%</td>
                                </tr>
                                <tr>
                                    <td>18th Mar</td>
                                    <td>7.6</td>
                                    <td>9.7</td>
                                    <td>13-20</td>
                                    <td>SSW / S / SSE</td>
                                    <td>0</td>
                                    <td>39-91%</td>
                                </tr>
                                <tr>
                                    <td>19th Mar</td>
                                    <td>5</td>
                                    <td>10</td>
                                    <td>8-23</td>
                                    <td>WNW / NW / W / WSW</td>
                                    <td>0</td>
                                    <td>5-100%</td>
                                </tr>
                                <tr>
                                    <td>20th Mar</td>
                                    <td>6.6</td>
                                    <td>9.2</td>
                                    <td>11.2-17.6</td>
                                    <td>WSW / SW</td>
                                    <td>0</td>
                                    <td>39-58%</td>
                                </tr>
                                <tr>
                                    <td>21st Mar</td>
                                    <td>8.2</td>
                                    <td>10.3</td>
                                    <td>15.1-32</td>
                                    <td>S / SSW / SW</td>
                                    <td>1.1mm</td>
                                    <td>83-100%</td>
                                </tr>
                                <tr>
                                    <td>22nd Mar</td>
                                    <td>9.1</td>
                                    <td>10.4</td>
                                    <td>20.5-43.9</td>
                                    <td>SSW / SW / W / WSW</td>
                                    <td>3.6mm</td>
                                    <td>32-100%</td>
                                </tr>
                                <tr>
                                    <td>23rd Mar</td>
                                    <td>9.5</td>
                                    <td>10.7</td>
                                    <td>33.5-38.3</td>
                                    <td>SW</td>
                                    <td>0.3mm</td>
                                    <td>50-100%</td>
                                </tr>
                                <tr>
                                    <td>24th Mar</td>
                                    <td>8.3</td>
                                    <td>9.1</td>
                                    <td>34.6-40.3</td>
                                    <td>SW / WSW</td>
                                    <td>0.2mm</td>
                                    <td>58-99%</td>
                                </tr>
                                <tr>
                                    <td>25th Mar</td>
                                    <td>8.2</td>
                                    <td>9.1</td>
                                    <td>34.2-39.2</td>
                                    <td>WSW</td>
                                    <td>0.1mm</td>
                                    <td>57-98%</td>
                                </tr>
                                <tr>
                                    <td>26th Mar</td>
                                    <td>6</td>
                                    <td>8</td>
                                    <td>11-31</td>
                                    <td>S / WSW / WNW / NNW / NNE</td>
                                    <td>6.8mm</td>
                                    <td>29-100%</td>
                                </tr>
                                <tr>
                                    <td>27th Mar</td>
                                    <td>3.5</td>
                                    <td>5.2</td>
                                    <td>7.9-9.7</td>
                                    <td>NE / NNE / ENE / E / ESE</td>
                                    <td>0</td>
                                    <td>81-100%</td>
                                </tr>
                                <tr>
                                    <td>28th Mar</td>
                                    <td>9</td>
                                    <td>10</td>
                                    <td>18-34</td>
                                    <td>SSE / S / SSW</td>
                                    <td>0.9mm</td>
                                    <td>55-100%</td>
                                </tr>
                                <tr>
                                    <td>29th Mar</td>
                                    <td>10</td>
                                    <td>12</td>
                                    <td>15-46</td>
                                    <td>SW / SSW / S</td>
                                    <td>1.1mm</td>
                                    <td>97-100%</td>
                                </tr>
                                <tr>
                                    <td>30th Mar</td>
                                    <td>10</td>
                                    <td>12</td>
                                    <td>28-42</td>
                                    <td>SW / WSW / SSW / S</td>
                                    <td>4.1mm</td>
                                    <td>38-100%</td>
                                </tr>
                                <tr>
                                    <td>31st Mar</td>
                                    <td>10</td>
                                    <td>11</td>
                                    <td>40-55</td>
                                    <td>SSW /SW / WSW / W / WNW</td>
                                    <td>4.3mm</td>
                                    <td>100%</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 7</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>I have used the Min and Max temperatures to produce this dual layer donut chart. I used a temperature chart to determine the colours for each temperature value.</p>
                        <p>As this was done in Feb and March, the temperatures were a little chilly so this is a pretty blue chart!!!</p>
                        <br/>
                        <img className="imgHalf" src={TempChart} alt="Donut chart representing the Min and Max temperatures throughout Feb and March 2023" />
                        <br/>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 8</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light">Take an hour to sit on a bench, or at a coffee table in a relatively busy area. Observe the world around you. What things do you notice which you might not normally notice if you were lookig at your phoneor talking to someone.</div>
                    <div className="badgePageItemEvidence">
                        <p>Christchurch Quay is a brilliant place to sit and watch the world go by, there is always a lot going on.</p>
                        <br/>
                        <ul className="list">
                            <li>Swans wandering around looking for food</li>
                            <li>Mums and Dads pushing pushchairs and chasing toddlers</li>
                            <li>People feeding the ducks and swans</li>
                            <li>People enjoying takeaways and eat ins from the cafe</li>
                            <li>The well-known homeless gentleman who lives in the shelter. Once he saw me litter-picking and asked for some kit to clean up the shelter. I delivered it the next day, sadly he wasn't there but I left it with his things.</li>
                            <li>If you listen, you might hear the Dragon Boat out on the water - this supports cancer sufferers and supporters.</li>
                            <li>People out in self-hire boats, paddle boarders and kayakers</li>
                            <li>You  might hear and see the local rowing club out practicing - you can hear the coxon or guide boat usually!</li>
                            <li>There might be music in the bandstand if you're there at the right time</li>
                            <li>People going through to the Sailing Club or to visit the Prioriy</li>
                            <li>You  might see the local traffic warden getting happy with tickets!</li>
                            <li>You could catch a bride and groom if there has been a wedding at the Priory</li>
                            <li>Children playing in the park or splashing in the splash pool</li>
                            <li>Queues of people at the ice cream van by the park</li>
                            <li>Dads playing ball with the kids on the green</li>
                            <li>Families picnicking near the park</li>
                            <li>Boat owners rowing out to their boats and getting ready to cast off</li>
                            <li>Boats coming and going</li>
                            <li>You will see the passenger ferry coming and going, shuttling people around to the different river banks and stops</li>
                            <li>People playing crazy golf</li>
                            <li>People admiring art exhibitions and enjoying the old mill</li>
                            <li>People stopping to see whether the pond has any fish in!</li>
                            <li>Photographers stopping to find the best shot</li>
                            <li>Everyone else walking around looking at their phones ;)</li>
                        </ul>
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default Observer