import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Book from '../images/goodhabits-read.jpg';

const GoodHabits = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Good Habits"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">01</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Positive changes</div>
                    <div className="badgePageItemEvidence">
                        <strong>Life admin</strong>
                        <br/>
                        What's life admin?! Managing the household and bills I guess. This is not an area that I have trouble with. I am always on top of my life admin.
                        <br/><br/><br/>
                        <strong>Health and Fitness</strong>
                        <br/>
                        My health is ok generally, I have already worked on keeping myself healthy, adjusting the supplements I need to take control of chronic conditions, cutting out chemicals and pharmaceuticals but I definitely need to exercise more.
                        <br/><br/>
                        I am using the exercise every day badge and the Fitness badge to implement this and get back into the habit of exercising regularly.
                        <br/><br/><br/>
                        <strong>Household Chores</strong>
                        <br/>
                        I don't really have a problem doing chores. Sometimes I put off the hoovering but everything else is done as required.
                        <br/><br/><br/>
                        <strong>Self Care</strong>
                        <br/>
                        Self care for me is about sorting out my fitness and getting back into a routine of reading and learning instead of watching TV!
                        <br/>
                        I am using various rebel badges to help get me back in the right habits.
                        <br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">02</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Implement</div>
                    <div className="badgePageItemEvidence">
                        <p>In progress! Come back at the end of April and see if I made it!</p>
                        <br/><br/>
                        <p><strong>End of April Update</strong></p>
                        <br/>
                        <p>Well, here we are. I can confirm that good habits are have been implemented and followed. I completed the mindfulness badge and wellness patch, I have reinstated my learning paths and undertaken new courses, household chores are up to date and my life admin is all in order!</p>
                        <br/>
                        <p>It's not easy to evidence, so you're going to have to take my word for this one!</p>
                        <br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">03</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Habit reading</div>
                    <div className="badgePageItemEvidence">
                    For this I read "The Power of Habit" by Charles Duhigg, which is the book that Charly recommended. Very interesting insight into how to identify / create / change habits that we form over the years.
                    <br/><br/>
                    I am definitely a creature of habit, I got it from my Dad! I have occassionally been successful in modifying habitual behaviour, I have other things I can work on!
                    <br/><br/>
                    <img src={Book} alt="The Power of Habit front cover"  width="380px" height="380px" className="imgThird" />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default GoodHabits