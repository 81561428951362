import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const MyEnvironment = () => {
    return (
        <div>
            <ActionBar area={"silver"}  />
            <EvidenceHeader title={"My Environment"} />
            <div className="mavPageItem">
                <div className="mavPageItemTitle silver">My Environment</div>
                <div className="mavPageItemContent">
                    <div className="mavPageItemInfo silver-light">Plan and execute a project which positively affects the environment. Your project can be a local one - e.g. such as setting up a litter picking group, or a national or international one - e.g. fundraising for a charity.</div>
                    <div className="mavPageItemEvidence">
                    
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyEnvironment