import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Reader1 from '../images/advancedreader1.jpg';
import Reader2 from '../images/advancedreader2.jpg';
import Reader3 from '../images/advancedreader3.jpg';
import Reader4 from '../images/advancedreader4.jpg';
import Reader5 from '../images/advancedreader5.jpg';
import Reader6 from '../images/advancedreader6.jpg';
import Reader7 from '../images/advancedreader7.jpg';
import Reader8 from '../images/advancedreader8.jpg';
import Reader9 from '../images/advancedreader9.jpg';
import Reader10 from '../images/advancedreader10.jpg';
import Reader11 from '../images/advancedreader11.jpg';
import Reader12 from '../images/advancedreader12.jpg';
import Reader13 from '../images/advancedreader13.jpg';
import Reader14 from '../images/advancedreader14.jpg';
import Reader15 from '../images/advancedreader15.jpg';
import Reader16 from '../images/advancedreader16.jpg';
import Reader17 from '../images/advancedreader17.jpg';
import Reader18 from '../images/advancedreader18.jpg';
import Reader19 from '../images/advancedreader19.jpg';
import Reader20 from '../images/advancedreader20.jpg';
import Reader21 from '../images/advancedreader21.jpg';
import Reader22 from '../images/advancedreader22.jpg';
import Reader23 from '../images/advancedreader23.jpg';
import Reader24 from '../images/advancedreader24.jpg';
import Reader25 from '../images/advancedreader25.jpg';
import Reader26 from '../images/advancedreader26.jpg';
import Reader27 from '../images/advancedreader27.jpg';
import Reader28 from '../images/advancedreader28.jpg';
import Reader29 from '../images/advancedreader29.jpg';
import Reader30 from '../images/advancedreader30.jpg';
import Reader31 from '../images/advancedreader31.jpg';
import Reader32 from '../images/advancedreader32.jpg';
import Reader33 from '../images/advancedreader33.jpg';
import Reader34 from '../images/advancedreader34.jpg';

const AdvancedReader = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"Advanced Reader"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence">
                        For this badge, I have chosen the <a className="linkUnderline" href="https://media1.popsugar-assets.com/files/docs/PS22_ReadingChallenge2023_Printable_16696733469632.pdf" target="_blank" rel="noopener noreferrer">PopSugar 2023 Reading Challenge</a>!
                        <br/><br/>
                        Mum decided she's going to do this with me as we are our own book club. We started off the list by hunting around the local charity shops for books that met categories on our list!
                        <br/>
                        Here's some of the first set of books, Mum's got some of the others!
                        <br/><br/>
                        <img className="imgThird" src={Reader34} alt="Filled in Reading Challenge Form" />
                        <img className="imgThird" src={Reader1} alt="Collection of books" />
                        <br/><br/>
                        We did another couple of sweeps of the charity shops and I used some online curated lists to help identify books to meet some of the clauses and then ordered from Amazon - buying second-hand as much as possible.
                        <br/><br/>
                        I'm not sure whether I have photos for every book - I think I lost some photos somewhere along the line, but the ones I can find are below!
                        <br/><br/>
                        Here's the list of books and authors we read:
                        <br/><br/>
                        <table>
                        <thead>
                            <tr>
                                <th>Category</th>
                                <th>Book</th>
                                <th>Author</th>    
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>A book you meant to read in 2022</td>
                                <td>Investing for Dummies</td>
                                <td>Tony Levene</td>
                            </tr>
                            <tr>
                                <td>A book you bought from an independent bookstore</td>
                                <td>Smoke heads</td>
                                <td>Doug Johnstone</td>
                            </tr>
                            <tr>
                                <td>A book about a vacation</td>
                                <td>Summer Water</td>
                                <td>Sarah Moss</td>
                            </tr>
                            <tr>
                                <td>A book by a first-time author</td>
                                <td>It's just Sex, isn't it?</td>
                                <td>Nerys McCabe</td>
                            </tr>
                            <tr>
                                <td>A book with Mythical Creatures</td>
                                <td>True Blood</td>
                                <td>Charlaine Harris</td>
                            </tr>
                            <tr>
                                <td>A book about a forbidden romance</td>
                                <td>Red, White and Royal Blue</td>
                                <td>Casey McQuiston</td>
                            </tr>
                            <tr>
                                <td>A book with "Girl" in the title</td>
                                <td>Who's that Girl</td>
                                <td>Mhairi McFarlane</td>
                            </tr>
                            <tr>
                                <td>A celebrity Memoir</td>
                                <td>This is just my Face</td>
                                <td>Gabourey Sidibe</td>
                            </tr>
                            <tr>
                                <td>A book with a color in the title</td>
                                <td>Orange is the New Black</td>
                                <td>Piper Kerman</td>
                            </tr>
                            <tr>
                                <td>A romance with a fat lead</td>
                                <td>Big Summer</td>
                                <td>Jennifer Weiner</td>
                            </tr>
                            <tr>
                                <td>A book about or set in Hollywood</td>
                                <td>Shopaholic to the Stars</td>
                                <td>Sophie Kinsella</td>
                            </tr>
                            <tr>
                                <td>A book published in Spring 2023</td>
                                <td>Happy Place</td>
                                <td>Emily Henry</td>
                            </tr>
                            <tr>
                                <td>A book published in the year you were born</td>
                                <td>The Big Orange Splot</td>
                                <td>Daniel Manus Pinkwater</td>
                            </tr>
                            <tr>
                                <td>A modern retelling of a classic</td>
                                <td>Pride</td>
                                <td>Ibi Zoboi</td>
                            </tr>
                            <tr>
                                <td>A book with a song lyric as its title</td>
                                <td>The Color Purple</td>
                                <td>Alice Walker</td>
                            </tr>
                            <tr>
                                <td>A book where the main character's name is in the title</td>
                                <td>Rachel's Holiday</td>
                                <td>Marian Keyes</td>
                            </tr>
                            <tr>
                                <td>A book with a love triangle</td>
                                <td>Her Perfect Twin</td>
                                <td>Sarah Bonner</td>
                            </tr>
                            <tr>
                                <td>A book that's been banned or challenged in any state in 2022</td>
                                <td>19 Minutes</td>
                                <td>Jodi Picoult</td>
                            </tr>
                            <tr>
                                <td>A book becoming a TV series or movie in 2023</td>
                                <td>Heartstopper</td>
                                <td>Alice Oseman</td>
                            </tr>
                            <tr>
                                <td>A book set in the decade you were born</td>
                                <td>The Vanishing Half</td>
                                <td>Brit Bennett</td>
                            </tr>
                            <tr>
                                <td>A book with a queer lead</td>
                                <td>Summer Bird Blue</td>
                                <td>Akemi Dawn Bowman</td>
                            </tr>
                            <tr>
                                <td>A book with a map</td>
                                <td>The Wild Silence</td>
                                <td>Raynor Winn</td>
                            </tr>
                            <tr>
                                <td>A book with a rabbit on the cover</td>
                                <td>Pine</td>
                                <td>Francis Toon</td>
                            </tr>
                            <tr>
                                <td>A book with just text on the cover</td>
                                <td>Everything is Illuminated / Roll of Thunder, Hear My Cry</td>
                                <td>Jonathan Safran Foer / Mildred D. Taylor</td>
                            </tr>
                            <tr>
                                <td>The shortest book (by pages) on your TBR List</td>
                                <td>Just One Thing</td>
                                <td>Michael Moseley</td>
                            </tr>
                            <tr>
                                <td>A #BookTok recommendation</td>
                                <td>Skip to the End</td>
                                <td>Molly James</td>
                            </tr>
                            <tr>
                                <td>A book you bought secondhand</td>
                                <td>You Have to Make Your Own Fun Around Here</td>
                                <td>Frances Macken</td>
                            </tr>
                            <tr>
                                <td>A book your friend recommended</td>
                                <td>The Sins of the Father</td>
                                <td>Jeffrey Archer</td>
                            </tr>
                            <tr>
                                <td>A book that's on a celebrity book-club list</td>
                                <td>Ruby</td>
                                <td>Cynthia Bond</td>
                            </tr>
                            <tr>
                                <td>A book about a family</td>
                                <td>The Other Family / I Don't Know How She Does It</td>
                                <td>Wendy Corsi Staub / Allison Pearson</td>
                            </tr>
                            <tr>
                                <td>A book that comes out in the second half of 2023</td>
                                <td>Confessions of a 40 Something Fuck Up</td>
                                <td>Alexandra Potter</td>
                            </tr>
                            <tr>
                                <td>A book about an athlete/sport</td>
                                <td>Friday Night Lights</td>
                                <td>H.G. Bissinger</td>
                            </tr>
                            <tr>
                                <td>A historical-fiction book</td>
                                <td>Blue Water</td>
                                <td>Leonora Nattrass</td>
                            </tr>
                            <tr>
                                <td>A book about divorce</td>
                                <td>Landline</td>
                                <td>Rainbow Rowell</td>
                            </tr>
                            <tr>
                                <td>A book you should have read in high school</td>
                                <td>The Alchemist</td>
                                <td>Paulo Coelho</td>
                            </tr>
                            <tr>
                                <td>A book you read more than 10 years ago</td>
                                <td>Mallory Towers</td>
                                <td>Enid Blyton</td>
                            </tr>
                            <tr>
                                <td>A book you wish you could read for the first time again</td>
                                <td>Mungo Thunk</td>
                                <td>Keith A. Pearson</td>
                            </tr>
                            <tr>
                                <td>A book by an author with the same initials as you</td>
                                <td>The Lost Wife</td>
                                <td>Alyson Richman</td>
                            </tr>
                            <tr>
                                <td>A book that takes place entirely in one day</td>
                                <td>Saturday</td>
                                <td>Ian McEwan</td>
                            </tr>
                            <tr>
                                <td>A book with a pet character</td>
                                <td>Lessons in Chemistry</td>
                                <td>Bonnie Garmus</td>
                            </tr>
                        </tbody>
                        </table>
                        <br/><br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Reader1} alt="Book" /></div>
                            <div className="galleryItem"><img src={Reader2} alt="Book" /></div>
                            <div className="galleryItem"><img src={Reader3} alt="Book" /></div>
                            <div className="galleryItem"><img src={Reader4} alt="Book" /></div>
                            <div className="galleryItem"><img src={Reader5} alt="Book" /></div>
                            <div className="galleryItem"><img src={Reader6} alt="Book" /></div>
                            <div className="galleryItem"><img src={Reader7} alt="Book" /></div>
                            <div className="galleryItem"><img src={Reader8} alt="Book" /></div>
                            <div className="galleryItem"><img src={Reader9} alt="Book" /></div>
                            <div className="galleryItem"><img src={Reader10} alt="Book" /></div>
                            <div className="galleryItem"><img src={Reader11} alt="Book" /></div>
                            <div className="galleryItem"><img src={Reader12} alt="Book" /></div>
                            <div className="galleryItem"><img src={Reader13} alt="Book" /></div>
                            <div className="galleryItem"><img src={Reader14} alt="Book" /></div>
                            <div className="galleryItem"><img src={Reader15} alt="Book" /></div>
                            <div className="galleryItem"><img src={Reader16} alt="Book" /></div>
                            <div className="galleryItem"><img src={Reader17} alt="Book" /></div>
                            <div className="galleryItem"><img src={Reader18} alt="Book" /></div>
                            <div className="galleryItem"><img src={Reader19} alt="Book" /></div>
                            <div className="galleryItem"><img src={Reader20} alt="Book" /></div>
                            <div className="galleryItem"><img src={Reader21} alt="Book" /></div>
                            <div className="galleryItem"><img src={Reader22} alt="Book" /></div>
                            <div className="galleryItem"><img src={Reader23} alt="Book" /></div>
                            <div className="galleryItem"><img src={Reader24} alt="Book" /></div>
                            <div className="galleryItem"><img src={Reader25} alt="Book" /></div>
                            <div className="galleryItem"><img src={Reader26} alt="Book" /></div>
                            <div className="galleryItem"><img src={Reader27} alt="Book" /></div>
                            <div className="galleryItem"><img src={Reader28} alt="Book" /></div>
                            <div className="galleryItem"><img src={Reader29} alt="Book" /></div>
                            <div className="galleryItem"><img src={Reader30} alt="Book" /></div>
                            <div className="galleryItem"><img src={Reader31} alt="Book" /></div>
                            <div className="galleryItem"><img src={Reader32} alt="Book" /></div>
                            <div className="galleryItem"><img src={Reader33} alt="Book" /></div>
                        </div>
                        <br/><br/>
                    </div>
                </div>
            </div>  
        </div>
    )
}

export default AdvancedReader