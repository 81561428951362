import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const  Outdoors24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"More Time Outdoors 2024"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Spend more time outdoors</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                                         
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Outdoors24