import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Mindfulness1 from '../images/mindfulness1.jpg';
import Mindfulness2 from '../images/mindfulness2.jpg';
import Mindfulness3 from '../images/mindfulness3.png';

const Mindfulness = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Mindfulness"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">01</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Meditation</div>
                    <div className="badgePageItemEvidence">
                        I find it hard to be still enough to do "proper" or traditional meditation. Instead, I do walking meditations.
                        <br/><br/>
                        I have found this extremely helpful and I have made many decisions as a result of these sessions, they help clear my mind so I can think clearly.
                        <br/><br/>
                        I will be finding short meditations to do post exercise as that's the time I'm most likely to be able to sit quietly during a warm-down.
                        <br/><br/>
                        Here's a poem I wrote during one of my walking meditations at my favourite place. This was included in my sketchbook that I sent to the Brooklyn Art Library.
                        <br/><br/>
                        <img className="imgHalf" src={Mindfulness3} alt="Poem written into a sketchbook" />


                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">02</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Yoga</div>
                    <div className="badgePageItemEvidence">
                        <p>I already know what time of day I get the most benefit - the afternoons when I finish working. It releases tension from the day and sets me up for the evening ahead.</p>
                        <br/><br/>
                        <p>I used an <a className="linkUnderline" href="https://www.mindbodygreen.com/articles/the-11-major-types-of-yoga-explained-simply" target="_blank" rel="noopener noreferrer">article at MindBodyGreen</a> to research the types of yoga available, then selected a few and found free taster sessions</p>
                        <br/>
                        <ul className="list">
                            <li>Vinyasa : <a className="linkUnderline" href="https://www.youtube.com/watch?v=yRCUfumiqhk" target="_blank" rel="noopener noreferrer">https://www.youtube.com/watch?v=yRCUfumiqhk</a></li>
                            <li>Detox Yoga : <a className="linkUnderline" href="https://www.youtube.com/watch?v=fANDSiAyaRQ" target="_blank" rel="noopener noreferrer">https://www.youtube.com/watch?v=fANDSiAyaRQ</a></li>
                            <li>Kundalini Yoga : <a className="linkUnderline" href="https://www.youtube.com/watch?v=AKZNKcBys0g" target="_blank" rel="noopener noreferrer">https://www.youtube.com/watch?v=AKZNKcBys0g</a></li>
                            <li>Iyengar : <a className="linkUnderline" href="https://www.youtube.com/watch?v=HQAVlIKw-d4" target="_blank" rel="noopener noreferrer">https://www.youtube.com/watch?v=HQAVlIKw-d4</a></li>
                        </ul>
                        <br/><br/>
                        <p>From these four, I preferred the Vinyasa as it's a continuous movement flow.</p>
                        <br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">03</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Gratitude</div>
                    <div className="badgePageItemEvidence">
                        I did do this for a month, but not with 5 things. A line a day gratitude journal was the approach I took, otherwise it would have been the same every day and there is little point in that as my gratitude is already fixed with that intent.
                        <br/><br/>
                        I'm not sharing my journal entries here, some things need to remain private, but here's the notebook I used - it's gold and very sparkly, I'm always grateful for sparkles!!!
                        <br/>
                        <img className="imgQuarter" src={Mindfulness2} alt="Gold sparkly notebook with the workds Mercury Retrograde Made Me Do it" />
                        <br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">04</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Creative activities</div>
                    <div className="badgePageItemEvidence">
                        This is definitely something I have done and that can be evidenced by any number of rebel badges and my Maverick Award so I'm not going to present all the evidence here!
                        <br/><br/>
                        Take a look at my website generally to see all the creative things I get up to!
                        <br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">05</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Indulge yourself</div>
                    <div className="badgePageItemEvidence">
                        "Me Time" is something I have done for quite some time. Sunday evenings are usually pamper time, it's a good time to uplift before the week ahead!
                        <br/><br/>
                        It's not something that can really be easily evidenced. I'm not sharing photos of me indulging myself!
                        <br/><br/>
                        Here's an old one just to break up the text on the page.
                        <br/>
                        <img className="imgQuarter" src={Mindfulness1} alt="Pamper products from Tropic" />
                        <br/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mindfulness