import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const Fitness = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Fitness"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Get active!</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Exercise regularly</div>
                     <div className="badgePageItemInfo reverse hidden">Set yourself a target number of days per week you wish to exercise - for example 3, 4 or 5. Multiply this number by 12. Over a 3 month period, complete that number of days exercise.</div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div>
        </div>
    )
}

export default Fitness