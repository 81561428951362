import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Cake1 from '../images/Rebel1stBdayCake-1.jpg';
import Cake2 from '../images/Rebel1stBdayCake-2.jpg';
import Cake3 from '../images/Rebel1stBdayCake-3.jpg';
import Cake4 from '../images/Rebel1stBdayCake-4.jpg';
import Cake5 from '../images/Rebel1stBdayCake-5.jpg';
import Card1 from '../images/FirstBday-Card1.jpg';
import Card2 from '../images/FirstBday-Card2.jpg';
import Card3 from '../images/FirstBday-Card3.jpg';
import Card4 from '../images/FirstBday-Card4.jpg';
import Card5 from '../images/FirstBday-Card5.jpg';
import Card6 from '../images/FirstBday-Card6.jpg';
import Card7 from '../images/FirstBday-Card7.jpg';
import Card8 from '../images/FirstBday-Card8.jpg';
import Card9 from '../images/FirstBday-Card9.jpg';

const  FirstBirthday = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Rebel 1st Birthday"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Complete one badge from every section of the badge book</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>This is well evidenced throughout this site! I have now completed 51 badges, more than one from each section.</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Make a birthday cake and eat a slice</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>I made a mini pound cake birthday cake and enjoyed more than a slice!! It had light blue, lemon flavoured buttercream icing and a purple candle in the middle!</p>
                        <br/>
                        <div className='gallery'>
                            <div className='galleryItem'><img src={Cake1} alt="A freshly baked mini cake in a spring form pan" /></div>
                            <div className='galleryItem'><img src={Cake2} alt="A freshly baked mini cake on a blue plate" /></div>
                            <div className='galleryItem'><img src={Cake3} alt="A freshly baked mini cake on a blue plate next to a bowl of buttercream icing" /></div>
                            <div className='galleryItem'><img src={Cake4} alt="A freshly baked mini cake on a white plate, iced with a light blue buttercream icing with a single purple candle in the middle" /></div>
                            <div className='galleryItem'><img src={Cake5} alt="A freshly baked mini cake on a white plate with a small slice cut out and laid on the side" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Learn how to sing or sign Happy Birthday in another language</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>I can sing happy Birthday in French.</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Make a Rebel Birthday card and share it on the group</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>I made a simple Pergamano birthday card with little butterflies, using the Rebel purple and blue colours. I didn't share in group because I'm backdating and this is almost 2 years old now!</p>
                        <br/>
                        <div className='gallery'>
                            <div className='galleryItem'><img src={Card1} alt="Making a Pergamano birthday card" /></div>
                            <div className='galleryItem'><img src={Card2} alt="Making a Pergamano birthday card" /></div>
                            <div className='galleryItem'><img src={Card3} alt="Making a Pergamano birthday card" /></div>
                            <div className='galleryItem'><img src={Card4} alt="Making a Pergamano birthday card" /></div>
                            <div className='galleryItem'><img src={Card5} alt="Making a Pergamano birthday card" /></div>
                            <div className='galleryItem'><img src={Card6} alt="Making a Pergamano birthday card" /></div>
                            <div className='galleryItem'><img src={Card7} alt="Making a Pergamano birthday card" /></div>
                            <div className='galleryItem'><img src={Card8} alt="Making a Pergamano birthday card" /></div>
                            <div className='galleryItem'><img src={Card9} alt="Making a Pergamano birthday card" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FirstBirthday