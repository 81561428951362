import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Medal1 from '../images/annual-adventure24-walk1.jpg'
import Medal2 from '../images/annual-adventure24-walk2.jpg'
import Medal3 from '../images/annual-adventure24-walk3.jpg'

const  Adventure24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Big Rebel Adventure 2024"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Walk 26 miles or climb 3000m - the equivalent of the 3 Peaks</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence"> 
                         <p>I am actually doing both the 26miles and 3000m for this challenge.</p>  
                         <br/>   
                         <p>I have already walked the 26 miles, and earned 3 medals from Medal Mad to prove that I covered the expected distance. Converting and rounding up, they come to 26miles, I have walked further than this though so I can confidently say I have covered enough distance!</p> 
                         <br/>
                         <p>I have the Under the Sea, Viking and Las Vegas medals (I love the viking one!!)</p>
                         <br/>
                        <img src={Medal1} alt="" className='imgQuarter'/>&nbsp;
                        <img src={Medal2} alt="" className='imgQuarter'/>&nbsp;
                        <img src={Medal3} alt="" className='imgQuarter'/>&nbsp;
                         <br/><br/>
                         <p>I am using my stairs indoors to do the climbing part. I've worked out how elevation is calculated on my Gear Fit and calculated how many floors I need to climb each day to get to the total by the end of June! I'll add the evidence when I've accumulated it.</p>
                         <br/>
                         <p>At 5/1/24, I have done 32 of 167 floors!</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Adventure24