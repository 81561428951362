import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Ingredients from '../images/cupjuly23-ingredients.jpg';
import Sundae1 from '../images/cupjuly23-sundae1.jpg';
import Sundae2 from '../images/cupjuly23-sundae2.jpg';
import Sundae3 from '../images/cupjuly23-sundae3.jpg';
import Sundae4 from '../images/cupjuly23-sundae4.jpg';
import Sundae5 from '../images/cupjuly23-sundae5.jpg';
import Sundae6 from '../images/cupjuly23-sundae6.jpg';
import Sundae7 from '../images/cupjuly23-sundae7.jpg';
import Sundae8 from '../images/cupjuly23-sundae8.jpg';
import Sundae9 from '../images/cupjuly23-sundae9.jpg';
import Sundae10 from '../images/cupjuly23-sundae10.jpg';
import Sundae11 from '../images/cupjuly23-sundae11.jpg';
import Sundae12 from '../images/cupjuly23-sundae12.jpg';
import Sundae13 from '../images/cupjuly23-sundae13.jpg';
import Sundae14 from '../images/cupjuly23-sundae14.jpg';
import Sundae15 from '../images/cupjuly23-sundae15.jpg';
import Sundae16 from '../images/cupjuly23-sundae16.jpg';
import Sundae17 from '../images/cupjuly23-sundae17.jpg';
import Pie1 from '../images/cupjuly23-pie1.jpg';
import Pie2 from '../images/cupjuly23-pie2.jpg';
import Pie3 from '../images/cupjuly23-pie3.jpg';
import Pie4 from '../images/cupjuly23-pie4.jpg';
import Pie5 from '../images/cupjuly23-pie5.jpg';
import Pie6 from '../images/cupjuly23-pie6.jpg';
import Poem1 from '../images/cupjuly23-poem.png';
import Poem2 from '../images/cupjuly23-poem1.png';



const CupJul23 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Rebel Cup Bonus Points"} />
            <div className="badgePageItem">Rebel Cup challenges are only posted in the facebook group for confirmed members. You will have to join the club to find out why I am doing these things!</div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Ice Cream Sundae</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>My sundae is home made dairy free (vegan) ice cream with home made pineapple syrup, served in a coconut or a pineapple.</p> 
                        <br/>
                        <p>I started prepping this before I made pie as it had several things that needed time to marinate!</p>           
                        <br/>
                        <p>Firstly, I couldn't get coconut flakes as called for by the recipe, so I bought coconuts and made my own! I toasted them in a pan then left them to marinate for 2 hours in the cocounut milk.</p>
                        <br/>
                        <p>Opening coconuts isn't easy 😂</p>
                        <br/>
                        <p>Next, I needed to prepare the syrup.This uses the core of a pineapple and the skin and flesh of a squeezed lime or lemon - I used lime. I squeezed the lime juice and left that aside for later use, then cored the pineapple and saved the flesh for later. I added the sugar and left to marinate overnight.</p>
                        <br/>
                        <p>Once the coconut flakes had marinated, I strained them out and added the remaining ingredients to make the ice cream.</p>
                        <br/>
                        <p>Now, I don't have an ice cream maker so I used a no-churn method. I put the mixture into a zip-lock bag and laid it in the freezer. Stirred occassionally, left overnight.</p>
                        <br/>
                        <p>I strained the pineapple and lime remains to get the syrup. It was very runny so I heated it to thicken and added some cornflour - then I vastly over-thickened and had to quickly reduce in a pan before adding to my ice cream!</p>
                        <br/>
                        <p>Meanwhile, I prepared the coconut and pineapple "bowls" and put them in the freezer for a couple of hours to ensure they were cold when I added the ice cream.</p>
                        <br/>
                        <p>Whilst waiting for everything to do what it needed, I made a Pina Colada cocktail - you can't do all this and not have the cocktail, right?! (Just as well I'd already done my poem!!)</p>
                        <br/>
                        <p>I knew I had to move quickly when I was ready to go as the ice cream melts very fast when not churned - think super soft scoop!</p>
                        <br/>
                        <p>I managed to get it into the bowls, drizzled my syrup over the top, added a cherry on top, some little flower sprinkles and an umbrella. Very exotic!!</p>
                        <br/>
                        <p>Not going to lie, these taste super good (and are very dangerous being dairy-free as it means I can actually eat them!!)</p>
                        <br/>
                        <p>Oh. The ice cream has rum in. Did I tell you that? ;)</p>
                        <br/><br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Ingredients} alt="Ingredients for Sundae and Pecan Pie" /></div>
                            <div className="galleryItem"><img src={Sundae1} alt="Coconuts, hammer and screwdriver" /></div>
                            <div className="galleryItem"><img src={Sundae2} alt="One cracked coconut with one sealed coconut" /></div>
                            <div className="galleryItem"><img src={Sundae3} alt="One crqacked coconut and another with a crack ready to open" /></div>
                            <div className="galleryItem"><img src={Sundae4} alt="Two coconuts that have been cracked open" /></div>
                            <div className="galleryItem"><img src={Sundae5} alt="Coring pineapple and slicing lime flesh" /></div>
                            <div className="galleryItem"><img src={Sundae6} alt="Fresh coconut flakes in a frying pan being toasted" /></div>
                            <div className="galleryItem"><img src={Sundae7} alt="Freshly toasted coconut flakes in a frying pan" /></div>
                            <div className="galleryItem"><img src={Sundae8} alt="Coconut milk in a bowl with toasted coconut flakes" /></div>
                            <div className="galleryItem"><img src={Sundae9} alt="Pineapple core and lime flesh in a bowl with sugar, marinating" /></div>
                            <div className="galleryItem"><img src={Sundae10} alt="Syrup being strained from pineapple core and lime flesh mixture" /></div>
                            <div className="galleryItem"><img src={Sundae11} alt="Syrup in a ramekin bowl" /></div>
                            <div className="galleryItem"><img src={Sundae12} alt="Syrup dropping off a teaspoon into a ramekin bowl" /></div>
                            <div className="galleryItem"><img src={Sundae13} alt="Ice cream in a zip lok bag with half a coconut and the bottom of a pineapple" /></div>
                            <div className="galleryItem"><img src={Sundae14} alt="Two completed sundaes, one served in a coconut half the other in a pineapple half, with a Pina Colada cocktail" /></div>
                            <div className="galleryItem"><img src={Sundae15} alt="Close up of a coconut sundae served in a bowl made from the base of a pineapple" /></div>
                            <div className="galleryItem"><img src={Sundae16} alt="Close up of a coconut sundae served in a bowl made from a coconut half" /></div>
                            <div className="galleryItem"><img src={Sundae17} alt="Pima Colada cocktail in a glass with an umbrella, a cherry, piece of pineapple and pineapple leaves for decoration." /></div>
                        </div>
                        <br/><br/>
                        <p>Here are the recipes that I used to help me with this:</p>
                        <ul className='list'>
                            <li>https://www.theguardian.com/food/2021/jul/28/how-to-make-the-perfect-vegan-coconut-ice-cream-recipe</li>
                            <li>https://www.seriouseats.com/fresh-pineapple-syrup</li>
                            <li>https://www.bbc.co.uk/food/recipes/pinacolada_86525</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Pecan Pie</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>I didn't do a pecan pie themed sundae, but I decided to make the pie anyway as I haven't ever made one before.</p>
                        <br/>
                        <p>I made the shortcrust pastry first and cooked that in a pie dish, then mixed up the ingredients, added them into the crust and cooked it!</p>
                        <br/>
                        <p>I'm already a huge fan of the Pecan and Maple twists you can buy in bakeries, this is like a huge version of that. It is extremely sweet but very tasty. It's been quickly snapped up by the family, so hopefully they've enjoyed it too!</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Ingredients} alt="Ingredients for Sundae and Pecan Pie" /></div>
                            <div className="galleryItem"><img src={Pie1} alt="Butter and flour in a bowl being rubbed together for pastry" /></div>
                            <div className="galleryItem"><img src={Pie2} alt="Butter and flour in a bowl, rubbed together to make pastry" /></div>
                            <div className="galleryItem"><img src={Pie3} alt="Freshly made shortcrust pastry in a pie dish" /></div>
                            <div className="galleryItem"><img src={Pie4} alt="Pecan Pie ingredients added to a shortcrust pie" /></div>
                            <div className="galleryItem"><img src={Pie5} alt="Cooked Pecan Pie" /></div>
                            <div className="galleryItem"><img src={Pie6} alt="A slice of freshly cooked Pecan Pie" /></div>
                        </div>
                        <p>Here's the recipe that I used:</p>
                        <ul className='list'>
                            <li>https://www.bbcgoodfood.com/recipes/new-england-pecan-pie</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Poem</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>Here is the poem that I wrote about my work:</p>
                        <br/>
                        <img src={Poem1} alt="Poem typed out on a red background." />
                        <br/>
                        <p>I added this to complement it, it's a page from a sketchbook that I did called "A narrative of me" and shows some of the languages that make me "multi-lingual" as clained in the poem!</p>
                        <img src={Poem2} alt="Page from a sketchbook showing a computer screen with code on and some hand-drawn icons." />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CupJul23