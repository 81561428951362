import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import LaravelCert from '../images/LaravelCert.jpg';

const Coder = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"Coder"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Part 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Learn a new language</div>
                    <div className="badgePageItemEvidence">
                        <p>I am splitting this badge into different parts, some backdated, some current as I have done a lot of work in a lot of languages so have done more than enough to claim this badge without doing anything else, but in the spirit of badge work I wanted to do something new as well so as not to entirely backdate! I chose to use my backdated Python / AI training as that hasn't been used towards any other badges.</p>
                        <br/>
                        <p><strong>Part 1 - Python</strong></p>
                        <p>A couple of years ago I took a CS50 AI with Python course with HarvardX. This had me learning Python, where I had to create various applications to put the knowledge into practice.</p>
                        <p>That definitely satisfied the requirement for hours of training!</p>
                        <br/><br/>
                        <p><strong>Part 2 - Laravel, Vue, Inertia</strong></p>
                        <p>Recently, I have been learning to work in Laravel, using PHP syntax, Vue 3 and Inertia.</p>
                        <p>I completed a course through Udemy, this was a total of 36 hours tutor lead training, with full hands on labs, building a site from scratch.</p>
                        <p>Here's my certificate:</p>
                        <br/>
                        <img src={LaravelCert} alt="Certificate awarded for Laravel training" className="galleryItem" />
                        <br/>
                        <p>I will be taking an additional course to supplement this as the project I want to work on will include the use of Bulma CSS as well.</p>
                        <br/>
                    </div>
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Part 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Create, test and debug</div>
                    <div className="badgePageItemEvidence">
                    <p><strong>Python</strong></p>
                    <p>I spent hours working on these projects! To say they have been tested and debugged to death is an understatement!!</p>
                    <br/><br/>
                    <p><strong>Laravel</strong></p>
                        <p>I am splitting this in to two parts as what I am doing doesn't 100% fit the criteria - the PHP side of it does. Firstly, we developed a brand new project during training and so that is part one.</p>
                        <br/>
                        <p>Secondly, I actually wanted to do this training to support an open source project that urgently needs developers.  They want help to develop and maintain their software and although I am a developer I didn't have the correct skills to help with this framework.</p>
                        <br/>
                        <p>I have used the project for a few years and am hoping now to be able to contribute more.</p>
                        <br/>
                        <p>I have downloaded the repo and set up my test environment so that I can work on it locally.</p>
                    </div>
                </div>
            </div>          
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Part 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Demonstrate</div>
                    <div className="badgePageItemEvidence">
                        <p><strong>Python</strong></p>
                        <p>As part of the assessment process I had to record my python apps and upload to YouTube, they are still visible there so you can see how I got on!  I figured it out eventually!</p>
                        <ul className="list">
                            <li className='linkUnderline'><a href="https://youtu.be/a4pO5VxnPfw">Tic Tac Toe</a></li>
                            <li className='linkUnderline'><a href="https://youtu.be/P_hvn9ioalg">Degrees</a></li>
                            <li className='linkUnderline'><a href="https://youtu.be/if-OJ_3gCbA">Knights</a></li>
                            <li className='linkUnderline'><a href="https://youtu.be/p5jayuKiqJY">Minesweeper</a></li>
                        </ul>
                        <br/><br/>
                        <p><strong>Laravel</strong></p>
                        <p>I will be working from the project's trello board to pick up any issues that need attention. I am focusing on the Web side rather than the app side - they seem to have more input to the app side, but I personally use their web product so I'm happy to champion that!</p>
                        <br/>
                        <p>I'm not going to link it here until I can claim to have made a difference to the project! The Python projects will have to satisfy this clause.</p>
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default Coder