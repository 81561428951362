import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';



const November24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"I'd Survive a Zombie Apocalypse"} />
            <p><strong>This page provides my evidence for the I'd survive a Zombie Apocalypse monthly badge.  The information for this badge is available publicly on the Rebel Badge Store Noticeboard so descriptions haven't been removed.</strong></p>
            <br/>
            <p>The information on this page is a summary of what I did to meet the clauses - it isn't necessarily representative of all my research and work.</p>
            
            <div className="badgePageItem">
                <div className="badgePageItemTitle orange">Coming soon!</div>
                <div className="badgePageItemContent">
                   
                </div>
            </div>
        </div>
    )
}

export default November24