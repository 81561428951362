import React from 'react';
import {Link} from 'react-router-dom';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const  Kickstart24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Kickstart 2024"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Complete one of two possible badges</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>See my <Link to="/goodhabits" className="linkUnderline">Good Habits page</Link></p>
                        <br/>
                        <p>I had previously completed Good Habits and My Goals takes 6-12 months, so I am using Good Habits for this clause.</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Wellness badge</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>This will have to be the <Link to="/walker" className="linkUnderline">Walker</Link> badge! I hadn't planned on doing it, but it works nicely with my walking challenge I have running for my Silver Maverick.</p>                 
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Grown Up badge</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>I am doing the <Link to="/detective" className="linkUnderline">Detective</Link> badge with two rebel buddies!!</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Start a longer-term badge</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>I have started <Link to="/mygoals" className="linkUnderline">My Goals</Link>, which will take me 6-12 months (I have a 12 month target)</p>                    
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">12 goals</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <ul className='list'>
                            <li>500 mile walk / 1% Club - active every day</li>
                            <li>At least one training course each month - in any subject</li>
                            <li>Complete 365 days of Creativity course - but only doing 1 video per day!</li>
                            <li>Do more drawing</li>
                            <li>Beach Cleans at least twice a week (weather permitting!)</li>
                            <li>Make more time for reading</li>
                            <li>Create new stock ideas for Gifted Cards</li>
                            <li>Take more photographs for Moments and Moxie</li>
                            <li>Relaunch Gifted Cards with new product range</li>
                            <li>Promote Moments and Moxie - make first sale!</li>
                            <li>Achieve Silver Maverick</li>
                            <li>Achieve Chief Rebel's Badge</li>
                            <li>Re-launch Christchurch Marine Conservation</li>
                            <li>Increase engagement with Plastic Free Highcliffe</li>
                        </ul>                    
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">4 goals for 3 months</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>The four goals I will be focussing on are:</p>
                        <ul className="list">
                            <li>500 mile walk / 1% club - at least 1 mile walk every day, ensures I am active every day and plants trees!</li>
                            <li>One training course per month - three already lined up</li>
                            <li>Beach cleans at least twice a week (weather permitting) - to ensure I keep motivation to go whilst mornings are dark and cold!</li>
                            <li>Make more time for reading - enjoyed reading more for Advanced Reader, will be keeping up for 52 books in a year.</li>
                        </ul>      
                        <br/>
                        <p><strong>January Update:</strong></p>
                        <p>I have walked 45.45 miles and climbed 252 floors towards my various targets. I have been active for 31 days in January so have planted 31 trees.</p>            
                        <p>I completed an OpenAI bootcamp course, earning another certificate for my collection.</p>
                        <p>I have done beach cleans as often as weather permits, not always twice a week but at least once a week.</p>
                        <p>I have finished another 4 books in January.</p>
                        <br/>
                        <p><strong>February Update:</strong></p>
                        <p>I have walked 35.46 miles and climbed 176 floors towards my various targets. I have been active for 20 days in February so have planted 20 trees. I couldn't keep up the daily rate this month due to illness, so actually these numbers are pretty good.</p>            
                        <p>I completed an Introduction to branding course with Centre of Excellence, earning another certificate for my collection.</p>
                        <p>I have done beach cleans as often as weather permits, which with this month's weather has not been very often!</p>
                        <p>I have continued reading as often as possible, including 300 pages on Nikola Tesla for my Historian badge.</p>
                        <br/>
                        <p><strong>March Update:</strong></p>
                        <p>I have walked 55.05 miles and climbed 337 floors towards my various targets. I have been active for 26 days in March so have planted 25 trees.</p>            
                        <p>I have continued with my 365 days of Creativity training.</p>
                        <p>I have done beach cleans as often as weather permits and these will count towards the Big Rebel Spring Clean.</p>
                        <p>I have continued reading as often as possible and finished the 52 books in a year challenge.</p>
                        <br/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Kickstart24