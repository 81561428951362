import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const Musician = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Musician"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">01</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Practise</div>
                    <div className="badgePageItemInfo reverse hidden">Take time out of your week to practise an instrument for at least 3 months.</div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">02</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Learn</div>
                    <div className="badgePageItemInfo reverse hidden">Find a song or piece of music which you cannot currently play and set yourself the challenge of learning to play it.</div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">03</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Sight reading</div>
                    <div className="badgePageItemInfo reverse hidden">Practise sight reading and play a new piece of music by sight reading.</div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">04</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Performance</div>
                    <div className="badgePageItemInfo reverse hidden">Prepare a 15 minute musical programme to perform to friends or family.</div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">05</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Composition</div>
                    <div className="badgePageItemInfo reverse hidden">Create and perform a 16 bar composition.</div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div>
        </div>
    )
}

export default Musician