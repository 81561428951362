import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const MyChange = () => {
    return (
        <div>
            <ActionBar area={"silver"}  />
            <EvidenceHeader title={"My Change"} />
            <div className="mavPageItem">
                <div className="mavPageItemTitle silver">My Change</div>
                <div className="mavPageItemContent">
                    <div className="mavPageItemInfo silver-light">Over a period of at least twelve months, make a positive change to your life, your neighbourhood, or your community.  The change should be specific, measurable and achievable. Prepare a presentation at the end of 12 months.</div>
                    <div className="mavPageItemEvidence">
                    
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyChange