import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import SMTimes from '../images/bronze-mybeliefs1.png';
import Beliefs2 from '../images/bronze-mybeliefs2.png';
import Beliefs3 from '../images/bronze-mybeliefs3.png';
import Beliefs4 from '../images/bronze-mybeliefs4.png';
import Beliefs5 from '../images/bronze-mybeliefs5.png';
import Beliefs6 from '../images/bronze-mybeliefs6.png';
import Beliefs7 from '../images/bronze-mybeliefs7.png';
import Beliefs8 from '../images/bronze-mybeliefs8.jpg';
import Beliefs9 from '../images/bronze-mybeliefs9.png';
import Beliefs10 from '../images/bronze-mybeliefs10.png';
import Beliefs11 from '../images/bronze-mybeliefs11.png';
import Beliefs12 from '../images/bronze-mybeliefs12.png';
import Beliefs13 from '../images/bronze-mybeliefs13.png';
import Beliefs14 from '../images/bronze-mybeliefs14.png';
import Beliefs15 from '../images/bronze-mybeliefs15.png';
import Beliefs16 from '../images/bronze-mybeliefs16.png';
import Beliefs17 from '../images/bronze-mybeliefs17.png';
import MMOption1 from '../images/mmoption1.png';
import MMOption2 from '../images/mmoption2.png';
import MMOption3 from '../images/mmoption3.png';
import MMFinal from '../images/PFHStamp-MM.png';
import WWFinal from '../images/PFHStamp-WW.png';
import PFFinal from '../images/PFHStamp-PFF.png';
import MMBlank from '../images/MMBlank.png';
import WWBlank from '../images/WWBlank.png';
import PFBlank from '../images/PFFBlank.png';
import MMSample from '../images/MMSample.png';
import WWSample from '../images/WWSample.png';
import PFSample from '../images/PFFSample.png';
import BeliefsP from '../documents/Bronze-MyBeliefs.pptx';


const MyBeliefs = () => {
    return (
        <div>
            <ActionBar area={"bronze"} />
            <EvidenceHeader title={"My Beliefs"} />
            <div className="mavPageItem">
                <div className="mavPageItemTitle brown">My Beliefs</div>
                <div className="mavPageItemContent">
                    <div className="mavPageItemInfo brown-light">Complete a project related to a cause or belief which you feel strongly about.</div>
                    <div className="mavPageItemEvidence">
                        <p>A few years ago I signed up with Surfers Against Sewage to lead a Plastic Free Community (PFC) and started up <a href="https://www.plasticfreehighcliffe.org.uk/" target="_blank" rel="noopener noreferrer">Plastic Free Highcliffe-on-Sea</a> (PFH).</p>
                        <br/>
                        <p>I made a good start but started to struggle with keeping the momentum going and getting people involved.</p>
                        <br/>
                        <p>Then the pandemic hit and I let it go entirely, with the exception of maybe one or two odd facebook posts. I didn't feel that it was an appropriate time to being pushing this agenda when the world was in turmoil, with people and businesses suffering.</p>
                        <br/>
                        <p>As the has lapsed for over 2 years now, I feel comfortable in using it as a measurable project here to get it up and running again and, hopefully, to eventually achieve plastic free status for Highcliffe!</p>
                        <br/>
                        <p>To be clear, I'm not expecting to have plastic free status in six months as a goal here, but I do want to have a strategy and an up-and-running service with regular community engagement to get it well on its way to the final goal.</p>
                        <br/><br/>
                        The steps that I need to take to reach this goal are:
                        <ol className="mavList">
                            <li>Social media bootcamp! I need to improve my skills to get engagement.</li>
                            <li>Re-visit my PFH email account and read all the emails from the Plastic Free Communities team to get up to date with all the changes.</li>
                            <li>Log in to my personal PFH dashboard to check it's up to date and showing progress</li>
                            <li>Contact the PFC teams to let them know I'm back!</li>
                            <li>Sign up to any of the PFC masterclasses that are advertised</li>
                            <li>Research the latest news and developments in the world of plastics and sustainability</li>
                            <li>Formulate a social media content plan</li>
                            <li>Gather images, subjects and links to generate blog content</li>
                            <li>Create graphics to be used in social media posts</li>
                            <li>Create blog posts to accompany social media posts</li>
                            <li>Create a posting schedule using Hootsuite to reduce the time needed for regular posting</li>
                            <li>Make a noise locally to try and get people to re-engage with the project</li>
                            <li>Connect with other local groups going through the same process. A little friendly co-operation - or even competition - can't hurt!</li>
                        </ol>
                        <br/>
                        <p>Things don't have to happen in that order and I'm sure things will be added but that's quite a lot to be getting on with.</p>
                        <br/>
                        <hr/>
                        <br/>
                        <div className="mavHeading"><h3 className="bronze">Social Media bootcamp</h3></div>
                        <p>I have started looking into social media tips and tricks. I found this infographic interesting - taken from <a href="https://10x-marketing.co.uk/creating-social-media-content/" target="_blank" rel="noopener noreferrer">10x Marketing</a></p>
                        <img className="beliefsImg" src={SMTimes} alt="Social media posting times" />
                        <br/><br/>
                        <p>There are handy suggestions from this blog: <a href="https://blog.paperturn.com/blog/how-to-create-social-media-content" target="_blank" rel="noopener noreferrer">https://blog.paperturn.com/blog/how-to-create-social-media-content</a></p>
                        <ol className="mavList">
                            <li>Capture attention with videos</li>
                            <li>A joke or meme goes a long way</li>
                            <li>Show your team in action</li>
                            <li>Get your customers involved</li>
                            <li>Create engaging and interactive flipbooks</li>
                            <li>Use gifs</li>
                            <li>Share major achievements</li>
                            <li>Follow an events calendar</li>
                            <li>Create infographics</li>
                            <li>Hop on the trends</li>
                        </ol>
                        <br/><br/>
                        <p>and more from this site: <a href="https://www.postplanner.com/blog/how-to-create-a-social-media-plan/" target="_blank" rel="noopener noreferrer">https://www.postplanner.com/blog/how-to-create-a-social-media-plan/</a></p>
                        <br/>
                        <ol className="mavList">
                            <li>Don't (only) talk about yourself</li>
                            <li>Think about native social video</li>
                            <li>Establish your posting schedule</li>
                            <li>Find the best time to post on Facebook</li>
                            <li>Find the best times to post on Twitter, Instagram, LinkedIn and Pinterest</li>
                            <li>Find the best frequency for posting on each network</li>
                            <li>Build your social media calendar</li>
                            <li>Establish which metrics you'll monitor for success</li>
                        </ol>
                        <br/><br/>
                        <p><a href="https://blog.hootsuite.com/content-idea-cheat-sheet/" target="_blank" rel="noopener noreferrer">Hootsuite have got a lot of really helpful information</a> which I will be reading more of as soon as possible:</p>
                        <p>They also have <a href="https://blog.hootsuite.com/resources/" target="_blank" rel="noopener noreferrer">a range of free tools</a> which I will be checking out as well!</p>
                        <p>The other useful tool here is a <a href="https://blog.hootsuite.com/weird-holidays-to-celebrate-on-social-media/" target="_blank" rel="noopener noreferrer">social media holiday calendar</a> full of days you can get involved with!</p>
                        <br/><br/>
                        This was a useful video on social media engagement hacks, strategies and tips: <a href="https://www.youtube.com/watch?v=kCy2adkj4HM" target="_blank" rel="noopener noreferrer">https://www.youtube.com/watch?v=kCy2adkj4HM</a> and offered the following advice:
                        <ol className="mavList">
                            <li>Post consistently</li>
                            <li>Leverage your personal profile - Share to your personal page to boost exposure</li>
                            <li>Engage First - Like, comment and post, not passive scroll</li>
                            <li>Comment and interact as your business</li>
                            <li>Follow the follow</li>
                            <li>Talk to people, not at them - Invite people to be a part of the conversation</li>
                            <li>Comment Bump - Don't respond straight away to comments - wait for a while them comment, bumps it back up the feed.</li>
                            <li>Leverage facebook groups</li>
                        </ol>
                        <br/>
                        <p>I also followed these two videos:</p>
                        <p><a href="https://www.youtube.com/watch?v=9M0Oa7miOoU" target="_blank" rel="noopener noreferrer">How to build a powerful social media strategy in 2022</a></p>
                        <p><a href="https://www.youtube.com/watch?v=4ajmfzj9G1g" target="_blank" rel="noopener noreferrer">7 Effective Marketing Strategies for 2022</a></p>
                        <br/><br/>
                        <p>This video concentrated on Nonprofits, which is always helpful as it gives another perspective and not all techniques are appropriate for what I need. </p>
                        <p><a href="https://www.youtube.com/watch?v=IwMSxbjceBs" target="_blank" rel="noopener noreferrer">Social Media for Nonprofts: 6 essential Strategies for Success</a></p>
                        <ol className="mavList">
                            <li>Visual Identity, add small watermark to content, be consistent</li>
                            <li>Create a social media calendar, be consistent</li>
                            <li>Use your brand voice consistently, what you say and how you say it</li>
                            <li>Say more with less words, keep it short</li>
                            <li>Engage your audience - interact with posts that mention you, interact with posts of passionate people, partner with like-minded organisations.</li>
                            <li>Have fun</li>
                        </ol>
                        <br/>
                        <p>This video provided some information on the apps that can be used to help manage social media content</p>
                        <p><a href="https://www.youtube.com/watch?v=mdiiXG6jdGU" target="_blank" rel="noopener noreferrer">Best apps for creating and managing social media content 2022</a></p>
                        <ol className="mavList">
                            <li>Descript - podcast and video editing software, complete production tool</li>
                            <li>CapCut - phone only, quick video editing (connects with TikTok)</li>
                            <li>Lightroom - photo editing (use with Snapseed)</li>
                            <li>Canva - content creation</li>
                            <li>Flick - hashtag research tool</li>
                            <li>Later - scheduling</li>
                            <li>Notion - project management workspace</li>
                            <li>Forest - activity timer</li>
                        </ol>
                        <br/>
                        <p>This video gave some insight on what people wished they knew before starting with content creation</p>
                        <p><a href="https://www.youtube.com/watch?v=Kw0bPnkipkA" target="_blank" rel="noopener noreferrer">7 things I wish I Knew as a beginner content creator</a></p>
                        <ol className="mavList">
                            <li>Stop waiting - just start, FOBO - fear of better options!</li>
                            <li>Monetize early</li>
                            <li>Not for everyone - you won't appeal to everyone, you will get opposing critique</li>
                            <li>Stick to the plan - come up with a plan and stick to it</li>
                            <li>Love your data - use analytics / insights</li>
                            <li>Do it your way - stand out and be yourself, don't copy competitors</li>
                            <li>Set clear boundaries</li>
                        </ol>
                        <br/>
                        <p>This was a useful watch to learn about common mistakes to avoid!</p>
                        <p><a href="https://www.youtube.com/watch?v=Kw0bPnkipkA" target="_blank" rel="noopener noreferrer">10 common instagram mistakes</a></p>
                        <ol className="mavList">
                            <li>Not including captions to stories and videos</li>
                            <li>Skipping captions under posts</li>
                            <li>Not including a link in your bio</li>
                            <li>Not using story highlights</li>
                            <li>Having a bad bio</li>
                            <li>Using hashtags in your bio</li>
                            <li>Posting videos with no covers </li>
                            <li>Not engaging</li>
                            <li>Buying followers</li>
                            <li>Using bots or engagement pods</li>
                            <li>Not being consistent</li>
                        </ol>
                        <br/><br/>
                        <p>That's enough of that. There's a lot of useful information although what I do still find frustrating is that there isn't much specific advice on engagement for people who aren't trying to sell anything or make a profit. There's still very helpful information though so, armed with this knowledge, let's proceed!</p>
                        <br/><br/>
                        <hr/>
                        <br/>
                        <div className="mavHeading"><h3 className="bronze">Re-visit my PFH email account and read all the emails from the Plastic Free Communities team to get up to date with all the changes.</h3></div>
                        <br/>
                        <p>Hard to evidence this one, but here's a very empty inbox with nothing marked "Unread"!</p>
                        <img className="beliefsImg" src={Beliefs2} alt="Screeenshot of a gmail inbox" />
                        <br/>

                        <br/><br/>
                        <hr/>
                        <br/>
                        <div className="mavHeading"><h3 className="bronze">Log in to my personal PFH dashboard to check it's up to date and showing progress</h3></div>
                        <br/>
                        <p>I logged into my dashboard and made the required updates. Here's a screenshot of the dashboard and a confirmation that I had made updates that had been seen by the PFC Team.</p>
                        <br/>
                        <img className="beliefsImgLarger" src={Beliefs3} alt="Screenshot of a plastic free communities dashboard" /><br/>
                        <img className="beliefsImgLarger" src={Beliefs4} alt="Email from the PFC team to confirm updates have been made" />
                        <br/><br/>
                        <hr/>
                        <br/>
                        <div className="mavHeading"><h3 className="bronze">Contact the PFC teams to let them know I'm back!</h3></div>
                        <br/>
                        <p>Here's my email to the team and their reply.</p>
                        <br/>
                        <img className="beliefsImgLarger" src={Beliefs5} alt="Email content" /><br/>
                        <img className="beliefsImgLarger" src={Beliefs6} alt="Email content" />
                        <br/><br/>
                        <hr/>
                        <br/>
                        <div className="mavHeading"><h3 className="bronze">Sign up to any of the PFC masterclasses that are advertised</h3></div>
                        <br/>
                        <p>Here is confirmation that I signed up to the next masterclass.  Unfortunately on the day I wasn't able to attend due to work priorities, but I have found since that the masterclasses are uploaded and shared through the dashboard, so I am making my way through them.</p>
                        <br/>
                        <img className="beliefsImgLarger" src={Beliefs7} alt="Email content" />
                        <br/><br/>
                        <hr/>
                        <br/>
                        <div className="mavHeading"><h3 className="bronze">Formulate a social media content plan</h3></div>
                        <br/>
                        <p>A plan. Where to start?! I needed to decide on a balance of information to share, frequency and how to make that relevant to people. I started brainstorming, here's some random thoughts I wrote down early on, a couple of these survived!</p>
                        <img className="beliefsImg" src={Beliefs8} alt="Handwritten notes in a notebook" />
                        <br/>
                        <p>I felt that one post a week wasn't enough to keep people engaged and didn't allow me to share as much as I would like to. To get people thinking about making changes you need to be able to show why there is a problem and the impact, not just tell people to make switches. It also needs to be relatable, for example telling someone about an problem half way around the world doesn't always make it a priority, so it's important to be able to tie that to a local issue that is closer to home and gives added motivation.</p>
                        <br/>
                        <p>I eventually settled on three posts per week:</p>
                        <ul className="mavList">
                            <li>Monday Minute - a statistic or short piece of information, no more than a one minute read</li>
                            <li>Worldwide Wednesdays - relevant stories from around the world, short form copy with link to more information</li>
                            <li>Plastic Free Fridays - talking about how to get involved, plastic free swaps and actions that people can realistically take</li>
                        </ul>
                        <br/>
                        <p>I also wanted to make sure I was talking about popular commercial holidays and how people could reduce their plastic waste around these times, so those had to be built in to the schedules.</p>
                        <br/>
                        <p>I need to be able to post regularly and maintain consistency in posting. The best way for me to do this was to prepare a year's worth of content ahead of time so I wasn't panicking throughout the year about what to do and skipping dates! Although the content is pre-prepared it is entirely flexible, so if something happens during the year that is more important I can switch content out, but at least I have content ready to go.</p>
                        <p>This is quite a lot of content to find but I embraced the challenge!</p>
                        <br/>
                        <p>Using tools at hand, I set up a spreadsheet! With a tab each for Monday Minute, Wordlwide Wednesdays and Plastic Free Fridays I started by populating the publish dates. That gave me the framework to work from.</p>
                        <br/><br/>
                        <hr/>
                        <br/>
                        <div className="mavHeading"><h3 className="bronze">Gather images, subjects and links to generate blog content</h3></div>
                        <br/>
                        <p>I started the process of finding content. I started with my Monday Minute subjects, tracking down statistics and survey results - anything that provided short form content. Here are my sources:</p>
                        <ul className="mavList">
                            <li>assets.publishing.service.gov.uk</li>
                            <li>blog.themodernmilkman.co.uk</li>
                            <li>businesswaste.co.uk</li>
                            <li>commonslibrary.parliament.uk</li>
                            <li>eandt.theiet.org</li>
                            <li>earth.org</li>
                            <li>ecowatch.com</li>
                            <li>factretriever.com</li>
                            <li>gov.uk</li>
                            <li>nurdlehunt.org.uk</li>
                            <li>readkong.com</li>
                            <li>ribble-pack.co.uk</li>
                            <li>sdgs.un.org</li>
                            <li>seashorty.co.uk</li>
                            <li>seedscientific.com</li>
                            <li>statista.com</li>
                            <li>uk.whales.org</li>
                            <li>wastemanaged.co.uk</li>
                            <li>wrap.org.uk</li>
                            <li>yougov.co.uk</li>
                        </ul>
                        <br/>
                        <p>I started with the content, adding the words that I wanted to include in the associated image. Once I had 52 items that I was happy with, I added the copy - the extra information for the content of the social post.</p>
                        <br/><br/>
                        <p>Once I had the Monday Minutes tied down, I made a start of Worldwide Wendesdays. I followed the same process for this.</p>
                        <p>One major source for finding content was the <a href="https://www.newsnow.co.uk/h/?search=plastic&lang=en" target="_blank" rel="noopener noreferrer">NewsNow</a> aggregator site </p>
                        <br/>
                        <p>Here are my sources for articles:</p>
                        <ul className="mavList">
                            <li>bbc.co.uk</li>
                            <li>businessinsider.com</li>
                            <li>cityam.com</li>
                            <li>civilbeat.org</li>
                            <li>dailymail.co.uk</li>
                            <li>eandt.theiet.org</li>
                            <li>echo.net.au</li>
                            <li>foxnews.com</li>
                            <li>globalcitizen.org</li>
                            <li>grist.org</li>
                            <li>guardian.ng</li>
                            <li>independent.co.uk</li>
                            <li>kidsagainstplastic.co.uk</li>
                            <li>lifebeforeplastic.co.uk</li>
                            <li>m.huffingtonpost.co.uk</li>
                            <li>mckinsey.com</li>
                            <li>metro.co.uk</li>
                            <li>mindseteco.co</li>
                            <li>news.un.org</li>
                            <li>news24.com</li>
                            <li>newscientist.com</li>
                            <li>oceana.org</li>
                            <li>oceanconservancy.org</li>
                            <li>oxfam.org.uk</li>
                            <li>packagingnews.co.uk</li>
                            <li>positive.news</li>
                            <li>recyclinginternational.com</li>
                            <li>robbreport.com</li>
                            <li>salon.com</li>
                            <li>science-andinfo.blogspot.com</li>
                            <li>seedscientific.com</li>
                            <li>smartcompany.com.au</li>
                            <li>surfrider.org</li>
                            <li>theguardian.com</li>
                            <li>thepremierdaily.com</li>
                            <li>thetelegraphandargus.co.uk</li>
                            <li>timesofmalta.com</li>
                            <li>treehugger.com</li>
                            <li>wastedive.com</li>
                            <li>weforum.org</li>
                        </ul>
                        <br/>
                        <p>The spreadsheet was starting to look a little healthier, finally! Ideas were coming together and I had written all the copy I needed. Here you can see my spreadsheet </p>
                        <img className="beliefsImgLarger" src={Beliefs9} alt="Spreadsheet content" />
                        <br/><br/>
                        <p>Onwards and upwards! I started looking at Plastic Free Fridays and deciding what topics I could cover. This was probably the most challenging and the copy is still outstanding, I may do this week by week rather than planning a year ahead, this will allow some flexibility and allow for me to try new things throughout the year and write about them. Still, I came up with 52 topics to at least plan what I was going to cover through the year.</p>
                        <br/>
                        <p>This included firstly the information on how local people can get involved with the project. It also includes local zero waste shops, plastic free swaps that people can try and plastic free celebrations, such as Easter and Christmas for example.</p>
                        <br/>
                        <p>There may be blog posts required for this, or it may just be social media copy, that remains undecided for now, but with the topics decided it's an easy weekly update.</p>
                        <br/>
                        <p>At this point I wasn't collecting images as I decided to generate my own.</p>
                        <br/><br/> 
                        <hr/>
                        <br/>
                        <div className="mavHeading"><h3 className="bronze">Create graphics to be used in social media posts</h3></div>
                        <br/>
                        <p>Branding!! It's the way forward!</p>
                        <br/>
                        <p>It's important to use branding to ensure that people become familiar with your content. The Plastic Free Communities project has its own colour scheme which I used, so that it is familiar locally but also nationally among other communities.</p>
                        <br/>
                        <p>I started work on logos for the three strap lines. I had an idea of what I wanted them to look like and, as ever, I didn't make things terribly easy for myself!</p>
                        <p>I found a service online that would help to generate the logos. I originally wanted a "stamp" look and I have kind of kept that, but the style I was thinking of originally I decided wouldn't work, because it involved faded text which is not accessible (my other most used word when designing!)</p>
                        <br/>
                        <p>Here, for example, are the original logos that I downloaded for the Monday Minute - note that these weren't intended to be the whole logo, I was using the generator to assist with the curved text! The gold one was a bonus that they provided, it wasn't one I was considering using, pretty though!</p>
                        <br/>
                        <img className="beliefsImg" src={Beliefs10} alt="The words Monday Minute written around a circle" />&nbsp;
                        <img className="beliefsImg" src={Beliefs11} alt="The words Monday Minute written around a circle" />&nbsp;
                        <img className="beliefsImg" src={Beliefs12} alt="The words Monday Minute written around a circle" />&nbsp;
                        <br/><br/>
                        <p>I used Publisher to put my logos together and try out different designs. here are three options for Monday Minute</p>
                        <img className="beliefsImg" src={MMOption1} alt="The words Monday Minute written around a circle" />&nbsp;
                        <img className="beliefsImg" src={MMOption2} alt="The words Monday Minute written around a circle" />&nbsp;
                        <img className="beliefsImg" src={MMOption3} alt="The words Monday Minute written around a circle" />&nbsp;
                        <br/><br/>
                        <p>When I first started putting my graphics together, I had used option 1. However, when I came back to it to continue I decided option 2 was by far the better one, so I started again!</p>
                        <br/>
                        <p>Here are my final stamps</p>
                        <br/>
                        <img className="beliefsImg" src={MMFinal} alt="The words Monday Minute written around a circle" />&nbsp;
                        <img className="beliefsImg" src={WWFinal} alt="The words Worldwide Wednesday written around a circle" />&nbsp;
                        <img className="beliefsImg" src={PFFinal} alt="The words Plastic Free Friday written around a circle" />&nbsp;
                        <br/><br/>
                        <p>The next step was to design the graphics that would be used for the social media posts. I used the project colours again, giving each graphic the same look but with slightly varied colours. Each graphic contains the relevant stamp. By this point I had a subscription for Adobe Illustrator so I used that to create these.</p>
                        <br/>
                        <p>An important consideration here is sizing. Each social media channel has optimal dimensions. <a href="https://blog.hootsuite.com/social-media-image-sizes-guide/" target="_blank" rel="noopener noreferrer">Hootsuite have a very useful guide</a> to this. </p>
                        <p>My final images have three sections. The innermost section is the safe area that will work across all channels</p>
                        <br/>
                        <p>Here are the final blank graphics</p>
                        <br/>
                        <img className="beliefsImg" src={MMBlank} alt="Blank graphic showing a logo in the top left and coloured outlines" />&nbsp;
                        <img className="beliefsImg" src={WWBlank} alt="Blank graphic showing a logo in the top left and coloured outlines" />&nbsp;
                        <img className="beliefsImg" src={PFBlank} alt="Blank graphic showing a logo in the top left and coloured outlines" />&nbsp;
                        <br/>
                        <p>Here you can see the consistent branding but with just slight variations for the different types.</p>
                        <br/><br/>
                        <p>Each graphic contains a title, the content and the source (where relevant). Here are the first posts for each type:</p>
                        <br/>
                        <img className="beliefsImg" src={MMSample} alt="Sample social media post with a logo, titlem, content and a URL source" />&nbsp;
                        <img className="beliefsImg" src={WWSample} alt="Sample social media post with a logo, titlem, content and a URL source" />&nbsp;
                        <img className="beliefsImg" src={PFSample} alt="Sample social media post with a logo, titlem, content and a URL source" />&nbsp;
                        <br/><br/>
                        <hr/>
                        <br/>
                        <div className="mavHeading"><h3 className="bronze">Create blog posts to accompany social media posts</h3></div>
                        <br/>
                        <p>This stage is what I originally planned but has changed a little. I decided to go in a slightly different direction for the majority of the posts in that I would write copy for the posts and include a link to my source reference to allow people to read more if they want to rather than generating blog posts for every social post.</p>
                        <br/>
                        <p>It is quite likely that I will use blog posts for Plastic Free Fridays rather than putting long-form content on social media. I have done this previously to share "how to" type of information. You can see how I have managed this in previous years at the <a href="https://www.plasticfreehighcliffe.org.uk/blog/" target="_blank" rel="noopener noreferrer">PFH Blog</a>.</p>
                        <br/>
                        <p>I did write content for the graphics and for the actual posts and that is all stored in my content planning spreadsheet. In the image below you can see a line from the spreadsheet where I have set the date (column A), title (column C), the content for the graphic (column D) and the copy for the post (column B). </p>
                        <br/>
                        <img className="beliefsImgLarger" src={Beliefs13} alt="A line in a spreadsheet showing social media content" />
                        <br/><br/>
                        <p>Once the content was done, I set about creating all my graphics for the year, here's a screenshot from my laptop which shows some of this:</p>
                        <br/>
                        <img className="beliefsImgLarger" src={Beliefs16} alt="Screenshot of icons in an electronic folder" />
                        <br/><br/>
                        <hr/>
                        <br/>
                        <div className="mavHeading"><h3 className="bronze">Create a posting schedule using Hootsuite to reduce the time needed for regular posting</h3></div>
                        <br/>
                        <p>This unfortunately isn't possible. I'm sure when I first signed up for Hootsuite I was able to do 30 scheduled posts. Now I am only able to schedule up to 5 posts at a time which doesn't even allow me to do a week at a time, so I will have to more actively schedule posts each week.</p>
                        <br/>
                        <p>Hootsuite and other social media managers are very expensive and simply not viable for non-profit organisations or individuals so I can only utilise free plans which are very limited. (I am planning to develop my own social media manager but that's a whole other project!!)</p>
                        <br/>
                        <p>I have therefore diarised my social media posting requirements each week to ensure that I don't forget!</p>
                        <br/>
                        <p>Here's a screenshot of my Hootsuite calendar for Monday 9th January, the second Monday Minute!</p>
                        <br/>
                        <img className="beliefsImgLarger" src={Beliefs17} alt="Hootsuite calendar page showing three scheduled events" />
                        <br/><br/>
                        <hr/>
                        <br/>
                        <div className="mavHeading"><h3 className="bronze">Make a noise locally to try and get people to re-engage with the project</h3></div>
                        <br/>
                        <p>I made a very soft start at the end of 2022 to re-introduce the page. I posted this on 31st December. It reached a grand total of 5 people, had 2 engagements and the solitary like was from my daughter (got to love that girl!)</p>
                        <img className="beliefsImgLarger" src={Beliefs14} alt="Facebook post" />
                        <br/><br/>
                        <p>I started my regular posts from Monday 2nd January as planned. Reach is low but will grow - Monday Minute reached 9 people, Worldwide Wednesday reached 22 and Plastic Free Friday reached 8 -  this is just on Facebook, I also have Instagram and Twitter.</p>
                        <br/>
                        <p>Here's an overview of the first week:</p>
                        <br/>
                        <img className="beliefsImgLarger" src={Beliefs15} alt="Screenshot of Facebook interaction on posts" />
                        <br/>
                        <p>So there is a long way to go, I will let the posts build a little so it's clear they are regular and consistent and then I will buy advertising to promote the pages.</p>
                        <br/>
                        <p>I have a couple of events planned where people can do a beach clean and then we we will have a plastic free picnic / drop-in and hopefull that will help engagement too. </p>
                        <br/>
                        <p>The other thing I need to work out is the best hashtags to use to gain followers. There are apps that can help with this and I have downloaded "Followers Booster" to start looking into the most popular tags. </p>
                        <br/>
                        <p>I also need to actually use the insights provided on the social channels to figure out what is working and what isn't!</p>
                        <br/>
                        <p>I'll get noisier as things progress, I need a few posts in place first to make it worthwhile. Second week of posts starting tonight (09/01/2023)</p>
                        <br/><br/>              
                        <hr/>
                        <br/>
                        <div className="mavHeading"><h3 className="bronze">Connect with other local groups going through the same process. A little friendly co-operation - or even competition - can't hurt!</h3></div>
                        <br/>
                        <p>My nearest community is <a href="https://nakedpantrynewforest.co.uk/plastic-free-new-milton" target="_blank" rel="noopener noreferrer">Plastic Free New Milton</a>. It is run by the owner of The Naked Pantry and we have met, albeit briefly.</p>
                        <br/>
                        <p>I will also be tracking down the other Plastic Free Communities on social media so that I can follow what others are doing.</p>
                        <br/><br/>
                        <hr/>
                        <br/>
                        <div className="mavHeading"><h3 className="bronze">Final Update</h3></div>
                        <br/>
                        <p>So far, I have remembered to make my weekly posts! Hootsuite have now cancelled their free tier so I couldn't use them any more. I found an alternative that is even better - Social Champ - where not only can I schedule more ahead of time I can also post with comments and in threads, so it's working out much better.</p>
                        <br/>
                        <p>I will continue to schedule my weekly posts ahead of time as much as possible and will write my Plastic Free Friday posts weekly. Hopefully engagement will start to build so I can take the community forward.</p>
                        <br/><br/>
                        <hr/>
                        <br/>
                        <div className="mavHeading"><h3>Putting it all together</h3></div>
                        <br/>
                        <p>I am now at the end of this project and I have met all my objectives to get the community back up and running.</p>
                        <br/>
                        <p>Ultimately I decided not to go ahead with the event on the 4th February as I'm not getting enough engagement yet, maybe that was a little optimistic! The next one is scheduled for 13th May so fingers crossed for that one.</p>
                        <br/>
                        <p>It will be an interesting year, I hope that I am able to grow the community and move closer to achieving Plastic Free Community status for Highcliffe.</p>
                        <br/>
                        <p>You can follow the project online here:</p>
                        <p><strong>Facebook:</strong> <a href="https://www.facebook.com/pfhighcliffe" target="_blank" rel="noopener noreferrer">/pfhighcliffe</a></p>
                        <p><strong>Instagram:</strong> <a href="https://www.instagram.com/pfhighcliffe" target="_blank" rel="noopener noreferrer">@pfhighcliffe</a></p>
                        <p><strong>Twitter:</strong> <a href="https://www.twitter.com/pfhighcliffe" target="_blank" rel="noopener noreferrer">@pfhighcliffe</a></p>
                        <p><strong>Website:</strong> <a href="https://www.plasticfreehighcliffe.org.uk" target="_blank" rel="noopener noreferrer">www.plasticfreehighcliffe.org.uk</a></p>
                        <br/><br/>
                        <p>Thank you Maverick Awards for giving me the inspiration to get this back on track.</p>
                        <br/><br/>
                        <hr/>
                        <br/>
                        <div className="mavHeading"><h3>Presentation</h3></div>
                        <br/>
                        <iframe title="My Beliefs PowePoint presentation" src="https://onedrive.live.com/embed?cid=0229995A9087012D&amp;resid=229995A9087012D%21342441&amp;authkey=AFBbhmtYGxtWV54&amp;em=2&amp;wdAr=1.7777777777777777" width="476px" height="288px" frameBorder="0">This is an embedded <a target="_blank" rel="noopener noreferrer" href="https://office.com">Microsoft Office</a> presentation, powered by <a target="_blank" rel="noopener noreferrer" href="https://office.com/webapps">Office</a>.</iframe>
                        <p><a href={BeliefsP} target="_blank" rel="nopener noreferrer">Download Presentation</a></p>
                        <br/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyBeliefs