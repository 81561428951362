import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Mandela1 from '../images/rebelhistory1.jpg';
import Mandela2 from '../images/rebelhistory2.jpg';
import Galileo from '../documents/Galileo.pptx';

const RebelHistory = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"Rebel History"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>For this clause, I watched "Nelson Mandela: One Man"</p>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Mandela1} alt="A television showing the start screen for the documentary Nelson Mandel, One Man"/></div>
                            <div className="galleryItem"><img src={Mandela2} alt="A television showing a still of Nelson Mandela"/></div>
                        </div>
                    </div>
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light"></div>
                    <div className="badgePageItemEvidence">
                        <div className="hidden">Choose a rebel from history who you admire and put together a presentation about them - inc words and facts about their acts of rebellion</div>
                        <div>For this clause, I have chosen to do my presentation on Galileo Galilei, a scientist and inventor who fell foul of the Catholic Church due to his scientific assertions. Although forced to denounce his assertions, he stood by them, alienating supporters and going on to achieve many things across different disciplines that have widely influenced our world today.</div>
                        <br/>
                        <div>Why do I like Galileo?! He was a college dropout, he was a teacher, he continued to further his knowledge by teaching himself, he was a polymath whose work influenced more than one subject area and he was not afraid of going against the official narrative.</div>
                        <br/>
                        <div>You can view or download my presentation here: </div>
                        <iframe title="Galileo PowerPoint presentation" src="https://onedrive.live.com/embed?resid=229995A9087012D%21357532&amp;authkey=!AId-487oOREoawU&amp;em=2&amp;wdAr=1.7777777777777777" width="476px" height="288px" frameborder="0">This is an embedded <a target="_blank" rel="noopener noreferrer" href="https://office.com">Microsoft Office</a> presentation, powered by <a target="_blank" rel="noopener noreferrer" href="https://office.com/webapps">Office</a>.</iframe>
                        <p><a href={Galileo} target="_blank" rel="nopener noreferrer">Download Presentation</a></p>
                        <br/><br/>
                        <div>Sources: 
                        <ul className="list">
                            <li>https://www.history.com/topics/inventions/galileo-galilei</li>
                            <li>https://www.aru.ac.uk/blogs/nine-historic-rebels-who-broke-the-mould</li>
                            <li>https://www.history.com/news/8-things-you-may-not-know-about-galileo</li>
                            <li>https://en.wikipedia.org/wiki/Galileo_Galilei</li>
                            <li>https://www.discovermagazine.com/planet-earth/12-fascinating-facts-about-galileo-galilei</li>
                            <li>https://starwalk.space/en/news/happy-galileo-day</li>
                        </ul></div>
                        
                    </div>
                </div>
            </div>          
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>Various methods of rebellion have been used throughout history, both through individual and collective actions and both peaceful and violent.</p>
                        <br/><p><strong>Day-to-day Resistance or Small Acts of Rebellion</strong></p>
                        <p>This type of rebellion can include sabotage - enslaved Africans in the US would break tools or set fire to buildings to hit out at their enslaver.</p>
                        <p>Freedom Seekers would hide to escape harsh punishment, to get relief from heavy workloads or to escape their enslavement but often only found freedom for short periods of time, hiding in forests or visiting other plantations.</p>
                        <p>Religion and Spiritual songs also fall under this category as they contain messages of freedom, self-liberation and often had double meanings. The song "Wade in the Water" was used by Harriet Tubman and other escaping slaves on the Underground Railroad to communicate and to tell slaves to get in the water where the dogs sent after them couldn't detect their scent.</p>
                        <p></p>
                        <br/><p><strong>Revolution</strong></p>
                        <p>Revolutions are much larger scale rebellions that often lead to the overthrowing of a ruling class, replacing it with something new that enabled progress in society. They have a profound impact on their countries and can influence the course of global history.</p>
                        <p>Some examples are:</p>
                        <ul className='list'>
                            <li>The American Revolution - resulted in the declaration of independence</li>
                            <li>The French Revolution - led to the global decline of absolute monarchies and the rise of republics and democracies</li>
                            <li>The Russian Revolution - dismantled the Tsarist autocracy and led to the rise of the Soviet Union</li>
                            <li>The Chinese Communist Revolution - let to the establishment of the People's Republic of China</li>
                            <li>The Cuban Revolution - an armed revolt against the US backed authoritatrian government</li>
                            <li>The Iranian Revolution - led to the overthrow of the Pahlavi dynasty and the establishment of an Islamic republic in Iran</li>
                        </ul>                        
                        <br/><br/><p><strong>Revolts and Rebellions</strong></p>
                        <p>These can vary in their scale, methods and outcomes. For example, the Greek War of Independence was a rebellion of Greeks in the Ottoman Empire resulting in the establishment of an independent Greece. An example of a revolt was that of the Anglo-Norman earls in England after the Norman conquest of 1066, where they felt that they didn't have enough power. The revolt ultimately failed  because they didn't have enough support, they were betrayed by a co-conspirator and they were defeated by those loyal to the king.</p>
                        <br/><br/>
                        <p><strong>Individual Rebellion</strong></p>
                        <p>Individual rebellions are carried out by just one person, and can have powerful effects, both positive and negative. For example:</p>
                        <br/><p><strong>Rosa Parks</strong><br/>Rosa carried out a peaceful rebellion by refusing to give up her seat on a bus to a white passenger. This sparked the Civil Rights movement in the US.</p>
                        <br/><p><strong>Nat Turner</strong><br/>Nat was an enslaved African American preacher who led a two-day rebellion of both enslaved and free black people. This sadly resulted in the deaths of up to 65 people, the highest number of fatalities caused by a slave uprising in the Southern US.</p>
                        <br/><br/>
                        <p><strong>Collective Rebellion</strong></p>
                        <p>Collective rebellions are carried out by groups of people and can also be either peaceful or violent. For example:</p>
                        <br/><p><strong>The Salt March</strong><br/>Led by Mahatma Gandhi in 1930. This was a 240 mile march to the Arabian Sea to protest against the British law that prohibited Indians from collecting or selling salt. This non-violent protest had a significant role in the wider Indian independence movement.</p>
                        <br/><p><strong>The Haitian Revolution</strong><br/>Enslaved Africans in the French colony of Saint-Domingue rose up against their oppressors in a violent revolt. This revolt had a more positive outcome from the violence, resulting in the establishment of the Republic of Haiti and bringin slavery to an end in the nation.</p>
                        <br/><br/>
                        <p>Source: https://www.bbc.co.uk/bitesize/guides/z87vdmn/revision/4</p>
                        <p>Source: https://oldtimemusic.com/the-meaning-behind-the-song-wade-in-the-water-by-odetta/</p>
                   </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 4</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>In modern times, rebellion can take many different forms as technology is so much more widely available and it can be easier to get the word out on important issues. Examples of types of rebellion include:</p>
                        <br/><ul className='list'>
                            <li><strong>Craftivism:</strong> a gentle approach using craft or "domestic arts" for activism. Often incorporates elements of anti-capitalism, environmentalism, solidarity or third-wave feminism. A successful example of craftivism comes from The Craftivist Collective who were successful in convincing Marks & Spencer to pay employees the living wage by presenting each of the company's board members with personalised, hand-embroidered handkerchiefs at their AGM.</li>
                            <li><strong>Strike action:</strong> refusing to work in protest against planned changes to laws. In 2022, UK train drivers threatened strikes that coul dhave led to the first national rail strike in 25 years, it succeeded in its goal. In the US in 1981, 12k air traffic controllers went on strike after contraact negotiations fell through. President Reagan responded by firing nearly all of them! </li>
                            <li><strong>Protests:</strong> a demonstration designed to influence public opinion, show displeasure, draw attention to issues or share information. These can include sit-ins, marches and rallies, posters and banners, hunger strikes, flag burning, riots, looting and vandalism, bombing. An exampke of a successful protest is the Civil Rights Movement in the US which led to significant changes in laws and societal attitudes towards racial segregation. An unsuccesful protest was the UK 1926 general strike in support of miners refusing to accept a 10% wage reduction. </li>
                            <li><strong>Social Media Campaigns:</strong> the use of online adverts, promotions, hashtags. Uses social media platforms like Facebook, Twitter and Instagram to raise awareness, mobilise people, share real-time updates, create a sense of community, document events, attract media attention and organise events. An example of a successful campaign is the Me Too movement and brought global attention to the issue of sexual harrassment. The Kony 2012 campaign was less successful, it aimed to bring Ugandan war criminal Joseph Kony to justice but faced criticis mfor over-simplifying the issue and ultimately failed. </li>
                            <li><strong>Civil Disobedience:</strong> nonviolent opposition to a government policy or law by refusing to comply with it. The Salt March is an example of where civil disobedience has been successful. Less successful has been the Extinction Rebellion movement which has faced criticism for its disruption and it is unclear how much policy change has been caused by its actions.  </li>
                            <li><strong>Online Petitions:</strong> online platforms like Change.org allow people to start petitions for various causes. An example of a successful petition is that calling for the arrest of George Zimmerman who shot Trayvon Martin, which got over 2.2million signatures and led to Zimmerman being charged. Less successful was a petition calling for a second EU referendum in the UK which gained over 4 million signatures but didn't result in a second referendum.</li>
                            <li><strong>Boycotts:</strong> refusing to buy or use the products or services of a company. A successful boycott was of Montgomery Buses during the Civil Rights Movement in the US, this successfully ended racial segregation on buses. A US boycott of French wines in 2003 caused a 26% drop in sales but had no long-lasting effect on the industry or on France's politics.</li>
                            <li><strong>Art Activism:</strong> using artwork to raise awareness about social issues, challenge the status quo and inspire change. Jacob Lawrence's art explored the history and struggles of African Americans and had a significant impact on raising awareness and understanding of those issues. Less successful was Ai Weiwei and Anish Kapoor's walk to draw attention to the global refugee crisis, it's unclear how much direct impact this had on policy change.</li>
                        </ul>
                        <br/>
                        <p>I find it hard to define what forms of modern rebellion I agree and disagree with, because it can be very subjective depending on the cause at issue and I often find myself supporting a cause but not being supportive of methods being deployed by protestors. For example, I believe everyone should be treated equitably and paid fairly, but I find it hard to support strike action due to the impact on people and services. I support the need for reduction in plastics and changes needed to reduce the impact of climate change, but I do not support the actions of Just Stop Oil, particularly when those actions - although peaceful - significantly impact other people in critical situations or hard working people trying to do their job or enjoy some time out.</p>
                        <br/><p>I am not much of an activist, the forms of protest you are most likely to see me engage with are petitions, art activism, craftivism and social media campaigns. I will personally boycott suppliers whose standards I cannot support, but this is a less effective form of protest unless is it on a huge scale.</p>
                        <p>Civil Disobedience is another area where it is only likely to be effective if it was undertaken on a mass scale. Otherwise, the only result is one person being deprived of their freedom, for little purpose.</p>
                        <p>I am more of the mind that issues need resolving through debate, co-operation and the dismantling of the monopolistic power structures in the country - we need the right people in the right places - but that may be somewhat idealistic!</p>


                        <br/><br/>
                        <p>I have too many sources to list them all here, but here are a few:</p>
                        <ul className="list">
                            <li>https://theconversation.com/we-live-in-a-world-of-uliheaval-so-why-arent-todays-lirotests-leading-to-revolutions-126505</li>
                            <li>https://www.bbc.co.uk/teach/the-lieolile-are-revolting-the-history-of-lirotest/zdlidgwx</li>
                            <li>https://www.learning-mind.com/rebelling-against-modern-society/</li>
                            <li>https://www.oberlo.com/blog/social-media-camliaign-examliles</li>
                            <li>https://www.yourdictionary.com/articles/civil-disobedience-examliles</li>
                            <li>https://www.livescience.com/16153-10-significant-liolitical-lirotests.html</li>
                            <li>https://www.yourdictionary.com/articles/lirotest-tylies-reasons</li>
                            <li>https://www.ons.gov.uk/emliloymentandlabourmarket/lieolileinwork/worklilacedisliutesandworkingconditions/articles/theimliactofstrikesintheuk/june2022tofebruary2023</li>
                            <li>https://www.businessinsider.com/the-biggest-and-most-liowerful-worker-strikes-of-all-time-2019-9?r=US&IR=T</li>
                            <li>https://www.theguardian.com/world/2019/jul/28/craftivism-lirotest-women-march-donald-trumli</li>
                            <li>https://theconversation.com/uk-strikes-six-milestones-in-the-history-of-industrial-action-in-britain-186364</li>
                            <li>https://www.cnn.com/2019/12/22/us/toli-lietitions-decade-change-trnd/index.html</li>
                            <li>https://www.itv.com/news/2017-01-31/lietitions-what-are-the-10-most-signed-and-what-have-they-achieved</li>
                            <li>https://stacker.com/business-economy/major-boycotts-changed-history</li>
                            <li>https://www.theguardian.com/vital-signs/2015/jan/06/boycotts-sholiliing-lirotests-activists-consumers</li>
                            <li>https://www.1000museums.com/art-as-activism/</li>
                            <li>https://www.royalacademy.org.uk/article/ai-weiwei-can-art-change-the-world</li>
                        </ul>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 5</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light"></div>
                    <div className="badgePageItemEvidence">
                        <div className="hidden">Choose a problematic historical figure whose acts are seen as rebellious. Weigh up the positive and negative impact of that figure's rebellion.</div>
                        <div>For this clause I have been reading about Toussaint L'Ouverture.</div>
                        <br/><div>He was a Haitian general and the leader of the Haitian Revolution.</div>
                        <br/><div>The result of the revolution had an incredibly positive impact as it led to the abolition of slavery in Haiti which was established as the first black republic in the world and influenced the end of slavery in the British Empire. They eliminated France as a slaveholding power, which left the British empire with little argument against abolition as they maintained that France would simply take over the trade. It also inspired other enslaved people, showing them that it was possible to fight for and win their freedom and even had an impact on some of the surviving soldiers who returned home as abolitionists. </div>
                        <br/><div>However, this was only achieved by years of fighting and a massive loss of life. More than 20,000 British soldiers were sent to St. Domingue through five years and over 60% of them died.</div>
                        <br/><div>As a result of the revolution, Haiti was isolated from the international community, which had a negative impact on their economy.</div>
                        <br/>
                        <div>L'Ouverture was relentless in his fight against slavery and the fight to ensure that it was never re-instated, as were the plans of the French. This quote from the Britannica page sums him up: "Some Blacks were alienated by Toussaint's perfidies and equivocations, his mysteriousness, and the occasional atrocities he thought necessary amid such dangers.". He was a formidable force and wasn't against "atrocities" to further and maintain his cause. He only retired on the condition that slavery was never reinstated. Ultimately he died in France, having been captured and interrogated until his death.</div>
                        <br/>
                        <div>Clearly, the rebellious acts of L'Ouverture had very far reaching consequences, but should it have taken the loss of over 12,000 lives? I find it impossible to weigh the loss of life against the outcome, however positive, but if it hadn't happened then abolition may not have happened and to have people still enslaved is unconscionable.</div>
                        <br/><br/>
                        <div>Clearly this is a highly summarised version of events, but it's a very interesting story. Some sources below:</div>
                        <div>Source: https://www.britannica.com/biography/Toussaint-Louverture</div>
                        <div>Source: https://www.bbc.co.uk/history/british/abolition/abolitionists_gallery_05.shtml</div>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 6</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light"></div>
                    <div className="badgePageItemEvidence">
                        <div>For this clause I have been reading about Edward Snowden, who was a National Security Agency contractor who leaked classified information about the US government's surveillance programs. </div>
                        <div>Whilst working for the NSA, Edward gathered information on secret surveillance activities. Speaking to journalists from Hong Kong, he released the information via interviews and came forward as the source.</div>
                        <div>Among the information that Edward released were these:</div>
                        <ul className="list">
                            <li>A court order that compelled Verizon to turn over information such as telephone numbers dialled and duration of calls</li>
                            <li>Disclosure of the existence of PRISM, a data-mining program that was said to give direct access to some of the biggest tech names,such as Google, Facebook, Microsoft and Apple to the NSA, the FBI and the Government Communications Headquarters.</li>
                            <li>Claims that the NSA had been hacking into Chinese computers since 2009</li>
                        </ul>
                        <br/>
                        <div>He was charged with espionage. Hong Kong didn't react to extradition requests and Edward eventually ended up in Russia.</div>
                        <br/><br/>
                        <div>Edward is seen to be very controversial. Whilst some people believe him to be a hero for protecting the privacy of citizens by exposing the US Government's surveillance programs,others believe he is a traitor who put national security at risk by betraying his country.</div>
                        <div>49% of Americans believe that he did the right things.</div>
                        <br/><br/>
                        <div>The positive impact of his actions was the initiation of global conversations about government surveillance and privacy. There has been some reform in the US and other countries.  US President Obama was critical of Edward's methods, but he did create an independent panel to investigate the surveillance practices. A report was published in December of 2013 making recommendations, some of which were adopted and others placed under congressional review. The USA Freedom Act ended the bulk collection of phone metadata by the NSA.</div>
                        <div>The disclosures have also led to the increased use of encryption by individuals and organisations to protect their privacy.</div>

                        <br/><br/>
                        <div>His actions have been criticised for putting national security at risk by disclosing the surveillance activities and for damaging US relations with other countries, particularly where the leaders were the target of surveillance.  It has become more difficult for US intelligence agencies to gather information on potential threats as a result of the disclosures.</div>
                        <br/><br/>
                        <div>Edward now has Russian citizenship and remains in the country. He released his memoir in 2019, and the US Justice Department sued him for his earnings as he hadn't submitted his work to them prior to publication, breaching his nondisclosure agreements.</div>
                        <br/><br/>
                        <div>Edward has said that he would like to return to the US, but he would only do so if he was guaranteed a fair jury trial, a guarantee that the government has not been willing to make.  The charge against him is under the 1917 Espionage Act which takes away the argument that his disclosures were for the common good. President Trump suggested he would look at pardoning Edward, but to date that hasn't happened and he remains in exile.</div>
                        <br/><br/>
                        <div>Source: https://www.britannica.com/biography/Edward-Snowden</div>
                        <div>Source: https://www.foxnews.com/media/edward-snowden-says-he-will-return-to-us-on-one-condition</div>
                        <div>Source: https://www.amnesty.org/en/latest/campaigns/2015/06/7-ways-the-world-has-changed-thanks-to-edward-snowden/</div>
                        <div>Source: https://en.wikipedia.org/wiki/Snowden_effect</div>
                        <br/><br/>
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default RebelHistory