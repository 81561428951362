import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Vision from '../images/RebelVision-May24.png';

const  May24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Rebel Vision"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Option 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence"> 
                       Learn what eurovision is and make a timeline of significant events
                       <p>Eurovision is an annual song contest,organised by the European Broadcasting Union, that features live performances from multiple artists who compete to win the competition. Performances are judged by juries from each of the countries and by viewers.  There is no defined genre and is a very diverse event. The semi-finals and finals of the competition are broadcast live.</p>
                       <br/>
                       <p>Timeline of significant events:</p>
                       <ul className="list">
                            <li>1956: The first Eurovision Song Contest is held in Lugano, Switzerland. Seven countries participate, and Switzerland wins the inaugural event with Lys Assia's song "Refrain."</li>
                            <li>1965: Luxembourg wins with France Gall's song "Poupée de cire, poupée de son," written by Serge Gainsbourg, marking a shift toward more contemporary music styles.</li>
                            <li>1969: Four countries (France, Spain, the Netherlands, and the United Kingdom) share the victory in a unique tie due to the lack of a tie-breaking rule.</li>
                            <li>1974: ABBA wins for Sweden with "Waterloo," launching their international career.</li>
                            <li>1975: The current voting system, where each country awards points from 1 to 8, 10, and 12, is introduced.</li>
                            <li>1981: Bucks Fizz wins for the UK with "Making Your Mind Up," known for its memorable choreography involving costume changes.</li>
                            <li>1988: Celine Dion wins for Switzerland with "Ne partez pas sans moi," which boosts her global music career.</li>
                            <li>1994: Riverdance performs during the interval act, becoming an international sensation and leading to a successful stage show.</li>
                            <li>1997: The first online broadcast of the Eurovision Song Contest occurs, expanding its reach globally.</li>
                            <li>2000: Denmark wins with the Olsen Brothers' "Fly on the Wings of Love," notable for using a vocoder effect.</li>
                            <li>2004: The semi-final system is introduced to manage the growing number of participating countries.</li>
                            <li>2007: The contest is broadcast in high-definition for the first time.</li>
                            <li>2008: A second semi-final is introduced due to the increasing number of participants.</li>
                            <li>2012: Loreen wins for Sweden with "Euphoria," becoming one of the contest's biggest commercial successes.</li>
                            <li>2014: Conchita Wurst wins for Austria with "Rise Like a Phoenix," making a significant impact on discussions about gender and sexuality in media.</li>
                            <li>2015: Australia is invited to participate for the first time to celebrate the contest's 60th anniversary.</li>
                            <li>2020: The contest is canceled for the first time in its history due to the COVID-19 pandemic. A special program, "Eurovision: Europe Shine a Light," is broadcast in its place.</li>
                            <li>2021: Italy wins with Måneskin's "Zitti e buoni," marking their first victory since 1990. The contest is held in Rotterdam, Netherlands, with extensive COVID-19 safety measures.</li>
                            <li>2022: Ukraine wins with Kalush Orchestra's "Stefania," amid significant global support due to the ongoing conflict with Russia.</li>
                       </ul>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Option 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence"> 
                       <p>Scoring in different European Languages</p>
                        <table>
                            <tr>
                                <th>Language</th>
                                <th>Nil points</th>
                                <th>Ten</th>
                                <th>Twelve</th>
                            </tr>
                            <tr>
                                <td>Albanian</td>
                                <td>Zero pikë</td>
                                <td>Dhjetë</td>
                                <td>Dymbëdhjetë</td>
                            </tr>
                            <tr>
                                <td>Croatian</td>
                                <td>Nula bodova</td>
                                <td>Deset</td>
                                <td>Dvanaest</td>
                            </tr>
                            <tr>
                                <td>French</td>
                                <td>Zéro point</td>
                                <td>Dix</td>
                                <td>Douze</td>
                            </tr>
                            <tr>
                                <td>Danish</td>
                                <td>Nul point</td>
                                <td>Ti</td>
                                <td>Tolv</td>
                            </tr>
                            <tr>
                                <td>German</td>
                                <td>Null Punkte</td>
                                <td>Zehn</td>
                                <td>Zwölf</td>
                            </tr>
                            <tr>
                                <td>Georgian</td>
                                <td>ნული ქულა (nuli qula)</td>
                                <td>ათი (ati)</td>
                                <td>თორმეტი (tormeti)</td>
                            </tr>
                            <tr>
                                <td>Hungarian</td>
                                <td>Nulla pont</td>
                                <td>Tíz</td>
                                <td>Tizenkettő</td>
                            </tr>
                            <tr>
                                <td>Icelandic</td>
                                <td>Engin stig</td>
                                <td>Tíu</td>
                                <td>Tólf</td>
                            </tr>
                            <tr>
                                <td>Italian</td>
                                <td>Zero punti</td>
                                <td>Dieci</td>
                                <td>Dodici</td>
                            </tr>
                            <tr>
                                <td>Polish</td>
                                <td>Zero punktów</td>
                                <td>Dziesięć</td>
                                <td>Dwanaście</td>
                            </tr>
                            <tr>
                                <td>Portuguese</td>
                                <td>Zero pontos</td>
                                <td>Dez</td>
                                <td>Doze</td>
                            </tr>
                            <tr>
                                <td>Serbian</td>
                                <td>Nula poena</td>
                                <td>Deset</td>
                                <td>Dvanaest</td>
                            </tr>
                            <tr>
                                <td>Slovene</td>
                                <td>Nič točk</td>
                                <td>Deset</td>
                                <td>Dvanajst</td>
                            </tr>
                            <tr>
                                <td>Spanish</td>
                                <td>Cero puntos</td>
                                <td>Diez</td>
                                <td>Doce</td>
                            </tr>
                            <tr>
                                <td>Turkish</td>
                                <td>Sıfır puan</td>
                                <td>On</td>
                                <td>On iki</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Option 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence"> 
                       <p>For this clause I watched "How Not to Win Eurovision" on iPlayer.</p>
                       <img src={Vision} alt="Screenshot of the iPlayer screen for the programme How Not to Win Eurovision showing four men in a car" className="imgThird" />
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Option 4</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence"> 
                       <p>The contest has been hit with several boycots over the years. These are usually influenced by politics, national security and cultural diplomacy.</p>
                       <ul className="list">
                        <li>1968: Austria boycotted the contest held in London due to the participation of Spain, which was under Francoist dictatorship at the time.</li>
                        <li>1970: Several countries (Finland, Norway, Sweden, and Portugal) boycotted the contest in protest of the four-way tie in 1969, arguing that the voting system was unfair.</li>
                        <li>1973: Austria withdrew from the contest in Luxembourg, possibly due to political reasons, though no official reason was given.</li>
                        <li>1979 and 1980: Turkey withdrew in 1979 due to pressure from Arab countries after the contest was held in Israel. They also withdrew in 1980 due to internal political unrest.</li>
                        <li>1993-1994: Several Eastern European countries could not participate due to limitations on the number of contestants. This included nations like Slovakia, Romania, and Hungary in various years.</li>
                        <li>1996: Germany failed to qualify from a pre-qualifying round, leading to significant controversy and discussions about the qualification process.</li>
                        <li>2005: Lebanon was set to participate but withdrew because their broadcaster refused to broadcast the Israeli entry, citing Lebanese laws that prohibit interactions with Israel.</li>
                        <li>2009: Georgia withdrew from the contest held in Moscow, Russia, after their entry "We Don't Wanna Put In" was perceived as a political statement against Vladimir Putin. The EBU requested changes to the lyrics, which Georgia refused.</li>
                        <li>2012: Armenia withdrew from the contest in Baku, Azerbaijan, due to safety concerns stemming from ongoing conflict between the two countries over Nagorno-Karabakh.</li>
                        <li>2013: Turkey did not participate due to dissatisfaction with the contest's rules and voting system. They have continued to boycott the contest since.</li>
                        <li>2017: Russia withdrew after their contestant, Yulia Samoylova, was banned from entering Ukraine (the host country) due to her previous illegal visit to Crimea.</li>
                        <li>2018: Ukraine withdrew due to a national scandal involving their selected representative and issues around Russian performances in Crimea.</li>
                        <li>2020: The contest was canceled due to the COVID-19 pandemic, marking the first cancellation in its history.</li>
                        <li>2022: Russia was excluded from participating due to the invasion of Ukraine, reflecting the broader international sanctions and isolation of Russia.</li>
                       </ul>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Option 5</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence"> 
                       <p>I listened to the 2024 entries. This was a lot harder decision that I thought it would be but these were ultimately my top 5:
                        <ul className="list">
                            <li>Denmark: SABA - "SAND"</li>
                            <li>Finland: Windows95man - "No Rules!"</li>
                            <li>Germany: ISAAK - "Always On The Run"</li>
                            <li>Luxembourg: TALI - "Fighter"</li>
                            <li>United Kingdom: Olly Alexander - "Dizzy"</li>
                        </ul>
                       </p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Option 6</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence"> 
                       <p>I listened to the winning songs from the last 10 years and these were my top 3:</p>
                       <ul className='list'>
                        <li>2015: Måns Zelmerlöw - "Heroes" (Sweden)</li>
                        <li>2019: Duncan Laurence - "Arcade" (Netherlands)</li>
                        <li>2023: Loreen - "Tattoo" (Sweden)</li>
                       </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default May24