import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Bracelet1 from '../images/cupgen24-bracelet1.jpg';
import Bracelet2 from '../images/cupgen24-bracelet2.jpg';
import Bracelet3 from '../images/cupgen24-bracelet3.jpg';
import Bracelet4 from '../images/cupgen24-bracelet4.jpg';
import Bracelet5 from '../images/cupgen24-bracelet5.jpg';
import Bracelet6 from '../images/cupgen24-bracelet6.jpg';
import Bracelet7 from '../images/cupgen24-bracelet7.jpg';




const CupGen24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Rebel Cup General 2024 Bonus Points"} />
            <div className="badgePageItem">Rebel Cup challenges are only posted in the facebook group for confirmed members. You will have to join the club to find out why I am doing these things!</div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Bonus 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light">Friendship Bracelet</div>
                    <div className="badgePageItemEvidence"> 
                        <p>We had a chat a make session in the Dorset Rebellion via zoom and I made my bracelet during and after that.</p>
                        <p>My first swap was a postal swap with fellow Dorset Rebel Rosie.</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Bracelet1} alt="Friendship bracelet pattern" /></div>
                            <div className="galleryItem"><img src={Bracelet2} alt="Embroidery threads with five colours picked out" /></div>
                            <div className="galleryItem"><img src={Bracelet3} alt="Set up on a zoom call ready to make friendship bracelets" /></div>
                            <div className="galleryItem"><img src={Bracelet4} alt="Embroidery threads laid out on a table ready for weaving intto a friendship bracelet" /></div>
                            <div className="galleryItem"><img src={Bracelet5} alt="A friendship bracelet in progress with embroidery threads" /></div>
                            <div className="galleryItem"><img src={Bracelet6} alt="Finished friendship bracelet" /></div>
                            <div className="galleryItem"><img src={Bracelet7} alt="Composite layout of two swapped friendship bracelets" /></div>
                        </div>                       
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CupGen24