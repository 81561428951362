import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Skirt1 from '../images/cup-april23-pins1.jpg';
import Skirt2 from '../images/cup-april23-pins2.jpg';
import Skirt3 from '../images/cup-april23-pins3.jpg';
import Skirt4 from '../images/cup-april23-pins4.jpg';
import Skirt5 from '../images/cup-april23-pins5.jpg';
import Skirt6 from '../images/cup-april23-pins6.jpg';
import Skirt7 from '../images/cup-april23-pins7.jpg';
import Skirt8 from '../images/cup-april23-pins8.jpg';
import Skirt9 from '../images/cup-april23-pins9.jpg';
import Skirt10 from '../images/cup-april23-pins10.jpg';
import Skirt11 from '../images/cup-april23-pins11.jpg';
import Skirt12 from '../images/cup-april23-pins12.jpg';
import Skirt13 from '../images/cup-april23-pins13.jpg';
import Skirt14 from '../images/cup-april23-pins14.jpg';
import Skirt15 from '../images/cup-april23-pins15.jpg';
import Skirt16 from '../images/cup-april23-pins16.jpg';
import Skirt17 from '../images/cup-april23-pins17.jpg';
import Skirt18 from '../images/cup-april23-pins18.jpg';
import Skirt19 from '../images/cup-april23-pins19.jpg';
import Skirt20 from '../images/cup-april23-pins20.jpg';
import Skirt21 from '../images/cup-april23-pins21.jpg';
import Skirt22 from '../images/cup-april23-pins22.jpg';
import Skirt23 from '../images/cup-april23-pins23.jpg';
import Skirt24 from '../images/cup-april23-pins24.jpg';
import Skirt25 from '../images/cup-april23-pins25.jpg';
import Skirt26 from '../images/cup-april23-pins26.jpg';
import Skirt27 from '../images/cup-april23-pins27.jpg';
import Skirt28 from '../images/cup-april23-pins28.jpg';
import Skirt29 from '../images/cup-april23-pins29.jpg';
import Skirt30 from '../images/cup-april23-pins30.jpg';
import Skirt31 from '../images/cup-april23-pins31.jpg';
import Skirt32 from '../images/cup-april23-pins32.jpg';
import Skirt33 from '../images/cup-april23-pins33.jpg';
import Skirt34 from '../images/cup-april23-pins34.jpg';
import Skirt35 from '../images/cup-april23-pins35.jpg';
import Skirt36 from '../images/cup-april23-pins36.jpg';
import Skirt37 from '../images/cup-april23-pins37.jpg';
import Skirt38 from '../images/cup-april23-pins38.jpg';
import Skirt39 from '../images/cup-april23-pins39.jpg';
import Skirt40 from '../images/cup-april23-pins40.jpg';
import Skirt41 from '../images/cup-april23-pins41.jpg';
import Skirt42 from '../images/cup-april23-pins42.jpg';
import Sculpture1 from '../images/cup-april23-sculpture1.jpg';
import Sculpture2 from '../images/cup-april23-sculpture2.jpg';
import Sculpture3 from '../images/cup-april23-sculpture3.jpg';
import Sculpture4 from '../images/cup-april23-sculpture4.jpg';
import Sculpture5 from '../images/cup-april23-sculpture5.jpg';
import Sculpture6 from '../images/cup-april23-sculpture6.jpg';
import Sculpture7 from '../images/cup-april23-sculpture7.jpg';
import Sculpture8 from '../images/cup-april23-sculpture8.jpg';
import Sculpture9 from '../images/cup-april23-sculpture9.jpg';
import Sculpture10 from '../images/cup-april23-sculpture10.jpg';
import Sculpture11 from '../images/cup-april23-sculpture11.jpg';
import Sculpture12 from '../images/cup-april23-sculpture12.jpg';
import Sculpture13 from '../images/cup-april23-sculpture13.jpg';
import Sculpture14 from '../images/cup-april23-sculpture16.jpg';
import Sculpture15 from '../images/cup-april23-sculpture17.jpg';
import Sculpture17 from '../images/cup-april23-sculpture19.jpg';
import Sculpture18 from '../images/cup-april23-sculpture20.jpg';
import Sculpture19 from '../images/cup-april23-sculpture21.jpg';
import Sculpture20 from '../images/cup-april23-sculpture22.jpg';
import Sculpture21 from '../images/cup-april23-sculpture23.jpg';
import Sculpture22 from '../images/cup-april23-sculpture24.jpg';
import Sculpture23 from '../images/cup-april23-sculpture25.jpg';
import Sculpture24 from '../images/cup-april23-sculpture26.jpg';
import Sculpture25 from '../images/cup-april23-sculpture27.jpg';
import Sculpture26 from '../images/cup-april23-sculpture28.jpg';
import Sculpture27 from '../images/cup-april23-sculpture29.jpg';
import Sculpture28 from '../images/cup-april23-sculpture30.jpg';
import Sculpture29 from '../images/cup-april23-sculpture31.jpg';
import Sculpture30 from '../images/cup-april23-sculpture32.jpg';
import Sculpture31 from '../images/cup-april23-sculpture33.jpg';
import Sculpture32 from '../images/cup-april23-sculpture34.jpg';
import Sculpture33 from '../images/cup-april23-sculpture35.jpg';
import Sculpture34 from '../images/cup-april23-sculpture36.jpg';
import Sculpture35 from '../images/cup-april23-sculpture37.jpg';
import Sculpture36 from '../images/cup-april23-sculpture38.jpg';
import Sculpture37 from '../images/cup-april23-sculpture39.jpg';
import Sculpture38 from '../images/cup-april23-sculpture40.jpg';
import Sculpture39 from '../images/cup-april23-sculpture41.jpg';
import Sculpture40 from '../images/cup-april23-sculpture42.jpg';
import Sculpture41 from '../images/cup-april23-sculpture44.jpg';
import Sculpture42 from '../images/cup-april23-sculpture45.jpg';
import Sculpture43 from '../images/cup-april23-sculpture46.jpg';
import Sculpture44 from '../images/cup-april23-sculpture47.jpg';
import Sculpture45 from '../images/cup-april23-sculpture48.jpg';
import Sculpture46 from '../images/cup-april23-sculpture49.jpg';
import Sculpture47 from '../images/cup-april23-sculpture50.jpg';
import Sculpture48 from '../images/cup-april23-sculpture51.jpg';
import Sculpture49 from '../images/cup-april23-sculpture52.jpg';
import Sculpture50 from '../images/cup-april23-sculpture53.jpg';
import Sculpture51 from '../images/cup-april23-sculpture54.jpg';
import Sculpture52 from '../images/cup-april23-sculpture55.jpg';
import Sculpture53 from '../images/cup-april23-sculpture56.jpg';
import Sculpture54 from '../images/cup-april23-sculpture57.jpg';
import Sculpture55 from '../images/cup-april23-sculpture58.jpg';
import Sculpture56 from '../images/cup-april23-sculpture59.jpg';
import Sculpture57 from '../images/cup-april23-sculpture60.jpg';
import Sculpture58 from '../images/cup-april23-sculpture61.jpg';
import Sculpture59 from '../images/cup-april23-sculpture62.jpg';




const CupApr23 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Rebel Cup Bonus Points"} />
            <div className="badgePageItem">Rebel Cup challenges are only posted in the facebook group for confirmed members. You will have to join the club to find out why I am doing these things!</div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Bonus 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light">Make a sculpture inspired by Mother Earth</div>
                    <div className="badgePageItemEvidence"> 
                        <p>I considered sculpture my nemesis, so I wasn't too keen on doing this one, but curiosity won out and I decided to see what I could do. I found a stunning Mother Earth picture on Google images and wondered if I might just be able to re-create it.</p>
                        <br/><br/>
                        <p>The finished article is far from perfect. The eyes are far too bulgy for a start! However, considering this is the first time I have ever attempted anything like this, I don't think it went too badly.</p>
                        <br/>
                        <p>See the gallery below to witness the transformation from gargoyle to something resembling a female face (with a stop at what kind of resembled Braveheart along the way!)</p>
                        <br/>
                        <p>This is made entirely from salt dough, uses paint brush fibres for eyelashes and a combination of acrylic and poster paints.</p>
                        <br/><br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Sculpture1} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture2} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture3} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture4} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture5} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture6} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture7} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture8} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture9} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture10} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture11} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture12} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture13} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture14} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture15} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture17} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture18} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture19} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture20} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture21} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture22} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture23} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture24} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture25} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture26} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture27} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture28} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture29} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture30} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture31} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture32} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture33} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture34} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture35} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture36} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture37} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture38} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture39} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture40} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture41} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture42} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture43} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture44} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture45} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture46} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture47} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture48} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture49} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture50} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture51} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture52} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture53} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture54} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture55} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture56} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture57} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture58} alt="Sculpture representation of Mother Earth in progress" /></div>
                            <div className="galleryItem"><img src={Sculpture59} alt="Sculpture representation of Mother Earth" /></div>

                        </div>                    
                    </div>
                </div>
            </div>
           <div className="badgePageItem">
                <div className="badgePageItemTitle silver-light">Bonus 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light">Make an item of clothing using at least 30 safety pins</div>
                    <div className="badgePageItemEvidence"> 
                        <p>When I first thought about this challenge, I was thiking of converting some jeans into crops for summer, with safety pins as a functional feature to hold sections together in the legs - a bit of a punk style.</p>
                        <br/>
                        <p>However, if I had to design and make something from scratch it made sense to try and work it into the designer badge - which had been picked for me as part of this quarter's badge - so I had to re-think as that wouldn't have met any of the clauses. </p>
                        <br/>
                        <p>I had seen posts before about converting jeans into a skirt so I looked for tutorials on that and found a brilliant ruffle skirt version. You can watch it on YouTube here:  https://www.youtube.com/watch?v=sO5pYX_wnC4 </p>             
                        <br/>
                        <p>To create this, I used one pair of old jeans and the fabric from a tshirt, another pair of trousers and some mosquito net! This met one of the designer badge clauses nicely with a change in purpose and re-using multiple articles of clothing.</p>
                        <br/>
                        <ul className="list">
                            <li>It uses 92 eyelets - all were individually hand-hammered</li>
                            <li>It uses 45 large coloured safety pins</li>
                            <li>It uses 59 dinky coloured safety pins</li>
                            <li>It is entirely hand-sewn</li>
                        </ul>       
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Skirt1} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt2} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt3} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt4} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt5} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt6} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt7} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt8} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt9} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt10} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt11} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt12} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt13} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt14} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt15} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt16} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt17} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt18} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt19} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt20} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt21} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt22} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt23} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt24} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt25} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt26} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt27} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt28} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt29} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt30} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt31} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt32} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt33} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt34} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt35} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt36} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt37} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt38} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt39} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt40} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt41} alt="Turning jeans into a skirt, progress shot" /></div>
                            <div className="galleryItem"><img src={Skirt42} alt="Turning jeans into a skirt, progress shot" /></div>
                        </div> 
                        <br/><br/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CupApr23