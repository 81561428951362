import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Plant1 from '../images/indoorgardener1.jpg';
import Plant2 from '../images/indoorgardener2.jpg';
import Plant3 from '../images/indoorgardener3a.jpg';
import Plant4 from '../images/indoorgardener4.jpg';
import Plant5 from '../images/indoorgardener5.jpg';
import Plant6 from '../images/indoorgardener6.jpg';
import Plant7 from '../images/indoorgardener7.jpg';
import Plant8 from '../images/indoorgardener8.jpg';
import Movie1 from '../documents/indoorgardenerclip.mp4';
import Orchid1 from '../images/indoorgardenerorchid1.jpg';
import Potting1 from '../images/indoorgardener-potting1.jpg';
import CactiInfo1 from '../images/IndoorGardener-CactiInfo1.jpg';
import CactiInfo2 from '../images/IndoorGardener-CactiInfo2.jpg';
import Orchid2 from '../images/indoorgardener-orchid2.jpg';
import Orchid3 from '../images/indoorgardener-orchid3.jpg';
import Orchid4 from '../images/indoorgardener-orchid4.jpg';
import Orchid5 from '../images/indoorgardener-orchid5.jpg';
import Orchid6 from '../images/indoorgardener-orchid6.jpg';
import Orchid7 from '../images/indoorgardener-orchid7.jpg';
import Orchid8 from '../images/indoorgardener-orchid8.jpg';
import Orchid9 from '../images/indoorgardener-orchid9.jpg';
import Orchid10 from '../images/indoorgardener-orchid10.jpg';
import Orchid11 from '../images/indoorgardener-orchid11.jpg';


const IndoorGardener = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Indoor Gardener"} />
            <p>I am not green-fingered in any sense of the word. My house is a house of doom for plants!</p>
            <p>This clip from the TV Show "Harlem" sums it up completely - disclaimer this is a recording of the TV screen on my phone so the sound is bad!</p>
            <br/>
            <div className="centre">
                <video width="400" height="240" controls>
                    <source src={Movie1} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">01</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Keep house plants alive!</div>
                    <div className="badgePageItemInfo reverse hidden">Over a period of at least 6 months keep at least 5 house plants alive! Including 1 succulent, 1 cactus, 1 orchid</div>
                    <div className="badgePageItemEvidence">
                        <p>Here is my original collection of plants:</p>
                        <br/>
                        <img className="imgThird" src={Plant1} alt="Ornamental pineapple plant" />
                        <img className="imgThird" src={Plant7} alt="Ornamental pineapple plant" />
                        <img className="imgThird" src={Plant2} alt="Succulents and Cacti" />
                        <img className="imgThird" src={Plant3} alt="Orchid" />
                        <img className="imgThird" src={Plant6} alt="Orchid" />
                        <br/><br/>
                        <p>I then added two new ones, much to the amusement of my mother who found it hilarious that I purchased a "living stone"!!.</p>
                        <br/>
                        <img className="imgThird" src={Plant4} alt="Succulents being transported" />
                        <br/><br/>
                        <p>All my plants lasted the requisite 6 months, sadly two have since lost their battle of survival in my house of doom!</p>
                        <br/>
                        <p>I lost the spiky cactus (Echinocactus grusonii) and the Horse's Teeth (Haworthia Truncata).</p>
                        <br/><br/>
                        <p>The orchid has now lost its flowers and I am trying to nurture it back to another bloom, only time will tell!</p>
                        <br/><br/>
                        <p>The pineapple plant has now lost its pineapple, I have cleared the dead leaves and re-potted the baby plants that grew at the base so I'm hoping I may get more at some point!</p>
                        <br/>
                        <img className="imgThird" src={Plant5} alt="New pineapple plants" />
                        <br/><br/>
                        <p>The remaining succulents are doing fine, the aloe also has babies that I need to separate.</p>
                        <br/><br/>
                        All in all, it's been more successful than I had thought!
                        <br/><br/>
                        Update 11/3/2023: It looks like the Haworthia Truncata might be making a fresh shoot for survival!! <br/>
                        <img className="imgThird" src={Plant8} alt="New sprout on a succulent plant"/>
                        <br/>
                        <p>Update 2/9/23: It was just teasing :( !!!</p>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">02</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Learn about light</div>
                    <div className="badgePageItemInfo reverse hidden">Learn how much light each plant requires and how the direction a window faces affects the light.</div>
                    <div className="badgePageItemEvidence">
                        <table>
                            <thead>
                                <tr>
                                    <th>Plant</th>
                                    <th>Light requirements</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Echinocactus grusonii</td>
                                    <td>Needs lots of sun, at least six hours of sunlight per day.</td>
                                </tr>
                                <tr>
                                    <td>Haworthia Truncata</td>
                                    <td>Likes to be in a light and airy position, but not in direct sunlight.</td>
                                </tr>
                                <tr>
                                    <td>Aloe Vera</td>
                                    <td>Needs bright, indirect sunlight and preferf to be warm.</td>
                                </tr>
                                <tr>
                                    <td>Pineapple Plant</td>
                                    <td>Needs bright, indirect light - not in direct sunlight.</td>
                                </tr>
                                <tr>
                                    <td>Orchid</td>
                                    <td>Bright, indirect light, prefer eastern or southern window. If the leaves feel warmer than air temperature, move to a less intense location.</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <p>The direction of the windows will determine the quality and quantity of natural light that gets to your plants. South-facing windows usually produce full sunlight and without that plant growth may be affected. East and West facing windows can work, but are better when supplemented with a grow lamp.</p>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">03</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Learn about watering</div>
                    <div className="badgePageItemInfo reverse hidden">How much water does each plant need? Which ones need to be watered more regularly? Research different ways to water an orchid.</div>
                    <div className="badgePageItemEvidence">
                        <table>
                            <thead>
                                <tr>
                                    <th>Plant</th>
                                    <th>Water requirements</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Echinocactus grusonii</td>
                                    <td>Needs good drainage to prevent root rot. Prefers dry conditions. Water only once every 2-3 weeks in the spring and summer and only water around the roots.</td>
                                </tr>
                                <tr>
                                    <td>Haworthia Truncata</td>
                                    <td>Only allows a very small amount of water in the summer, and must dry out between watering. In winter only needs watering every other month. Needs well-drained soil.</td>
                                </tr>
                                <tr>
                                    <td>Aloe Vera</td>
                                    <td>Water once a week unless it it very hot and dry, then increase to twice a week.  Allow to dry out completely before watering again. Needs plenty of liquid when you do water.</td>
                                </tr>
                                <tr>
                                    <td>Pineapple Plant</td>
                                    <td>Water only when the soil dries out completely.</td>
                                </tr>
                                <tr>
                                    <td>Orchid</td>
                                    <td>Water when they dry out. If the roots are white and plump or bright green, all is well. If the roots are shriveled and grey, it needs more water. If the roots are shriveled or spongy brown or black, you have over-watered.  In winter, use warm water once a week, twice a week in warmer weather. Water until the water runs out of the pot.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">04</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Products and tools</div>
                    <div className="badgePageItemInfo reverse hidden">What different products and tools can you use to promote the plants' health?</div>
                    <div className="badgePageItemEvidence">
                        <p>For the plants that I have specialist tools aren't required, but they benefit from different products to fertilise and feed them:</p>
                        <ul className='list'>
                            <li>Orchid drop feeder</li>
                            <li>Special cactus and succulent feed</li>
                            <li>Liquid feed, high in potassium</li>
                        </ul>
                        <br/>
                        <p>A damp cloth or soft brush might help to keep cacti dust free! I used a cane to support the pineapple fruit and the orchid uses canes as well with little plastic claw clips to clip the stems to the canes.</p>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                    <div className="badgePageItemTitle olive">05</div>
                    <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Transplant</div>
                    <div className="badgePageItemInfo reverse hidden">Know when to transplant a plant to a larger plant pot. Transplant your cactus as it grows and monitor the impact this has on size.</div>
                    <div className="badgePageItemEvidence">
                        <p>I haven't transplanted my cactus, because it showed no signs of growing and I don't want to grow it or kill it! I have however transplanted other plants.</p>
                        <p>My Aloe Vera had babies, so I gently separated them out into their own pots.</p>
                        <p>When my pineapple had come to the end of its flowering life, I removed the fruit and any dead leaves. I noticed that it had acquired some little suckers at the bottom, so I removed and planted those to allow them to grow.</p>
                        <br/><br/>
                        <p>It's easy to know when to transplant - the cactus will grow up outside of the pot or the roots will start shooting out of the bottom of the pot! If you have had it for a long time, it will need to be re-potted in fresh soil for renewed nutrients.</p>
                        <br/>
                        <p>Putting a cactus into a larger pot doesn't automatically mean that it will grow any bigger - it may have the opposite effect and could even kill the cactus as the larger pot will retain a lot more water and may cause the roots to rot. </p>
                        <p>The Urban Sprout have some really useful information about <a className="linkUnderline" href="https://www.theurbansprout.com/will-a-cactus-grow-bigger-in-a-bigger-pot/" target="_blank" rel="noopener noreferrer">cacti and larger pots</a>.</p>
                        <p>There was some very useful cactus information in the leaflet from the local garden centre as well.</p>
                        <br/><br/>
                        <p>I have also done some re-potting for my outdoor gardener badge when growing from seed. When the seedlings start getting tall and spindly, it's time to move them on!</p>
                        <br/><br/>
                        <div className="gallery">
                            <div className="imgThird"><img src={Potting1} alt="Baby plants separated and potted." /></div>
                            <div className="imgThird"><img src={CactiInfo1} alt="Leaflet showing information on caring for cacti." /></div>
                            <div className="imgThird"><img src={CactiInfo2} alt="Leaflet showing information on caring for cacti." /></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">06</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light">Nurture</div>
                    <div className="badgePageItemInfo reverse hidden">When your orchid loses its flowers nurture it so that it blooms a second time.</div>
                    <div className="badgePageItemEvidence">
                        <p>It finally lost it's flowers :(  I have cut it back, given it some water and some food. Fingers crossed it blesses me with new flowers!</p>
                        <br/>
                        <img className="imgThird" src={Orchid1} alt="Orchid cut back for re-growth" />
                        <br/><br/>
                        <p>I kept an orchid feeder solution in the pot and occassionally watered the roots.</p>
                        <p>Then, something very exciting happened ... my orchid actually came back to life!!! :)</p>
                        <br/>
                        <p>I spotted what looked like a little bulge in the stem and sure enough it turned out to be a new branch. Unfortunately, it didn't survive the extreme heat of summer and died when I was away on holiday. However, soon after I noticed that there was another little bulge further down one of the stems, and this one grew and thrived!</p>
                        <br/>
                        <p>I have several new blooms looking beautiful again now!</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Orchid2} alt="Close up of a new orchid branch." /></div>
                            <div className="galleryItem"><img src={Orchid3} alt="Close up of a new orchid branch." /></div>
                            <div className="galleryItem"><img src={Orchid4} alt="Close up of a new orchid branch and buds." /></div>
                            <div className="galleryItem"><img src={Orchid5} alt="Close up of a new orchid branch and buds." /></div>
                            <div className="galleryItem"><img src={Orchid6} alt="Close up of a new orchid branch and buds." /></div>
                            <div className="galleryItem"><img src={Orchid7} alt="Close up of a new orchid branch and buds." /></div>
                            <div className="galleryItem"><img src={Orchid8} alt="Close up of a new orchid branch, buds and flowers." /></div>
                            <div className="galleryItem"><img src={Orchid9} alt="Close up of a new orchid branch, buds and flowers." /></div>
                            <div className="galleryItem"><img src={Orchid10} alt="Close up of a new orchid branch, buds and flowers." /></div>
                            <div className="galleryItem"><img src={Orchid11} alt="Close up of a new orchid branch, buds and flowers." /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IndoorGardener