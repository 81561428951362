import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import XmasWreath1 from '../images/XmasWreath1.jpg';
import XmasWreath2 from '../images/XmasWreath2.jpg';
import XmasWreath3 from '../images/XmasWreath3.jpg';
import XmasWreath4 from '../images/XmasWreath4.jpg';
import XmasWreath5 from '../images/XmasWreath5.jpg';
import XmasWreath6 from '../images/XmasWreath6.jpg';
import XmasWreath7 from '../images/XmasWreath7.jpg';
import XmasWreath8 from '../images/XmasWreath8.jpg';
import XmasOrnament1 from '../images/XmasOrnament1.jpg';
import XmasOrnament2 from '../images/XmasOrnament2.jpg';
import XmasOrnament3 from '../images/XmasOrnament3.jpg';
import XmasOrnament4 from '../images/XmasOrnament4.jpg';
import XmasOrnament5 from '../images/XmasOrnament5.jpg';
import XmasOrnament6 from '../images/XmasOrnament6.jpg';
import XmasOrnament7 from '../images/XmasOrnament7.jpg';
import XmasGift1 from '../images/XmasGift1.jpg';
import XmasGift2 from '../images/XmasGift2.jpg';
import XmasGift3 from '../images/XmasGift3.jpg';
import XmasGift4 from '../images/XmasGift4.jpg';
import XmasPaper1 from '../images/XmasPaper1.jpg';
import XmasPaper2 from '../images/XmasPaper2.jpg';
import XmasPaper3 from '../images/XmasPaper3.jpg';
import XmasPaper4 from '../images/XmasPaper4.jpg';
import XmasPaper5 from '../images/XmasPaper5.jpg';
import XmasPaper6 from '../images/XmasPaper6.jpg';
import XmasMarket1 from '../images/XmasMarket1.jpg';
import XmasMarket2 from '../images/XmasMarket2.jpg';
import XmasMarket3 from '../images/XmasMarket3.jpg';
import XmasWine1 from '../images/XmasWine1.jpg';
import XmasWine2 from '../images/XmasWine2.jpg';
import XmasWine3 from '../images/XmasWine3.jpg';
import XmasCard1 from '../images/XmasCard1.jpg';
import XmasCard2 from '../images/XmasCard2.jpg';
import XmasCard3 from '../images/XmasCard3.jpg';
import XmasCard4 from '../images/XmasCard4.jpg';
import XmasCard5 from '../images/XmasCard5.jpg';
import XmasCard6 from '../images/XmasCard6.jpg';

const Xmas22 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Christmas 2022"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Make a festive wreath</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence">
                        I don't have access to flowers and greenery and I'm trying to do as much badge work as possible without spending any more money, so I opted for using materials that I already had.
                        <br/><br/>
                        I love paper craft so I found a tutorial for a simple festive wreath to follow. Not quite the traditional paper flowers, but I've made a lot of those before so it was time for something different.
                        <br/><br/>
                        <a href="https://colorspapers.blogspot.com/2019/11/easy-make-christmas-paper-wreath-paper.html" target="_blank" rel="noopener noreferrer">Easy Make Christmas Paper Wreath tutorial</a>
                        <br/><br/>
                        <div className="hRow">
                            <img src={XmasWreath1} alt="Ring made out of cardboard to be used as a base for a festive wreath"/>
                            <img src={XmasWreath2} alt="Strips of paper for a festive wreath"/>
                            <img src={XmasWreath3} alt="Folded paper for a festive wreath"/>
                            <img src={XmasWreath4} alt="Fan folded paper for a festive wreath"/>
                            <img src={XmasWreath5} alt="Folded paper formed into fan shapes "/>
                            <img src={XmasWreath6} alt="Sticking fan folded paper onto a backing ring"/>
                            <img src={XmasWreath7} alt="All folded fan shapes stuck onto a backing ring to form a wreath"/>
                            <img src={XmasWreath8} alt="Completed festive wreath with a red paper box on top"/>
                            <br/><br/>            
                        </div>       
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Make a festive ornament</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence">
                        When I went away for My Adventure, my daughter brought a pack of festive card and a YouTube tutorial with her so we could make some decorations!
                        <br/><br/>
                        So, we poured some drinks, got some snacks and settled down in our hotel room for a crafty session.
                        <br/><br/>
                        These stars are really lovely and easy to make (with some patience for drying glue!)
                        <br/><br/>
                        <div className="hRow">
                            <img src={XmasOrnament1} alt="Card folded ready for gluing" />
                            <img src={XmasOrnament2} alt="Card folded up and glued ready to put together" />
                            <img src={XmasOrnament3} alt="Folded card in a star shape, with a hanging ribbon attached" />
                            <img src={XmasOrnament4} alt="Completed star ornaments" />
                            <br/><br/>
                        </div>
                        <br/><br/>
                        I also bought a packet of DIY reindeer, so I made those up too!
                        <br/><br/>
                        <div className="hRow">
                            <img src={XmasOrnament5} alt="Craft pack for making reindeer" />
                            <img src={XmasOrnament6} alt="Contents of a craft pack for making reindeer" />
                            <img src={XmasOrnament7} alt="Completed reindeer ornaments" />
                            <br/><br/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Handmake at least one present</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence">
                        OK, this is a strange present, I know! It has a story though.
                        <br/><br/>
                        Quite some time ago now, I went to an open mic night with my brother. He wanted to play and I went for moral support!
                        <br/><br/>
                        We were watching the other performers and one group had some shaking sticks made from bottle caps. He instantly fell in love and told me my next project was to make him one.
                        <br/><br/>
                        I regularly do beach cleaning so finding these bottle caps is not a problem! I started storing them up in preparation. In the summer when I found a broken kids spade, I removed the broken plastic parts and saved the wooden handle.
                        <br/><br/>
                        Fast forward a couple of years and I still hadn't got round to doing anything with them, so when this badge came up it was a perfect fit!
                        <br/><br/>
                        So, here's me manually drilling holes into many metal bottle caps and hammering them into a spade handle!!
                        <br/><br/>
                        <div className="hRow">
                            <img src={XmasGift1} alt="Bottle Caps being prepared for drilling" />
                            <img src={XmasGift2} alt="Bottle Caps drilled and being hammered onto some wood" />
                            <img src={XmasGift3} alt="Wooden handle with bottle caps hammered on " />
                            <img src={XmasGift4} alt="A finished music shaker toy made from bottle caps" />
                        </div>
                        <div className="hRow">
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Make eco-friendly wrapping paper</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence">
                        I'll be the first to say that this didn't go particularly well and it doesn't look great, but it's pretty I guess!
                        <br/><br/>
                        This uses the following materials:
                        <ul className="listWrite">
                            <li>Brown paper saved from an Amazon delivery</li>
                            <li>Plant-based, biodegradable glitter</li>
                            <li>Glue made from flour, sugar and vinegar</li>
                            <li>Pistachio nut shells</li>
                            <li>Brown paper tape</li>
                            <li>Metal bell</li>
                        </ul>
                        <br/><br/>
                        <a href="https://woodme.site/how-to-make-homemade-and-eco-friendly-glue/" target="_blank" rel="noopener noreferrer">How to make homemade and eco friendly glue tutorial</a>
                        <br/><br/>
                        My concession to non-eco-friendly was the hot glue holding the pistachios, but the paper itself is eco-friendly!
                        <br/><br/>
                        <div className="hRow">
                            <img src={XmasPaper1} alt="Brown paper with stencils" />
                            <img src={XmasPaper2} alt="Brown paper with stencilled christmas pictures" />
                            <img src={XmasPaper3} alt="A bowl containing home-made glue" />
                            <img src={XmasPaper4} alt="Brown paper with glitter being added" />
                            <img src={XmasPaper5} alt="Present wrapped in home made wrapping paper" />
                            <img src={XmasPaper6} alt="Present wrapped in home made wrapping paper" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Watch at least three christmas movies</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence">
                        Oh now this one is very easy!!!  I had Christmas movies on whilst I was doing various arts and crafts and this is what I have watched so far:
                        <br/><br/>
                        <ul className="listWrite">
                            <li>Falling for Christmas</li>
                            <li>Office Christmas Party</li>
                            <li>I believe in Santa</li>
                            <li>Who Killed Santa</li>
                            <li>Christmas With You</li>
                            <li>The Grinch</li>
                            <li>Deck the Halls</li>
                            <li>Operation Christmas Drop</li>
                            <li>Christmas Under Wraps</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Visit a Christmas market</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence">
                        We visited Southampton Christmas Market when we stayed away for My Adventure. I didn't take lots of photos, but here's one from the ice rink where we hid in a corner and drank mulled wine and shared a portion of dirty fries!!
                        <br/><br/>
                        <div className="hRow">
                            <img src={XmasMarket1} alt="A christmas reindeer display outisde the ice rink at Southampton Christmas Market" />
                            <img src={XmasMarket2} alt="City walls in Southampton" />
                            <img src={XmasMarket3} alt="Mulled Wine and Dirty Fries at Southampton Christmas Market" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Make your own mulled wine</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence">
                        I visited one of our local zero waste stores and picked up a bag of mulled wine spices. I used this to make my own mulled wine which I always drink whilst I put up the christmas decorations!
                        <br/>
                        <br/>
                        <div className="hRow">
                            <img src={XmasWine1} alt="Mulled wine spices in a packet" />
                            <img src={XmasWine2} alt="Mulled wine simmering in a pan" />
                            <img src={XmasWine3} alt="Mulled wine in a glass" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Make your own festive cards</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence">
                        Pergamano is a craft I was introduced to many years ago by my Auntie. It has been a shared passion for  along time but I don't do it often any more because I don't often have occassion to do so.
                        <br/><br/>
                        I took this opportunity to make a christmas card for her using the Pergamano techniques, although I couldn't use my white and green tinta inks as they had dried up! I used art pens to do the outlining and tinta inks for the colours.
                        <br/><br/>
                        <div className="hRow">
                            <img src={XmasCard1} alt="Parchment and tools ready for making a Pergamono card" />
                            <img src={XmasCard2} alt="Parchment showing a drawing in progress of a Pergamano bauble" />
                            <img src={XmasCard3} alt="Pergamano card showing a bauble with embossing complete" />
                            <img src={XmasCard4} alt="Pergamano card showing a bauble with embossing and pricking complete" />
                            <img src={XmasCard5} alt="Pergamano card showing a bauble with emobssing, pricking and cutting complete" />
                            <img src={XmasCard6} alt="Completed Pergamano card showing a christmas bauble" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Xmas22