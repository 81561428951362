import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const TVCritic = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"TV Critic"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Watch Shows</div>
                    <div className="badgePageItemInfo purple-light hidden">Choose 5 different TV shows to watch over a 4 wk period. They should span different genres and not be something you would usually choose.</div>
                    <div className="badgePageItemEvidence">
                       <p>These were the 5 shows that I watched. Three were shows I had redcently watched that were different in genre to what I would usually watch and two were taken from a list put in the buddy group.</p>
                       <ul className="list">
                        <li>Mary & George</li>
                        <li>Hazbin Hotel</li>
                        <li>Young Royals</li>
                        <li>Kin</li>
                        <li>Confessions of an Ibiza Drug Mule</li>
                       </ul>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Reviews</div>
                    <div className="badgePageItemInfo purple-light hidden">Write reviews of each show. What did you like? What didn't you like? What did you learn? Did anything surprise you?</div>
                    <div className="badgePageItemEvidence">
                        <p><strong>Mary and George - Historical Drama - NowTV / Sky. On Starz very soon!</strong></p>
                        <br/>
                        <p>This is a historical drama about Mary Villiers and how, after the death of her husband, she elevates her family through her second son George from nothing to being the most important and influential person in the court of King James in the Jacobean period.</p>
                        <p>Created by D.C.  Moore and starring Julianne Moore as Mary, Nicholas Galitzine as George and Tony Curran as King James I.</p>
                        <p>The writing is engaging and modern whilst supporting the historical genre. There is a lot packed in to eight episodes, but it is a relatively short time period. You need to pay attention to keep up with the characters that come in and out and their influence on the plot line. </p>
                        <p>The casting is superb, showing a wide variety of different characters with the actors effortlessly interacting to carrry the plot forward. Julianne is perfect as the scheming Mary Villiers and Tony Curran brilliantly brings to life the mercurial King James. Nicholas Galitzine, as George Villiers, has the biggest character arc and progression, there is little he didn't go through either as a character or an actor on this set! </p>
                        <p>The visuals of the show are dramatic, embodying the fashion and decoration of the period. The costumes and hair are very elaborate. Filmed across a wide variety of castles and houses, the locations are grandiose, showing clearly the luxuries of the royal court.</p>
                        <p>Whilst the story is clearly a product of the Jacobean period - I understand there is a lot of historical accuracy contained in the modern telling - the story of sex, power and their influence and corruption is still relatable in current times. Mary shows the resilience of women in protecting their families and George? Well George turns himself into power personified, but eventually it did, as ever, become his downfall.</p>
                        <br/>
                        <p>I really enjoyed this series, but it won't be for everyone. It is rated 15, and contains a lot of sexual content between all variations of gender.</p>
                        <br/><br/><br/><br/>
                        <p><strong>Young Royals</strong></p>
                        <br/>
                        <p>You could be forgiven at times for thinking I am a teen girl, but that's not actually the case! I did however get  really hooked on this!</p>
                        <p>I heard a buzz about it on Instagram and decided to check it out. I almost immediately stopped watching because I quickly realised it was dubbed in English and my brain really struggles to rationalise the disparity between what I am seeing on the screen and what I am hearing!! </p>
                        <p>owever, I did decide to go back to it and try and get beyond that and I am very happy I did. I discovered after finishing it that the cast did their own English dubbing, so it was at least their real voices I was listening to.</p>
                        <br/>
                        <p>Young Royals tells the story of Willhelm, who is a Prince of Sweden, as he navigates life at Hillerska boarding school. When he meets Simon - a non-boarder - he struggles to navigate the course between his heart and the crown, with often dramatic consequences.</p>
                        <br/>
                        <p>Created by Lisa Ambjorn, Lars Beckung and Camilla Holter it is set on location in Sweden between a manor-style building and a castle, representing the school and the royal palace respectively. It has just concluded it's last ever episode of three seasons. </p>
                        <br/>
                        <p>The young cast are brilliant, both the main cast and supporting characters, but the strength undoubtedly comes from the incredible chemistry between Edvin Ryding (who plays Prince Wilhelm) and Omar Rudberg (who plays Simon Eriksson).  This is Omar's debut acting role and he brings a very strong performance, but Edvin has been acting from a young age and it really shows. He is masterful in his delivery of some very emotional scenes. </p>
                        <br/>
                        <p>The story has a clear progression from start to end, with the usual school and teenage issues developing and resolving. There is a natural antagonist in the form of August, 2nd Cousin of Wilhelm - played by Malte Gardinger - and it is easy to hate him, but there is a child-like depth to him and it's easy to unravel his motivations. Frida Argento who plays Sara (Simon's sister) and Nikita Uggla, who plays Felice Ehrencrona, form the remainder of the main cast, bringing their own unique characters into force and they have a big influence on the storyline.</p> 
                        <br/>
                        <p>The inclusion of Simon's friends from outside of the school as supporting cast also provides a really nice balance and some fun to the story.</p>
                        <br/>
                        <p>The adult cast complement the main cast very well, being a strict and guiding presence, without dominating the screenplay.</p>
                        <br/>
                        <p>There is plenty of music involved, with the school choir performing at various occassions and with Simon writing for Wilhelm. It is a story steeped in boarding school traditions, as well as royal protocols,  fighting against changing, modern attitudes. I love the soundtrack. </p>
                        <br/>
                        <p>It addresses stories of initiation, drug-taking (in the form of prescription pills), mental health, Aspergers, ADHD, eating disorders, sex and relationships. There is nothing that is gratuitous, all the scenes forward and inform the plot.</p>
                        <br/>
                        <p>I have absolutely loved watching this, it really appealed to me, but as ever it won't be for all. It's inspiring to watch such a young cast excel in telling this story and I hope that at some point we may see more of them on channels available in the UK. </p>
                        <br/><br/><br/><br/>
                        <p><strong>High: confessions of an Ibiza drug mule</strong></p>
                        <p>This is a documentary following the story of “The Peru Two”, Michaella McCollum and Melissa Reid, who were caught up in a drug smuggling ring and ultimately arrested whilst transporting around 11kg of cocaine from Lima, Peru to Ibiza.</p>
                        <p>Produced by the BBC, it is currently available through BBC iPlayer and Netflix and there are five episodes. </p>
                        <p>At first, it comes across as a drama, with narration over a dramatisation, but then is taken over by direct interview with Michaella, who sits alone in front of the camera, illustrated with dramatisations of events and interviews with other people to further clarify the story. </p>
                        <p>It is a terrifying example of how easy it is to slip into a situation that you quickly lose control of. Groomed during a holiday to Ibiza, Michaella and Melissa ended up in maximum security prison in South America where they were facing 15 years imprisonment, eventually being released in just over two years.</p>
                        <p>The series is easy to watch, despite the subject matter and is lively in presentation. The only thing I find a little frustrating is the use of SMS messages popping up on the screen during earlier episodes. They do add emphasis to the story, but personally I'd prefer things to be included in the audio. This is an illustrative technique used in a lot of programs now so not specific to this production!</p>
                        <p>A wide range of people were interviewed from both the families, communities and people involved in the case, giving interesting commentary on the story at the time as it developed in the media. Some appeared in shadow with voice changing techniques, to provide inside information on the operations.</p>
                        <p>The descriptions of prison life and relationships provided an interesting contrast to life as portrayed in UK prisons and the stories of the other women housed there are very sad. </p>
                        <br/>
                        <p>It's hard to say that you enjoy programs like this, but it kept my attention and was good to hear the story directly from one of the people involved. I hope that maybe it may prevent other people from falling into the same path.</p>
                        <br/><br/><br/><br/>
                        <p><strong>Hazbin Hotel - Prime. 1 Season, 9 Episodes (the pilot is on YouTube, you can watch fine without it).</strong></p>
                        <p>Adult Animated Musical, strictly 18+.</p>
                        <p>Trigger Warnings: rated TV-MA</p>
                        <br/>
                        <p>Now, if you want something completely different … ! I include this because the genre is less usual and the content even more so.  I don't generally watch animated series, I don't have anything against them, just prefer to watch non-animated content. </p>
                        <p>I kept hearing a song used in reels on Instagram and it led to this. If there was ever going to be an animation that pulled me in, an Adult Musical Animation was going to do it. More so because of where it is set … Hell!</p>
                        <br/>
                        <p>Warning: this has very adult themes throughout and is rated TV-MA for mature content. This indicates graphic violence, explicit sexual activity, or crude indecent language. </p>
                        <p>It is - somewhat obviously - about death and sin, being set in Hell but has an overall theme of rehabilitation and redemption!</p>
                        <br/>
                        <p>Hazbin Hotel is the story of Charlie, the Princess of Hell, who has opened a new hotel to rehabilitate the sinners with the aim of getting them into Heaven, preventing the annual exterminations carried out by Adam and the angels for the purposes of “population control”.</p>
                        <br/>
                        <p>Sounds horrific, right? It would be, if it weren't for the insanely (annoyingly) catchy music! Charlie has endless hope and enthusiasm and drags everyone along with her, singing and dancing! Vaggie, her partner, tries to keep her calm and limit the singing, but not to very great effect.  Every character has a really intriguing back story, of how they died, at what age and  how they became who they are in Hell, although you will have to go looking at the Wiki to get a lot of that!</p>
                        <br/>
                        <p>At the beginning of the story, Charlie has one resident at the hotel, Angel Dust (a sex worker). Alastor, the Radio Demon, suddenly turns up after a 7 year absence to “help” and add a little controversy, bringing with him Nifty (a problematic maid) and Husk (a jaded bar man), closely followed by rival Sir Pentious (a snake) and his egg boiz who decide to join the hotel crew for redemption. We have villains “The Vees” - Valentino (a moth), Vox (a TV set) and Velvette (a demon) - various other supporting characters and Charlie's good old dad, Lucifer himself (who has a love for rubber ducks). </p>
                        <p>On the other side of the universe, we have the Heaven-based crew, who cause a lot of problems for, well, everyone! There is St. Peter at the gates, Adam - first man and leader of the exterminators - Lute, his second in command and Sera and Emily the angels.</p>
                        <br/>
                        <p>Voiced by a collection of well-known Broadway performers and voice actors, the music is an absolute powerhouse of performance. Bop, banger, belter - whatever your preferred term, I defy you not to sing along after your first watch!</p>
                        <p>Written by Vivienne Medrano, Hazbin Hotel has been around for a long time on YouTube and other platforms, it was finally made into a series on Prime and first aired in January 2024, so it already has a large, established fan base who are very devoted.</p>
                        <p>The battles and stories between the different characters are brilliant, and the amount of detail in every scene is just incredible, I know there is a lot that I missed and have subsequently learned through watching YouTube creators point things out.</p>
                        <p>It's easy to watch though, colourful and lively, you don't need to think too hard about it. </p>
                        <p>But does Charlie succeed in redeeming any souls? After an unexpectedly early extermination attack from Heaven, will any of the sinners survive? Will any of them make it to Heaven? </p>
                        <p>Give it a watch, and you too could find yourself rooting for Hell.</p>
                        <br/><br/><br/><br/>
                        <p><strong>Kin - BBC</strong></p>
                        <p>Set in Dublin, Kin is about the Kinsellas, a local crime family and their involvement in a gangland war. In all ways out-done by rival family, the Cunningham cartel, they struggle to hold their ground whilst facing threats against their businesses and  their lives.  Alleged to be based upon real world events involving the Kinahan gang.</p>
                        <br/>
                        <p>Kin was created by Peter McKenna and Ciaran Donnelly and directed for four episodes by Diarmuid Goggins then subsequently by Tessa Hoffe. The show has been compared to the series Love/Hate, with viewers split over which series is better!</p>
                        <br/>
                        <p>It has a strong cast, headed by Aidan Gillen as Frank Kinsella and Ciaran Hinds as rival boss Eamon Cunningham. Clare Dunne holds a strong role and  her storyline is definitely one to watch.</p>
                        <br/>
                        <p>Whilst I didn't dislike the series, it didn't have the same pull for me as others have. I don't have any specific critiicism of the writing or production, but it felt to me like too many other shows of the genre. The only difference in this one is that children are considered “fair game” and that, to me, was a step further than I was comfortable with. </p>
                        <br/>
                        <p>I wasn't planning to watch season two, but by the end of season one I was curious enough to stick with it. There were some developing stories that I was interested to see play out and my prediction for the end of season two wasn't wholly wrong, but happened differently than I expected which is a good thing!</p>
                        <br/>
                        <p>Whether or not the storylines reflect real life really depends on your knowledge of how this particular world operates.  It certainly operates on the same terms as other shows in the genre, with the importance of family ties, negotations and retribution. It's good to see strong female characters playing important parts in the family dynamics.  In my opinion, it's a fair representation but all reports state specifically that it is fiction.</p>
                        <br/>
                        <p>Your experience of this one will likely be based on your previous viewing. If you've not watched this genre before, it could be quite compelling; as long as you're comfortable with violence and bad language! With mostly favourable reviews online, it is a good series with a great cast and worth the watch</p>
                        <br/>
                        <p>“This is fucking ominous.” - Viking</p>
                   </div>
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Compare</div>
                    <div className="badgePageItemInfo purple-light hidden">Find a written review, look at Twitter - what is the consensus? Do you agree?</div>
                    <div className="badgePageItemEvidence">
                        <p><strong>Mary & George Reviews</strong></p>
                        <br/>
                        <p>Here is a written review from Digital Spy:</p>
                        <p>https://www.digitalspy.com/tv/a46984719/mary-and-george-review/</p>
                        <p>Do I agree with this review? Yes. In fact I have only seen glowing reviews of this show, and I definitely support them.</p>
                        <p>Here are a couple more examples:</p>
                        <ul className="list">
                        <li>The Guardian praises Julianne Moore's performance as “ludicrously good fun” in this 17th-century drama. </li>
                        <li>https://inews.co.uk/culture/television/mary-and-george-review-2938235?ico=in-line_link</li>
                        <li>https://collider.com/mary-and-george-review/</li>
                        <li>https://www.empireonline.com/tv/reviews/mary-george/</li>
                        </ul>
                        <br/>
                        <p>I had to look hard to find anything negative. The Digital Spy article quoted above is a posiive review, buts urges caution to anyone expeting anything “Bridgerton” esque! The characters are not as endearing and the settings not as lavish.</p>
                        <p>The Telegraph suggested that it doesn't stand out as much as it could have a few years ago and that the trend of period dramas being arch and rude with shocking sex scenes and language has become run of the mill.</p>
                        <p>Metacritic shows mixed reviews with 58% positive reviews, but any negativity seems to be directed at specific scenes rather than the whole show.</p>

                        <p>I agree with Nicholas Galtzine - who plays George - that nothing was gratuitous. It was all in advancement of the plot. Sex was how he earned his power, and showing how he achieved his progression from naïve young boy who opened the show by trying to kill himself, to becoming the most influential person in the country was necessary. King James was known for his proclivities, to not represent them as they were would give a false narrative. We shouldn't sanitise history.</p>

                        <p>Over on Twitter / X - and on social media generally - it has been causing a buzz.</p>
                        <ul className="list">
                        <li>Many fans are praising the show for its dark and sexy appeal, with one viewer describing it as a ‘historical series' that they absolutely loved.</li>
                        <li>The performances of Julianne Moore, Nicholas Galitzine, and Tony Curran have been highlighted, with calls for them to receive awards for their roles.</li>
                        <li>Another fan mentioned the phenomenal acting, calling Julianne Moore's portrayal of Mary Villiers terrifying and Nicholas Galitzine mesmerising as George.</li>
                        </ul>
                        <br/><br/><br/><br/>
                        <p><strong>Young Royals Reviews</strong></p>
                        <ul className="list">
                            <li>Radio Times describes it as a “predictable but heartfelt Swedish teen drama” that leans into the intensity of first love. The review appreciates the authentic teenage cast and the series' approach to the classic ‘royal and a commoner' trope.</li>
                            <li>PureWow gives the show a rating of 3 out of 5 stars, noting its predictability but also praising its depiction of LGBTQ themes and royal tradition. The review highlights the realistic portrayal of teen life and the refreshing inclusion of actual teenagers in the cast.</li>
                            <li>Decider initially expresses a lukewarm reaction, suggesting the series feels like another teen drama about privileged students. However, it acknowledges the show's exploration of the characters' directions in life.</li>
                        </ul>
                        <br/>
                        <p>Do I agree with the reviews? I don't think I do entirely. I didn't find it to be predicatble, maybe that says more about me than the show. There are plenty of twists and turns along the way!</p>
                        <p>The Twitterverse has been largely positive, with much love for the relationship between Wilhem and Simon.</p>
                        <p>Twitter users have shared their reactions to the Netflix show “Young Royals,” particularly the series finale. Here are some highlights:</p>
                        <ul className='list'>
                            <li>Fans have described the finale as an “absolutely perfect” and “emotional rollercoaster” that provided a satisfying conclusion to the series.</li>
                            <li>Viewers appreciated the character development and the resolution of the relationship between Wilhelm and Simon, with many expressing their emotional investment in the couple's journey.</li>
                            <li>The hashtag #WilmonForever became a trend among fans, celebrating the love story between the main characters.</li>
                        </ul>
                        <p>#WilmonForever</p>
                        <br/><br/><br/><br/>
                        <p><strong>High: Confessions of a Drug Mule Reviews</strong></p>
                        <ul className='list'>
                            <li>The Review Geek describes the show as an “interesting but rather shallow account” of Michaella McCollum's story, one half of the infamous “Peru Two.” The review notes that while the series includes some “golden nuggets of information,” it lacks depth and fails to explore crucial aspects of Michaella's life and decisions.</li>
                            <li>Alison Rowat's review in HeraldScotland mentions that the documentary, while focusing on McCollum's perspective, misses the input of Melissa Reid, the other half of the “Peru Two.” The review suggests that the documentary feels incomplete and lacks critical exploration of the reasons behind the events.</li>
                            <li>Rotten Tomatoes features a review by John Doyle from Globe and Mail, who criticizes the show for being a “bewildering concoction of half-truths” and for glorifying foolish behavior.</li>
                            <li>Leisurebyte's review points out that the documentary feels more like a sensational news segment without offering deep insights into Michaella's character or her life after prison.</li>
                        </ul>
                        <br/>
                        <p>Do I agree with these reviews? In part.  There was probably room for more background, I didn't feel it was incomplete or glorifying the issue though.</p>
                        <br/>
                        <p>On Twitter / X and social media there has been some discussion, showing both empathy and skepticism.</p>
                        <ul className='list'>
                            <li>Some viewers found the show interesting and Michaella McCollum likable, despite acknowledging that she made a poor decision as a young person.</li>
                            <li>Others believe that by sharing her story, McCollum is being courageous and may help others avoid making similar mistakes</li>
                            <li>There are also critical voices that question the portrayal of McCollum as a sympathetic figure, arguing that the documentary glosses over the severity of her actions and the consequences of drug smuggling.</li>
                            <li>A few comments suggest that the show doesn't fully reveal the truth and that no one could be as naive as portrayed at 20 years old. However, there is also respect for how McCollum turned her life around after the incident.</li>
                        </ul>   
                        <br/><br/><br/><br/>
                        <p><strong>Hazbin Hotel Reviews</strong></p>
                        <br/>
                        <ul className='list'>
                            <li>Sayantan Gayen from CBR praised the show, stating, “Hazbin Hotel wraps up Season 1 with a satisfying and stellar conclusion to a build-up that has been in the works since the series made landfall.” He gave it a 9/10</li>
                            <li>Sarah Bea Milner from That Shelf had a different take, describing the series as “an untethered narrative mess with a reliance on adult humour and an aggressive aesthetic” and seemed to be disappointed with its transition to a full series</li>
                            <li>Vera Wylde from Council of Geeks recommends giving it a shot if you're into "more adult animation with swears and open acknowledgment of sex, and drugs, and drinking, and violence"</li>
                            <li>Gregory Wakeman, a top critic from Chicago Reader, felt that despite its valiant attempts, the show "ultimately delivers too few laughs to be worthwhile"</li>
                            <li>Charlie Ceates from Cultured Vultures called the first season a “wonderful Frankenstein creation of different genres” and found it truly memorable, scoring it an 8/10</li>
                        </ul>
                        <br/>
                        <p>Do I agree with these reviews? Again, in part. I loved the show, but I was coming at it completely fresh, not having previously been part of the fandom on YouTube or other social media before it made it to Prime, so I didn't feel any of the disappointment of OG fans. There;s certainly no shortage of detail or content in the show, even beyond the swearing and other adult content. Clearly this one is divisive, with people either loving or hating it!</p>
                        <br/>
                        <p>There is always a lot of buzz on Twitter / X and other social media about the show. The fandom is certainly devoted and are very outspoken about their favourite show! There is a lot of fanfic and fan art.</p>
                        <ul className='list'>
                            <li>Reddit Discussions: On platforms like Reddit, there are mixed opinions. Some users question why there is a significant amount of dislike for the show on Twitter, noting that much of the criticism may stem from its adult themes, representation of LGBTQ+ characters, and the creator's past controversies1.</li>
                            <li>Metacritic Reviews: Users on Metacritic have described “Hazbin Hotel” as queer, raunchy, and fun, with memorable songs and exciting character arcs.</li>
                            <li>IGN Opinion: An opinion piece on IGN appreciates the balance in the narrative of “Hazbin Hotel”, although it suggests that more character work early on could have been beneficial.</li>
                            <li>CBR Analysis: CBR highlights that “Hazbin Hotel” resonates with viewers by offering representation to underrepresented communities through relatable character arcs and storylines.</li>
                        </ul>
                        <br/><br/><br/><br/>
                        <p><strong>Kin Reviews</strong></p>
                        <br/>
                        <ul className="list">
                            <li>Must watch Podcast: "The Irish crime drama continues the story of the Kinsella family, now at the top of the crime game in Dublin. But their triumph has incurred the wrath of an even more dangerous enemy, leading to suspicion, distrust, and resentment within the family. Definitely a Must Watch"</li>
                            <li>The Arts Desk: "“Kin” Season 2 delves into the shifting dynamics between the members of the Kinsella family. Dealing with rival gangs provides plenty of fuel for the eight new episodes, where the murder rate in Dublin rivals that of El Salvador or Al Capone's Chicago. The intricately written show probes family bonds and betrayal in the criminal underworld"</li>
                            <li>The Guardian: "in portrays a chilly, menacing Dublin, full of modern but charmless architecture, gripped by organized crime. The Kinsella family, who make their living by selling drugs supplied by ominous crime lord Eamon Cunningham, find themselves at his beck and call. The show doesn't make viewers fall in love with the characters but offers empathy and understanding in a bleak setting"</li>
                            <li>5 Live Must Watch Blog: "Scott Bryan and Hayley Campbell agree that Kin Season 2 is a Must Watch. The show, one of Ireland's biggest TV programs, continues its intricate exploration of crime families and shifting loyalties. While bleak, it features great performances and word-of-mouth appeal."</li>
                            <li>Extra IE: " Irish Twitter has had its say on Kin, and the show has garnered attention. The series, acquired by the BBC, combines family dynamics, crime, and betrayal in a gripping narrative. Viewers recommend starting from the beginning to fully appreciate the characters and their complex relationships"</li>
                        </ul>
                        <br/>
                        <p>Do I agree with the reviews? Mostly. The reviewers all seem to agree that the show is a "must watch" even though the setting is bleak and the characters not wholly likeable. I'd agree that it's worth the watch, although if you've seen other shows of this genre then you won't find much different in the narrative.</p>
                        <br/>
                        <p>Twitter and Social media have various comments:</p>
                        <ul className='list'>
                            <li>A Reddit user shared their thoughts on Kin Season 2. They found it much better than the first season, praising the improved plot and acting. However, they criticized some of the accents and acting quality</li>
                            <li>Fans have generally expressed disbelief following the ending of season 2</li>
                            <li>The dramatic twists and turns have left viewers wanting more with requests for a Season 3.</li>
                        </ul>
                    </div>
                </div>
            </div> 
             <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 4</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">Watch party</div>
                    <div className="badgePageItemInfo purple-light hidden">Agree to watch with someone else. Discuss. What did you like? What didn't you like? How did it make you feel?</div>
                    <div className="badgePageItemEvidence">
                        <pp>There is a buddy group in Nelson who are contributing to a review document - making suggestions and selecting the ones that they want to watch.</pp>
                    </div>
                </div>
            </div> 
             <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 5</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light">3 Part Drama</div>
                    <div className="badgePageItemInfo purple-light hidden">Choose a drama with at least 3 parts. Watch all, step back and analyse. Who were the most impactful characters? What were their arcs? What were the key messages?</div>
                    <div className="badgePageItemEvidence">
                        <p><strong>3 Body problem</strong></p>
                        <br/>
                        <p>Wow! That was a ride! Thank you for the recommendation. If you've read any of my other reviews you'll see that I've been watching some fairly light things recently, so it was good to get back to something of heavier substance, just about right to satisfy my inner nerd! </p>
                        <p>I'm not doing a full or deep review of this, partly to avoid spoilers and also partly because the clause doesn't call for a review, so I'll do my analysis separately, but maybe this will help someone else decide whether to watch.</p>
                        <p>You really, really need to concentrate on this one - the only reason it didn't quite work for me is because I use TV as background whilst doing other things which makes it frustrating when I have to keep looking up! That's a me problem, not a criticism of the show, but I say it to highlight the need to be focussed on it, or you will miss important information!</p>
                        <br/>
                        <p>3 Body Problem is an interpretation of a book of the same name. It is classifed as Fantasy Drama and Science Fiction, and it's certainly a brain-bender. It follows the story of five friends, as they investigate strange occurences around the world in the science community, which ultimately leads them to the inevitability of inter-planetary invasion. It is so much more than that though. Hopping across time, location, language and dimension, there is plenty to keep your attention. </p>
                        <br/>
                        <p>Violent from the outset, it does present some graphic scenes. It weaves a story of the influence that the decision of one person can have on world events.  Within the first half of the season, we get to understand what the “3 Body Problem” is, and I found all that fascinating. The themes of the show include first contact, technological advancement and the survival of civilisations.</p>
                        <br/>
                        <p>It is great to see some very strong ladies taking the lead, not to take anything away from the male leads of course, there were no weak points that I saw among the cast. With a mixture of names I didn't know and a couple of very well known faces, it was a nice balance. It has very sad undertones, it isn't a happy program, but the intrigue stops that from becoming too heavy.</p>
                        <br/><br/>
                        <p>A great watch, well worth the time, and maybe an interesting warning of things to come! </p>
                        <br/><br/>
                        <p>Has the universe ever winked at you?</p>
                        <br/><br/><br/><br/>
                        <p><strong>Which characters were most impactful?</strong></p>
                        <ul className='list'>
                            <li>Ye Wenjie is without a doubt the most impactful character, as it is her decisions that create the impact that is the plot of the show.</li>
                            <li>The Oxford Five - Auggie, Jin, Saul, Jack and Will - are the main cast driving the story.</li>
                            <li>Mike Evans is an influential character who supports the ETO.</li>
                            <li>Clarence ‘Da' Shi is a detective, working on unravelling the mysteries that are happening.</li>
                            <li>Thomas Wade, head of the world's most elite intelligence operation.</li>
                        </ul>
                        <br/>
                        <p>These characters are central to the series' narrative, driving the plot forward with their actions and decisions.</p>
                        <br/><br/><br/><br/>
                        <p><strong>Character arcs</strong></p>
                        <ul className='list'>
                            <li>Ye Wenjie - starts as an astrophysice prodigy during the Cultural Revolution but evolves into a very complex character whose decisions impact humanity in a profound way.</li>
                            <li>Clarence - an unusual character who has, as we understand, been fired from various agencies and is on his last chance. He grows whilst unravelling the mysteries and ultimately ends up protecting the five.</li>
                            <li>Mike Evans - a wealthy heir who uses his resources to support the ETO, which highlights how personal beliefs and wealths can influence global events.</li>
                            <li>Jack - deals with the scientific and ethical dilemmas posed by the events and pays the ultimate price.</li>
                            <li>Saul - grapples with the existential threats and moral complexitis of the crisis.</li>
                            <li>Auggie - navigates the challenges of scientific discovery and the weight of human survival</li>
                            <li>Jin - shows a blend of scientific curiosity, personal struggle and a search for truth. She is resilient and relentless in her pursuit of knowledge, even when facing the unknown.</li>
                        </ul>
                        <br/><br/><br/><br/>
                        <p><strong>Key Messages</strong></p>
                        <br/>
                        <p>The key messages in the Netflix series “3 Body Problem” include:</p>
                        <ul className='list'>
                            <li>The Consequences of Human Actions: The series explores how decisions, especially those made by Ye Wenjie, can have significant and far-reaching consequences.</li>
                            <li>The Search for Extraterrestrial Life: It delves into humanity's attempts to communicate with alien civilizations and the potential risks involved.</li>
                            <li>Humanity's Response to Crisis: The characters' actions reflect different approaches to dealing with an existential threat, highlighting themes of resilience and cooperation.</li>
                            <li>The Significance of Individual Lives: The series poses philosophical questions about the value of a single life in the context of larger global or cosmic events.</li>
                            <li>Science and Ethics: It examines the ethical dilemmas faced by scientists and the impact of scientific discoveries on society.</li>
                            <li>Cultural and Historical Reflections: The backdrop of the Cultural Revolution and its impact on the characters provides a historical perspective on the narrative.</li>
                        </ul>
                        <br/><br/>
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default TVCritic