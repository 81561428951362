import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const AdvancedSelfCare = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"Advanced Self Care"} area={"booktwo"} />
            <div className="badgePageItem">This isn't a very easy badge to evidence, so below is an overview of what I focused on.</div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>Water intake is something that I need to be better with. I have always hated drinking water!  I installed an app that allows me to set targets for a day and gives me regular reminders to drink whicn has been really helpful.</p>
                        <br/>
                        <p>I did have to make an adjustment, as I saw a sudden correlation with my water intake increase and resurgence of regular migraine. There is a link between migraine and low sodium, so it's possible I was washing away my sodium content - or it could just be conincidence.</p>
                        <br/>
                        <p>I have a much better balance now to ensure that I stay hydrated and drink water more than I do anything else, which I never would have thought possible!</p>
                    </div>
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>My second self-care item was to maintain a regular bedtime. I need 7 hours, I'm up at 6am, so I have to be asleep for 11pm ideally.</p>
                        <br/>
                        <p>I'm not going to say that I make it every single night, sometimes if I've worked late my brain is over-stimulated and I need longer to cool off, but 99% of the time I'm in bed when I should be!</p>
                        <br/>
                        <p>In fact, if I'm feeling particularly tired I will go even earlier, which - as a night owl - has always been unheard of. So, I'm striking a healthy balance currently!</p>
                    </div>
                </div>
            </div>          
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">Clause 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>Screen time is a problem for me. I have a bit of an addiction to my phone and spend a lot more time doom scrolling than I should! I recently left my phone somewhere and the anxiety was real!</p>
                        <br/>
                        <p>My aim has been to stop the scrolling and get back to more productive things in the evenings and weekends. It's not always easy because I spend up to 10hours a day on the computer for work, so I don't always want to do training or online things in the evening, but Rebel Badge Club has helped with that, as I often now have things to make or do offline .</p>
                        <br/>
                        <p>I get weekly reports from my phone that tell me how much I have used my phone in comparison to the previous week and that helps me monitor it.</p>
                        <br/>
                        <p>I've achieved 8 additional certificates in different training areas recently and have more courses purchased and waiting, so I'm definitely back on track now.</p>
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default AdvancedSelfCare