import React from 'react';
import {Link} from 'react-router-dom';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Chef1 from '../images/chef1.jpg';
import Chef2 from '../images/chef2.jpg';
import Chef3 from '../images/chef3.jpg';
import Chef4 from '../images/chef4.jpg';
import Chef5 from '../images/chef5.jpg';
import Chef6 from '../images/chef6.jpg';
import Chef7 from '../images/chef7.jpg';
import Chef8 from '../images/chef8.jpg';
import Chef9 from '../images/chef9.jpg';
import Chef10 from '../images/chef10.jpg';
import Chef11 from '../images/chef11.jpg';
import Chef12 from '../images/chef12.jpg';
import Chef13 from '../images/chef13.jpg';
import Chef14 from '../images/chef14.jpg';
import Chef15 from '../images/chef15.jpg';
import Chef16 from '../images/chef16.jpg';
import Chef17 from '../images/chef17.jpg';
import Chef18 from '../images/chef18.jpg';
import Chef19 from '../images/chef19.jpg';
import Chef20 from '../images/chef20.jpg';
import Chef21 from '../images/chef21.jpg';
import Chef22 from '../images/chef22.jpg';
import Chef23 from '../images/chef-dinnerparty1.jpg';
import Chef24 from '../images/chef-dinnerparty2.jpg';



const Chef = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Chef"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle green">01</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Cooking skills</div>
                    <div className="badgePageItemEvidence">
                        This is a hard one to evidence, but this is something I've been doing for the last couple of years. I have been switching my diet around to become a majority of vegetarian / vegan dishes rather than meat so I have been exploring lots of different recipes.
                        <br/><br/>
                        As a busy working person, my style previously has been that I'll only cook what I can throw in the oven or on the hob for twenty minutes, walk away and serve!! Still healthy food, but lazy and uninteresting! I was definitely in a rut of eating the same things over and over.
                        <br/><br/>
                        Now, I have a folder full of new recipes and I cook from scratch more often than not with lots of ingredients I'd never dreamt of using before and I have a very full spice rack which I make good use of!
                        <br/><br/>
                        I have re-ignited my love for cooking and found some very tasty meals, but I'm always hunting and trying new things now, building up my recipe collection!
                        <br/><br/>
                        Just to break up the text, here's my spice rack and another rack of different condiments!
                        <br/><br/>
                        <img className="imgQuarter" src={Chef1} alt="Spice rack full of herbs and spices" />&nbsp;
                        <img className="imgQuarter" src={Chef2} alt="Kitchen corner shelf with bottles of condiments" />
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">02</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Prepare meals from scratch</div>
                    <div className="badgePageItemEvidence">
                        This is something that I have now been doing for probably at least a year. I do take photos, but I find that although everything tastes amazing, the photos make it look like the most un-appetising thing in the world!! I guess I need to focus on food photography ;)
                        <br/><br/>
                        Here are some photos anyway, try not to be put off!
                        <br/><br/>
                        <div className="chefGallery">
                            <div className="chefItem"><img src={Chef3} alt="Sticky Peanut Steak" title="Sticky Peanut Steak" width="200px" height="200px" /></div>
                            <div className="chefItem"><img src={Chef4} alt="Miso Roast Chicken" title="Miso Roast Chicken" width="200px" height="200px" /></div>
                            <div className="chefItem"><img src={Chef5} alt="Cajun Spiced Salmon" title="Cajun Spiced Salmon" width="200px" height="200px" /></div>
                            <div className="chefItem"><img src={Chef6} alt="Spiced cauliflower steaks, halloumi and pickled red onions" title="Spiced cauliflower steaks, halloumi and pickled red onions" width="200px" height="200px" /></div>
                            <div className="chefItem"><img src={Chef7} alt="Broccoli and Walnut Panzanella" title="Broccoli and Walnut Panzanella" width="200px" height="200px" /></div>
                            <div className="chefItem"><img src={Chef8} alt="Roasted cauliflower with tomato and cashew sauce" title="Roasted cauliflower with tomato and cashew sauce" width="200px" height="200px" /></div>
                            <div className="chefItem"><img src={Chef9} alt="Asparagus, beetroot and egg" title="Asparagus, beetroot and egg" width="200px" height="200px" /></div>
                            <div className="chefItem"><img src={Chef10} alt="Ginger vegetable stir fry" title="Ginger vegetable stir fry" width="200px" height="200px" /></div>
                            <div className="chefItem"><img src={Chef11} alt="Russian beetroot, cheese and prune salad" title="Russian beetroot, cheese and prune salad" width="200px" height="200px" /></div>
                            <div className="chefItem"><img src={Chef12} alt="Cajun spiced salmon" title="Cajun spiced salmon" width="200px" height="200px" /></div>
                            <div className="chefItem"><img src={Chef13} alt="Cheese and onion stuffed squash" title="Cheese and onion stuffed squash" width="200px" height="200px" /></div>
                            <div className="chefItem"><img src={Chef14} alt="Thai beef and sprout stir fry with salt and pepper cashews" title="Thai beef and sprout stir fry with salt and pepper cashews" width="200px" height="200px" /></div>
                            <div className="chefItem"><img src={Chef15} alt="Broccoli and walnut panzanella" title="Broccoli and walnut panzanella" width="200px" height="200px" /></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">03</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Experiment</div>
                    <div className="badgePageItemEvidence">
                        I have got dishes from everywhere now, including:
                        <ul className="list">
                            <li>Turkish</li>
                            <li>Hungarian</li>
                            <li>Chinese</li>
                            <li>Thai</li>
                        </ul>
                        <br/><br/>
                        These are some of the new ingredients that I have tried:
                        <ul className="list">
                            <li>Galangal</li>
                            <li>Harissa</li>
                            <li>White Miso</li>
                            <li>Kimchi</li>
                            <li>Tamarind</li>
                            <li>Tamari</li>
                            <li>Teriyaki Sauce</li>
                            <li>Sriracha</li>
                            <li>Jalapeno</li>
                            <li>Pomegranate Molasses</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">04</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Knife skills</div>
                    <div className="badgePageItemEvidence">
                        Another rebel shared a link to a free knife skills course which I took, and it was absolutely brilliant!
                        <br/><br/>
                        I learnt a lot from that course and I now have a brand new set of very sharp knives (courtesy of secret Santa) so I can practice what I learned!!
                        <br/><br/>
                        (Secret Santa was so worried about this he later got me a special kitchen glove that I can use to save my fingers!!)
                        <br/><br/>
                        You can find the course at Craftsy: <a href="https://www.craftsy.com/class/complete-knife-skills/560/" target="_blank" rel="noopener noreferrer">Brendan McDermott's Complete Knife Skills </a>
                        <br/><br/>
                        I really recommend watching it if you like spending time in the kitchen. Here are the notes I took!
                        <br/><br/>
                        Four basic knifes:
                        <br/>longer blade is safer and more efficient
                        <br/>Stainless steel or carbon - personal preference.
                        <br/>SS - doesn't rust, doesn't hold edge as long
                        <br/>CS - holds edge longers, oxidises

                        <br/>1. Chef Knife
                        <br/>2. Serrated - not just for bread! Confectioners knife, serrated utility knife. Tougher waxy skins - squash, peppers, melon (can get left-handed version)
                        <br/>3. Paring knife 
                        <br/>4. Boning knife - flay knife, flexible for fish and chicken, more rigid for cutting other meats
                        <br/><br/>
                        Can sharpen on ceramic - rough area on plate of coffee mug
                        <br/><br/>
                        Need a good cutting board - safe and stable
                        <br/>Wood - sand down and use mineral oil
                        <br/><br/>
                        <br/>Low cut - slice from the back of the knife, not chop from the front, Keep food still, knife comes down onto food
                        <br/>High cut - use for carrots, parsnips, squash, pineapple, melon, bring knife up off the board
                        <br/><br/>
                        Plateaus or planks are the starting point for creating julienne and dice
                        <br/><br/>
                        Cutting Onions
                        <br/>* use a sharp knife
                        <br/>* slice
                        <br/>Cut end off, put new flat edge on the board
                        <br/>Cut in to the root, cut vertically
                        <br/>Peel, remove the silky skin
                        <br/>Follow the grain - half moons are great but they are against the grain. This cuts down on the moisure that makes you cry
                        <br/><br/><br/>
                        Horizontal cut
                        <br/>Put item on edge of board
                        <br/>Knife blade is flat on the board
                        <br/><br/>
                        Pull cut
                        <br/>Low cut but pull towards you instead of pushing away
                        <br/><br/><br/>
                        Garlic and shallots
                        <br/>Garlic - use paring knife, Horizontal cut about 3/4 through, Pull cut, Chef knife to low cut
                        <br/>Shallot - like onion but use paring knife
                        <br/><br/><br/>
                        Jalapeno - Paring knife, cut off the top, use spine of knife and rotate - cores the pepper! Pith is the hottest part. Use serrated to cut.
                        <br/><br/><br/>
                        Caulifower - paring knife. Turn upside down, cut into heart all the way round. Twist and pull the stalk. Go round again, then pull out florets
                        <br/><br/><br/>
                        Citrus
                        <br/>Don't use zester.
                        <br/>Cut  a little off top and bottom for flat surface
                        <br/>Right where white meets orange, shave through, very fine slices. Remove any white.
                        <br/>Chef knife, low cut into julienne then dice
                        <br/><br/>
                        <br/>To continue to segments, use same slice to remove the pith
                        <br/>Use paring knife, cut in  between segments to core.
                        <br/>Gets segment without the membrane
                        <br/><br/><br/>
                        Tomato - use serrated knife
                        <br/>Take a bit off top and bottom
                        <br/>Cut around the core so you're not wasting anything.
                        <br/>Use pull cut to draw through skin, saw off skin and around core
                        <br/>Pull out the seeds
                        <br/>Julienne then dice
                        <br/><br/>
                        <br/>Cherry Tomato - use two lids, pour into lid, put another on top, you can horizontal slice through them!
                        <br/><br/><br/>
                        Bell Peppers
                        <br/>Take off top and bottom, take out core from top
                        <br/>Slice down one side, pull open, pull out seeds and core
                        <br/>Push down to flatten out
                        <br/>Horizontal cut through membrane to remove the pith/core
                        <br/>Julienne
                        <br/><br/><br/>
                        Pineapple
                        <br/>Use serrated knife
                        <br/>Take off top and bottom
                        <br/>Take off skin - more cuts you make, the less you waste - slice, don't chop
                        <br/>Turn onto side, cut in half.
                        <br/>Cut around centre core into segments
                        <br/>Chef knife slice into segments
                        <br/><br/><br/>
                        Butternut Squash
                        <br/>(it's just butternut squash, not a knife fight!!)
                        <br/>Use serrated knife
                        <br/>Cut where the shape changes
                        <br/>High cut - Slice don't chop!
                        <br/>Cut off top
                        <br/>Top: Slice off skin top to bottom, Chef knife, cut in half, High cut into plateaus, then slice and dice
                        <br/><br/><br/>
                        Avocado
                        <br/>Buy firm, little bit of softness
                        <br/>Cut in half lengthways, following seed in the centre - use chef knife with horizontal cut
                        <br/>Twist and it comes apart
                        <br/>Don't dig stone out, slice into it and twist
                        <br/>Use either chef or paring knife
                        <br/>Pull knife through flesh, turn if you want to dice
                        <br/>Use spoon to scoop out
                        <br/>Or slice, then peel off skin
                        <br/><br/><br/>
                        Herbs
                        <br/>Use chef knife and low cut
                        <br/>Parsley and Cilantro
                        <br/>Parsley - flavour in leaf
                        <br/>Cilantro - flavour in stem, use first three leaves and stem
                        <br/>Bunch in hand, use chef knife and  high cut, shave off leaves
                        <br/>Clean up - stick in bowl of water, shake up, let them float to the top.
                        <br/>Crush herbs into a ball in your hand, bunch onto board, low cut with chefs knife
                        <br/><br/>
                        Basil needs more finesse!
                        <br/>Take maybe 4 or 5 leaves, pick them off the stem
                        <br/><br/>Chiffonade - chopping technique in which leaves are cut into long, thin strips
                        <br/>Stack them up
                        <br/>Roll them up like a cigar
                        <br/>Low cut
                        <br/><br/><br/>
                        Pomegranate
                        <br/>Cut in half around the middle
                        <br/>Pull to loosen up
                        <br/>Tap around with the back of the knife, seeds fall out
                        <br/><br/><br/>
                        Kiwi
                        <br/>Cut off top and bottom
                        <br/>Put flat end on board
                        <br/>Use spoon to slide underneath skin then pull around
                        <br/>Cut with chefs knife
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">05</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Three-course meal</div>
                    <div className="badgePageItemEvidence">
                    <p>Prepare yourselves for more dodgy photos!!</p>
                    <br/><br/>
                    <strong>Starter</strong>
                    <br/>
                    <p>Here we have devilled eggs! I've never tried them before and always wanted to, so these are my starter!</p>
                    <br/>
                    <img className="imgThird" src={Chef20} alt="Spicy spaghetti with garlic mushrooms" />
                    <br/><br/>
                    <p>Recipe: <a className="linkUnderline" href="https://www.deliciousmagazine.co.uk/recipes/miso-devilled-eggs/" target="_blank" rel="noopener noreferrer">https://www.deliciousmagazine.co.uk/recipes/miso-devilled-eggs/</a></p>
                    <br/>
                    <p>I neglected to sieve my egg yolks, so unfortunately my lovely piping all went wrong because it was too thick! Still tasted good though!</p>
                    <br/><br/><br/>
                    <strong>Main Course</strong>
                    <br/>
                    <p>Spicy Spaghetti with garlic mushrooms</p>
                    <br/>
                    <img className="imgThird" src={Chef21} alt="Spicy spaghetti with garlic mushrooms" />
                    <br/><br/>
                    <p>Recipe: <a className="linkUnderline" href="https://www.bbcgoodfood.com/recipes/spicy-spaghetti-garlic-mushrooms" target="_blank" rel="noopener noreferrer">https://www.bbcgoodfood.com/recipes/spicy-spaghetti-garlic-mushrooms</a></p>
                    <br/><br/><br/>
                    <strong>Pudding</strong>
                    <br/>
                    <p>Chia Coconut Pudding</p>
                    <br/>
                    <p>OK, can we not focus on how much this reminds me of frogspawn!!</p>
                    <br/>
                    <img className="imgThird" src={Chef22} alt="Chia coconut pudding" />
                    <br/>
                    <p>Recipe: <a className="linkUnderline" href="https://www.allrecipes.com/recipe/237469/chia-coconut-pudding-with-coconut-milk/" target="_blank" rel="noopener noreferrer">https://www.allrecipes.com/recipe/237469/chia-coconut-pudding-with-coconut-milk/</a></p>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">06</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Modify a recipe</div>
                    <div className="badgePageItemEvidence">
                        I do this fairly regularly as I will substitute with vegan ingredients or with dairy-free alternatives as I am dairy allergic.
                        <br/><br/>
                        Sometimes I also have to find substitutes when I run out of ingredients!
                        <br/><br/>
                        Some replacements I have made:
                        <ul className="list">
                            <li>Plant Based milk instead of dairy in the baking badge for cream and custard tarts</li>
                            <li>Vegan "sausage" instead of pork sausage</li>
                            <li>Quorn mince instead of pork mince in a hot and spicy soup recipe</li>
                            <li>I used a tahini and soy sauce combination as a substitute for White Miso paste</li>
                            <li>I have substituted lime juice for tamarind</li>
                            <li>Coconut milk from tins or from powder instead of dairy milk</li>
                            <li>Oat milk in a Roux Sauce instead of dairy</li>
                        </ul>
                        <br/><br/>
                        So although I haven't replaced three ingredients in one recipe, I do regularly substitute and I am getting much better at knowing what flavours I do and don't like and change things up acordingly!
                        <br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">07</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Dinner Party</div>
                    <div className="badgePageItemEvidence">
                    <p>I combined this with my world traveller badge and cooked up a Hawaiian dinner party for the family while we were away on holiday.</p>
                    <br/>
                    <ul className="list">
                        <li>Starter: Lomi Lomi Salmon</li>
                        <li>Main: Classic Loco Moco</li>
                        <li>Pudding: Hawaiian Style Custard Pie</li>
                    </ul>
                    <br/>
                    <p>Here are a couple of photos of the starter and main. There are more photos on my <Link to="/WorldTraveller" className="linkUnderline">World Traveller page</Link>.</p>
                    <br/>
                    <div className="gallery">
                        <div className="galleryItem"><img src={Chef23} alt="" /></div>
                        <div className="galleryItem"><img src={Chef24} alt="" /></div>
                    </div>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">08</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Gluten-free and vegan</div>
                    <div className="badgePageItemEvidence">
                        This is something I have done fairly frequently as I have followed different diets throughout the last few years to try and eliminate food sensitivities.
                        <br/><br/>
                        I have worked with gluten-free flours for baking, but I do more with vegan recipes than gluten free now. I stay away from carbohydrates so a lot of what I eat is naturally free from gluten!
                        <br/><br/>
                        Here are some of the vegan recipes I have cooked recently:
                        <ul className="list">
                            <li>Spiced "chorizo" pastry slices</li>
                            <li>Brussel sprouts pad thai</li>
                            <li>Beetroot and red onion tarte tatin</li>
                            <li>Broccoli and walnut panzanella</li>
                            <li>Aromatic spinach and potato curry</li>
                            <li>Satay Noodle Soup</li>
                            <li>Sticky noodles with homemade hoisin</li>
                            <li>Roasted cauli-broc bowl with tahini hummus</li>
                            <li>Vegan Thai Curry</li>
                            <li>Ginger vegetable stir fry</li>
                            <li>Roasted cauliflower with tomato and cashew sauce</li>
                        </ul>
                        <br/><br/>
                        Here are some photos of these. The potato curry is photographed with egg,clearly it is only vegan if served withouth the egg!
                        <br/><br/>
                        <div className="chefGallery">
                            <div className="chefItem"><img src={Chef16} alt="Roasted cauli-broc bowl with tahini hummus" title="Roasted cauli-broc bowl with tahini hummus" /></div>
                            <div className="chefItem"><img src={Chef17} alt="Potato and spinach curry" title="Potato and spinach curry" /></div>
                            <div className="chefItem"><img src={Chef18} alt="Filling for spiced 'Chorizo' pastry slices" title="Filling for spiced 'Chorizo' pastry slices" /></div>
                            <div className="chefItem"><img src={Chef19} alt="Beetroot and red onion tarte tatin" title="Beetroot and red onion tarte tatin" /></div>

                        </div>

                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle green">09</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo green-light">Recipe book</div>
                    <div className="badgePageItemEvidence">
                        I have done this by default as I've printed out the recipes that I like and, whilst not an actual book, they live in a folder in the kitchen and are used regularly!!
                        <br/><br/>
                        My 20 favourites at the moment are
                        <ul className="list">
                            <li>Broccoli and Walnut panzanella</li>
                            <li>Ginger vegetable stir fry</li>
                            <li>Aromatic spinach and potato curry</li>
                            <li>Hot and Sour Soup</li>
                            <li>Cajun Salmon with mango slaw</li>
                            <li>Thai beef and sprout stir-fry with salt and pepper cashews</li>
                            <li>Brussel Sprouts pad thai</li>
                            <li>Crispy potato and halloumi bake</li>
                            <li>Sticky Peanut Steak</li>
                            <li>Turkish Menemen</li>
                            <li>Shakshuka</li>
                            <li>Asparagus salad with a runny poached egg</li>
                            <li>Beetroot and Halloumi salad with pomegranate and dill</li>
                            <li>Pumpkin Soup</li>
                            <li>Hungarian Goulash</li>
                            <li>Spicy spaghetti with garlic mushrooms</li>
                            <li>Sausage curry</li>
                            <li>Easy vegan pho</li>
                            <li>Gingery Broccoli Fry with Cashews</li>
                            <li>Russian Beetroot, cheese and prune salad</li>
                        </ul>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Chef