import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import PinaColada from '../images/cupjuly23-sundae17.jpg';
import tea1 from '../images/mixologist-tea1.jpg';
import tea2 from '../images/mixologist-tea2.jpg';
import tea3 from '../images/mixologist-tea3.jpg';
import tea4 from '../images/mixologist-tea4.jpg';
import Mary1 from '../images/mixologist-mary1.jpg';
import Mary2 from '../images/mixologist-mary2.jpg';
import Marg1 from '../images/mixologist-marg1.jpg';
import Marg2 from '../images/mixologist-marg2.jpg';
import Cosmo1 from '../images/mixologist-cosmo1.jpg';
import Cosmo2 from '../images/mixologist-cosmo2.jpg';
import Boul1 from '../images/mixologist-boul1.jpg';
import Boul2 from '../images/mixologist-boul2.jpg';

const Mixologist = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"Mixologist"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Pina Colada</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>Back in July, I made a Pina Colada to go with my Sundae, it wasn't officially part of the challenge so I am using it here.</p>
                        <p>Recipe: https://www.bbc.co.uk/food/recipes/pinacolada_86525</p>
                        <img className="imgThird" src={PinaColada} alt="A Pina Colada cocktail in a short glass, decorated with Pineapple leaves, a cocktail umbrella, a cube of pineapple and a cherry" />
                    </div>
                </div>
            </div>       
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Long Island Iced Tea (Mocktail)</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>Recipe: https://www.deliciousmagazine.co.uk/recipes/non-alcoholic-long-island-iced-tea/</p>
                        <br/>
                        <img className="imgQuarter" src={tea1} alt="Loose Tea in a jug of hot water" />
                        <img className="imgQuarter" src={tea2} alt="Strained, steeped tea in a large plastic jug" />
                        <img className="imgQuarter" src={tea3} alt="Apple Cider Vinegar, Ginger Beer, a jug of tea and a glass containing lemon wedges" />
                        <img className="imgQuarter" src={tea4} alt="A Long Island Iced Tea mocktail in a tall glass" />
                    </div>
                </div>
            </div>    
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Boulevardier</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>Recipe: https://www.liquor.com/recipes/boulevardier/</p>
                        <br/>
                        <img className="imgQuarter" src={Boul1} alt="Bottles of Vermouth, Campari and Jim Beam on a counter-top" />&nbsp;
                        <img className="imgQuarter" src={Boul2} alt="A Boulevardier cocktail in a short glass" />
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Bloody Mary</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>Recipe: https://www.bbcgoodfood.com/recipes/bloody-mary </p>
                        <br/>
                        <img className="imgQuarter" src={Mary1} alt="Worcestershire Sauce, Tomato Juice, Tabasco Sauce and a small bottle of Vodka" />&nbsp;
                        <img className="imgQuarter" src={Mary2} alt="A Bloody Mary cocktail in a tall glass with a stick of celery." />
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Margarita (Mocktail)</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>Recipe: https://www.thespruceeats.com/margarita-mocktail-recipe-5201293</p>
                        <br/>
                        <img className="imgQuarter" src={Marg1} alt="Salt, a bottle of Agave Nectar, a bottle of lemon juice with two limes and an orange" />&nbsp;
                        <img className="imgQuarter" src={Marg2} alt="A Margarita mocktail in a short glass with salt on the rim" />
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Cosmopolitan</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>Recipe: https://www.bbcgoodfood.com/recipes/cosmopolitan-cocktail</p>
                        <br/>
                        <p>I also made my own lemon vodka for this one, recipe: https://www.tasteofhome.com/article/lemon-vodka/</p>
                        <br/>
                        <img className="imgQuarter" src={Cosmo1} alt="A bottle of Triple Sec, a drink shaker containing vodka and lemon wedges, a bottle of lime juice and a carton of cranberry juice" />&nbsp;
                        <img className="imgQuarter" src={Cosmo2} alt="A Cosmopolitan cocktail in a short glass" />
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default Mixologist