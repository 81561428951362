import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const MyOutdoors = () => {
    return (
        <div>
            <ActionBar area={"silver"}  />
            <EvidenceHeader title={"My Outdoors"} />
            <div className="mavPageItem">
                <div className="mavPageItemTitle silver">My Outdoors</div>
                <div className="mavPageItemContent">
                    <div className="mavPageItemInfo silver-light">Plan and execute an adventure which involves at least three consecutive nights in the outdoors. You can either camp or glamp (e.g. in a hut, permanent tent, caravan, camper van or treehouse). Take part in at least three outdoor activities during the adventure which challenge you. These might be adventurous activities, or ones which involve pure enjoyment of the outdoors, such as birdwatching or stargazing.</div>
                    <div className="mavPageItemEvidence">
                    
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyOutdoors