import React from 'react';
import {Link} from 'react-router-dom';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import equity from '../images/equity-equality.jpg';
import PeriodPoverty from '../images/PeriodPoverty.png';


const HeroMar23 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Be a Hero"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue-light">My Powers</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-lighter">Complete the My Powers Badge</div>
                    <div className="badgePageItemEvidence"> 
                        I have completed this badge but due to the personal nature I am not uploading the evidence to this site.
                        <br/><br/>
                        <Link to="/mypowers">My Powers badge</Link>
                        <br/><br/>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue-light">Equity and equality</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-lighter">Explain the difference between equity and equality in your own chosen way</div>
                    <div className="badgePageItemEvidence"> 
                        <p>My favourite explanation of this has always been in images like the one below:</p>
                        <img className="imgThird" src={equity} alt="Illustration of the difference between equity and equality using people standing on boxes to reach apples on a tree" />
                        <br/><br/>
                        <p>Equality is making sure that everyone has the same - but this does not always ensure that everyone benefits in the same way.</p>
                        <br/>
                        <p>Equity means that everyone receives something different but the end result is that they experience the same benefit.</p>
                        <br/>
                        <p>The image is a perfect representation of this, in that the smallest person doesn't receive the same benefit as the largest person because using the same equipment does not give them the same outcome.</p>
                        <br/>
                        <p className="srcCite">Image Source: https://press.rebus.community/openatthemargins/chapter/breaking-open-ethics-epistemology-equity-and-power/</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue-light">Suffrage</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-lighter">Learn about the history of the suffrage movement and the suffagettes</div>
                    <div className="badgePageItemEvidence"> 
                        The suffrage movement started in 1832 with Mary Smith who presented a petition to Henry Hunt MP that stated that she and other spinsters should be able to have a say in the election of Members of Parliament. In 1866 the first suffrage petition was presented to the house of Commons with 1500 signatures.
                        <br/><br/>
                        Fast forwarding to 1897, the National Union of WOmen's Suffrage Societies was formed, with 17 societies. They favoured peaceful campaign methods.
                        <br/><br/>
                        Another petition, containing 37,000 signatures was presented by Women textile workers demanding votes for women in 1902.
                        <br/><br/>
                        In 1903 and Women's Social and Political Union was formed at the home of Emmeline Pankhurst. The WSPU favoured more militant action, which was adopted along with their motto "Deeds not words" in 1905.
                        <br/><br/>
                        Between 1907 and 1916 protests and demonstrations escalated, with over 3000 women attending the first procession from Hyde Park to Exeter Hall. Later processions rose to over 50,000 women and proceeded to marches, hunger strikes, smashing windows and arson.
                        <br/><br/>
                        Several bills and and acts were submitted and failed between 1867 and 1912:
                        <br/>
                        <ul className='list'>
                            <li>May 1867: Second Reform Bill - Unsuccessful amendment that would have allowed suffrage to women property holders</li>
                            <li>8 March 1907: The Women's Enfranshisement Bill - introduced for 2nd reading but talked out</li>
                            <li>August 1907: Qualification of Women Act passed, allowing women to be elected to borough and county councils and as mayor</li>
                            <li>November 1910: The Conciliation Bill - passed by Commons but fails to become law</li>
                            <li>March 1912: The Parliamentary Franchise (Women) Bill introduced - defeated by 222 votes to 208</li>
                        </ul>
                        <br/><br/>
                        Two Acts were introduced that direcly worked against the suffrage movement:
                        <br/>
                        <ul className='list'>
                            <li>November 1911: Asquith introduces a manhood suffrage bill</li>
                            <li>April 1913: The "Cat and Mouse" Act introduced - allows authorities to release suffragettes on hunger strike then re-arrest them once they have recovered.</li>
                        </ul>
                        <br/><br/>
                        Some early changes started to happen in 1870:
                        <br/>
                        <ul className='list'>
                            <li>December 1870: The Married Women's Property Act - married women have the right to own their own property and money</li>
                            <li>November 1870: Isle of Man - Amendment to the Manx Election Act 1875 - grants female suffrage </li>
                            <li>December 1894: Local Government Act - passed, allows married and single women to vote in elections for county and borough councils.</li>
                        </ul>
                        In 1912, the Labour Party became the first part to include Women's Suffrage in their manifesto
                        <br/><br/>
                        In 1916, Asquith made a declaration of allegience to women's enfranchisement.
                        <br/><br/>
                        There were competing factions amongst the suffrage movement, with different branches being created.
                        <br/>
                        <ul className='list'>
                            <li>January 1867: Manchester National Society for Women's Sufrage (MNSWS)</li>
                            <li>1897 - The National Union of Women's Suffrage Societies (NUWSS) - led by Millicent Fawcett, peaceful campaigns</li>
                            <li>October1903 - The Women's Social and Political Union (WSPU) - led by Emmeline Pankhurst, militant action</li>
                            <li>Autumn 1907: Wome's Freedom League (WFL)</li>
                            <li>July 1908 - The WOmen's National Anti-Suffrage League (WASL) - led by Mrs Humphrey Ward</li>
                            <li>October 1909: The WOmen's Tax Resistance League (WTRL), direct action group, 'No Vote, No Tax'</li>
                            <li>August 1910: WASL merges with Men's National League for Opposing Women's Suffrage</li>
                            <li>The East London Federation of Suffragettes expelled from the WSPU when Christabel Pankhurst says they are too concerned with other causes</li>
                            <li>March 1919:: NUWSS becomes the National Union of Societies for Equal Citizenship</li>
                        </ul>
                        <br/><br/>
                        There were several notable and tragic events during the process of protests and demonstrations:
                        <br/>
                        <ul className='list'>
                            <li>1907: 76 suffragettes, including Dora Thewlis, arrested when the WSPU tried to storm the Houses of Parliament.</li>
                            <li>July 1909: Marion Wallace Dunlop is the first imprisoned suffragette to go on hunger strike</li>
                            <li>November 1910: Suffragettes are met with police brutality, assaults and arrest during the "Black Friday" march on parliament</li>
                            <li>1911: Emily Wilding Davison avoids the census by hiding in a cupboard in the crypt at the House of Commons</li>
                            <li>June 1913: Emily Wilding Davison is killed when she steps out in front of the King's horse at the Epson Derby</li>
                            <li>December 1913: Sophia Duleep Singh is taken to court for refusing to pay taxes</li>
                            <li>8 March 1014: Sylvia Pankhurst arrested in front of Charing Cross Station on her way to speak in Trafalgar Square</li>
                        </ul>
                        <br/><br/>
                        Finally, in 1918 changes started to happen more widely:
                        <br/>
                        <ul className='list'>
                            <li>February 1918: The Representation of the People Bill - passed, allowing women over 30 to vote, if married to a member of the Local Government Register</li>
                            <li>November 1918: The Parliamentary Qualification of Women Act is passed -  enables women to stand as MPs</li>
                            <li>1918: Constance Markiewicz stands for Sinn Fein and is the first woman elected to Westminster. She declines the seat in line with SF politice.</li>
                            <li>November 1919: Nancy Astor takes her seat in the Houses of Commons as the first female MP for Britain</li>
                            <li>July 1928: The Representation of the People Act - entitles everyone over age 21 to vote</li>
                        </ul>
                        <br/><br/>
                        <strong>In May 1929, women over the age of 21 voted in their first general election.</strong>
                        <br/><br/>
                        <p>Timeline source: https://www.bl.uk/votes-for-women/articles/womens-suffrage-timeline</p>
                        <p>Additional source: https://www.bl.uk/votes-for-women/articles/the-campaign-for-womens-suffrage-an-introduction</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue-light">Period Poverty</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-lighter">Do something proactive to tackle period poverty - e.g. donating sanitary products to a food bank</div>
                    <div className="badgePageItemEvidence"> 
                        <p>I have previously supported period poverty through Global Citizen and by signing petitions.</p>
                        <br/>
                        <p>In the spirit of badges, this month I have made a donation of £10 to <a href="https://periodpoverty.uk/" target="_blank" rel="noreferrer noopener">Period Poverty</a>.</p>
                        <br/>
                        <img className="imgThird" src={PeriodPoverty} alt="Screenshot confirmation of donation to the Period Poverty website" />
                        <br/><br/>
                        <p>I looked at becoming a Red Rebel, but I don't have enough of a network of people for fundraising, so I will continue to individually support what I can, when I can!</p>
                        <br/><br/>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue-light">International Women's Day</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-lighter">Learn about the history of IWD and how it is celebrated around the world</div>
                    <div className="badgePageItemEvidence"> 
                        International Women's day started back in 1908 when 15,000 women marched through New York city demanding shorter hours, better pay and voting rights.
                        <br/><br/>
                        The very first National Women's Day was held on February 28th in the US and it continued on the last Sunday in February until 1913.
                        <br/><br/>
                        International Women's Day was formed in 1910 at the International Conference of Working Women in Copenhagen when Clara Zetkin proposed that there should be a global women's day every year to press for women's demands and celebrate women.
                        <br/><br/>
                        On 19th March 1911, IWD was first celebrated in Austria, Denmark, Germany and Switzerland.  On 23rd February 1913, Russian women held their first IWD
                        <br/><br/>
                        It was agreed in 1911 that IWD would be held on March 8th each year and remains on that date even now.
                        <br/><br/>
                        The United Nations celebrated IWD for the first time in 1975 and a resolution was adopted for a UN Dat for Women's Rights and International Peace to be observed on any day of the year by member states.
                        <br/><br/>
                        1996 introduced themes for each of the annual IWD celebrations, starting with "Celebrating the past, Planning for the Future". I have included a list of the themese at the end of this section.
                        <br/><br/>
                        The millenium saw a drop in interest for feminism, driving the creation of internationalwomensday.com which sprung up before the advent of social media to help spread the word.
                        <br/><br/>
                        A strong quote from the IWD website history: "This was a time when the rise of women was still largely seen as the fall of men"
                        <br/><br/>
                        2011 was the first centenary of IWD and Barack Obama declared that March 2011 would be "Women's History Month". Other events took place around the world:
                        <br/>
                        <ul className='list'>
                            <li>Hillary Clinton launched the "100 Women Initiative: Empowering Women and Girls through International Exchanges</li>
                            <li>Annie Lennox lead a march in London to raise awareness fo the charity Women for Women International</li>
                        </ul>
                        <br/><br/>
                        Since then, the movement has grown and with the advent of social media it became much easier to spread the message and it is celebrated across the world.
                        <br/><br/>
                        Many countries have made IWD an official holiday, where tradition sees men honoring their mothers, wives etc. with flowers and small gifts.
                        <br/><br/>
                        Other countries have made it like Mothers Day, with small gifts being given to mothers and grandmothers.
                        <br/><br/>
                        Globally, we see events such as rallies, conferences, government activities and networking and, more locally, craft markets, theatre performances, fashion shows and other celebrations.
                        <br/><br/>
                        Here's a selection of the different eventshappening globally, as listed on the IWD website:
                        <br/>
                        <ul className="list">
                            <li>Fun runs</li>
                            <li>Luncheons</li>
                            <li>Garden Parties</li>
                            <li>CardiGras and other festivals</li>
                            <li>Breakfast meetings and networking dinners</li>
                            <li>Afternoon Tea / High Tea</li>
                            <li>Galas, Summits and Symposia</li>
                            <li>Parties and Discos</li>
                            <li>Empowerment Fund</li>
                            <li>Talks and Fireside Chats</li>
                            <li>Marches</li>
                            <li>Arts Groups, Music, Songwriting and Theatre</li>
                            <li>Sea Swims</li>
                            <li>Wine Tasting</li>
                        </ul>                    
                        <br/><br/><br/>
                        <strong>IWD Themes</strong>
                        <ul className='list'>
                            <li>1996  Celebrating the Past, Planning for the Future</li>
                            <li>1997  Women and the Peace Table</li>
                            <li>1998  Women and Human Rights</li>
                            <li>1999  World Free of Violence Against Women</li>
                            <li>2000  Women Uniting for Peace</li>
                            <li>2001  Women and Peace: Women Managing Conflicts</li>
                            <li>2002  Afghan Women Today: Realities and Opportunities</li>
                            <li>2003  Gender Equality and the Millenium Development Goals</li>
                            <li>2004  Women and HIV/AIDS</li>
                            <li>2005  Gender Equality Beyond 2005; Building a More Secure Future</li>
                            <li>2006  Women in Decision-Making</li>
                            <li>2007  Ending Impunity for Violence Against Women and Grls</li>
                            <li>2008  Investing in Women and Girls</li>
                            <li>2009  Women and Men United to End Violence Against Women and Girls</li>
                            <li>2010  Equal Rights, Equal Opportunities: Progress for All</li>
                            <li>2011  Equal Access to Education, Training, and Science and technology: Pathway to Decent Work for Women</li>
                            <li>2012  Empower Rural Women, End Poverty and Hunger</li>
                            <li>2013  A Promise is a Promise: Time for Action to End Violence Against Women</li>
                            <li>2014  Equality for Women is Progress for All</li>
                            <li>2015  Empowering Women, Empowering Humanity: Picture it!</li>
                            <li>2016  Planet 50-50 by 2030: Step It Up for Gender Equality</li>
                            <li>2017  Women in the Changing World of Work: Planet 50-50 by 2030</li>
                            <li>2018  Time is Now: Rural and urban activists transforming women's lives</li>
                            <li>2019  Think Equal, Build Smart, Innovate for Change</li>
                            <li>2020  I am Generation Equality: Realizing Women's Rights</li>
                            <li>2021  Women in Leadership: Achieving an equal Future in a COVID-19 World</li>
                            <li>2022  Gender Equality today for a Sustainable Tomorrow #BreakTheBias</li>
                            <li>2023  DigitALL: Innovation and technology for gender equlity #EmbraceEquity</li>
                        </ul>
                        <br/>
                        <p>Source: https://www.slideshare.net/pkphcu/iwd-themes</p>
                        <p>Source: https://www.unwomen.org/en/news/stories/2019/12/announcer-international-womens-day-2020-theme</p>
                        <p>Source: https://www.unwomen.org/en/news/stories/2020/11/announcer-international-womens-day-2021</p>
                        <p>Source: https://www.internationalwomensday.com/2022Theme</p>
                        <p>Source: https://www.internationalwomensday.com</p>



                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue-light">Women's Rights</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-lighter">Learn about the different rights women have in your country and when they received those rights. Are there any rights women are still fighting for?</div>
                    <div className="badgePageItemEvidence"> 
                        <p>There is a timeline of women's rights in the UK at the Local Histories website: < a href="https://localhistories.org/a-timeline-of-womens-rights-uk/" target="_blank" rel="noopener noreferrer">A timeline of womens rights uk</a></p>
                        <br/><br/>
                        <p>The Fawcett Society have a report on women's rights and gender equality to 2016 here: <a href="https://www.fawcettsociety.org.uk/Handlers/Download.ashx?IDMF=45a5a7f5-ffbd-4078-b0b7-4bfcccab159d" target="_blank" rel="noopener noreferrer">Equality. It's About Time.</a></p>
                        <br/><br/>
                        <p>In 2023, women are still fighting for equal rights. Equal pay is still an issue in the UK and as of July 2022, the Government had removed women's rights commitments from the gender equality pact. This struck out the commitments to repeal laws threatening "sexual and reproductive health and rights" and "bodily autonomy" as reported in the i paper. </p>
                        <br/><br/>
                        <p>Women are still very much fighting to take control of their lives and their bodies from the grasp of the state.</p>
                        <br/><br/>
                        <p>The Covid-19 pandemic highlighted inequalities further between men and women, as women became more likely to lose their jobs, have to manage home-schooling and domestic chores. A survey carried out by Mumsnet showed that women are expecting a reversal of gender equality to take effect.</p>
                        <br/><br/>
                        <p>The same survey showed that there is still a hude disparity in domestic life as well, with 73% of women saying they still do all the laundry at home with percentages of well over 60% for other domestic chores as well.</p>
                        <br/><br/>
                        <p>For all the progress being made, we still have a society very weighted in favour of men and that is something that has to change.</p>
                        <br/><br/>
                        <p>Source: https://inews.co.uk/news/government-removes-womens-rights-gender-equality-pact-foreign-office-1760234</p>
                        <p>Source: https://www.theguardian.com/world/2021/mar/08/half-of-women-in-uk-fear-equality-is-going-back-to-1970s-survey</p> 
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue-light">Feminism</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-lighter">Learn about intersectional feminism and why it so important</div>
                    <div className="badgePageItemEvidence"> 
                        <p>The phrase "Intersectional Feminism" was created by Kimberle Crenshaw in 1989 and she defined it as "a prism for seeing the way in which various forms of inequality often operate together and exacerbate each other". </p>
                        <br/><br/>
                        <p>It shines a light on the overlaps of other inequalities when combined with race inequality as well as highlighting the historical and generational context surrounding inequalities faced by women.</p>
                        <br/><br/>
                        <p>It is important to consider IF in all situations, to ensure that everyone is receiving the support and solutions that are required. In times of crisis, some groups of people are overlooked because responses are targetted in a blanket way, rather than focussing on the needs of disparate groups of people, who subsequently suffer as a result of inadequate solutions.</p>
                        <br/><br/>
                        <p>Looking at situations through an IF lens allows you to see all the interconnected issues affecting communities and any areas where power structures or anything else that impacts the wellbeing of that community should be challenged.</p>
                        <br/><br/>
                        <p>There is a critical need for "normal" not to become the norm - what is "normal" should evolve alongside communities and the world. It is not valid to maintain the status quo, just because something has always been done in a certain way does not mean that it should continue in that way. </p>
                        <br/><br/>
                        <p>IF recognises that opression is not experienced by everyone in the same way, that it is experienced through a comination of ethnicity, religion, sex, social class, ability and age. It pvodies a better understanding of how oppression is uniquely experienced by women.</p>
                        <br/><br/>
                        <p>Source: https://www.unwomen.org/en/news/stories/2020/6/explainer-intersectional-feminism-what-it-means-and-why-it-matters</p>
                        <p>Source: https://simplysociology.com/intersectional-feminism.html</p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default HeroMar23