import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import Rebel from "../images/rebel-logo.png";


const Silver = () => {

    useEffect(() => {
        const elements = document.querySelectorAll(".mavItem");
        var currDate = new Date();

        elements.forEach((el) => {
            var oVal = 0;
            var oProg;
            var mProg;
            var count =  el.querySelectorAll(".mavClauseStatus").length;
            var maxval = 100 / count;

            el.querySelectorAll(".mavClauseStatus").forEach((elS) => {

                var cID = elS.id;
                var cStatus = elS.textContent;
                var cStart = el.querySelector("#" + cID.replace("Status","Start")).textContent;
                var cEnd = el.querySelector("#" + cID.replace("Status","End")).textContent;
                var cProg = el.querySelector("#" + cID.replace("Status","Progress"));
                oProg = el.querySelector("#" + cID.substring(0, cID.length-7).replace("1","") + "Progress");
                mProg = el.querySelector("#" + cID.substring(0, cID.length-7).replace("1","") + "ProgressMini");

                if (cStatus === "In progress")
                {
                    if (cEnd === "" && cStart !== "")
                    {
                        var startString = cStart.split("/");
                        var startDate = new Date(startString[2],startString[1]-1,startString[0]);
                        var diff = new Date(startDate - currDate);
                        var days =  Math.ceil(diff/1000/60/60/24);
                        var maxdays = -90;
                        cProg.value = (Math.ceil(days/maxdays*100));

                        var setP = days * maxdays / 100;

                        if(setP > maxval)
                        {
                            setP = maxval / 2;
                        }
                        oVal = oVal + setP;

                        cProg.classList.add("orange");
                        cProg.classList.remove("green");
                        cProg.classList.remove("red");
                    }
                    else if (cEnd !== "" && cStart !== "")
                    {
                        cProg.value = 100;
                        cProg.classList.add("green");
                        cProg.classList.remove("orange");
                        cProg.classList.remove("red");
                        oVal = oVal + maxval;
                    }
                }
                else if (cStatus === "Complete")
                {
                    cProg.value = 100;
                    cProg.classList.add("green");
                    cProg.classList.remove("orange");
                    cProg.classList.remove("red");
                    oVal = oVal + maxval;
                }
                else if (cStatus === "Not started")
                {
                    cProg.value = 1;
                    cProg.classList.remove("green");
                    cProg.classList.remove("orange");
                    cProg.classList.add("red");
                    oVal = oVal + 0;
                }
            })

            var oCalc = Math.ceil(oVal);
            oProg.value = oCalc;
            mProg.value = oCalc;

            
            if(oCalc <= 1)
            {
                oProg.classList.add("red");
                oProg.classList.remove("orange");
                oProg.classList.remove("green");
                mProg.classList.add("red");
                mProg.classList.remove("orange");
                mProg.classList.remove("green");
            }
            else if (oCalc < 100)
            {
                oProg.classList.remove("red");
                oProg.classList.add("orange");
                oProg.classList.remove("green");
                mProg.classList.remove("red");
                mProg.classList.add("orange");
                mProg.classList.remove("green");
            }
            else
            {
                oProg.classList.remove("red");
                oProg.classList.remove("orange");
                oProg.classList.add("green");
                mProg.classList.remove("red");
                mProg.classList.remove("orange");
                mProg.classList.add("green");
            }    
        })


        document.querySelectorAll(".mavItemIntro").forEach((elI) => {elI.classList.add("hidden")})
        document.querySelectorAll(".mavClause").forEach((elI) => {elI.classList.add("hidden")})
        document.querySelectorAll(".mavProgress").forEach((elI) => {elI.classList.add("hidden")})
        document.querySelectorAll(".icon-down-open").forEach((elI) => {elI.classList.add("shown")})
        document.querySelectorAll(".icon-up-open").forEach((elI) => {elI.classList.add("hidden")})

        document.querySelectorAll(".mavItemTitle").forEach((mit) => {addListener(mit);})

    }, [])



    return (
        <div>
            <div className="actionBar">
                <div className="actionBarItem"><Link to="/">Back to Dashboard</Link></div>
            </div>
            <div className="row bookPageTop">
                <div className="rebelLeft"><img src={Rebel} alt="Sticker of the word rebel" className="rebelLogoBookLeft" width="200px" height="127px" /></div>
                <div className="rebelMid">
                    <h2>Silver Maverick Award</h2>
                </div>
                <div className="rebelRight"><img src={Rebel} alt="Sticker of the word rebel" className="rebelLogoBookRight" width="200px" height="127px"     /></div>
            </div>
            <div className="row pageIntroduction silverIntro">
                <div>This page follows my progress in completing my Silver award.</div>
                <br/><br/>
            </div>

            <div className="mavItem">
                <div className="mavItemTitle silver" tabIndex={0} role="button" aria-expanded="false">
                    <div>My Change</div>
                    <div>
                        <progress id="changeProgressMini" value="73" max="100" className="orange"></progress>
                        <span className="icon-down-open"></span></div>
                    </div>
                <div className="mavItemIntro silver-light">Over a period of at least twelve months, make a positive change to your life, your neighbourhood, or your community.  The change should be specific, measurable and achievable. Prepare a presentation at the end of 12 months.<br/><Link to="/silver-mychange">View evidence page</Link></div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month One</div>
                    <div className="mavClauseStatus" id="change1Status">Not started</div>
                    <div className="mavClauseStart" id="change1Start"></div>
                    <div className="mavClauseEnd" id="change1End"></div>
                    <div className="mavClauseProgress"><progress id="change1Progress" value="100" max="100" className="orange"></progress></div>
                </div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month Two</div>
                    <div className="mavClauseStatus" id="change2Status">Not started</div>
                    <div className="mavClauseStart" id="change2Start"></div>
                    <div className="mavClauseEnd" id="change2End"></div>
                    <div className="mavClauseProgress"><progress id="change2Progress" value="100" max="100" className="orange"></progress></div>
                </div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month Three</div>
                    <div className="mavClauseStatus" id="change3Status">Not started</div>
                    <div className="mavClauseStart" id="change3Start"></div>
                    <div className="mavClauseEnd" id="change3End"></div>
                    <div className="mavClauseProgress"><progress id="change3Progress" value="100" max="100" className="orange"></progress></div>
                </div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month Four</div>
                    <div className="mavClauseStatus" id="change4Status">Not started</div>
                    <div className="mavClauseStart" id="change4Start"></div>
                    <div className="mavClauseEnd" id="change4End"></div>
                    <div className="mavClauseProgress"><progress id="change4Progress" value="100" max="100" className="orange"></progress></div>
                </div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month Five</div>
                    <div className="mavClauseStatus" id="change5Status">Not started</div>
                    <div className="mavClauseStart" id="change5Start"></div>
                    <div className="mavClauseEnd" id="change5End"></div>
                    <div className="mavClauseProgress"><progress id="change5Progress" value="100" max="100" className="orange"></progress></div>
                </div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month Six</div>
                    <div className="mavClauseStatus" id="change6Status">Not started</div>
                    <div className="mavClauseStart" id="change6Start"></div>
                    <div className="mavClauseEnd" id="change6End"></div>
                    <div className="mavClauseProgress"><progress id="change6Progress" value="100" max="100" className="orange"></progress></div>
                </div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month Seven</div>
                    <div className="mavClauseStatus" id="change7Status">Not started</div>
                    <div className="mavClauseStart" id="change7Start"></div>
                    <div className="mavClauseEnd" id="change7End"></div>
                    <div className="mavClauseProgress"><progress id="change7Progress" value="100" max="100" className="orange"></progress></div>
                </div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month Eight</div>
                    <div className="mavClauseStatus" id="change8Status">Not started</div>
                    <div className="mavClauseStart" id="change8Start"></div>
                    <div className="mavClauseEnd" id="change8End"></div>
                    <div className="mavClauseProgress"><progress id="change8Progress" value="100" max="100" className="orange"></progress></div>
                </div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month Nine</div>
                    <div className="mavClauseStatus" id="change9Status">Not started</div>
                    <div className="mavClauseStart" id="change9Start"></div>
                    <div className="mavClauseEnd" id="change9End"></div>
                    <div className="mavClauseProgress"><progress id="change9Progress" value="100" max="100" className="orange"></progress></div>
                </div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month Ten</div>
                    <div className="mavClauseStatus" id="change10Status">Not started</div>
                    <div className="mavClauseStart" id="change10Start"></div>
                    <div className="mavClauseEnd" id="change10End"></div>
                    <div className="mavClauseProgress"><progress id="change10Progress" value="100" max="100" className="orange"></progress></div>
                </div>

                <div className="mavProgress"><progress id="changeProgress" value="73" max="100" className="orange"></progress></div>
            </div>

            <div className="mavItem">
                <div className="mavItemTitle silver" tabIndex={0} role="button" aria-expanded="false">
                    <div>My Environment</div>
                    <div>
                        <progress id="environmentProgressMini" value="5" max="100" className="orange"></progress>
                        <span className="icon-down-open"></span></div>
                    </div>
                <div className="mavItemIntro silver-light">Plan and execute a project which positively affects the environment. Your project can be a local one - e.g. such as setting up a litter picking group, or a national or international one - e.g. fundraising for a charity.<br/><Link to="/silver-myenvironment">View evidence page</Link></div>
                <div className="mavClause">
                    <div className="mavClauseTask">Project</div>
                    <div className="mavClauseStatus" id="environment1Status">Not started</div>
                    <div className="mavClauseStart" id="environment1Start"></div>
                    <div className="mavClauseEnd" id="environment1End"></div>
                    <div className="mavClauseProgress"><progress id="environment1Progress" value="5" max="100" className="orange"></progress></div>
                </div>  
                <div className="mavProgress"><progress id="environmentProgress" value="5" max="100" className="orange"></progress></div>
            </div>

            <div className="mavItem">
                <div className="mavItemTitle silver" tabIndex={0} role="button" aria-expanded="false">
                    <div>My Fitness</div>
                    <div>
                        <progress id="fitnessProgressMini" value="2" max="100" className="orange"></progress>
                        <span className="icon-down-open"></span></div>
                    </div>
                <div className="mavItemIntro silver-light">Over a period of at least six months, work towards a personal fitness goal. Choose the goal specific to your body and your capabilities. The goal should be specific, measurable and achievable in 6-12 months. Complete the goal.<br/><Link to="/silver-myfitness">View evidence page</Link></div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month One</div>
                    <div className="mavClauseStatus" id="fitness1Status">Not started</div>
                    <div className="mavClauseStart" id="fitness1Start"></div>
                    <div className="mavClauseEnd" id="fitness1End"></div>
                    <div className="mavClauseProgress"><progress id="fitness1Progress" value="2" max="100" className="orange"></progress></div>
                </div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month Two</div>
                    <div className="mavClauseStatus" id="fitness2Status">Not started</div>
                    <div className="mavClauseStart" id="fitness2Start"></div>
                    <div className="mavClauseEnd" id="fitness2End"></div>
                    <div className="mavClauseProgress"><progress id="fitness2Progress" value="2" max="100" className="orange"></progress></div>
                </div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month Three</div>
                    <div className="mavClauseStatus" id="fitness3Status">Not started</div>
                    <div className="mavClauseStart" id="fitness3Start"></div>
                    <div className="mavClauseEnd" id="fitness3End"></div>
                    <div className="mavClauseProgress"><progress id="fitness3Progress" value="2" max="100" className="orange"></progress></div>
                </div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month Four</div>
                    <div className="mavClauseStatus" id="fitness4Status">Not started</div>
                    <div className="mavClauseStart" id="fitness4Start"></div>
                    <div className="mavClauseEnd" id="fitness4End"></div>
                    <div className="mavClauseProgress"><progress id="fitness4Progress" value="2" max="100" className="orange"></progress></div>
                </div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month Five</div>
                    <div className="mavClauseStatus" id="fitness5Status">Not started</div>
                    <div className="mavClauseStart" id="fitness5Start"></div>
                    <div className="mavClauseEnd" id="fitness5End"></div>
                    <div className="mavClauseProgress"><progress id="fitness5Progress" value="2" max="100" className="orange"></progress></div>
                </div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month Six</div>
                    <div className="mavClauseStatus" id="fitness6Status">Not started</div>
                    <div className="mavClauseStart" id="fitness6Start"></div>
                    <div className="mavClauseEnd" id="fitness6End"></div>
                    <div className="mavClauseProgress"><progress id="fitness6Progress" value="2" max="100" className="orange"></progress></div>
                </div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month Seven</div>
                    <div className="mavClauseStatus" id="fitness7Status">Not started</div>
                    <div className="mavClauseStart" id="fitness7Start"></div>
                    <div className="mavClauseEnd" id="fitness7End"></div>
                    <div className="mavClauseProgress"><progress id="fitness7Progress" value="2" max="100" className="orange"></progress></div>
                </div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month Eight</div>
                    <div className="mavClauseStatus" id="fitness8Status">Not started</div>
                    <div className="mavClauseStart" id="fitness8Start"></div>
                    <div className="mavClauseEnd" id="fitness8End"></div>
                    <div className="mavClauseProgress"><progress id="fitness8Progress" value="2" max="100" className="orange"></progress></div>
                </div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month Nine</div>
                    <div className="mavClauseStatus" id="fitness9Status">Not started</div>
                    <div className="mavClauseStart" id="fitness9Start"></div>
                    <div className="mavClauseEnd" id="fitness9End"></div>
                    <div className="mavClauseProgress"><progress id="fitness9Progress" value="2" max="100" className="orange"></progress></div>
                </div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month Ten</div>
                    <div className="mavClauseStatus" id="fitness10Status">Not started</div>
                    <div className="mavClauseStart" id="fitness10Start"></div>
                    <div className="mavClauseEnd" id="fitness10End"></div>
                    <div className="mavClauseProgress"><progress id="fitness10Progress" value="2" max="100" className="orange"></progress></div>
                </div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month Eleven</div>
                    <div className="mavClauseStatus" id="fitness11Status">Not started</div>
                    <div className="mavClauseStart" id="fitness11Start"></div>
                    <div className="mavClauseEnd" id="fitness11End"></div>
                    <div className="mavClauseProgress"><progress id="fitness11Progress" value="2" max="100" className="orange"></progress></div>
                </div>
                <div className="mavClause">
                    <div className="mavClauseTask">Month Twelve</div>
                    <div className="mavClauseStatus" id="fitness12Status">Not started</div>
                    <div className="mavClauseStart" id="fitness12Start"></div>
                    <div className="mavClauseEnd" id="fitness12End"></div>
                    <div className="mavClauseProgress"><progress id="fitness12Progress" value="2" max="100" className="orange"></progress></div>
                </div>
                
                <div className="mavProgress"><progress id="fitnessProgress" value="5" max="100" className="orange"></progress></div>
            </div>

            <div className="mavItem">
                <div className="mavItemTitle silver" tabIndex={0} role="button" aria-expanded="false">
                    <div>My Outdoors</div>
                    <div>
                        <progress id="outdoorsProgressMini" value="100" max="100" className="green"></progress>
                        <span className="icon-down-open"></span></div>
                    </div>
                <div className="mavItemIntro silver-light">Plan and execute an adventure which involves at least three consecutive nights in the outdoors. You can either camp or glamp (e.g. in a hut, permanent tent, caravan, camper van or treehouse). Take part in at least three outdoor activities during the adventure which challenge you. These might be adventurous activities, or ones which involve pure enjoyment of the outdoors, such as birdwatching or stargazing.<br/><Link to="/silver-myoutdoors">View evidence page</Link></div>
                <div className="mavClause">
                    <div className="mavClauseTask">My outdoors</div>
                    <div className="mavClauseStatus" id="outdoors1Status">Not started</div>
                    <div className="mavClauseStart" id="outdoors1Start"></div>
                    <div className="mavClauseEnd" id="outdoors1End"></div>
                    <div className="mavClauseProgress"><progress id="outdoors1Progress" value="100" max="100" className="green"></progress></div>
                </div>
                <div className="mavProgress"><progress id="outdoorsProgress" value="100" max="100" className="green"></progress></div>
            </div>

            <div className="mavItem">
                <div className="mavItemTitle silver" tabIndex={0} role="button" aria-expanded="false">
                    <div>My Buddy</div>
                    <div>
                        <progress id="buddyProgressMini" value="5" max="100" className="silver"></progress>
                        <span className="icon-down-open"></span></div>
                    </div>
                <div className="mavItemIntro silver-light">Pair up with at least one fellow rebel and plan and execute either an expedition, an exploration of a country or region you have never been to before, or a project with a positive impact for others. Prepare a presentation about your group activity.  The buddy does not need to be completing their Maverick Award, however they do need to be an active Rebel. You could work with a Rebel in another country over Zoom, or recruit a friend or family member to the Rebel tribe.<br/><Link to="/silver-mybuddy">View evidence page</Link></div>
                <div className="mavClause">
                    <div className="mavClauseTask">My buddy</div>
                    <div className="mavClauseStatus" id="buddy1Status">Not started</div>
                    <div className="mavClauseStart" id="buddy1Start"></div>
                    <div className="mavClauseEnd" id="buddy1End"></div>
                    <div className="mavClauseProgress"><progress id="buddy1Progress" value="5" max="100" className="orange"></progress></div>
                </div>
                <div className="mavProgress"><progress id="buddyProgress" className="orange" value="5" max="100"></progress></div>
            </div>
        </div>
    )
}

function listenFunc(element)
{
    var elementIntro = element.parentElement.querySelector(".mavItemIntro");
    var elementClause = element.parentElement.querySelectorAll(".mavClause");
    var elementProgress = element.parentElement.querySelector(".mavProgress");

    if(element.getAttribute("aria-expanded") === "false")
    {
        element.setAttribute("aria-expanded", "true");
    }
    else
    {
        element.setAttribute("aria-expanded", "false");
    }

    if(elementIntro.classList.contains("hidden"))
    {
        elementIntro.classList.remove("hidden");
        elementIntro.classList.add("shown");
    } else {
        elementIntro.classList.remove("shown");
        elementIntro.classList.add("hidden");
    }
    
    elementClause.forEach((ec) => {
        if(ec.classList.contains("hidden"))
        {
            ec.classList.remove("hidden");
            ec.classList.add("shown");
        } else {
            ec.classList.remove("shown");
            ec.classList.add("hidden");
        }
    })

    if(elementProgress.classList.contains("hidden"))
    {
        elementProgress.classList.remove("hidden");
        elementProgress.classList.add("shown");
    } else {
        elementProgress.classList.remove("shown");
        elementProgress.classList.add("hidden");
    }
}

function addListener(element)
{
    element.addEventListener("click",function(){
        listenFunc(element);
    })

    element.addEventListener("keydown", function(e){
        if (e.key !== undefined){
            if(e.key === "Enter" || e.key === " " || e.key === "ArrowDown" || e.key === "ArrowUp" || e.key === "ArrowLeft" || e.key === "ArrowRight")
            {
                listenFunc(element);
            }
        } else if (e.which !== undefined) {
            if(e.key === 13 || e.key === 32 || e.key === 40 || e.key === 38 || e.key ===37 || e.key === 39)
            {
                listenFunc(element);
            }
        }
    })
}

export default Silver