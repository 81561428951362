import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const Critic = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Critic"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">A play</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">An Exhibition</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">A Musical</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">A Comedy Show</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">A Music Album</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div>
            
        </div>
    )
}

export default Critic