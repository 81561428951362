import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Crisps1 from '../images/AppleCrisps1.jpg';
import Crisps2 from '../images/AppleCrisps2.jpg';
import Crisps3 from '../images/AppleCrisps3.jpg';
import Crisps4 from '../images/AppleCrisps4.jpg';
import Carving1 from '../images/Carving1.jpg';
import Carving2 from '../images/Carving2.jpg';
import Carving3 from '../images/Carving3.jpg';
import Carving4 from '../images/Carving4.jpg';
import Carving5 from '../images/Carving5.jpg';
import Carving6 from '../images/Carving6.jpg';
import Hummus1 from '../images/Halloween23-Hummus1.jpg';
import Hummus2 from '../images/Halloween23-Hummus2.jpg';
import Hummus3 from '../images/Halloween23-Hummus3.jpg';
import Hummus4 from '../images/Halloween23-Hummus4.jpg';
import Hummus5 from '../images/Halloween23-Hummus5.jpg';
import Hummus6 from '../images/Halloween23-Hummus6.jpg';
import Leaves1 from '../images/halloween23-leaves1.jpg';
import Leaves2 from '../images/halloween23-leaves2.jpg';
import Wreath1 from '../images/halloween23-wreath1.jpg';
import Wreath2 from '../images/halloween23-wreath2.jpg';



const Halloween23 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Halloween 2023"} />
            <p><strong>This page provides my evidence for the Halloween 2023 monthly badge.  The information for this badge is available publicly on the <a className="linkUnderline" href="https://www.rebelbadgestore.com/rebelblog/october-2023-the-halloween-challenge-badge" target="_blank" rel="noopener noreferrer">Rebel Badge Store Noticeboard</a> so descriptions haven't been removed.</strong></p>
            
            <div className="badgePageItem">
                <div className="badgePageItemTitle orange">Find out about a local legend or ghost story</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo orange-light"></div>
                    <div className="badgePageItemEvidence">
                       <p>A few years ago, I did the ghost tour of Bournemouth Town Hall! I found it really interesting and we visited places I hadn't been in previously. It's quite an amazing building and has previously been a hotel and a wartime hospital, so you can imagine the potential for ghostly goings on!</p>
                       <p>The basement is home to a soldier who drinks from a sink and three young boys who died in a fire when they were locked in. It is said that you can at times smell the smoke and hear screams. </p>
                       <p>There is a headless Maharaja who haunts the 4th floor, in his indian uniform.</p>
                       <p>You can also see horses and a carriage from the old hotel days as well as a resident ghost cat.</p>
                       <p>There is a lady who haunts the car park - it used to be baths on that site and sometimes you can see her from the parking floors.</p>
                    </div>
                    <br/>
                    <p>A story that I wasn't aware of, closer to home, is the ghost of a phantom horseman riding at Hengistbury Head, wearing a 17th Century style flared jacket.</p>
                    <p>There is also apparently a story saying that a ghostly woman can be seen throwing herself off the cliff.</p>
                    <br/>
                    <p>Christchurch has a lot of haunted hotspots, including the Priory, where the ghost of John Draper - Prior in 1552 - can be seen at the entrance of Draper Chapel. Other people have seen monks and smelled incense, which hasn't been used in the Priory for many years.</p>
                    <br/>
                </div>
            </div>
             <div className="badgePageItem">
                <div className="badgePageItemTitle orange">Learn about the Teal Pumpkin Project</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo orange-light"></div>
                    <div className="badgePageItemEvidence">
                       <p>Many children live with allergies and intolerances to food, making it harder for them to enjoy trick or treating and other seasonal events. Placing a Teal pumpkin at your door shows that you have non-food treats available for children who can't have the usual candy products.</p>
                       <p>The project was started by the Food Allergy Community of East Tennessee (FACET) and continues to grow around the world.</p>
                       <p>The <a href="https://www.foodallergy.org/our-initiatives/awareness-campaigns/living-teal/teal-pumpkin-project">Food Allergy website has some brilliant information and resources around this.</a></p>
                       <p>As someone subject to food allergies, I appreciate this project!</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle orange">Read about the campaign to remove visible differences from halloween costumes</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo orange-light"></div>
                    <div className="badgePageItemEvidence">
                    <p>I have read the following resources for this clause:</p>
                    <br/>
                    <p>https://www.insider.com/women-with-facial-difference-want-halloweeners-not-to-imitate-them-2022-10</p>
                       <p>https://carlyfindlay.com.au/2013/10/27/appearance-diversity-scary-face-at-halloween/</p>
                       <p>https://www.yahoo.com/lifestyle/disability-not-a-costume-offensive-halloween-costumes-204019592.html</p>
                       <p>https://faceequalityinternational.org/2021/10/why-halloween-hell/</p>
                       <p>https://www.changingfaces.org.uk/about-visible-difference/</p>
                       <p>https://faceequalityinternational.org/2021/10/why-is-halloween-difficult-for-the-facial-difference-community/</p>
                       <p>https://healthunlocked.com/changingfaces/posts/141936419/halloween-dressing-up-as-a-character.-not-a-condition.</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle orange">Make Apple or Vegetable crisps</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo orange-light"></div>
                    <div className="badgePageItemEvidence">
                       <p>One portion of apple crisps!! I have to say I quite enjoyed these, a bit too sweet though!</p>
                       <div className='gallery'>
                        <div className='galleryItem'><img src={Crisps1} alt="An apple with the top and bottom sliced off"/></div>
                        <div className='galleryItem'><img src={Crisps2} alt="Thin apple slices on a baking sheet ready for baking"/></div>
                        <div className='galleryItem'><img src={Crisps3} alt="Thin apple slices on a baking sheet, crispy after baking"/></div>
                        <div className='galleryItem'><img src={Crisps4} alt="Apple crisps in a small bowl, ready for eating"/></div>
                       </div>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle orange">Carve Something which isn't a pumpkin</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo orange-light"></div>
                    <div className="badgePageItemEvidence">
                       <p>OK, I went for a watermelon, because I liked the idea of the red insides!!</p>
                       <p>Pattern was inspired by an image I saw on google.</p>
                       <br/>
                       <div className='gallery'>
                        <div className='galleryItem'><img src={Carving1} alt="A watermelon with a face drawn on with marker" /></div>
                        <div className='galleryItem'><img src={Carving2} alt="A watermelon with eyes cut out and a mouth drawn on with marker" /></div>
                        <div className='galleryItem'><img src={Carving3} alt="A watermelon with a face carving started" /></div>
                        <div className='galleryItem'><img src={Carving4} alt="A watermelon with eyes and some teeth carved out" /></div>
                        <div className='galleryItem'><img src={Carving5} alt="A watermelon with a face carved into it" /></div>
                        <div className='galleryItem'><img src={Carving6} alt="A watermelon with a halloween scary face carved into it" /></div>
                       </div>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle orange">Make an Autumn-Themed wreath</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo orange-light"></div>
                    <div className="badgePageItemEvidence">
                       <p>I decided to use the leaves I had collected for another clause tp make my wreath - along with some conkers, conker casings and moss. I foud a lovely idea with that added faces to conkers for a Halloween feel from this link:</p>
                       <p>https://incredibusy.com/how-to-make-a-halloween-leaf-wreath/</p>
                       <br/>
                       <p>I realise this is only a temporary wreath, the leaves won't last and so I haven't assembled it fully, but I enjoyed putting it together. Here it is both with and without the conkers!</p>
                       <br/>
                       <div className='gallery'>
                        <div className='galleryItem'><img src={Wreath1} alt="An autumn wreath made with leaves and with skeleton faces painted on conkers laid out on a table" /></div>
                        <div className='galleryItem'><img src={Wreath2} alt="An autumn wreath made with leaves laid out on a table" /></div>
                       </div>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle orange">See how many visibly different coloured leaves you can find</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo orange-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>On my way back from a walk, I stopped and picked up some beautiful leaves with lovely reds and greens. I left them outside to dry off, then forgot to bring them in, then the storm hit, I lost some and the others subsequently dried out and went brown ... a bit like this:</p>
                        <img src={Leaves1} alt="Dried out leaves" className='galleryItem'/>
                        <br/><p>So, off I went on a leaf finding mission! I seemed to have missed all the lovely reds and oranges so my collection was looking very yellow and green but then I spotted some red glinting at me from across the road! I found some lovely reds and oranges to join the collection, so here's what the pile looks like now:</p>
                        <img src={Leaves2} alt="Dried out leaves" className='galleryItem'/>
                        <p>Quite a few shades there and slightly more attractive!!</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle orange">Make something with pumpkin you've never made before</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo orange-light"></div>
                    <div className="badgePageItemEvidence">
                       <p>Well, I've already done Pumpkin Pie, Pumpkin Soup and Pumpkin Cake so this year we're going with ..... Pumpkin Hummus!!</p>
                       <p>It's actually quite tasty. I halved the recipe as the pumpkin was just a little munchkin one (thank you Tesco!), I didn't adjust the baking time for my tiny amount of pumpkin flesh, so let's call it caramelised pumpkin!!</p>
                       <br/><p>Recipe: Pumpkin Hummus from BBC Good Food, https://www.bbcgoodfood.com/recipes/pumpkin-houmous</p>
                       <br/>
                       <div className="gallery">
                        <div className='galleryItem'><img src={Hummus1} alt="A munchkin pumpkin" /></div>
                        <div className='galleryItem'><img src={Hummus2} alt="A munchkin pumpkin with the top sliced off to reveal the contents" /></div>
                        <div className='galleryItem'><img src={Hummus3} alt="Munchkin pumpkin flesh on a baking tray with garlic and oil" /></div>
                        <div className='galleryItem'><img src={Hummus4} alt="Baked pumpkin flesh ready for hummus making" /></div>
                        <div className='galleryItem'><img src={Hummus5} alt="Hummus ingredients in a blender" /></div>
                        <div className='galleryItem'><img src={Hummus6} alt="Pumpkin Hummus being served inside a munchkin Pumpkin" /></div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Halloween23