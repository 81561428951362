import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./pages/Home";
import Book1 from "./pages/book1";
import Book2 from "./pages/book2";
import Bronze from "./pages/bronze";
import Silver from "./pages/silver";
import Activist from "./pagesBook1/activist";
import Artist from "./pagesBook1/artist";
import Adulting from "./pagesBook1/adulting";
import Apothecary from "./pagesBook1/apothecary";
import Baker from "./pagesBook1/baker";
import Chef from "./pagesBook1/chef";
import CodeBreaker from "./pagesBook1/codebreaker";
import CommunityService from "./pagesBook1/communityservice";
import ConsciousConsumer from "./pagesBook1/consciousconsumer";
import Craft from "./pagesBook1/craft";
import Critic from "./pagesBook1/critic";
import Designer from "./pagesBook1/designer";
import Diarist from "./pagesBook1/diarist";
import DIY from "./pagesBook1/diy";
import EmergencyHelper from "./pagesBook1/emergencyhelper";
import Entrepreneur from "./pagesBook1/entrepreneur";
import Environmentalist from "./pagesBook1/environmentalist";
import Fitness from "./pagesBook1/fitness";
import Florist from "./pagesBook1/florist";
import Fundraiser from "./pagesBook1/fundraiser";
import Gardener from "./pagesBook1/gardener";
import GoodHabits from "./pagesBook1/goodhabits";
import IndoorGardener from "./pagesBook1/indoorgardener";
import InteriorDesigner from "./pagesBook1/interiordesigner";
import Investor from "./pagesBook1/investor";
import Mindfulness from "./pagesBook1/mindfulness";
import MoneySaver from "./pagesBook1/moneysaver";
import Musician from "./pagesBook1/musician";
import MyBrand from "./pagesBook1/mybrand";
import MyGoals from "./pagesBook1/mygoals";
import MyRoots from "./pagesBook1/myroots";
import MyStyle from "./pagesBook1/mystyle";
import MyTalents from "./pagesBook1/mytalents";
import Photographer from "./pagesBook1/photographer";
import Reader from "./pagesBook1/reader";
import SelfCare from "./pagesBook1/selfcare";
import Stargazer from "./pagesBook1/stargazer";
import WorldTraveller from "./pagesBook1/worldtraveller";
import Writer from "./pagesBook1/writer";
import MyAdventure from "./pagesBronze/myadventure";
import MyBeliefs from "./pagesBronze/mybeliefs";
import MyChallenge from "./pagesBronze/mychallenge";
import MyCommunity from "./pagesBronze/mycommunity";
import MySkills from "./pagesBronze/myskills";
import Halloween22 from "./pagesExtras/halloween22";
import Write22 from "./pagesExtras/write22";
import Christmas22 from "./pagesExtras/christmas22";
import Extras from "./pages/extras";
import Nelson from "./pagesExtras/nelson";
import AdvancedAdulting from './pagesBook2/advancedadulting';
import AdvancedCommunity from './pagesBook2/advancedcommunity';
import AdvancedCrafter from './pagesBook2/advancedcrafter';
import AdvancedReader from './pagesBook2/advancedreader';
import AdvancedSelfCare from './pagesBook2/advancedselfcare';
import AdvancedWriter from './pagesBook2/advancedwriter';
import BrainGames from './pagesBook2/braingames';
import Builder from './pagesBook2/builder';
import Coder from './pagesBook2/coder';
import Confectioner from './pagesBook2/confectioner';
import CraftPart2 from './pagesBook2/craftpart2';
import Detective from './pagesBook2/detective';
import Farmer from './pagesBook2/farmer';
import GlobalCitizen from './pagesBook2/globalcitizen';
import Inventor from './pagesBook2/inventor';
import Journalist from './pagesBook2/journalist';
import Mixologist from './pagesBook2/mixologist';
import MusicLover from './pagesBook2/musiclover';
import Collection from './pagesBook2/mycollection';
import Passion from './pagesBook2/mypassion';
import Powers from './pagesBook2/mypowers';
import Story from './pagesBook2/mystory';
import Studies from './pagesBook2/mystudies';
import NatureLover from './pagesBook2/naturelover';
import Observer from './pagesBook2/observer';
import Paleo from './pagesBook2/palaeontologist';
import Politician from './pagesBook2/politician';
import RebelHistory from './pagesBook2/rebelhistory';
import Scientist from './pagesBook2/scientist';
import Sommelier from './pagesBook2/sommelier';
import ExerciseJan23 from './pagesExtras/exercisejan23.js';
import LoveFeb23 from './pagesExtras/lovefeb23.js';
import Happy2023 from './pagesExtras/happy2023.js';
import SoupMar23 from './pagesExtras/soupmar23.js';
import HeroMar23 from './pagesExtras/heromar23.js';
import ChocApr23 from './pagesExtras/chocapr23.js';
import LifeLessOrdinary23 from './pagesExtras/lifelessordinary23.js';
import CupApr23 from './pagesExtras/cupapr23.js';
import CupMay23 from './pagesExtras/cupmay23.js';
import CauseMay23 from './pagesExtras/causemay23.js';
import HealthCheckJun23 from './pagesExtras/healthcheckjun23.js';
import CupJul23 from './pagesExtras/cupjul23.js';
import GoWild23 from './pagesExtras/gowild23.js';
import BigKid23 from './pagesExtras/bigkidjul23.js';
import Scavenger23 from './pagesExtras/scavengeraug23.js';
import Scrapheap23 from './pagesExtras/scrapheapsep23.js';
import Halloween23 from './pagesExtras/halloween23.js';
import CupOct23 from './pagesExtras/cupoct23.js';
import ScienceFair23 from './pagesExtras/sciencefair23.js';
import SilverMyBuddy from './pagesSilver/silver-mybuddy.js';
import SilverMyChange from './pagesSilver/silver-mychange.js';
import SilverMyEnvironment from './pagesSilver/silver-myenvironment.js';
import SilverMyFitness from './pagesSilver/silver-myfitness.js';
import SilverMyOutdoors from './pagesSilver/silver-myoutdoors.js';
import Xmas23 from './pagesExtras/christmas23.js';
import CupNov23 from "./pagesExtras/cupnov23.js";
import Roundup23 from "./pagesAnnual/roundup2023.js";
import Adventure24 from "./pagesAnnual/bigadventure2024.js";
import Books24 from "./pagesAnnual/52books2024.js";
import Outdoors24 from "./pagesAnnual/outdoors2024.js";
import RebelCause24 from "./pagesAnnual/rebelcause2024.js";
import FirstBirthday from "./pagesQuarterly/firstbirthday.js";
import SecondBirthday from "./pagesQuarterly/secondbirthday.js";
import Kickstart24 from "./pagesQuarterly/kickstart24.js";
import January24 from "./pagesMonthly/january24.js";
import CupJan24 from "./pagesExtras/cupjan24.js";
import CupGen24 from "./pagesExtras/cupgen24.js";
import Walker from "./pagesBook2/walker.js";
import CupFeb24 from "./pagesExtras/cupfeb24.js";
import February24 from "./pagesMonthly/february24.js";
import Historian from "./pagesBook2/historian.js";
import CupMar24 from './pagesExtras/cupmar24.js';
import March24 from "./pagesMonthly/march24.js";
import April24 from './pagesMonthly/april24.js';
import Deeds24 from './pagesQuarterly/deedsnotwords24.js';
import TVCritic from './pagesBook2/tvcritic.js';
import Buddy from './pagesBook2/rebelbuddy.js';
import CupApr24 from './pagesExtras/cupapr24.js';
import May24 from './pagesMonthly/may24.js';
import June24 from './pagesMonthly/june24.js';
import CupMay24 from './pagesExtras/cupmay24.js';
import CupJul24 from './pagesExtras/cupjul24.js';
import Linguist from "./pagesBook1/linguist.js";
import July24 from "./pagesMonthly/july24.js";
import Brave from './pagesQuarterly/bebrave24.js';
import HealthCheck24 from './pagesAnnual/healthcheck2024.js';
import August24 from './pagesMonthly/august24.js';
import CupAug24 from "./pagesExtras/cupaug24.js";
import September24 from "./pagesMonthly/september24.js";
import October24 from "./pagesMonthly/october24.js";
import ThirdBirthday24 from "./pagesQuarterly/thirdbirthday.js";
import November24 from "./pagesMonthly/november24.js";

import "./App.css";

function App() {

  return (
      <div className="App">
      <BrowserRouter basename={'/rebel'}>
          <Header />
          <main>
            <div className="appMain">
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/bookone" element={<Book1 />} />
                <Route path="/booktwo" element={<Book2 />} />
                <Route path="/bronze" element={<Bronze />} />
                <Route path="/silver" element={<Silver />} />
                <Route path="/activist" element={<Activist />}/>
                <Route path="/artist" element={<Artist />}/>
                <Route path="/adulting" element={<Adulting />}/>
                <Route path="/apothecary" element={<Apothecary />}/>
                <Route path="/baker" element={<Baker />}/>
                <Route path="/chef" element={<Chef />}/>
                <Route path="/codebreaker" element={<CodeBreaker />}/>
                <Route path="/communityservice" element={<CommunityService />}/>
                <Route path="/consciousconsumer" element={<ConsciousConsumer />}/>
                <Route path="/craft" element={<Craft />}/>
                <Route path="/critic" element={<Critic />}/>
                <Route path="/designer" element={<Designer />}/>
                <Route path="/diarist" element={<Diarist />}/>
                <Route path="/diy" element={<DIY />}/>
                <Route path="/emergencyhelper" element={<EmergencyHelper />}/>
                <Route path="/entrepreneur" element={<Entrepreneur />}/>
                <Route path="/environmentalist" element={<Environmentalist />}/>
                <Route path="/fitness" element={<Fitness />}/>
                <Route path="/florist" element={<Florist />}/>
                <Route path="/fundraiser" element={<Fundraiser />}/>
                <Route path="/gardener" element={<Gardener />}/>
                <Route path="/goodhabits" element={<GoodHabits />}/>
                <Route path="/indoorgardener" element={<IndoorGardener />}/>
                <Route path="/interiordesigner" element={<InteriorDesigner />}/>
                <Route path="/investor" element={<Investor />}/>
                <Route path="/mindfulness" element={<Mindfulness />}/>
                <Route path="/moneysaver" element={<MoneySaver />}/>
                <Route path="/musician" element={<Musician />}/>
                <Route path="/mybrand" element={<MyBrand />}/>
                <Route path="/mygoals" element={<MyGoals />}/>
                <Route path="/myroots" element={<MyRoots />}/>
                <Route path="/mystyle" element={<MyStyle />}/>
                <Route path="/mytalents" element={<MyTalents />}/>
                <Route path="/photographer" element={<Photographer />}/>
                <Route path="/reader" element={<Reader />}/>
                <Route path="/selfcare" element={<SelfCare />}/>
                <Route path="/stargazer" element={<Stargazer />}/>
                <Route path="/worldtraveller" element={<WorldTraveller />}/>
                <Route path="/writer" element={<Writer />}/> 
                <Route path="/myadventure" element={<MyAdventure />}/>    
                <Route path="/mybeliefs" element={<MyBeliefs />}/> 
                <Route path="/mychallenge" element={<MyChallenge />}/>  
                <Route path="/mycommunity" element={<MyCommunity />}/>  
                <Route path="/myskills" element={<MySkills />}/> 
                <Route path="/halloween22" element={<Halloween22 />}/>     
                <Route path="/write22" element={<Write22 />}/>    
                <Route path="/christmas22" element={<Christmas22 />}/>
                <Route path="/extras" element={<Extras />}/>  
                <Route path="/nelson" element={<Nelson />}/>  
                <Route path="/advancedadulting" element={<AdvancedAdulting />}/> 
                <Route path="/advancedcommunity" element={<AdvancedCommunity />}/> 
                <Route path="/advancedcrafter" element={<AdvancedCrafter />}/>
                <Route path="/advancedreader" element={<AdvancedReader />}/>
                <Route path="/advancedselfcare" element={<AdvancedSelfCare />}/> 
                <Route path="/advancedwriter" element={<AdvancedWriter />}/>
                <Route path="/braingames" element={<BrainGames />}/>    
                <Route path="/builder" element={<Builder/>}/> 
                <Route path="/coder" element={<Coder />}/>          
                <Route path="/confectioner" element={<Confectioner />}/>
                <Route path="/craftpart2" element={<CraftPart2 />}/>   
                <Route path="/detective" element={<Detective />}/>
                <Route path="/farmer" element={<Farmer />}/>          
                <Route path="/globalcitizen" element={<GlobalCitizen />}/>
                <Route path="/inventor" element={<Inventor />}/>  
                <Route path="/journalist" element={<Journalist />}/>            
                <Route path="/mixologist" element={<Mixologist />}/>   
                <Route path="/musiclover" element={<MusicLover />}/>   
                <Route path="/mycollection" element={<Collection />}/>  
                <Route path="/mypassion" element={<Passion />}/>
                <Route path="/mypowers" element={<Powers />}/>       
                <Route path="/mystory" element={<Story />}/> 
                <Route path="/mystudies" element={<Studies />}/>   
                <Route path="/naturelover" element={<NatureLover/>}/> 
                <Route path="/observer" element={<Observer />}/>   
                <Route path="/palaeontologist" element={<Paleo />}/>  
                <Route path="/politician" element={<Politician />}/>  
                <Route path="/rebelhistory" element={<RebelHistory />}/>
                <Route path="/scientist" element={<Scientist />}/>   
                <Route path="/sommelier" element={<Sommelier />}/>   
                <Route path="/exercisejan23" element={<ExerciseJan23 />}/>
                <Route path="/lovefeb23" element={<LoveFeb23/>}/>
                <Route path="/happy2023" element={<Happy2023/>}/>
                <Route path="/soupmar23" element={<SoupMar23/>}/>
                <Route path="/Heromar23" element={<HeroMar23/>}/>
                <Route path="/Chocapr23" element={<ChocApr23/>}/>
                <Route path="/lifelessordinary23" element={<LifeLessOrdinary23/>}/>
                <Route path="/CupApr23" element={<CupApr23/>}/>
                <Route path="/CupMay23" element={<CupMay23/>}/>
                <Route path="/CauseMay23" element={<CauseMay23/>}/>
                <Route path="/HealthCheckJun23" element={<HealthCheckJun23/>}/>
                <Route path="/CupJul23" element={<CupJul23/>}/>
                <Route path="/GoWild23" element={<GoWild23/>}/>
                <Route path="/BigKidJul23" element={<BigKid23/>}/>
                <Route path="/ScavengerAug23" element={<Scavenger23/>}/>
                <Route path="/ScrapheapSep23" element={<Scrapheap23/>}/>
                <Route path="/Halloween23" element={<Halloween23/>}/>
                <Route path="/CupOct23" element={<CupOct23/>}/>
                <Route path="/ScienceFair23" element={<ScienceFair23/>}/>
                <Route path="/silver-mybuddy" element={<SilverMyBuddy/>}/>
                <Route path="/silver-mychange" element={<SilverMyChange/>}/>
                <Route path="/silver-myenvironment" element={<SilverMyEnvironment/>}/>
                <Route path="/silver-myfitness" element={<SilverMyFitness/>}/>
                <Route path="/silver-myoutdoors" element={<SilverMyOutdoors/>}/>
                <Route path="/christmas23" element={<Xmas23/>}/>
                <Route path="/cupnov23" element={<CupNov23/>}/>
                <Route path="/roundup2023" element={<Roundup23/>}/>
                <Route path="/bigadventure2024" element={<Adventure24/>}/>
                <Route path="/52books2024" element={<Books24/>}/>
                <Route path="/outdoors2024" element={<Outdoors24/>}/>
                <Route path="/rebelcause2024" element={<RebelCause24/>}/>
                <Route path="/firstbirthday" element={<FirstBirthday/>}/>
                <Route path="/secondbirthday" element={<SecondBirthday/>}/>
                <Route path="/kickstart24" element={<Kickstart24/>}/>
                <Route path="/january2024" element={<January24/>}/>
                <Route path="/cupjan24" element={<CupJan24/>}/>
                <Route path="/cupgen24" element={<CupGen24/>}/>
                <Route path="/walker" element={<Walker/>} />
                <Route path="/cupFeb24" element={<CupFeb24/>}/>
                <Route path="/february24" element={<February24/>}/>
                <Route path="/rebelhistorian" element={<Historian/>}/>
                <Route path="/cupMar24" element={<CupMar24/>}/>
                <Route path="/march24" element={<March24/>}/>
                <Route path="/april24" element={<April24/>}/>
                <Route path="/deedsnotwords24" element={<Deeds24/>}/>
                <Route path="/tvcritic" element={<TVCritic/>}/>
                <Route path="/rebelbuddy" element={<Buddy/>}/>
                <Route path="/cupApr24" element={<CupApr24/>}/>
                <Route path="/may24" element={<May24/>}/>
                <Route path="/june24" element={<June24/>}/>
                <Route path="/cupMay24" element={<CupMay24/>}/>
                <Route path="/cupJul24" element={<CupJul24/>}/>
                <Route path="/linguist" element={<Linguist/>}/>
                <Route path="/july24" element={<July24/>}/>
                <Route path="/bebrave24" element={<Brave/>}/>
                <Route path="/healthcheck24" element={<HealthCheck24/>}/>
                <Route path="/cupAug24" element={<CupAug24/>}/>
                <Route path="/august24" element={<August24/>}/>
                <Route path="/september24" element={<September24/>}/>
                <Route path="/october24" element={<October24/>}/>
                <Route path="/thirdbirthday24" element={<ThirdBirthday24/>}/>
                <Route path="/november24" element={<November24/>}/>
              </Routes>
            </div>
          </main>
          <Footer />
      </BrowserRouter>
      </div>
  );
}

export default App;