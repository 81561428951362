import React from 'react';
import { Link } from 'react-router-dom';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Sommelier1 from '../images/sommelier1.jpg';
import Sommelier2 from '../images/sommelier2.jpg';
import Sommelier3 from '../images/sommelier3.png';
import Sommelier4 from '../images/sommelier4.jpg';

const Sommelier = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"Sommelier"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light"></div>
                    <div className="badgePageItemEvidence">
                        <div className='hidden'>Learn about the different types of glass, bottles and wine glasses</div>
                        <h3>Wine Bottles</h3>
                        <br/>
                        <p>There are many different types of wine bottles - this great infographic from winefolly.com illustrates this perfectly:</p>
                        <img className='galleryItem' src={Sommelier1} alt="Chart showing different styles of wine bottles" />
                        <p>Source and full image: https://winefolly.com/tips/types-of-wine-bottles/ </p>
                        <br/>
                        <p>The colour of the glass used for the bottle can vary depending on marketing needs, UV protection and traditional expectations.</p>
                        <p>The UV protection is the most important factor, as you don't want the wine to be affected by the light - lightstruck.</p>
                        <br/>
                        <p>There are five shades of glass that can be used:</p>
                        <ul className='list'>
                            <li>Flint / Clear glass - little to no UV protection, wines in this glass are intended for quick use</li>
                            <li>Champagne Green - vibrant, up to 63% UV protection</li>
                            <li>Antique Green - darker, provides UV protection from fading and oxidation. </li>
                            <li>Dead Leaf Green (light yellow) - some UV protection</li>
                            <li>Amber Brown - Filters 97-98% of light wavelengths, offers the best protection but not widely used outside of the Rhine region.</li>
                        </ul>
                        <br/><p>Source: https://gravitywinehouse.com/blog/wine-bottle-glass-color/</p>
                        <br/><br/>
                        <h3>Wine Glasses</h3>
                        <p>Wine glasses can either be Glass or Crystal and come in a huge array of styles! Crystal glasses are very delicate and easily breakable whereas glass glasses are cheaper and more durable.</p>
                        <br/><p>There is a brilliant chart on this page from webstaurantstore.com which shows all the different styles of glasses: <br/> https://www.webstaurantstore.com/guide/580/types-of-wine-glasses.html </p>
                    </div>
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light"></div>
                    <div className="badgePageItemEvidence">
                        <img className="galleryItem" src={Sommelier3} alt="Two bottles of wine with a decanter and glass on a table with trees in the background" />
                        <br />
                        <p>Old World wines - produced in European and Middle Eastern countries and are usually lighter-bodied.They can be lower in alcohol, have  brighter acidity and have more earthy flavours.</p>
                        <p>New World wines - produced in America, Oceania, China and India. They are more full bodied and are higher in alcohol, are lower in acidity and have bolder flavours.</p>
                        <br/><br/>
                        <p>Old world wines - take the name of the place they were made, have strict traditional regulations and have more acidity and tannins.</p>
                        <p>New World wines - take the name of the grape, processes are more experimental and are richer and bolder fruity flavours.</p>
                        <br/>
                        <p>This image showing the physical distribution is from vinepair.com</p>
                        <img className="galleryItem" src={Sommelier2} alt="Map showing where New World and Old World wines are produced" />
                        <br />
                        <p>Source: https://vinepair.com/wine-blog/understanding-difference-old-new-world-wines</p>
                        <p>Source: https://vervewine.com/blogs/the-blog/old-world-vs-new-world-everything-you-need-to-know</p>
                        <br />
                    </div>
                </div>
            </div>          
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light"></div>
                    <div className="badgePageItemEvidence">
                        <img className="galleryItem" src={Sommelier4} alt="A glass of sparkling wine with a bottle of rose and a bottle of sparkling wine on a bar" />
                        <h3>Rose Wines</h3>
                        <br/><p>Rose wines are made by crushing red/black grapes. This allows the juice from the grapes to macerate with their skins - a process that can take from 1 - 12 hours. The maceration process lets the colour, flavour and tannins to mix with the juice.</p>
                        <p>The colour of the wine depends on the length of the maceration process. Long maceration times make the wine fuller.</p>
                        <p>It can also be made by using both red and white grapes together.</p>
                        <br/>
                        <p>There are three different approaches to using the product after maceration:</p>
                        <ul className="list">
                            <li>Some places use the free-run juice</li>
                            <li>Some places use the free-run juice and the pressed juice, blending them later in the process</li>
                            <li>Some are created from the by-products of red wine making, with juice being taken from red wine fermentation tanks whilst it is still pale and then fermented</li>
                        </ul>
                        <br/>
                        <p>Source: https://www.falstaff.com/en/news/how-are-rose-wines-made</p>
                        <p>Source: https://www.virginwines.co.uk/hub/wine-guide/winemaking/how-is-rose-wine-made/</p>
                        <br/><br/>
                        <h3>Sparkling Wines</h3>
                        <br/>
                        <p>There are a number of ways that sparkling wines can be made, but these are the most common:</p>
                        <ul className='list'>
                            <li>Traditional Method - still wine is bottled, yeast and sugar added, aged in bottle</li>
                            <li>Transfer Method - hand-picked grapes, gently pressed, 2nd fermentation in bottle</li>
                            <li>Tank / Charmat Method - fermented 1st in stainless steel tanks then fermented in pressurized tanks</li>
                            <li>Asti Method - chilled and stored, fermentatin in pressurized tank when needed</li>
                            <li>Carbonation Method - CO2 injected into wine and then bottled under pressure</li>
                        </ul>
                        <br/><p>Source: https://napavalleywineacademy.com/the-5-ways-to-make-sparkling-wine/</p>
                        <br/><p>Some other methods are:</p>
                        <ul className="list">
                            <li>Ancestral method</li>
                            <li>Dioise Method - used for Clairette de Die AOC, used in the Drome valley and also used for Asti Spumante</li>
                            <li>Continuous Method - Also called the Russian method. Produced in Russia and former Soviet Uniot countries</li>
                            <li>Soda method - simpler and cheaper. Made by adding CO2 from a carbonator. EU wines must clearly label this as "aerated sparkling wine"</li>
                        </ul>
                        <br/><p>Source:https://en.wikipedia.org/wiki/Sparkling_wine_production</p>
                        <br/>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 4</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>These are some of the tool syou can use to enhance the flavour of your wine.</p><br/>
                        <p>Aeration - can help wine mature so it has a smoother, more balanced flavour. Pour wine into a decanter through an aeratoin funnel or uncork and leave to sit for at least an hour.</p>
                        <p>Chilling - can calm down unsavoury flavours. White wine is often chilled, red wine can be chilled for an our before serving.</p>
                        <p>Glasses - using the correct glass shapre can help bring our the flavours</p>
                        <p>Mixers - add lemon juice can brighten flavour, a pinch of sugar balances acidic wine, use wine in other mixed drinks such as sangria or spritzers</p>
                        <p>Mushrooms - enhance the taste of red wine!</p>
                        <p>Pennies - can help stop odours like rotten egg and struck matches</p>
                        <br/>
                        <p>Source: https://www.foodandwine.com/wine/7-ways-make-bad-wine-drinkable</p>
                        <p>Source: https://www.wikihow.com/Make-Wine-Taste-Better</p>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 5</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light"></div>
                    <div className="badgePageItemEvidence">
                        <div className='hidden'>Learn about the different types of wine flavours</div>
                        <p><h3>Common flavours found in wine:</h3></p>
                        <br/><p><strong>Primary flavours</strong> are grape flavours that include fruit, flower and herb aromas</p>
                        <p><strong>Secondary flavours</strong> are fermentation aromas and include cream, brea, mushroomn or butter</p>
                        <p><strong>Tertiary flavours</strong> are created by aging and oxidation and include vanilla, nuttiness, coffee and tobacco</p>
                        <br/>
                        <p><strong>Fruit flavours</strong> - red wines contain dark fruit flavours like cherry, strawberry, blueberry or black currant whilst white wines usually have citrus flavours like lemon, lime, orange or grapefruit or may use other fruit flavours such as apple, peach or mango.</p>
                        <p><strong>Floral flavours</strong> - elderflower, honeysuckle n=and violets are sometimes used</p>
                        <p><strong>Spices - cloves</strong> - cinnamon, vanilla and nutmeg are often used</p>
                        <p><strong>Toasty / Nutty</strong> - brioche, walnuts, coconut, coffee</p>
                        <p><strong>Other</strong> - cooked meats, game, vegetables, petrol, tobacco, waxy or stoney flavours</p>
                        <br/>
                        <p>Wine Folly have an amazing list of all the different kinds of grapes and their flavours, far too many to list here.<br/>Link: https://winefolly.com/grapes/</p>
                        <br/>
                        <p>Source: https://www.mcclaincellars.com/fruit-flavors-found-in-common-types-of-wine/</p>
                        <p>Source: https://www.bbcgoodfood.com/howto/guide/how-to-taste-wine</p>
                        <p>Source: https://winefolly.com/tips/introducing-the-wine-flavor-chart/</p>
                        <p>Source: https://www.masterclass.com/articles/a-complete-guide-to-13-types-of-wine</p>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 6</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light"></div>
                    <div className="badgePageItemEvidence">
                    <h3>Glossary of Terms</h3>
                    <br/><p>There are a lot of terms used in wine making and tasting! I have listed some basics below and then provided links to much more verbose sources at the end!</p>
                    <br/>
                        <table>
                            <thead>
                                <tr>
                                    <th>Term</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr><td>Aeration</td><td>Air is often forced through the wine to release the flavour. It can be done differently, including simply leaving the wine in the glass to breathe.</td></tr>
                                <tr><td>Aftertaste</td><td>The taste that is left in your mouth after you 've swallowed. The longer it lasts, the better the quality</td></tr>
                                <tr><td>Aging</td><td>Older wine is not always better.  During wine production, wine is kept in cellars and barrels to allow the wine to age, producing amazing flavours. That is often all the time required for aging.</td></tr>
                                <tr><td>Appearance</td><td>What the wine looks like in the glass, for example cloudy, clear or specific descriptions of the shade of red or white</td></tr>
                                <tr><td>Aroma</td><td>The smell of the wine. Aroma usually refers to the smell of young wine</td></tr>
                                <tr><td>Balance</td><td>How well the wine can juggle acidity, alcohol, sugar, aromas, and flavours in a single taste</td></tr>
                                <tr><td>Body</td><td>How heavy the wine feels in your mouth, for example “light, medium, or full-bodied.”</td></tr>
                                <tr><td>Bouquet</td><td>The complex aroma of aged wine</td></tr>
                                <tr><td>Carafe</td><td>A glass container with a flared lip used to serve wine</td></tr>
                                <tr><td>Closed</td><td>The wine is described as closed when there isn't much of an aroma</td></tr>
                                <tr><td>Cork Taint</td><td>A term used to describe a wine fault caused by a mold that grows on bleached wine corks. It gives the wine a musty aroma and taste.</td></tr>
                                <tr><td>Corked</td><td>A wine is described as corked when a faulty cork leaks a chemical and contaminates the wine</td></tr>
                                <tr><td>Decant</td><td>A form of aerating where the wine is poured into a decanter - a type of jug - and is either poured back into the bottle or served straight from the decanter</td></tr>
                                <tr><td>Mouthfeel / texture</td><td>The texture of the wine in your mouth, for example  silky, dusty, numbing, chewy, and creamy.</td></tr>
                                <tr><td>Must</td><td>Unfermented grape juice, skins, stems, and seeds</td></tr>
                                <tr><td>Nose</td><td>A nicer way to refer to the smell</td></tr>
                                <tr><td>Open</td><td>The wine is described as open when it has a range of different aromas</td></tr>
                                <tr><td>Press</td><td>A machine that extracts the grape juice from grapes before fermentation. </td></tr>
                                <tr><td>Sommelier</td><td>A certified wine professional that sometimes works as a wine butler</td></tr>
                                <tr><td>Table Wine</td><td>Everyday wine of decent quality or affordable non-sparkling, non-fortified wines. Table wine also refers to affordable wines that are not sparkling or fortified.</td></tr>
                                <tr><td>Tannins</td><td>Naturally occurring compounds in grape skins that give the wine a dry, bitter flavor. </td></tr>
                                <tr><td>Vintage</td><td>The year that the wine was made</td></tr>
                                <tr><td>Yeast</td><td>A microorganism that eats sugar and turns grape juice into wine during the fermentation process</td></tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <p>Source: https://www.vinovest.co/blog/wine-terms</p>
                        <p>Source: https://www.vinology.com/40-essential-wine-terms/</p>
                        <br/><br/>
                        <p>References for more comprehensive glossaries:</p>
                        <ul className="list">
                            <li>https://www.vinology.com/40-essential-wine-terms/</li>
                            <li>https://www.vinovest.co/blog/wine-terms</li>
                            <li>https://www.winecountry.com/blog/common-wine-descriptions/</li>
                            <li>https://www.thewinesociety.com/discover/wine-basics/wine-explained/wine-glossary-and-tasting-terms</li>
                            <li>https://www.vinology.com/wine-terms/</li>
                            <li>https://www.dummies.com/article/home-auto-hobbies/food-drink/beverages/wine/wine-for-dummies-cheat-sheet-208245/</li>
                        </ul>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 7</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light"></div>
                    <div className="badgePageItemEvidence">
                        <div className='hidden'>Learn about wine pairing. Create a 3 course meal with pairings</div>
                        <h3>A three course meal with wine pairings</h3>
                        <br/><p>I decided to use the three course meal that I created to serve for part of my <Link to="/chef">Chef</Link> badge.</p>
                        <br/><p></p>
                        <p><strong>Starter: Lomi Lomi Salmon</strong></p>
                        <p>I consulted three sources and chose a "Sauvignon Blanc" which should highlight the fresh flavours in the dish that contains peppers and onion. </p>
                        <p>I didn't want a red wine, that's too heavy for a starter (in my opinion). A sparkling wine was an option, but a chilled Sauvignon Blanc should work well.</p>
                        <br/>
                        <p>Source: https://www.tasteofhome.com/article/wine-pairing-with-salmon/</p>
                        <p>Source: https://www.thewinebuyingguide.com/Wine-Pairings/Wine-Pairing-With-Salmon</p>
                        <p>Source: https://www.thespruceeats.com/best-wines-with-salmon-5088446</p>
                        <br/><br/>
                        <p><strong>Main: Classic Loco Moco</strong></p>
                        <p>I selected a Pinot Noir to go with this dish, based on a recommendation from Wine Selectors as they suggested this could be a good accompaniment to dishes with mushrooms.</p>
                        <p>Wine Folly suggested that I should select a medium or bold red to go with red meat, Wine Selectors suggested a light or medium red. </p>
                        <br/>
                        <p>Source: https://www.wineselectors.com.au/selector-magazine/food/the-essential-beef-and-wine-pairing-guide</p>
                        <p>Source: https://winefolly.com/tips/basic-wine-and-food-pairing-chart/</p>
                        <br/><br/>
                        <p><strong>Dessert: Hawaiian Style Custard Pie</strong></p>
                        <p>I have chosen a Lugana to go with pudding, it should complement the cream of the pudding.</p>
                        <p>An obvious choice would have been Port or a fortified wine, but I felt that would be too heavy (or maybe I'm out of touch with dessert wines!). Independent Wine recommended the Lugana as it has high acidity to cut through the creamy sweetness.</p>
                        <p>An orangey Moscatel was another potential choice as recommended by Matching Food and Wine.</p>
                        <br/>
                        <p>Source: https://www.matchingfoodandwine.com/news/pairings/what-wine-to-pair-with-a-custard-tart/</p>
                        <p>https://www.independent.wine/italian-food-and-wine-pairing/choosing-wine-for-dessert-the-best-wines-to-serve-after-dinner/</p>
                        <br/><br/>
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default Sommelier