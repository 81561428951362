import React from 'react';
import {Link} from 'react-router-dom';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Scrapbook from '../documents/RebelDetective-Scrapbook.pdf';
import Detective1 from '../images/RebelDetective-1.jpg';
import Detective2 from '../images/RebelDetective-2.jpg';
import Detective3 from '../images/RebelDetective-3.jpg';
import BloodTypes1 from '../images/RebelDetective-BloodTypes1.jpg';
import BloodTypes2 from '../images/RebelDetective-BloodTypes2.jpg';

const Detective = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"Detective"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light"></div>
                    <div className="badgePageItemEvidence">
                        To meet this clause, I took the course “Forensic Science and Fingerprints” from OpenLearn.
                            The main three shapes and their sub-types are:
                            <ol className='list'>
                                <li>Loops - making around 60% of all fingerprints
                                    <ul className='list'>
                                        <li>Radial loop</li>
                                        <li>Ulnar loop</li>
                                    </ul>
                                </li>
                                <li>Arches - around 5% of fingerprints
                                    <ul className='list'>
                                        <li>Plain arch</li>
                                        <li>Tented arch</li>
                                    </ul>
                                </li>
                                <li>Whorls - around 35% of all fingerprints
                                    <ul className='list'>
                                        <li>Plain</li>
                                        <li>Central</li>
                                        <li>Pocket</li>
                                        <li>Double Loop</li>
                                        <li>Accidental</li>
                                    </ul>
                                </li>
                            </ol>
                            <br/><br/>
                            <p>My fingerprints are arches, but I'm not 100% certain which sub-type.</p>
                            <br/>
                    </div>
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>DNA stands for Deoxyribonucleic Acid and is made up of two long strands, arranged in a spiral, called a double-helix structure. It's like a recipe book that has all the instructions a living thing needs to grow, function, and reproduce. These instructions are carried on different sections of the DNA, which are called genes. Genes determine things like what color the eyes are and how the lungs work. </p>
                        <br/><p>DNA can be found on surfaces touched or worn by a person, or where biological material - such as blood, semen, saliva, urine, faeces, hair, teeth, bone, tissue and cells - could have been left behind. DNA evidence could be found at a crime scene where, for example, blood or skin cells exist on items left at a scene. If someone has touched an object or weapon they may leave skin cells behind - this is a low-level DNA and is referred to as “touch DNA”. It is even possible to extract DNA from bacteria in dirt, the heads of indivdual sperm cells and hair follicles.</p>
                        <br/>
                        <p>There are several steps required to record DNA evidence, this must follow strict protocols to ensure integrity and accuracy. 
                        <ul className="list">
                            <li>Collection: Forensic scientists collect human cells left at a crime scene</li>
                            <li>Extraction: The DNA is then extracted from these cells</li>
                            <li>Analysis: The extracted DNA is analyzed to create a DNA profile</li>
                            <li>Comparison: The DNA profile is then checked against a database of other profiles. If there is a match, it could be used as evidence</li>
                            <li>Recording: Once the laboratory analyzes the evidence and generates DNA profiles, the “suspect” DNA profiles from the crime scene are uploaded into a database</li>
                        </ul>
                        <br/>
                        All DNA evidence must be stored and documented properly and measures taken to prevent contamination.</p>
                        <br/>
                        <p>DNA profiles can be used in court, but not usually as the sole basis of a prosecution, as while it can place someone at a scene, it can't determine whether or not they committed the crime.</p>
                        <br/>
                        <p>DNA is passed down from parents to children and runs through families to varying degrees. Most of our DNA is identical to other people, but there are specific regions (polymorphics) that vary greatly and the differences in these regions (polymorphisms) can be analysed to create our DNA profile.</p>
                        <br/>
                        <p>These are the approximate percentages of DNA shared between different types of relatives:</p>
                        <ul className='list'>
                            <li>Identical Twin: 100%</li>
                            <li>Parent / Child: 50% (but 47.5% for father-son relationships)</li>
                            <li>Full Sibling: 50% (range: 38% - 61%)</li>
                            <li>Grandparent / Grandchild, Aunt / Uncle, Niece / Nephew, Half Sibling: 25% (range: 17% - 34%)</li>
                            <li>1st Cousin, Great-grandparent, Great-grandchild, Great-Uncle / Aunt, Great Nephew / Niece: 12.5% (range: 4% - 23%)</li>
                            <li>1st Cousin once removed, Half first cousin: 6.25% (range: 2% - 11.5%)</li>
                            <li>2nd Cousin: 3.13% (range: 2% - 6%)</li>
                            <li>2nd Cousin once removed, Half second cousin: 1.5% (range: 0.6% - 2.5%)</li>
                            <li>3rd Cousin: 0.78% (range: 0% - 2.2%)</li>
                            <li>4th Cousin: 0.20% (range: 0% - 0.8%)</li>
                            <li>5th Cousin to Distant Cousin: 0.05% (Variable)</li>
                        </ul>
                        <br/>
                        <p>Source: https://www.sciencelearn.org.nz/resources/1978-forensics-and-dna#:~:text=Forensic%20scientists%20can%20use%20DNA,people%20involved%20in%20a%20crime.</p>
                        <p>Source: https://www.open.edu/openlearn/science-maths-technology/science/across-the-sciences/dna-profiling</p>
                        <p>Source: https://customercare.23andme.com/hc/en-us/articles/212170668-Average-Percent-DNA-Shared-Between-Relatives</p>
                        <br/>
                    </div>
                </div>
            </div>          
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>Blood Spatter Analysis (Bloodstain Pattern Analysis) is used in forensic science to study bloodstains at crime scenes to help reconstruct events.</p>
                        <p>When interpreted correctly, it can be a vital source of information but it also has challenges, for example too much or too little blood, overlapping stains or multiple sources.
                            <br/><br/>
                            It works in this way:<br/>
                            <ol className='list'>
                                <li>Examine the bloodstains - size,shape, disstribution and location</li>
                                <li>Understand the patterns </li>
                                <li>Collect and document evidence - cut away stained surfaces or materials, taking photographs, drying and packaging stained items. </li>
                                <li>Interpret the findings - analysts can determine things like the direction, angle and speed of the blood's flight before impact and make educated guesses about, for example, the weapon used and sequence of events</li>
                            </ol>
                        </p>
                        <br/>
                        <p>There are three basic types of blood stain:
                            <ol className="list">
                                <li>Passive - drops, flows and pools, usually from injuries</li>
                                <li>Transfer - from objects coming into contact with bloodstains that leave behind wipes, swipes or patterns, such as bloody shoe prints or a body being dragged</li>
                                <li>Impact - from blood projected through the air, usually spatters but can also includes gushes, splashes and arterial spurts</li>
                            </ol>
                        <br/>
                            <ul className='list'>
                                <li>Single Drop - blood drops that have fallen vertically</li>
                                <li>Impact Spatter - forceful impact between an object and wet blood, causes the blood to break into smaller droplets. Greater forces create smaller droplets and density decreases further away from the source which may assist in identifying the position of individuals and objects</li>
                                <li>Cast-Off Strain - when centrifugal force causes blood drops to fall from an object in motion or sudden deceleration. Can be difficult to interpret due to pattern variations but produces fairly linear stains</li>
                                <li>Transfer Bloodstains - when blood is transferred from one surface toanother. Can help in determining sequence of events and movement of objects or people. Patterns in blood could help identify the object that made the stain, or help identify finger prints for example.</li>
                                <li>Projected Pattern / Arterial Damage Stain - discharge of pressurised blood onto a surface. Stains usually represent the beating of the heart, distributed in spurts, but can vary depending on extent and location of the wound and whether the person is moving.</li>
                                <li>Pool Stains - blood accumulated on surfaces from prolonged bleeding. If a body is absent, can determine whether they are likely to still be alive.</li>
                                <li>Insect Stains - result from insect activity, such as flies which feed on blood and produce “Flyspeck”, small circular stains. They may also spread blood by walking through it.</li>
                                <li>Expiration Stains - usually caused by coughing or otherwise produced from the mouth. Can produce a pattern of small, round stains, like a fine mist.</li>
                            </ul>
                        </p>
                        <br/>
                        <p>
                            Blood Spatter Analysis offers many benefits in forensic investigations:<br/>
                            <ul className='list'>
                                <li>Reconstructing the Crime Scene: BPA can help recreate the events that occurred at the crime scene. By studying the bloodstains and their patterns, investigators can make educated guesses about the sequence of events, the type of weapon used, the direction of the blow, and the severity of the injury.</li>
                                <li>Identifying Suspects: The analysis can provide clues that lead to potential suspects, e.g. the absence or presence of blood on a person's clothing can be significant</li>
                                <li>Validating Statements: BPA can be used to confirm or refute statements made by witnesses or suspects. If the bloodstain patterns are inconsistent with the accounts given, it could indicate that the person is not telling the truth.</li>
                                <li>Determining the Victim's Position: The position of the victim at the time of the crime can often be determined based on the bloodstain patterns.</li>
                                <li>Evidence of a Struggle: The presence of certain types of bloodstains can indicate if there was a struggle during the crime.</li>
                            </ul>
                        </p>
                        <br/>
                        <p>Source: https://www.forensicsciencesimplified.org/blood/principles.html
                            <br/>Source: https://aboutforensics.co.uk/bloodstain-pattern-analysis/
                            <br/>Source: https://ejfs.springeropen.com/articles/10.1186/s41935-021-00224-8 
                            <br/>Source: https://www.forensicscienceexpert.com/2020/02/blood-spatter-analysis.html 
                        </p>
                        <br/><br/>
                        <p><strong>Blood Types</strong></p>
                        <p>Blood types are passed genetically from parents to children, but your blood type may not be the same as your parents as you inherit a gene from each of them. These tables show the relationships:</p>
                        <img src={BloodTypes1} alt="A table showing a matrix of blood types" className="imgThird" /><img src={BloodTypes2} alt="A table showing a matrix of RH Factors" className="imgThird" />
                        <br/>
                        <p>
                            There are many rare blood types - over 600 antigens can live on red blood cells - but these are the eight most common:<br/>
                            <ul className='list'>
                                <li>A+      A and Rh antigens, B antibodies</li>
                                <li>A-      A antigens, B antibodies</li>
                                <li>B+      B and Rh antigens, A antibodies</li>
                                <li>B-      B antigens, A antobodies</li>
                                <li>AB+     A, B and Rh antigens, no antibodies</li>
                                <li>AB-     A and B antigens</li>
                                <li>O+      Rh antigens, A and B antibodies</li>
                                <li>O-      A and B antibodies</li>
                            </ul>
                        </p>
                        <br/><p>Blood types A and B are both dominant over blood type O. This means that if someone has type O blood, they must have inherited two O genes, otherwise the dominant type would determine the blood type.</p>
                        <br/>
                        <p>
                            Source: https://www.oneblood.org/blog/how-do-blood-types-work.html <br/>
                            Source: https://www.nhs.uk/conditions/blood-groups/ <br/>
                            Source: https://www.testing.com/articles/what-you-need-know-about-blood-type-and-your-health/ <br/>
                        </p>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 4</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>You can view a <a href={Scrapbook} className='linkUnderline' target="_blank" rel="noopener noreferrer">PDF of my scrapbook on Sherlock Holmes</a>.</p>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 5</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>For this clause, I have used these books and games:</p>
                        <div className='gallery'>
                            <div className="galleryItem"><img src={Detective1} alt="A book titled Moriarty's Trap"/></div>
                            <div className="galleryItem"><img src={Detective2} alt="A book title The Cypher Files"/></div>
                            <div className="galleryItem"><img src={Detective3} alt="A mini card pack titled Cupid's Revenge"/></div>
                        </div>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 6</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>You can find examples of this in my <Link to="/codebreaker" className='linkUnderline'>Codebreaker badge</Link> under “Messages”</p>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle olive">Clause 7</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo olive-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>For this clause I watched three true crime documentaries from NetFlix. </p>
                        <br/>
                        <p><strong>Curry and Cyanide</strong></p>
                        <p>The title of this one drew me in! A production from Netflix India, it is dubbed in English. This tells the story of Jolly Joseph and her reign of terror in the family that she married in to.  She is being investigated for the murders of 6 family members, including a 2 year old girl. The trial is ongoing so we don't know yet what the outcome will be,
                            <br/>It uses re-enactments and interviews with suriving family, investigators, lawyers and other experts as well as images and video footage from the time. It is presented very well and clearly lays out the background and timeline, very helpfully illustrated with a dynamic family tree showing the relationships, which made it easier to follow. 
                            <br/>There is no narration, or interviewer, it is all told through the interviewees. 
                            <br/>I enjoyed the format and as the story developed it had me gasping more at each new turn - and each new life - it took! 
                            <br/>A pretty horrific story of how one person almost got away with six murders, but for the vigilance of one person who dared to question what was happening.
                        </p>
                        <br/><br/>
                        <p><strong>The Parachute Murder Plot</strong></p>
                        <p>This one is somewhat closer to home, a Netflix UK production. It tells the story of Victoria Cilliers, who miraculously survived crashing to earth when her parachute and backup failed to deploy.  A call to the investigators from a friend sparked an investigation into her husband who was ultimately jailed for life for two attempted murder charges.
                            <br/>It follows the UK documentary style of having an interviewer / investigator and showing both sides of a conversation. Fiona Bruce leads, taking you through how the investigation started to its conclusion.
                            <br/>Emile Cilliers was originally tried for the attempted murder, but Victoria became a hostile witness when she failed to support the prosecution's case and the jury was hung. The investigators pressed forward and, after three years, they tried him again and got a unanimous guilty verdict.
                            <br/>Presented using interviews with pictures and video footage, some of that was repetitive. The evidence shown was interesting but - the obviously awful subject matter aside - the whole delivery felt a little flat.
                            <br/>A truly disturbing tale of an ice cold, arrogant, manipulative man who showed no remorse for his actions.
                        </p>
                        <br/><br/>
                        <p><strong>Girl in the Picture</strong></p>
                        <p>This one is from Netflix USA and, as bad as the others were, this was the most harrowing story of the three I watched. It just kept getting worse, right to the end.
                           <br/>It tells the story of Suzanne Sevakis, a young girl kidnapped and subsequently subjected to a life of abuse by the kidnapper who raised her as his daughter, married her then most likely killed her, then killed her son. That's not the least of it.
                           <br/>The story is driven by interviewees, like Curry and Cyanide we don't see an interviewer or have a narrator. We hear from the people who were closest to Suzanne and the lead investigators on the case, who showed an incredible persistence in discovering who she was and what happened to her. It was amazing how it all eventually unravelled.
                           <br/>Just when you think this young lady's story can't get any worse, it doubles down and down and down again. It is a truly devastating story of one beautiful spirit who was failed by so many, but who still managed to leave a lovely legacy behind to keep her spirit alive.
                           <br/>There are so many questions that should have been asked along the way and so many times she could have been saved. This was an engaging watch, leaving you as dogged as the detective in wanting to get to the truth. 
                        </p>
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default Detective