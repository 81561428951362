import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Mosaic1 from '../images/CupMay24-Mosaic1.jpg';
import Mosaic2 from '../images/CupMay24-Mosaic2.jpg';
import Mosaic3 from '../images/CupMay24-Mosaic3.jpg';
import Mosaic4 from '../images/CupMay24-Mosaic4.jpg';
import Mosaic5 from '../images/CupMay24-Mosaic5.jpg';
import Mosaic6 from '../images/CupMay24-Mosaic6.jpg';


const CupMay24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Rebel Cup Bonus Points"} />
            <div className="badgePageItem">Rebel Cup challenges are only posted in the facebook group for confirmed members. You will have to join the club to find out why I am doing these things!</div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Bonus 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light">Mosaic</div>
                    <div className="badgePageItemEvidence"> 
                       <p>Here's my mosaic for the Bull in a China Shop challenge.</p>
                       <div className="gallery">
                        <div classname="galleryItem"><img src={Mosaic1} alt="Pieces of broken ceramics laid out in a circular pattern" /></div>
                        <div classname="galleryItem"><img src={Mosaic2} alt="A circular piece of cardboard with tiling adhesive spread on the top" /></div>
                        <div classname="galleryItem"><img src={Mosaic3} alt="Pieces of broken ceramics being placed in a pattern on tiling adhesive" /></div>
                        <div classname="galleryItem"><img src={Mosaic4} alt="Pieces of broken ceramics being placed in a pattern on tiling adhesive" /></div>
                        <div classname="galleryItem"><img src={Mosaic5} alt="Pieces of broken ceramics being grouted into place" /></div>
                        <div classname="galleryItem"><img src={Mosaic6} alt="A completed mosaic showing two small fish swimming in the sea." /></div>
                       </div>
                    </div>
                </div>
            </div>
           
        </div>
    )
}

export default CupMay24