import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';


const BigKidJul23 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Big Kid Summer 2023"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Challenges</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light">I'm not going to list all the options here, they are on the <a className="linkUnderline" href="https://www.rebelbadgestore.com/rebelblog/july-challenge-badge-big-kid-challenge" target="_blank" rel="noopener noreferrer">blog at the Rebel Badge Store website</a>.</div>
                    <div className="badgePageItemEvidence"> 
                            <p>Check back and see how many I manage to do in one month!</p>     
                            <br/>
                            <p>I have opted not to display photos for this one, after all, sometimes it's nice to just have fun without proving it. You'lll just have to trust me on this one.</p>
                            <br/>
                            <p>This was what I opted for:</p>
                            <ul className="list">
                                <li>Build a sandcastle</li>
                                <li>Make a picture using finger painting</li>
                                <li>Jumpin a puddle with your wellies on</li>
                                <li>Colour in a black and white outline picture</li>
                                <li>Make a daisy chain</li>
                                <li>Make a fort our of blankets or pillows</li>
                                <li>Have a picnic with all your childhood foods</li>
                                <li>Swing on a swing</li>
                                <li>Bounce on a trampoline</li>
                                <li>Blow bubble</li>
                                <li>Make and eat an ice lolly</li>
                                <li>Feed the ducks</li>
                            </ul>  
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BigKidJul23