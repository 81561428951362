import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Historian1 from '../images/Historian1.png';
import Historian2 from '../images/Historian2.png';
import Book1 from '../images/historian-book1.jpg';
import Book2 from '../images/historian-book2.jpg';
import Video1 from '../images/historian-video1.jpg';
import Video2 from '../images/historian-video2.jpg';
import Video3 from '../images/historian-video3.jpg';
import Timeline from '../images/historian-timeline.png';

const Historian = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"Historian"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Sources</div>
                    <div className="badgePageItemEvidence">
                        <p>Primary sources give you direct access to raw information, while secondary sources analyse, interpret, or summarize that information</p>
                        <br/>
                        <p>Primary sources can provide direct evidence, examples are:</p>
                        <ul className='list'>
                            <li>Historical Context
                                <ul className='list'>
                                    <li>Letters and diaries written during a specific period.</li>
                                    <li>Photographs and video footage captured at the time.</li>
                                    <li>Official documents and records (e.g., government decrees, treaties).</li>
                                    <li>Physical objects (such as artifacts or archaeological findings).</li>
                                </ul>
                            </li>
                            <li>Art and Literature
                                <ul className='list'>
                                    <li>Novels, poems, and other literary works.</li>
                                    <li>Paintings, sculptures, and art installations.</li>
                                    <li>Films and live performances.</li>
                                </ul>
                            </li>
                            <li>Communication and Social Studies
                                <ul className='list'>
                                    <li>Interview transcripts.</li>
                                    <li>Recordings of speeches.</li>
                                    <li>Newspapers, magazines, and social media posts.</li>
                                </ul>
                            </li>
                            <li>Law and Politics
                                <ul className='list'>
                                    <li>Court records.</li>
                                    <li>Legal texts.</li>
                                    <li>Government documents.</li>
                                </ul>
                            </li>
                            <li>Sciences
                                <ul className='list'>
                                    <li>Empirical studies.</li>
                                    <li>Statistical data.</li>
                                </ul>
                            </li>
                        </ul>
                        <br/>
                        <p>Secondary sources can provide second-hand information and commentary based on primary sources, examples are:</p>
                        <ul className='list'>
                            <li>Books and Articles
                                <ul className='list'>
                                    <li>Synthesize information on a topic.</li>
                                    <li>Interpret or evaluate primary sources.</li>
                                </ul>
                            </li>
                            <li>Artistic Works
                                <ul className='list'>
                                    <li>Synopses and descriptions of paintings, music, or films.</li>
                                </ul>
                            </li>
                            <li>Encyclopedias and Textbooks
                                <ul className='list'>
                                    <li>Summarize information and ideas.</li>
                                </ul>
                            </li>
                            <li>Reviews and Essays
                                <ul className='list'>
                                    <li>Evaluate or interpret specific works or events.</li>
                                </ul>
                            </li>
                        </ul>
                        <br/>
                    </div>
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Perspectives</div>
                    <div className="badgePageItemEvidence">
                        <p>For this clause, I am looking at the invention of the internet. Whilst it is an incredible development, there are also some more negative aspects.</p>
                        <br/>
                        <p>Emerging from early research on packet switching and the ARAPNET, which set the groundwork for a global network, the internet has become an incredible technological achievement that transcends borders, enables collboration, easy dissemination of information and global interaction without geographic constraints.</p>
                        <br/>
                        <p>The internet is a huge information infrastructure that connects people, businesses and knowledge around the world, with sustained investment in reasearch and development, uniting government, industry and academia.</p>
                        <br/>
                        <p>It has had a transformational effect on society, removing geographic limitations and creating a global community. It empowers individuals to leanr, collaborate and express themselves, briding cultural gaps, aiding understanding and amplifying marginalised voices.</p>
                        <br/>
                        <p>The invention of the internet is a multifaceted phenomenon, fusing technology, social dynamics and global cooperation.</p>
                        <br/><br/>
                        <p>In contrast, the internet has also created a dystopian condition where people have become addicted to screens and have given up human connection in favour of virtual connection. Privacy is seriously threatened, with personal data being harvested, analysed and exploited and every interaction being surveiled.  Social media platforms manipulate emotions and polarise society, affecting the ability for critical thought and turning our attention into a commodity.  Our thoughts are driven by algorithms that prioritise sensational content and sow the seeds of division and outrage.</p>
                        <br/>
                        <p>Another negative aspect of the internet is cybercrime, which has become rampant through identify theft, cyberbullying and other nefarious activities.</p>
                        <br/>
                        <p> This culminates in the internet becoming a true dystopian landscape, where it isn't liberating but a prison where autonomy is surrendered for convenience and where people are totally connected and yet completely isolated.</p>
                        <br/>
                        <p>The dark side of the internet is a challenge to the promises that it delivers and requires vigilance, critical thinking and management of ethics.</p>
                        <br/>
                    </div>
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Historical Figure</div>
                    <div className="badgePageItemEvidence">
                        <p>I borrowed this book about Nikola Tesla from the Open Library. 300 pages of superbly written text about the mysterious inventor who was so far ahead of his time.</p>
                        <br/>
                        <img src={Historian1} alt="Screenshot of the front page of a book called Tesla, Man Out Of Time" className="imgThird" />&nbsp;<img src={Historian2} alt="Screenshot of the index page of a book" className="imgThird" />
                        <br/><br/>
                        <p>This is a timeline of major events in his life:</p>
                        <br/>
                        <table>
                            <thead>
                                <tr>
                                    <th>Year</th>
                                    <th>Event</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1856</td>
                                    <td>Born on July 9/10 in Smiljan, Austrian Empire (now in Croatia)</td>
                                </tr>
                                <tr>
                                    <td>1875</td>
                                    <td>Enrolled at Austrian Polytechnic in Graz, Austria</td>
                                </tr>
                                <tr>
                                    <td>1881</td>
                                    <td>Moved to Budapest, Hungary to work as a telephone engineer</td>
                                </tr>
                                <tr>
                                    <td>1882</td>
                                    <td>Tesla visualizes the principle of the rotating magnetic field and develops plans for an induction motor, a crucial step toward the successful utilization of alternating current</td>
                                </tr>
                                <tr>
                                    <td>1884</td>
                                    <td>Immigrates to the United States, arriving in New York with just four cents in his pocket. 
                                        <br/>Sells patent rights for his alternating-current dynamos, transformers, and motors to George Westinghouse
                                    </td>
                                </tr>
                                <tr>
                                    <td>1888</td>
                                    <td>Patented the Tesla Coil</td>
                                </tr>
                                <tr>
                                    <td>1888 - 1889</td>
                                    <td>Worked on alternating current (AC) systems and patents, including the induction motor</td>
                                </tr>
                                <tr>
                                    <td>1891</td>
                                    <td>Became a naturalized citizen of the United States
                                        <br/>Invents the Tesla coil, an induction coil widely used in radio technology
                                    </td>
                                </tr>
                                <tr>
                                    <td>1893</td>
                                    <td>Presented his ideas on wireless communication and demonstrated wireless power transmission</td>
                                </tr>
                                <tr>
                                    <td>1895</td>
                                    <td>Began construction of Wardenclyffe Tower in Shoreham, New York</td>
                                </tr>
                                <tr>
                                    <td>1899</td>
                                    <td>Experimented with wireless communication and lighting at Colorado Springs Laboratory</td>
                                </tr>
                                <tr>
                                    <td>1900</td>
                                    <td>Wardenclyffe Tower faced financial difficulties and was eventually abandoned</td>
                                </tr>
                                <tr>
                                    <td>1915</td>
                                    <td>Lost legal battles with Guglielmo Marconi over radio patents</td>
                                </tr>
                                <tr>
                                    <td>1943</td>
                                    <td>Passes away on January 7 in New York, leaving behind a legacy of groundbreaking inventions and contributions to electrical engineering</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/>
                    </div>
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 4</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Critical moments</div>
                    <div className="badgePageItemEvidence">
                        <p>For this clause, we are looking at the internet revolution, which has had a profound impact on the world. It is more than just technology, it impacts people, connections and the dynamism of interaction between information and society.</p>
                        <br/>
                        <p> It's significance can be categorised as follows.</p>
                        <br/>
                        <ol className='list'>
                            <li><strong>Communication Transformation</strong><br/>
                                In the past, staying informed relied upon physical, printed media which created a delay in news, today's news and information comes to us in real-time with just a click. Email, instant messaging, social media platforms, and video conferencing have transformed how people communicate, breaking down barriers of distance and time.
                            </li>
                            <li><strong>From Static Network to Dynamic Content</strong><br/>
                                The internet started as a static network with a network of expert coders to maintain the content. Now, anyone can be a creator, commentator or publisher and huge amounts of data are uploaded and downloaded every day,
                            </li>
                            <li><strong>Global Connectivity</strong><br/>
                                Again, in the past, global connection was possible but was through much slower media which delayed information reaching global communities. Now, the world is connected online and data is transmitted instantly without any geographic constraints.  This empowers individuals with the ability to learn, research and stay informed.
                            </li>
                            <li><strong>Commerce</strong><br/>
                                The internet has transformed retail and business models, with businesses now able to easily reach global markets. E-commerce platforms make online shopping easy and convenient.
                            </li>
                            <li><strong>Education</strong><br/>
                                The Internet has transformed education by providing access to online courses, resources, and educational platforms. Distance learning, online degree programs, and digital learning tools have expanded educational opportunities and made learning more accessible and flexible.
                            </li>
                            <li><strong>Entertainment</strong><br/>
                                The internet has made it possible for a wide range of digital content to be accessible to everyone, whether commercial platforms for movies, tv, music and streaming or individual creators providing user-generated content such as 	videos and podcasts.
                            </li>
                            <li><strong>Social Interaction</strong><br/>
                                Social media platorms allow people to connect, share and collaborate on a global scale. This has transformed how people and communities interact, communicate and form relationships.
                            </li>
                            <li><strong>Innovation and Collaboration</strong><br/>
                                The internet has made it possible for people to collaborate, share ideas and crowdsource solutions through open-source software and solutions, online collaboration tools and crowdfunding.
                            </li>
                            <li><strong>Political and Social Impact</strong><br/>
                                The internet has enabled people to organise, mobilise and advocate for change as online platforms have facilitated campaigns and activism which leads to greater civic engagement and awareness.
                            </li>
                            <li><strong>Information Revolution</strong><br/>
                                The internet shifted society from traditional industry to an economy that is centred on the production and distribution of information. An infinite amount of information is now available at the click of a button.
                            </li>
                            <li><strong>Challenges and Debates</strong><br/>
                                The internet has brought with it concerns over privacy and security and has caused debate over the impact to social relationships.However effective the internet is at connecting people, it can also be isolating. People connecting with their interests and beliefs places them into safe bubbles that can create a barrier to critical thinking due to lack of exposure to competing or opposing beliefs.  Finding a balance between bing connected with using the technology responsibly remains a challenge.
                            </li>
                        </ol>
                        <br/>
                    </div>
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 5</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Museums</div>
                    <div className="badgePageItemEvidence">
                        <p>I was fortunate enough to be able to visit The Museum of Witchcraft and Magic is Boscastle. It has one of the largest collections in the world of items related to witchcraft, the occult and magic and these cover a wide range of different magical practices and beliefs.</p>
                        <br/>
                        <p>The museum holds over 3000 objects and over 7000 books which can also be browsed online through their website.</p>
                        <br/>
                        <p>The museum has a serious and respectful approach, avoiding sensationalism and acknowledging the historical significance of the subject and the artifacts. It provides an educational insight by balancing the mystical with the scholarly, further creating a fascination with the subject matter.  The authenticity of the museum is deepened by its inclusion of real-world artifacts  - such as items made by real people at significant points in time.</p>
                        <br/>
                        <p>The museum is charming and intriguing, it captivates the imagination, but can also be a little scary or creepy, depending on your disposition! The museum had to re-do some exhibits as they were too scary for children.</p>
                        <br/>
                        <p>The exhibits include charms and curses, herbs and healing, magical tools, scrying and divination tools and protection talismans – such as those made by soldiers during World War I.</p>
                        <br/>
                        <p>I don't think there is much that I would change. The space is limited due to the location, but it works well, if a little cosy! At busy times it can feel hurried.</p>
                        <br/>
                        <p>Incorporating more interactive elements could enhance the visitor experience.</p>
                        <br/>
                        <p>Including narratives from different cultural backgrounds and traditions would enrich the exhibits.</p>
                        <br/><br/>
                        <p>Source: https://museumofwitchcraftandmagic.co.uk</p>
                        <p>Source: https://en.wikipedia.org/wiki/Museum_of_Witchcraft_and_Magic</p>
                    </div>
                </div>
            </div>  
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 6</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Historical Period</div>
                    <div className="badgePageItemInfo blue-light hidden">Choose a historical period, event, or topic and study it. Read or listen to at least two books and watch at least one film or documentary about it. Present your findings in the format of your choice</div>
                    <div className="badgePageItemEvidence">
                        <p>For this clause I have read the books and watched the videos in the gallery below.</p>
                        <div className="gallery">
                            <div classname="galleryItem"><img src={Book1} alt="Cover of the book titled The History of Computer Storage"/></div>
                            <div classname="galleryItem"><img src={Book2} alt="Cover of the book titled When Computing Got Personal" /></div>
                            <div classname="galleryItem"><img src={Video1} alt="Screenshot from a YouTube Video titled The Mother of All Demos"/></div>
                            <div classname="galleryItem"><img src={Video2} alt="Screenshot from a YouTube video titled Triumph of the Nerds"/></div>
                            <div classname="galleryItem"><img src={Video3} alt="Screenshot from a YouTube video titled Nerds 2.0.1."/></div>
                        </div>
                        <p>Here is a timeline of computer history. There's a lot more to this, but these are some key moments.</p>
                        <img src={Timeline} alt="A timeline of important dates in the history of computers" className="imgThird" />
                    </div>
                </div>
            </div>  
        </div>
    )
}

export default Historian