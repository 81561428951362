import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import VikingBoat1 from '../images/vikingboat1.jpg';
import VikingBoat2 from '../images/vikingboat2.jpg';
import VikingBoat3 from '../images/vikingboat3.jpg';
import VikingBoat4 from '../images/vikingboat4.jpg';
import VikingBoat5 from '../images/vikingboat5.jpg';
import VikingBoat6 from '../images/vikingboat6.jpg';
import VikingBoat7 from '../images/vikingboat7.jpg';
import VikingBoat8 from '../images/vikingboat8.jpg';
import VikingBoat9 from '../images/vikingboat9.jpg';
import VikingBoat10 from '../images/vikingboat10.jpg';
import VikingBoat11 from '../images/vikingboat11.jpg';
import VikingBoat12 from '../images/vikingboat12.jpg';
import VikingBoat13 from '../images/vikingboat13.jpg';
import VikingBoat14 from '../images/vikingboat14.jpg';
import VikingBoat15 from '../images/vikingboat15.jpg';
import VikingBoat16 from '../images/vikingboat16.jpg';
import Pretzel1 from '../images/Pretzel1.jpg';
import Pretzel2 from '../images/Pretzel2.jpg';
import Pretzel3 from '../images/Pretzel3.jpg';
import Pretzel4 from '../images/Pretzel4.jpg';
import Pretzel5 from '../images/Pretzel5.jpg';
import Pretzel6 from '../images/Pretzel6.jpg';
import Pretzel7 from '../images/Pretzel7.jpg';
import Pretzel8 from '../images/Pretzel8.jpg';
import Pretzel9 from '../images/Pretzel9.jpg';


const CupOct23 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Rebel Cup Bonus Points"} />
            <div className="badgePageItem">Rebel Cup challenges are only posted in the facebook group for confirmed members. You will have to join the club to find out why I am doing these things!</div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Viking Boat</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light"></div>
                    <div className="badgePageItemEvidence"> 
                       <p>I took this one super seriously and opted to make a wooden boat - out of lolly sticks!! I found a basic template online and used it to guide my boat design. I did add to it by adding a proper base, rather than just a flat bottom.</p>
                        <p>What did I learn during this process?</p>
                        <ul className="list">
                            <li>Hot glue isn't waterproof</li>
                            <li>Neither is all wood glue</li>
                            <li>Lolly sticks are not easy to work with!! They are hard to cut and split very easily</li>
                        </ul>
                        <br/>
                        <p>I am pretty proud of the result though! It definitely looks good and it floats - I'm just not sure how long it would stay afloat for, given that the glue is not technically waterproof! Hot glue holds it together and it is coated in a thick layer of wood glue, the acrylic paint probably helps.</p>
                        <p>I soaked the lolly sticks in water to bend them, I managed to curve them gently then tied them with string in the position I wanted and left them to dry overnight. This is how I achieved the curved bow and stern.</p>
                        <br/>
                        <p>Here's the finished boat:</p>
                        <div className="galleryItem"><img src={VikingBoat16} alt="Viking boat made from lolly sticks" /></div>
                        <br/>
                        <p>Some of the process:</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={VikingBoat1} alt="Printout of a viking boat pattern" /></div>
                            <div className="galleryItem"><img src={VikingBoat2} alt="Part of a viking boat constructed using lolly sticks" /></div>
                            <div className="galleryItem"><img src={VikingBoat3} alt="Base of a viking boat made from lolly sticks" /></div>
                            <div className="galleryItem"><img src={VikingBoat4} alt="A lolly stick soaked in water to soften it, bent and tied with string to hold the shape until it dries" /></div>
                            <div className="galleryItem"><img src={VikingBoat5} alt="Lolly sticks soaking in a glass of water to soften them" /></div>
                            <div className="galleryItem"><img src={VikingBoat6} alt="Lolly sticks curved into shape for part of a viking boat" /></div>
                            <div className="galleryItem"><img src={VikingBoat7} alt="Viking boat made from lolly sticks" /></div>
                            <div className="galleryItem"><img src={VikingBoat8} alt="Oars and rudder bases made from lolly sticks" /></div>
                            <div className="galleryItem"><img src={VikingBoat9} alt="Cutting viking shields out of lolly sticks" /></div>
                            <div className="galleryItem"><img src={VikingBoat10} alt="Oars and shields made from lolly sticks" /></div>
                            <div className="galleryItem"><img src={VikingBoat11} alt="Viking boat with oars made from lolly sticks" /></div>
                            <div className="galleryItem"><img src={VikingBoat12} alt="Painting oars and shields" /></div>
                            <div className="galleryItem"><img src={VikingBoat13} alt="Painting oars and shields" /></div>
                            <div className="galleryItem"><img src={VikingBoat14} alt="A sail made from paper" /></div>
                            <div className="galleryItem"><img src={VikingBoat15} alt="The curved bow of a viking boat made from lolly sticks" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Pretzels</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>First ever attempt at making pretzels, didn't turn out too badly!</p>
                        <br/>
                        <div className='gallery'>
                            <div className='galleryItem'>< img src={Pretzel1} alt="Bread mix in a bowl with a jug of water and melteed butter"/></div>
                            <div className='galleryItem'>< img src={Pretzel2} alt="Bread dough, ready to knead"/></div>
                            <div className='galleryItem'>< img src={Pretzel3} alt="Bread dough kneaded and risen"/></div>
                            <div className='galleryItem'>< img src={Pretzel4} alt="Baked Bicarbonate of Soda"/></div>
                            <div className='galleryItem'>< img src={Pretzel5} alt="Bread dough cut into 8 pieces ready for rolling"/></div>
                            <div className='galleryItem'>< img src={Pretzel6} alt="Pretzels ready for boiling"/></div>
                            <div className='galleryItem'>< img src={Pretzel7} alt="A pretzel in a pan of water and Baked Bicarbonate of Soda"/></div>
                            <div className='galleryItem'>< img src={Pretzel8} alt="Soft Pretzel on a plate"/></div>
                            <div className='galleryItem'>< img src={Pretzel9} alt="Soft pretzels on a plate"/></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CupOct23