import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Scientist1 from '../images/Scientist1.jpg';
import Scientist2 from '../images/Scientist2.jpg';
import Scientist3 from '../images/Scientist3.jpg';
import Scientist4 from '../images/Scientist4.jpg';
import Scientist5 from '../images/Scientist5.jpg';
import Scientist6 from '../images/Scientist6.jpg';
import Scientist7 from '../images/Scientist7.jpg';
import Scientist8 from '../images/Scientist8.jpg';
import Scientist9 from '../images/Scientist9.jpg';
import Scientist10 from '../images/Scientist10.jpg';
import PDF from '../documents/RebelScientistPrint.pdf';


const Scientist = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"Scientist"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Clause 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>I had great fun doing science experiments and generally making a mess!  A couple of things didn't quite go to plan and I've included those in my book!</p>
                        <p>I used two websites to source ideas and tried to pick experiments that used things that were likely to already be in a kitchen or could be picked up easily and cheaply.</p>
                        <p>I think the most expensive ingredient was Alka Seltzer for the lava lamp!</p>
                        <br/>
                        <p>After one failed experiment, I ignored anything with Borax as an ingredient as we can't buy it in the UK and the substitute didn't work in the experiment I was trying.</p>
                        <p>I also did things on a smaller scale in some instances - the lava lamp uses a lot of oil, so I used a small bottle instead of the suggested large one and it worked just as well.</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Scientist1} alt="A rubber egg" /></div>
                            <div className="galleryItem"><img src={Scientist2} alt="Skittles in a bowl with water" /></div>
                            <div className="galleryItem"><img src={Scientist3} alt="Raisins in a glass of fizzy water" /></div>
                            <div className="galleryItem"><img src={Scientist4} alt="Shaving cream on top on a glass of water with added food colouring" /></div>
                            <div className="galleryItem"><img src={Scientist5} alt="Water and oil in a jar with food colouring" /></div>
                            <div className="galleryItem"><img src={Scientist6} alt="Milk in a bowl with added food colouring" /></div>
                            <div className="galleryItem"><img src={Scientist7} alt="A lense made of jelly sitting on top of writing on a box" /></div>
                            <div className="galleryItem"><img src={Scientist8} alt="An ice cube stuck to a piece of string, hanging over a bowl of water" /></div>
                            <div className="galleryItem"><img src={Scientist9} alt="Tealights on a table being extinguished by a chemical reaction from a glass being held over the top." /></div>
                            <div className="galleryItem"><img src={Scientist10} alt="Snow Fluff balls made from shaving foam." /></div>
                        </div>
                        <br/><br/>
                        <p>Here is my final piece, <a href={PDF} className="linkUnderline">Sami Squirrel's Super Science</a></p>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default Scientist