import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const Politician = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"Politician"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>England's political system combines a constitutional monarchy - which is largely ceremonial - with a parliamentary democracy where elected representatives have legislative power.</p>
                        <br/>
                        <p>The Houses of Parliament are composed of two houses, the lower and upper.</p>
                        <br/>
                        <ol className='list'>
                            <li>House of Commons - the lower house
                                <ul className='list'>
                                    <li>650 Members of Parliament (MPs)</li>
                                    <li>MPs are directly elected by the public during general elections</li>
                                    <li>Plays a crucial role in shaping legislation, scrutinising the government and representing the interests of constituents</li>
                                </ul>
                            </li>
                            <li>House of Lords - the upper house
                                <ul className='list'>
                                    <li>Appointed members, including life peers, bishops and hereditary peers</li>
                                    <li>Lacks power to block legislation indefinitely, but reviews and siggests amendments to bills</li>
                                    <li>Acts as a revising chamber, offering expertise and scrutiny</li>
                                </ul>
                            </li>
                            <li>Powers and Responsibilities
                                <ul className='list'>
                                    <li><strong>Legislative Power:</strong> Both houses participate in the legislative process. Bills are proposed, debated, and amended in the House of Commons, and then reviewed by the House of Lords. Once agreed upon, they receive royal assent and become law.</li>
                                    <li><strong>Budget and Finance:</strong> The House of Commons holds the “power of the purse,” meaning it approves government spending and taxation proposals.</li>
                                    <li><strong>Scrutiny and Oversight:</strong> MPs question ministers, hold inquiries, and scrutinize policies. The House of Lords provides additional scrutiny.</li>
                                    <li><strong>Representation:</strong> MPs represent constituencies, advocating for their constituents' interests.</li>
                                </ul>
                            </li>
                            <li>Election of Members
                                <ul className='list'>
                                    <li><strong>House of Commons:</strong> MPs are elected through a first-past-the-post system. Each constituency elects one MP, and the party with the majority of seats usually forms the government.</li>
                                    <li><strong>House of Lords:</strong> Members are appointed, not elected. Life peers are chosen for their expertise, while hereditary peers inherit their titles.</li>
                                </ul>
                            </li>
                            <li>Government Formation
                                <ul className='list'>
                                    <li>The political party (or coalition) with the majority of seats in the House of Commons forms the government. The leader of that party becomes the Prime Minister.</li>
                                    <li>The Prime Minister appoints ministers from their party to head government departments.</li>
                                    <li>The government interacts with the monarchy through constitutional conventions. The monarch's role is largely ceremonial, but they grant royal assent to bills and participate in state functions.</li>
                                </ul>
                            </li>
                            <li>Judiciary and Monarchy
                                <ul className='list'>
                                    <li>Judiciary: The judiciary operates independently of the government. Courts interpret laws, resolve disputes, and ensure justice.</li>
                                    <li>Monarchy: The reigning monarch (currently Charles III) does not make political decisions. All decisions are taken by the government and Parliament. The monarch's role is symbolic and constitutional.</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>The four largest political parties in England are:</p>
                        <ul className='list'>
                            <li>Conservative Party:  Center-right to right-wing party that promotes  British conservationism and unionism</li>
                            <li>Labour Party: Center-left party that advocates for social democracy and democratic socialism</li>
                            <li>Liberal Democrats: Centrist party that champions liberalism and federalism</li>
                            <li>Scottish National Party: Primarily focused on Scotland but with a presence in England, known for its support of Scottish Independence</li>
                        </ul>
                        <br/><br/>
                        <p>Highest to Lowest membership:</p>
                        <ul className='list'>
                            <li>Labour Party</li>
                            <li>Conservative Party</li>
                            <li>SNP</li>
                            <li>Liberal Democrats</li>
                        </ul>
                        <br/><br/>
                        <p>I asked Bing what the top 10 political issues are and will use these for the comparison exercise.
                            <br/>Links to the individual constitutions are listed at the end.
                        </p>
                        <br/><br/>
                        <ol className='list'>
                            <li><strong>Brexit</strong>
                                <ul className='list'>
                                    <li><strong>Conservative Party:</strong> Generally supportive of Brexit, aiming for a clean break from the European Union and seeking to implement the terms of the withdrawal agreement and negotiate trade deals.</li>
                                    <li><strong>Labour Party:</strong> More ambiguous stance on Brexit, with some members advocating for a second referendum. Initially divided but generally favored a softer Brexit, advocating for closer ties with the EU, protection of workers' rights, and environmental standards</li>
                                    <li><strong>Liberal Democrats:</strong> Strongly opposed Brexit, advocating for a second referendum to either reverse Brexit or negotiate a softer departure. Expressed a desire for Britain to rejoin the European Union single market</li>
                                    <li><strong>Scottish National Party:</strong> Strongly opposed to Brexit - particularly in Scotland, where the majority voted to remain in the EU - and have expressed a commitment to rejoining the EU as soon as possible</li>
                                </ul>
                            </li>
                            <li><strong>NHS</strong>
                                <ul className='list'>
                                <li><strong>Conservative Party:</strong>  Generally supportive of the NHS but tends to prioritize measures to increase efficiency and reduce costs through market-oriented reforms.  Commitments to universal service, increased funding, infrastructure upgrades, staffing increases, and more GP appointments, but has faced criticism for alleged underfunding. </li>
                                <li><strong>Labour Party:</strong> Strongly supportive of the NHS and tends to advocate for increased public funding, expansion of services, and reversing privatization measures. They have promised to invest an extra £35bn into health and social care over the next five years.</li>
                                <li><strong>Liberal Democrats:</strong> Supportive of the NHS and tend to emphasize increasing funding, improving mental health services, and reducing waiting times. They have expressed concerns about the current state of the NHS and proposed a 5-point Winter Plan for Ambulances.</li>
                                <li><strong>SNP:</strong> Strongly supportive of the NHS in Scotland, advocating for increased funding and control over health policy. They have committed to a £2.5bn boost to frontline NHS spending.</li>
                                </ul>
                            </li>
                            <li><strong>Education</strong>
                                <ul className='list'>
                                    <li><strong>Conservative Party:</strong> Generally supports school choice, academization of schools, and increasing standards through accountability measures. They have promised to increase school funding by £14 billion and raise teachers' salaries.</li>
                                    <li><strong>Labour Party:</strong> Advocates for increased funding for schools, reducing class sizes, and opposing tuition fees for higher education. They have proposed to change the mix of what children learn in England's schools, including creative subjects and sport until the age of 16.</li>
                                    <li><strong>Liberal Democrats:</strong> Support investment in education, advocating for policies such as free early education, pupil premium, and opposing tuition fees for higher education. They have proposed to rewrite the national curriculum to boost creative subjects in schools.</li>
                                    <li><strong>Scottish National Party:</strong> In Scotland, they emphasize free tuition for higher education and investment in schools. They have committed to investing in schools and ensuring every child has the chance to succeed in life.</li>
                                </ul>
                            </li>
                            <li><strong>Economy</strong>
                                <ul className='list'>
                                    <li><strong>Conservative Party:</strong> Generally supports a market-driven economy with low taxes, reduced regulation, and promoting business growth. They believe in a free-market economy and oppose government controls. They focus on increasing homeownership, reducing public expenditure on welfare, and cutting taxes. </li>
                                    <li><strong>Labour Party:</strong> Tends to advocate for more state intervention, higher taxes on the wealthy, nationalization of key industries, and policies to reduce income inequality. They believe in a strong welfare state and publicly-funded services. They aim to achieve the highest sustained growth in the G7 group of rich nations by the end of Labour's first term.</li>
                                    <li><strong>Liberal Democrats:</strong> Advocate for a mixed economy, supporting market principles while also advocating for measures to address inequality and promote social justice. They believe in higher government investment in infrastructure and research, as well as national hyper-fast broadband. They also support small businesses.</li>
                                    <li><strong>Scottish National Party:</strong>  Advocates for a fairer economy, with policies focused on supporting small businesses and promoting sustainable growth. They aim for a competitive, fairer, and more sustainable economy. They focus on increasing support for research and development, investing in low-carbon innovation, and establishing a National Manufacturing Institute in Renfrewshire.</li>
                                </ul>
                            </li>
                            <li><strong>Environment</strong>
                                <ul className='list'>
                                    <li><strong>Conservative Party:</strong> Generally supports environmental protection but tends to balance this with economic considerations and emphasizes market-based solutions. They have a group within the party known as the “blue environmentalists” who are on board with tackling carbon emissions and are campaigning on oceans, protecting animals, rewilding, and reducing plastic waste.</li>
                                    <li><strong>Labour Party:</strong> Tends to prioritize environmental protection, climate action, and sustainable development, often advocating for stricter regulations and investment in renewable energy. They aim to drastically reduce greenhouse gas emissions by investing in new industries and reforms to existing practices. They have proposed an annual £28bn green investment fund, a mass retrofitting programme, a pledge to decarbonise steel, and a “net zero and nature test” for every policy.</li>
                                    <li><strong>Liberal Democrats:</strong> Strongly support environmental protection, advocating for measures to combat climate change, promote renewable energy, and protect natural habitats. They aim to cut greenhouse gas emissions to net zero by 2045. They plan to invest significantly in renewable power so that 80% of the UK's electricity is generated from renewables by 2030.</li>
                                    <li><strong>Scottish National</strong> Party: Strongly supports environmental protection and renewable energy, aiming for Scotland to become carbon-neutral. They are committed to achieving a 100% reduction in emissions as soon as possible and have put in place the toughest targets anywhere10. They aim to phase out the need for petrol and diesel cars and vans by 2032.</li>
                                </ul>
                            </li>
                            <li><strong>Housing</strong>
                                <ul className='list'>
                                    <li><strong>Conservative Party:</strong> Generally supports policies to increase home ownership, such as Help to Buy, and encourages private sector development. Has been focused on increasing homeownership, with policies to benefit homeowners.</li>
                                    <li><strong>Labour Party:</strong> Advocates for increasing social housing, rent controls, and measures to tackle homelessness. Includes commitments to building more homes, planning reform, creating new towns, supporting first-time buyers, and addressing renters' rights.</li>
                                    <li><strong>Liberal Democrats:</strong> Support policies to increase affordable housing supply, improve renting conditions, and tackle homelessness. Believe in taking action to tackle the housing crisis, calling for significantly more homes to be built every year, including significantly more social homes.</li>
                                    <li><strong>Scottish National Party:</strong>  Focuses on increasing affordable housing supply, supporting first-time buyers, and promoting socially rented housing. Delivered 96,750 affordable homes, nearly 67,000 of which were for social rent. They plan to deliver a further 100,000 affordable homes by 2032.</li>
                                </ul>
                            </li>
                            <li><strong>Crime and Policing</strong>
                                <ul className='list'>
                                    <li><strong>Conservative Party:</strong> Generally supports tough-on-crime policies, increasing police numbers, and strengthening law enforcement powers. Has taken a hardline approach on crime and justice, pledging 20,000 new police officers on the streets.</li>
                                    <li><strong>Labour Party:</strong> Advocates for community policing, crime prevention measures, and addressing root causes of crime such as poverty and inequality.  Has set out a mission on crime, to halve the level of serious violent crime within a decade.</li>
                                    <li><strong>Liberal Democrats:</strong>  Tend to emphasize community policing, restorative justice, and addressing underlying social issues to reduce crime. Have adopted a public health approach to violent crime, transforming prisons into “places of rehabilitation and recovery”.</li>
                                    <li><strong>Scottish National Party:</strong>  Focuses on community policing, early intervention, and alternatives to imprisonment. Has increased the number of police officers in Scotland by 883 since they came into office.</li>
                                </ul>
                            </li>
                            <li><strong>Welfare and Social Services</strong>
                                <ul className='list'>
                                    <li><strong>Conservative Party:</strong> Tends to support welfare reforms aimed at reducing dependency, increasing work incentives, and controlling spending. Has focused on reducing public expenditure on welfare.</li>
                                    <li><strong>Labour Party:</strong>  Generally advocates for a more generous welfare state, supporting higher benefits, and opposes austerity measures. Believes in a strong welfare state and publicly-funded services.</li>
                                    <li><strong>Liberal Democrats:</strong>  Advocate for a fairer welfare system, supporting measures to alleviate poverty, protect vulnerable groups, and reform the welfare system. Believe that an active state is essential to empower people and provide the support they need.</li>
                                    <li><strong>Scottish National Party:</strong> Generally supports a social safety net and opposes austerity measures, particularly in Scotland. Has increased public investment in social care by 25% over the parliament, delivering over £800 million of increased support for social care</li>
                                </ul>
                            </li>
                            <li><strong>Defense and Security</strong>
                                <ul className='list'>
                                    <li><strong>Conservative Party:</strong> Generally supports maintaining a strong military, NATO membership, and investing in defense capabilities. Supports a strong defense and national security policy, maintaining an independent nuclear deterrent, and NATO as the cornerstone of transatlantic defense and security</li>
                                    <li><strong>Labour Party:</strong> Supports a strong defense but tends to prioritize multilateral diplomacy, disarmament, and humanitarian interventions over military action. Supports  a comprehensive strategic defense and security review, strengthening strategic alliances internationally, and improving the nation's moral relationship with those in service.</li>
                                    <li><strong>Liberal Democrats:</strong> Tend to advocate for a strong defense while also emphasizing diplomacy, conflict prevention, and arms control. Advocates for a well-funded military and strong intelligence agencies to protect the country's interests. They also support NATO and maintain a commitment to the nuclear deterrent.</li>
                                    <li><strong>Scottish National Party:</strong> Advocates for the removal of nuclear weapons from Scotland, opposes military intervention, and emphasizes diplomacy and conflict resolution. Opposes the renewal of Trident and has criticized Westminster defense cuts.</li>
                                </ul>
                            </li>
                            <li><strong>Family and Social Issues</strong>
                                <ul className='list'>
                                    <li><strong>Conservative Party:</strong> Tends to support traditional family values, policies to support families such as tax breaks, and emphasizes personal responsibility. Often supports traditional values, including family values, marriage, and religious freedom. They believe in economic conservatism and individualism, emphasizing personal responsibility.</li>
                                    <li><strong>Labour Party:</strong> Advocates for policies to support families, such as parental leave, childcare support, and gender equality measures. At the forefront of progressive social policies, including LGBTQ+ rights, gender equality, and racial justice. They also aim to support struggling families and pensioners during cost-of-living crises.</li>
                                    <li><strong>Liberal Democrats:</strong> Support policies promoting family well-being, such as flexible working arrangements, parental leave, and policies to tackle domestic violence. Believe in empowering people and providing the support they need, including helping struggling families and pensioners when they face a cost-of-living crisis.</li>
                                    <li><strong>Scottish National Party:</strong> Supports policies to support families, such as childcare support and initiatives to tackle domestic violence. Committed to supporting families and young people across Scotland, improving public services to help families and young people through the cost-of-living crisis.</li>
                                </ul>
                            </li>
                        </ol>
                        <br/><br/>
                        <p>
                            <br/>Source: https://www.conservatives.com/content/conservatives/gb/en/our-plan.html
                            <br/>Source: https://labour.org.uk/missions/
                            <br/>Source: https://labourlist.org/2023/05/labour-manifesto-2024-election-what-policies-npf-party/
                            <br/>Source: https://www.libdems.org.uk/conference/papers/spring-2024
                            <br/>Source: https://www.theguardian.com/politics/2019/nov/20/liberal-democrat-manifesto-key-policies-explained
                            <br/>Source: https://www.conservatives.com/content/conservatives/gb/en/our-plan.html
                            <br/>Source: https://www.snp.org/programme-for-government-2023-24-what-you-need-to-know/
                        </p>
                    </div>
                </div>
            </div>          
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>I have done this clause, but due to the personal nature of the work I am not evidencing it here.</p>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 4</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light"></div>
                    <div className="badgePageItemInfo blue-light hidden">What percentage of people in your country currently votes?Are there any groups of people who are under-represented at the ballet box? Why? What can be done to ecourage more people to vote?</div>
                    <div className="badgePageItemEvidence">
                        <p>In the last general election in 2019, 67.3% of voters turned out, which means that approx. two-thirds of eligible voters participated in the election. It was a slight decrease from the 2017 election, but is one of the highest turnouts since 1997.</p>
                        <br/>
                        <p>These groups are under-represented at the ballet box:</p>
                        <ol className='list'>
                            <li><strong>Young Voters:</strong> Young people, especially those aged 18 to 24, tend to have lower voter turnout. Reasons include lack of political engagement, feeling disconnected from traditional politics, and not being adequately informed about the voting process.</li>
                            <li><strong>Ethnic Minorities:</strong> Some ethnic minority communities face barriers such as language difficulties, cultural differences, and historical disenfranchisement. </li>
                            <li><strong>Low-Income Individuals:</strong> Economic disparities can impact voter participation. People facing financial hardship may prioritize immediate needs over voting, and they might lack access to reliable transportation to polling stations.</li>
                            <li><strong>People with Disabilities:</strong> Physical accessibility issues at polling stations can discourage people with disabilities from voting. Additionally, some individuals may lack information on accessible voting options.</li>
                            <li><strong>Homeless Population:</strong> Homeless individuals often face challenges in obtaining valid photo ID (required for voting) due to unstable living conditions. </li>
                            <li><strong>Transgender and Non-Binary Individuals:</strong> The new voter ID law, which requires photo identification, may disproportionately affect transgender and non-binary people. Some may be uncomfortable showing ID that doesn't match their gender identity.</li>
                            <li><strong>Elderly Voters:</strong> While many elderly citizens actively vote, some face mobility issues or cognitive decline, making it harder for them to participate.</li>
                            <li><strong>Migrants and Refugees:</strong> Recent migrants and refugees may lack awareness of the voting process or face language barriers. Additionally, their immigration status can impact eligibility.</li>
                            <li><strong>Students:</strong> Students often move between home and university towns, leading to confusion about where to vote. Some may also feel disengaged from local politics.</li>
                            <li><strong>Prisoners:</strong> Prisoners are disenfranchised in England, except for those on remand or serving shorter sentences. This exclusion affects their representation.</li>
                        </ol>
                        <br/>
                        <p>Source: https://www.libertyhumanrights.org.uk/advice_information/how-does-the-new-voter-id-law-affect-my-rights</p>
                        <br/><br/>
                        <p>These are some of the strategies that could be deployed to encourage more people to vote:</p>
                        <ol className='list'>
                            <li>Education and Awareness
                                <ul className='list'>
                                    <li>Include civic education in schools to ensure students are aware of the process, their rights and responsibilities and the importance of voting.</li>
                                    <li>Run awareness campaigns to dispell myths and misconceptions and stress the value of voting.</li>
                                </ul>
                            </li>
                            <li>Accessibility
                                <ul className='list'>
                                    <li>Facilitate mulitple voting methods and times to include as many people as possible. Inc: postal voting, early voting and mobile polling stations.</li>
                                    <li>Ensure that every polling location is accessible to all.</li>
                                </ul>
                            </li>
                            <li>Community Engagement
                                <ul className='list'>
                                    <li>Engage community leaders, local organizations, and influencers to encourage voter participation.</li>
                                    <li>Use volunteers to to door-to-door campaigns, engaging people at home to provide information</li>
                                </ul>
                            </li>
                            <li>Incentives
                                <ul className='list'>
                                    <li>Explore incentives like discounts, free public transportation on election day, or entry into prize draws</li>
                                    <li>Simplify the registration processes</li>
                                </ul>
                            </li>
                            <li>Youth Engagement
                                <ul className='list'>
                                    <li>Young people need to have a stake in their future, voting age could be lowered to 16</li>
                                    <li>Use social media to reacher younger generations</li>
                                </ul>
                            </li>
                            <li>Representation
                                <ul className='list'>
                                    <li>Ensure that the demographics of the population are represented in candidates</li>
                                    <li>Ensure that representation is fair and proportional</li>
                                </ul>
                            </li>
                            <li>Transparency and Trust
                                <ul className='list'>
                                    <li>Regulate campaign funding to prevent undue influence and maintain trust.</li>
                                    <li>Implement strict fact checking to ensure that misinformation is not spread during election times</li>
                                </ul>
                            </li>
                            <li>Celebrate Voting
                                <ul className='list'>
                                    <li>Encourage pride in voting</li>
                                    <li>Use stickers, “I Voted” for example, as a reward</li>
                                </ul>
                            </li>
                            <li>Peer Influence
                                <ul className='list'>
                                   <li>Highlight that voting is a social norm</li>
                                    <li>Encourage people to discuss voting with friends and family</li>
                                </ul>
                            </li>
                            <li>Political Engagement
                                <ul className='list'>
                                    <li>Ensure that local issues are highlighted as being connected to voting</li>
                                    <li>Educate new citizens about their voting rights and encourage their participation.</li>
                                </ul>
                            </li>
                        </ol>
                        <br/><br/>
                        <p>Source: Unfortunately I forgot to record the source for this. It has been taken and adapted from an answer from Bing chat. Credit to the original writer.</p>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">Clause 5</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>I have completed this clause but due to the personal nature of the content I am not sharing it here.</p>
                    </div>
                </div>
            </div> 
        </div> 
    )
}

export default Politician