import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Scavenger1 from '../images/Scavenger1.jpg';
import Scavenger2 from '../images/Scavenger2.jpg';
import Scavenger3a from '../images/Scavenger3a.jpg';
import Scavenger3b from '../images/Scavenger3b.jpg';
import Scavenger4 from '../images/Scavenger4.jpg';
import Scavenger5 from '../images/Scavenger5.jpg';
import Scavenger6 from '../images/Scavenger6.jpg';
import Scavenger7 from '../images/Scavenger7.jpg';


const ScavengerAug23 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Scavenger Hunt August 2023"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Challenges</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light">I'm not going to list all the options here, they are on the <a className="linkUnderline" href="https://www.rebelbadgestore.com/rebelblog/the-scavenger-hunt-and-super-scavenger-return" target="_blank" rel="noopener noreferrer">blog at the Rebel Badge Store website</a>.</div>
                    <div className="badgePageItemEvidence"> 
                        <p>I opened this one up to the family, as I don't get out enough to find random items. This isn't a badge that I would usually do, but I had paid for it as part of my quarterly subscription, so it shows less effort than most of my other badges (sorry RBC!!)</p>     
                        <br/>
                        <p>Here's what we came up with:</p> 
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Scavenger1} alt="Playing on a mini arcade game" /></div>
                            <div className="galleryItem"><img src={Scavenger2} alt="A shop sign that says 'Vape of Good Hope'" /></div>
                            <div className="galleryItem"><img src={Scavenger3a} alt="Five rubber ducks in a row on a bathroom windowsill" /></div>
                            <div className="galleryItem"><img src={Scavenger3b} alt="Real ducklings in a row, swimming on a lake." /></div>
                            <div className="galleryItem"><img src={Scavenger4} alt="A children's maagazine called 'Num Noms'" /></div>
                            <div className="galleryItem"><img src={Scavenger5} alt="A tiny cake on a plate in a dollshouse room." /></div>
                            <div className="galleryItem"><img src={Scavenger6} alt="A tiny lamp on a table in a dollshouse room." /></div>
                            <div className="galleryItem"><img src={Scavenger7} alt="A model of a dragon in an egg." /></div>
                        </div>     
                        <br/><br/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScavengerAug23