import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Philo from '../images/MyBrand-Philomath.png';
import Thala from '../images/MyBrand-Thalassophile.png';
import Scien from '../images/MyBrand-CitizenScientist.png';

const MyBrand = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"My Brand"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">01</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light">10 words about you</div>
                    <div className="badgePageItemInfo reverse hidden">Write a list of 10 words which you'd like other people to use when they describe you.</div>
                    <div className="badgePageItemEvidence">
                        <ul className='list'>
                            <li>Kind</li>
                            <li>Friendly</li>
                            <li>Generous</li>
                            <li>Patient</li>
                            <li>Happy</li>
                            <li>Funny</li>
                            <li>Helpful</li>
                            <li>Caring</li>
                            <li>Genuine</li>
                            <li>Unconventional</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">02</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light">Brand values</div>
                    <div className="badgePageItemEvidence">
                        <ul className='list'>
                            <li>Kind - Act with kindness and compassion</li>
                            <li>Friendly - Be welcoming and approachable</li>
                            <li>Generous - Always be willing to give and share</li>
                            <li>Patience - Be calm and composed in all situations</li>
                            <li>Happy - Be positive and always ready with a smile</li>
                            <li>Funny - Bring laughter and amusement to others</li>
                            <li>Helpful - Be available and supportive to others</li>
                            <li>Genuine - Be true to yourself and your own values</li>
                            <li>Dynamic - Try new things, maintain enthusiasm and flexibility</li>
                            <li>Unconventional - Inspire creativity and originality</li>
                        </ul>
                        <br/>
                        <p>Does my behaviour reflect these values? Yes. Like everyone, I am human and have off days, but yes, these values are mine and I live by them.</p>
                        <p>Could I make any changes? I've already done a lot of that of the last few years, evaluating my life and making changes as needed according to what makes me the person who can embody these values.</p>
                        <p>I need to be kinder to myself and understand why things go wrong when I haven't been able to reflect my values so that I can adjust my behaviour accordingly.</p>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">03</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light">Social Media</div>
                    <div className="badgePageItemEvidence">
                        <p>Do I represent my values on social media? Yes. My social media is authentically me and I am my values. I do not say or do anything online that I wouldn't say or do offline. The only time I may not represent my values is if I am venting about a bad experience, but even then I try to find the positive!</p>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">04</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light">What are you known for?</div>
                    <div className="badgePageItemEvidence">
                        <p>What am I known for? Hopefully those things listed above. The words capable, conscientious, trustworthy and reliable would hopefully be there too. </p>
                        <p>What would I like to be known for? All of the above. I just want to be helpful, to help other people realise their own abilities, to make people feel like anything is possible.</p>
                        <p>What changes can I make to affect this? None, I strive to do this at all times.</p>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">05</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light">Motivations</div>
                    <div className="badgePageItemInfo reverse hidden">What drives or motivates you? Write a list of plans for the next six months which tap into these drivers and tap into your values.</div>
                    <div className="badgePageItemEvidence">
                        <p>I am motivated by:</p>
                        <ul className='list'>
                            <li>Knowledge</li>
                            <li>Service</li>
                            <li>Creativity</li>
                        </ul>
                        <br/>
                        <p>Each of these will be covered in more detail by My Goals, but these are my over-arching plans:</p>
                        <ul className="list">
                            <li>To continue with my 365 days of creativity course</li>
                            <li>Finish my AI Bootcamp course</li>
                            <li>Finish the other courses that I have already purchased and enrol in more for the next three months</li>
                            <li>Continue with scheduled beach cleans</li>
                            <li>Set up new activities for Christchurch Marine Conservation and re-launch group (voluntary)</li>
                            <li>Work on engagement for Plastic Free Highcliffe (voluntary)</li>
                            <li>Design a new product range for Gifted Cards based on original idea - return to roots</li>
                            <li>Work on adding more photographs for Moments and Moxie - promote to achieve first sale!</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">06</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light">Design a badge</div>
                    <div className="badgePageItemInfo reverse hidden">Design a Rebel bagde or three which define you.What would it be called? What Icon would you use?</div>
                    <div className="badgePageItemEvidence">
                        <p>My badge is Philomath, because I love learning! It was going to be Polymath, but although I have a wide range of knowledge and skills, I'm not sure any of them would be in depth enough for me to be considered a real polymath!</p>
                        <p>My icon is a head with question marks where a brain should be! Credit for the head image to user geralt-9301 on Pixabay.</p>
                        <img src={Philo} alt="A badge clause page for a Philomath badge" className='imgThird'/>
                        <br/><br/>
                        <p>I wasn't going to do more than one, but eventually I came up with two more things to represent me.</p>
                        <br/>
                        <p>Here are Thalassophile and Citizen Scientist.</p>
                        <br/>
                        <img src={Thala} alt="A badge clause page for a Thalassophile badge" className='imgThird'/>
                        <img src={Scien} alt="A badge clause page for a Citizen Scientist badge" className='imgThird'/>
                        <br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">07</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light">See Book for exercises.</div>
                    <div className="badgePageItemEvidence">
                        <p>Evaluate qualities (1-10)</p>
                        <ul className='list'>
                            <li>Self-Belief =&gt; 5</li>
                            <li>Positive Mental Attitude =&gt; 9</li>
                            <li>Flexibility =&gt; 8</li>
                            <li>Decision Making =&gt; 8</li>
                            <li>Determination =&gt; 9</li>
                            <li>Desire to Learn and Grow =&gt; 10</li>
                        </ul> 
                        <br/>
                        <p>Choose your own qualities and rate them (1-10):</p>
                        <p>I don't have any further qualities to add.</p>
                        <ul className='list'>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyBrand