import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';

const Powers = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"My Powers"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Part 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence">
                        This section is about identifying your "superpowers" and inspiration.
                        <br/><br/>
                        Due to the personal nature of this badge, I am not uploading the evidence here, but it has been completed.
                        <br/><br/>
                    </div>
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle pink">Part 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo pink-light"></div>
                    <div className="badgePageItemEvidence">
                        This section is about taking time to observe and understand your powers as you and others see them.
                        <br/><br/>
                        Due to the personal nature of this badge, I am not uploading the evidence here, but it has been completed.
                        <br/><br/>
                    </div>
                </div>
            </div>     
        </div>
    )
}

export default Powers