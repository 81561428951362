import React from 'react'
import "../styles/styles.css";
 
const Footer = () => {
    return (
        <footer>
            <div>Disclaimer: Rebel Badge Book and The Maverick Awards are created and owned by Charly Lester, I claim no credit for any of this. This website is purely my progress towards achieving the goals set in the publication and may use colours that represent the relevant challenges. Badges and associated descriptions have been redacted to protect the content of the books. No copyright infringement intended.</div>
            <div>&nbsp;</div>
            <div>This web site &copy; 2022 msrainea </div>
        </footer>
    )
}

export default Footer