import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import ScienceFair1 from '../images/sciencefair23-1.jpg';
import ScienceFair2 from '../images/sciencefair23-2.jpg';
import ScienceFair3 from '../images/sciencefair23-3.jpg';
import ScienceFair4 from '../images/sciencefair23-4.jpg';
import ScienceFair5 from '../images/sciencefair23-5.jpg';
import ScienceFair6 from '../images/sciencefair23-6.jpg';
import ScienceFair7 from '../images/sciencefair23-7.jpg';
import ScienceFair8 from '../images/sciencefair23-8.jpg';
import ScienceFair9 from '../images/sciencefair23-9.jpg';
import ScienceFair10 from '../images/sciencefair23-10.jpg';
import ScienceFair11 from '../images/sciencefair23-11.jpg';
import ScienceFair12 from '../images/sciencefair23-12.jpg';
import ScienceFair13 from '../images/sciencefair23-13.jpg';
import ScienceFair15 from '../images/sciencefair23-15.jpg';
import ScienceFair16 from '../images/sciencefair23-16.jpg';
import ScienceFair17 from '../images/sciencefair23-17.jpg';
import ScienceFair18 from '../images/sciencefair23-18.jpg';
import ScienceFair19 from '../images/sciencefair23-19.jpg';
import ScienceFair20 from '../images/sciencefair23-20.jpg';
import ScienceFair21 from '../images/sciencefair23-21.jpg';
import ScienceFair22 from '../images/sciencefair23-22.jpg';
import ScienceFair23 from '../images/sciencefair23-23.jpg';
import ScienceFair24 from '../images/sciencefair23-24.jpg';
import ScienceFair25 from '../images/sciencefair23-25.jpg';
import ScienceFair26 from '../images/sciencefair23-26.jpg';
import ScienceFair27 from '../images/sciencefair23-27.jpg';
import Poster from '../images/ScienceFair23-Poster.png';
import Video1 from '../videos/sciencefair23-video-mute-1.mp4';
import Video2 from '../videos/sciencefair23-video-mute-2.mp4';



const ScienceFair2023 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"Rebel Cup Bonus Points"} />
            <div className="badgePageItem">Rebel Cup challenges are only posted in the facebook group for confirmed members. You will have to join the club to find out why I am doing these things!</div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle silver">Science Fair</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo silver-light">Complete one of 15 science projects from any of three different levels or an expert level of your own choice</div>
                    <div className="badgePageItemEvidence"> 
                        <p>For science fair, I went with the Robotic Hand option. I have recently done my scientist badge so was looking for something a little more involved / challenging. This was certainly that!</p>
                        <br/><p>I found a tutorial for a cardboard version, which seemed the simplest idea. However, it had no printables or dimensions etc. with it which didn't help, fortunately I was able to find some pattern pieces from someone else who had made a similar unit.</p>
                        <br/><p>I started by cutting the pattern, laying it out on the cardboard and cutting out each piece. Fortunately I had just had a large Amazon delivery, so I had a perfect box to cut up!</p>
                        <br/>
                        <div className='gallery'>
                            <div className='galleryItem'><img src={ScienceFair1} alt="Paper, syringes, plastic tube and tools laid out on a floor" /></div>
                            <div className='galleryItem'><img src={ScienceFair2} alt="Paper pattern laid out on cardboard ready for cutting" /></div>
                            <div className='galleryItem'><img src={ScienceFair3} alt="Paper pattern pieces cut our of cardboard" /></div>
                        </div>
                        <br/>
                        <p>Next, it was time to start assembling. I was using skewers to provide the moveable joints and hot glue to stick the bits that needed sticking.</p>
                        <br/>
                        <div className='gallery'>
                            <div className='galleryItem'><img src={ScienceFair4} alt="Pieces of cardboard with syringes places in between and skewers poked through to hold them together" /></div>
                            <div className='galleryItem'><img src={ScienceFair5} alt="Cardboard pieces assemble into a robotic arm shape" /></div>
                            <div className='galleryItem'><img src={ScienceFair6} alt="Cardboard piece assemble into a robotic arm shape" /></div>
                        </div>
                        <br/>
                        <p>Then, the syringes needed to be prepared by drilling holes into the necks and fastening connections on the bottoms. Unfortunately I didn't have cable ties, so I used a combination of wire and metal jump rings to attach them to the cardboard pieces instead.</p>
                        <div className='gallery'>
                            <div className='galleryItem'><img src={ScienceFair7} alt="A syringe with a hand drill making a hole in the neck" /></div>
                            <div className='galleryItem'><img src={ScienceFair8} alt="A robotic arm with syringes in place" /></div>
                       </div>
                       <br/>
                       <p>Next up, I needed to make and attach the hand. After my initiall attempt, I did double up the cardboard pieces to make it a little stronger.</p>
                       <div className='gallery'>
                            <div className='galleryItem'><img src={ScienceFair9} alt="Cardboard pieces laid out to make a pincer" /></div>
                            <div className='galleryItem'><img src={ScienceFair10} alt="A cardboard pincer piece attached to the robotic arm" /></div>
                       </div>
                       <br/>
                       <p>Finally, it was time to create the swivel base and attach the arm to it, along with the final syringe that will drive the turning mechanism.</p>
                       <div className='gallery'>
                            <div className='galleryItem'><img src={ScienceFair11} alt="Two pieces of cardboard with a battery in the middle to form a turning platform" /></div>
                            <div className='galleryItem'><img src={ScienceFair12} alt="A cardboard robotic arm placed on to a swivel base" /></div>
                            <div className='galleryItem'><img src={ScienceFair13} alt="A syringe fitted to the swivel base of a cardboard robotic arm" /></div>
                       </div>
                       <br/>
                       <p>Next up, I needed to put the controls together. There was no pattern for this so I made it up based on pictures I had seen. I used lolly sticks with cardboard spacers, the syringes had holes drilled through the necks and I used pieces of wooden skewers to provide the moveable parts.</p>
                       <div className='gallery'>
                            <div className='galleryItem'><img src={ScienceFair15} alt="Lolly sticks attached to syringes using skewers and cardboard spacers" /></div>
                            <div className='galleryItem'><img src={ScienceFair16} alt="Syringes attached to swivelling frame" /></div>
                       </div>
                       <br/>
                       <p>The last step was to connect all the syringes with plastic tubing filled with coloured water. I used food colouring for this. It took a bit of experimentation to get the right water levels.</p>
                       <div className='gallery'>
                            <div className='galleryItem'><img src={ScienceFair17} alt="A cardboard robotic arm on a table with a glass of water coloured with blue food colouring" /></div>
                            <div className='galleryItem'><img src={ScienceFair18} alt="A hydrualic robotic arm made from cardboard on a table with syringe controls" /></div>
                       </div>
                       <br/>
                       <p>I decided that I wasn't sure whether I had done the right thing to meet the clause of the badge, so I designed a robotic hand and attached that in place of the pincer. I started with string to pull the fingers, but this was much too big and not firm enough to provide the correct control when attached to the arm. So, I re-threaded using a thin metal wire. This was probably too thin, but it worked well enough. I had to fix the position of the hand, ultimately turning it to an angle so that it could effectively grip a can.</p>
                       <div className='gallery'>
                            <div className='galleryItem'><img src={ScienceFair19} alt="Cardboard cut into the shape of a hand" /></div>
                            <div className='galleryItem'><img src={ScienceFair20} alt="Cardboard cut into the shape of a hand with plastic pieces attached to enable bending" /></div>
                            <div className='galleryItem'><img src={ScienceFair21} alt="Cardboard cut into the shape of a hand with plastic pieces attached to enable bending" /></div>
                            <div className='galleryItem'><img src={ScienceFair22} alt="Cardboard cut into the shape of a hand with plastic pieces attached and string threaded through" /></div>
                            <div className='galleryItem'><img src={ScienceFair23} alt="Cardboard cut into the shape of a hand with plastic pieces attached and string threaded through" /></div>
                            <div className='galleryItem'><img src={ScienceFair24} alt="Cardboard cut into the shape of a hand with plastic pieces attached and wire threaded through" /></div>
                            <div className='galleryItem'><img src={ScienceFair25} alt="Wires being attached to a syringe to form a pulling mechanism for a cardboard hand" /></div>
                       </div>
                       <p>The final piece</p>
                       <br/>
                       <div className='gallery'>
                            <div className='galleryItem'><img src={ScienceFair26} alt="A cardboard robotic hand gripping a can" /></div>
                            <div className='galleryItem'><img src={ScienceFair27} alt="A cardboard robotic hand gripping a can" /></div>
                       </div>
                       <br/>
                       <p>Videos of both versions working.</p>
                       <video width="1024" height="768" controls>
                            <source src={Video1} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <br/>
                        <video width="1024" height="768" controls>
                            <source src={Video2} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                       <br/>
                       <p>The poster that I prepared for the group</p>
                       <img src={Poster} alt="A poster about this science fair project" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScienceFair2023