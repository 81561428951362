import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Menu from '../images/Mar24-ChocolateChallenge-Menu.png';
import Book1 from '../images/Mar24-ChocolateChallenge-Book1.png';
import Book2 from '../images/Mar24-ChocolateChallenge-Book2.png';
import Egg from '../images/Mar24-ChocolateChallenge-Egg.jpg';
import Truffles1 from '../images/Mar24-ChocolateChallenge-Truffles1.jpg';
import Truffles2 from '../images/Mar24-ChocolateChallenge-Truffles2.jpg';
import Truffles3 from '../images/Mar24-ChocolateChallenge-Truffles3.jpg';
import Truffles4 from '../images/Mar24-ChocolateChallenge-Truffles4.jpg';
import Truffles5 from '../images/Mar24-ChocolateChallenge-Truffles5.jpg';
import Truffles6 from '../images/Mar24-ChocolateChallenge-Truffles6.jpg';
import Truffles7 from '../images/Mar24-ChocolateChallenge-Truffles7.jpg';
import Truffles8 from '../images/Mar24-ChocolateChallenge-Truffles8.jpg';
import Paint1 from '../images/Mar24-ChocolateChallenge-Paint1.jpg';
import Paint3 from '../images/Mar24-ChocolateChallenge-Paint3.jpg';
import Paint4 from '../images/Mar24-ChocolateChallenge-Paint4.jpg';
import Paint5 from '../images/Mar24-ChocolateChallenge-Paint5.jpg';
import Paint6 from '../images/Mar24-ChocolateChallenge-Paint6.jpg';
import Paint7 from '../images/Mar24-ChocolateChallenge-Paint7.jpg';
import Paint8 from '../images/Mar24-ChocolateChallenge-Paint8.jpg';
import Paint9 from '../images/Mar24-ChocolateChallenge-Paint9.jpg';
import Paint10 from '../images/Mar24-ChocolateChallenge-Paint10.jpg';
import Paint11 from '../images/Mar24-ChocolateChallenge-Paint11.jpg';
import Paint12 from '../images/Mar24-ChocolateChallenge-Paint12.jpg';


const  March24 = () => {
    return (
        <div>
            <ActionBar area="extras" />
            <EvidenceHeader title={"You've got Rebel mail"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Learn about the differences between fair trade and direct trade</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>Fair trade provides protection whereas Direct Trade promotes aspiration and relationships that focus on quality.</p>
                        <br/>
                        <ol className='list'>
                            <li>Fair Trade
                                <ul className='list'>
                                    <li>Objective: Fair Trade aims to alleviate poverty and promote social justice by ensuring fair wages, ethical business practices, and environmental guidelines.</li>
                                    <li>Certification: It provides a certification system with clear guidelines. When you buy Fair Trade products, you support farmers who adhere to these standards.</li>
                                    <li>Minimum Price: Fair Trade sets a minimum price for products, which protects farmers during market downturns.</li>
                                    <li>Cooperatives: It primarily works with farmers' cooperatives, allowing them to negotiate collectively for better prices.</li>
                                    <li>Fixed Price: The fixed price is guaranteed to the cooperative, but not necessarily to individual small-scale farmers or their workers.</li>
                                    <li>Quality Independence: The price remains unaffected by the quality of the product, which may not incentivize farmers to improve crop quality.</li>
                                    <li>Certification Costs: Becoming Fair Trade certified can be expensive for producer organizations.</li>
                                    <li>Marketing Costs: Roasters may bear the cost of marketing the Fair Trade brand.</li>
                                    <li>Protection: Fair Trade emphasizes protection for farmers.</li>
                                </ul>
                            </li>
                            <li>Direct Trade
                                <ul className='list'>
                                    <li>Objective: Direct Trade focuses on building sustainable, long-term relationships between roasters and farmers.</li>
                                    <li>Quality-Centric: It prioritizes high-quality beans and encourages collaboration in the field.</li>
                                    <li>Personal Agreements: Prices are personally agreed upon, covering production costs and offering premiums for quality.</li>
                                    <li>No Certification Fees: Farmers do not incur certification costs.</li>
                                    <li>Incentive for Quality: Farmers are motivated to invest in labor and inputs to grow superior crops.</li>
                                    <li>Spotting Needs: Working directly with farmers allows identifying community needs for better farming practices.</li>
                                    <li>Aspiration: Direct Trade offers aspiration for farmers to improve and excel.</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Read a chocolate-related book</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>For this clause I borrowed the book "Chocolate - The Nature of Indulgence" from the OpenLibrary. </p>
                        <br/>
                        <img src={Book1} alt="" className='imgThird'/>&nbsp;<img src={Book2} alt="" className='imgThird' />
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Design a three course menu where every course includes chocolate</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>Here is my menu:</p>
                        <br/>
                        <img src={Menu} alt="A4 menu" className='imgThird' />
                        <br/>
                        <p>Recipe: https://www.allrecipes.com/recipe/270196/chocolate-hummus</p>
                        <p>Recipe: https://www.tasteofhome.com/recipes/chocolate-molasses-pork-roast</p>
                        <p>Recipe: https://www.allrecipes.com/recipe/25095/nanaimo-bars-iii</p>
                        <p>Recipe: https://www.bbcgoodfood.com/recipes/jackos-chocolate-orange</p>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Donate a large chocolate egg or rabbit to a food bank</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>Large Twix egg donated to the local food bank.</p>
                        <br/>
                        <img src={Egg} alt="Twix Easter Egg" className='imgThird' />
                        <br/>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Make Chocolate Truffles with your own choice of flavour</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>For this clause, I made dark chocolate truffles - some plain, some with peppermint flavouring - and white chocolate and pistachio truffles.</p>
                        <p>The dark chocolate truffles worked well, but unfortunately weren't a hit with the family as they didn't like the dark chocolate.</p>
                        <p>The white chocolate truffles were more like white chocolate splats as they didn't set properly :( </p>
                        <br/>
                        <div className="gallery">
                            <div className='galleryItem'><img src={Truffles1} alt="A Tesco finest dark chocolate bar" /></div>
                            <div className='galleryItem'><img src={Truffles2} alt="Dark chocolate being melted in a bowl on a hob" /></div>
                            <div className='galleryItem'><img src={Truffles3} alt="Melted chocolate in small bowls waiting to cool and set" /></div>
                            <div className='galleryItem'><img src={Truffles4} alt="White chocolate being melted in a bowl on a hob" /></div>
                            <div className='galleryItem'><img src={Truffles5} alt="Melted white chocolate in a bowl waiting to cool and set" /></div>
                            <div className='galleryItem'><img src={Truffles6} alt="Truffles being formed and laid out on a baking sheet" /></div>
                            <div className='galleryItem'><img src={Truffles7} alt="Dark chocolate truffles" /></div>
                            <div className='galleryItem'><img src={Truffles8} alt="White chocolate truffles with pistachios" /></div>
                        </div>
                        <br/>
                        <p>Can't win them all!</p>
                        <br/>
                    </div>
                </div>
            </div>
            <div className="badgePageItem">
                <div className="badgePageItemTitle brown">Paint a picture on a slab of chocolate - all colours and shapes should be made from difference chocolates and sweets</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo brown-light"></div>
                    <div className="badgePageItemEvidence"> 
                        <p>My chocolate painting:</p>
                        <ul className='list'>
                            <li>Canvas = White Chocolate bar</li>
                            <li>Starwberry mushrooms cut in half</li>
                            <li>The grass is green Nerds</li>
                            <li>The pink flowers are sliced bubblegum spears - perfectly shaped for tiny flowers</li>
                            <li>The butterfly is made from the mushrooms</li>
                            <li>The blue sky was done using the colour from crushed candy necklace "beads",soaked in water</li>
                            <li>The green was a combe of the colouring from green skittles and green candy necklace "beads", soaked in water</li>
                            <li>The sun was entirely from the colouring of yellow skittles</li>
                        </ul>
                        <br/>
                        <div className='gallery'>
                            <div className='galleryItem'><img src={Paint1} alt=""/></div>
                            <div className='galleryItem'><img src={Paint3} alt=""/></div>
                            <div className='galleryItem'><img src={Paint4} alt=""/></div>
                            <div className='galleryItem'><img src={Paint5} alt=""/></div>
                            <div className='galleryItem'><img src={Paint6} alt=""/></div>
                            <div className='galleryItem'><img src={Paint7} alt=""/></div>
                            <div className='galleryItem'><img src={Paint8} alt=""/></div>
                            <div className='galleryItem'><img src={Paint9} alt=""/></div>
                            <div className='galleryItem'><img src={Paint10} alt=""/></div>
                            <div className='galleryItem'><img src={Paint11} alt=""/></div>
                            <div className='galleryItem'><img src={Paint12} alt=""/></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default March24