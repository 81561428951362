import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Book1 from '../images/Entrepreneur-Book1.jpg';
import Book2 from '../images/Entrepreneur-Book2.jpg';
import Podcast1 from '../images/Entrepreneur-Podcast1.jpg';
import Podcast2 from '../images/Entrepreneur-Podcast2.jpg';
import Podcast3 from '../images/Entrepreneur-Podcast3.jpg';
import Podcast4 from '../images/Entrepreneur-Podcast4.jpg';
import Podcast5 from '../images/Entrepreneur-Podcast5.jpg';
import Podcast6 from '../images/Entrepreneur-Podcast6.jpg';
import Podcast7 from '../images/Entrepreneur-Podcast7.jpg';
import Palette from '../images/Entrepreneur-ColourPalette.png';
import Accessibility1 from '../images/Entrepreneur-Accessibility1.png';
import Accessibility2 from '../images/Entrepreneur-Accessibility2.png';
import Accessibility3 from '../images/Entrepreneur-Accessibility3.png';
import Accessibility4 from '../images/Entrepreneur-Accessibility4.png';
import Accessibility5 from '../images/Entrepreneur-Accessibility5.png';
import Logo1 from '../images/Entrepreneur-Logo1.png';
import Logo2 from '../images/Entrepreneur-Logo2.png';

const Entrepreneur = () => {
    return (
        <div>
            <ActionBar />
            <EvidenceHeader title={"Entrepreneur"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">01</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Brainstorm</div>
                    <div className="badgePageItemInfo reverse hidden">Brainstorm at least 5 different business ideas, choose your favourite</div>
                    <div className="badgePageItemEvidence">
                        <p>This clause is largely backdated as I have been through this process several times across the years!  I have:</p>
                        <ul classname="list">
                            <li>Escap-e : a service business for virtual administration, now acts as an umbrella company for work that I do for anyone that requires no or small payment</li>
                            <li>Paper Angel: a service business for providing paperwork services for small businesses and sole traders who can't afford to employ an administrator.I set this up but it's no longer in use.</li>
                            <li>Gifted Cards: a product business for selling hand-made craft products.  This is currently active, but not trading - in process of re-branding.</li>
                            <li>Moments and Moxie: a product business for sharing artwork and selling photographic prints through picfair. Currently active and pending first sale.</li>
                            <li>Turned Up Tix: a service business aiming to make access to theatre and other arts events easier through pay it forward services.</li>
                        </ul>
                        <br/>
                        <p>For the purposes of the badge, I will be going forward with Turned Up Tix, as it's the one business that I haven't as yet fully developed.</p>
                        <br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">02</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Create</div>
                    <div className="badgePageItemInfo reverse hidden">Create a brand name, logo and colour palette.</div>
                    <div className="badgePageItemEvidence">
                        <p><strong>Brand Name</strong></p>
                        <br/>
                        <p>The Brand Name is “Turned Up Tix” - this is a play on words in a couple of different ways</p>
                        <ul className="list">
                            <li>Turned Up - in effect that the tickets turned up , “turn up - be found, especially by chance, to arrive or appear somewhere, usually unexpectedly or in a way that was not planned”. Oxford Languages, Cambridge Dict.</li>
                            <li>Turnt Up - slang, “A state of euphoria brought about by having an extremely good time, the act of dancing excitedly to popular music, an energetic feeling brought on by upbeat music, highly felt emotions or positive events”. Urban Dict.</li>
                        </ul>
                        <p>It's meant to imply that you turned up tickets for something that you couldn't previously acquire, bringing a feeling of excitement.</p>
                        <br/><br/>
                        <p><strong>Colour Palette</strong></p>
                        <br/>
                        <table>
                            <thead>
                                <tr>
                                    <th><strong>Colour Name</strong></th>
                                    <th><strong>Hex Code</strong></th>
                                    <th><strong>RGB</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Oxford Blue</td>
                                    <td>#05062D</td>
                                    <td>5, 6, 45</td>
                                </tr>
                                <tr>
                                    <td>Violet</td>
                                    <td>#51214F</td>
                                    <td>81, 33, 79</td>
                                </tr>
                                <tr>
                                    <td>Burnt Sienna</td>
                                    <td>#DC7B6A</td>
                                    <td>220, 123, 106</td>
                                </tr>
                                <tr>
                                    <td>Icterine</td>
                                    <td>#F9F871</td>
                                    <td>249, 248, 113</td>
                                </tr>
                                <tr>
                                    <td>Ghost White</td>
                                    <td>#E8E9F3</td>
                                    <td>232, 233, 243</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                        <p>Representation of palette courtesy of Coolors.co </p>
                        <img src={Palette} alt="Five colour palette" className="imgThird" />
                        <br/><br/>
                        <p>The palette is warm and strong, allowing for good contrast between text and colour blocks. All colours pass accessibility tests to Level AAA with either black or white text, tested and confirmed with the WebAIM Contrast Checker. </p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Accessibility1} alt="Accessibility score for colour Oxford Blue" /></div>
                            <div className="galleryItem"><img src={Accessibility2} alt="Accessibility score for colour Violet" /></div>
                            <div className="galleryItem"><img src={Accessibility3} alt="Accessibility score for colour Burnt Sienna" /></div>
                            <div className="galleryItem"><img src={Accessibility4} alt="Accessibility score for colour Icterine" /></div>
                            <div className="galleryItem"><img src={Accessibility5} alt="Accessibility score for colour Ghost White" /></div>
                        </div>
                        <br/><br/>
                        <p><strong>Logo</strong></p>
                        <br/>
                        <p>The first iteration of the logo was a text only representation:</p>
                        <img src={Logo1} alt="Turned Up Tix" className="imgThird" />
                        <br/>
                        <p>I decided it needed a better and more eye-grabbing alternative, particularly as social media channels work better when you have round logos, or logos that fit in circles.</p>
                        <br/>
                        <p>I had a play around and came up with this version that uses the colour palette.</p>
                        <img src={Logo2} alt="Turned Up Tix" className="imgThird" />
                        <br/>
                        <p>It'll probably go through various iterations, but for now, this is the one.</p>
                        <br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">03</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Plan.</div>
                    <div className="badgePageItemInfo reverse hidden">Write a basic business plan.</div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">04</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Brand research</div>
                    <div className="badgePageItemInfo reverse hidden">Research your brand name.</div>
                    <div className="badgePageItemEvidence">
                        <p><strong>Which trademark classes would you need to file for</strong></p>
                        <p>https://www.search-uk-trade-mark-classes.service.gov.uk/searchclasses</p>
                        <ul className="list">
                            <li>potentially Class 16 for printed tickets</li>
                            <li>Class 41 - reservation, booking, agency services</li>
                        </ul>    
                        <br/><br/>
                        <p><strong>Check the TM register - are there any existing trademarks which you might be infringing?</strong></p>
                        <p>I searched the register for the keyword “tix” and Class 41 which returned 5 results:</p>
                        <ul className="list">
                            <li>UK00001381092, Atac-Tix, Adventure game services</li>
                            <li>UK00001381082, Atac-Tix, Adventure game services</li>
                            <li>UK00003157712, TIXSE, Ticket information services</li>
                            <li>UK00003338691, KATIA TXI, Courses, publications, apps, downloadable, online</li>
                            <li>UK00003338691, KATIA TXI, Courses, publications, apps, downloadable, online</li>
                        </ul>
                        <br/>
                        <p>I don't believe there are any trademarks that I would be infringing.</p>
                        <br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">05</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Register</div>
                    <div className="badgePageItemInfo reverse hidden">Find out how you would register a limited company with Companies House.</div>
                    <div className="badgePageItemEvidence">
                        <p>You can register a company through Gov.uk, here:</p>
                        <p>https://www.gov.uk/limited-company-formation/register-your-company</p>
                        <br/><br/>
                        <p>It costs £50 to register a company and it is usually registered within 24 hours.</p>
                        <p>To exclude the word “limited” from the company name, you have to register via post!</p>
                        <p> You need the following information about yourself and any shareholders or guarantors:</p>
                        <ul className="list">
                            <li>Town of birth</li>
                            <li>Mother's maiden name</li>
                            <li>Father's first name</li>
                            <li>Telephone number</li>
                            <li>National insurance number</li>
                            <li>Passport number</li>
                        </ul>
                        <br/><br/>
                        <p>Steps available in the Gov.uk service:</p>
                        <ol className="list">
                            <li>Check if setting up a limited company is right for you</li>
                            <li>Choose a name</li>
                            <li>Choose directors and a company secretary</li>
                            <li>Decide who the shareholders or guarantors are and identiy with with significant control (PSC) over your company</li>
                            <li>Prepare documents agreeing how to run your company</li>
                            <li>Check what records you'll need to keep</li>
                            <li>Register your company</li>
                        </ol>
                        <br/><br/>
                        <p>I am not planning to register a limited company through this service.</p>
                        <br/><br/>                    
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">06</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Market research</div>
                    <div className="badgePageItemInfo reverse hidden">Carry out market research.</div>
                    <div className="badgePageItemEvidence"></div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">07 - Task 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Prototype</div>
                    <div className="badgePageItemEvidence">
                        <p>Not applicable.</p>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">07 - Task 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Training</div>
                    <div className="badgePageItemEvidence">
                        <p>Not applicable.</p>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">07 - Task 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Podcasts</div>
                    <div className="badgePageItemEvidence">
                        <p>For this clause, I listened to a selection of podcast episodes:</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Podcast1} alt="Tips to get you started as an entrepreneur" /></div>
                            <div className="galleryItem"><img src={Podcast2} alt="6 tips on being a successful entrepreneur" /></div>
                            <div className="galleryItem"><img src={Podcast3} alt="From Zero to Business Idea" /></div>
                            <div className="galleryItem"><img src={Podcast4} alt="5 rules of success - Best Entrepreneur advice" /></div>
                            <div className="galleryItem"><img src={Podcast5} alt="The Entrepreneur Experiment Diary" /></div>
                            <div className="galleryItem"><img src={Podcast6} alt="10 Trends you can use to start a business" /></div>
                            <div className="galleryItem"><img src={Podcast7} alt="I gave an entrepreneur £8000" /></div>
                        </div>
                        <br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">07 - Task 4</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Reading</div>
                    <div className="badgePageItemEvidence">
                        <p>For this clause, I read this book:</p>
                        <br/>
                        <div className="gallery">
                            <div className="galleryItem"><img src={Book1} alt="Front cover of a book titled Entrepreneurship by Paul Verghese" /></div>
                            <div className="galleryItem"><img src={Book2} alt="Back cover of a book titled Entrepreneurship by Paul Verghese" /></div>
                        </div>
                        <br/><br/>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">07 - Task 5</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Sell</div>
                    <div className="badgePageItemEvidence">
                       <p>Not applicable.</p>
                    </div>
                </div>
            </div>

            <div className="badgePageItem">
                <div className="badgePageItemTitle blue">07 - Task 6</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo blue-light">Brand domains and handles</div>
                    <div className="badgePageItemEvidence">
                        <p>I own the web domain www.turneduptix.org.uk</p>
                        <p>I also own the Twitter and Instagram handles @turneduptix</p>
                        <p>I also own the Facebook page Turned Up Tix /turneduptix</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Entrepreneur