import React from 'react';
import ActionBar from '../components/actionbar';
import EvidenceHeader from '../components/evidenceheader';
import Scrapbook from '../documents/RebelMusicLover-Scrapbook.pdf';
import Clipping1 from '../images/MusicLover-Clipping1.png';
import Clipping2 from '../images/MusicLover-Clipping2.png';
import Clipping3 from '../images/MusicLover-Clipping3.png';
import Clipping4 from '../images/MusicLover-Clipping4.png';
import Clipping5 from '../images/MusicLover-Clipping5.png';
import Clipping6 from '../images/MusicLover-Clipping6.png';
import Clipping7 from '../images/MusicLover-Clipping7.png';
import Country1 from '../images/MusicLover-Country1.png';
import Country2 from '../images/MusicLover-Country2.png';
import Country3 from '../images/MusicLover-Country3.png';
import Chiptune1 from '../images/MusicLover-Chiptune1.png';
import Chiptune2 from '../images/MusicLover-Chiptune2.png';
import Chiptune3 from '../images/MusicLover-Chiptune3.png';

const MusicLover = () => {
    return (
        <div>
            <ActionBar area={"booktwo"} />
            <EvidenceHeader title={"Music Lover"} area={"booktwo"} />
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 1</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>I have a very wide range musical interests and a very eclectic playlist! My longest running love is rock music, so I wanted to stay away from that and look at something different. I've been immersed in folk since an early age and of course the pop of my school days. </p>
                        <br/><p>Later in life I have embraced more country, I love catchy songs that I can sing along to and country very often fulfils that desire! So that was my first choice.</p>
                        <br/><p>In the last few years I have developed more my love for hip hop and rap. One thing I never envisaged myself listening to was noise rap, horrorcore, industrial rap. However, I now do, in a limited way. So for my second genre I chose this, to explore it further.</p>
                        <br/><br/>
                        <p>I started this whole clause by asking Bing what the top 10 most populare genres were. This is what it said:</p>
                        <br/>
                        <p>Q: what are the top 10 most popular genres of music currently?
                        <br/>A:According to a recent article by Prime Sound, the following are the top 10 most popular music genres globally:
                            <ul className='list'>
                                <li>Pop</li>
                                <li>Rock</li>
                                <li>Hip-hop/Rap</li>
                                <li>Electronic Dance Music (EDM)</li>
                                <li>Country</li>
                                <li>Jazz</li>
                                <li>Classical</li>
                                <li>R&B/Soul</li>
                                <li>Indie/Alternative</li>
                                <li>Latin</li>
                            </ul>
                        </p>
                        <br/><br/>
                        <p>My first two chosen genres fit in there. Next, I asked it to tell me about lesser known genres, this is what it said:</p>
                        <br/>
                        <p>Q: What are some lesser known or less popular genres of music?
                        <br/>A: 
                            <ol className='list'>
                                <li>Shoegaze -  This genre of music originated in the UK in the late 1980s and is characterized by its ethereal soundscapes, distorted guitar effects, and hazy vocals. Some popular shoegaze bands include My Bloody Valentine, Slowdive, and Lush </li>
                                <li>Chiptune - Chiptune, also known as 8-bit music, is a genre of electronic music that is created using sound chips from vintage computers, video game consoles, and arcade machines. Some popular chiptune artists include Anamanaguchi, Sabrepulse, and Bit Shifter</li>
                                <li>Gypsy Jazz - Gypsy jazz, also known as “jazz manouche,” is a style of music that originated in France in the 1930s. It is characterized by its fast tempos, virtuosic guitar playing, and the use of the violin as a lead instrument. Some popular gypsy jazz artists include Django Reinhardt, Stéphane Grappelli, and Biréli Lagrène</li>
                                <li>Math Rock: Math rock is a genre of rock music that is characterized by its complex rhythms, unusual time signatures, and intricate guitar work. Some popular math rock bands include Don Caballero, Battles, and Hella</li>
                                <li>Neoclassical Metal: Neoclassical metal is a subgenre of heavy metal that incorporates elements of classical music, such as complex guitar solos, symphonic arrangements, and virtuosic playing. Some popular neoclassical metal artists include Yngwie Malmsteen, Jason Becker, and Tony MacAlpine</li>
                            </ol>
                            <br/>
                            <p>I have vaguely heard the term “shoegaze” before but couldn't have defined it, and I definitely hadn't heard of the others!  </p>
                            <br/>
                            <p>I was curious about Chiptune, so I went with that for my third genre.</p>
                        </p>
                        <br/>
                        <br/>
                        <div className="genreSection">
                            <h2>Genre 1: Country Music</h2>
                            <br/>
                            <p>The early 20th century saw the rise of country music in areas of the South and West of America. Originally labelled “hillbilly music”, it was renamed in 1949 by the recording industry to “country and western music” as the term “hillbilly” was seen as derogatory.
                            <br/><br/>Whilst considered to be American, the roots of the music actually came from the songs of the English, Scottish and Irish people who settled in the Appalachians and southern areas.
                            <br/><br/>In the early 1920s, the first string-band music of the southern mountains was  commerically recorded - the first hit record was Fiddlin' John Carson.
                            <br/><br/>The music brought liveliness and realism to the otherwise sentimental popular music of the time.
                            <br/><br/>Radio stations helped to spread the popularity of Country music, particularly to white, rural audiences.
                            <br/><br/>Two of the most influential shows were the “National Barn Dance”, Chicago 1924 and the “Grand Ole Opry”, Nashville, 1925. These encouraged recordings and appearances by talented musicians, including the Carter Family and Jimmie Rodgers who had a string influence on later musicians.
                            <br/><br/>It has evolved a lot over the years and has split into several sub-genres, such as country rock and bluegrass. As the exposure continued, country music was exposed to gospel and blues. In 1930s Oklahoma it evolved into a western swing style with steel and amplified guitars and a dance rhythm after being exposed to swing jazz. In the 1940s another sub-genre to arise was honky-tonk, which had a steel guitar and fiddle combination with bitter lyrics about “rural whites adrift in the big city”. 
                            </p>
                            <br/>
                            <p>In the 1940s, Nashville became the centre of Country music, largely helped by Hank Williams. The Grand Ole Opry was the main performance venue. </p>
                            <p>In the 50s and 60s, it became much more commericalised. Popular artists used a Nashville style whilst others used big orchestral arrangements. Popular artists of this time included Johnny Cash, Tammy Wynette, Patsy Cline and Loretta Lynn.</p>
                            <br/><br/>
                            <p>In the 1970s the music became more urban, using electric guitars and saw the rise of “outlaw” music from Willie Nelson and Waylon Jennings.</p>
                            <br/><p>The late 20th Century saw the rise of Dolly Parton, Garth Brooks and Emmylou Harris and continued into the 21st Century with Carrie Underwood, Miranda Lambert, and Kenny Chesney, among many others.</p>
                            <br/><br/>
                            <p>I have  a lot of Sugarland on my music list - there's a story there, but it's not for here!  I like the music and it plays often.  I have been really fortunate to see these artists live in concert locally:
                                <br/>
                                <ul className='list'>
                                    <li>Canaan Smith - American country singer / songwriter</li>
                                    <li>Sarah Darling - American country singer / songwriter</li>
                                    <li>Ward Thomas - English modern country-pop</li>
                                    <li>The Shires - British country music</li>
                                    <li>Kiefer Sutherland - American country</li>
                                </ul> 
                            </p>
                            <br/>
                            <p>More recently, I have come across these artists on Instagram and added them to my spotify playlists:
                            <br/>
                            <ul className='list'>
                                <li>Taylor Goyette</li>
                                <li> Tigirlily Gold</li>
                                <li>Chase McDaniel</li>
                            </ul>
                            <br/>
                            <p>All of these are also in regular rotation on my playlists. </p>
                            <br/><br/>
                            Source: https://www.britannica.com/art/country-music
                            </p>
                        </div>
                        <br/><br/>
                        <div className="genreSection">
                            <h2>Genre 2: Chiptune</h2>
                            <br/>
                            <p>Chiptune is a style of music that is made using the sounds chips and synthesisers from old arcade games, computers and video game consoles. It is also known as chip music, 8-bit or 16-bit music.</p>
                            <br/><p>Any music can be arranged in chiptune style, making it a genre interpretor!</p>
                            <br/><p>In the 1950s programmers would experiment with their machines' sound capabilities. This included the CSIRAC and Ferranti Mark 1. It eventually became popular in the video game industry, especially in Japan where game soundtracks - like Super Mario Bros for example - were composed.</p>
                            <br/><p>Chiptune first emerged in the 70s and 80s when the music used in video and computer games was limited by the hardware. It was influenced largely by progressive rock, synth-pop and soundtrack music.</p>
                            <br/><p>A subculture developed around chiptune, where people used a software tools called trackers to sample the chip sounds and create their own music. Trackers let you vary the pitch, volume and duration of the sound and allow you to arrange sounds into patterns and sequences.</p>
                            <br/><p>As new technologies emerge chiptune continue to evolve, with artists using tools like hardware emulators and circuit bending. </p>
                            <br/><p>It has also influenced other styles of music, such as 
                            <br/>
                                <ul className='list'>
                                    <li>Bitpop - chiptune combined with pop</li>
                                    <li>Nintendocore  - chiptune with hardcore punk and metal</li>
                                    <li>Hyperpop - chiptune with experimental pop and electronic music </li>
                                </ul>
                            </p>
                            <br/>
                            <p>From the 1980s to the 2000s it was less popular, but started making a comeback to popularity when artists such as Beck, The Killers and No Doubt used it in their tracks. More recently, video game beats have been used by Kesha - in TikTok -, 50 Cent - in Ayo Technology - Snoop Dogg, Eminem, Nelly Furtado and Timbaland.</p>
                            <br/>
                            <p>For 2024, the top 5 artists are:
                                <br/>
                                <ol className='list'>
                                    <li>Dubmood</li>
                                    <li>Ozzed</li>
                                    <li>Zabutom</li>
                                    <li>Magnus Palsson</li>
                                    <li>Bossfight</li>
                                </ol>
                            <br/><br/>
                            Source: https://www.soundmaximum.com/chiptune-artists/ 
                            </p>
                            <br/><p>I have quite enjoyed listening to this music. It is very much for listening, not watching, as it doesn't have the dynamism of, say, pop or rock concerts, but I can see myself listening to it as a motivational tool to keep me focussed when working.  The high-energy of the beats is great for background focus music.</p>
                        </div>
                        <br/><br/>
                        <div className="genreSection">
                            <h2>Genre 3: Experimental HipHop / Industrial Rap</h2>
                            <br/>
                            <p>During the pandemic, I gave in to the hype and watched the Hamilton musical on Disney+. Long story short, in following the cast and joining various online events, I found myself down a hip hop hole and loving it!</p>
                            <br/>
                            <p>Part of that was stumbling across Clppng, an experimental hip hop group consisting of three people, one of whom is Daveed Diggs, who played Lafayette and Jeffferson in Hamilton. Diggs raps over beats generated by William Hutson and Jonathan Snipes.</p>
                            <br/><br/>
                            <p>Now. If you'd asked me if I ever thought I'd be listening to noise rap, I would probably have laughed at you, but here we are! Clppng also have a horrorcore theme, with lyrics that can be dark and gruesome.</p>
                            <br/><p>Fun fact: the only track I always skip is one entitled “Burning Piano”. It is literally the sound of a piano on fire and it breaks my heart every time!! I'm sure the fact that I get upset by this but not by any of the other human focussed violence speaks volumes, but let's not go there right now!!</p>
                            <br/><p>Industrial Hip Hop, or Noise Rap, is a subgenre of hip hop and uses post-industrial, industrial and musique concrete beats and sounds, combining rock music, samplers and synthesizers. The lyrics are often disturbing or confrontational, sometimes shouted, and have dystopian, political or other industrial themes.</p>
                            <br/><p>It originated in the late 1980s and was pioneered by The Beatnigs in 1988 on their debut album, with Mark Stewart's album “As the Veneer of Democracy Starts to Fade” becoming a landmark record for industrial hip hop.  Originally inspired by attempts to combine industrial music with hardcore hip-hop and dub, Public Enemy inspired a darker form of hip hop.</p>
                            <br/><p>In the late 1990s and early 2000s it started to become more popular, with producers blending Industrial Rock and Electronic music. 2006 - 2008 saw mainstream hip-hop gaining traction again and the sub-genre lost its grip until 2010. </p>
                            <br/><br/>
                            <p>In 2010, a new wave of popularity hit with new artists rising up, including Death Grips Moodie Black, NAH and Clppng. Quote from a Vice article (linked below):
                            “The most notable new example though, has to be LA trio clipping., who not only push digital signals past their capabilities, distorting their representation, but also use obscure organic samples of household noise (water, glass, alarm clocks, etc.), delivered with such clinical rhythmic precision that it could sit quite comfortably on the radio next to any mainstream g-funk or trap record.” 
                            Kanye West's Yeezus album brought noise even more mainstream, bringing noise-hop to pop.</p>
                            <br/><br/>
                            <p>
                            Source: https://www.vice.com/en/article/65z5pz/the-evolution-of-noise-hop
                            <br/>Source: https://rateyourmusic.com/genre/industrial-hip-hop/
                            </p>
                        </div>
                    </div>
                </div>
            </div>   
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 2</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>See my <a href={Scrapbook} target="_blank" rel="noopener noreferrer" className="linkUnderline">scrapbook on Chiptune</a></p>
                    </div>
                </div>
            </div>          
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 3</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        <div className="genreSection">
                            <h2>Concert 1: Clppng. Live for no audience during a global pandemic </h2>
                            <p>https://www.youtube.com/watch?v=a2MCATB75C8</p>
                            <br/>
                            <img src={Clipping1} alt="A table shown against a white brick wall with a digital alarm clock and a hand-held microphone on the top" className='imgHalf'/>
                            <br/>
                            <p>This concert I was lucky enough to attend during the pandemic. It took place at around 4am UK time, so I was up and nursing a coffee whilst I was watching, then went back to bed for less than an hour before getting up for work!! Not sure how I imagined I was going to sleep after this!
                                <br/><br/>As you can see in the screenshot, there is an alarm clock - the alarm going off started the concert.I was well and truly awake at that point!
                                <br/><br/>The concert was streamed at Coaxial Arts. William and Jonathan stand with their equipment either side of Daveed Diggs, who is roaming with the mic.  Each track transitions flawlessly into the next without breaks. Despite the lack of audience, the performance is full of energy and everyone is having fun. The camera tracks Daveed, but also gives great close ups of the equipment being used, which I always find fascinating! You can see the group dynamic as they take cues from each other.
                            </p>
                            <img src={Clipping2} alt="Three performers facing the camera" className='imgThird'/>
                            <img src={Clipping3} alt="Three performers facing the camera" className='imgThird'/>
                            <br/>
                            <p>The live chat is flying, nothing but good things being said, and donations being made into the fundraiser.
                                <br/><br/>
                                Around halfway through, we appear to lose Daveed, who - having previous readied his hood - walks out into a dark, rainy alleyway, where huge puddles sit on the ground. The camera follows at a distance and music continues to play in the background.  A few minutes pass, Daveed walks back towards the camera, starting up the next song as he moves back into the main performance area. 
                                <br/><br/>
                                <img src={Clipping4} alt="An alleyway on a rainy night, lit by street lights, with a person crouched down with their back against a wall" className="imgThird"/><img src={Clipping5} alt="An alleyway on a rainy night, lit by street lights, with a person wearing a black hoodie walking towards the camera" className="imgThird"/>
                            </p>
                            <br/>
                            <p>Another moment saw all the lights turned off, with just a camera light shining at Daveed and the lights on the equipment glowing.
                                <br/>
                                <img src={Clipping6} alt="technical equpiment shown glowing in the dark" className="imgThird"/>
                            </p>
                            <br/>
                            <p>It's all over too soon, a captivating performance during dark times. More please!
                                <br/>
                                <img src={Clipping7} alt="The end credits of a live streamed concert" className="imgThird"/>
                            </p>
                        </div>
                        <br/><br/>
                        <div className="genreSection">
                            <h2>Concert 2: Country Music - in performance at the White House </h2>
                            <p>https://www.youtube.com/watch?v=NZ4mCdYigEs</p>
                            <br/>
                            <img src={Country1} alt="Music performers shown lined up on a stage" className='imgHalf'/>
                            <br/>
                            <p>An intimate concert filmed in the East Room of the White House for President Obama and First Lady Michelle Obama. </p>
                            <br/><p>Set list (taken from the YouTube description): “Home,” Dierks Bentley “Cowboy Man,” Lyle Lovett “Pancho & Lefty,” Kris Kristofferson & Darius Rucker “If I Die Young,” The Band Perry “When You Say Nothing at All,” Allison Krauss“Riding on a Railroad,” James Taylor “I Got Nothin'”, Darius Rucker “Always on My Mind,” Dierks Bentley & Lauren Alaina “Crazy,” Mickey “Wichita Lineman,” James Taylor “Coal Miner's Daughter,” Lauren Alaina “Funny How Time Slips Away,” Lyle Lovett“I Will Always Love You,” The Band Perry “Me And Bobby McGee,” Full Cast</p>
                            <br/><p>With a simple stage and backdrop and mood lighting, the environment was expectedly classy. The audience is seated in a formal theatre arrangement and there are many impressive instruments on view and very talented artists performing. A single band accompanied each performance as required and each artist introduced the next, ultimately all coming together at the end to perform “Me and Bobby McGee”.</p>
                            <br/><p>A well-established lineup of artists with a collection of well-known hits. A nicely curated programme and flawless performances for a very enjoyable concert.</p> 
                        </div>
                        <br/><br/>
                        <div className="genreSection">
                            <h2>Concert 3: Country Rocks the Park Live from Toyota Park - Australia</h2>
                            <p>https://www.youtube.com/watch?v=dOCQFcR3QxA</p>
                            <br/>
                            <img src={Country2} alt="Credits for a live stream performance" className='imgThird'/>&nbsp;<img src={Country3} alt="Credits for a live stream performance" className='imgThird'/>
                            <br/>
                            <p>17 Jan 2023 - Tamworth Country Rocks</p>
                            <br/><p>An audience shot from the stage shows this to be what looks like a local festival, rather than crowds standing, there are people sat on camp chairs on grass in front of the stage. Nevertheless, it's a good setup, with a full light rig, tech kit and cameramen on stage, being livestreamed. From what I can tell, the sound quality is what you would expect from this kind of festival. The compere, Justin, was doing a great job of keeping things going in between sets.</p>
                            <br/><p>Opened by alt-country rockers Copperline with a lively set followed by Ronnie Joudi, labelled as the “Contemporary Cowboy”. Next up is Stewart Barton, a young up and coming artist, followed by country rocker Ben Ransom, belting out some rocking numbers. After a brief interlude from Country Rocks, rock chick Ingrid Mae took the stage followed by Brewn, a country-infused rock band who unleashed the pyrotechnics! Bringing the fire next was Australian Country Rock artist Paul Costa and band. Headlining the evening was Hayley Jensen who had previously appeared on Australian Idol </p>
                            <br/><p>A really great lineup of artists, each giving an engaging performance. I'd have been happy to have been here in person listening! </p>
                        </div>
                        <br/><br/>
                        <div className="genreSection">
                            <h2>Concert 4: Live Chiptune Performance with Four Gameboys</h2>
                            <p>https://www.youtube.com/watch?v=cFz43VTRyE4</p>
                            <br/>
                            <img src={Chiptune1} alt="A technical set up for a performance of chiptune music, shown from overhead looking at the equipment" className='imgHalf'/>
                            <br/>
                            <p>This was uploaded on 24th April 2015 and is a 48 minute performance of chiptune music perfomed with Four Gameboys! The artist is Trey Frey, and he is using a rig that uses four Nintendo Gameboys running LSDj and a DJ mixer.</p>
                            <br/>
                            <p>This is an interesting perspective to watch from, you have an overhead view of the rig with the artist inlaid in the bottom right, so you can see exactly what he is doing to produce the sounds.</p>
                            <br/><p>The beats are heavy with a great bass sound, but you can clearly hear the gameboy sounds in the mix!</p>
                            <br/><p>Plenty of appreciation in the live comments - which is almost like having an audience! </p>
                            <br/><p>Music quality was good, camera a little blurry but that was addressed in the comments. A great performance, I enjoyed this one.</p>
                            <br/>
                        </div>
                        <br/><br/>
                        <div className="genreSection">
                            <h2>Concert 5: Chiptune Concert - Arcade Vintage</h2>
                            <p>https://www.youtube.com/watch?v=vI_6f483Zl0</p>
                            <br/>
                            <img src={Chiptune2} alt="A man on a stage surrounded by vintage computer equipment, performing a Chiptune concert" className='imgHalf'/>
                            <br/>
                            <p>This is a Chiptune Concert held on October 1, 2022 at the "Arcade Vintage" Video Game Museum in the city of Ibi - Alicante.</p>
                            <br/><p>The performance was given by one man and a synthesiser on a stage surrounded by vintage computer equipment!  I believe the performer is Rob Hubbard, but he played a variety of tracks from various composers including Maniacs of Noise, Outrun, Rob Hubbard, Jan Hammer, Kebu, Brad Fiedel, Chris Huelsbeck, Allister Brimble, Firefox & Tip, Imagitec Design, Ron Klaren and Nation XII.</p>
                            <br/><p>Behind him on the stage is a screen where arcade games play to each track - the sounds and/or beats from each game are used in the tracks.</p>
                            <br/><p>The performance is given in a hall, there is no high-tec lighting or sound setup and a solo performer, so the ambience feels low, but the music is varied and high-energy. Some was even familiar!  There is no audience featured in the filming, so it's hard to get a feel of atmosphere, but the beats create a party atmosphere and it's easy to listen to. At the end, the audience that were present were appreciative and calling for more and I'd have been happy to listen to more too.</p>
                        </div>
                        <br/><br/>
                        <div className="genreSection">
                            <h2>Concert 6: Retroreal 2022 Chiptune Concert</h2>
                            <p>https://www.youtube.com/watch?v=UAR0yuBFkCE</p>
                            <br/>
                            <img src={Chiptune3} alt="A man on a stage with technical equipment on a table in front of him and a projection screen on the wall behind him showing computer games" className='imgHalf'/>
                            <br/>
                            <p>RetroReal 2022 was held on June 4, 2022 at the Espacio Joven in Ciudad Real. The event allowed people the opportunity to see and interact with  lot of old video game systems that are no longer commercially available.</p>
                            <br/><p>This is a 9 minute performance from composer Rob Hubbard. He is using a synth setup with two monitors. As he plays tracks, the game on the screen behind him changes, showing the game from which the sounds / beats originated. </p>
                            <br/><p>It's hard to comment on performance, but whatever lacks in the traditional concert performance is made up for in the energy of the music! The tracks covered are: Commando, Monty on the Run,  The Last V8, Delta, Crazy Coments y Ace 2.</p>

                        </div>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 4</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>This clause requires the creation of three different playlists. To find out what they each mean, you'll just have to join the club ;)</p>
                        <br/><p>My first playlist is essentially my Liked Songs list on Spotify. There's over a thousand songs, I'm not listing them here!</p>
                        <br/><p>My Second playlist is made up mostly of 8D music playlists on YouTube, for example:</p>
                        <ul className='list'>
                            <li>Sia - Unstoppable (8D version)</li>
                            <li>8D Music Mix - Best 8D Audio Songs (playlist)</li>
                        </ul>
                        <br/>
                        <p>My third playlist is really hard to come up with - and maybe I'll add to it over time - but here's a selection that represent this one:</p>
                        <ul className="list">
                            <li>Chase McDaniel - Project - “Baby, I'm a project, loving me's a mistake, Find another prospect, save yourself the heartbreak, Think you're gonna fix me, think we're making progress, You'll be broken with me somewhere in the process”</li>
                            <li>Em Beihold - Numb Little Bug - “Do you ever get a little bit tired of life, like you're not really happy but you don't wanna die”</li>
                            <li>Natalie Kills - Problem - “We're rock bottom but there ain't no stopping, Cause it's you and me against the world”</li>
                            <li>Sky Rey - IDGAF - “I don't really give a fuck and I don't think I ever have”</li>
                            <li>Mary Heather Hickman - Treasure - “If he looks like he's bad news, Six-foot five with a couple tattoos, A smokin' habit and a drinking problem, Sounds like he's my prince charming … I can't change ‘em, I can't fix ‘em, But damn I sure can pick ‘em”</li>
                            <li>Shanin Blake - Energy Vampires - “I know my worth, I know I shine, So I'm gonna protect my energy all the time”</li>
                            <li>Livingston - Traitor - “Oh, I just gave my heart to a traitor, Maybe I was dumb enough to think I could save ya, Love, I'm just a sinner, and I can't be your savior, Why am I the one to put myself in danger?”</li>
                            <li>The Script, will.i.am - Hall of Fame - “You can go the distance, you can run the mile, you can walk straight through hell with a smile”</li>
                            <li>Teflon Sega - Paralyzed - “Sleep walking to a rhythm trying not to freak out, Paralyzed, hypnotized, numb inside, lost up in my thoghts, got nowhere to hide….welcome to the twisted circus in my mind.”</li>
                            <li>Will Bowes - The Devil I Know - “I drink wine with the devil, you know that I'll play you like a level”</li>
                            <li>Empire Cast - Born to Win - “Hey, hey, can't nothing stop me now hey, I hope there's no one in my way, through my way, struggle and pain, Born to win”</li>
                            <li>Leslie Odom Jr. feat. Sia - Cold - “I wanna help you ease the feeling, weighing on your mind…. And you know the safest place to be, is right here next to me”</li>
                            <li>Anthony Ramos - Stop - “Even when my feet are up, my mind is runnin' fast.When I'm home, I get impatient, when I'm gone, I kinda hate it.”</li>
                            <li>Shinedown - Monsters - “'Cause my monsters are real ,and they're trained how to kill, and there's no comin' back and they just laugh at how I feel”</li>
                            <li>Ava Max - Kings & Queens - “To all of the queens who are fighting alone, Baby, you're not dancing on your own.”</li>
                        </ul>
                    </div>
                </div>
            </div> 
            <div className="badgePageItem">
                <div className="badgePageItemTitle purple">Clause 5</div>
                <div className="badgePageItemContent">
                    <div className="badgePageItemInfo purple-light"></div>
                    <div className="badgePageItemEvidence">
                        <p>This is to do with innovations in music, I can't say any more than that without giving out the clause!</p>
                        <br/>
                        <ul className='list'>
                            <li><strong>Streaming services</strong>: Streaming services like Spotify and Apple Music have revolutionised the way people listen to music. They offer a vast library of songs that can be accessed from anywhere, at any time, and on any device. They also provide personalised recommendations based on the user's listening history and preferences, which helps users discover new music that they might enjoy.<br/></li>
                            <li><strong>Augmented reality (AR)</strong>: AR technology has been used to create interactive music experiences that allow fans to engage with their favorite artists in new and exciting ways. For example, some artists have used AR to create virtual concerts or to enhance their music videos with 3D animations.<br/></li>
                            <li><strong>Virtual reality (VR)</strong>: VR technology has been used to create immersive music experiences that transport fans to different worlds. For example, some artists have used VR to create virtual reality concerts or to create music videos that allow fans to explore different environments.<br/></li>
                            <li><strong>Artificial intelligence (AI)</strong>: AI technology has been used to create personalised music experiences that cater to the user's preferences. For example, some music services use AI to create custom playlists based on the user's listening history and mood.<br/></li>
                            <li><strong>Blockchain</strong>: Blockchain technology has been used to create more transparent and fair music distribution systems that allow artists and fans to connect directly and securely. Blockchain can also enable faster and more accurate royalty payments, as well as new ways of monetizing music content, such as tokenization and smart contracts.<br/></li>
                            <li><strong>3D audio</strong>: 3D audio technology has been used to create more realistic and immersive sound experiences that simulate the spatial and directional aspects of sound. 3D audio can enhance the quality and depth of music, as well as create new possibilities for storytelling and interactivity. 3D audio can also be used to create personalized soundscapes that adapt to the user's preferences and environment.<br/></li>
                            <li><strong>Social media</strong>: Social media platforms have been used to create more engaging and interactive music experiences that allow fans to communicate with their favorite artists and other music lovers. Social media can also help artists promote their music, build their fanbase, and generate feedback and insights. Social media can also be a source of inspiration and collaboration for music creation and discovery.<br/></li>
                        </ul>
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default MusicLover